import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";
import { GeoLocation } from "../property/location.model";
import { Employee } from "../employee/employee.model";
import { MatchingItem } from "./matching.item.model";
import { Ticket } from "../ticket/ticket.model";
import { Constant } from "../../shared/constant/constant";
import { POS } from "../orgchart-pos/model/pos.model";
import { Category } from "../category/category.model";
import { Customer } from "../customer/customer.model";
import { ObjectUnsubscribedError } from "rxjs";

export class Demand extends BaseModel implements BaseModelInterface  {
    @ValidateIf(o => o.otherProperty === "")
    @IsNotEmpty()
    @Length(10, 20)
    id: string;

    @Length(10, 100)
    name: string;

    category: Category;

    customer: Customer;

    customerName: string;

    phone: string;

    type: string;

    ticket: Ticket;

    ticketId: string;

    pos: POS;

    posId: string;

    attributes: any;

    commonAttributes: any[];

    note: string;

    employee: Employee;

    source: string;

    matchingCount: string;

    code: string;

    lock: boolean;

    dealing: any;
    searchType: string;
    propertyCode: string;
    unit: any;

    contractCode: string;

    lead: Lead;
    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'demand';
    }
    static getTypeMatching() {
        return 'matching';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.type = params.type || Constant.BUY_TRANSACTION;
            this.ticket = params.ticket ? new Ticket(params.ticket) : new Ticket();
            this.ticketId = this.ticket.id;
            this.category = params.category ? new Category(params.category) : new Category();
            this.note = params.note || '';
            this.pos = params.pos ? new POS(params.pos) : new POS();
            this.attributes = params.attributes || [];
            this.commonAttributes = params.commonAttributes ? params.commonAttributes.map((attribute) => {
                if (attribute.name == 'price') {
                    attribute.text = 'Giá';
                } else  if (attribute.name == 'area') {
                    attribute.text = 'Diện tích';
                } else  if (attribute.name == 'direction') {
                    attribute.text = 'Hướng';
                }
                return attribute;
            }) : [];
            this.customer = params.customer ? new Customer(params.customer) : new Customer();
            this.customerName = this.customer.personalInfo.name || '';
            this.phone = this.customer.personalInfo.phone || '';
            this.code = params.code || null;
            this.source = params.source || params.resource || 'webbroker';
            this.employee = params.employee ? new Employee(params.employee) : new Employee();
            this.matchingCount = params.matchingCount;
            this.lock = params.lock || false;
            this.dealing = params.dealing || null;
            this.unit = {
              project: {
                id: ''
              },
              code: ''
            };
            this.unit.project.id = params.unit ? (params.unit.project ? params.unit.project.id : '') : '';
            this.unit.code = params.unit ? params.unit.code : '';
            this.searchType = params.searchType || 'normal';
            this.propertyCode = params.propertyCode || '';
            this.contractCode = params.contractCode || '';
            this.lead = params.lead || {};
        } else {
            this.setAllNull();
        }
        super.setValue(params);
        this.status = this.status || 'process';
    }

    setAllNull() {
        this.id = '';
        this.name = '';
        this.type =  Constant.BUY_TRANSACTION;
        this.ticket = new Ticket();
        this.pos = new POS();
        this.employee = new Employee();
        this.attributes = [];
        this.commonAttributes = [];
        this.source = 'webbroker';
        this.status = 'process';
        this.category = new Category();
        this.lock = false;
        this.dealing = {};
        this.searchType = 'normal';
        this.propertyCode = '';
        this.contractCode = '';
        this.lead = {
            id: '', 
            code: ''
        };
        this.unit = {
          project: {
            id: ''
          },
          code: ''
        };
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object.name = this.name;
        object.type = this.type;
        object.ticket = this.ticket.createObjectReference();
        object.ticketId = this.ticket.id;
        object.note = this.note || 'pending';
        object.category = this.category;
        object.customer = this.customer ? new Customer(this.customer) : new Customer();;
        object.pos = this.pos.createObjectReference();
        object.attributes = this.attributes;
        object.employee = this.employee.createObjectReference();
        object.commonAttributes = this.commonAttributes;
        object.dealing = this.dealing;
        object.searchType = this.searchType;
        object.propertyCode = this.propertyCode;
        object.contractCode = this.contractCode;
        object.lead = this.lead;
        object.unit = this.unit;
        if (this.searchType === 'code') {
          delete object.unit.project;
          object.commonAttributes =  null;
          object.attributes = {};
          object.category = new Category();
        } else {
          delete object.unit.code;
        }
        object.resource = this.source;
        return object;
    }
}

export interface Lead {
    id: string;
    code: string;
} 
