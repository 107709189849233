import { BaseModel } from '../../shared/models/base.model';
import { BaseModelInterface } from '../../shared/models/base.model.interface';
import { DateToFormat } from 'app/shared/parse/date-to-format';
import { parsePhone } from 'app/shared/utility/utility';
import { Constant, Relationship } from 'app/shared/constant/constant';
import { ExploitEnum } from 'app/enums/exploit.enum';
import { ExploitTextEnum } from 'app/shared/enum/lead-job.enum';
import { FamilyModel } from 'app/shared/models/family.model';
import { VehicleModel } from 'app/shared/models/vehicle.model';
import { FileModel } from 'app/shared/components/file-uploader';

export class LeadModel extends BaseModel implements BaseModelInterface {

    id: string;
    name: string;
    address: string;
    phone: string;
    email: string;
    profileUrl: string;
    pos: Object;
    status: string;
    lifeCycleStatus: string;
    processBy: string;
    type: string;
    createdDate: Date;
    updatedDate: Date;
    timeOut: Date;
    customerId: string;
    timezoneclient: string;
    notes: Object[];
    customer: object;
    employee: object;
    source: string;
    code: string;
    processedDate: Date;
    processedHistory: LeadProcessed[];
    isCalled: boolean;
    advisingType: string;
    assignedDate: Date;
    repoId: string;
    customerPhone: string;
    repo: string;
    isHot: boolean

    // new
    updatedName: string;
    updatedPhone: string;
    updatedEmail: string;
    updatedProfileUrl: string;

    isInNeed: string;
    reasonNoNeed: string;
    otherReason: string;
    interestedProduct: InterestedProduct;
    direction: Direction;
    needLoan: boolean;
    isAppointment: boolean;
    isVisited: boolean;
    hasSendSmsAfterSurvey: boolean;
    note: string;

    callHistory: any[];

    callId: string;
    startCall: Date;
    endCall: Date;
    answerTime: number;
    callHistoryCount: number;
    callHistoryMinuteCount: number;
    configData: any;

    // Excel
    sourceExcel: string;
    posName: string;
    employeeName: string;
    phoneExcel: string;

    updatedNameExcel: string;
    updatedPhoneExcel: string;
    updatedEmailExcel: string;
    updatedProfileUrlExcel: string;

    isInNeedExcel: string;
    reasonNoNeedExcel: string;
    otherReasonExcel: string;
    interestedProductExcel: string;
    directionExcel: string;
    needLoanExcel: string;
    isAppointmentExcel: string;
    isVisitedExcel: string;
    noteExcel: string;
    surveys: any[];

    assignedDateExcel: string;
    updatedDateExcel: string;
    takeCare: any;
    customData: any;
    repoType: string;
    contentData: any;
    assignee: string;
    exploitStatus: string;
    countAssign: number;
    title:string;
    dateEndWork:Date;
    isMonthly:boolean;
    block:string;
    timeStartWork:Date;
    timeEndWork: Date;
    implementMaxDate: Date;
    isFirstWorkId: string;
    isChange: boolean;
    exploitStatusText: string;
    project: any;
  
    reason: string

    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.repoType = params.repoType || '';
            this.address = params.address || '';
            this.phone = (params.configData && params.configData.visiblePhone) ? params.phone : parsePhone(params.phone);
            this.customerPhone = params.phone
            this.email = params.email || '';
            this.profileUrl = params.profileUrl || '';
            this.pos = params.pos || {};
            this.status = params.status || '';
            this.lifeCycleStatus = params.lifeCycleStatus || '';
            this.processBy = params.processBy || '';
            this.type = params.type || 'PRIMARY';
            this.createdDate = params.createdDate || new Date();
            this.updatedDate = params.updatedDate || new Date();
            this.timeOut = params.timeOut || new Date();
            this.customerId = params.customerId || '';
            this.timezoneclient = params.timezoneclient || '';
            this.notes = params.notes || [];
            this.customer = params.customer || [];
            this.employee = params.employee || [];
            this.source = params.source || '';
            this.surveys = params.surveys || [];
            this.code = params.code || '';
            this.processedDate = params.processedDate || new Date();
            this.processedHistory = params.processedHistory || [];
            this.isCalled = params.isCalled || false;
            this.hasSendSmsAfterSurvey = params.hasSendSmsAfterSurvey || false;
            this.advisingType = params.advisingType || '';
            this.assignedDate = params.assignedDate || new Date();
            this.repoId = params.repoId || ''
            this.updatedName = params.updatedName || '';
            this.updatedPhone = params.updatedPhone || '';
            this.updatedEmail = params.updatedEmail || '';
            this.updatedProfileUrl = params.updatedProfileUrl || '';
            this.isHot = params.isHot || false

            this.isInNeed = params.isInNeed || '';
            this.configData = params.configData || {};
            this.reasonNoNeed = params.reasonNoNeed || '';
            this.otherReason = params.otherReason || '';
            this.interestedProduct = params.interestedProduct || new InterestedProduct();
            this.direction = params.direction || new Direction();
            this.needLoan = params.needLoan || false;
            this.isAppointment = params.isAppointment || false;
            this.isVisited = params.isVisited || false;
            this.note = params.note || '';
            this.project = params.project;

            this.callHistory = params.callHistory || [];

            this.callId = params.callId || '';
            this.startCall = params.startCall || null;
            this.endCall = params.endCall || null;
            this.answerTime = params.answerTime || 0;

            this.callHistoryCount = params.callHistoryCount || 0;
            this.callHistoryMinuteCount = params.callHistoryMinuteCount || 0;

            this.sourceExcel = Constant.LEAD_SOURCE[this.source];
            this.posName = this.pos ? this.pos['name'] : '';
            this.employeeName = this.employee ? this.employee['name'] : '';
            this.phoneExcel = parsePhone(params.phone);

            this.updatedNameExcel = this.updatedName ? '1' : '0';
            this.updatedPhoneExcel = this.updatedPhone ? '1' : '0';
            this.updatedEmailExcel = this.updatedEmail ? '1' : '0';
            this.updatedProfileUrlExcel = this.updatedProfileUrl ? '1' : '0';

            this.isInNeedExcel = this.isInNeed === 'yes' ? '1' : this.isInNeed === 'no' ? '0' : '';
            // this.reasonNoNeedExcel: string;
            // this.otherReasonExcel: string;
            this.interestedProductExcel = InterestedProduct.toDisplayString(this.interestedProduct);
            this.directionExcel = Direction.toDisplayString(this.direction);
            this.needLoanExcel = this.needLoan ? '1' : '0';
            this.isAppointmentExcel = this.isAppointment ? '1' : '0';
            this.isVisitedExcel = this.isVisited ? '1' : '0';
            this.noteExcel = this.note ? '1' : '0';

            this.assignedDateExcel = DateToFormat.parseDate(this.assignedDate, 'dd/MM/yyyy - hh:mm:ss');
            this.updatedDateExcel = DateToFormat.parseDate(this.updatedDate, 'dd/MM/yyyy - hh:mm:ss');
            this.takeCare = params.takeCare || null;
            this.customData = params.customData? params.customData :{}
            this.contentData = params.customData && params.customData.projectData ? params.customData.projectData: params.customData && params.customData.rentData ? params.customData.rentData : params.customData && params.customData.transfer ? params.customData.transfer : {}
            this.exploitStatus = params.exploitStatus || '';
            this.isChange = params.isChange || false;
            if(this.exploitStatus === ExploitEnum.ASSIGN || this.exploitStatus === ExploitEnum.REASSIGN) {
                this.assignee = this.takeCare.id || null;
            }
            this.countAssign = params.countAssign || 0;
            this.title = params.title || '';
            this.dateEndWork = params.dateEndWork || null;
            this.isMonthly = params.isMonthly || false;
            this.block = params.block || '';
            this.timeStartWork = params.timeStartWork || null;
            this.timeEndWork = params.timeEndWork || null;

            this.implementMaxDate = params.implementMaxDate || null;
            this.isFirstWorkId = params.isFirstWorkId || null;
            this.reason = params.reason || null;

            this.isChange = params.isChange || false
            this.exploitStatusText = params.exploitStatus ? this.setExploitStatusText(params.exploitStatus) : '';

        } else {
            this.setAllNull();
        }
        super.setValue(params);
    }

    setAllNull() {
        this.id = '';
        this.name = '';
        this.repoType =  '';
        this.address = '';
        this.phone = '';
        this.customerPhone = ''
        this.email = '';
        this.profileUrl = '';
        this.pos = null;
        this.status = '';
        this.lifeCycleStatus = '';
        this.processBy = '';
        this.type = 'PRIMARY';
        this.surveys = [];
        this.createdDate = new Date();
        this.updatedDate = new Date();
        this.timeOut = new Date();
        this.customerId = '';
        this.timezoneclient = '';
        this.notes = [];
        this.customer = [];
        this.employee = [];
        this.source = '';
        this.code = '';
        this.processedDate = new Date();
        this.processedHistory = [];
        this.isCalled = false;
        this.hasSendSmsAfterSurvey = false;
        this.advisingType = '';
        this.assignedDate = new Date();
        this.repoId = '';
        this.isHot = false;

        this.updatedName = '';
        this.updatedPhone = '';
        this.updatedEmail = '';
        this.updatedProfileUrl = '';
        this.configData = {};
        this.project = {};

        this.isInNeed = '';
        this.reasonNoNeed = '';
        this.otherReason = '';
        this.interestedProduct = new InterestedProduct();
        this.direction = new Direction();
        this.needLoan = false;
        this.isAppointment = false;
        this.isVisited = false;
        this.note = '';
        this.callId = '';
        this.startCall = null;
        this.endCall = null;
        this.answerTime = 0;
        this.takeCare = null;
        this.customData = {}
        this.contentData ={}
        this.assignee = null;
        this.exploitStatus = '';
        this.countAssign = 0;
        this.title = '';
        this.dateEndWork = null;
        this.isMonthly = false;
        this.block = '';
        this.timeEndWork = null;
        this.timeStartWork = null;
        this.implementMaxDate =  null;
        this.isFirstWorkId = null;
        this.isChange = false;
    }
    createObjectRequestAPI() {
        let object: any = {};
        object.id = this.id || '';
        object.name = this.name || '';
        object.address = this.address || '';
        object.phone = this.phone || '';
        object.email = this.email || '';
        object.profileUrl = this.profileUrl || '';
        object.pos = this.pos || {};
        object.status = this.status || '';
        object.lifeCycleStatus = this.lifeCycleStatus || '';
        object.processBy = this.processBy || '';
        object.type = this.type || 'PRIMARY';
        object.createdDate = this.createdDate || new Date();
        object.updatedDate = this.updatedDate || new Date();
        object.timeOut = this.timeOut || new Date();
        object.customerId = this.customerId || '';
        object.timezoneclient = this.timezoneclient || '';
        object.notes = this.notes || [];
        object.customer = this.customer || [];
        object.source = this.source || '';
        object.code = this.code || '';
        object.processedDate = this.processedDate || new Date();
        object.processedHistory = this.processedHistory || [];
        object.isCalled = this.isCalled || false;
        object.hasSendSmsAfterSurvey = this.hasSendSmsAfterSurvey || false;
        object.advisingType = this.advisingType || '';
        object.assignedDate = this.assignedDate || new Date();
        object.repoId = this.repoId || '';
        object.isHot = this.isHot || false

        object.updatedName = this.updatedName || '';
        object.updatedPhone = this.updatedPhone || '';
        object.updatedEmail = this.updatedEmail || '';
        object.updatedProfileUrl = this.updatedProfileUrl || '';

        object.isInNeed = this.isInNeed || '';
        object.reasonNoNeed = this.reasonNoNeed || '';
        object.otherReason = this.otherReason || '';
        object.interestedProduct = this.interestedProduct || new InterestedProduct();
        object.direction = this.direction || new Direction();
        object.needLoan = this.needLoan || false;
        object.isAppointment = this.isAppointment || false;
        object.isVisited = this.isVisited || false;
        object.note = this.note;
        object.callId = this.callId || '';
        object.startCall = this.startCall || null;
        object.endCall = this.endCall || null;
        object.answerTime = this.answerTime || 0;
        object.surveys = this.surveys || [];
        object.title = this.title || '';
        object.exploitStatus = this.exploitStatus ||'';
        object.dateEndWork = this.dateEndWork || null;
        object.isMonthly = this.isMonthly || false;
        object.block = this.block || '';
        object.timeEndWork = this.timeEndWork || null;
        object.implementMaxDate = this.implementMaxDate || null;
        object.timeStartWork = this.timeStartWork || null;
        return object;
    }
    getHeaders() {
        return [
            { header: 'Mã', key: 'code' },
            { header: 'Họ và tên', key: 'name' },
            { header: 'Số điện thoại', key: 'phoneExcel' },
            { header: 'Nguồn', key: 'sourceExcel' },
            { header: 'Đơn vị', key: 'posName' },
            { header: 'TVV', key: 'employeeName' },
            { header: 'Thời gian bắt đầu', key: 'assignedDateExcel' },
            { header: 'Thời gian cập nhật mới nhất', key: 'updatedDateExcel' },
            { header: 'Số cuộc gọi đã thực hiện', key: 'callHistoryCount' },
            { header: 'Tổng thời lượng cuộc gọi (giây)', key: 'callHistoryMinuteCount' },
            { header: 'Họ và tên cập nhật', key: 'updatedNameExcel' },
            { header: 'SDT cập nhật', key: 'updatedPhoneExcel' },
            { header: 'Email cập nhật', key: 'updatedEmailExcel' },
            { header: 'Link profile cập nhật', key: 'updatedProfileUrlExcel' },
            { header: 'Khách hàng có nhu cầu mua không?', key: 'isInNeedExcel' },
            { header: 'Sản phẩm quan tâm?', key: 'interestedProductExcel' },
            { header: 'Hướng', key: 'directionExcel' },
            { header: 'Khách hàng có nhu cầu vay ngân hàng?', key: 'needLoanExcel' },
            { header: 'Hẹn tư vấn', key: 'isAppointmentExcel' },
            { header: 'Khách muốn đi xem dự án?', key: 'isVisitedExcel' },
            { header: 'Ghi chú khác', key: 'noteExcel' },
        ];
    }

    setExploitStatusText(exploitStatus: string) {
        switch(exploitStatus) {
            case ExploitEnum.NEW:
            case ExploitEnum.RENEW:
            case ExploitEnum.REASSIGN:
            case ExploitEnum.ASSIGN:
                exploitStatus = ExploitTextEnum.NEW;
                break;
            case ExploitEnum.PROCESSING:
                exploitStatus = ExploitTextEnum.PROCESSING;
                break;
            case ExploitEnum.DONE:
                exploitStatus = ExploitTextEnum.DONE;
                break;
            case ExploitEnum.CANCEL:
                exploitStatus = ExploitTextEnum.CANCEL;
                break;
        }
        return exploitStatus;
    }
}

export interface LeadProcessed {
    id: string;
    processedDate: Date;
    processBy: string;
    isReject: boolean;
    isTimeOut: boolean;
    causeReject: string[];
}

export class InterestedProduct {
    all: boolean;
    build: boolean;
    buy: boolean;
    goldsilkBoulevard: boolean;
    villa: boolean;
    apartment: boolean;
    constructor(param?) {
        if (param) {
            this.all = param.all || false;
            this.build = param.build || false;
            this.buy = param.buy || false;
            this.goldsilkBoulevard = param.goldsilkBoulevard || false;
            this.villa = param.villa || false;
            this.apartment = param.apartment || false;
        } else {
            this.all = false;
            this.build = false;
            this.buy = false;
            this.goldsilkBoulevard = false;
            this.villa = false;
            this.apartment = false;
        }
    }
    static toDisplayString(item: InterestedProduct) {
        let result = '';
        if (item.all) {
            result += 'Tất cả, ';
        } else {
            if (item.build) {
                result += 'Nhà phố thương mại (shophouse) - Tự xây, ';
            }
            if (item.buy) {
                result += 'Nhà phố thương mại (shophouse) - Xây sẵn, ';
            }
            if (item.goldsilkBoulevard) {
                result += 'Shophouse Goldsilk Boulevard, ';
            }
            if (item.villa) {
                result += 'Shophouse Villa, ';
            }
            if (item.apartment) {
                result += 'Căn hộ, ';
            }
        }
        return result ? result.substr(0, result.length - 2) : '';
    }
}
export class Direction {
    all: boolean;
    e: boolean;
    w: boolean;
    s: boolean;
    n: boolean;
    en: boolean;
    es: boolean;
    wn: boolean;
    ws: boolean;
    constructor(param?) {
        if (param) {
            this.all = param.all || false;
            this.e = param.e || false;
            this.w = param.w || false;
            this.s = param.s || false;
            this.n = param.n || false;
            this.en = param.en || false;
            this.es = param.es || false;
            this.wn = param.wn || false;
            this.ws = param.ws || false;
        } else {
            this.all = false;
            this.e = false;
            this.w = false;
            this.s = false;
            this.n = false;
            this.en = false;
            this.es = false;
            this.wn = false;
            this.ws = false;
        }
    }
    static toDisplayString(direction: Direction) {
        let result = '';
        if (direction.all) {
            result = 'Tất cả, ';
        } else {
            if (direction.e) {
                result += 'Đông, ';
            }
            if (direction.w) {
                result += 'Tây, ';
            }
            if (direction.s) {
                result += 'Nam, ';
            }
            if (direction.n) {
                result += 'Bắc, ';
            }
            if (direction.en) {
                result += 'Đông Bắc, ';
            }
            if (direction.es) {
                result += 'Đông Nam, ';
            }
            if (direction.wn) {
                result += 'Tây Bắc, ';
            }
            if (direction.ws) {
                result += 'Tây Nam, ';
            }
        }
        return result ? result.substr(0, result.length - 2) : '';
    }
}

export class RatingCustomerManagementModel {
    name: string;
    value: number
    constructor(param?: any) {
        this.name = param && param.ratingValue ? `Đánh giá ${param.ratingValue} sao` : '';
        this.value = param && param.count ? param.count : 0
    }
}

export class ApartMentManageModel {
    stt: number;
    id: string;
    customerInfo: any;
    description: string;
    family: FamilyModel[];
    modifiedDate: string;
    projectInfo: any;
    propertyInfo: any;
    vehicles: VehicleModel[];
    owner: FamilyModel;
    profileNContract: FileModel[];
    status: string;
    constructor(params?: any) {
        this.setValue(params);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.customerInfo = params.customerInfo || {};
            this.description = params.description || '';
            this.family = params.family || [];
            this.modifiedDate = params.modifiedDate || '';
            this.projectInfo = params.projectInfo || '';
            this.propertyInfo = params.propertyInfo || '';
            this.vehicles = params.vehicles || [];
            this.owner = params.family ? this.getOwnerName(params.family) : new FamilyModel({}); 
            this.profileNContract = params.propertyInfo.profileNContract || [];
            this.status = params.status || ''
        } else {
            this.id = '';
            this.customerInfo = {};
            this.description = '';
            this.family = [];
            this.modifiedDate = '';
            this.projectInfo = '';
            this.propertyInfo = '';
            this.vehicles = [];
            this.profileNContract = [];
            this.status = ''
        }
    }

    getOwnerName(family:FamilyModel[]){
        let owner = family.find((item:FamilyModel) => item.relationship === Relationship.owner);
        if(owner) {
            return new FamilyModel(owner);
        }else {
            return new FamilyModel({});
        }
    }
}
