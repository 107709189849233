import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface DialogData {
  type: string;
  title: string;
  reason: string;
  execute: boolean;
  body: string;
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'popup-dialog.html',
})
export class HandleErrorRequestDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HandleErrorRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }
}