import { Pipe, PipeTransform } from '@angular/core';
import { SaleList } from 'app/pages/project/event-sale/table-deal-panel/sale-list.model';
import { UNIT_STATUS } from '../constant/transaction.constant';
@Pipe({name: 'unitStatus'})
export class TransformUnitStatus implements PipeTransform {
  transform(value: string) {
    return TransformUnitStatus.parse(value);
  }
  static transform(value: string) {
    return this.parse(value);
  }

  private static parse(typeInput) {
    if(!typeInput) return '';
    let type = typeInput.toUpperCase();
    const text = [
        { id: 'LOCK', name: UNIT_STATUS['LOCK'] },
        { id: 'CLOSE', name: UNIT_STATUS['CLOSE'] },
        { id: 'COMING', name: UNIT_STATUS['COMING'] },
        { id: 'PROCESSING', name: UNIT_STATUS['PROCESSING'] },
        { id: 'CONFIRM', name: UNIT_STATUS['CONFIRM'] },
        { id: 'MCONFIRM', name: UNIT_STATUS['MCONFIRM'] },
        { id: 'SUCCESS', name: UNIT_STATUS['SUCCESS'] },
        { id: 'MSUCCESS', name: UNIT_STATUS['MSUCCESS'] },
        { id: 'LOCK_CONFIRM', name: UNIT_STATUS['LOCK_CONFIRM'] },
        { id: 'UNSUCCESS', name: UNIT_STATUS['UNSUCCESS'] },
        { id: 'CANCEL', name: UNIT_STATUS['CANCEL'] },
        { id: 'MOVED', name: UNIT_STATUS['MOVED'] },
      ].find(i => i.id === type);
    if (text) {
      return text.name;
    };
    return type;
  }
}
