import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Settings } from "app/app.settings.model";
import { AppSettings } from "app/app.settings";
import { UserService } from "app/shared/services/common/user.service";
import { PasswordService } from "../password.service";
import { Constant } from "app/shared/constant/constant";
import { ToastrService } from "app/shared/services/common";

@Component({
    selector: "forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
    public form: FormGroup;
    public settings: Settings;
    data: any;
    step = 0;
    receiveVia = "";
    Constant = Constant;
    username = "";
    constructor(
        public appSettings: AppSettings,
        public fb: FormBuilder,
        public router: Router,
        public userService: UserService,
        public passwordService: PasswordService,
        public toastr: ToastrService
    ) {
        this.data = {};
        this.settings = this.appSettings.settings;
        this.form = this.fb.group(
            {
                email: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(3),
                    ]),
                ],
                password: [
                    this.data.password,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(8),
                    ]),
                ],
                confirmPassword: [
                    this.data.confirmPassword,
                    Validators.compose([Validators.required]),
                ],
            },
            { validator: this.mustMatch("password", "confirmPassword") }
        );
    }

    mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    getFormControl(name: string) {
        if (!this.form) {
            return null;
        }
        return this.form.get(name);
    }

    public onOtpChange(event) {
        this.data.otp = event;
    }

    public doneOtp() {
        if (this.data.otp.length === 6) {
            this.step = 3;
        }
    }

    public backStep(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.step--;
    }

    public onSubmit(values: Object) {
        this.form.markAsTouched();
        if (!this.form.valid) {
            return;
        }
        const formData = this.form.getRawValue();
        return this.passwordService
            .updatePassword(formData.password, this.data.token, this.data.otp)
            .subscribe((res: any) => {
                const data = res.json();
                if (data.err) {
                    this.toastr.error(res.msg);
                } else {
                    this.step = 4;
                }
            });
    }

    public checkUsername(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.form.get("email").markAsTouched();
        if (this.form.get("email").valid) {
            let body = this.form.getRawValue();

            this.passwordService
                .checkUsername({
                    ...body,
                    username: body.email,
                })
                .subscribe((res: any) => {
                    const data = res.json();
                    if (data.success) {
                        this.step = 1;
                    }
                });
        }
    }

    public requestSendOtp(receiveVia: string, event: Event = null) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.receiveVia = receiveVia;
        this.form.get("email").markAsTouched();
        if (this.form.get("email").valid) {
            let body = this.form.getRawValue();

            this.passwordService
                .forgotPasswordByOtp({
                    ...body,
                    receiveVia,
                    username: body.email,
                })
                .subscribe((res: any) => {
                    const data = res.json();
                    if (data.err) {
                        this.toastr.error(data.msg);
                    } else {
                        this.username = data.data.username;
                        this.step = 2;
                        this.data = {
                            ...this.data,
                            token: data.data.token,
                        };
                    }
                });
        }
    }

    getTextReceiveVia(receiveVia: string) {
        switch (receiveVia) {
            case "EMAIL":
                return "email";
            case "SMS":
                return "số điện thoại";
            case "ZALO":
                return "zalo";
            default:
                return "";
        }
    }
}
