import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CPagination } from 'app/api-models';
import { Subject } from 'rxjs-compat';

@Component({
  selector: 'app-paging-v3',
  templateUrl: './app-paging-v3.component.html',
  styleUrls: ['./app-paging-v3.component.scss']
})
export class AppPagingV3Component implements OnInit, OnChanges, OnDestroy {

  @Input() disabled: boolean = false;
  @Input() autoScrollToTop: boolean = true;
  @Input() data: CPagination<any> = new CPagination();
  @Output() dataChange: EventEmitter<CPagination<any>> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();

  public pageLength = 1;
  public listPageSize: SelectItemModel[] = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' }
  ];
  public paging: number[] = [];
  get numberPage(): number {
    return Math.floor(this.data.total / this.data.pageSize) + (this.data.total % this.data.pageSize > 0 ? 1 : 0);
  }
  get showStartDot(): boolean {
    return this.paging && this.paging[0] && this.paging[0] > 1;
  }
  get showEndDot(): boolean {
    return this.paging && this.paging[this.paging.length - 1] && this.paging[this.paging.length - 1] < this.numberPage;
  }
  get isPagingNextToStart(): boolean {
    return this.paging[0] <= 2;
  }
  get isPagingNextToEnd(): boolean {
    return this.numberPage - this.paging[this.paging.length - 1] <= 1;
  }
  get indexFrom(): number {
    const indexFrom = ((this.data.page - 1) * this.data.pageSize);
    return indexFrom > 0 ? (indexFrom + 1) : 1;
  }
  get indexTo(): number {
    const indexTo = (this.data.page * this.data.pageSize);
    return indexTo < this.data.total ? indexTo : this.data.total;
  }

  constructor(@Inject(DOCUMENT) private document) { }

  ngOnInit() {
    this.initPaging();
  }

  ngOnChanges() {
    this.initPaging();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initPaging() {
    const paging = [];
    const startIndex: number = (this.data.page - this.pageLength > 0) ? this.data.page - this.pageLength : 1;
    const endIndex: number = (this.data.page + this.pageLength <= this.data.totalPage) ? (this.data.page + this.pageLength) : this.data.totalPage;
    for (let i = startIndex; i <= endIndex; i++) {
      paging.push(i);
    }
    this.paging = paging;
  }

  onChangePageSize(event: SelectItemModel) {
    if (this.autoScrollToTop) {
      this.scrollToTop();
    }
    // Tính lại total page
    const newTotalPage: number = Math.ceil(this.data.total / this.data.pageSize);
    this.data.totalPage = newTotalPage;
    this.data.page = 1;
    // Init lại paging
    this.initPaging();
    this.dataChange.emit(this.data);
  }

  firstPage() {
    this.changePage(1);
  }

  previousPage() {
    this.changePage(this.data.page - 1);
  }

  nextPage() {
    this.changePage(this.data.page + 1);
  }

  lastPage() {
    this.changePage(this.numberPage);
  }

  changePage(pageNumber: number) {
    if (this.autoScrollToTop) {
      this.scrollToTop();
    }
    this.data.page = pageNumber;
    this.initPaging();
    this.dataChange.emit(this.data);
  }

  private scrollToTop() {
    setTimeout(() => {
      this.document.getElementById('main-content').scrollTop = 0;
    });
  }

}

interface SelectItemModel {
  value: any;
  label: string;
}

