import { Injectable } from '@angular/core';
import { Http, Response, RequestOptionsArgs, RequestMethod, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DataService, SecurityService, StorageService } from '../../shared/services';
import { ConstantUrl } from '../../shared/constant/url';
import { Observable } from 'rxjs/Rx';
import { EventChangeService } from '../../shared/services/event.change.service';
import { HttpService } from 'app/shared/services/http.service';

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class LoginService {
    ConstantUrl = ConstantUrl;
    isUnAuthen = false;
    constructor(
        private service: DataService,
        private securityService: SecurityService,
        private storageService: StorageService,
        private httpService: HttpService
    ) { }

    login(body) {
        this.storageService.resetAPI();
        body.authType = 'AGENT';
        return this.service.post(ConstantUrl.url_sts_domain + 'auth/login', body)
        .map((res) => {
            let data: any = res.json() || {};
            this.storageService.store('userId', data.id);
            this.securityService.setAuthorizationData(data.access_token, null, data.expires_in);
            this.storageService.store('isTrialRegister', !!data.isTrialRegister); // Check tài khoản dùng thử.
            this.storageService.store('isFirstOpenTrial', !!data.isFirstLoginTrial); // Kiểm tra đăng nhập dùng thử lần đầu.
            return data;
        });
    }
    checkIsExpired() {
        return this.securityService.isExpired();
    }
    sendEmailRegisterTrial(fullName: string, phoneNumber: string) {
        const body = {
            fullName,
            phoneNumber
        };
        const url = ConstantUrl.url_sts_domain + 'user/emailRegisterTrial';
        return this.service.post(url, body);
      }

    sendEmailRegisterTrialNew(p) {
        const url = ConstantUrl.url_sts_domain + 'user/emailRegisterTrial';
        return this.service.post(url, p);
      }

    createTrialRegiter(dto:any){
        const url = ConstantUrl.url_employee + 'public/trialRegister'
        return this.httpService.post(url,dto)
    }

    sendOtpSms(dto:any, token) {
        const url = ConstantUrl.url_employee + 'public/trialRegister/verify-otp'
        return this.service.post(url, dto, {
            token
        })
    }

    sendOtp(dto: any, token) {
        const url = ConstantUrl.url_employee + 'public/trialRegister/re-send-otp'
        return this.service.post(url, dto, {
            token
        })
    }
}
