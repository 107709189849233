export enum PrimaryTransactionStatus {
  CLOSE = 'CLOSE', // Chưa mở bán--> tạo ycdch
  ADMIN_APPROVED_TICKET = 'ADMIN_APPROVED_TICKET', // admin duyệt ycdch
  ADMIN_REJECTED_TICKET = 'ADMIN_REJECTED_TICKET', // admin từ chối ycdch
  CANCEL_REQUESTED = 'CANCEL_REQUESTED', // đề nghị hủy ycdch từ ĐVBH
  ADMIN_APPROVED_CANCEL_REQUESTED = 'ADMIN_APPROVED_CANCEL_REQUESTED', // gđs duyệt để nghị hủy
  ADMIN_CANCEL_REQUESTED = 'ADMIN_CANCEL_REQUESTED', // gđs từ chối để nghị hủy
  CS_APPROVED_CANCEL_REQUESTED = 'CS_APPROVED_CANCEL_REQUESTED', // dvkh duyệt để nghị hủy
  CS_CANCEL_REQUESTED = 'CS_CANCEL_REQUESTED', // dvkh từ chối để nghị hủy
  DEPOSIT_APPROVED = 'DEPOSIT_APPROVED', // kế toán đã xác nhận cũ, sau này ko dùng nữa
  DEPOSIT_REJECTED = 'DEPOSIT_REJECTED', // kế toán từ chối
  CS_APPROVED_TICKET = 'CS_APPROVED_TICKET', // DVKH xác nhận YCDCH
  CS_REJECTED_TICKET = 'CS_REJECTED_TICKET', // DVKH từ chối YCDCH
  BOOKING_APPROVED = 'BOOKING_APPROVED', // kế toán đã xác nhận
  PRIORITY_UPDATED = 'PRIORITY_UPDATED', // đã ráp ưu tiên
  COMING = 'COMING', // Bắt đầu giai đoạn 1
  PROCESSING = 'PROCESSING', // KH đã xác nhận
  PROCESSING_UNPAID = 'PROCESSING_UNPAID', // tạo cọc, nhưng kế toán chưa xác nhận
  WAITING_OTP = 'WAITING_OTP', // ĐVBH chờ OTP để xác nhận
  POS_CONFIRM = 'POS_CONFIRM', // ĐVBH xác nhận
  POS_REJECT = 'POS_REJECT', // ĐVBH từ chối
  POS_CONFIRM_UNPAID = 'POS_CONFIRM_UNPAID', // ĐVBH xác nhận cọc
  POS_REJECT_UNPAID = 'POS_REJECT_UNPAID', // ĐVBH từ chối 
  SA_CONFIRM_UNPAID = 'SA_CONFIRM_UNPAID', // Sale admin xác nhận cọc
  SA_REJECT_UNPAID = 'SA_REJECT_UNPAID', // Sale admin từ chối 
  SUCCESS = 'SUCCESS', // Thành công, DVKH xác nhận
  UNSUCCESS = 'UNSUCCESS', //Không thành công,  DVKH từ chối
  REFUNDED = 'REFUNDED', //Không thành công,  đã hoàn tiền
  TICKET_CANCELLED = 'TICKET_CANCELLED', // YCDCH BỊ cancel
  LOCK_CONFIRM = 'LOCK_CONFIRM', // Lock SP thành công, cần bổ sung thông tin.
  LOCK_CONFIRM_LOCK = 'LOCK_CONFIRM_LOCK',
  CS_REJECTED_ESCROW = 'CS_REJECTED_ESCROW',
  POS_CONFIRM_LOCK = 'POS_CONFIRM_LOCK',
  PROCESSING_LOCK = 'PROCESSING_LOCK',
  WAITING_SMS = 'WAITING_SMS',
  WAITING_SMS_LOCK = 'WAITING_SMS_LOCK',
}

export const PrimaryTransactionStatusArray = [
  PrimaryTransactionStatus.CLOSE,
  PrimaryTransactionStatus.ADMIN_APPROVED_TICKET, // admin duyệt ycdch
  PrimaryTransactionStatus.CANCEL_REQUESTED, // đề nghị hủy ycdch từ ĐVBH
  PrimaryTransactionStatus.ADMIN_APPROVED_CANCEL_REQUESTED, // gđs duyệt để nghị hủy
  PrimaryTransactionStatus.ADMIN_CANCEL_REQUESTED, // gđs từ chối để nghị hủy
  PrimaryTransactionStatus.CS_APPROVED_CANCEL_REQUESTED, // dvkh duyệt để nghị hủy
  PrimaryTransactionStatus.CS_CANCEL_REQUESTED, // dvkh từ chối để nghị hủy
  PrimaryTransactionStatus.DEPOSIT_APPROVED, // kế toán đã xác nhận
  PrimaryTransactionStatus.DEPOSIT_REJECTED, // kế toán từ chối
  PrimaryTransactionStatus.CS_APPROVED_TICKET, // DVKH xác nhận YCDCH
  PrimaryTransactionStatus.CS_REJECTED_TICKET, // DVKH từ chối YCDCH
  PrimaryTransactionStatus.COMING, // Bắt đầu giai đoạn 1
  PrimaryTransactionStatus.PROCESSING, // KH đã xác nhận
  PrimaryTransactionStatus.POS_CONFIRM, // ĐVBH xác nhận
  PrimaryTransactionStatus.POS_REJECT, // ĐVBH từ chối
  PrimaryTransactionStatus.SUCCESS, // Thành công, DVKH xác nhận
  PrimaryTransactionStatus.UNSUCCESS, //Không thành công,PrimaryTransactionStatus.DVKH từ chối
  PrimaryTransactionStatus.REFUNDED, //Không thành công,PrimaryTransactionStatus.đã hoàn tiền
  PrimaryTransactionStatus.TICKET_CANCELLED, // YCDCH BỊ cancel
]
export enum PrimaryTransactionAction {
  EXTEND_TIME_BOOKING = 'EXTEND_TIME_BOOKING', // Chưa mở bán
}
