import { Component, ViewChild, OnInit, OnDestroy, Inject } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { SecurityService } from './shared/services/security.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationEnd } from '@angular/router';
import { VoiceCallService } from './shared/services/voicecall.service';
import { OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { oauthConfig } from './oauth.config';
import { UserV2Service } from './shared/services/common/user-v2.service';
import { CUser } from './api-models';
import { StorageService } from './shared/services';
import { CommonService } from './shared/services/common';
import { environment } from 'environments/environment';
declare let ga: Function;
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material';
import { WarningSystemDialog } from './shared/components/warning-system/warning-system-dialog.component';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    Authenticated = false;
    subscription: Subscription;
    sub: Subscription;

    public settings: Settings;
    user: CUser = null;
    isHide: boolean = false;
    constructor(public appSettings: AppSettings,
        private securityService: SecurityService,
        private configurationService: ConfigurationService,
        private router: Router,
        private voiceCallService: VoiceCallService,
        private oauthService: OAuthService,
        private userV2Service: UserV2Service,
        private storageService: StorageService,
        private commonService: CommonService,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document
    ) {

        this.loadExternalStyles('./assets/wcp-fontawesome/css/wcp-fontawesome.css');
        this.loadExternalStyles('./assets/contentbuilder/contentbuilder.css');
        this.loadExternalStyles('./assets/imagemap/image-map-pro.css');
        this.loadExternalScript('./assets/minimalist-blocks/content.js');
        this.loadExternalScript('./assets/wcp-fontawesome/js/wcp-fontawesome.js');
        this.loadExternalScript('./assets/contentbuilder/contentbuilder.min.js');

        this.settings = this.appSettings.settings;

        this.Authenticated = this.securityService.IsAuthorized;
        this.configureWithoutDiscovery();
        // Automatically load user profile
        this.oauthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(_ => {
                this.oauthService.loadUserProfile();
            });
        this.userV2Service.user$.subscribe((user: CUser) => {


          if (user.id) {
            this.user = user;
            this.storageService.storeLocal('stringeeChatCusEmail', user.email);
            this.storageService.storeLocal('stringeeChatCusName', user.name);
          } else {
            this.storageService.removeLocal('stringeeChatCusEmail');
            this.storageService.removeLocal('stringeeChatCusName');
          }
        });
    }

    ngOnInit() {

        //   this.socketService.load();
        this.configurationService.load();

        let chatDiv = '.chat-container div';
        $(window).bind('scroll', function () {
            $(chatDiv).removeClass('ani');
        });
        $(document).on('click', '.chat-button', function () {
            $(chatDiv).toggleClass('ani');
        })
        this.isHide = false;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let searchString = `/login`;
                if (!event.urlAfterRedirects.includes(searchString)) {
                    if(!this.isHide) {
                        this.isHide = true;
                        const isHide = this.storageService.retrieveLocal('isHideWarning');
                        if(!isHide) {
                            this.showMaintenanceSystemDialog();
                        }
                    }
                }
            }
        });
        window.onbeforeunload = (e) => {
            e = e || window.event;

            if (e) {
                if (VoiceCallService.isInCalling) {
                    // For IE and Firefox prior to version 4
                    e.returnValue = 'true';
                    // For Safari
                    return 'true';
                }
            }
        };

        window.onunload = (e) => {
            if (VoiceCallService.isInCalling) {
                this.voiceCallService.hangupCall();
            }
        };
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }

    showMaintenanceSystemDialog() {
        this.userV2Service.getWarningSystem().subscribe(res => {
            if(res && res[0] && res[0].isShow) {
                this.dialog.open(WarningSystemDialog, {
                    width: '585px',
                    data: {
                        content: res[0].warningContent,
                        icon: res[0].warningIcon,
                    }
                });
            }
        })
    }

    private configureWithoutDiscovery() {
        this.oauthService.configure(oauthConfig);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.tryLogin();
    }

    private loadExternalStyles(styleUrl: string) {
        return new Promise(resolve => {
          const head = this.document.getElementsByTagName('head')[0];
          const style = this.document.createElement('link');
          style.rel = 'stylesheet';
          style.href = `${styleUrl}`;
          style.onload = resolve;
          head.appendChild(style);
        })
      }

      private loadExternalScript(scriptUrl: string) {
        return new Promise(resolve => {
          const scriptElement = document.createElement('script');
          scriptElement.src = scriptUrl;
          scriptElement.onload = resolve;
          document.body.appendChild(scriptElement);
        });
      }
}
