import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Constant } from '../../constant/constant';
import { StorageService } from '../../services';
import { Address } from '../../models/address.model';
import { HANH_CHINH_VN } from '../../constant/hanhchinhvn';

@Component({
  selector: 'app-input-interested-area-app-select',
  templateUrl: './input-interested-area-app-select.component.html',
  styleUrls: ['./input-interested-area-app-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputInterestedAreaAppSelectComponent),
      multi: true,
    }
  ]
})
export class InputInterestedAreaAppSelectComponent implements ControlValueAccessor, OnInit {
  @Input() isRequired: boolean;
  isDisabled: boolean;
  Constant = Constant;
  provinces: Province[] = [];
  districts: District[] = [];

  public value: Address = new Address();
  mainForm: FormGroup = null;

  public get valid() {
    this.markAsTouched(this.mainForm);
    return this.mainForm.valid;
  }

  constructor(
    public formBuilder: FormBuilder,
    public storageService: StorageService
  ) { }

  ngOnInit() {
    this.getMasterDataProvince();
    // Init form
    this.initForm();
  }

  initForm() {
    this.mainForm = this.formBuilder.group({
      province: [{ value: this.value.province, disabled: this.isDisabled }, this.isRequired ? Validators.required : null],
      district: [{ value: this.value.district, disabled: this.isDisabled }, this.isRequired ? Validators.required : null]
    });
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  getMasterDataProvince() {
    const data = JSON.parse(HANH_CHINH_VN);
    this.provinces = data;
  }

  patchFormValue(data: Address, formData: any): Address {
    data.province = formData.province;
    data.district = formData.district;
    return data;
  }

  onProvinceChange(event: any) {
    const currentProvince: Province = this.provinces.find((province: Province) => province.n === event);
    this.districts = currentProvince ? currentProvince.d : [];
    this.mainForm.patchValue({ district: null });
    const value = new Address(this.mainForm.getRawValue());
    this.onChange(value);
    this.onTouched();
  }

  onDistrictChange(event: any) {
    const value = new Address(this.mainForm.getRawValue());
    this.onChange(value);
    this.onTouched();
  }

  // ControlValueAccessor Implementation
  public onChange = (newVal: Address) => { };
  public onTouched = (_?: any) => { };

  public patchValue(data: Address) {
    this.value = data;
    this.mainForm.patchValue(this.value);
  }

  public reset(data: Address) {
    this.value = data;
    this.mainForm.patchValue(this.value);
    this.mainForm.markAsUntouched();
  }

  public markAsDirty() {
    this.markAsTouched(this.mainForm);
  }

  public getValue() {
    return this.patchFormValue(this.value, this.mainForm.getRawValue());
  }

  public setDisable(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.mainForm.get('province').disable();
      this.mainForm.get('district').disable();
    } else {
      this.mainForm.get('province').enable();
      this.mainForm.get('district').enable();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.setDisable(isDisabled);
  }

}

interface Province {
  n: string;  // Name
  d: District[]; // Districts
}

interface District {
  n: string;  // Name
  w: string[];  // Wards
}
