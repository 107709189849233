import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


export interface ConfirmData {
  title: string;
  message: string;
  execute: boolean;
}

@Component({
  selector: 'confirm-popup-upgrade',
  templateUrl: 'confirm-popup-upgrade-trial.component.html',
  styleUrls: ['confirm-popup-upgrade-trial.component.scss']
})
export class ConfirmPopupUpgrade implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupUpgrade>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) { }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  execute() {
    this.dialogRef.close(true);
  }
}