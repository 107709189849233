import { Component, OnInit, Input, Output, EventEmitter, forwardRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxButtonComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxButtonComponent),
      multi: true
    }
  ]
})
export class CheckboxButtonComponent implements OnInit {

  @Input() value: any;
  @Input() isDisabled: boolean = false;
  @Input() set checked(val: boolean) {
    this.isCheckedNgModel = val || false;
  }
  @Output() outChange: EventEmitter<boolean> = new EventEmitter();


  public isCheckedNgModel: boolean;
  private _changeCb: any;
  private _blurCb: any;

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(value: any): void {
    this.isCheckedNgModel = value;
  }

  registerOnChange(fn: any) {
    this._changeCb = fn;
  }

  registerOnTouched(fn: any) {
    this._blurCb = fn;
  }

  callChangeCb() {
    if (this._changeCb) {
      this._changeCb(this.isCheckedNgModel);
    }
  }

  callBlur() {
    if (this._blurCb) {
      this._blurCb();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(c: FormControl) {
    return c.valid ? null : {
      type: {
        valid: false,
        actual: c.value
      }
    };
  }

  onChangeCheckBox() {
    this.isCheckedNgModel = !this.isCheckedNgModel;
    this.outChange.emit(this.isCheckedNgModel);
    this.callChangeCb();
  }

  setCheckedCheckbox(value: any) {
    value = value || false;
    this.isCheckedNgModel = value;
  }

}
