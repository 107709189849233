import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Constant } from 'app/shared/constant/constant';

@Component({
  selector: 'app-surveys-feedback',
  templateUrl: './app-surveys-feedback.component.html',
  styleUrls: ['./app-surveys-feedback.component.scss']
})
export class SurveysFeedbackComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder
  ) {
  }
  dynamicForm: FormGroup;
  Constant = Constant;

  @Input() lstSurveys: any[] = [];
  @Input() disabledForm: boolean;

  lst: any[] = [
    { id: 1, nameOption: 'h' },
    { id: 2, nameOption: '6' }
  ]

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
      surveys: new FormArray([]),
      subSurveys: new FormArray([])
    });
    // Init form
    this.initForm();
  }

  ngOnChanges() {
    if(this.lstSurveys) {
      this.initForm()
    }
  }

  initForm() {
    if (this.lstSurveys.length > 0) {
      const countShow = this.lstSurveys.filter((survey: any) => {
        if (survey.showStt) {
          return false;
        }
        return true;
      }).length;
      this.lstSurveys.forEach((item: any) => {
        let sttSurvey = item.id;
          if (countShow > 0) {
            sttSurvey = sttSurvey - countShow;
          }
        if (item.type === 'text' || item.type === 'paragraph') {
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer, Validators.required],
            subSurvey: [],
            showStt: [item.showStt],
            key: [sttSurvey ]
          }));
        } else if (item.type === 'file') {
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer],
            subSurvey: [],
            showStt: [item.showStt],
            key: [sttSurvey ]
          }));
        } else if (item.type === 'checkbox') {
          let lstSubSurvey = [];
          if (item.subSurvey.length > 0) {
            item.subSurvey.forEach((subSurvey: any) => {
              lstSubSurvey.push(this.formBuilder.group({
                idSubSurvey: [subSurvey.idSubSurvey, Validators.required],
                checked: [subSurvey.checked],
                nameOption: [subSurvey.nameOption, [Validators.required]],
              }))
            })
          }
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer ? item.answer : []], 
            subSurvey: [lstSubSurvey],
            showStt: [item.showStt],
            key: [sttSurvey ]
          }));
        } else if (item.type === 'radio') {
          let lstSubSurvey = [];
          if (item.subSurvey.length > 0) {
            item.subSurvey.forEach((subSurvey: any) => {
              lstSubSurvey.push(this.formBuilder.group({
                idSubSurvey: [subSurvey.idSubSurvey, Validators.required],
                checked: [subSurvey.checked],
                nameOption: [subSurvey.nameOption, [Validators.required]],
              }))
            })
          }
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer ? item.answer : null, Validators.required],
            subSurvey: [lstSubSurvey],
            showStt: [item.showStt],
            key: [sttSurvey ]
          }));
        } else if (item.type === 'dropdown') {
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer, Validators.required],
            subSurvey: [item.subSurvey],
            showStt: [item.showStt],
            key: [sttSurvey ]
          }));
        }
      })
    }
  }

  get f() { return this.dynamicForm.controls; }
  get surveys() { return this.f.surveys as FormArray; }

  getLstItemDropdown(id: any) {
    let lstItem: any[] = [];
    let findSurvey = this.lstSurveys.find((item: any) => item.id === id);
    if (findSurvey && findSurvey.subSurvey.length > 0) {
      findSurvey.subSurvey.forEach((subSurvey: any) => {
        let itemSelect = {
          id: subSurvey.idSubSurvey,
          name: subSurvey.nameOption,
        }
        lstItem.push(itemSelect);
      });
    }
    return lstItem;
  }

  checkValidateFormSurvey() {
    let checkValidate: boolean = false;
    this.markAsTouched(this.surveys);
    this.surveys.controls.forEach((survey: any, index: any) => {
      let check = this.issurveyControlInvalid(index);
      if (check) {
        checkValidate = true;
      }
    });
    return checkValidate;
  }

  public issurveyControlInvalid(index): boolean {
    return this.surveys.controls[index].get('answer').invalid;
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  submitData() {
    let lstSurvey: any[] = [];
    if (this.surveys.controls.length > 0) {
      this.surveys.controls.forEach((survey: any) => {
        let dataSurvey = survey.value;
        let surveyConfig: any = {};
        let dataSubSurvey: any[] = [];
        if (dataSurvey.type === 'text' || dataSurvey.type === 'paragraph') {
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = dataSurvey.answer;
          surveyConfig.subSurvey = [];
          surveyConfig.showStt = dataSurvey.showStt;
        } else if (dataSurvey.type === 'file') {
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = dataSurvey.answer;
          surveyConfig.subSurvey = [];
          surveyConfig.showStt = dataSurvey.showStt;
        } else if (dataSurvey.type === 'radio' || dataSurvey.type === 'checkbox') {
          if (survey.value.subSurvey.length > 0) {
            survey.value.subSurvey.forEach((subSurvey: any) => {
              let data = {
                idSubSurvey: subSurvey.value.idSubSurvey,
                checked: subSurvey.value.checked,
                nameOption: subSurvey.value.nameOption
              }
              dataSubSurvey.push(data);
            });
          }
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          if (dataSurvey.type === 'radio') {
            surveyConfig.answer = dataSurvey.answer;
          } else {
            surveyConfig.answer = dataSurvey.answer;
          }
          surveyConfig.subSurvey = dataSubSurvey;
          surveyConfig.showStt = dataSurvey.showStt;
        } else if (dataSurvey.type === 'dropdown') {
          if (survey.value.subSurvey.length > 0) {
            survey.value.subSurvey.forEach((subSurvey: any) => {
              let data = {
                idSubSurvey: subSurvey.idSubSurvey,
                checked: subSurvey.checked,
                nameOption: subSurvey.nameOption
              }
              dataSubSurvey.push(data);
            });
          }
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = dataSurvey.answer;
          surveyConfig.subSurvey = dataSubSurvey;
          surveyConfig.showStt = dataSurvey.showStt;
        }
        lstSurvey.push(surveyConfig);
      })
    }
    return lstSurvey;
  }

}
