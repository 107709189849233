import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CDocument } from 'app/api-models/c-document';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { EUploadType } from 'app/enums/e-upload-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertMessageService } from 'app/shared/services/alert-message.service';

@Component({
  selector: 'app-upload-image-product',
  templateUrl: './upload-image-product.component.html',
  styleUrls: ['./upload-image-product.component.scss']
})
export class UploadImageProductComponent implements OnInit {

  @Input() titleProperty: string = '';
  @Input() iconProperty: string = '';
  @Input() disabled: boolean = false;

  @Input() maxSize: number; // max byte 1 tấm hình
  @Input() maxSizeUpload: number; // max byte 1 lần upload
  @Input() maxUploadFile: number; // maximum một lần upload

  @Input() documents: CDocument[] = [];
  @Output() documentsChange: EventEmitter<CDocument[]> = new EventEmitter();

  constructor(
    private alertMessageService: AlertMessageService,
    private uploadFileService: UploadFileService
  ) { }

  ngOnInit() {
  }

  onSelectedFile(event: Event) {
    const obFile = event.target['files'];
    const selectedFiles: File[] = Object.keys(obFile).map((elfile) => obFile[elfile]);
    const selectedDocuments: CDocument[] = new CDocument().decodeFileList(selectedFiles);

    if (this.isPermissionUpload(selectedDocuments)) {
      event.target['value'] = null;
      this.onUpload(selectedDocuments, event);
    }
  }

  onUpload(selectedDocuments: CDocument[], event: Event) {
    this.documents = this.documents.concat(selectedDocuments);
    this.uploadFileService.uploadFileV2(
      selectedDocuments.map((doc: CDocument) => doc.file),
      EUploadType.Property
    ).subscribe((resDocuments: CDocument[]) => {
      event.target['value'] = null;
      this.documents = this.documents.map((elDocument: CDocument) => (elDocument.file && resDocuments.length) ? resDocuments.shift() : elDocument);
      this.documentsChange.emit(this.documents);
    },
      (err: HttpErrorResponse) => {
        event.target['value'] = null;
        this.documents = this.documents.filter((elDocument: CDocument) => !selectedDocuments.includes(elDocument));
        const errors = err.error.errors;
        if (errors['file.invalid.input.error']) {
          this.alertMessageService.error(errors['file.invalid.input.error']);
        }

      });
  }

  onRemove(indexDocument: number) {
    this.documents.splice(indexDocument, 1);
    this.documentsChange.emit(this.documents);
  }

  private isPermissionUpload(selectedDocuments: CDocument[]): boolean {
    const totalSize = selectedDocuments.reduce((previousValue: number, currentValue: CDocument) => (previousValue + currentValue.size), 0);

    if (this.maxSize && selectedDocuments.some((el: CDocument) => el.size > this.maxSize)) {
      this.alertMessageService.error(`Size của một tấm hình vượt quá giới hạn. Vui lòng kiểm tra lại hình ảnh của bạn.`);
      return false;
    }

    if (this.maxSizeUpload && +this.maxSizeUpload < totalSize) {
      this.alertMessageService.error(`Tổng size vượt quá giới hạn. Vui lòng kiểm tra lại hình ảnh của bạn.`);
      return false;
    }

    if (this.maxUploadFile && selectedDocuments.length > this.maxUploadFile) {
      this.alertMessageService.error(`Số lượng hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.`);
      return false;
    }

    return true;
  }

}
