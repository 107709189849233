import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {
  public message: string;
  public isMoveSalesProgram: boolean = false;
  public salesProgramName: string;
  public btnTextConfirm: string = 'Xác nhận';
  public btnTextCancel: string = 'Huỷ';
  public isChecked: boolean = false;
  public textCheckbox: any = null;
  public arrCheckbox: any[] = [];

  public content: any;
  public title: any;
  public imageUrl: any;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.message = data.message;
    this.isMoveSalesProgram = data.isMoveSalesProgram;
    this.salesProgramName = data.salesProgramName;
    this.btnTextConfirm = data.textConfirm ? data.textConfirm : this.btnTextConfirm;
    this.btnTextCancel = data.textCancel ? data.textCancel : this.btnTextCancel;
    this.textCheckbox = data.textCheckbox ? data.textCheckbox : null;
    this.arrCheckbox = data.arrCheckbox ? data.arrCheckbox : [];
    this.content = data.content;
    this.title = data.title;
    this.imageUrl = data.imageUrl;
  }

  confirm() {
    let result: any = true;
    if (this.textCheckbox || this.arrCheckbox) {
      result = { checkbox: this.isChecked, arrCheckbox: this.arrCheckbox };
    }
    return this.dialogRef.close(result);
  }

  changeCheckbox(event) {
    this.arrCheckbox.forEach(e => {
        if (e.value !== event.value) {
            e.isChecked = false;
        }
    })
  }

  cancel() {
    this.dialogRef.close();
  }

}
