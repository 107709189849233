import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'decimal'})
export class TransformDecimal implements PipeTransform {
  transform(value: number, type) {
    let result = undefined;
    switch (type) {
      case '1': 
        result = this.decimalAdjust('round', value, -1);
      break;
      case '2': 
        result = this.decimalAdjust('round', value, -2);
      break;
      default:
        result = this.decimalAdjust('round', value, -3);
      break;
    }
    return result;
  }
  decimalAdjust(type, value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math[type](value * valueMul) / valueMul;
  }
  summary(price) {
    let text = '';
    if (price >= 1000*1000*1000) {
      text = (price/(1000*1000*1000)).toString().substring(0, 4) + ' Tỷ'
    } else if (price >= 1000*1000) {
        text = (price/(1000*1000)).toString().substring(0, 4) + ' Triệu'
    } else if (price >= 1000) {
      text = (price/(1000)).toString().substring(0, 4) + ' Nghìn'
    }
    return text;
  }
}