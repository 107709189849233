import { Injectable, isDevMode } from '@angular/core';
import { Http, Response, RequestOptionsArgs, RequestMethod, Headers } from '@angular/http';
import { IConfiguration } from '../models/configuration.model';
import { StorageService } from './storage.service';

import 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';



@Injectable()
export class ConfigurationService {
    serverSettings: IConfiguration;
    // observable that is fired when settings are loaded from server
    private settingsLoadedSource = new Subject();
    settingsLoaded$ = this.settingsLoadedSource.asObservable();
    isReady = false;

    constructor(private http: Http, private storageService: StorageService) { }

    load() {
        // const baseURI = document.baseURI.endsWith('/') ? document.baseURI : `${document.baseURI}/`;
        // // const url = `${baseURI}Home/Configuration`;
        // let url = `${baseURI}assets/config/`;
        // let configurationFileName = 'config.json';

        // if (!isDevMode()) {
        //     configurationFileName = 'config.prod.json';
        // }
        // url = url + configurationFileName;

        // this.http.get(url).subscribe((response: Response) => {
        //     this.serverSettings = response.json();

        //     this.storageService.store('identityUrl', this.serverSettings.systemApis.identityUrl);
        //     this.storageService.store('notifierUrl', this.serverSettings.systemApis.notifierUrl);
        //     // =====
        //     this.storageService.store('categoryUrl', this.serverSettings.businessApis.catalogUrl);


        //     this.isReady = true;
        //     this.settingsLoadedSource.next(this.isReady);
        // });
    }

}
