import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SessionStorageService } from 'ng2-webstorage';
import { AuthorizeService } from '../services/common/authorize.service';
import { AuthenticateService } from './authenticate.service';
import { CanActiveAccessService } from './can-active-access.service';
import { StateStorageService } from './state-storage.service';
import { CookieUtilsService } from './cookie.service';
import { AuthenticateUserService } from './authenticate-user.service';
@NgModule({
    imports: [
    ],
    declarations: [
    ],
    entryComponents: [
    ],
    providers: [
        AuthorizeService,
        AuthenticateService,
        SessionStorageService,
        CanActiveAccessService,
        StateStorageService,
        CookieUtilsService,
        AuthenticateUserService
    ],
    schemas: []
})
export class CPAuthenticateModule {}
