import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'memberFilter',
    pure: false
})
export class MemberFilterPipe implements PipeTransform {
    transform(items, q) {
        if (q) {
            return items.filter(items => items.name && items.name.match(new RegExp(q, 'i')));
        } else {
            return items;
        }
    }
}
