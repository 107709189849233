import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { oauthConfig } from 'app/oauth.config';
import { ConstantUrl } from 'app/shared/constant/url';
import { HttpService } from '../http.service';
import { MatDialog } from '@angular/material';
import { ConfirmPopup } from 'app/shared/components/confirm-popup/confirm-popup';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import { map } from 'rxjs/operators';
import { DialogMessageImageComponent } from 'app/shared/components/dialog-message-image/dialog-message-image.component';
import { UserV2Service } from './user-v2.service';
import moment = require('moment');


@Injectable()
export class ErpService {
  url_erp: string = ConstantUrl.url_erp + 'directrouter/index';
  url : string = ConstantUrl.url_erp;
  oauthConfig : any = oauthConfig
  constructor(
    private oauthService: OAuthService,
    private httpService: HttpService,
    private dialog: MatDialog,
    private userV2Service: UserV2Service,
  ) {
    this.userV2Service.user$.subscribe((user) => {
      if(user.pos.erpConfig){
        this.setConfig(user.pos.erpConfig);
      }
  });
  }
  public setConfig(config :{url: string, config: any}){
    this.url_erp = config.url + 'directrouter/index';
    this.url = config.url;
    this.oauthConfig = config.config;
  }
  public post(data) {
    let config: any =
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
      },
    };
    return this.httpService.postErp(this.url_erp, data, config).pipe( map((res: any) => {
      if (res.result && !res.result.success && (res.result.title == 'Error' || res.result.title == 'Failure')) {
        const dialogRef = this.dialog.open(DialogMessageImageComponent, {
          width: '550px',
          data: {
            imageUrl: 'assets/img/dxres/warning.svg',
            message: res.result.message,
            title: 'Lỗi ERP'
          }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
        });
      }
      if (res.result && res.result.hasCmd) {
        const hasCmd = { "action": "FrmFdProcess", "method": "runCmd", "data": [data.data[0]], "type": "rpc", "tid": 69 };
        this.httpService.post(this.url_erp, hasCmd, config).subscribe();
      }
      return res;
    }));
  }

  public get(url) {
     return this.httpService.getERP(url);
  }

  async getAccessToken() {
    if (!this.oauthService.hasValidAccessToken()) {
      const config = this.oauthConfig;
      config.redirectUri = window.location.href;
      config.silentRefreshRedirectUri = window.location.href;
      this.oauthService.configure(config);
      await this.oauthService.tryLogin();
      await this.oauthService.initLoginFlow('/some-state;p1=1;p2=2?p3=3&p4=4');
      return await this.oauthService.getAccessToken();
    } else {
      return await this.oauthService.getAccessToken();
    }
  }

  getToken() {
    return this.oauthService.getAccessToken();
  }
  checkTokenErp() {
    if (!this.oauthService.hasValidAccessToken()) {
      const dialogRef = this.dialog.open(ConfirmPopup, {
        width: '650px',
        data: {
          title: 'Bạn chưa đăng nhập ERP, Vui lòng đăng nhập để tiếp tục',
          isReason: false,
          textCancel: 'Hủy',
          textOk: 'Đăng nhập',
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.execute) {
          this.getAccessToken().then(res => {
            return true;
          })
        } else {
          return false;
        }
      });
    } else {
      return true;
    }
}
  mapCustomerErpFromTicket(ticket,employeeCode){
    const birthday = ticket.customer.info.birthday ? new Date(ticket.customer.info.birthday) : null;
    let district = null;
    const province = JSON.parse(HANH_CHINH_VN).find(item => item.n === ticket.customer.info.rootAddress.province);
    if(province){
      district = province.d.find(item => item.n === ticket.customer.info.rootAddress.district);
    }
    return {
      "idcard": ticket.customer.identities[0].value,
      "telephone": ticket.customer.personalInfo.phone,
      "name": ticket.customer.personalInfo.name,
      "birthday": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getDate() : null,
      "birthmonth": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getMonth() + 1 : null,
      "birthyear": ticket.customer.info.onlyYear ? parseInt(ticket.customer.info.birthdayYear) : birthday ? birthday.getFullYear() : null,
      "gender": ticket.customer.info.gender === 'male' ? 'M' : 'F',
      "employee": employeeCode,
      "address": ticket.customer.info.rootAddress.fullAddress,
      "email": ticket.customer.personalInfo.email,
      "comments": ticket.customer.info.address.fullAddress,
      "ward": ticket.customer.info.rootAddress.ward,
      "district": district ? district.codeErp : '',
      "city": province ? province.codeErp : '',
      "country": ticket.customer.info.rootAddress.country === 'Việt Nam' ? "VN" : '',
      "issueddate": new Date(ticket.customer.identities[0].date),
      "issuedplace": ticket.customer.identities[0].place,
      "contactaddress": ticket.customer.info.address.fullAddress,
    }
  }

  mapCustomerCheckUpdateForm(ticket,maKH) {
    const birthday = ticket.customer.info.birthday ? new Date(ticket.customer.info.birthday) : null;
    let district = null;
    const province = JSON.parse(HANH_CHINH_VN).find(item => item.n === ticket.customer.info.rootAddress.province);
    if(province){
      district = province.d.find(item => item.n === ticket.customer.info.rootAddress.district);
    }
    return {
          "prospect": maKH,
          "name": ticket.customer.personalInfo.name ,
          "telephone": ticket.customer.personalInfo.phone,
          "birthday": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getDate() : null,
          "birthmonth": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getMonth() + 1 : null,
          "birthyear": ticket.customer.info.onlyYear ? parseInt(ticket.customer.info.birthdayYear) : birthday ? birthday.getFullYear() : null,
          "idcard": ticket.customer.identities[0].value,
          "gender": ticket.customer.info.gender === 'male' ? 'M' : 'F',
          "ward": ticket.customer.info.rootAddress.ward,
          "district": district ? district.codeErp : '',
          "city": province ? province.codeErp : '',
          "country": ticket.customer.info.rootAddress.country === 'Việt Nam' ? "VN" : '',
          "address": ticket.customer.info.rootAddress.fullAddress,
          "email": ticket.customer.personalInfo.email,
          "comments": ticket.customer.info.address.fullAddress,
          "contactaddress": ticket.customer.info.address.fullAddress,
    }
  }

  mapCustomerUpdateForm(ticket,maKh, employeeCode) {
    const birthday = ticket.customer.info.birthday ? new Date(ticket.customer.info.birthday) : null;
    let district = null;
    const province = JSON.parse(HANH_CHINH_VN).find(item => item.n === ticket.customer.info.rootAddress.province);
    if(province){
      district = province.d.find(item => item.n === ticket.customer.info.rootAddress.district);
    }
    return {
      "prospect": maKh,
      "idcard": ticket.customer.identities[0].value,
      "telephone": ticket.customer.personalInfo.phone,
      "name": ticket.customer.personalInfo.name,
      "birthday": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getDate() : null,
      "birthmonth": ticket.customer.info.onlyYear ? 0 : birthday ? birthday.getMonth() + 1 : null,
      "birthyear": ticket.customer.info.onlyYear ? parseInt(ticket.customer.info.birthdayYear) : birthday ? birthday.getFullYear() : null,
      "gender": ticket.customer.info.gender === 'male' ? 'M' : 'F',
      "employee": employeeCode,
      "issuedplace": ticket.customer.identities[0].place,
      "ward": ticket.customer.info.rootAddress.ward,
      "district": district ? district.codeErp : '',
      "city": province ? province.codeErp: '',
      "country": ticket.customer.info.rootAddress.country === 'Việt Nam' ? "VN" : '',
      "address": ticket.customer.info.rootAddress.fullAddress,
      "comments": ticket.customer.info.address.fullAddress,
      "email": ticket.customer.personalInfo.email,
      "contactaddress": ticket.customer.info.address.fullAddress,
    }
  }

  mapCreateYCDCHForm(ticketCode, campaign, maKh, orgchartid, employeeErp, createdDate) {
    return {
      "status1": "YCDCH",
      "customer": maKh,
      "bookingdate": createdDate ? moment(createdDate).add(7, 'hours') : moment().add(7, 'hours'),
      "isnew": "Y",
      "campaign": campaign.campaign,
      "project": campaign.project,
      "investor": campaign.investor,
      "bookingvalue": campaign.bookingvalue,
      "employee": employeeErp.employee,
      "site": employeeErp.site,
      "formid": "pctbooking6",
      "orgchartid": orgchartid ? orgchartid : '',
      "employee1": employeeErp.site,
      "remarks": ticketCode,
    }
  }
  mapCreateYCDCOForm(ticketCode, campaign, maKh, orgchartid, employeeErp, createdDate, property = '', resGetFrmPmPriceBook) {
    return {
      "status1": "YCDCO",
      "customer": maKh,
      "bookingdate": createdDate ? moment(createdDate).add(7, 'hours') : moment().add(7, 'hours'),
      "isnew": "Y",
      "campaign": campaign.campaign,
      "project": campaign.project,
      "investor": campaign.investor,
      "bookingvalue": campaign.bookingvalue,
      "employee": employeeErp.employee,
      "site": employeeErp.site,
      "formid": "pctbooking6",
      "orgchartid": orgchartid ? orgchartid : '',
      "employee1": employeeErp.site,
      "remarks": ticketCode,
      "property": property,
      "pricebookid": resGetFrmPmPriceBook.pricebookid,
      "pymtterm": resGetFrmPmPriceBook.pymtterm,
    }
  }
  findYCFormTicketCode(ticketCode, campaign) {
    return {
      "action": "FrmFdUserForm",
      "method": "get",
      "data": [{
        "limit": 25,
        "formid": "pctbooking2",
        "filters":
          [{
            "field": "remarks",
            "op": "eq",
            "value": ticketCode,
            "type": "string"
          },
          {
            "field": "campaign",
            "op": "eq",
            "value": campaign,
            "type": "string"
          }],
        "page": 1,
        "start": 0,
        "sort":
          [{
            "property": "systemno",
            "direction": "DESC"
          }]
      }],
      "type": "rpc",
      "tid": 213
    }
  }
  getPropertyByCode(code, prospect, createdDate) {
    return {
      "action": "FrmPmProperty",
      "method": "getABlockProject",
      "data": [{
        "limit": 15,
        "project": prospect.project,
        "bookingdate": createdDate ? moment(createdDate).add(7, 'hours') : moment().add(7, 'hours'),
        "filters":
          [{
            "field": "property",
            "op": "eq",
            "value": code,
            "type": "string"
          }],
        "page": 1,
        "start": 0
      }],
      "type": "rpc",
      "tid": 123
    };
  }
  findCustomerOfficial(code) {
    return {
      "action": "FrmCsNameAddress",
      "method": "get",
      "data": [
        {
          "limit": 25,
          "filters":
            [{
              "field": "prospect",
              "op": "eq", "value": code,
              "type": "string"
            }],
          "page": 1,
          "start": 0
        }],
      "type": "rpc",
      "tid": 280
    };
  }
  convertToCustomer(prospect) {
    return {
      "action": "FrmCrProspect",
      "method": "convertToCustomer",
      "data": [
        { ...prospect }
      ],
      "type": "rpc",
      "tid": 359
    };
  }
  getCustomerOfficial(nadcode) {
    return {
      "action": "FrmCsNameAddress",
      "method": "get",
      "data":
        [{
          nadcode
        }],
      "type": "rpc",
      "tid": 293
    };
  }

  getFrmPmPriceBook(property) {
    return {
      "action": "FrmPmPriceBook",
      "method": "get",
      "data":
        [{
          property
        }],
      "type": "rpc",
      "tid": 267
    };
  }

  getCustomerByCode(code: string){
    const data = {
      "action":"FrmCrProspect",
      "method":"getE",
      "data":[
         {
            "limit":250,
            "prospect":-1,
            // "employee": employeeCode,
            "filters":[
               {
                  "field":"prospect",
                  "op":"eq",
                  "value":code,
                  "type":"string"
               }
            ],
            "page":1,
            "start":0
         }
      ],
      "type":"rpc",
      "tid":56
   }
   return this.post(data);
  }
  getProspectByCode(code: string) {
    return {
      "action": "FrmCrProspect",
      "method": "getE",
      "data": [
        {
          "limit": 250,
          "prospect": -1,
          "filters": [
            {
              "field": "prospect",
              "op": "eq",
              "value": code,
              "type": "string"
            }
          ],
          "page": 1,
          "start": 0
        }
      ],
      "type": "rpc",
      "tid": 56
    }
  }
  // Get All service
  mapSyncData(prospect,campaign,employee) {
    return [
      {
        "action": "FrmHrOrgChartHis",
        "method": "get",
        "data": [
          {
            "employee": employee,
            "filters": [

            ],
            "isShortData": "Y"
          }
        ],
        "type": "rpc",
        "tid": 501
      },
      {
        "action": "FrmCrProspect",
        "method": "get",
        "data": [
          {
            "filters": [
              {
                "field": "prospect",
                "op": "=",
                "value": prospect
              }
            ]
          }
        ],
        "type": "rpc",
        "tid": 502
      },
      {
        "action": "FrmFdUserForm",
        "method": "get",
        "data": [
          {
            "formid": "pctcampaign",
            "filters": [
              {
                "field": "campaign",
                "type": "string",
                "op": "=",
                "value": campaign
              }
            ]
          }
        ],
        "type": "rpc",
        "tid": 503
      },
      {
        "action": "FrmHrOrgChartHis",
        "method": "get",
        "data": [
          {
            "employee": employee,
            "filters": [

            ],
            "isShortData": "Y"
          }
        ],
        "type": "rpc",
        "tid": 504
      }
    ]
  }

  // approve YCDCH
  approveYCDCH(systemno) {
    return {
      "id": "XNYC",
      "BookingNo": systemno,
      "status1": "XNDCH",
      "emp": "",
      "refresh": "1",
      "sessionId": "",
      "transfercode": "XNYC"
    }
   }

   getDataEmployeeErp(employee) {
    return {
        "action": "FrmHrEmployee",
        "method": "get",
        "data": [
          {
            "filters": [
              {
                "field": "employee",
                "op": "eq",
                "type": "string",
                "value": employee
              }
            ],
            "status": "W"
          }
        ],
        "type": "rpc",
        "tid": 182
      }
   }
  getYCDCH(systemno) {
    const data = {
      "action": "FrmFdUserForm",
      "method": "get",
      "data": [
        {
          "limit": 25,
          "formid": "pctbooking2",
          "filters": [
            {
              "field": "systemno",
              "op": "eq",
              "value": systemno,
              "type": "string"
            }
          ],
          "page": 1,
          "start": 0
        }
      ],
      "type": "rpc",
      "tid": 53
    }
    return this.post(data);
  }
  getHoldForEntry(){
    const data = {
      "action": "FrmPmTransaction",
      "method": "getHoldForEntry",
      "data": [
        {
          "revenueno": 1
        }
      ],
      "type": "rpc",
      "tid": 117
    };
    return this.post(data);
  }
  getUserLogin(){
    const url  = this.url + 'api/me/get';
    return this.get(url);
  }
  async logout(){
    this.oauthService.logOut();
  }
  createYCHDCH(bookingNo, employee){
    const data = {
      "action": "FrmFdTransfer",
      "method": "runTransferCode",
      "data": [
          {
              "id": "PCTBooking03",
              "BookingNo": bookingNo,
              "Status": "X",
              "status1": "XNHDCH",
              "emp": employee,
              "refresh": "1",
              "transfercode": "PCTBooking03"
          }
      ],
      "type": "rpc",
      "tid": 39
  }
    return this.post(data);
  }
  getTransaction(systemno ,revenueno){
    const data = {
      "action": "FrmPmTransaction",
      "method": "get",
      "data": [
        {
          "limit": 25,
          "customer": -1,
          "action": "V",
          "anal_r3": systemno,
          "revenueno": revenueno,
          "page": 1,
          "start": 0,
          "filter": [
            {
              "type": "string"
            }
          ]
        }
      ],
      "type": "rpc",
      "tid": 76
    }
    return this.post(data);
  }
  approveRefund(item , transaction, popupData) {
    const data = {
      "action": "FrmPmTransaction",
      "method": "postRevision",
      "data": [
        [
          {
            "tvcdb": transaction.tvcdb,
            "revenueno": transaction.revenueno,
            "revenueline": transaction.revenueline,
            "originalline": transaction.originalline,
            "revenuetype": transaction.revenuetype,
            "transref": transaction.transref,
            "transdate": transaction.transdate,
            "period": transaction.period,
            "transmonth": transaction.transmonth,
            "duedate": transaction.duedate,
            "service": transaction.service,
            "property": transaction.property,
            "customer": transaction.customer,
            "bankcode": "",
            "d_c": transaction.d_c,
            "amount": transaction.amount,
            "otheramt": parseInt(transaction.otheramt ,0),
            "currencycode": transaction.currencycode,
            "currencyrate": parseInt(transaction.currencyrate ,0),
            "value0": parseInt(transaction.value0 ,0),
            "value1": parseInt(transaction.value1 ,0),
            "value2": parseInt(transaction.value2 ,0),
            "value3": parseInt(transaction.value3 ,0),
            "value4": parseInt(transaction.value4 ,0),
            "value5": parseInt(transaction.value5 ,0),
            "value6": parseInt(transaction.value6 ,0),
            "value7": parseInt(transaction.value7 ,0),
            "value8": parseInt(transaction.value8 ,0),
            "value9": parseInt(transaction.value9 ,0),
            "anal_r0": transaction.anal_r0,
            "anal_r1": transaction.anal_r1,
            "anal_r2": transaction.anal_r2,
            "anal_r3": transaction.anal_r3,
            "anal_r4": transaction.anal_r4,
            "anal_r5": transaction.anal_r5,
            "anal_r6": transaction.anal_r6,
            "anal_r7": transaction.anal_r7,
            "anal_r8": transaction.anal_r8,
            "anal_r9": transaction.anal_r9,
            "orgchartid": transaction.orgchartid,
            "extreference1": transaction.extreference1,
            "extreference2": transaction.extreference2,
            "extdate1": transaction.extdate1,
            "extdate2": transaction.extdate2,
            "extdescription1": transaction.extdescription1,
            "extdescription2": transaction.extdescription2,
            "extdescription3": transaction.extdescription3,
            "extdescription4": transaction.extdescription4,
            "accountcode": transaction.accountcode,
            "allocation": "p",
            "allocationref": transaction.allocationref,
            "allocationdate": transaction.allocationdate,
            "allocoperatorid": transaction.allocoperatorid,
            "reversal": transaction.reversal,
            "lossgain": transaction.lossgain,
            "roughflag": transaction.roughflag,
            "inuseflag": transaction.inuseflag,
            "allocinprogress": "Y",
            "transfertola": transaction.transfertola,
            "entrydate": transaction.entrydate,
            "postingdate": transaction.postingdate,
            "holdoperatorid": transaction.holdoperatorid,
            "postoperatorid": transaction.postoperatorid,
            "updatedby": transaction.updatedby,
            "lastupdate": transaction.lastupdate,
            "Dirty": transaction.Dirty,
            "code": transaction.revenueno,
            "loanvalue": 0,
            "inst": 0,
            "inst2": 0,
            "instrate": 0,
            "duedate2": null,
            "extdate3": null,
            "extdate4": null,
            "extnumber1": 0,
            "extnumber2": 0,
            "extnumber3": 0,
            "extnumber4": 0,
            "invoiceid": 0,
            "interestamt": 0,
            "remainamt": 0,
            "paidamt": transaction.amount,
            "id": "tavico.model.PmTransaction-104"
          },
          {
            "tvcdb": transaction.tvcdb,
            "revenueno": 0,
            "revenueline": 1,
            "originalline": 1,
            "revenuetype": popupData.bankInfo === 'TM' ? 'PC' : "BN",
            "transref": popupData.receiptNum,
            "transdate": popupData.receiptDate,
            "duedate": transaction.duedate,
            "service": transaction.service,
            "property": transaction.property,
            "customer": transaction.customer,
            "d_c": "D",
            "amount": Math.abs(transaction.amount),
            "otheramt": parseInt(transaction.otheramt ,0),
            "currencycode": transaction.currencycode,
            "currencyrate": parseInt(transaction.currencyrate ,0),
            "value0": parseInt(transaction.value0 ,0),
            "value1": parseInt(transaction.value1 ,0),
            "value2": parseInt(transaction.value2 ,0),
            "value3": parseInt(transaction.value3 ,0),
            "value4": parseInt(transaction.value4 ,0),
            "value5": parseInt(transaction.value5 ,0),
            "value6": parseInt(transaction.value6 ,0),
            "value7": parseInt(transaction.value7 ,0),
            "value8": parseInt(transaction.value8 ,0),
            "value9": parseInt(transaction.value9 ,0),
            "anal_r0": transaction.anal_r0,
            "anal_r1": transaction.anal_r1,
            "anal_r2": transaction.anal_r2,
            "anal_r3": transaction.anal_r3,
            "anal_r4": transaction.anal_r4,
            "anal_r5": transaction.anal_r5,
            "anal_r6": transaction.anal_r6,
            "anal_r7": transaction.anal_r7,
            "anal_r8": transaction.anal_r8,
            "anal_r9": transaction.anal_r9,
            "orgchartid": transaction.orgchartid,
            "extreference1": item.transaction && item.transaction.propertyTicket ? item.transaction.propertyTicket.customer.personalInfo.name : '',
            "extreference2": transaction.extreference2,
            "extdate1": transaction.extdate1,
            "extdate2": transaction.extdate2,
            "extdescription1": popupData.description,
            "extdescription2": `${item.transaction.propertyTicket.bookingTicketCode}(${item.code})`,
            "extdescription3": transaction.extdescription3,
            "extdescription4": transaction.extdescription4,
            "accountcode": transaction.accountcode,
            "allocation": "p",
            "reversal": transaction.reversal,
            "lossgain": transaction.lossgain,
            "inuseflag": transaction.inuseflag,
            "allocinprogress": "Y",
            "transfertola": transaction.transfertola,
            "entrydate": transaction.entrydate,
            "updatedby": transaction.updatedby,
            "lastupdate": transaction.lastupdate,
            "Dirty":  transaction.Dirty,
            "code": transaction.revenueno,
            "loanvalue": 0,
            "inst": 0,
            "inst2": 0,
            "instrate": 0,
            "duedate2": null,
            "extdate3": null,
            "extdate4": null,
            "extnumber1": 0,
            "extnumber2": 0,
            "extnumber3": 0,
            "extnumber4": 0,
            "invoiceid": 0,
            "interestamt": 0,
            "remainamt": 0,
            "paidamt": transaction.amount,
            "period": transaction.period,
            "transmonth": 0,
            "allocationdate": transaction.allocationdate,
            "postingdate": null,
            "id": "tavico.model.PmTransaction-105",
            "bankcode": popupData.bankInfo === 'TM' ? '' : popupData.bankInfo,
            "roughflag": transaction.roughflag
          }
        ]
      ],
      "type": "rpc",
      "tid": 207
    }
    return this.post(data);
  }
  createTransaction(item , tvcdb,dataYc, popupData) {
    const data = {
      action: "FrmPmTransaction",
      method: "roughEntry",
      data: [
        [{
          tvcdb: tvcdb,
          revenuetype: popupData.bankInfo === "TM" ? "TTDCH" : "BCDCH",
          forcepreset: "N",
          transref: popupData.receiptNum,
          transdate: popupData.receiptDate,
          duedate: null,
          service: "",
          property: "",
          customer: item.propertyTicket.project.setting.vendorCode ? item.propertyTicket.project.setting.vendorCode : "Z",
          bankcode: popupData.bankInfo === "TM" ? "" : popupData.bankInfo,
          amount: item.money,
          otheramt: 0,
          currencycode: "",
          currencyrate: 0,
          value0: 0,
          value1: 0,
          value2: 0,
          value3: 0,
          value4: 0,
          value5: 0,
          value6: 0,
          value7: 0,
          value8: 0,
          value9: 0,
          anal_r0: "",
          anal_r1: dataYc.employee,
          anal_r2: dataYc.customer,
          anal_r3: dataYc.systemno,
          anal_r4: "",
          anal_r5: "",
          anal_r6: "",
          anal_r7: "",
          anal_r8: "",
          anal_r9: "",
          extreference1: popupData.payer,
          extreference2: "",
          extdate1: null,
          extdate2: null,
          extdescription1: popupData.description,
          extdescription2: `${item.propertyTicket.bookingTicketCode}(${item.code})`,
          extdescription3: "",
          extdescription4: "",
          d_c: "C",
          code: 0,
          revenueno: 0,
          revenueline: 1,
          originalline: 1,
          period: 0,
          transmonth: 0,
          allocationdate: null,
          entrydate: null,
          postingdate: null,
          loanvalue: 0,
          inst: 0,
          inst2: 0,
          instrate: 0,
          duedate2: null,
          extdate3: null,
          extdate4: null,
          extnumber1: 0,
          extnumber2: 0,
          extnumber3: 0,
          extnumber4: 0,
          invoiceid: 0,
          interestamt: 0,
          remainamt: 0,
          id: "tavico.model.PmTransaction-29",
          paidamt: 0,
          type: popupData.bankInfo === "TM" ? "TTDCH" : "BCDCH",
        }
        ]
      ],
      type: "rpc",
      tid: 71
    }
    return this.post(data);
  }
  checkSyncTransaction(data){
    const body = [];
    data.forEach(item => {
      body.push({
        "action": "FrmPmTransaction",
        "method": "get",
        "data": [
          {
            "limit": 25,
            "filters": [
              {
                "field": "anal_r2",
                "op": "eq",
                "value": item.type === 'REFUND' ? item.transaction.propertyTicket.codeCustomerErp: item.propertyTicket.codeCustomerErp,
                "type": "string"
              },
              {
                "field": "anal_r3",
                "op": "eq",
                "value": item.type === 'REFUND' ? item.transaction.propertyTicket.systemnoErp : item.propertyTicket.systemnoErp,
                "type": "string"
              },
              {
                "field": "extdescription2",
                "op": "eq",
                "value": `${item.type === 'REFUND' ? item.transaction.propertyTicket.bookingTicketCode : item.propertyTicket.bookingTicketCode}(${item.code})`,
                "type": "string"
              }
            ],
            "page": 1,
            "start": 0,
            "sort": [
              {
                "property": "transdate",
                "direction": "DESC"
              }
            ]
          }
        ],
        "type": "rpc",
        "tid": 186
      })
    });
    return this.post(body);
  }
  getDataEmployeeErpByEmail(email) {
    const body =  {
        "action": "FrmHrEmployee",
        "method": "get",
        "data": [
          {
            "filters": [
              {
                "field": "email",
                "op": "eq",
                "type": "string",
                "value": email
              }
            ],
            "status": "W"
          }
        ],
        "type": "rpc",
        "tid": 182
      }
    return this.post(body);
   }
   createContract(bookingvalue = 0, systemno, property, saleperson, customer, states, createdDate, resGetFrmPmPriceBook, statusContract){

    return {
      "action": "FrmPmContract",
      "method": "add",
      "data": [
        {
          "contractid": "",
          "startdate": createdDate ? moment(createdDate).format('YYYY-MM-DD 00:00:00') : moment().format('YYYY-MM-DD 00:00:00'),
          "contractvalue": 0,
          "effectivedate": "1911-01-01T00:00:00",
          "enddate": "1911-01-01T00:00:00",
          "loanvalue": 0,
          "loandate": "1911-01-01T00:00:00",
          "value0": 0,
          "value1": 0,
          "value2": 0,
          "value3": 0,
          "value4": 0,
          "value5": 0,
          "value6": 0,
          "value7": bookingvalue,
          "value8": 0,
          "value9": 0,
          "id": "tavico.model.UserForm.contract4-1",
          "action": "C",
          "property": property.property,
          "contracttype": property.project,
          "clientcode": customer.nadcode,
          "site": saleperson.site,
          "anal_pct5": systemno,
          "pricebookid": resGetFrmPmPriceBook.pricebookid,
          "pymtterm": resGetFrmPmPriceBook.pymtterm,
          "anal_pct2": "",
          "dagid": "",
          "personincharge": "",
          "notes": "",
          "bankloan": "N",
          "bankref": "",
          "bankcode": "",
          "loannotes": "",
          "anal_pct0": "",
          "anal_pct1": "",
          "anal_pct3": "",
          "anal_pct6": "",
          "anal_pct7": "",
          "anal_pct8": "",
          "anal_pct9": "",
          "extreference1": "Y",
          "salepersons": [
            saleperson
          ],
          "promotions": [],
          "members": [
            customer
          ],
          "states": states,
          "byconstprogress": "N",
          "status": statusContract
        }
      ],
      "type": "rpc",
      "tid": 421
    }
   }

  updateContract(systemno, property, resGetFrmPmPriceBook){
    return {
      "action": "FrmFdUserForm",
      "method": "upd",
      "data": [
        {
          formid: "pctbooking2",
          id: "tavico.model.UserForm.pctbooking2-83",
          status: "C",
          systemno: systemno,
          status1: "XNDCO",
          pricebookid: resGetFrmPmPriceBook.pricebookid,
          property: property.property
        }
      ],
      "type": "rpc",
      "tid": 216
    }
  }

   getDataLicense(params) {
    const body =  {
        "action": "FrmPmTransaction",
        "method": "getReferences",
        "data": [
          {
            "filters": [
              {
                "field": "transref",
                "op": "in",
                "type": "string",
                "value": params
              },
            ],
          }
        ],
        "type": "rpc",
        "tid": 49
      }
    return this.post(body);
  }

    getDataContract(params) {
        const p = params.map(code => {
            return {
                "action": "FrmPmContract",
                "method": "get",
                "data": [
                    {
                        "limit": 25,
                        "contractid": -1,
                        "filters": [
                            {
                                "field": "contractid",
                                "op": "eq",
                                "value": code,
                                "type": "string"
                            }
                        ],
                        "page": 1,
                        "start": 0
                    }
                ],
                "type": "rpc",
                "tid": 201
            }
        });
        return this.post(p).toPromise();
    }

    getClientInfoByCodeErp(params) {
        const p = params.map(code => {
            return {
                "action": "FrmCsNameAddress",
                "method": "get",
                "data":
                    [
                        {
                            "nadcode": code
                        }
                    ],
                "type": "rpc",
                "tid": 293
            }
        });
        return this.post(p).toPromise();
    }

    getEmployeeInfoByCodeErp(params) {
        const p = params.map(code => {
            return {
                "action": "FrmHrEmployee",
                "method": "get",
                "data": [
                    {
                        "limit": 15,
                        "employee": -1,
                        "filters": [
                            {
                                "field": "employee",
                                "op": "like",
                                "value": code,
                                "type": "string"
                            }
                        ],
                        "page": 1,
                        "start": 0
                    }
                ],
                "type": "rpc",
                "tid": 240
            }
        });
        return this.post(p).toPromise();
    }

  getInfoByRevenuenoErp(params) {
    const body = {
      "action": "FrmPmTransaction",
      "method": "get",
      "data": [
        {
          "filters": [
            {
              "field": "revenueno",
              "op": "in",
              "type": "int",
              "value": params
            },
          ],
        }
      ],
      "type": "rpc",
      "tid": 97
    }
    return this.post(body);
  }

  getCustomerByListCode(params){
    const data = {
      "action":"FrmCsNameAddress",
      "method":"get",
      "data":[
         {
            "filters":[
               {
                  "field":"nadcode",
                  "op":"in",
                  "value":params,
                  "type":"string"
               }
            ]
         }
      ],
      "type":"rpc",
      "tid":106
   }
   return this.post(data);
  }
}
