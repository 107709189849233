export class DebtReportModel {

    stt: number;
    namePrimaryContract: string;
    installmentsName: string;
    project: string;
    productCode: string;
    debt: number;
    paymentDueDate: Date;
    expiredDate: Date;
    type: string;
    id: string;
    isTransferred: boolean;
    countPaymentDueDate: string;

    constructor(params?: any) {
        if (params) {
            // Số ngày quá hạn: = Ngày hiện tại trừ - Hạn thanh toán;
            this.countPaymentDueDate = params.paymentDueDate && this.countDayBetweenCurrentDate(params.paymentDueDate) > 0 ? this.countDayBetweenCurrentDate(params.paymentDueDate).toString() : '';
        }
        Object.assign(this, {
            stt: 1,
            namePrimaryContract: '',
            installmentsName: '',
            project: '',
            productCode: '',
            debt: 0,
            paymentDueDate: new Date,
            expiredDate: new Date,
           },params);
    }

    countDayBetweenCurrentDate(paymentDueDate) {
        let difference = new Date().getTime() - new Date(paymentDueDate).getTime();
        let days = Math.ceil(difference / (1000 * 3600 * 24));
        return days;
    }
}

