import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Property } from "../../property/property.model";
import { Constant } from '../../../shared/constant/constant';
import { Contract } from 'app/pages/contract/contract.model';
import { Demand } from '../demand.model';

export interface DialogData {
  type: string;
  title: string;
  requestModel: any;
  execute: boolean;
  matching: any;
}

@Component({
  selector: 'agency-fee-dialog',
  styleUrls: ['./agency-fee-dialog.scss'],
  templateUrl: 'agency-fee-dialog.html',
})
export class AgencyFeeDialog implements OnInit {
  Constant = Constant;
  agencyFeeForm: FormGroup;
  action: string;
  property: Property;
  contract: Contract;
  demand: Demand;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AgencyFeeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    this.action = 'Chấp nhận';
    this.data.type = 'accept';
    this.mapData();
    this.initForm();
    this.setFormValue();
    this.feePercentOnChange();
  }

  initForm() {
    this.agencyFeeForm = this.fb.group({
      suggestFeePercent: ['', Validators.compose([Validators.required,Validators.maxLength(3), Validators.min(0), Validators.max(100)])],
    });
  }
  setFormValue() {
    this.agencyFeeForm.patchValue({suggestFeePercent: this.contract.salesCommissionPercent});
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.data.execute = true;
    let object = {
      contractId : this.contract.id,
      // demandId : this.demand.id,
      unitId : this.property.id,
      salesCommissionPercent : this.requestFee.salesCommissionPercent,
      sourceCommissionPercent : this.requestFee.sourceCommissionPercent,
      reason: ' ',
    }

    this.data.requestModel = object;
    this.dialogRef.close(this.data);
  }

  feePercentOnChange(): void {
    this.agencyFeeForm.get('suggestFeePercent').valueChanges.subscribe(val => {
      if (val != this.contract.salesCommissionPercent) {
        this.action = 'Yêu cầu mới';
        this.data.type = 'request';
      }
    });
  }

  mapData() {
    this.property = (this.data.matching && this.data.matching.property) && new Property(this.data.matching.property);
    this.demand = (this.data.matching && this.data.matching.demand) && new Demand(this.data.matching.demand);
    this.contract = (this.data.matching && this.data.matching.contract) && new Contract(this.data.matching.contract);
  }

  get requestFee() {
    const isValidFee = this.agencyFeeForm.get('suggestFeePercent').valid;
    const salesCommissionPercent = isValidFee ? parseInt(this.agencyFeeForm.get('suggestFeePercent').value, 10) : 0;
    const sourceCommissionPercent = 100 - salesCommissionPercent;
    const brokeFee = this.contract.brokerFee;
    const sourceFee = brokeFee * (sourceCommissionPercent/100);
    const requestFee = brokeFee * (salesCommissionPercent/100);
    let object = {
      brokeFee : brokeFee,
      sourceCommissionPercent: sourceCommissionPercent,
      sourceFee : sourceFee,
      salesCommissionPercent : salesCommissionPercent,
      requestFee: requestFee,
      suggestTotalPrice : brokeFee + requestFee,
    }
    return object;
  }

  get isSuggestFeeControlValid() {
    return this.agencyFeeForm.get('suggestFeePercent').valid;
  }



}
