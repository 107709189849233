import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, ViewChild } from '@angular/core';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { MatDialog, MatMenu } from '@angular/material';
import { AuthorizeService } from 'app/shared/authenticate';
import { NotiPopupTrial } from 'app/shared/components/noti-popup-trial/noti-popup-trial';
import { UserV2Service } from 'app/shared/services/common/user-v2.service';
import { ReplaySubject, Subject } from 'rxjs-compat';
import moment = require('moment');
import { CUser } from 'app/api-models';

@Component({
  selector: 'app-vertical-trial-menu',
  templateUrl: './vertical-trial-menu.component.html',
  styleUrls: ['./vertical-trial-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [MenuService]
})
export class VerticalTrialMenuComponent implements OnInit {

  parentMenu: Array<any>;
  childrenMenu: Menu[] = [];
  checkExpired: boolean = true;
  public settings: Settings;
  public destroyUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);
  private currentUser: CUser;
  private unsubscribe$: Subject<any> = new Subject();

  @Input() menuItems;
  @Input() menuParentId;
  @Input() small: boolean = false;
  @ViewChild(`smallMenu`) smallMenuRef: MatMenu;

  constructor(public appSettings: AppSettings,
              public menuService: MenuService, 
              public router: Router, 
              private authorizeService: AuthorizeService, 
              private dialog: MatDialog,
              private userV2Service: UserV2Service
              ) {
    this.settings = this.appSettings.settings;
  }
  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
    this.userV2Service.user$
      .pipe(takeUntil(this.destroyUnsubscribe))
      .subscribe((user: CUser) => {
        if(user.id) {
          const currentDate = moment(new Date())
          const endDate = moment(user.endDateTrial)
          this.checkExpired = endDate.isSameOrAfter(currentDate)
          this.disabledMenu()
        }
      });
    
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  onChildrenMenu(menu: Menu) {
    this.childrenMenu = this.menuItems.filter((el: Menu) => el.parentId === menu.id);
  }

  onClick(menu) {
    if(menu.authoritiesTrial) {
      this.menuService.toggleMenuItem(menu.id);
      this.menuService.closeOtherSubMenus(this.menuItems, menu.id);
      if (!menu.hasSubMenu) {
        this.menuService.menuClick.next(menu);
      }
      if(menu.routerLink) {
        this.router.navigate([menu.routerLink])
      }
    }
    else {
      const popup = this.dialog.open(NotiPopupTrial, {
        panelClass: 'confirm-upgrade',
        width: '500px'
      })

      popup.afterClosed().subscribe(res => {
        if(res) {
          this.router.navigate([]).then(result=>{
            window.open('nang-cap-trai-nghiem','_blank')
             })
        }
      })
    }
  }
  disabledMenu() {
    this.parentMenu.map(item => {
          this.authorizeService.hasAuthority(item.authorities).then(res => {
               return item.authoritiesTrial = (res && this.checkExpired)
          })
    })
  }
  onImgError(event) {
    event.target.src = './assets/imgs/altImg.png'
  }

}
