import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
    selector: 'custom-excel',
    templateUrl: './custom-excel.component.html',
    styleUrls: ['./custom-excel.component.scss'],
})

export class CustomExcelComponent implements OnInit, OnChanges {
    @Input() buttonName: string;
    @Input() firstRow: number;
    @Input() fileExtensions: string[];
    @Input() customStyle: boolean = false;
    @Output() sendDataEvent = new EventEmitter();
    @Output() selected = new EventEmitter<File>();
    // @Output() result: EventEmitter<any> = new EventEmitter();
    jsonArray: any =[];
    constructor(
        // public excelService: ExcelService,
        public toastr: ToastrService,
    ) {
        this.fileExtensions = ['xls', 'xlsx'];
    }
    ngOnInit() {

    }
    ngOnChanges(event) {
        if (event && event.data && event.data.currentValue) {

        } else {
            this.jsonArray = [];
        }
    }

    selectFile() {
        $('#browserExcel').click();
    }
    openDialog(oEvent) {
        this.jsonArray = [];
        const _this = this;
        // Get The File From The Input
        var selectedFile = oEvent.target.files[0];
        this.selected.emit(selectedFile);
        // Validate image
        if (!this.checkFileExtension(selectedFile)) {
            this.toastr.error('Lỗi!', 'Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại .xls hoặc .xlsx.');
            return;
        }

        // Create A File Reader HTML5
        var reader = new FileReader();
        const firstRow : number = this.firstRow;
        // Ready The Event For When A File Gets Selected
        reader.onload = function(e) {
            // var data = e.currentTarget.result;
            let fileReader = e.target as FileReader;
            let data = fileReader.result
            const workbook = XLSX.read(data, {type: 'binary'});
            //row begin 4
            workbook.SheetNames.forEach(sheetName => {_this.jsonArray.push({sheetName: sheetName, sheet: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                raw: true, defval:null, range: firstRow - 1
            })})});
            _this.sendDataEvent.emit(_this.jsonArray);
        };
        // reader.onload = this.excelService.readExcelEvent;

        // Tell JS To Start Reading The File.. You could delay this if desired
        reader.readAsBinaryString(selectedFile);
    }

    checkFileExtension(file: File): boolean {
        if (!file) {
          return false;
        }
        return this.fileExtensions.includes(file.name.split('.').pop().toLocaleLowerCase());
      }
}
