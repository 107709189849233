import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StorageService } from 'app/shared/services';

@Component({
    selector: 'app-dialog-warning-system',
    templateUrl: 'warning-system-dialog.component.html',
    styleUrls: ['warning-system-dialog.component.scss'],
})
export class WarningSystemDialog implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<WarningSystemDialog>,
        public storageService: StorageService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() { }

    onCloseDialog() {
        this.dialogRef.close();
    }

    changeHide(event) {
        this.storageService.storeLocal('isHideWarning', event);
    }
}
