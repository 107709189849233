import { Directive, Input, TemplateRef, ViewContainerRef, Injector } from '@angular/core';
import { AuthorizeService } from './authorize.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *userAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *userAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
    selector: '[userAuthority]'
})
export class UserAuthorityDirective {
    private authorities: any;
    constructor(
        private authorizeService: AuthorizeService,
        private injector: Injector,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef
        )
         {
        this.authorizeService = this.injector.get(AuthorizeService);
    }

    @Input()
    set userAuthority(value: any) {
        // this.authority = authority;
        this.authorities = typeof value === 'string' ? [<string>value] : <any[]>value;
        let list = [];
        this.authorities.forEach((item) => {
            if (!item) {
                return;
            }
            if (typeof item == 'object' && item.length > 0) {
                item.forEach((i) => {
                    list.push(i);
                })
            } else if (item.includes('EVAL:')) {
                if (eval(item.substring(5, item.length))) {
                    list.push('');
                } else list.push('FAIL');
            } else {
                list.push(item);
            }
        });
        this.authorities = list;
        this.updateView();
    }

    private updateView(): void {
        this.authorizeService.hasAuthority(this.authorities).then(result => {
            this.viewContainerRef.clear();
            if (result) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }
}
