import { Injectable, Injector } from '@angular/core';
import { SessionStorageService } from 'ng2-webstorage';
// import { inject } from '@angular/core/src/render3/instructions';

@Injectable()
export class StateStorageService {
    $sessionStorage: any;
    constructor(
        private injector: Injector,
    ) {
        this.$sessionStorage = injector.get(SessionStorageService)
    }

    getPreviousState() {
        const previousState = this.$sessionStorage.retrieve('previousState');
        return previousState;
    }

    resetPreviousState() {
        this.$sessionStorage.clear('previousState');
    }

    storePreviousState(previousStateName, previousStateParams) {
        let previousState = { 'name': previousStateName, 'params': previousStateParams };
        this.$sessionStorage.store('previousState', previousState);
    }

    getDestinationState() {
        const destinationState = this.$sessionStorage.retrieve('destinationState');
        return destinationState;
    }
    resetDestinationState() {
        this.$sessionStorage.clear('destinationState');
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        let destinationInfo = {
            'destination': {
                'name': destinationState.name,
                'data': destinationState.data,
            },
            'params': destinationStateParams,
            'from': {
                'name': fromState.name,
             }
        };
        this.$sessionStorage.store('destinationState', destinationInfo);
    }
}
