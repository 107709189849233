import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


export interface NotiData {
  title: string;
  message: string;
  execute: boolean;
}

@Component({
  selector: 'noti-popup-trial',
  templateUrl: 'noti-popup-trial.html',
  styleUrls: ['noti-popup-trial.scss']
})
export class NotiPopupTrial implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NotiPopupTrial>,
    @Inject(MAT_DIALOG_DATA) public data: NotiData) { }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  execute() {
    this.dialogRef.close(true);
  }
}