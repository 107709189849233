import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import {Subject, Observable} from 'rxjs';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import {HttpService} from '../../shared/services/http.service';
import { CPagination } from 'app/api-models';
import { ContractReport } from './report/contract-report.model';
import {map} from 'rxjs/operators';
import { PaymentProgressModel } from 'app/shared/models/payment-progress.models';
import { ContractDepositModel } from 'app/shared/models/contract-deposit.models';
import { DeliveryModel } from 'app/shared/models/delivery.model';
import * as moment from 'moment';
import { ProjectReportModel } from '../project-report/project-report.model';
import { DebtReportModel } from './debt-report/debt-report.model';
import { TransformMoneyPipe } from 'app/shared/pipe';
const momentTz = require('moment-timezone');

@Injectable()
export class ContractService extends BaseService {
    ConstantUrl = ConstantUrl;
    public emitChangeSource = new Subject<any>();
    public emitTable = new Subject<any>();
    constructor(
        public service: DataService,
        private httpService: HttpService,
        private storageService: StorageService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_contract_domain + 'contract';
        this.queryUrl = ConstantUrl.url_contract_query + 'contract';
    }

    getImportHistories(params): Observable<Response> {
        const url = ConstantUrl.url_sale_policy_query + 'importHistory';
        return this.httpService.get(url, params);
    }
    importContract(files: File, dto) {
        const url = ConstantUrl.url_sale_policy_domain + 'primary-contract/import';
        let formData = new FormData();
        formData.append('files', files);
        return this.httpService.postErp(url, formData);
    }
    get(params?) {
        const url = this.queryUrl;
        return this.httpService.get(url, params ? params : null);
    }
    update(contract, headers) {
        return this.service.post(this.domainUrl, contract, headers);
    }
    executeAmendment(amendment, headers) {
        return this.service.post(this.domainUrl, amendment, headers);
    }
    insertCustomer(customer) {
        const url = ConstantUrl.url_property_domain + 'propertyCustomer';
        return this.service.post(url, customer);
    }
    insertSurveyPropertyUnit(property) {
        const url = ConstantUrl.url_property_domain + 'surveyPropertyUnit';
        return this.service.post(url, property);
    }
    getSurveyPropertyUnitById(id) {
        const url = ConstantUrl.url_property_query + 'surveyPropertyUnit/' + id;
        return this.service.get(url);
    }
    getTicketById(id) {
        const url = ConstantUrl.url_ticket_query + 'lead/' + id;
        return this.service.get(url);
    }
    getConsignmentById(id) {
        const url = ConstantUrl.url_property_query + 'consignment/' + id;
        return this.service.get(url);
    }
    getCategories() {
        const url = ConstantUrl.url_category_query + 'category/pub/all';
        return this.service.get(url);
    }
    checkIdentity(request) {
        let url =  ConstantUrl.url_property_query + 'propertyCustomer/checkCustomerExist';
        return this.service.post(url, request);
    }
    extend(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/extend';
        return this.service.put(url, request);
    }
    sendApprove(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/sendApprove';
        return this.service.put(url, request);
    }
    approve(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/approve';
        return this.service.put(url, request, header);
    }
    reject(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/reject';
        return this.service.put(url, request, header);
    }
    liquidate(id, headers?, model?) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState';
        let request = model;
        request.id = id;
        request.state = "LIQUIDATE";
        return this.service.put(url, request, headers || {});
    }
    approveLiquidate(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState/approve';
        return this.service.put(url, request, header);
    }
    rejectLiquidate(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState/reject';
        return this.service.put(url, request);
    }
    downloadFile(code) {
        // code = 'DXS_O2O_00004';
        const url = ConstantUrl.url_contract_query + 'contract/download/' + code;
        return this.service.dowloadFile(url, null, code);
    }
    getLiquidates() {
        const url = this.queryUrl + '/getLiquidateContracts';
        return this.service.get(url);
    }
    changeState(contract: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/liquidate';
        return this.service.put(url, contract);
    }
    getEmployee() {
        const url = ConstantUrl.url_contract_query + 'employee';
        return this.service.get(url);
    }
    upload(body) {
        const url = ConstantUrl.url_contract_query + 'contract/upload';
        return this.service.put(url, body);
    }
    confirmRevenue(params: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/confirmRevenue';
        return this.service.put(url, params);
    }
    getManager(userId) {
        const url = ConstantUrl.url_contract_query + 'employee/findManager/' + userId;
        return this.service.get(url);
    }
    actionActive(contract, headers) {
        let url = ConstantUrl.url_contract_domain + 'contract/active';
        return this.service.put(url, contract, headers);
    }
    getFilterList() {
      let url = ConstantUrl.url_contract_query + 'contract/filterList';
      return this.service.get(url);
    }
    getAllFilesByContractId(contractId, fileName) {
      let url = ConstantUrl.url_contract_query + 'contract/download/' + contractId + '/liquidate-documents';
      return this.service.dowloadFile(url, {}, fileName);
    }
    // Hợp đồng cần duyệt
    getWaiting(params?) {
        const url = this.queryUrl + '/waiting';
        return this.httpService.get(url, params ? params : null);
    }

    getProjects(params?) {
        let url = ConstantUrl.url_property_query + 'project/admin';
        return this.service.get(url, params || {});
    }

    // Report
    public getReportContracts(params): Observable<CPagination<ContractReport>> {
        const url = ConstantUrl.url_dealt_query + 'dealt/reportSales';
        return this.service.get(url, params).pipe(
          map((res: any) => {

            const pagination = new CPagination<ContractReport>().decode(res);
            pagination.items = pagination.items.map((item: any) => new ContractReport(item));
            return pagination;
          })
        );
    }

    public getReportContractExport(params) {
        params.pageSize = '';
        params.page = '';
        const url = ConstantUrl.url_dealt_query + 'dealt/reportSales?export=grouping';
        return this.service.get(url, params);
    }

    public getListDeposit(params): Observable<CPagination<ContractDepositModel>> {
        const url = `${ConstantUrl.url_sale_policy_query}primary-contract`;
        return this.service.get(url, params).pipe(
          map((res: any) => {
            const pagination = new CPagination<ContractDepositModel>().decode(res);
            pagination.items = pagination.items.map((item: any, index: any) => { const c = new ContractDepositModel(item, index); return c.createDataForList(); });
            return pagination;
          })
        );
    }

    public getListPrimaryContract(params) {
        const url = `${ConstantUrl.url_sale_policy_query}primary-contract`;
        return this.service.get(url, params);
    }
    getDepositById(id : string) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/${id}`);
    }

    getByDiscountPolicy(id: string) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/getByDiscountPolicy/${id}`);
    }

    getByDiscountPolicyByIds(ids: string) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/getByDiscountPolicy/ids?ids=${ids}`);
    }

    getListPolicy(param?: any) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}policy`, param);
    }

    createDeposit(params) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract`, params);
    }

    updateDeposit(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract`, params);
    }

    updateFile(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract/files`, params);
    }

    updateManyPrimaryContract(params: any) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract/updateManyPrimaryContract`, params);
    }

    deleteDeposit(id) {
        return this.service.delete(`${ConstantUrl.url_sale_policy_domain}primary-contract/${id}`,);
    }

    requestDeposit(params) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract/requestApproveContract`, params);
    }

    approveDeposit(params) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract/approveContract`, params);
    }

    downloadDeposit(body = {}) {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/download';
        return this.service.dowloadFile(url, body);
    }
    downloadListByTemplate(body = {}, fileName) {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/contract/download-query';
        return this.service.dowloadFile(url, body, fileName);
    }

    getAllPaymentProgress(): Observable<any> {
        return this.service.get(ConstantUrl.url_sale_policy_query + 'schedule');
    }
    confirmPayment(id, params, fileName) {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/download/' + id + '/confirmPayment';
        return this.service.dowloadFile(url, params, fileName);
    }
    historyPayment(id, params, fileName) {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/download/' + id + '/historyPayment';
        return this.service.get(url, params, fileName);
    }

    downloadByIdDeposit(id, params, fileName) {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/download/' + id;
        return this.service.dowloadFile(url, params, fileName);
    }

    calculateInterest(id, params): Observable<any> {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/calculateInterest/' + id;
        return this.service.get(url, params);
    }

    createOrUpdateInterestSlip(params): Observable<any> {
        const url = ConstantUrl.url_sale_policy_domain + 'primary-contract/interestCalculation';
        return this.service.put(url, params);
    }

    approveInterestSlip(params): Observable<any> {
        const url = ConstantUrl.url_sale_policy_domain + 'primary-contract/approveInterestCalculation';
        return this.service.put(url, params);
    }

    deleteInterestSlip(params): Observable<any> {
        const url = ConstantUrl.url_sale_policy_domain + 'primary-contract/deleteInterestCalculation';
        return this.service.put(url, params);
    }

    downloadInterestSlip(id, params, fileName): Observable<any> {
        const url = ConstantUrl.url_sale_policy_query + 'primary-contract/downloadInterestCalculation/' + id;
        return this.service.dowloadFile(url, params, fileName);
    }

    getHDC(p) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/getDepositContract`, p)
    }

    createPurchase(params) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract/purchase`, params);
    }

    updatePurchase(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract/purchase`, params);
    }

    approvePurchase(params) {
        return this.service.post(`${ConstantUrl.url_sale_policy_domain}primary-contract/approvePurchaseContract`, params);
    }

    getListDelivery(id: string, params?: any) {
        return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/handover/${id}`, params || {}).pipe(
            map(response => {
                const pagination = new CPagination<DeliveryModel>().decode(response);
                pagination.items = pagination.items.map((item, index) => {
                    const delivery = new DeliveryModel(item);
                    delivery.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    delivery.emailContentPreview = delivery.emailTemplate ? this.parseEmailTemplateToPreview(delivery) : '';
                    return delivery;
                });
                return pagination;
            })
        );
    }

    private parseEmailTemplateToPreview(delivery: DeliveryModel) {
        const template: string = delivery.emailTemplate;
        return template.replace(new RegExp('{{customerName}}', 'g'), delivery.primaryTransaction.customer.personalInfo.name || '')
                        .replace(new RegExp('{{investorName}}', 'g'), delivery.careInvestor ? delivery.careInvestor.name || '' : '')
                        .replace(new RegExp('{{code}}', 'g'), delivery.primaryTransaction.propertyUnit.code)
                        .replace(new RegExp('{{time}}', 'g'), delivery.handoverSchedule && delivery.handoverSchedule.handoverStartTime ? moment(delivery.handoverSchedule.handoverStartTime).format('hh:mm'): '' ) 
                        .replace(new RegExp('{{date}}', 'g'), delivery.handoverSchedule && delivery.handoverSchedule.handoverStartTime ? moment(delivery.handoverSchedule.handoverStartTime).format('DD/MM/YYYY'): '')
                        .replace(new RegExp('{{hotline}}', 'g'), delivery.careInvestor ? delivery.careInvestor.phone || '' : '')
                        .replace(new RegExp('{{investorAddress}}', 'g'), delivery.careInvestor ? delivery.careInvestor.address || '' : '')
                        .replace(new RegExp('{{investorWebsite}}', 'g'), delivery.careInvestor ? delivery.careInvestor.website || '' : '');
    }

    makeDeliveryAppointment(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract/deliveryDate`, params);
    }
    makeDeliveryConfirm(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract/deliveryConfirm`, params);
    }

    sendDeliveryNotification(params) {
        return this.service.put(`${ConstantUrl.url_sale_policy_domain}primary-contract/sendDeliveryNotify`, params);
    }
    getDebtReport(params) {
        const url = `${ConstantUrl.url_sale_policy_query}primary-contract/getDebtReportContract`
        return this.service.get(url, params).pipe(
            map((res: any) => {
                const pagination = new CPagination<DebtReportModel>().decode(res);
                pagination.items = pagination.items.map((item: any) => new DebtReportModel(item));
                pagination.items = pagination.items.map((item: any, index) => {
                    const reportProject = new DebtReportModel(item);
                    reportProject.stt = index + 1 + params.pageSize * (params.page - 1);
                    reportProject.debt = (reportProject.debt).toString().length < 5 ? reportProject.debt : TransformMoneyPipe.transform(item.debt, '');
                    reportProject.paymentDueDate = item.paymentDueDate ? momentTz(item.paymentDueDate).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY') : null;
                    reportProject.expiredDate = item.expiredDate ? momentTz(item.expiredDate).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY') : null;
                    return reportProject;
                });
                return pagination
            })
        )
    }

    getReportProject(params) {
        const uri = `${ConstantUrl.url_property_query}project/admin/report-project`;
        return this.httpService.get(uri, params).pipe(
            map((res: any) => {
              const pagination = new CPagination<ProjectReportModel>().decode(res);
              pagination.items = pagination.items.map((item: any) => new ProjectReportModel(item));
              pagination['sumStatus'] = res.sumStatus;
              pagination.items = pagination.items.map((item: any, index) => {
                const reportProject = new ProjectReportModel(item);
                reportProject.stt = index + 1 + params.pageSize * (params.page - 1);
                return reportProject;
                });
              return pagination;
            })
          );
    }

    previewExcel(params) {
        const url = `${ConstantUrl.url_sale_policy_query}primary-contract/getDebtReportContract/export`
        return this.httpService.get(url, params)
    }
}
