import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Constant } from 'app/shared/constant/constant';
import { Customer } from 'app/pages/customer';
import { PersonalInfo } from 'app/pages/customer/customer.model';
import { LeadModel, Direction, InterestedProduct } from 'app/pages/lead/lead.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-input-lead-in-need',
  templateUrl: './form-input-lead-in-need.component.html',
  styleUrls: ['./form-input-lead-in-need.component.scss']
})
export class FormInputLeadInNeedComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() data: LeadModel;
  @Output() dataChange: EventEmitter<LeadModel> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();

  public listReason = [
    { value: 'cantMeet', label: 'Khách không liên lạc được' },
    { value: 'other', label: 'Khác' }
  ];

  public Constant = Constant;
  public mainForm: FormGroup = null;

  constructor(
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();

  }
  ngOnChanges() {
    this.initForm();
  }


  initForm() {
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        isInNeed: this.data.isInNeed,
        reasonNoNeed: this.data.reasonNoNeed,
        otherReason: this.data.otherReason,
        interestedProduct: this.data.interestedProduct,
        direction: this.data.direction,
        needLoan: this.data.needLoan,
        isAppointment: this.data.isAppointment,
        isVisited: this.data.isVisited,
        note: this.data.note,
        directionAll: this.data.direction.all,
        directionE: this.data.direction.e,
        directionW: this.data.direction.w,
        directionN: this.data.direction.n,
        directionS: this.data.direction.s,
        directionES: this.data.direction.es,
        directionEN: this.data.direction.en,
        directionWS: this.data.direction.ws,
        directionWN: this.data.direction.wn,

        interestedProductAll: this.data.interestedProduct.all  || false,
        interestedProductBuild: this.data.interestedProduct.build  || false,
        interestedProductBuy: this.data.interestedProduct.buy  || false,
        interestedProductGoldsilkBoulevard: this.data.interestedProduct.goldsilkBoulevard || false,
        interestedProductVilla: this.data.interestedProduct.villa || false,
        interestedProductApartment: this.data.interestedProduct.apartment || false,
      });
    } else {
      // Init form
      this.mainForm = this.formBuilder.group({
        // Thông tin cá nhân
        isInNeed: this.data.isInNeed,
        reasonNoNeed: this.data.reasonNoNeed,
        otherReason: this.data.otherReason,
        interestedProduct: this.data.interestedProduct,
        direction: this.data.direction,
        needLoan: this.data.isVisited,
        isAppointment: this.data.isAppointment,
        isVisited: this.data.isVisited,
        note: this.data.note,
        directionAll: this.data.direction.all,
        directionE: this.data.direction.e,
        directionW: this.data.direction.w,
        directionN: this.data.direction.n,
        directionS: this.data.direction.s,
        directionES: this.data.direction.es,
        directionEN: this.data.direction.en,
        directionWS: this.data.direction.ws,
        directionWN: this.data.direction.wn,

        interestedProductAll: this.data.interestedProduct.all,
        interestedProductBuild: this.data.interestedProduct.build,
        interestedProductBuy: this.data.interestedProduct.buy,
        interestedProductGoldsilkBoulevard: this.data.interestedProduct.goldsilkBoulevard,
        interestedProductVilla: this.data.interestedProduct.villa,
        interestedProductApartment: this.data.interestedProduct.apartment,
      });
    }

    this.mainForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        // Patch data
        this.data.isInNeed = res.isInNeed;
        this.data.reasonNoNeed = res.reasonNoNeed;
        this.data.otherReason = res.otherReason;
        this.data.interestedProduct = new InterestedProduct({
          all: res.interestedProductAll,
          build: res.interestedProductBuild,
          buy: res.interestedProductBuy,
          goldsilkBoulevard: res.interestedProductGoldsilkBoulevard,
          villa: res.interestedProductVilla,
          apartment: res.interestedProductApartment
        });
        this.data.direction = new Direction({
          all: res.directionAll,
          e: res.directionE,
          w: res.directionW,
          n: res.directionN,
          s: res.directionS,
          es: res.directionES,
          en: res.directionEN,
          ws: res.directionWS,
          wn: res.directionWN,
        });
        this.data.needLoan = res.needLoan;
        this.data.isAppointment = res.isAppointment;
        this.data.isVisited = res.isVisited;
        this.data.note = res.note;
        // Emit new data
        this.dataChange.emit(this.data);
        // Emit form valid state
      });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }
}
