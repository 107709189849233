import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import moment = require('moment');
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from 'app/app.settings';
import { Settings } from 'app/app.settings.model';
import { LoginService } from '../login.service';
import { Constant } from 'app/shared/constant/constant';
import { listOfNeeds2Constant, ListOfNeedsConstant, ScaleListConstant, WorkListConstant } from 'app/shared/constant/user-info.constant';
import { PhoneAuthenRegisterComponent } from 'app/shared/components/authenticate-register-trial/phone-authen/phone-authen.component';
import { TypeSendOtp } from 'app/shared/enum/trial-register.enum';
import { DuplicateAuthenticate } from 'app/shared/components/authenticate-register-trial/check-duplicate-authen/check-duplicate-authen.component';

@Component({
  selector: 'app-register-trial-dialog',
  templateUrl: './register-trial-dialog.component.html',
  styleUrls: ['./register-trial-dialog.component.scss'],
})
export class RegisterTrialDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject();
  public settings: Settings;
  public form: FormGroup;
  listOfNeeds = ListOfNeedsConstant
  listOfNeeds2 = listOfNeeds2Constant
  workList = WorkListConstant
  scaleList = ScaleListConstant
  public isOpen:boolean = false;

  public mainForm: FormGroup = null;
  constructor(
    public dialogRef: MatDialogRef<RegisterTrialDialogComponent>,
    public appSettings: AppSettings,
    public fb: FormBuilder,
    private dialog: MatDialog,
    public loginService: LoginService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      fullName: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      phoneNumber: [null, [Validators.required, Validators.pattern(Constant.REGEX_VN_PHONE)]],
      email: [null, [Validators.required, Validators.pattern(Constant.REGEX_EMAIL)]],
      demand: [null, [Validators.required]],
      position: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      companySize: [null, [Validators.required]],
    });
    }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }
  public onSubmit() {
    if(this.data.isDKTV===true){
      if (!this.form.valid) {
        this.toastr.error('Error!', 'Sai thông tin đăng ký');
        return;
      }
      if (this.data && this.data.isAddField) {
        const p = {
          fullName: this.form.value.fullName,
          phoneNumber: this.form.value.phoneNumber,
          email: this.form.value.email,
          demand: this.form.value.demand,
          position: this.form.value.position,
          companyName: this.form.value.companyName,
          companySize: this.form.value.companySize,
        }
        this.loginService.sendEmailRegisterTrialNew(p).subscribe((res: any) => {
          this.toastr.success('Successfully!', 'Đăng ký thành công');
          this.closeDialog();
        });
        return;
      }
      this.loginService.sendEmailRegisterTrial(this.form.value.fullName, this.form.value.phoneNumber).subscribe((res: any) => {
          this.toastr.success('Successfully!', 'Đăng ký thành công');
          this.closeDialog();
      });
    }else{
      if (!this.form.valid) {
        this.toastr.error('Error!', 'Sai thông tin đăng ký');
        return;
      }
      if (this.data && this.data.isAddField) {
        const p = {
          fullName: this.form.value.fullName,
          phoneNumber: this.form.value.phoneNumber,
          email: this.form.value.email,
          demand: this.form.value.demand,
          position: this.form.value.position,
          companyName: this.form.value.companyName,
          companySize: this.form.value.companySize,
        }
        if(!this.isOpen){
          this.isOpen = true;
          this.loginService.createTrialRegiter(p).subscribe((res: any) => {
            if(res && res.isDuplicate) {
              const popup = this.dialog.open(DuplicateAuthenticate, {
                width: '450px',
                autoFocus: false,
                data: {
                  authenType: TypeSendOtp.PHONE,
                  authenText: 'số điện thoại',
                  phoneNumber: this.form.value.phoneNumber,
                  email: this.form.value.email,
                  isShowAuthenList: true,
                  accessToken: res.accessToken
                }
              });
            } else {
              const popup = this.dialog.open(PhoneAuthenRegisterComponent, {
                width: '500px',
                autoFocus: false,
                data: {
                  authenType: TypeSendOtp.PHONE,
                  authenText: 'số điện thoại',
                  phoneNumber: this.form.value.phoneNumber,
                  email: this.form.value.email,
                  isShowAuthenList: true,
                  accessToken: res.accessToken
                }
              });
            }
            this.closeDialog(true)
            
          }, error => {
              const errorMessage = error.error.errors
              if(errorMessage) {
                const keys = Object.keys(errorMessage).toString()
                this.toastr.error('Lỗi!', errorMessage[keys])
              }
              else {
                this.toastr.error('Lỗi!', 'Vui lòng kiểm tra lại thông tin')
              }
              this.isOpen = false;
          });
        }
      }
    }
    
  }
  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  public closeDialog(execute?: boolean): void {
    document.body.style.overflow = 'auto'
    this.dialogRef.close({execute: execute});
    this.isOpen = false
  }
}
