import {validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty} from "class-validator";
import { DefineAttribute } from "../../../pages/define-attributes/define-attributes.model";
import { GroupAttributes } from "../../../pages/define-attributes/group-attributes.model";
export class GroupsAttributes{

    groups: any = [];
    attributes: any = [];
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'DefineAttributes';
    }

    setValue(params: any) {
        if (params) {
            this.attributes = params.attributes || [];
            if (this.attributes && this.attributes.length > 0 && (!params.groups || (params.groups && params.groups.length == 0))) {
                // this.groups.forEach((group: GroupAttributes) => group.attributes = []);     // Clear old group
                this.attributes.forEach((attribute) => {
                    let group = this.groups.find(group => group.groupName == attribute.groupName);
                    if (group) {
                        if (!attribute.displayOrder) {
                            attribute.displayOrder = group.attributes.length + 1;
                        }
                        group.attributes.push(new DefineAttribute(attribute));
                    } else {
                        this.groups.push(new GroupAttributes({
                            groupName: attribute.groupName,
                            groupOrder: this.groups.length + 1
                        }));
                        attribute.displayOrder = 1;
                        this.groups[this.groups.length -1].attributes.push(attribute);
                    }
                });
            } else {
                this.groups = params.groups ? params.groups.map((group) => {
                    return new GroupAttributes(group);
                })  : [];
            }
            // let findGroup = this.groups.find((item) => item.groupName === 'Tiện ích trong khu vực');
            // if (!findGroup) {
            //     this.groups.push(new GroupAttributes({
            //         groupOrder: 4,
            //         groupName: 'Tiện ích trong khu vực'
            //     }));
            // }
            this.groups.sort((a1, a2) => {
                return a1.groupOrder - a2.groupOrder;
            });
            this.groups.forEach((group) => {
                group.setOrders();
            });
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.attributes = [];
        this.groups = [];
    }
    createObjectRequestAPI() {
        let attributes: any = {};
        return attributes;
    }
    static parseAttributesFromGroups(groups) {
        let attributes: any = [];
        groups.forEach((group) => {
            group.attributes.forEach((attribute) => {
                attribute = new DefineAttribute(attribute);
                attribute.groupName = group.groupName;
                attribute.groupOrder = group.groupOrder;
                attributes.push(attribute.createObjectRequestAPI());
            });
        });
        return attributes;
    }
}
