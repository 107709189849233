import { IDecode } from '../i-decode';
import { CBaseDate } from '../c-base-date';

export class CContractDeal extends CBaseDate implements IDecode<CContractDeal> {
  public index: number;
  public contractName: string;
  public contractStatus: string;
  public contractExpiredDate: string;
  public contractProperty: any;
  public price: string;
  public brokerFee: string;
  public address: string;
  public posContract: string;
  public employeeContract: string;
  public employeeContractName: string;
  public dealts: any[] = [];
  public dealt: any;

  constructor(params: Partial<CContractDeal> = {}) {
    super(params);
    this.copy(params);
  }

  static copyList(params: Partial<CContractDeal>[] = []): CContractDeal[] {
    return params.map(el => new CContractDeal().copy(el));
  }

  copy(params: Partial<CContractDeal> = {}): CContractDeal {
    super.copy(params);
    this.index = params.index || null;
    this.contractName = params.contractName || null;
    this.contractStatus = params.contractStatus || null;
    this.contractExpiredDate = params.contractExpiredDate || null;
    this.contractProperty = params.contractProperty || null; // TODO: CProperty model
    this.price = params.price || null;
    this.brokerFee = params.brokerFee || null;
    this.address = params.address || null;
    this.posContract = params.posContract || null;
    this.employeeContract = params.employeeContract || null;
    this.employeeContractName = params.employeeContractName || null;
    this.dealts = params.dealts || [];  // TODO: CDeal model
    this.dealt = params.dealt;
    return this;
  }

  decode(paramsApi: any): CContractDeal {
    return new CContractDeal(paramsApi);
  }

  decodeList(paramsApi: any[]): CContractDeal[] {
    return paramsApi.map(el => new CContractDeal().decode(el));
  }
}
