import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AppCustomPreloader } from './AppCustomPreloader';
import { CommingSoonComponent } from './pages/errors/coming-soon/coming-soon.component';
import { ForgotPasswordComponent } from './pages/password/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/password/reset-password/reset-password.component';
import { EmployeePOSComponent } from './pages/employee/employee-pos/employee-pos.component';
import { UserRouteAccessDeactivate } from './shared/services/common/user.route.access.service';
import { RouterLinkDefault } from './theme/components/menu/menu';
import { ConfirmChangedEmailComponent } from './pages/employee/confirm-changed-email/confirm-changed-email.component';
import {SearchEmployeePageComponent} from './pages/search-employee-page/search-employee-page.component';
import { EmployeePublicComponent } from './pages/employee-public/employee-public.component';
import { MailerLogComponent } from './pages/mailer/mailer-log/mailer-log.component';

export const routes: Routes = [
    {
        path: '', canActivate: [UserRouteAccessDeactivate],
        component: PagesComponent, children: [
            { path: '', redirectTo: RouterLinkDefault, pathMatch: 'full' },
            { path: 'demo-ui-common', loadChildren: './pages/demo-ui-common/demo-ui-common.module#DemoUiCommonModule' },
            { path: 'dashboard', redirectTo: RouterLinkDefault, pathMatch: 'full' },
            { path: 'import-ticket', loadChildren: './pages/ticket-common/ticket-common.module#TicketCommonModule', data: { breadcrumb: 'Rổ yêu cầu tư vấn' } },
            { path: 'import-ticket-care', loadChildren: './pages/ticket-common-care/ticket-common.module#TicketCommonCareModule', data: { breadcrumb: 'Yêu cầu tư vấn' } },
            { path: 'property-common', loadChildren: './pages/property-common/property-common.module#PropertyCommonModule', data: { breadcrumb: 'Rổ sản phẩm' } },
            { path: 'property-authentication', loadChildren: './pages/property-authentication/property-authentication.module#PropertyAuthenticationModule', data: { breadcrumb: 'Yêu cầu hậu kiểm' } },
            { path: 'contract', loadChildren: './pages/contract/contract.module#ContractModule', data: { breadcrumb: 'Hợp đồng' } },
            { path: 'delivery', loadChildren: './pages/delivery/delivery.module#DeliveryModule', data: { breadcrumb: 'Bàn giao' } },
            { path: 'report', loadChildren: './pages/report/report.module#ReportModule', data: { breadcrumb: 'Báo cáo' } },
            { path: 'mailbox', loadChildren: './pages/mailbox/mailbox.module#MailboxModule', data: { breadcrumb: 'Hộp thư' } },
            { path: 'notification/:id', loadChildren: './pages/notification/notification.module#NotificationModule', data: { breadcrumb: 'Thông báo' } },
            { path: 'booking', loadChildren: './pages/booking/booking.module#BookingModule', data: { breadcrumb: 'Lịch hẹn' } },
            { path: 'news', loadChildren: './pages/news/management-news.module#ManagementNewsModule', data: { breadcrumb: 'Quản lý tin tức' } },
            { path: 'news-category', loadChildren: './pages/folder/management-folder.module#ManagementFolderModule', data: { breadcrumb: 'Quản lý thư mục' } },
            { path: 'ticket', loadChildren: './pages/ticket/ticket.module#TicketModule', data: { breadcrumb: 'Yêu cầu tư vấn' } },
            { path: 'ticket-advising', loadChildren: './pages/ticket-advising/ticket-advising.module#TicketAdvisingModule', data: { breadcrumb: 'Danh sách yêu cầu tư vấn dịch vụ' } },
            { path: 'demand', loadChildren: './pages/demand/demand.module#DemandModule', data: { breadcrumb: 'Yêu cầu tư vấn' } },
            { path: 'lead', loadChildren: './pages/lead/lead.module#LeadModule', data: { breadcrumb: 'Khai thác Lead' } },
            { path: 'lead-care', loadChildren: './pages/lead-care/lead.module#LeadCareModule', data: { breadcrumb: 'Yêu cầu hỗ trợ' } },
            { path: 'loan', loadChildren: './pages/loan/loan.module#LoanModule', data: { breadcrumb: 'Quản lý gói vay' } },
            { path: 'supplier', loadChildren: './pages/supplier/supplier.module#SupplierModule', data: { breadcrumb: 'Quản lý nhà cung cấp' } },
            { path: 'service', loadChildren: './pages/service/service.module#ServiceModule', data: { breadcrumb: 'Quản lý dịch vụ' } },
            { path: 'booking-seat', loadChildren: './pages/booking-seat/booking-seat.module#BookingSeatModule', data: { breadcrumb: 'Yêu cầu đặt chỗ' } },
            { path: 'utilities', loadChildren: './pages/type-of-utilities/type-of-utilities.module#TypeOffUtilitiesModule', data: { breadcrumb: 'Loại tiện ich' } },
            { path: 'apartment-management', loadChildren: './pages/apartment-management/apartment-management.module#ApartmentManagementModule', data: { breadcrumb: 'Quản lý căn hộ' } },

            { path: 'management-documnet-rule', loadChildren: './pages/management-document-rule/management-document-rule.module#ManagementDocumentRuleModule', data: { breadcrumb: 'Ban quản lý' } },

            { path: 'bill-management', loadChildren: './pages/bill-management/bill-management.module#BillManagementModule', data: { breadcrumb: 'Quản lý hóa đơn' } },

            { path: 'matching', loadChildren: './pages/matching/matching.module#MatchingModule', data: { breadcrumb: 'Matching' } },
            { path: 'deal', loadChildren: './pages/deal/deal.module#DealModule', data: { breadcrumb: 'Danh sách giao dịch' } },
            { path: 'receipt', loadChildren: './pages/receipt/receipt.module#ReceiptModule' },
            { path: 'money-order', loadChildren: './pages/money-order/money-order.module#MoneyOrderModule', data: { breadcrumb: 'Danh sách đề nghị thu tiền' } },
            {
                path: 'consignment',
                loadChildren: './pages/consignment/consignment.module#ConsignmentModule',
                data: { breadcrumb: 'Yêu cầu tư vấn' }
            },
            { path: 'project', loadChildren: './pages/project/project.module#ProjectModule', data: { breadcrumb: 'Dự án' } },
            { path: 'profile', loadChildren: './pages/my-profile/my-profile.module#MyProfileModule', data: { breadcrumb: 'Tài khoản' } },
            { path: 'customer', loadChildren: './pages/customer/customer.module#CustomerModule', data: { breadcrumb: 'Khách hàng' } },
            { path: 'employee', loadChildren: './pages/employee/employee.module#EmployeeModule', data: { breadcrumb: 'Nhân viên' } },
            { path: 'category', loadChildren: './pages/category/category.module#CategoryModule', data: { breadcrumb: 'Loại sản phẩm' } },
            { path: 'property', loadChildren: './pages/property/property.module#PropertyModule', data: { breadcrumb: 'Bất động sản' } },
            { path: 'checkin-checkout', loadChildren: './pages/checkin-checkout/checkin-checkout.module#CheckinCheckoutModule', data: { breadcrumb: 'Check-in' } },
            {
                path: 'define-attributes',
                loadChildren: './pages/define-attributes/define-attributes.module#DefineAttributesModule',
                data: { breadcrumb: 'Mẫu sản phẩm/nhu cầu' }
            },
            { path: 'role', loadChildren: './pages/role/role.module#RoleModule', data: { breadcrumb: 'Chức năng người dùng' } },
            { path: 'feature', loadChildren: './pages/feature/feature.module#FeatureModule', data: { breadcrumb: 'Tính năng' } },
            { path: 'msx', loadChildren: './pages/msx/msx.module#MsxModule', data: { breadcrumb: 'MSX' } },
            { path: 'orgchart', loadChildren: './pages/orgchart/orgchart.module#OrgChartModule', data: { breadcrumb: 'Sơ đồ tổ chức' } },
            { path: 'payment', loadChildren: './pages/payment/payment.module#PaymentModule', data: { breadcrumb: 'Phiếu thanh toán' } },
            { path: 'orgchart-pos', loadChildren: './pages/orgchart-pos/orgchart-pos.module#OrgchartPosModule', data: { breadcrumb: 'Danh sách trung tâm giao dịch' } },
            { path: 'call-log', loadChildren: './pages/call-log/call-log.module#CallLogModule', data: { breadcrumb: 'Lịch sử cuộc gọi' } },
            { path: 'employee-pos', component: EmployeePOSComponent, data: { breadcrumb: 'Nhân viên thuộc trung tâm giao dịch' } },
            { path: 'contract-read', loadChildren: './pages/contract-read/contract-read.module#ContractReadModule', data: { breadcrumb: 'Hợp đồng' } },
            { path: 'message', loadChildren: './pages/message/message.module#MessageModule', data: { breadcrumb: 'Tin nhắn' } },
            { path: 'message/:id', loadChildren: './pages/message/message.module#MessageModule', data: { breadcrumb: 'Tin nhắn' } },
            { path: 'transfer-unit-create', loadChildren: './pages/transfer-unit-create/transfer-unit-create.module#TransferUnitCreateModule', data: { breadcrumb: 'Tạo yêu cầu chuyển đơn vị' } },
            { path: 'transfer-unit-list', loadChildren: './pages/transfer-unit-list/transfer-unit-list.module#TransferUnitListModule', data: { breadcrumb: 'Danh sách yêu cầu chuyển đơn vị' } },
            { path: 'commission', loadChildren: './pages/commission/commission.module#CommissionModule', data: { breadcrumb: 'Phí/ Hoa hồng' } },
            { path: 'sales-policy', loadChildren: './pages/sales-policy/sales-policy.module#SalesPolicyModule', data: { breadcrumb: 'Chính sách bán hàng' } },
            { path: 'user-log', loadChildren: './pages/user-log/user-log.module#UserLogModule' },
            { path: 'workflow-step', loadChildren: './pages/workflow-step/workflow-step.module#WorkflowStepModule', data: { breadcrumb: 'Chức năng duyệt' }},
            { path: 'workflow-management', loadChildren: './pages/workflow-management/workflow-management.module#WorkflowManagementModule'},
            { path: 'training', loadChildren: './pages/training/training.module#TrainingModule', data: { breadcrumb: 'Training' }},
            { path: 'liquidation', loadChildren: './pages/liquidation/liquidation.module#LiquidationModule', data: { breadcrumb: 'Thanh lý' }},
            { path: 'request-management', loadChildren: './pages/request-management/request-management.module#RequestManagementModule', data: { breadcrumb: 'Request' }},
            { path: 'cms', loadChildren: './pages/cms/cms.module#CmsModule', data: { breadcrumb: 'Quản lý nội dung' } },
            { path: 'investors', loadChildren: 'app/pages/investors/investors.module#InvestorsModule', data: { breadcrumb: 'Danh sách chủ đầu tư' } },
            { path: 'mobile-configuration', loadChildren: './pages/mobile-configuration/mobile-configuration.module#MobileConfigurationModule', data: { breadcrumb: 'Quản lý cấu hình mobile' } },
            { path: 'project-report', loadChildren: './pages/project-report/project-report.module#ProjectReportModule', data: { breadcrumb: 'Báo cáo dự án' } },
            { path: 'sales-unit-report', loadChildren: './pages/sales-unit-report/sales-unit-report.module#SalesUnitReportModule', data: { breadcrumb: 'Báo cáo đại lý' } },
            { path: 'group-admin', loadChildren: './pages/group/group.module#GroupModule', data: { breadcrumb: 'Quản lý nhóm' } },
            { path: 'sync-erp', loadChildren: './pages/sync-erp/sync-erp.module#SyncErpModule', data: { breadcrumb: 'Đồng bộ Erp' } },
            { path: 'mailer-template', loadChildren: './pages/mailer/mailer.module#MailerModule', data: { breadcrumb: 'Cấu hình mẫu Email' } },
            { path: 'zns-template', loadChildren: './pages/zns-template/zns-template.module#ZnsTemplateModule', data: { breadcrumb: 'Cấu hình Mẫu Zalo' } },
            { path: 'sms-template', loadChildren: './pages/sms-template/sms-template.module#SmsTemplateModule', data: { breadcrumb: 'Cấu hình SMS' }},

            { path: 'erp-transaction', loadChildren: './pages/erp-transaction/erp-transaction.module#ErpTransactionModule', data: { breadcrumb: 'Thông báo giao dịch' } },
            {
                path: 'group-admin/:groupId/member',
                loadChildren: './pages/group-member/group-member.module#GroupMemberModule',
                data: { breadcrumb: 'Quản lý thành viên nhóm' }
            },
            {
                path: 'group-admin/:groupId/report',
                loadChildren: './pages/group-report/group-report.module#GroupReportModule',
                data: { breadcrumb: 'Báo cáo tương tác của TVV' }
            },
            {
                path: 'group-admin/:groupId/post',
                loadChildren: './pages/group-post/group-post.module#GroupPostModule',
                data: { breadcrumb: 'Quản lý bài đăng nhóm' }
            },
            { path: 'project/esale-kit', loadChildren: './pages/esale-kit/esale-kit.module#ESaleKitModule', data: { breadcrumb: 'Quản lý E-SalesKit' } },
            {path: 'notification-manual', loadChildren: './pages/notification-manual/notification-manual.module#NotificationManualModule', data: { breadcrumb: 'Quản lý thông báo' }},
            { path: 'sms-history', loadChildren: './pages/sms/sms.module#SmsModule', data: { breadcrumb: 'Lịch sử gửi tin nhắn' } },
            { path: 'zns-history', loadChildren: './pages/zns/zns.module#ZnsModule', data: { breadcrumb: 'Lịch sử gửi Zalo'}},
            { path: 'email-history', component: MailerLogComponent, data: {breadcrumb: 'Lịch sử gửi Email'}},
            {
                path: 'notification-manual-dxhome',
                loadChildren: './pages/notification-manual-dxhome/notification-manual-dxhome.module#NotificationManualDxhomeModule',
                data: { breadcrumb: 'Quản lý thông báo DxHomes' }
            },
            {path: 'user-trial', loadChildren: './pages/user-trial/user-trial.module#UserTrialModule', data: { breadcrumb: 'Quản lý tài khoản dùng thử' }},
            {path: 'e-voucher', loadChildren: './pages/e-voucher/e-voucher.module#EVoucherModule', data: { breadcrumb: 'e-voucher' }},
            {
                path: 'tool-map',
                loadChildren: './pages/project-image/project-image.module#ProjectImageModule',
                data: { breadcrumb: 'Mặt bằng' }
            },
        ]
    },
    { path: 'news-public', loadChildren: './pages/news-public/news-public.module#NewsPublicModule'},
    { path: 'dxcare-news-public', loadChildren: './pages/news-public/news-public.module#NewsPublicModule' },
    { path: 'live-stream', loadChildren: './pages/live-stream-v2/live-stream-v2.module#LiveStreamV2Module' },
    { path: 'chinh-sach-bao-mat', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyModule'},
    { path: 'dieu-khoan-su-dung', loadChildren: './pages/policy-system/policy-system.module#PolicySystemModule' },
    { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: 'forgot-password', component: ForgotPasswordComponent, data: { breadcrumb: 'Quên mật khẩu'} },
    { path: 'reset-password/:token', component: ResetPasswordComponent, data: { breadcrumb: 'Lấy lại mật khẩu' } },
    { path: 'confirm-changed-email/:token', component: ConfirmChangedEmailComponent, data: { breadcrumb: 'Thay đổi email' } },
    { path: 'coming-soon', component: CommingSoonComponent, data: { breadcrumb: 'Coming Soon' } },
    { path: 'tu-van-vien', component: SearchEmployeePageComponent, data: { breadcrumb: 'Tư vấn viên' } },
    { path: 'tu-van-vien/:id', component: EmployeePublicComponent, data: { breadcrumb: 'Chi tiết tư vấn viên' } },
    {
        path: 'esalekit',
        loadChildren: './pages/e-sale-kit/e-sale-kit.module#ESaleKitModule', data: { breadcrumb: 'Sale' },
    },
    { path: 'online-payment', loadChildren: './pages/payment/online-payment/online.payment.module#OnlinePaymentModule' },
    {
        path: 'tool-map-view',
        loadChildren: './pages/project-image/project-image.module#ProjectImageModule'
    },
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    //    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    preloadingStrategy: AppCustomPreloader
    // useHash: true
});
