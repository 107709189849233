import { IDecode } from '../i-decode';
import { CBaseDate } from '../c-base-date';
import { CPOS } from '../c-pos';

export class CTicketHistory extends CBaseDate implements IDecode<CTicketHistory> {
  public id: string;
  public surveyCode: string;
  public code: string;
  public name: string;
  public phone: string;
  public email: string;
  public address: string;
  public description: string;
  public pos: CPOS;
  public status: string;
  public lifeCycleStatus: string;
  public processBy: string;
  public type: string;
  
  public customerId: string;
  public decisionDate: string;

  constructor(params: Partial<CTicketHistory> = {}) {
    super(params);
    this.copy(params);
  }

  static copyList(params: Partial<CTicketHistory>[] = []): CTicketHistory[] {
    return params.map(el => new CTicketHistory().copy(el));
  }

  copy(params: Partial<CTicketHistory> = {}): CTicketHistory {
    super.copy(params);
    this.id = params.id || null;
    this.surveyCode = params.surveyCode || null;
    this.code = params.code || null;
    this.name = params.name || null;
    this.phone = params.phone || null;
    this.email = params.email || null;
    this.address = params.address || null;
    this.description = params.description || null;
    this.pos = params.pos ? new CPOS(params.pos) : new CPOS();
    this.status = params.status || null;
    this.lifeCycleStatus = params.lifeCycleStatus || null;
    this.processBy = params.processBy || null;
    this.type = params.type || null;
    this.customerId = params.customerId || null;
    this.decisionDate = params.decisionDate || null;
    return this;
  }

  decode(paramsApi: any): CTicketHistory {
    return new CTicketHistory(paramsApi);
  }

  decodeList(paramsApi: any[]): CTicketHistory[] {
    return paramsApi.map(el => new CTicketHistory().decode(el));
  }
}
