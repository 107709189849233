export enum ExploitEnum {
    NEW = 'new',
    ASSIGN = 'assign',
    RENEW = 'renew',
    REASSIGN = 'reassign',
    PROCESSING = 'processing',
    CANCEL = 'cancel',
    DONE = 'done',
}

export enum ExploitTextEnum {
    NEW = 'Chưa xử lý',
    PROCESSING = 'Đang xử lý',
    CANCEL = 'Đã trả về',
    DONE = 'Đã xử lý',
}

export enum ExploitTextMaitenanceEnum {
    NEW = 'Chưa thực hiện',
    PROCESSING = 'Đang xử lý',
    CANCEL = 'Từ chối',
    DONE = 'Hoàn thành',
}

export enum BackgroundColorLeadJob {
    NEW = '#ffffff',
    PROCESSING = '#ffbf6a',
    DONE = '#c6eab6',
    CANCEL = '#fbaeaf'
}