import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Constant } from "app/shared/constant/constant";
import { TXN_STATUS } from "app/shared/constant/transaction.constant";
const uuid = require('uuid');
@Component({
    selector: "app-setting-sms-dialog",
    templateUrl: "./setting-sms-dialog.component.html",
    styleUrls: ["./setting-sms-dialog.component.scss"]
})
export class SettingSmsDialogComponent {
    Constant = Constant;

    listStatus = Object.keys(TXN_STATUS).map(txn => {
        return { id: txn, name: TXN_STATUS[txn] };
    });;
    listConfigs: any[] = [];
    selectedStatus: string = null;
    smsBrandname: string = null;
    numberDay: number = null;
    public config: any = {
        smsBrandname : this.smsBrandname,
        listConfigs: []
    };
    constructor(
        public dialogRef: MatDialogRef<SettingSmsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.smsBrandname = this.data.config && this.data.config.smsBrandname ? this.data.config.smsBrandname : null;
        this.listConfigs = this.data.config && this.data.config.listConfigs ? this.data.config.listConfigs: []
    }



    closeDialog(): void {
        this.dialogRef.close();
    }


    addConfig() {
        if (!this.selectedStatus) {
            return;
        }
        this.listConfigs.push({
            id:  uuid.v4(),
            status : this.selectedStatus,
            numberDay: this.numberDay
        }
        );
    }
    releaseConfig(id) {
        const index = this.listConfigs.findIndex((e) => e.id === id);
        if (index >= 0) {
            this.listConfigs.splice(index, 1);
        }
    }
    getStringStatus(id){
        const status: any = this.listStatus.find(i => i.id === id);
        if (status){
            return status.name;
        }
        return null;
    }
    execute(){
        this.dialogRef.close( { smsBrandname : this.smsBrandname, listConfigs: this.listConfigs });
    }
    onNoClick(){
        this.dialogRef.close();
    }
}
