import { BaseModel } from 'app/shared/models/base.model';
import { DefineAttribute } from '../define-attributes/define-attributes.model';
import { POS } from '../orgchart-pos/model/pos.model';
import { Images } from 'app/shared/components/upload-images/image.model';
import { Address } from 'app/shared/models/address.model';
import { SocialUrl } from 'app/shared/models/social-url.model';

export class Employee extends BaseModel {
  id: string;
  identityCode: string;
  name: string;
  code: string;
  avatar: string;
  password: string;
  email: string;
  phone: string;
  extPhone: string;
  status: string;
  attributes: any;
  group: any;
  permissions: any;
  images: Images;
  system: string = 'O2O';
  // Override this property to work with current logic.
  description: string = '';
  role: any;
  roleId: string = '';
  posId: string = '';
  level: number;
  workingAt: string;
  managerAt: string;
  pos: any;
  interestedArea: Address;
  socialUrls: SocialUrl[];
  relationIinviter: string = "";
  createdAt: Date;
  inviter: object;
  identityFrontImage: string = null;
  identityBackImage: string = null;
  gender: string = '';
  dob: string = '';
  identityDate: string = "";
  identityAddress: string = "";
  taxId: string = "";
  address: string;
  registerStatus: string;
  secondEmail: string;
  user: any;
  isManage: boolean;
  erp : any;
  erpCode: string;
  erpUrl: string;
  isBqlPrefect: boolean;
  isBql: boolean;
  isAdmin: boolean;

  static createObjectReference(employee: any) {
    let object: any = {};
    employee.id && (object.id = employee.id || null);
    object.identityCode = employee.object;
    object.email = employee.email;
    object.code = employee.code;
    object.name = employee.name;
    object.phone = employee.phone;
    object.extPhone = employee.extPhone;
    object.roleId = employee.roleId || '';
    object.images = employee.images.createObjectRequestAPI();
    // object.identityFrontImage = employee.identityFrontImage;
    // object.identityBackImage = employee.identityBackImage;
    // object.gender = employee.gender;
    // object.dob = employee.dob;
    // object.identityDate = employee.identityDate;
    // object.identityAddress = employee.identityAddress;
    // object.taxId = employee.taxId;
    // object.address = employee.address;
    return object;
  }

  static getType() {
    return 'employee';
  }

  constructor(object?: any) {
    super();
    if (object) {
      this.id = object.id || '';
      this.identityCode = object.identityCode;
      this.name = object.name;
      this.code = object.code;
      this.password = object.password;
      this.avatar = object.avatar || '';
      this.status = object.status;
      this.phone = object.phone;
      this.extPhone = object.extPhone;
      this.email = object.email;
      this.attributes = object.attributes || [];
      this.permissions = object.permissions || [];
      // this.pos = object.pos ? new POS(object.pos) :  new POS();
      this.images = object.images ? new Images(object.images) : new Images();
      this.description = object.description || '';
      this.system = object.system;
      this.role = object.role || null;
      this.roleId = object.roleId || '';
      this.level = object.level;
      this.workingAt = object.workingAt;
      this.managerAt = object.managerAt;
      this.pos = object.pos;
      this.posId = object.posId;
      this.isManage = object.isManage;
      this.interestedArea = object.interestedArea ? new Address(object.interestedArea) : new Address();
      this.socialUrls = object.socialUrls ? object.socialUrls.map((item: any) => new SocialUrl(item)) : [];
      this.relationIinviter = object.relationIinviter;
      this.createdAt = object.createdAt;
      this.inviter = object.inviter;
      this.identityFrontImage = object.identityFrontImage;
      this.identityBackImage = object.identityBackImage;
      // this.gender = object.gender;
      // this.dob = object.dob;
      this.identityDate = object.identityDate;
      this.identityAddress = object.identityAddress;
      this.taxId = object.taxId;
      // this.address = object.address;
      this.registerStatus = object.registerStatus;
      this.secondEmail = object.secondEmail;
      this.erp = object.erp;
      this.erpCode = object.erp ? object.erp.code : '';
      this.erpUrl = object.erp ? object.erp.url : '';
      this.isBqlPrefect = object.isBqlPrefect || false;
      this.isBql = object.isBql || false;
      this.isAdmin = object.isAdmin || false;
    } else {
      this.setAllNull();
    }
    super.setValue(object);
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id || '';
      this.identityCode = params.identityCode;
      this.name = params.name;
      this.code = params.code;
      this.password = params.password;
      this.avatar = params.avatar || '';
      this.status = params.status;
      this.phone = params.phone;
      this.extPhone = params.extPhone;
      this.email = params.email;
      this.attributes = params.attributes || [];
      this.permissions = params.permissions || [];
      this.images = params.images ? new Images(params.images) : new Images();
      this.description = params.description || '';
      this.system = params.system;
      this.role = params.role || null;
      this.roleId = params.roleId || '';
      this.level = params.level;
      this.workingAt = params.workingAt;
      this.managerAt = params.managerAt;
      this.isManage = params.managerAt ? true : false;
      this.pos = params.pos;
      this.posId = params.pos ? params.pos.id : '';
      this.interestedArea = params.interestedArea ? new Address(params.interestedArea) : new Address();
      this.socialUrls = params.socialUrls ? params.socialUrls.map((item: any) => new SocialUrl(item)) : [];
      this.relationIinviter = params.relationIinviter;
      this.createdAt = params.createdAt;
      this.inviter = params.inviter;
      this.identityFrontImage = params.identityFrontImage;
      this.identityBackImage = params.identityBackImage;
      // this.gender = params.gender;
      // this.dob = params.dob;
      this.identityDate = params.identityDate;
      this.identityAddress = params.identityAddress;
      this.taxId = params.taxId;
      this.secondEmail = params.secondEmail;
      this.erp = params.erp;
      this.erpCode = params.erp ? params.erp.code : '';
      this.erpUrl = params.erp ? params.erp.url : '';
      this.isBqlPrefect = params.isBqlPrefect || false;
      this.isBql = params.isBql || false;
      this.isAdmin = params.isAdmin || false;
      // this.address = params.address;
    } else {
      this.setAllNull();
    }
    super.setValue(params);
  }

  setAllNull() {
    this.id = null;
    this.identityCode = null;
    this.name = null;
    this.avatar = '';
    this.code = '';
    this.email = '';
    this.password = '';
    this.phone = '';
    this.status = 'waiting';
    this.attributes = [];
    this.images = new Images();
    this.description = '';
    this.extPhone = '';
    this.system = 'O2O';
    this.role = null;
    this.roleId = '';
    this.level = null;
    this.workingAt = null;
    this.managerAt = null;
    this.posId = null;
    this.isManage = false;
    this.pos = {};
    this.interestedArea = new Address();
    this.socialUrls = [];
    this.erp = null;
    this.erpCode =  '';
    this.erpUrl = '';
    this.isBqlPrefect = false
    this.isBql = false
    this.isAdmin = false
  }

  createObjectRequestAPI() {
    let object: any = {};
    this.id && (object.id = this.id || '');
    object.identityCode = this.identityCode;
    object.name = this.name;
    object.code = this.code;
    object.email = this.email;
    object.password = this.password;
    object.status = this.status;
    object.permissions = this.permissions;
    object.images = this.images;
    object.avatar = this.avatar;
    object.phone = this.phone;
    object.extPhone = this.extPhone;
    object.description = this.description;
    object.roleId = this.roleId;
    object.images = this.images.createObjectRequestAPI();
    object.attributes = this.attributes.map((attribute: any) => new DefineAttribute(attribute).createObjectRequestAPI());
    object.system = this.system;
    object.level = this.level;
    object.workingAt = this.workingAt;
    object.managerAt = this.managerAt;
    object.pos = this.pos;
    object.interestedArea = this.interestedArea.createObjectRequestAPI();
    object.socialUrls = this.socialUrls.map(item => item.createObjectRequestAPI());
    object.relationIinviter = this.relationIinviter;
    object.createdAt = this.createdAt;
    object.inviter = this.inviter;
    object.identityFrontImage = this.identityFrontImage;
    object.identityBackImage = this.identityBackImage;
    // object.gender = this.gender;
    // object.dob = this.dob;
    object.identityDate = this.identityDate;
    object.identityAddress = this.identityAddress;
    object.taxId = this.taxId;
    object.secondEmail = this.secondEmail;
    // object.address = this.address;
      if (this.posId) {
          object.posAssign = {
              'id': this.posId,
              'is_manager_checked': this.posId ? this.isManage : false
          };
      }
    object.erp = {
      'code' : this.erpCode,
      'url' : this.erpUrl
    };

    object.isBqlPrefect = this.isBqlPrefect;
    object.isBql = this.isBql;
    object.isAdmin = this.isAdmin;

    return object;
  }

  createObjectReference() {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.identityCode = this.identityCode;
    object.email = this.email;
    object.code = this.code;
    object.name = this.name;
    object.phone = this.phone;
    object.extPhone = this.extPhone;
    object.images = this.images;
    object.avatar = this.avatar;
    object.description = this.description;
    object.roleId = this.roleId;
    object.level = this.level;
    object.workingAt = this.workingAt;
    object.managerAt = this.managerAt;
    object.posId = this.posId;
    object.isManage = this.isManage;
    object.pos = this.pos;
    object.interestedArea = this.interestedArea;
    object.relationIinviter = this.relationIinviter;
    object.createdAt = this.createdAt;
    object.inviter = this.inviter;
    // object.identityFrontImage = this.identityFrontImage;
    // object.identityBackImage = this.identityBackImage;
    // object.gender = this.gender;
    // object.dob = this.dob;
    object.identityDate = this.identityDate;
    object.identityAddress = this.identityAddress;
    object.taxId = this.taxId;
    object.secondEmail = this.secondEmail;
    // object.address = this.address;
    return object;
  }
}
