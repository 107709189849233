import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { Constant } from 'app/shared/constant/constant';
import { ToastrService } from 'ngx-toastr';
import { ImagePopup } from '../image-popup/image-popup';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-file-project-uploader',
  templateUrl: './file-project-uploader.component.html',
  styleUrls: ['./file-project-uploader.component.scss']
})
export class FileProjectUploaderComponent {
  @Input() disabled?: boolean;
  @Input() type: string = Constant.UPLOAD_FILE_TYPE.RECEIPT;
  @Input() items?: FileElement[];
  @Output() itemsChange = new EventEmitter();
  @Input() limit: number = 5;
  @Input() isShowNotice: boolean = true;
  Constant = Constant;
  isLoading: boolean = false;
  isDragOver: boolean = false;
  isUploading: boolean = false;
  fileExtensionsExcluded: string[] = ['exe'];
  @Input() accept = '*';

  constructor(
    public uploadFileService: UploadFileService,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  onDragOver(event: any) {
    this.isDragOver = true;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event: any) {
    this.isDragOver = false;
    event.stopPropagation();
    event.preventDefault();
  }

  onDrop(event: any) {
    this.isDragOver = false;
    event.preventDefault();
    const selectedFile = event.dataTransfer.files;
    this.addFile(selectedFile);
  }

  onFileSelected(event: any) {
    const selectedFiles: File[] = event.target.files;
    this.addFile(selectedFiles);
  }

  openFile(item: FileElement) {
    if (this.checkImage(item.absoluteUrl || item.url)) {
      // image, open popup
      this.openImagePopup(item.absoluteUrl || item.url);
    } else {
      // other file type, download
      window.open(item.absoluteUrl || item.url);
    }
  }

  checkImage(url: string) {
    const a = url.match(/\.(jpeg|jpg|gif|png|heic)$/);
    return a != null;
  }
  openImagePopup(url: string): void {
    this.dialog.open(ImagePopup, {
      width: '1000px',
      data: {
        url: url
      }
    });
  }

  async addFile(files: File[]) {
    // Validate file extension
    for (let file of files) {
      const fileExtension = file.name.split('.').pop();
      if (this.fileExtensionsExcluded.includes(fileExtension)) {
        this.toastr.error('Lỗi!', 'File tải lên không hợp lệ: ' + file.name);
        return;
      }
    }
    // Validate file limit
    if (files.length > this.limit || (files.length + this.items.length) > this.limit) {
      // Show notification
      this.toastr.error('Lỗi!', 'Giới hạn tải tối đa ' + this.limit + ' files');
      return;
    }
    // Validate file size
    const filesSizeInvalid: string[] = [];
    for (let file of files) {
      if (file.size > (10 * 1024 * 1024)) {
        filesSizeInvalid.push(file.name);
      }
    }
    if (filesSizeInvalid.length > 0) {
      // Show notification
      this.toastr.error('Lỗi!', 'Kích thước file tải lên quá 10MB: ' + filesSizeInvalid.join(','));
      return;
    }
    // Upload file
    this.isUploading = true;
    try {
      let results = [];
      let url = '';
      switch (this.type) {
        case Constant.UPLOAD_FILE_TYPE.RECEIPT, Constant.UPLOAD_FILE_TYPE.PROPERTY_TICKET: {
          url = '/document-receipt';
          results = await this.uploadFileService.uploadDocument(files, url);
          break;
        }
        case Constant.UPLOAD_FILE_TYPE.CONTRACT_LIQUIDATE: {
          url = '/documents';
          let datas = [
            { key: 'typeValidate', value: 'contract-liquidate' }
          ];
          results = await this.uploadFileService.uploadDocument(files, url, datas);
          break;
        }
        default: {
          url = '/document-receipt';
          results = await this.uploadFileService.uploadDocument(files, url);
          break;
        }
      }
      for (const result of results) {
        // Check if file name existed
        const fileIndex = this.items.findIndex((file: FileElement) => file.name === result.originalName);
        if (fileIndex === -1) {
          // Add new file
          this.items.push(<FileElement>{
            name: result.originalName,
            url: result.url,
            absoluteUrl: result.absoluteUrl,
            uploadName: result.uploadName || ''
          });
        } else {
          // Replace old file
          this.items[fileIndex].url = result.url;
        }
      }
      this.isUploading = false;
      this.itemsChange.emit(this.items);
    } catch (err) {
      this.isUploading = false;
    }
  }

  async hideFile(item: FileElement) {
    if (!item.url) {
      return;
    }
    const itemIndex = this.items.findIndex((file: FileElement) => file.url === item.url);
    this.items.splice(itemIndex, 1);
    this.itemsChange.emit(this.items);
  }

}

export interface FileElement {
  name: string;
  url: string;
  absoluteUrl: string;
  uploadName: string;
}
