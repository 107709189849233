import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Address } from 'app/shared/models/address.model';
import { ConsignmentService } from 'app/pages/consignment/consignment.service';
import { StorageService } from 'app/shared/services';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import { Constant } from 'app/shared/constant/constant';
import { QUOC_GIA } from 'app/shared/constant/countries';
const VIET_NAM = 'Việt Nam';

@Component({
  selector: 'app-form-input-address',
  templateUrl: './form-input-address.component.html',
  styleUrls: ['./form-input-address.component.scss'],
  providers: [ConsignmentService]
})
export class FormInputAddressComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() rootAddress: Address = new Address();
  @Input() address: Address = new Address();
  @Input() isValidateAddress: boolean = false;
  @Input() hideAddress: boolean = false;
  @Input() showLabel: boolean = true;
  @Output() rootAddressChange: EventEmitter<Address> = new EventEmitter();
  @Output() addressChange: EventEmitter<Address> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();
  
  public Constant = Constant;
  public mainForm: FormGroup = null;
  public listCountryRootAddress: Country[] = JSON.parse(QUOC_GIA);
  public listCountryAddress: Country[] = JSON.parse(QUOC_GIA);
  public listProvince: Province[] = JSON.parse(HANH_CHINH_VN);
  public listProvinceRootAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictRootAddress: District[] = [];
  public listWardRootAddress: string[] = [];
  public listProvinceAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictAddress: District[] = [];
  public listWardAddress: string[] = [];
  public countValidate: number = 0;
  public get fullRootAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('rootAddressText').value ? result += `${this.mainForm.get('rootAddressText').value}` : result += '';
    this.mainForm.get('rootAddressWard').value ? result += `${dashSpace + this.mainForm.get('rootAddressWard').value}` : result += '';
    this.mainForm.get('rootAddressDistrict').value ? result += `${dashSpace + this.mainForm.get('rootAddressDistrict').value}` : result += '';
    this.mainForm.get('rootAddressProvince').value ? result += `${dashSpace + this.mainForm.get('rootAddressProvince').value}` : result += '';
    this.mainForm.get('rootAddressCountry').value ? result += `${dashSpace + this.mainForm.get('rootAddressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }
  public get fullAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('addressText').value ? result += `${this.mainForm.get('addressText').value}` : result += '';
    this.mainForm.get('addressWard').value ? result += `${dashSpace + this.mainForm.get('addressWard').value}` : result += '';
    this.mainForm.get('addressDistrict').value ? result += `${dashSpace + this.mainForm.get('addressDistrict').value}` : result += '';
    this.mainForm.get('addressProvince').value ? result += `${dashSpace + this.mainForm.get('addressProvince').value}` : result += '';
    this.mainForm.get('addressCountry').value ? result += `${dashSpace + this.mainForm.get('addressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }

  constructor(
    public formBuilder: FormBuilder,
    public consignmentService: ConsignmentService,
    public storageService: StorageService) {
  }


  ngOnInit() {
    this.initForm(this.rootAddress, this.address);
  }

  ngOnChanges() {
    this.initForm(this.rootAddress, this.address);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearValidateAddress() {
      if(this.countValidate > 0){
        if(this.isValidateAddress){
            const listValidates: string[] = ['addressCountry', 'addressProvince', 'addressDistrict', 'addressWard', 'addressText'];
            const {addressProvince, addressDistrict, addressWard, addressText} = this.mainForm.getRawValue();
            if (!!addressProvince || !!addressDistrict || !!addressText || !!addressWard) {
                listValidates.forEach((key: string) => {
                    this.mainForm.controls[key].clearValidators();
                    this.mainForm.controls[key].updateValueAndValidity();
                });

            } else {
                listValidates.forEach((key: string) => {
                    this.mainForm.controls[key].setValidators([Validators.required]);
                    this.mainForm.controls[key].updateValueAndValidity();
                });
            }
            this.markAsTouched(this.mainForm);
          }
      }
  }

  validateFormAddress() {
    let isValid: boolean = true;
    this.countValidate++;
      if (this.isValidateAddress) {
        const {addressProvince, addressDistrict, addressWard, addressText} = this.mainForm.getRawValue();
        const listValidates: string[] = ['addressCountry', 'addressProvince', 'addressDistrict', 'addressWard', 'addressText'];
        if (!!addressProvince || !!addressDistrict || !!addressText || !!addressWard) {
            listValidates.forEach((key: string) => {
                this.mainForm.controls[key].clearValidators();
                this.mainForm.controls[key].updateValueAndValidity();
            });
        } else {
            listValidates.forEach((key: string) => {
                this.mainForm.controls[key].setValidators([Validators.required]);
                this.mainForm.controls[key].updateValueAndValidity();
            });
            if (!this.mainForm) {
                isValid = false;
              }
              this.markAsTouched(this.mainForm);
              isValid = this.mainForm.valid;
        }
      }
      return isValid;
  }

  initForm(rootAddress: Address, address: Address) {
    if (!rootAddress || !address) { return; }

    rootAddress.country = rootAddress.country || VIET_NAM;
    this.loadRootAddressOptions(rootAddress);
    address.country = address.country || VIET_NAM;
    this.loadAddressOptions(address);
    if (this.mainForm) {
      this.mainForm.reset();
      // Patch existed form
      this.mainForm.setValue({
        // Địa chỉ thường trú
        rootAddressCountry: rootAddress.country ? rootAddress.country : null,
        rootAddressProvince: rootAddress.province ? rootAddress.province : null,
        rootAddressDistrict: rootAddress.district ? rootAddress.district : null,
        rootAddressWard: rootAddress.ward ? rootAddress.ward : null,
        rootAddressText: rootAddress.address ? rootAddress.address : null,
        // Địa chỉ liên lạc
        isCopyRootAddress: false,
        addressCountry: address.country ? address.country : null,
        addressProvince: address.province ? address.province : null,
        addressDistrict: address.district ? address.district: null,
        addressWard: address.ward ? address.ward : null,
        addressText: address.address ? address.address : null,
      });
      // Update input disable/enable state
      if (this.disabled) {
        this.mainForm.disable();
      } else {
        this.mainForm.enable();
        this.onChangeCountryRootAddress(this.getFormControl('rootAddressCountry').value);
        this.onChangeCountryAddress(this.getFormControl('addressCountry').value);
        // this.mainForm.get('rootAddressCountry').disable();
        // this.mainForm.get('addressCountry').disable();
      }
    } else {

      this.mainForm = this.formBuilder.group({

        // Địa chỉ thường trú
        rootAddressCountry: [{ value: rootAddress.country, disabled: this.disabled }],
        rootAddressProvince: [{ value: rootAddress.province, disabled: this.disabled }],
        rootAddressDistrict: [{ value: rootAddress.district, disabled: this.disabled }],
        rootAddressWard: [{ value: rootAddress.ward, disabled: this.disabled }],
        rootAddressText: [{ value: rootAddress.address, disabled: this.disabled }],
        // Địa chỉ liên lạc
        isCopyRootAddress: [{ value: false, disabled: this.disabled }],
        addressCountry: [{ value: address.country, disabled: this.disabled}],
        addressProvince: [{ value: address.province, disabled: this.disabled }],
        addressDistrict: [{ value: address.district, disabled: this.disabled }],
        addressWard: [{ value: address.ward, disabled: this.disabled }],
        addressText: [{ value: address.address, disabled: this.disabled }],
      });

      this.mainForm.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          // Patch data
          this.rootAddress = this.patchFormValueRootAddress(this.rootAddress, this.mainForm.getRawValue());
          this.address = this.patchFormValueAddress(this.address, this.mainForm.getRawValue());
          // Emit new data
          this.rootAddressChange.emit(this.rootAddress);
          this.hideAddress ? this.addressChange.emit(this.rootAddress) : this.addressChange.emit(this.address);
          // Emit form valid state
          this.validChange.emit(this.mainForm.valid);
        });
      // Subscribe rootAddress change
      Observable.merge(
        this.mainForm.get('rootAddressCountry').valueChanges,
        this.mainForm.get('rootAddressProvince').valueChanges,
        this.mainForm.get('rootAddressDistrict').valueChanges,
        this.mainForm.get('rootAddressWard').valueChanges,
        this.mainForm.get('rootAddressText').valueChanges)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => this.copyRootAddress());
      // Subscribe isCopyRootAddress change
      this.mainForm.get('isCopyRootAddress').valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: boolean) => this.onChangeAddress(res));
    }

  }

  // PUBLIC
  public validateForm(): boolean {
    if (!this.mainForm) {
      return false;
    }
    this.markAsTouched(this.mainForm);
    return this.mainForm.valid;
  }

  copyRootAddress() {
    if (this.mainForm.get('isCopyRootAddress').value) {
      // If isCopyRootAddress === trueCopy then copy root address to address
      const data = {
        addressCountry: this.mainForm.get('rootAddressCountry').value,
        addressProvince: this.mainForm.get('rootAddressProvince').value,
        addressDistrict: this.mainForm.get('rootAddressDistrict').value,
        addressWard: this.mainForm.get('rootAddressWard').value,
        addressText: this.mainForm.get('rootAddressText').value
      };
      this.mainForm.patchValue(data); // Prevent emit change event => infinity loop
    }
  }

  onChangeAddress(value: boolean) {
    // If form is disabled, no control status change allowed
    if (this.disabled) {
      return;
    }
    if (value) {
      this.copyRootAddress();
      // this.mainForm.get('addressCountry').disable();
      this.mainForm.get('addressProvince').disable();
      this.mainForm.get('addressDistrict').disable();
      this.mainForm.get('addressWard').disable();
      this.mainForm.get('addressText').disable();
    } else {
      // this.mainForm.get('addressCountry').enable();
      this.mainForm.get('addressProvince').enable();
      this.mainForm.get('addressDistrict').enable();
      this.mainForm.get('addressWard').enable();
      this.mainForm.get('addressText').enable();
    }
  }

  /** BEGIN - Xử lý Select Địa chỉ thường trú */
  onChangeProvinceRootAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      this.mainForm.patchValue({
        rootAddressDistrict: null,
        rootAddressWard: null
      });
      this.listDistrictRootAddress = [];
      this.listWardRootAddress = [];
      return;
    }
    this.mainForm.patchValue({
      rootAddressDistrict: null,
      rootAddressWard: null
    });
    this.listDistrictRootAddress = currentProvince.d;
  }

  onChangeDistrictRootAddress(districtName: string) {
    const provinceName = this.mainForm.get('rootAddressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      this.mainForm.patchValue({
        rootAddressWard: null
      });
      this.listWardRootAddress = [];
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      this.mainForm.patchValue({
        rootAddressWard: null
      });
      this.listWardRootAddress = [];
      return;
    }
    this.mainForm.patchValue({
      rootAddressWard: null
    });
    this.listWardRootAddress = currentDistrict.w;
  }
  onChangeProvinceAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      this.mainForm.patchValue({
        addressDistrict: null,
        addressWard: null
      });
      this.listDistrictAddress = [];
      this.listWardAddress = [];
      return;
    }
    this.mainForm.patchValue({
      addressDistrict: null,
      addressWard: null
    });
    this.listDistrictAddress = currentProvince.d;
    this.clearValidateAddress();
  }

  onChangeDistrictAddress(districtName: string) {
    const provinceName = this.mainForm.get('addressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      this.mainForm.patchValue({
        addressWard: null
      });
      this.listWardAddress = [];
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      this.mainForm.patchValue({
        addressWard: null
      });
      this.listWardAddress = [];
      return;
    }
    this.mainForm.patchValue({
      addressWard: null
    });
    this.listWardAddress = currentDistrict.w;
    this.clearValidateAddress();
  }
  onChangeAddessText(event){
    this.clearValidateAddress();
  }

  loadRootAddressOptions(rootAddress: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === rootAddress.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictRootAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === rootAddress.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardRootAddress = currentDistrict.w;
  }

  loadAddressOptions(address: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === address.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === address.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardAddress = currentDistrict.w;
  }
  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  patchFormValueRootAddress(model: Address, formData: any): Address {

    const rootAddress: Address = new Address({
      country: formData.rootAddressCountry,
      province: formData.rootAddressProvince,
      district: formData.rootAddressDistrict,
      ward: formData.rootAddressWard,
      address: formData.rootAddressText
    });
    model = rootAddress;
    return model;
  }
  patchFormValueAddress(model: Address, formData: any): Address {

    const address: Address = new Address({
      country: formData.addressCountry,
      province: formData.addressProvince,
      district: formData.addressDistrict,
      ward: formData.addressWard,
      address: formData.addressText
    });
    model = address;
    return model;
  }
  onChangeCountryRootAddress(countryName: string) {
    if (countryName === VIET_NAM) {
      this.mainForm.get('rootAddressProvince').enable();
      this.mainForm.get('rootAddressDistrict').enable();
      this.mainForm.get('rootAddressWard').enable();
    } else {
      this.mainForm.get('rootAddressProvince').reset();
      this.mainForm.get('rootAddressDistrict').reset();
      this.mainForm.get('rootAddressWard').reset();
      this.mainForm.get('rootAddressProvince').disable();
      this.mainForm.get('rootAddressDistrict').disable();
      this.mainForm.get('rootAddressWard').disable();
    }
  }
  onChangeCountryAddress(countryName: string) {
    if (countryName === VIET_NAM) {
      this.mainForm.get('addressProvince').enable();
      this.mainForm.get('addressDistrict').enable();
      this.mainForm.get('addressWard').enable();
    } else {
      this.mainForm.get('rootAddressProvince').reset();
      this.mainForm.get('rootAddressDistrict').reset();
      this.mainForm.get('rootAddressWard').reset();
      this.mainForm.get('addressProvince').disable();
      this.mainForm.get('addressDistrict').disable();
      this.mainForm.get('addressWard').disable();
    }
  }

  onClear() {
    this.mainForm.reset();
  }
}

interface Province {
  n: string;  // Name
  d: District[]; // Districts
}
interface District {
  n: string;  // Name
  w: string[];  // Wards
}
interface Country {
  name: string;  // Name
}
