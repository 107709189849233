import { Injectable, OnInit, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizeService } from '../services/common/authorize.service';

@Injectable()
export class CanActiveAccessService implements CanActivate {
    authorizeService: any;
    constructor(private router: Router,
                private injector: Injector) {
                    this.authorizeService = injector.get(AuthorizeService);
                }

    canActivate(route: ActivatedRouteSnapshot) {
       return this.checkLogin(route);
    }
    /*
    * allow access page if satisfies 2 rules:
    ** 1. activeRoute uri existed on list feature of authorize user
    ** 2. status of authorize user is active
    */
    checkLogin(activeRoute) {
        if (activeRoute._routerState.url === '/accessdenied') {
            return false;
        }
        let checkRoleFeature = false;
    }
}
