import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges } from '@angular/core';
import { DefineAttribute } from '../../../pages/define-attributes/define-attributes.model';
import { Constant } from '../../constant/constant';
import { Options } from 'ng5-slider';

@Component({
    selector: 'dynamic-attribute',
    templateUrl: './dynamic-attribute.component.html',
    styleUrls: ['./dynamic-attribute.component.scss'],
})

export class DynamicAttributeComponent implements OnInit, OnChanges {
    @Input() attribute;
    @Input() value;
    @Input() disable;
    @Input() config;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    orders: any = Constant.ORDERS;
    controlTypes: any = Constant.CONTROL_TYPES_OBJECT;
    optionsNg5Slider: Options = {
        floor: 0,
        ceil: 100,
        step: 1,
    };
    Constant = Constant;
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    constructor(
    ) {
    }
    ngOnInit() {
        !this.config && (this.config = {}) 
    }
    ngOnChanges(){
        this.attribute = new DefineAttribute(this.attribute);
    }
}
