import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'TrainingSearchPipe'})
export class TrainingSearchPipe implements PipeTransform {
    transform (value, args?): Array<any> {
        let searchText = new RegExp(args, 'ig')
        if (value) {
            return value.filter(user => {
                return user.email.search(searchText) !== -1 || user.name.search(searchText) !== -1 || user.phoneNumber.search(searchText) !== -1
            })
        }
    }
}
