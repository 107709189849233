import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DataService } from '../data.service';
import { HttpService } from '../http.service';
import { CDocument } from 'app/api-models/c-document';
import { map, delay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EUploadType } from 'app/enums/e-upload-type.enum';
import { StorageService } from '..';
import { ConstantUrl } from 'app/shared/constant/url';

@Injectable()
export class UploadFileService {


  FOLDER = 'jsa-s3/';
  url = ConstantUrl.url_upload;
  constructor(
    private http: DataService,
    private httpService: HttpService,
    private storageService: StorageService
  ) { }


  uploadFileV2(files: File[], typeValidate?: EUploadType): Observable<CDocument[]> {
    const params = { files, typeValidate: typeValidate };
    return this.httpService.post(this.url, this.httpService.convertAnyToFormData(params))
      .pipe(
        delay(1000),
        map((resApi: any) => new CDocument().decodeList(resApi))
      );
  }

  uploadFileV3(files: File[], typeValidate?: EUploadType, projectId?: string, isSetFolder?: boolean): Observable<CDocument[]> {
    let params;
    if (isSetFolder) {
      params = { files, typeValidate: typeValidate, projectId: projectId, public: true };
    } else {
      params = { files, typeValidate: typeValidate, projectId: projectId};
    }
    return this.httpService.post(this.url, this.httpService.convertAnyToFormData(params))
      .pipe(
        delay(1000),
        tap((resApi: any) => {}),
        map((resApi: any) => new CDocument().decodeList(resApi))
      );
  }

  uploadFileV4(files, typeValidate?) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      if (files.length) {
        for (let file of files) {
          formData.append("files", file);
        }
      } else {
        formData.append("files", files);
      }
      if (typeValidate) {
        formData.append("typeValidate", typeValidate);
      }
      return this.http.post(this.url, formData, null, 180000)
        .subscribe((response) => {
          resolve(response);
        },
          (error) => {
            reject(error);
          })
    })

  }

  uploadFile(files, typeValidate?) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      if (files.length) {
        for (let file of files) {
          formData.append("files", file);
        }
      } else {
        formData.append("files", files);
      }
      if (typeValidate) {
        formData.append("typeValidate", typeValidate);
      }
      return this.http.post(this.url, formData, null, 0, true)
        .subscribe((response) => {
          resolve(response);
        },
          (error) => {
            reject(error);
          })
    })

  }

  public uploadDocument(files: File[], url, datas?): Promise<any> {
    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    if (datas) {
      for (let data of datas) {
        formData.append(data.key, data.value);
      }
    }
    return this.http.post(this.url + url, formData).toPromise().then((res) => res.json());
  }
  public uploadFileESaleKit(files: File): Observable<any> {
    let formData = new FormData();
    formData.append('files', files);
    formData.append('featureName', 'esalekit');
    formData.append('id', '3');
    formData.append('createThumbnail', 'true');
    return this.httpService.post(this.url + '/upload-file', formData);
  }
}
