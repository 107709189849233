import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Constant } from '../../constant/constant';
import { UploadFileService } from '../../services/common/upload-file.service';
import { Images, ZoneImage, Image } from './image.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ImagePopup } from '../image-popup/image-popup';

@Component({
    selector: 'upload-images',
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.scss'],
})

export class UploadImagesComponent implements OnInit, OnChanges {
    @Input() data;
    @Input() type;
    @Input() isView;
    @Input() fileExtensions: string[];
    @Input() addZoneDisabled: boolean;
    @Input() defaultListName: string;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    Constant = Constant;
    private currentIndex: any  = 0;
    public images: any = new Images();
    public isUpload: boolean = false;
    public indexChangeZoneName: any;
    public zoneName: any;
    constructor(
        public uploadFileService: UploadFileService,
        public toastr: ToastrService,
        public dialog: MatDialog,
    ) {
        this.fileExtensions = ['jpeg', 'jpg', 'png', 'heic'];
    }
    ngOnInit() {

    }
    ngOnChanges(event) {
        if (event && event.data && event.data.currentValue) {
            this.images = event.data.currentValue;
        } else {
            this.images = new Images();
        }
        if (this.type == 'property' && this.images.zones.length == 0) {
            this.images.zones = [new ZoneImage({name: 'Trong nhà'}),  new ZoneImage({name:'Trước nhà'}) ,  new ZoneImage({name:'Sổ hồng'}),   new ZoneImage({name:'Image 3D'})];
        }
        if (this.type == 'contract' && this.images.zones.length == 0) {
            this.images.zones = [new ZoneImage({name: 'Hợp đồng'}),  new ZoneImage({name:'Phụ lục'}) ,  new ZoneImage({name:'Chữ ký'})];
        }
    }
    addZone() {
        let volume = 0, zoneName = Constant.TYPE_IMAGES.icon;
        this.images.zones.forEach(zone => zone.name.includes(Constant.TYPE_IMAGES.icon) && volume++);
        if (volume > 0) {
            zoneName += ' ' + volume;
        }
        this.images.zones.push(new ZoneImage({
            name: zoneName
        }));
    }
    removeZone(zoneName) {
        let position = null;
        this.images.zones.forEach((zone, index) => {
            if (zone.name == zoneName) {
                position = index;
            }
        });
        this.images.zones.splice(position, 1);
    }
    uploadFile(index?, item?) {
        this.currentIndex = index;
        this.images.selectedItem = item;
        if (index || index == 0) {
            $('#zoneUpload' + index).click();
            if (!item) {
                let image = new Image({
                    name: this.images.zones[index].name + '-' + this.images.zones[index].list.length,
                    text: this.images.zones[index].name + ' ' + this.images.zones[index].list.length
                });
                this.images.zones[index].list.push(image);
                this.images.selectedItem = image;
            }
        } else {
            $('#inputFile').click();
            if (!item) {
                let image = new Image({
                    name: 'IMAGES-' + this.images.list.length,
                    text: 'Images ' + this.images.list.length
                });
                this.images.list.push(image);
                this.images.selectedItem = image;
            }
        }

    }
    selectFile(event) {
        // const file = event.target.files[0];
        // check maximum items upload
        if (event.target.files.length > Constant.MAX_ITEMS_UPLOAD) {
            event.target.value = ''
            this.toastr.error('Lỗi!', 'Hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.');
            return;
        }
        for (let file of event.target.files) {
            if ( !this.validateIfImage(file) ) {
                event.target.value = ''
                this.toastr.error('Lỗi!', 'Hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.');
                return;
            }
        }
        this.isUpload = true;
        this.images.selectedItem.loading = true;
        return this.uploadFileService.uploadFile(event.target.files, this.type)
            .then((res: any) => {
                event.target.value = ''
                let data = res.json();
                let flagFirst = true;
                setTimeout(() => {
                    for (let item of data) {
                        this.setImageData(item, flagFirst);
                        flagFirst = false;
                        this.images.selectedItem.loading = false;
                    }
                }, 1000 * data.length);
            })
            .catch((error) =>{
                event.target.value = ''
                this.images.selectedItem.loading = false;
                return;
            });
    }
    setImageData(file, flagFirst) {
        let name = file.originalName;
        let names = [];
        while (name.length > 11) {
            names.push(name.substring(0, 11));
            name = name.substring(11, name.length);
        }
        names.push(name);
        name = names.join('');
        if (flagFirst === true){
            this.images.selectedItem.name = this.images.selectedItem.nameChange = name;
            this.images.selectedItem.url = file.url;
        } else {
            let image = new Image({
                isDisplay : false,
                text : 'Images ' + this.images.list.length,
                name : name,
                nameChange : name,
                url : file.url,
                textChange : '',
                loading: false
            });
            this.images.zones[this.currentIndex].list.push(image);
        }
    }
    createInputChangeName(index) {
        this.indexChangeZoneName = index;
        this.zoneName = this.images.zones[this.indexChangeZoneName].name;
    }
    changeName(object, type?) {
        !type && (type = 'name');
        object[type] = object[type + 'Change'];
        object.isChange = false;
    }
    cancelChangeName(object, type) {
        object.isChange = false;
        object[type + 'Change'] = object[type];
    }
    emitData(isValidated?) {
        // if (this.type == 'property') {
        //     let findImage = this.images.zones.every(zone =>
        //         {
        //             return (zone.list.length > 0 && zone.list.every(image => image.url)) || zone.name == 'Image 3D';
        //     });
        //     if (!findImage) {
        //         isValidated && this.toastr.error('Phải có ít nhất 1 hình ảnh trong mỗi group Trong nhà, Ngoài trời, Sổ hồng!', 'Images');
        //         return null;
        //     }
        // }
        return {
            list: this.images.list.map((image) => {
                return new Image(image).createObjectRequestAPI();
            }),
            zones: this.images.zones
        }

    }
    removeImage(zone, item) {
        if (zone && zone.list) {
            let position = zone.list.indexOf(item);
            zone.list.splice(position, 1);
        }
    }

    validateIfImage(file: any): boolean {
    if (!!!file) {
        return false;
    }
    // check max size image 5MB
    if (file.size > Constant.MAX_SIZE_IMAGE) {
        return false;
    }
    return this.fileExtensions.includes(file.name.split('.').pop().toLocaleLowerCase());
    }
    openFile(item) {
        if (this.checkImage(item.url)) {
            // image, open popup
            this.openImagePopup(item.url);
        } else {
            // other file type, download
            window.open(item.url);
        }
    }
    checkImage(url: string) {
        const a = url.match(/\.(jpeg|jpg|gif|png|heic)$/);
        return a != null;
    }
    openImagePopup(url: string): void {
        this.dialog.open(ImagePopup, {
            width: '1000px',
            data: {
                url: url
            }
        });
    }
}
