import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'TrainingEventRegisterPipe' })
export class TrainingEventRegisterPipe implements PipeTransform {
    transform (value, args?): Array<any> {
        let searchText = new RegExp(args, 'ig')
        if (value) {
            return value.filter(user => {
                if (!user.personalInfo.email && user.personalInfo.name && user.personalInfo.phone) {
                    return (
                        user.personalInfo.name.search(searchText) !== -1 ||
                        user.personalInfo.phone.search(searchText) !== -1
                    )
                }
                if(user.personalInfo.email && user.personalInfo.name && user.personalInfo.phone){
                    return (
                        user.personalInfo.email.search(searchText) !== -1 ||
                        user.personalInfo.name.search(searchText) !== -1 ||
                        user.personalInfo.phone.search(searchText) !== -1
                    )
                }
            })
        }
        return value
    }
}

