import { IDecode } from './i-decode';

export class CIdName implements IDecode<CIdName> {

    public id: string | number;
    public name: string;
    public count: number;

    constructor(params: Partial<CIdName> = {}) {
        this.copy(params);
    }

    static copyList(params: Partial<CIdName>[] = []): CIdName[] {
        return params.map(el => new CIdName().copy(el));
    }

    copy(params: Partial<CIdName> = {}): CIdName {

        this.id = params.id || null;
        this.name = params.name || null;
        this.count = params.count || null;

        return this;
    }

    decode(paramsApi: any): CIdName {
        return new CIdName(paramsApi);
    }

    decodeList(paramsApi: any[]): CIdName[] {
        return paramsApi.map(el => new CIdName().decode(el));
    }
}
