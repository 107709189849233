import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, Validators, FormControl} from '@angular/forms';

@Component({
    selector: 'app-form-input-dynamic-v2',
    templateUrl: './form-input-dynamic-v2.component.html',
    styleUrls: ['./form-input-dynamic-v2.component.scss']
})
export class FormInputDynamicV2Component implements OnInit, OnChanges {
    @Input() data: any = {};
    @Input() disabled: boolean;
    @Output() submitForm: EventEmitter<any> = new EventEmitter();
    dynamicForm: FormGroup;
    listType = [
        {label: 'Trường đơn', value: 'Input'},
        {label: 'Mảng', value: 'Array'},
        {label: 'Đối tượng', value: 'Object'},
    ];

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        if (!this.dynamicForm) {
            this.dynamicForm = this.formBuilder.group({
                form: new FormArray([])
            });
        }
    }

    ngOnChanges() {
        if (!this.dynamicForm) {
            this.dynamicForm = this.formBuilder.group({
                form: new FormArray([])
            });
        }
        for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
                const e = this.data[key];
                switch (typeof e) {
                    case 'string':
                        const inputForm = this.createFormBuilder('Input', key, e);
                        this.forms.push(inputForm);
                        break;
                    case 'object':
                        if (e.length > 0) {
                            const arrForm = this.createFormBuilder('Array', key);
                            e.forEach(arrValue => {
                                this.addArrayForm(arrForm, arrValue);
                            });
                            this.forms.push(arrForm);
                        } else {
                            const objForm = this.createFormBuilder('Object', key);
                            for (const objKey in e) {
                                if (e.hasOwnProperty(objKey)) {
                                    const objValue = e[objKey];
                                    this.addObjectForm(objForm, objKey, objValue);
                                }
                            }
                            this.forms.push(objForm);
                        }
                        break;

                    default:
                        break;
                }
            }
        }
    }

    // convenience getters for easy access to form fields
    get getControls() {
        return this.dynamicForm.controls;
    }

    get forms() {
        return this.getControls.form as FormArray;
    }

    createFormBuilder(type = '', key = '', value = '') {
        return this.formBuilder.group({
            type: new FormControl({value: type, disabled: this.disabled}, Validators.required),
            key: new FormControl({value: key, disabled: this.disabled}, Validators.required),
            value: new FormControl({value: value, disabled: this.disabled}),
            array: new FormArray([]),
            object: new FormArray([]),
        });
    }

    addDynamicForm() {
        const newForm = this.createFormBuilder();
        this.forms.push(newForm);
    }

    addArrayForm(form, value = '') {
        form.get('array').push(this.formBuilder.group({
            value: new FormControl({value: value, disabled: this.disabled}),
        }));
    }

    addObjectForm(form, key = '', value = '') {
        form.get('object').push(this.formBuilder.group({
            key: new FormControl({value: key, disabled: this.disabled}, Validators.required),
            value: new FormControl({value: value, disabled: this.disabled}),
        }));
    }

    removeAtIndex(index) {
        this.forms.removeAt(index);
    }

    removeItemArrayForm(form, index) {
        form.removeAt(index);
    }

    removeItemObjectForm(form, index) {
        form.removeAt(index);
    }

    onChangeType(event, form: any) {
        if (event.value === 'Array') {
            this.addArrayForm(form);
        } else if (event.value === 'Object') {
            this.addObjectForm(form);
        }
    }

    markAsTouched(group: FormGroup | FormArray) {
        group.markAsTouched({onlySelf: true});
        Object.keys(group.controls).map((field) => {
            const control = group.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.markAsTouched(control);
            }
        });
    }

    submit() {
        this.markAsTouched(this.forms);
        if (this.dynamicForm.invalid) {
            return false;
        }
        const model: any = {};
        this.forms.controls.forEach(f => {
            const keyField = f.get('key').value;
            switch (f.get('type').value) {
                case 'Array':
                    const arrData = [];
                    const arrForms = f.get('array') as FormArray;
                    arrForms.controls.forEach(arr => {
                        arrData.push(arr.get('value').value);
                    });
                    model[keyField] = arrData;
                    break;
                case 'Object':
                    const objData: any = {};
                    const objForms = f.get('object') as FormArray;
                    objForms.controls.forEach(obj => {
                        objData[obj.get('key').value] = obj.get('value').value;
                    });
                    model[keyField] = objData;
                    break;

                default:
                    model[keyField] = f.get('value').value;
                    break;
            }
        });
        this.submitForm.emit(model);
        return true;
    }
}
