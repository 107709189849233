import moment = require("moment");

export class ProjectReportModel {
    
    stt: number;
    id: string;
    name: string; // tên dự án
    totalUnits: number; // tổng số dự án
    countSalesUnit: number;       // tổng số đơn vị bán hàng
    countYcdcSuccess: number;  // số lần đặt chỗ thành công
    countYcdcRefund: number;  // số lần đặt chỗ có yêu cầu hoàn tiền
    countYcdcYCDCH: number; // yêu cầu giữ chỗ từ chỗ
    countYcdcnotYCDCH: number; // yêu cầu giữ chỗ mới
    createdDate: string;
    totalYCDC: number;
    // fromDate: string; // từ ngày
    // toDate: string;   // đến ngày



    constructor(params?: any) {
       Object.assign(this, {
        stt: 1,
        id: '',
        name: '', 
        totalUnits: 0, 
        countSalesUnit: 0,      
        countYcdcSuccess: 0, 
        countYcdcRefund: 0, 
        countYcdcYCDCH: 0, 
        countYcdcnotYCDCH: 0, 
        createdDate: '',
        totalYCDC: 0,
       },params);
    }

    createDataForList() {
        let object: any = Object.assign({}, this);
        object;
      }
      
      createRequestObj(data) {
        let object: any = Object.assign({}, this, data);
        return object;
      }

}

export class LoanReportModel {

  id: string;
  stt: number;
  code: string;
  customer: string;
  pos: string;
  depositDate: string;
  bankLoan: string;
  paymentMethod: string;
  projectName: string;
  constructor(param?: any) {
    Object.assign(this, {}, param)
  }

  createDataForList() {
    let object: any = Object.assign({}, this);
    object.depositDate = object.depositDate && object.depositDate.date ? moment(object.depositDate.date).format('DD/MM/YYYY') : ''
    object.code =  object.propertyUnit && object.propertyUnit.code ? object.propertyUnit.code : null
    object.pos = object.pos && object.pos.name ? object.pos.name : null
    object.customer = object.customer && object.customer.personalInfo  && object.customer.personalInfo.name  ? object.customer.personalInfo.name : null
    object.projectName = object.project && object.project.name ? object.project.name : null
    return object;
  }
  
  createRequestObj(data) {
    let object: any = Object.assign({}, this, data);
    return object;
  }

}