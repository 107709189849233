export class FileModel {

  name: string;
  url: string;
  absoluteUrl: string;

  constructor(param?: any) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.name = params.name || '';
      this.url = params.url || null;
      this.absoluteUrl = params.url || null;
    } else {
      this.setAllNull();
    }
  }
  setAllNull() {
    this.name = null;
    this.url = null;
    this.absoluteUrl = null;
  }

  createObjectRequestAPI() {
    let object: any = {};
    object.name = this.name;
    object.url = this.url;
    // object.absoluteUrl = this.absoluteUrl;
    return object;
  }

}
