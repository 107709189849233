import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Constant } from 'app/shared/constant/constant';

@Component({
    selector: 'app-confirm-recalculate-period',
    templateUrl: './confirm-recalculate-period.component.html',
    styleUrls: ['./confirm-recalculate-period.component.scss']
})
export class ConfirmRecalculatePeriodComponent implements OnInit {
    public readonly listPeriods = Constant.LIST_PERIOD_COMM;
    mainForm: FormGroup = null;
    constructor(
        public dialogRef: MatDialogRef<ConfirmRecalculatePeriodComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.initForm();
    }

    getFormControl(name: string) {
        return this.mainForm.get(name);
    }

    isControlInvalid(formControl: string): boolean {
        return this.mainForm && this.mainForm.get(formControl).invalid && (this.mainForm.get(formControl).dirty || this.mainForm.get(formControl).touched);
    }

    initForm() {
        this.mainForm = this.formBuilder.group({
            selectedPeriod: ['', Validators.required],
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    
    execute() {
        if (this.isControlInvalid('selectedPeriod')) {
            return;
        }
        this.dialogRef.close({
            period: this.getFormControl('selectedPeriod').value,
        });
    }
}

