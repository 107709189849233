export class SocialUrl {
  name: string;
  url: string;

  constructor(object?: any) {
    if (object) {
      this.name = object.name;
      this.url = object.url;
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    this.name = null;
    this.url = null;
  }

  createObjectRequestAPI() {
    let object: any = {};
    object.name = this.name;
    object.url = this.url;
    return object;
  }
}
