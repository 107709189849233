export const MASTER_DATA = {
    POS_CONFIGS: [
        {
            code: 'POS1',
            brokerFee: 10000000,
            brokerFeePercent: 2,
            expiredMonths: 6
        }
    ],
    ATTRIBUTES: [
        {
            code: 'direction',
            name: 'Hướng',
            values:  [{
                key: 'BAC',
                value: 'Bắc',
              },{
                key: 'DONG_BAC',
                value: 'Đông Bắc',
              },{
                key: 'TAY_BAC',
                value: 'Tây Bắc',
              },{
                key: 'DONG',
                value: 'Đông',
              },{
                key: 'DONG_NAM',
                value: 'Đông Nam',
              },{
                key: 'NAM',
                value: 'Nam',
              },{
                key: 'TAY_NAM',
                value: 'Tây Nam',
              },{
                key: 'TAY',
                value: 'Tây',
              }
            ]
        },
        {
          code: 'room',
          name: 'Phòng',
          values:  [{
            key: '1',
            value: '1 phòng',
          },
          {
            key: '2',
            value: '2 phòng',
          },
          {
            key: '3',
            value: '3 phòng',
          },
          {
            key: '4',
            value: '4 phòng',
          }]
        }
    ],
    DIRECTIONS : [
    {
      key: 'BAT_KY',
      value: 'Bất kỳ',
    },{
      key: 'BAC',
      value: 'Bắc',
    },{
      key: 'DONG_BAC',
      value: 'Đông Bắc',
    },{
      key: 'TAY_BAC',
      value: 'Tây Bắc',
    },{
      key: 'DONG',
      value: 'Đông',
    },{
      key: 'DONG_NAM',
      value: 'Đông Nam',
    },{
      key: 'NAM',
      value: 'Nam',
    },{
      key: 'TAY_NAM',
      value: 'Tây Nam',
    },{
      key: 'TAY',
      value: 'Tây',
    }],
    BANKS: [{
      key: 'VIETCOMBANK',
      value : 'Vietcombank'
    },{
      key: 'VIETINBANK',
      value : 'Vietinbank'
    },{
      key: 'VPBANK',
      value : 'VP Bank'
    },{
      key: 'BIDV',
      value : 'BIDV'
    }],
    MONEY: [
      {
        key: 'CASH',
        value : 'Tiền mặt'
      }, {
        key: 'TRANSFER',
        value : 'Chuyển khoản'
      }, {
        key: 'CREDIT_CARD',
        value : 'Thẻ tín dụng'
      }, {
        key: 'OTHER',
        value : 'Khác'
      },
      {
        key: 'INVESTOR',
        value : 'Đã thu CĐT'
      },
      {
        key: 'POS',
        value: 'Cà thẻ'
      },
      {
        key: 'DVBH',
        value: 'Đã thu ĐVBH'
      }
    ],
    TYPE_RECEIPT: [
      {
        key: 'CONTRACT',
        value : 'Thu phí HĐMG'
      },
      // {
      //   key: 'DEPOSIT',
      //   value : 'Thu phí HĐMG'
      // },
      // {
      //   key: 'DEPOSIT_24H',
      //   value : 'Đặt cọc 24h'
      // }
    ],
    CURRENCY: [
      {
        key: 'VND',
        value : 'VND'
      }
    ],
    ID_CARDS : [{
        key: 'CMND',
        value: 'CMND',
      },
      {
        key: 'IC',
        value: 'Thẻ Căn cước',
      },
      {
        key: 'PASSPORT',
        value: 'Hộ chiếu',
      },
      {
        key: 'GPKD',
        value: 'Giấy phép kinh doanh',
      }],
    INTEREST : [
      {
        key: 0,
        value: 'Không quan tâm',
      },
      {
        key: 25,
        value: 'Ít quan tâm',
      },
      {
        key: 50,
        value: 'Có quan tâm',
      },
      {
        key: 75,
        value: 'Quan tâm nhiều',
      },
      {
        key: 100,
        value: 'Cực kỳ quan tâm',
      },
    ],
    TYPE_DATE_REPORT : [{
      key: 'DAY',
      value: 'Ngày',
    },{
      key: 'MONTH',
      value: 'Tháng',
    },{
      key: 'YEAR',
      value: 'Năm',
    }],
    TYPE_DATE_SUBJECT : [{
      key: 'EMPLOYEE',
      value: 'Nhân viên',
    },{
      key: 'POS',
      value: 'Trung tâm giao dịch',
    }],
}