import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
  ActivatedRoute
} from '@angular/router';
import { SecurityService } from '../security.service';
import { map, take, concatMap } from 'rxjs/operators';
import { StorageService } from '../storage.service';

export interface CanComponentDeactivate {
  confirmChangeRouter: (url?) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UserRouteAccessDeactivate implements CanActivate {
  constructor(
    private securityService: SecurityService,
    private router: Router,
    private storageService: StorageService
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.securityService.IsAuthenticated.pipe(
        take(1), map(x => {
            if (x) {
                return true;
            } else {
                const url = window.location.href
                let uri = this.getLocation(url)
                let checkUrl = uri.pathname.replace('/', '')
                if (checkUrl !== "") {
                    this.storageService.store('callbackUrl', uri.pathname)
                    this.router.navigate(['/login']);
                } else {
                    this.router.navigate(['/login']);
                }
                return false;
            }
        }));
  }

  getLocation(href) {
    const l = document.createElement("a");
    l.href = href;
    return l;
  }
}
