import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { markAsTouched } from "app/shared/utility/form-utility";
import { ToastrService } from "ngx-toastr";
import { TypeSendOtp } from "app/shared/enum/trial-register.enum";
import { PhoneAuthenRegisterComponent } from "../phone-authen/phone-authen.component";
import { LoginService } from "app/pages/login/login.service";


@Component({
  selector: 'app-authen-success',
  templateUrl: 'authen-success.component.html',
  styleUrls: ['authen-success.component.scss']
})


export class AuthenSuccessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AuthenSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
    private _service: LoginService
    ) { }

    typeSend = TypeSendOtp
    phoneNumber: string
    email: string
  ngOnInit() {
    if(this.data.phoneNumber) this.phoneNumber = this.data.phoneNumber.replace(this.data.phoneNumber.substring(3,8), '*****');
    if(this.data.email) {
      const[name, domain] = this.data.email.split('@')
      const {length: len} = name
      const maskedName = name[0] + "*****" + name[len - 1]
      this.email = maskedName + "@" + domain 
    } 
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}