import { IDecode } from './i-decode';

export class CProject implements IDecode<CProject> {

    public id: string;
    public name: string;

    constructor(params: Partial<CProject> = {}) {
        this.copy(params);
    }


    copy(params: Partial<CProject> = {}): CProject {
        params = params || {};

        this.id = params.id || null;
        this.name = params.name || null;

        return this;
    }

    decode(paramsApi: any): CProject {
        return new CProject (paramsApi);
    }

    decodeList(paramsApi: any[]): CProject[] {
        return paramsApi.map(el => new CProject().decode(el));
    }
}

