import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import { HttpService } from 'app/shared/services/http.service';
import { map } from 'rxjs/operators';
import { Demand } from './demand.model';
import { Customer } from '../customer';
import { CPagination } from 'app/api-models/c-pagination';
import { Observable } from 'rxjs';
import {Project} from '../project/project.model';

@Injectable()
export class DemandService extends BaseService {
    ConstantUrl = ConstantUrl;
    constructor(
        public service: DataService,
        public httpService: HttpService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_demand_domain + 'demand';
        this.queryUrl = ConstantUrl.url_demand_query + 'demand';
    }
    insertCustomer(customer) {
        const url = ConstantUrl.url_demand_domain + 'customer';
        return this.service.post(url, customer);
    }
    insert(obj, isValidate?) {
        let url = !isValidate ? this.domainUrl + '/save' : this.domainUrl + '/save_validate';
        return this.service.post(url, obj);
    }
    getTicketById(id) {
        const url = ConstantUrl.url_ticket_query + 'lead/' + id;
        return this.service.get(url);
    }
    getCategories() {
        const url = ConstantUrl.url_category_query + 'category/pub/all';
        return this.service.get(url);
    }
    searchTemplate(request) {
        const url = ConstantUrl.url_demand_query + 'demandTemplate/pub/param';
        return this.service.post(url, request);
    }
    checkIdentity(request) {
        let url = ConstantUrl.url_demand_query + 'customer/param';
        return this.service.post(url, request);
    }
    getMatchingList(id) {
        let url = ConstantUrl.url_matching_query + 'matching/demand/' + id;
        return this.service.get(url);
    }

    sendAcceptDealing(params?: any) {
        const url = this.ConstantUrl.url_matching_domain + 'dealing/accept';
        return this.service.post(url, params);
    }

    sendRequestDealing(params?: any) {
        const url = this.ConstantUrl.url_matching_domain + 'dealing/request';
        return this.service.post(url, params);
    }

    createDeal(request) {
        let url = ConstantUrl.url_deal_domain + 'dealing/request';
        return this.service.post(url, request);
    }

    get(params?) {
        let url = ConstantUrl.url_matching_query + 'matching/demandList';
        return this.service.get(url, params ? params : null);
    }

    getDemandById(id: string): Observable<Demand> {
        const url = this.queryUrl + '/' + id;
        return this.httpService.get(url)
            .pipe(
                map((res: any) => new Demand(res))
            );
    }

    getDemand(params?: any): Observable<CPagination<Demand>> {
        const url = ConstantUrl.url_matching_query + 'matching/demandList';
        return this.httpService.get(url, params ? params : null)
            .pipe(
                map((res: any) => {
                    const pagination = new CPagination<Demand>().decode(res);
                    pagination.items = pagination.items.map((item: any) => new Demand(item));
                    return pagination;
                })
            );
    }

    getCustomer(id: string, isPos: boolean): Observable<Customer> {
        const url = isPos ? ConstantUrl.url_property_query + 'propertyCustomer/' + id : ConstantUrl.url_customer_query + 'customer/' + id;
        return this.httpService.get(url)
            .pipe(
                map((res: any) => {
                    const customer: Customer = new Customer(res);
                    customer.isPotential = isPos;
                    return customer;
                })
            );
    }

    getDetailDealing(id: string) {
        const url = ConstantUrl.url_matching_query + `dealing/${id}`;
        return this.service.get(url);
    }
    getFilterList() {
        let url = ConstantUrl.url_matching_query + 'matching/demandList/filter';
        return this.service.get(url);
    }
    getCustomerById(id, type) {
        let url = type === 'pos' ? ConstantUrl.url_property_query + 'propertyCustomer/' + id : ConstantUrl.url_customer_query + 'customer/' + id;
        return this.service.get(url);
    }
    getByCustomerId(params?) {
      let url = ConstantUrl.url_matching_query + 'matching/customer';
      return this.service.get(url, params ? params : null);
    }
    public getProject(): Observable<Project[]> {
      const url = this.ConstantUrl.url_property_query + 'project/admin/dropdownList';
      return this.httpService.get(url)
        .pipe(
          map((res: any) => {
            if (res && res.rows && Array.isArray(res.rows)) {
              return res.rows.map((item: any) => new Project(item));
            }
            return res && Array.isArray(res) ? res.map((item: any) => new Project(item)) : [];
          })
        );
    }

    checkPhone(phone: string) {
        return this.service.get(`${ConstantUrl.url_property_query}propertyCustomer/getCusByPhone?phone=${phone}`);
    }

    syncCustomerPotential(data, token, urlErp, createddate) {
        let url = ConstantUrl.url_demand_domain + 'customer/syncCustomerPotential';
        return this.service.post(url, data, { token, urlErp, createddate});
    }
}
