export class FamilyModel {
    birthdayYear: string;
    bornAddress: string;
    code: string;
    contactAddress: string;
    email: string;
    gender: string;
    id: string;
    identityId: string;
    identityIssuedDate: string;
    identityIssuedPlace: string;
    identityValue: string;
    job: string;
    name: string;
    nation: string;
    phone: string;
    reason: string;
    relationship: string;
    religion: string;
    rootAddress: string;
    status: string;
    isActiveAccount: boolean;
    customerId: string;
    constructor(params: any) {
        this.setValue(params)
    }

    setValue(params: any) {
        if (params) {
            this.birthdayYear = params.birthdayYear || '';
            this.bornAddress = params.bornAddress || '';
            this.code = params.code || '';
            this.contactAddress = params.contactAddress || '';
            this.email = params.email || '';
            this.gender = params.gender || '';
            this.id = params.id || '';
            this.identityId = params.identityId || '';
            this.identityIssuedDate = params.identityIssuedDate || '';
            this.identityIssuedPlace = params.identityIssuedPlace || '';
            this.identityValue = params.identityValue || '';
            this.job = params.job || '';
            this.name = params.name || '';
            this.nation = params.nation || '';
            this.phone = params.phone || '';
            this.reason = params.reason || '';
            this.relationship = params.relationship || '';
            this.religion = params.religion || '';
            this.rootAddress = params.rootAddress || '';
            this.status = params.status || '';
            this.isActiveAccount = params.isActiveAccount;
            this.customerId = params.customerId || '';
        } else {
            this.birthdayYear = '';
            this.bornAddress = '';
            this.code = '';
            this.contactAddress = '';
            this.email = '';
            this.gender = '';
            this.id = '';
            this.identityId = '';
            this.identityIssuedDate = '';
            this.identityIssuedPlace = '';
            this.identityValue = '';
            this.job = '';
            this.name = '';
            this.nation = '';
            this.phone = '';
            this.reason = '';
            this.relationship = '';
            this.religion = '';
            this.rootAddress = '';
            this.status = '';
            this.isActiveAccount = false;
            this.customerId = '';
        }
    }

}