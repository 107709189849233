import { Pipe, PipeTransform } from '@angular/core';
import { ERoleId } from 'app/enums/e-role-id.enum';

@Pipe({
  name: 'sortDsgh'
})
export class SortDsghPipe implements PipeTransform {

  transform(ds: any, fieldSort: string, reverse: boolean, isChangeStatus?: boolean, roldId?: string): any {


    let value = ds || [];
    const filedArr: string[] = fieldSort.split('.');

    value = value.sort((a, b) => {

      const aValue = filedArr.reduce((preV, curV, curIndex) => {
        return preV[curV];
      }, a);

      const bValue = filedArr.reduce((preV, curV, curIndex) => {
        return preV[curV];
      }, b);

      if (reverse) {
        return (aValue < bValue ? 1 : -1);
      }
      return (aValue < bValue ? -1 : 1);
    });

    let valueActions = [];
    let valueNotActions = [];

    switch (roldId) {
      case ERoleId.DVKH: {
        value.forEach((element, index) => {
          if (element.recordStatus === 'CONFIRM') {
            valueActions.push(element);
          } else {
            valueNotActions.push(element);
          }
        });
        break;
      }

      default: {
        if (isChangeStatus && ERoleId.DVKH !== roldId) {
          value.forEach((element, index) => {
            if (element.recordStatus === 'PROCESSING' || element.recordStatus === 'COMING') {
              valueActions.push(element);
            } else {
              valueNotActions.push(element);
            }
          });
        } else {
          valueNotActions = [...value];
        }
      }
    }

    value = [...valueActions, ...valueNotActions];
    return value;
  }

}
