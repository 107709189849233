import { EEventStatusProjet, EEventStatusStageProject, EEventStatusPriorityProject } from './e-event-project.enum';
import { IDecode } from 'app/api-models/i-decode';
import { CProject } from 'app/api-models/c-project';
import { IEventProjectLiveStatistic } from './event-dashboard-page.component';

export class CEventProjectManager implements IDecode<CEventProjectManager> {

    public id: string;
    public status: string;
    public priority: number;
    public stage: number;
    public stageStatus: string;
    public priorityStatus: string;
    public project: CProject;
    public userConnected: IEventProjectLiveStatistic;
    public salesProgram: any;

    constructor(params: Partial<CEventProjectManager> = {}) {
        this.copy(params);
    }

    isLiveStreaming(): boolean {
        return this.status === EEventStatusProjet.STREAMING;
    }

    isFinshed(): boolean {
        return this.status === EEventStatusProjet.FINISHED;
    }

    isInitial(): boolean {
        return this.status === EEventStatusProjet.INITIAL;
    }

    isInProgressStage(): boolean {
        return this.stageStatus === EEventStatusStageProject.IN_PROGRESS;
    }
    isInMiddlePriority(): boolean {
        return this.priorityStatus === EEventStatusPriorityProject.MIDDLE;
    }
    isInProgressPriority(): boolean {
        return this.priorityStatus === EEventStatusPriorityProject.IN_PROGRESS;
    }

    isFinshedStage(): boolean {
        return this.stageStatus === EEventStatusStageProject.FINISHED;
    }
    isFinshedPriority(): boolean {
        return this.priorityStatus === EEventStatusStageProject.FINISHED;
    }

    copy(params: Partial<CEventProjectManager> = {}): CEventProjectManager {
        params = params || {};

        this.id = params.id || null;
        this.status = params.status || EEventStatusProjet.INITIAL;
        this.priority = params.priority || 1;
        this.stage = params.stage || 1;
        this.stageStatus = params.stageStatus || null;
        this.priorityStatus = params.priorityStatus || null;
        this.project = new CProject(params.project);
        this.userConnected = params.userConnected ? { ...params.userConnected } : <IEventProjectLiveStatistic>{};
        this.salesProgram = params.salesProgram ? params.salesProgram : null;
        return this;
    }

    decode(paramsApi: any): CEventProjectManager {
        return new CEventProjectManager(paramsApi);
    }

    decodeList(paramsApi: any[]): CEventProjectManager[] {
        return paramsApi.map(el => new CEventProjectManager().decode(el));
    }
}
