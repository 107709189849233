  export enum PropertyUnitStatus {
    LOCK = 'LOCK', // Khóa đầu tư
    CLOSE = 'CLOSE', // Chưa mở bán
    COMING = 'COMING', // Đang chờ xác nhận
    PROCESSING = 'PROCESSING', // Đang xử lý
    CONFIRM = 'CONFIRM', // ĐVBH xác nhận giao dịch
    LOCK_CONFIRM = 'LOCK_CONFIRM',
    SUCCESS = 'SUCCESS', // Giao dịch thành công
    UNSUCCESS = 'UNSUCCESS', // Giao dịch không thành công
    CANCEL = 'CANCEL', // Thanh lý
    MOVED = 'MOVED', // Đã chuyển CTBH
    DEPOSIT = "DEPOSIT",
    TRANSFER = "TRANSFER",
}
