import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injector, ViewChild } from '@angular/core';
@Component({
    selector: 'custom-form',
    templateUrl: './custom-form.component.html',
    providers: [],
    styleUrls: [
        'custom-form.scss'
    ]

})
export class CustomFormComponent  implements OnInit {
    @Input() properties;
    @Output() result: EventEmitter<any> = new EventEmitter();
    ngOnInit() {
    }
    getData() {
        this.result.emit(this.properties);
    }
    changeData(value, index) {
        this.properties[index].value = value;
    }
}
