import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'phone' })
export class TransformPhonePipe implements PipeTransform {
  transform(value: any) {
    return this.parsePhone(value);
  }
  parsePhone(value) {
    if (!value) {
      return '';
    }
    if (parseInt(value) > -1) {
      return '******' + value.slice(-4);
    }
    return value;
  }
}
