import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import { PosDealingComponent } from 'app/pages/deal/pos-dealing/pos-dealing.component';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import {Observable} from 'rxjs';
import {CPagination} from '../../api-models';
import {EmployeeReport} from './employee-report/employee-report.model';
import {map} from 'rxjs/operators';
import {parseEmail} from '../../shared/utility/utility';
import { HttpService } from 'app/shared/services/http.service';

@Injectable()
export class EmployeeService extends BaseService {
    ConstantUrl = ConstantUrl;
    url = ConstantUrl.url_voice_call_call_log;

    constructor(
        public service: DataService,
        private storageService: StorageService,
        private voiceCallService: VoiceCallService,
        protected http: HttpService,
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_employee_domain + 'employee';
        this.queryUrl = ConstantUrl.url_employee_query + 'employee';

    }

    insertEmployeeHttpService(params) {
        return this.http.post(this.domainUrl, params);
    }

    updateEmployeeHttpService(params) {
        const url = ConstantUrl.url_employee_domain + 'employee';
        return this.http.put(url, params);
    }

    getTemplates() {
        const url = ConstantUrl.url_profile_query + 'profileTemplate';
        return this.service.get(url);
    }
    getRoles() {
        const url = ConstantUrl.url_sts_query + 'role';
        return this.service.get(url);
    }
    getPos() {
        const url = ConstantUrl.url_pos_query + 'pos';
        return this.service.get(url);
    }
    getEmployeePOS(posId, params?) {
        const url = ConstantUrl.url_orgchart_query + 'orgchart/pub/info/' + posId + '/list';
        return this.service.get(url, params || {});
    }
    public getAcl(id?) {
        const url = ConstantUrl.url_user_query + '/' + this.storageService.retrieve('userId');
        return this.service.get(url);
    }
    getUSerPermission(agencyId) {
        return this.service.get(ConstantUrl.url_user_query + '/' + agencyId)
            .map((res) => {
                let data: any = res || {};
                return data;
            });
    }
    setEmployeePermission(body) {
        const url = this.ConstantUrl.url_user_domain + '/setUserPermission';
        return this.service.post(url, body);
    }
    public getMsxs() {
        const url = ConstantUrl.url_sts_query + 'msx';
        return this.service.get(url);
    }
    public getFeatures() {
        const url = ConstantUrl.url_sts_query + 'feature';
        return this.service.get(url);
    }

    public getTavicoEmployee(code: string) {
        const url = ConstantUrl.url_employee_query + 'tavico/employee/' + code;
        return this.service.get(url);
    }
    public resetPassword(params: any) {
        const url = ConstantUrl.url_sts_domain + 'user/admin/resetPwd';
        return this.service.put(url, params);
    }
    public updateEmployee(params: any) {
        const url = ConstantUrl.url_employee_domain + 'employee';
        return this.service.put(url, params);
    }

    changeUserActivation(id) {
        const url = this.domainUrl + '/changeActivation/' + id;
        return this.service.put(url, {});
    }

    public approve(id: string, params = {}) {
        const url = this.domainUrl + '/' + id + '/approve';
        return this.service.put(url, params)
    }

    public reject(id: string) {
        const url = this.domainUrl + '/' + id + '/reject';
        return this.service.put(url, {})
    }

    public getAccountant() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/accountants';
        return this.service.get(url);
    }
    public getDevBusiness() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/devBusiness';
        return this.service.get(url);
    }
    public getSaleAdmins() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/saleAdmins';
        return this.service.get(url);
    }
    


    public getCurrentEmployee() {
        const url = this.queryUrl + '/current';
        return this.service.get(url);
    }

    public selfUpdate(params: any) {
        const url = this.domainUrl + '/selfUpdate';
        return this.service.put(url, params);
    }
    getEmployeeListByNameOrEmail(params) {
        params.limitSize = 99999;
        const url = this.queryUrl + '/getLikeEmailOrName';
        return this.service.get(url, params);
    }
    getRegisredEmployee(params) {
        const url = this.queryUrl + '/registered';
        return this.service.get(url, params ? params : null);
    }

    exportUser(params = null) {
        const url = this.queryUrl + '/exportUser';
        return this.service.dowloadFile(url, params ? params : null);
    }

    getReportEmployeeSales(params) {
        const url = this.queryUrl + '/report-employee-sales';
        return this.service.get(url, params ? params : null);
    }

    // Report
    public getAllEmployees(params): Observable<CPagination<EmployeeReport>> {
      const url = this.queryUrl + '/report';
      return this.service.get(url, params).pipe(
        map((res: any) => {
          const pagination = new CPagination<EmployeeReport>().decode(res);
          pagination.items = pagination.items.map((item: any) => new EmployeeReport(item));
          return pagination;
        })
      );
    }

    public getAllEmployeeExport(params) {
      params.pageSize = '';
      params.page = '';
      const url = this.queryUrl + '/report';
      return this.service.get(url, params);
    }

    getIncomeCallLogs(agentId, filter) {
        this.voiceCallService.getAuthenticationToken();
        const headers = { 'X-STRINGEE-AUTH': this.storageService.retrieve('voice_call_authen_token') };
        let queryUrl = this.url + '?to_number=' + agentId + '&from_created_time=' + (filter.dateFrom / 1000) + '&to_created_time=' + (filter.dateTo / 1000);
        return this.service.getWithHeaders(queryUrl, headers);
    }

    getCallOutLogs(agentId, filter) {
        this.voiceCallService.getAuthenticationToken();
        const headers = { 'X-STRINGEE-AUTH': this.storageService.retrieve('voice_call_authen_token') };
        let queryUrl = this.url + '?from_user_id=' + agentId + '&from_created_time=' + (filter.dateFrom / 1000) + '&to_created_time=' + (filter.dateTo / 1000);
        return this.service.getWithHeaders(queryUrl, headers);
    }

    getApprovalContract(params) {
        const url = ConstantUrl.url_contract_query + 'contract';
        return this.service.get(url, params ? params : null);
    }

    getOtherReport(params) {
        const url = ConstantUrl.url_ticket_query + 'lead/report/count';
        return this.service.get(url, params ? params : null);
    }

    changeEmail(headerToken: string) {
        const url = `${ConstantUrl.url_sts_domain}user/changeEmail`;

        const options = {
          headers: {
            token: headerToken
          }
        };
        return this.http.post(url, {}, options);
      }
    sendEmailConfirmChange(token: string) {
        return this.service.put(`${ConstantUrl.url_sts_domain}user/emailConfirmChangeEmail`, { token });
    }

    importFile(files: File) {
        const url = this.domainUrl + '/primary';
        let formData = new FormData();
        formData.append('files', files);
        return this.service.import(url, formData);
    }

    getHistoryImport(params?: any) {
        return this.service.get(`${ConstantUrl.url_employee_query}employeeImportHistory`, params);
    }

    export(params) {
        const uri = `${this.queryUrl}/report-employee-sales/export`;
        return this.service.dowloadFile(uri, params);
    }
}
