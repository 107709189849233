import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-lead-history-call',
  templateUrl: './form-lead-history-call.component.html',
  styleUrls: ['./form-lead-history-call.component.scss']
})
export class FormLeadHistoryCallComponent implements OnInit {
  @Input() callHistories: any[];

  constructor() { }

  ngOnInit() {
  }

}
