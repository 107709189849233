import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ViewChildren, ElementRef, ComponentRef, QueryList, AfterViewInit, AfterContentInit, ContentChildren } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator } from '@angular/forms';
import { RadioButtonComponent } from './radio-button/radio-button.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent implements OnInit, AfterContentInit, AfterViewInit, ControlValueAccessor, Validator {

  @Input() isDisabled: boolean = false;
  @Output() outChange: EventEmitter<any> = new EventEmitter();
  @ContentChildren(RadioButtonComponent, {descendants: true}) radioButtonComponentList: QueryList<RadioButtonComponent>;

  private _changeCb: any;
  private _blurCb: any;
  public ngModelValue: any;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {

    this.onChangeSelection(this.ngModelValue);

    this.radioButtonComponentList.toArray().forEach((comRef: RadioButtonComponent) => {
      comRef.changeRadioButton$.subscribe((ngValue: any) => {
        this.onChangeSelection(ngValue);
      });
    });
  }


  ngAfterViewInit(): void {
  }

  onChangeSelection(event: any) {

    this.ngModelValue = event;
    this.updateComponent();

    this.callChangeCb();
    this.outChange.emit(this.ngModelValue);
  }

  updateComponent() {
    if (this.radioButtonComponentList) {
      this.radioButtonComponentList.toArray().forEach((comRef: RadioButtonComponent) => {
        comRef.setCheckedRadio(this.ngModelValue);
      });
    }
  }

  writeValue(value: any): void {
    this.ngModelValue = value;
    this.updateComponent();
  }

  registerOnChange(fn: any) {
    this._changeCb = fn;
  }

  registerOnTouched(fn: any) {
    this._blurCb = fn;
  }

  callChangeCb() {
    this._changeCb(this.ngModelValue);
  }

  callBlur() {
    this._blurCb();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(c: FormControl) {
    return c.valid ? null : {
      type: {
        valid: false,
        actual: c.value
      }
    };
  }

}
