import { Injectable, Injector  } from '@angular/core';
import { Http, Response, BaseRequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { JhiEventManager } from 'ng-jhipster';
import { CookieUtilsService } from './cookie.service';
import { AuthorizeService } from '../services/common/authorize.service';
@Injectable()
export class AuthenticateUserService {
    cookieService: any;
    eventManager: JhiEventManager;
    authorizeService: AuthorizeService;
    account: any;
    constructor(
        public http: HttpClient,
        injector: Injector) {
            // super('user',http, null, injector);
            this.cookieService =  injector.get(CookieUtilsService);
            this.eventManager =  injector.get(JhiEventManager);
            this.authorizeService =  injector.get(AuthorizeService);
        }

    getAuthorities(): Observable<any> {

        let token = this.cookieService.getToken();
        if(!token) {
             return Observable.throw(false);
        }
        const url = '/api/user';
        let options: any = new BaseRequestOptions();
        options.headers.set('Content-Type', 'application/json');
        options.headers.set('JWT-TOKEN', 'Bearer ' + this.cookieService.getToken());
        return this.http.get(url, options)
        .map((res: any) => {
            return this.parseUserAuthorities(res);
        })
    }

    private parseUserAuthorities(res) {
        // USER_ROLE mean that it already authenticated
        // let convertedAuth = ['USER_ROLE'];
        const data = res;
        if (data && data.roles && data.roles.length > 0) {
            let accessControls: any = []
            data.roles.map((role) => {
                accessControls = accessControls.concat(role.accessControls);
            })
            this.eventManager.broadcast( {name: 'clApp.changeUsername', username: data.username});
            //  data.role.accessControls;
            let authorities = {};
            accessControls.forEach((accessControl) => {
                const array = accessControl.name.split('_');
                const authority = array[1].toLowerCase();
                const func = array[2].toLowerCase();
                !authorities[authority] && (authorities[authority] = {})
                !authorities[authority][func] && (authorities[authority][func] = [])
            })
            if (accessControls && accessControls.length === 0) {
                this.eventManager.broadcast( {name: 'clApp.showToast', message: 'User do not have any role', type: 'error'});
            }
            this.authorizeService.setAuthorities(authorities);
            this.eventManager.broadcast({ name: 'clApp.changeAuthority' , content: true});
            this.account = {
                id: data.id,
                authorities,
                token: this.cookieService.getToken()
            }
            return this.account;
        } else {
            return null
        }
    }
}
