import moment = require("moment");
import { StatusCustomerTrial, UserTrialStatusEnum, UserTrialStatusTrial } from "app/shared/constant/userTrialAccount.constant";

export class UserTrialModel {

    id: string;
    stt: number;
    fullName: string;
    code: string;
    email: string;
    phoneNumber: string;
    position: string;
    companyName: string;
    companySize: string;
    statusTrial: string;
    status: string;
    startDateTrial: string;
    endDateTrial: string;
    demand: string;
    createdDate: string;
    upgradePeriod: string;
    startDateUpgrade: string;
    upgradePackage: string;
    active: boolean;
    historyUpgrade: any;
    constructor(params) {
        Object.assign(this,{
            id: '',
            stt: 0,
            fullName: '',
            code: '',
            email: '',
            phoneNumber: '',
            position: '',
            companyName: '',
            companySize: '',
            statusTrial: '',
            status: '',
            startDateTrial: '',
            endDateTrial: '',
            demand: '',
            createdDate: '',
            upgradePeriod: '',
            startDateUpgrade: '',
            upgradePackage: '',
            historyUpgrade: [],
        },params)
    }
    createDataForList() {
        let object: any = Object.assign({}, this);
        object.startDateTrialFormat =  object.startDateTrial ? moment(object.startDateTrial).format('DD/MM/YYYY') : '';
        object.endDateTrialFormat =  object.endDateTrial ? moment(object.endDateTrial).format('DD/MM/YYYY') : '';
        object.createdDate =  object.createdDate ? moment(object.createdDate).format('DD/MM/YYYY') : '';
        object.startDateUpgrade = object.startDateUpgrade ? moment(object.startDateUpgrade).format('DD/MM/YYYY') : '';
        object.endDateUpgrade = object.endDateUpgrade ? moment(object.endDateUpgrade).format('DD/MM/YYYY') : '';
        object.statusCustomerTrial = this.convertStatusTrialText(object.endDateTrial, object.statusTrial, object.status, object.upgradePackage, object.active)
        object.statusText = this.convertStatusText(object.status)
        return object;
    }
    private convertStatusText(text) {
        let textValue = ''
        switch (text) {
            case UserTrialStatusEnum.APPROVED:
                textValue = 'Đã duyệt'
                break;
            case UserTrialStatusEnum.REJECTED:   
                textValue =  'Đã từ chối' 
                break;
            case UserTrialStatusEnum.WAITING:   
                textValue = 'Đang chờ duyệt'    
                break;
        }
        return textValue;
    }
    private convertStatusTrialText(endDate: any, statusTrial: any, status: any, upgradePackage, active: any) {
        const currentDate = new Date()

       
        if(!active) {
            return  StatusCustomerTrial.NOT_ACTIVATED
        }
        if( statusTrial === UserTrialStatusTrial.TRIAL && moment(endDate) < moment(currentDate)) {
            return  StatusCustomerTrial.EXPIRED
        }
        if(statusTrial === UserTrialStatusTrial.TRIAL && !endDate && !upgradePackage) {
            return  StatusCustomerTrial.TRYING
        }
        if(statusTrial === UserTrialStatusTrial.TRIAL && moment(endDate) >= moment(currentDate) && !upgradePackage ) {
            return  StatusCustomerTrial.TRYING
        }
        if(status === UserTrialStatusEnum.APPROVED && statusTrial === UserTrialStatusTrial.WAITNG_UPGRADE) {
            return  StatusCustomerTrial.WAITING_FOR_UPGRADE
        }    
        if(status === UserTrialStatusEnum.APPROVED &&  upgradePackage) {
            return  StatusCustomerTrial.UPGRADED
        }
    }
}