import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-message-notice',
  templateUrl: './dialog-message-notice.component.html',
  styleUrls: ['./dialog-message-notice.component.scss']
})
export class DialogMessageNoticeComponent {
  public imageUrl: string;
  public message: string;
  public isSuccessIcon: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogMessageNoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.message = data.message;
    }
  
    ngOnInit() {
      this.isSuccessIcon = this.data.isSuccessIcon;
      setTimeout(()=> {
        this.dialogRef.close();
      },3000);
    }
}
