import {validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty} from "class-validator";
import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { IsLongerThan } from "../../shared/decorators/IsLongerThan.decorator";
import { Attribute } from "../attribute/attribute.model";
import { DefineAttribute } from "./define-attributes.model";
import { GroupAttributes } from "./group-attributes.model";
import { Constant } from "../../shared/constant/constant";
import { GroupsAttributes } from "../../shared/components/groups-attribute/groups-attributes.model";
export class TemplateAttributes extends BaseModel implements BaseModelInterface {
    
    @ValidateIf(o => o.otherProperty === "")
    id: string;

    @IsNotEmpty()
    name: string;

    category: any = {};

    role: any = {};

    categoryId: string;

    categoryName: string;

    roleId: string;

    roleName: string;

    type: string;

    typeText: string;

    @IsBoolean()
    active: boolean;

    attributes: Array<DefineAttribute> = [];

    attributesNames: string;

    groups: GroupAttributes[] = [];

    static typeUrl = 'property';
    
    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static setType(type) {
        this.typeUrl = type;
    }
    static getType() {
        if (this.typeUrl) {
            return 'define-attributes/' + this.typeUrl;
        } else return 'define-attributes';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.active = params.active || false;
            this.categoryId = params.categoryId || '';
            this.categoryName = params.categoryName || '';
            if ( params.category) {
                this.categoryId = params.category.id;
                this.categoryName = params.category.name;
            }
            this.category = params.category || {};
            this.roleId = params.roleId || '';
            this.roleName = params.roleName || '';
            if ( params.role) {
                this.roleId = params.role.id;
                this.roleName = params.role.name;
            }
            this.role = params.role || {};
            this.type = params.type || params.transactionType ||  '';
            let types = Constant.TYPES_TRANSACTION;
            let findObject = types.find(type => type.key == this.type);
            if (findObject) {
                this.typeText = findObject.value;
            }
            !this.typeText && (this.typeText = this.type);
            this.attributes = params.attributes || params.templates || params.preDefineAttributes || [];
            this.attributesNames = '';
            this.attributes = this.attributes.map((attribute) => {
                this.attributesNames += attribute.attributeName +  ', ';
                return new DefineAttribute(attribute);
            });
            if (this.attributesNames.length > 150) {
                this.attributesNames = this.attributesNames.substring(0, 150) + '...';
            }
            this.groups = new GroupsAttributes({attributes: this.attributes}).groups;
            this.groups.sort((a1, a2) => {
                return a1.groupOrder - a2.groupOrder;
            });
            this.groups.forEach((group) => {
                group.setOrders();
            })
           
            this.attributesNames = this.attributesNames.substring(0, this.attributesNames.length - 2);
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    } 

    setAllNull() {
        this.id ='';
        this.name = '';
        this.category = {};
        this.role = {};
        this.roleId = '';
        this.categoryName = '';
        this.categoryId = '';
        this.roleName = '';
        this.attributes = [];
        this.groups = [];
        this.type = '';
    }

    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || '');
        object.name = this.name;
        object.active = this.active || false;
        // object.templates = this.attributes.map((attribute) => {
        //     return new DefineAttribute(attribute).createObjectRequestAPI();
        // }); 
        if (this.category && this.category.id) {
            object.category = this.category;
        } else if (this.role && this.role.id) {
            object.role = this.role;
        }
        object.transactionType = this.type; 
        object.templates = GroupsAttributes.parseAttributesFromGroups(this.groups);
        object.type = this.type;
        return object;
    }
}

