// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    productionMode: false,
    urlBackEnd: "https://tapping.store/",
    urlBackEndFpt: "https://uat-api-crm.datxanh.com.vn/",
    webUrl: "",
    appName: "FPT CRM",
    rabbitmq: {
        ws: {
            url: "wss://64.239.24.142:15674/ws",
            host: "/",
            login: "rabitmqfpt",
            passcode: "rabitmqfpt",
        },
    },
    googleMap: {
        apiKey: "",
    },
    voiceCall: {
        uriAPI: "",
    },
    urlErp: "https://zzz",
    campaignErp: [{ "200 Triệu": "CTBH_ABC" }, { "50 Triệu": "SGT" }],
    isDxgApp: false,
    xClientCode: "REAL_AGENT",
};
