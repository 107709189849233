export const ListOfNeedsConstant = [
    {
      label: 'Bộ giải pháp quản lý phân phối bất động sản',
    },
    {
      label: 'Bộ giải pháp quản trị và phát triển kinh doanh',
    },
    {
      label: 'Bộ giải pháp quản lý nhân viên kinh doanh',
    },
    {
      label: 'Bộ giải pháp quản lý bán hàng sự kiện',
    },
    {
      label: 'Bộ giải pháp quản lý đại lý bán hàng',
    },
    {
      label: 'Bộ giải pháp chuyển đổi số toàn diện cho sàn bđs',
    }
  ];
export const  WorkListConstant = [
    {
      label: 'CEO/ Founder/ Chủ Tịch',
    },
    {
      label: 'Giám đốc (IT, Kinh doanh, Vận hành,...)',
    },
    {
      label: 'Trưởng phòng kinh doanh',
    },
    {
      label: 'Nhân viên (Staff)',
    },
    {
      label: 'Môi giới tự do',
    },
    {
      label: 'Vị trí khác ...',
    }
  ];
export const  ScaleListConstant = [
    {
      label: '1-15 nhân viên'
    },
    {
      label: '16-30 nhân viên'
    },
    {
      label: '31-60 nhân viên'
    },
    {
      label: '61-100 nhân viên'
    },
    {
      label: '100-500 nhân viên'
    },
    {
      label: '>500 nhân viên'
    },

  ]

  export const listOfNeeds2Constant = [
    {
      label: 'Đơn vị tổng thầu',
    },
    {
      label: 'Chủ đầu tư',
    },
    {
      label: 'Ban quản lý',
    },
  
  ]
    