import { BaseModel } from "../../../shared/models/base.model";
import { BaseModelInterface } from "../../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";
import { ConfigItem } from "./config.item.model";
import { GeoLocation } from "../../property/location.model";

export class POS extends BaseModel implements BaseModelInterface  {
    @ValidateIf(o => o.otherProperty === "")
    @IsNotEmpty()
    @Length(10, 20)
    id: string;

    @Length(10, 100)
    name: string;
    
    status: string;
    workingDate: Date;
    managerId: string;
    staffIds: string[];
    customerIds: string[];

    parentId: string;
    parentName: string;
    text: string;
    level: number;

    config: object[];
    personalStaffIds: string[];
    info: any;
    personalInfo: any;
    canAssignStaff: boolean;

    description: string;    

    location: GeoLocation;
    

    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'pos';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.level = params.level || 0;
            this.info = params.info || {};
            this.personalInfo = params.personalInfo || {};
            this.managerId = params.managerId || '';
            this.parentName = params.parentName || '';
            this.text = params.text || '';
            this.staffIds = params.staffIds || [];
            this.customerIds = params.customerIds || [];
            this.config = params.config || [];
            this.description = params.description || '';
            this.status = params.status || 'init';
            this.personalStaffIds = params.personalStaffIds || [];
            this.canAssignStaff = params.canAssignStaff || false;
            this.location = new GeoLocation(params.location) || new GeoLocation();
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    } 

    setAllNull() {
        this.id = '';
        this.name =  '';
        this.level = 0;
        this.info = {};
        this.personalInfo =  {};
        this.managerId = '';
        this.parentName = '';
        this.text = '';
        this.staffIds =  [];
        this.customerIds =  [];
        this.config =  [];
        this.description =  '';
        this.status =  'init';
        this.personalStaffIds =  [];
        this.canAssignStaff = false;
        this.location = new GeoLocation();
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object = super.createObjectRequestAPI(object);
        object.name = this.name;
        object.level = this.level;
        object.info = {
            id: this.info.id,
            email: this.info.email
        };
        object.personalInfo = {
            id: this.personalInfo.id,
            email: this.personalInfo.email,
            phone: this.personalInfo.phone,
            address: this.personalInfo.address,
            location:  new GeoLocation(this.location).createObjectRequestAPI()
        };
        object.config = this.config.map((config) => {
            return new ConfigItem(config).createObjectRequestAPI();
        });
        object.personalStaffIds = this.personalStaffIds.map((employee) => {
            let object: any = {};
            // employee.id && (object.id =  employee.id || null);
            // object.email = employee.email;
            // object.code = employee.code;
            // object.name = employee.name;
            // object.phone = employee.phone;
            // object.images = employee.images;
            return object;
        });
        object.description = this.description;
        object.radius = this.personalInfo.radius;
        // apply for review. consider to use or remove later
        object.managerId = this.managerId;
        return object;
    }
    createObjectReference() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object.name = this.name;
      //  object.address = this.address;
        return object;
    }
}
