import { BREAKPOINT, LayoutDirective } from '@angular/flex-layout';
import { Directive } from '@angular/core';

const MOBILE_BREAKPOINTS = [
  {
    alias: 'mobile',
    suffix: 'Mobile',
    mediaQuery: 'screen and (max-width: 599px)',
    overlapping: true
  }
];

export const MobileBreakpointsProvider = {
  provide: BREAKPOINT,
  useValue: MOBILE_BREAKPOINTS,
  multi: true
};

const inputs = ['fxLayout.mobile'];
const selector = `[fxLayout.mobile]`;

@Directive({selector, inputs})
export class MobileLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
