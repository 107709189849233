import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuideComponent } from './guide/guide.component';
import { TrialGuideComponent } from './trial-guide.component';
import { SharedModule } from 'app/shared';

@NgModule({
  declarations: [TrialGuideComponent, GuideComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents: [TrialGuideComponent, GuideComponent],
  exports:[TrialGuideComponent, GuideComponent]
})
export class TrialGuideModule { }
