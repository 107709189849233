import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constant } from 'app/shared/constant/constant';
import { AppSettings } from 'app/app.settings';
import { EmployeeService } from '../employee.service';
import { Settings } from 'app/app.settings.model';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-confirm-changed-email',
  templateUrl: './confirm-changed-email.component.html',
  styleUrls: ['confirm-changed-email.component.scss'],
  providers: [EmployeeService]
})
export class ConfirmChangedEmailComponent implements OnInit {
  Constant = Constant;
  public settings: Settings;
  data: any = {};
  token: string;
  isLoading: boolean = false;
  isTokenValidate: boolean = false;
  isSend: boolean = false;
  constructor(
    public appSettings: AppSettings,
    public route: ActivatedRoute,
    public router: Router,
    public employeeService: EmployeeService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = false;
    const tokenObj = this.route.params['_value'];
    this.isLoading = true;
    this.employeeService.changeEmail(tokenObj.token)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (res) => {
          this.isTokenValidate = true;
          this.isLoading = false;
          this.isSend = true;
        },
        (err) => {
          this.isTokenValidate = false;
        }
      );
  }
  goToLogin() {
    this.router.navigate(['login']);
  }
}
