import {Component, OnInit, OnDestroy,} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';
import {EmployeeService} from '../../shared/services/employee.service';
import {Employee} from '../../shared/models/employee.model';
import {CPagination} from '../../api-models';
import { FormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-search-employee-page',
  templateUrl: './search-employee-page.component.html',
  styleUrls: ['./search-employee-page.component.scss']
})
export class SearchEmployeePageComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject();
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private modelChanged: Subject<string> = new Subject<string>();
  public pagingEmployee: CPagination<Employee> = new CPagination<Employee>();
  public currentFilter: any = {};
  public searchKey = new FormControl();
  public employeeList = [];

  page: number = 1;
  pageSize: number = 12;
  debounceTime = 500;
  query = '';
  AreaList:any  = [
    {id: 'Hà Nội', title: 'Hà Nội'},
    {id: 'Đà Nẵng', title: 'Đà Nẵng'},
    {id: 'Hồ Chí Minh', title: 'Hồ Chí Minh'}
  ]
  totalEmployeeActive: any = 0;
  posList: any = [];
  posRoleList = [];
  selectAreaList: any = [];
  selectPosList = [];
  selectPosRoleList = [];
  roleId: string = 'all';

  public searchPosRole: string = '';
  public searchPos: string = '';
  public searchAreaList: string = '';
  filterKeyword = '';
  public subscription: Subscription;
  meta: any;
  title = 'Danh sách tư vấn viên';
  image = 'assets/img/profile-employee/new_banner_pc.png';
  description = 'Danh sách tư vấn viên';
  constructor(
    public router: Router,
    private employeeService: EmployeeService,
    public route: ActivatedRoute,
    private _metaService: Meta,
  ) {
    this.meta = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setMetaTags();
      }
    });
   }

  ngOnInit() {
    this.isLoading$.next(true);
    this.subscription = this.modelChanged
      .pipe(
        debounceTime(this.debounceTime),
      )
      .subscribe(() => {
        this.search();
      });
      const params = this.route.snapshot.queryParams;
      this.pagingEmployee.page = params.page ? parseInt(params.page) : 1;
      this.query = params.keyword || '';
      this.roleId = params.roleId || 'all';
    this.getEmployees();
  }

  private setMetaTags() {
      this._metaService.updateTag({ property: 'og:description', content: this.description });
      this._metaService.updateTag({ property: 'og:image', content: this.image });
      this._metaService.updateTag({ property: 'og:url', content: window.location.href });
      this._metaService.updateTag({ property: 'og:title', content: this.title });
 }

  getPos(){
    this.employeeService.getPos().subscribe(res => {
      this.posList = res;
    })
  }
  getRole() {
    this.employeeService.getRole().subscribe(res => {
      this.posRoleList = res;
    })
  }
  search() {
    this.pagingEmployee.page = 1;
    this.employeeList = [];
    this.getEmployees();
      this.router.navigate(
          [],
          {
              queryParams: {
                  page: 1,
                  keyword: this.query
              },
              queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
  }
  inputChanged() {
    this.modelChanged.next();
  }
  getEmployees() {
    this.pagingEmployee.items = [];
    this.isLoading$.next(true);
      // tìm kiếm theo keywords
      const params = {
        page: this.pagingEmployee.page || 1,
        pageSize: this.pageSize || 12,
        query: this.query || '',
        roleFilter: this.roleId || '',
      };
      this.currentFilter = {...this.currentFilter,...params}
      this.employeeService.getNearestEmployee(this.currentFilter).pipe(
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.loadData(res);
        });
  }
  menuClosed() {

  }

  loadData(res) {
    const rows = res.rows ? res.rows : res;
    const items = rows.map(emp => new Employee(emp));
    this.totalEmployeeActive = res.totalEmployeeActive
    this.pagingEmployee = new CPagination<Employee>({
      page:  this.pagingEmployee.page ?  this.pagingEmployee.page : 1,
      pageSize: this.pageSize,
      total: res.total,
      totalPage: res.totalPage ? res.totalPage : Math.ceil(res.total / this.pageSize),
      items: items
    });
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  }

  ngOnDestroy() {
    this.meta && this.meta.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  public onChangePagingEmployee(event: CPagination<Employee>) {
    this.page = event.page;
    this.pagingEmployee = event;
    this.getEmployees();
      this.router.navigate(
      [],
      {
          queryParams: {
              page: event.page
          },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
  clearSearch() {
    this.query = '';
    this.modelChanged.next();
  }

  onClickCheckBoxArea(checked: boolean, item: any) {
    item.checked = checked;
    this.selectAreaList = this.AreaList.filter(item => item.checked).map(item => item.id);
    this.searchAreaList = this.selectAreaList.toString() || '';
  }
  onClickCheckBoxPos(checked: boolean, item: any) {
    item.checked = checked
    this.selectPosList = this.posList.filter(item => item.checked).map(item => item.id)
    this.searchPos = this.selectPosList.toString() || '';
    this.currentFilter.posId = this.searchPos
  }
  onClickCheckBoxPosRole(checked: boolean, item: any) {
    item.checked = checked;
    this.selectPosRoleList = this.posRoleList.filter(item => item.checked).map(item => item.id);
    this.searchPosRole = this.selectPosRoleList.toString() || '';
    this.currentFilter.roleId = this.searchPosRole;
  }
  searchCode() {
    let keyword = '';
    if(this.searchKey.value) {
      keyword =  this.searchKey.value.trim();
      this.currentFilter.query = keyword;
    }
    this.search()
  }
  resetFilter() {
    this.uncheckFilter();
    this.currentFilter = {};
    this.getEmployees();
  }
  uncheckFilter() {
    if(this.posList.length) {
      this.selectPosList = [];
      this.posList.map(item => {
        item.checked = false
      })
    }
    if(this.posRoleList.length) {
      this.selectPosRoleList = [];
      this.posRoleList.map(item => {
        item.checked = false
      })
    }
  }
  scroll($element) {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  
  handlerKeyUp(event) {
    this.searchCode()
  }
  goToHome() {
      this.pagingEmployee.page = 1
      this.getEmployees();
      this.router.navigate(
      [],
      {
          queryParams: {
              page: 1
          },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
  getEmployeeByPosition($element) {
    this.roleId = $element
    this.pagingEmployee.page = 1
    this.getEmployees()
    this.router.navigate(
      [],
      {
        queryParams: {
            page: 1,
            roleId: this.roleId
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
    }
    )
  }  
}
