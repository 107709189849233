import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GuideComponent>,
  ) { }
  nextStep = 1;

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  onBack() {
    this.nextStep = 1;
  }
  
  goNext() {
    this.nextStep +=1;
  }
}
