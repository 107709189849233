import { Menu } from './menu.model';
import { RouterLink } from '@angular/router';

export const RouterLinkDefault = '/project';


const menus = [

    // (id, title, routerLink, href, icon, target, hasSubMenu, parentId, authorite)
    new Menu(11, 'Siêu thị dự án', null, null, 'icon-lead', null, true, 0, ['project','market', 'get', 'all']),
    new Menu(20, 'Khai thác Lead', null, null, 'icon-lead', null, true, 0, ['lead', 'primary', 'view', 'menu']),
    // new Menu(201, 'Lấy Lead mới', '/lead/survey', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(208, 'Khai thác Lead', '/lead/exploitation', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(205, 'Cấu hình nguồn Lead', '/lead/config/list', null, 'icon-dot', null, false, 20, ['lead', 'repository', 'manage']),
    new Menu(202, 'DS Lead đã phân bổ', '/lead/list', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    // new Menu(202, 'DS Lead đang khảo sát', '/lead/list', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(203, 'Quản lý Leads', '/import-ticket/demand', null, 'icon-dot', null, false, 20, ['lead', 'import']),
    new Menu(209, 'Phân bổ lead', '/lead/deliver', null, 'icon-dot', null, false, 20, ['lead', 'manual', 'deliver']),
    new Menu(204, 'Lịch sử tải nhập', '/import-ticket/history', null, 'icon-dot', null, false, 20, ['lead', 'import']),
    new Menu(207, 'Báo cáo thống kê', '/lead/report/exploitation', null, 'icon-dot', null, false, 20, ['lead', 'primary', 'view', 'menu']),

    new Menu(206, 'Quản lý nội dung', null, null, 'leftmenu-content', null, true, 0, ['care', 'get', 'all']),
    new Menu(2061, 'Intro (Splash Screen)', '/cms/intro', null, 'icon-dot', null, false, 206, ['care', 'intro', 'get', 'all']),
    new Menu(2062, 'Ưu đãi', '/cms/promotion', null, 'icon-dot', null, false, 206, ['care', 'promotion', 'get', 'all']),
    new Menu(2063, 'Từ khóa phổ biến', '/cms/popular-keywords', null, 'icon-dot', null, false, 206, ['care', 'keyword', 'get', 'all']),
    new Menu(2063, 'Trợ giúp', '/cms/help-question', null, 'icon-dot', null, false, 206, ['faq','get','all']),
    // new Menu(202, 'Lịch sử khai thác', '/ticket/history', null, 'history', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(3000, 'Quản lý gói vay', null, null, 'leftmenu-content', null, true, 0, ['property', 'package', 'loan']),
    new Menu(3001, 'Danh sách gói vay', '/loan/list', null, 'icon-dot', null, false, 3000, ['property', 'package', 'loan']),

    new Menu(5000, 'Quản lý nhà cung cấp', null, null, 'leftmenu-content', null, true, 0, ['utility', 'get', 'all']),
    new Menu(5001, 'Danh sách nhà cung cấp', '/supplier/list', null, 'icon-dot', null, false, 5000, ['utility', 'get', 'all']),
    // new Menu(5002, 'Danh sách dịch vụ', '/service/list', null, 'icon-dot', null, false, 5000, ['utility', 'get', 'all']),
    // new Menu(5003, 'Yêu cầu đặt chỗ', '/booking-seat/list', null, 'icon-dot', null, false, 5000, ['utility', 'get', 'all']),
    // new Menu(5004, 'Loại tiện ích', '/utilities/list', null, 'icon-dot', null, false, 5000, ['utility', 'get', 'all']),

    new Menu(6000, 'Quản lý tòa nhà', null, null, 'leftmenu-content', null, true, 0, ['utility', 'view', 'menu'], false, true),
    new Menu(6001, 'Dashboard', '/management-documnet-rule/dashboard/', null, 'icon-dot', null, false, 6000, ['utility', 'view', 'menu'], false,true),
    new Menu(6002, 'Quản lý cư dân', '/management-documnet-rule/population-manage/', null, 'icon-dot', null, false, 6000, ['utility', 'view', 'menu'], false,true),
    new Menu(6003, 'Quản lý công việc', '/management-documnet-rule/work/', null, 'icon-dot', null, false, 6000, ['leadjob','view','menu'], false,true),
    new Menu(6004, 'Quản lý yêu cầu', '/management-documnet-rule/list-care/', null, 'icon-dot', null, false, 6000, ['lead','care','view','menu'], false,true),
    new Menu(6005, 'Yêu cầu đặt chỗ', '/booking-seat/list/', null, 'icon-dot', null, false, 6000, ['booking', 'get', 'all'], false,true),
    new Menu(6006, 'Danh sách hóa đơn', '/bill-management/list/', null, 'icon-dot', null, false, 6000, ['invoice','get','all'], false,true),
    new Menu(6007, 'Phí dịch vụ', '/bill-management/service-charge-list/', null, 'icon-dot', null, false, 6000, ['service','charge','get','all'], false,true),
    new Menu(6008, 'Căn hộ', '/apartment-management/list/', null, 'icon-dot', null, false, 6000, ['care','resident','get','all'], false,true),
    new Menu(6009, 'Thư mục', '/management-documnet-rule/folder-list/', null, 'icon-dot', null, false, 6000, ['property','resident','document','folder','read'], false,true),
    new Menu(6010, 'Tài liệu', '/management-documnet-rule/document-list/', null, 'icon-dot', null, false, 6000, ['property','resident','document','read'], false,true),
    new Menu(6011, 'Ban quản lý ', '/management-documnet-rule/management-info/', null, 'icon-dot', null, false, 6000, ['property','management','get','all'], false,true),
    new Menu(6012, 'Tiện ích', '/utilities/list/ ', null, 'icon-dot', null, false, 6000, ['utility-type'], false,true),
    new Menu(6013, 'Lịch sử tạo hóa đơn', '/bill-management/bill-history-list/', null, 'icon-dot', null, false, 6000, ['invoice','history','get','all'], false,true),
    new Menu(6014, 'Danh sách dịch vụ', '/service/list/', null, 'icon-dot', null, false, 6000, ['utility', 'get','all'], false,true),
    // new Menu(6000, 'Ban quản lý', '/management-documnet-rule/project-list', null, 'leftmenu-content', null, false, 0, ['utility', 'view', 'menu']),
    // new Menu(7000, 'Quản lý hóa đơn', null, null, 'leftmenu-content', null, true, 0, ['utility', 'get', 'all']),
    // new Menu(7001, 'Danh sách hóa đơn', '/bill-management/list', null, 'icon-dot', null, false, 7000, ['utility', 'get', 'all']),
    // new Menu(7001, 'Phí dịch vụ', '/bill-management/service-charge-list', null, 'icon-dot', null, false, 7000, ['utility', 'get', 'all']),
    // new Menu(7001, 'Lịch sử tạo hóa đơn', '/bill-management/bill-history-list', null, 'icon-dot', null, false, 7000, ['utility', 'get', 'all']),


    // new Menu(2000, 'Quản lý căn hộ', null, null, 'leftmenu-content', null, true, 0, ['care', 'resident', 'get', 'all']),
    // new Menu(2001, 'Danh sách căn hộ', '/apartment-management/list', null, 'icon-dot', null, false, 2000, ['care', 'resident', 'get', 'all']),

    new Menu(70, 'Khách hàng', null, null, 'leftmenu-khachhang', null, true, 0, []),
    new Menu(701, 'Khách hàng tiềm năng', '/customer/pos', null, 'icon-dot', null, false, 70, ['property', 'customer', 'get', 'all']),
    new Menu(702, 'Khách hàng giao dịch', '/customer/tranx', null, 'icon-dot', null, false, 70, ['customer', 'get', 'all']),
    new Menu(703, 'Khách hàng PropCare', '/customer/care', null, 'icon-dot', null, false, 70, ['care', 'customer', 'get', 'all']),
    new Menu(704, 'Danh sách BQL PropCare', '/customer/management', null, 'icon-dot', null, false, 70, ['care', 'customer', 'get', 'all']),
    new Menu(705, 'Khách hàng DXHome', '/customer/dx-home', null, 'icon-dot', null, false, 70, ['dxhome', 'customer', 'get', 'all']),
    new Menu(706, 'Danh sách BQL DXHome', '/customer/management', null, 'icon-dot', null, false, 70, ['dxhome', 'customer', 'get', 'all']),

    new Menu(100, 'Dự án', null, null, 'leftmenu-duan', null, true, 0, ['property', 'project', 'get', 'all']),
    new Menu(101, 'Danh sách dự án', '/project', null, 'icon-dot', null, false, 100, ['property', 'project', 'get', 'all']),
    // new Menu(102, 'Quản lý bán hàng', '/project/event-sale', null, 'icon-dot', null, false, 100, ['property', 'project', 'get', 'all']),
    new Menu(107, 'Danh sách chủ đầu tư', '/investors', null, 'icon-dot', null, false, 100,  ['investor', 'get', 'all']),
    // new Menu(101, 'Danh sách chủ đầu tư', '/project/investor', null, 'icon-dot', null, false, 100, ['investor', 'get', 'all']),
    new Menu(103, 'Lịch sử tải nhập', '/project/history', null, 'icon-dot', null, false, 100, ['property', 'import', 'view', 'history']),
    new Menu(104, 'Lịch sử gửi email', '/project/email/history', null, 'icon-dot', null, false, 100, ['property', 'primary', 'mailer', 'history']),
    new Menu(105, 'Lịch sử đồng bộ', '/project/sync-history', null, 'icon-dot', null, false, 100, ['property', 'sync', 'view', 'history']),
    new Menu(106, 'Chương trình bán hàng', '/project/sales-program', null, 'icon-dot', null, false, 100, ['sales', 'program', 'get']),
    new Menu(107, 'Lịch sử tạo khách hàng PropCare', '/project/care-plus-history', null, 'icon-dot', null, false, 100, ['property', 'sync', 'view', 'history']),
    new Menu(108, 'Thiết lập E-Saleskit', '/project/esale-kit', null, 'icon-dot', null, false, 100, ['esalekit', 'get', 'all']),

    new Menu(170, 'Phí/ Hoa hồng', null, null, 'leftmenu-commission', null, true, 0, ['commission','view','sale','policy','menu']), // Thêm permission ở service commission
    new Menu(1701, 'Tính phí/ hoa hồng', '/commission', null, 'icon-dot', null, false, 170, ['commission', 'create']),
    new Menu(1704, 'Chỉ tiêu', '/commission/indicator', null, 'icon-dot', null, false, 170, ['commission', ]),
    new Menu(1705, 'Chính sách hoa hồng', '/commission/commission-policy', null, 'icon-dot', null, false, 170, ['commission', 'create']),
    new Menu(1706, 'Chính sách bán hàng', '/commission/sale-policy', null, 'icon-dot', null, false, 170, ['commission', 'create']),
    new Menu(1702, 'Hoa hồng cá nhân', '/commission/publish', null, 'icon-dot', null, false, 170, ['commission', 'list', 'get', 'id']),
    new Menu(1703, 'Lịch sử tải nhập', '/commission/import-history', null, 'icon-dot', null, false, 170, ['commission', 'view', 'history']),
    new Menu(1710, 'Báo cáo doanh thu', '/commission/report', null, 'icon-dot', null, false, 170, ['commission', 'view', 'history']),

    new Menu(180, 'Chính sách bán hàng', null, null, 'leftmenu-thanhtoan', null, true, 0, ['policy', 'view', 'menu']), // Thêm permission ở service primary-contract
    new Menu(1801, 'Chính sách thanh toán', '/sales-policy/payment-policy', null, 'icon-dot', null, false, 180, ['policy', 'get', 'all']),
    new Menu(1802, 'Chính sách chiết khấu', '/sales-policy/discount-policy', null, 'icon-dot', null, false, 180, ['policy', 'get', 'all']),
    // new Menu(1803, 'Tiến độ thanh toán/Góp vốn', '/sales-policy/payment-progress', null, 'icon-dot', null, false, 180, ['schedule', 'get', 'all']),
    // new Menu(1804, 'Bảng chiết khấu', '/sales-policy/discount-table', null, 'icon-dot', null, false, 180, ['discount', 'get', 'all']),

    new Menu(80, 'Thanh toán', null, null, 'leftmenu-thanhtoan', null, true, 0, ['transaction', 'get', 'all']),
    new Menu(801, 'Đề nghị thu tiền', '/receipt/create', null, 'icon-dot', null, false, 80, ['transaction', 'create']),
    new Menu(802, 'Danh sách đề nghị thu tiền', '/money-order', null, 'icon-dot', null, false, 80, ['transaction', 'get', 'all']),
    new Menu(803, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 80, ['transaction-refund', 'get', 'all']),

    // new Menu(86, 'Báo cáo dự án', null, null, 'leftmenu-thanhtoan', null, true, 0, ['transaction', 'get', 'all']),
    // new Menu(861, 'Báo cáo dự án', '/project-report', null, 'icon-dot', null, false, 86, ['transaction', 'create']),


    // new Menu(70, 'Thanh toán', null, null, 'leftmenu-thanhtoan', null, true, 0, ['transaction-refund', 'reject']),
    // new Menu(505, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 70, ['transaction-refund', 'get', 'all']),

    new Menu(85, 'Kế toán', null, null, 'leftmenu-ketoan', null, true, 0, ['transaction-order', 'approve']),
    new Menu(850, 'Phiếu thu', '/money-order/accounting/create', null, 'icon-dot', null, false, 85, ['transaction', 'create']),
    // new Menu(852, 'Danh sách phiếu thu', '/money-order/receipt-list', null, 'icon-dot', null, false, 85, ['transaction', 'create']),
    new Menu(854, 'Đề nghị thu tiền chờ duyệt', '/money-order/accounting/waiting', null, 'icon-dot', null, false, 85, ['transaction-order', 'approve']),
    new Menu(856, 'Đề nghị thu tiền đã xử lý', '/money-order/accounting', null, 'icon-dot', null, false, 85, ['transaction-order', 'approve']),
    new Menu(858, 'Chuyển khoản chờ xác nhận', '/money-order/event', null, 'icon-dot', null, false, 85, ['transaction-order', 'approve']),
    new Menu(8510, 'Lịch sử giao dịch Gateway', '/money-order/payment-history', null, 'icon-dot', null, false, 85, ['payment', 'gateway', 'log']),
    new Menu(8514, 'Phiếu chi', '/money-order/accounting/payment', null, 'icon-dot', null, false, 85,  ['transaction', 'create']),
    new Menu(8512, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 85, ['transaction-refund', 'get', 'all']),
    new Menu(120, 'Báo cáo', null, null, 'leftmenu-baocao', null, true, 0, ['report', 'view', 'menu']),
    new Menu(1201, 'Báo cáo yêu cầu tư vấn', '/ticket/report', null, 'icon-dot', null, false, 120, ['lead', 'report']),
    new Menu(1202, 'Báo cáo sản phẩm', '/property/report', null, 'icon-dot', null, false, 120, ['contract', 'report', 'property']),
    new Menu(1204, 'Báo cáo giao dịch', '/deal/report', null, 'icon-dot', null, false, 120, ['dealt', 'report']),
    new Menu(1203, 'Báo cáo nhân viên', '/employee/report', null, 'icon-dot', null, false, 120, ['employee', 'report']),
    new Menu(1203, 'Báo cáo doanh thu', '/contract/report', null, 'icon-dot', null, false, 120, ['contract', 'report', 'sale']),
    // new Menu(1205, 'Báo cáo bán mới', '/property/primary-report', null, 'icon-dot', null, false, 120,  []),
    new Menu(1205, 'Báo cáo bán mới', '/property/primary-report', null, 'icon-dot', null, false, 120, ['property', 'primary', 'report']),
    new Menu(1206, 'Báo cáo đặt chỗ, chuyển cọc', '/property/primary-detail-report', null, 'icon-dot', null, false, 120, ['property', 'ticket', 'report', 'primary', 'detail']),
    new Menu(1208, 'Báo cáo khai thác Lead', '/lead/exploit-report', null, 'icon-dot', null, false, 120, ['lead', 'report', 'view', 'menu']),
    new Menu(12010, 'Báo cáo kết quả khảo sát Lead', '/lead/survey-report', null, 'icon-dot', null, false, 120, ['lead', 'report', 'view', 'menu']),
    new Menu(12011, 'Báo cáo khai thác KHTN', '/customer/pos-report', null, 'icon-dot', null, false, 120, ['property', 'customer', 'report']),
    // new Menu(1203, 'Lịch sử cuộc gọi', '/call-log/report', null, 'icon-dot', null, false, 120, []),
    // new Menu(202, 'Lịch sử khai thác', '/ticket/history', null, 'icon-dot', null, false, 120, ['lead', 'get', 'all', 'lead']),
    // new Menu(304, 'Lịch sử tư vấn ký gửi', '/consignment/history', null, 'icon-dot', null, false, 120, []),
    new Menu(12012, 'Lịch sử cuộc gọi', '/call-log/report', null, 'icon-dot', null, false, 120,  ['call', 'log', 'report']),
    // new Menu(1202, 'Cuộc gọi đi', '/call-log/call-out', null, 'icon-dot', null, false, 120, []),
    // new Menu(1103, 'Cuộc gọi đến', '/call-log/income-call', null, 'icon-dot', null, false, 130, []),

    new Menu(12013, 'Báo cáo thống kê sản phẩm', '/property/report-statistics', null, 'icon-dot', null, false, 120, ['property', 'primary', 'view', 'report']),
    new Menu(12014, 'Báo cáo dự án', '/project-report', null, 'icon-dot', null, false, 120, ['property', 'project', 'view', 'report']),
    new Menu(12015, 'Báo cáo đại lý', '/sales-unit-report', null, 'icon-dot', null, false, 120, ['orgchart', 'view', 'report']),
    new Menu(12016, 'Báo cáo nhân viên', '/employee/report-sales', null, 'icon-dot', null, false, 120, ['employee', 'view', 'report']),
    new Menu(12014, 'Báo cáo khoản vay', '/project-report/loan-report', null, 'icon-dot', null, false, 120, ['property','primary','view','finance','report']),
    new Menu(12017, 'Báo cáo User DXHome', '/report/statistic-user-dxhome', null, 'icon-dot', null, false, 120, ['dxhome','customer','user','dashboard']),
    // new Menu(140, 'Trung tâm giao dịch', null, null, 'leftmenu-ttgiaodich', null, true, 0, ['orgchart', 'get', 'all']),
    // new Menu(1400, 'Sơ đồ tổ chức', '/orgchart', null, 'icon-dot', null, false, 140, ['orgchart', 'get', 'all']),
    // new Menu(1401, 'Kế hoạch phát triển TTGD', '/orgchart-pos/waiting-for-approval', null, 'icon-dot', null, false, 140, ['pos']),
    // new Menu(1402, 'Danh sách TTGD', '/orgchart-pos', null, 'icon-dot', null, false, 140, ['pos']),
    // // new Menu(703, 'Danh sách TVV chờ duyệt', '/', null, 'icon-dot', null, false, 140, ['employee']),
    // new Menu(1403, 'Danh sách TVV', '/employee', null, 'icon-dot', null, false, 140, ['employee']),
    // new Menu(1404, 'Chức năng người dùng', '/role', null, 'icon-dot', null, false, 140, ['role']),
    // // new Menu(706, 'Phân quyền', '/', null, 'icon-dot', null, false, 140, ['role']),
    // new Menu(1405, 'Gán nhân viên', '/orgchart-pos/assignment', null, 'icon-dot', null, false, 140, ['employee', 'sys', 'admin']),

    new Menu(240, 'Quản lý phân quyền người dùng', '/orgchart', null, 'leftmenu-ttgiaodich', null, true, 0, ['orgchart', 'get', 'all']),
    new Menu(2401, 'Danh sách ĐVBH', '/orgchart/sales-unit', null, 'icon-dot', null, false, 240, ['orgchart', 'get', 'all']),
    new Menu(2402, 'Danh sách user', '/orgchart/user-list', null, 'icon-dot', null, false, 240, ['employee', 'orgchart']),
    // new Menu(2408, 'Nạp tiền gói dịch vụ', '/transaction-payment/deposit-wallet-list', null, 'icon-dot', null, false, 240, ['role']),
    new Menu(2403, 'Danh sách user admin', '/orgchart/admin-user', null, 'icon-dot', null, false, 240, ['employee', 'get' , 'all', 'admin']),
    new Menu(2404, 'Vai trò người dùng', '/orgchart/user-role', null, 'icon-dot', null, false, 240, ['role', 'view', 'menu']),
    new Menu(2405, 'Lịch sử tải nhập user', '/orgchart/import-user-history', null, 'icon-dot', null, false, 240, ['employee', 'import', 'view', 'history']),
    new Menu(2406, 'Khách hàng dùng thử', '/user-trial', null, 'icon-dot', null, false, 240, ['trial', 'register', 'view', 'menu']),

    new Menu(150, 'Cài đặt', null, null, 'leftmenu-caidat', null, true, 0, ['system', 'admin', 'config']),
    new Menu(1500, 'Loại sản phẩm', '/category', null, 'icon-dot', null, false, 150, ['property', 'unit']),
    new Menu(1501, 'Thuộc tính sản phẩm', '/attribute/property', null, 'icon-dot', null, false, 150, ['property', 'attribute', 'get', 'all']),
    new Menu(1502, 'Mẫu sản phẩm', '/define-attributes/property', null, 'icon-dot', null, false, 150, ['property', 'template', 'get', 'all']),
    new Menu(1503, 'Mẫu nhu cầu ', '/define-attributes/demand', null, 'icon-dot', null, false, 150, ['demand']),

    new Menu(160, 'Danh sách TVV', '/orgchart/user-list', null, 'leftmenu-dsttv', null, false, 0, ['employee', 'orgchart']),

    new Menu(30, 'Yêu cầu tư vấn', null, null, 'icon-yeucautuvan', null, true, 0, ['lead', 'view', 'menu']),
    new Menu(301, 'Lấy YCTV mới', '/ticket', null, 'icon-dot', null, false, 30, ['lead', 'get', 'all', 'lead']),
    new Menu(302, 'TV Môi Giới đang khảo sát', '/demand', null, 'icon-dot', null, false, 30, ['demand', 'get', 'all']),
    new Menu(303, 'TV Ký Gửi đang khảo sát', '/consignment', null, 'icon-dot', null, false, 30, ['consignment', 'get', 'all']),
    new Menu(304, 'Tư vấn dịch vụ', '/ticket-advising', null, 'icon-dot', null, false, 30, ['lead', 'get', 'all', 'lead', 'advising']),


    new Menu(40, 'Hợp đồng', null, null, 'leftmenu-hopdong', null, true, 0, ['primary','contract','view','menu']), //thêm permission ở service primary-contract
    new Menu(401, 'Hợp đồng cọc', '/contract/deposit', null, 'icon-dot', null, false, 40, ['primary', 'contract', 'get', 'all']),
    // new Menu(402, 'Hợp đồng góp vốn', '/contract/borrow', null, 'icon-dot', null, false, 40, ['primary', 'contract', 'get', 'all']),
    new Menu(402, 'Hợp đồng mua bán/ Thuê', '/contract/purchase', null, 'icon-dot', null, false, 40, ['primary', 'contract', 'get', 'all']),
    new Menu(402, 'Hợp đồng chuyển nhượng', '/contract/transfer', null, 'icon-dot', null, false, 40, ['primary', 'contract', 'get', 'all']),
    // new Menu(405, 'HDMG chưa duyệt', '/contract/draft', null, 'icon-dot', null, false, 40, ['contract', 'get', 'all']),
    // new Menu(406, 'HDMG đã duyệt', '/contract/list', null, 'icon-dot', null, false, 40, ['contract', 'get', 'all']),
    // new Menu(407, 'HDMG cần duyệt', '/contract/need-approve', null, 'icon-dot', null, false, 40, ['contract', 'approve']),
    new Menu(408, 'Lịch sử tải nhập', '/contract/import-history', null, 'icon-dot', null, false, 40, ['contract', 'import', 'view', 'history']),
    new Menu(409, 'Thống kê công nợ', '/contract/debt-report', null, 'icon-dot', null, false, 40, ['primary','contract','get','dept','report']),


    new Menu(320, 'Hợp đồng', null, null, 'leftmenu-hopdong', null, true, 0, ['primary','view','transaction']), //thêm permission ở service primary-contract
    new Menu(3201, 'Giao dịch', '/contract/transaction', null, 'icon-dot', null, false, 320, ['primary', 'contract', 'get', 'all']),
    // new Menu(402, 'Hợp đồng góp vốn', '/contract/borrow', null, 'icon-dot', null, false, 40, ['primary', 'contract', 'get', 'all']),
    new Menu(3202, 'Hợp đồng mua bán/ Thuê', '/contract/purchase', null, 'icon-dot', null, false, 320, ['primary', 'contract', 'get', 'all']),
    new Menu(3202, 'Hợp đồng chuyển nhượng', '/contract/transfer', null, 'icon-dot', null, false, 320, ['primary', 'contract', 'get', 'all']),
    // new Menu(3205, 'HDMG chưa duyệt', '/contract/draft', null, 'icon-dot', null, false, 320, ['contract', 'get', 'all']),
    // new Menu(3206, 'HDMG đã duyệt', '/contract/list', null, 'icon-dot', null, false, 320, ['contract', 'get', 'all']),
    // new Menu(3207, 'HDMG cần duyệt', '/contract/need-approve', null, 'icon-dot', null, false, 320, ['contract', 'approve']),
    new Menu(3208, 'Lịch sử tải nhập', '/contract/import-history', null, 'icon-dot', null, false, 320, ['contract', 'import', 'view', 'history']),
    new Menu(3209, 'Thống kê công nợ', '/contract/debt-report', null, 'icon-dot', null, false, 320, ['primary','contract','get','dept','report']),


    new Menu(22, 'Thanh lý', null, null, 'leftmenu-hopdong', null, true, 0, ['proposal','view','menu']), //thêm permission ở service primary-contract
    new Menu(22001, 'Đơn đề nghị', '/liquidation/proposal', null, 'icon-dot', null, false, 22, ['proposal', 'get', 'all']),
    new Menu(22002, 'Thanh lý hợp đồng', '/liquidation/contract', null, 'icon-dot', null, false, 22, ['liquidation', 'get', 'all']),

    new Menu(41, 'Bàn giao', null, null, 'leftmenu-bangiao', null, true, 0, ['primary', 'contract', 'handover', 'get', 'all']),
    new Menu(411, 'Danh sách bàn giao', '/delivery', null, 'icon-dot', null, false, 41, ['primary', 'contract', 'handover', 'get', 'all']),
    new Menu(412, 'Thiết lập bàn giao', '/delivery/configurations', null, 'icon-dot', null, false, 41, ['primary', 'contract', 'handover', 'get', 'all']),

    new Menu(230, 'Yêu cầu hỗ trợ', null, null, 'leftmenu-sr', null, true, 0, ['lead', 'care', 'primary', 'view', 'menu']),
    new Menu(2301, 'Theo dõi tiến trình xử lí yêu cầu', '/lead-care/exploitation', null, 'icon-dot', null, false, 230, ['lead', 'care', 'get', 'all', 'lead']),
    new Menu(2302, 'Cấu hình SR Form', '/lead-care/config/list', null, 'icon-dot', null, false, 230, ['lead', 'care', 'repository', 'manage']),
    new Menu(2302, 'Yêu cầu chuyển nhượng', '/lead-care/transfer/list', null, 'icon-dot', null, false, 230, ['lead', 'care', 'get', 'all', 'lead']),
    new Menu(2303, 'DS các yêu cầu hỗ trợ', '/lead-care/list', null, 'icon-dot', null, false, 230, ['lead', 'care', 'get', 'all', 'lead']),
    // new Menu(1904, 'Quản lý yêu cầu hỗ trợ', '/import-ticket-care/demand', null, 'icon-dot', null, false, 190, ['lead', 'care', 'import']),
    // new Menu(1905, 'Lịch sử tải nhập', '/import-ticket-care/history', null, 'icon-dot', null, false, 190, ['lead', 'care', 'import']),
    new Menu(1906, 'Báo cáo thống kê', '/lead-care/report/exploitation', null, 'icon-dot', null, false, 230, ['lead', 'care', 'report', 'view', 'all']),

    new Menu(45, 'Rổ yêu cầu tư vấn', null, null, 'products', null, true, 0, ['lead', 'common', 'view', 'menu']),
    new Menu(450, 'TV Môi Giới', '/import-ticket/demand', null, 'icon-dot', null, false, 45, ['lead', 'import']),
    new Menu(451, 'TV Ký Gửi', '/import-ticket/consignment', null, 'icon-dot', null, false, 45, ['lead', 'import']),
    new Menu(452, 'Lịch sử', '/import-ticket/history', null, 'icon-dot', null, false, 45, ['lead', 'import']),

    new Menu(50, 'Sản phẩm', null, null, 'icon-quanlysp', null, true, 0, ['property','unit','view','menu']), // thêm permission ở service property
    new Menu(57, 'Sản phẩm khai thác', '/property-common', null, 'icon-dot', null, false, 50, ['property', 'unit', 'import']),
    new Menu(58, 'Sản phẩm O2O', '/property', null, 'icon-dot', null, false, 50, ['property', 'unit', 'get', 'all']),

    new Menu(60, 'Danh sách giao dịch', null, null, 'leftmenu-dsgiaodich', null, true, 0, ['dealt','view','menu']), // thêm permission ở service dealt
    new Menu(601, 'Giao dịch đang xử lý', '/deal/dealt', null, 'icon-dot', null, false, 60, ['dealt', 'get', 'all']),
    new Menu(602, 'Giao dịch cần duyệt', '/deal/dealt-approve', null, 'icon-dot', null, false, 60, ['dealing', 'get', 'waiting', 'all']),
    new Menu(603, 'Giao dịch đã xử lý', '/deal/dealt-completed', null, 'icon-dot', null, false, 60, ['dealing', 'get', 'waiting', 'all']),
    // new Menu(310, 'Giao dịch đã xử lý', '/', null, 'icon-dot', null, false, 50, ['dealt','get','all']),

    new Menu(90, 'Hậu kiểm', null, null, 'leftmenu-haukiem', null, true, 0, ['property', 'unit', 'pull']),
    new Menu(901, 'Hậu kiểm sản phẩm', '/property-authentication', null, 'icon-dot', null, false, 90, ['property', 'unit', 'pull']),
    new Menu(902, 'Danh sách hậu kiểm', '/property-authentication/authenticated', null, 'icon-dot', null, false, 90, ['property', 'unit', 'pull']),

    new Menu(110, 'Tiện ích', null, null, 'leftmenu-tienich', null, true, 0, ['news','view','menu']), // thêm permission ở service news
    new Menu(111, 'Lịch hẹn', '/booking', null, 'icon-dot', null, false, 110, []),
    new Menu(112, 'Quản lý thư mục', '/news-category', null, 'icon-dot', null, false, 110, ['news', 'category', 'get', 'all']),
    new Menu(113, 'Quản lý tin tức', '/news', null, 'icon-dot', null, false, 110, ['news', 'get', 'all']),

    new Menu(99, 'Quản lý công việc', '/workflow-management', null, 'icon-userlog', null, false, 0, ['work','view','menu']),

    new Menu(130, 'Chuyển ĐVBH', null, null, 'leftmenu-chuyendvbh', null, true, 0, ['orgchart']),
    new Menu(131, 'Tạo yêu cầu', '/transfer-unit-create', null, 'icon-dot', null, false, 130, ['orgchart', 'create', 'transfer', 'pos']),
    new Menu(132, 'Danh sách yêu cầu', '/transfer-unit-list', null, 'icon-dot', null, false, 130, ['orgchart', 'accept', 'transfer', 'pos']),
    // new Menu(112, 'Thông tin cập nhật', '/', null, 'icon-dot', null, false, 110, []),

    new Menu(200, 'User log', '/user-log', null, 'icon-userlog', null, false, 0, ['logger', 'get', 'in', 'project']),

    new Menu(210, 'Chức năng duyệt', '/workflow-step', null, 'leftmenu-workflow', null, true, 0, ['workflow','view','menu']), // thêm permission ở service workflow
    new Menu(2101, 'Nhóm duyệt', '/workflow-step/group', null, 'icon-dot', null, false, 210, ['group', 'get', 'all']),
    new Menu(2102, 'Flow duyệt', '/workflow-step/flow', null, 'icon-dot', null, false, 210, ['workflow', 'get', 'all']),
    new Menu(2103, 'Thực hiện', '/workflow-step/doing', null, 'icon-dot', null, false, 210, ['workflow', 'data', 'get', 'all']),

    new Menu(190, 'Training', '/training', null, 'leftmenu-training', null, true, 0, ['training']),
    new Menu(1901, 'Sự kiện', '/training/event', null, 'icon-dot', null, false, 190, ['training', 'get', 'all']),
    new Menu(1902, 'Đăng ký tham gia sự kiện', '/training/event-register', null, 'icon-dot', null, false, 190, ['training','register']),
    new Menu(1904, 'Danh sách tham dự', '/training/training-guest-list', null, 'icon-dot', null, false, 190, ['user', 'get', 'list', 'guest']),
    new Menu(1903, 'Lịch sử tải nhập', '/training/import-history', null, 'icon-dot', null, false, 190, ['training', 'view', 'history']),

    // new Menu(220, 'Quản lý yêu cầu', '/request-management', null, 'leftmenu-workflow', null, true, 0, []),
    // new Menu(2201, 'Danh sách yêu cầu', '/request-management/request', null, 'icon-dot', null, false, 220, []),
    // new Menu(2202, 'Danh sách SR Type', '/request-management/sr-type', null, 'icon-dot', null, false, 220, []),

    new Menu(220, 'Phiếu thanh toán Online', '/payment', null, 'leftmenu-thanhtoan', null, true, 0, ['payment']),
    new Menu(2201, 'Tạo phiếu thanh toán', '/payment/create', null, 'icon-dot', null, false, 220, ['payment', 'create']),
    new Menu(2202, 'Danh sách phiếu thanh toán', '/payment/list', null, 'icon-dot', null, false, 220, ['payment', 'get']),
    new Menu(2203, 'Lịch sử gửi mail chứng từ', '/payment/mail-histories', null, 'icon-dot', null, false, 220, ['payment', 'get']),

    new Menu(221, 'Building check in', null, null, 'leftmenu-haukiem', null, true, 0, ['building', 'access', 'view', 'menu']),
    new Menu(2211, 'Danh sách người ra vào', '/covid-checkin/user-list', null, 'icon-dot', null, false, 221, ['building', 'access', 'user', 'get', 'all']),
    new Menu(2212, 'Lich sử tải nhập', '/covid-checkin/user-list/history', null, 'icon-dot', null, false, 221, ['building', 'access', 'import', 'history', 'get', 'all'] ),
    new Menu(2213, 'Lich sử khai báo y tế', '/covid-checkin/user-list/health-decleration', null, 'icon-dot', null, false, 221, ['building', 'access', 'healthdeclaration', 'get', 'all'] ),
    new Menu(2214, 'Lịch sử ra vào tòa nhà', '/covid-checkin/user-list/access-list-history', null, 'icon-dot', null, false, 221, ['building', 'access', 'history', 'get', 'all'] ),
    new Menu(2215, 'Dánh sách lịch hẹn', '/covid-checkin/user-list/appointment-access-user', null, 'icon-dot', null, false, 221, ['building', 'access', 'user', 'get', 'all'] ),


    new Menu(250, 'Quản lý nhóm', null, null, 'leftmenu-khachhang', null, true, 0, ['group', 'get', 'all']),
    new Menu(2501, 'Nhóm', '/group-admin/project', null, 'icon-dot', null, false, 250, ['group', 'get', 'all']),
    new Menu(2502, 'Thành viên', '/group-admin/member-list', null, 'icon-dot', null, false, 250, ['group', 'get', 'all']),

    new Menu(260, 'Quản lý thông báo', null, null, 'leftmenu-thanhtoan', null, true, 0, ['notification', 'manual', 'view', 'menu']),
    new Menu(2601, 'Danh sách biểu mẫu', '/notification-manual/template', null, 'icon-dot', null, false, 260, ['notification', 'manual', 'template', 'get', 'all']),
    new Menu(2602, 'Danh sách nhóm khách hàng', '/notification-manual/group-employee', null, 'icon-dot', null, false, 260, ['notification','manual','customer','group','get','all']),
    new Menu(2603, 'Danh sách thông báo', '/notification-manual/list', null, 'icon-dot', null, false, 260, ['notification', 'manual', 'get', 'all']),
    new Menu(2604, 'Thông báo giao dịch', '/erp-transaction', null, 'icon-dot', null, false, 260, ['erp', 'transaction', 'view', 'menu']),
    new Menu(2605, 'Cấu hình mẫu tin nhắn', '/sms-template', null, 'icon-dot', null, false, 260, ['sms','template', 'get', 'all']),
    new Menu(2606, 'Cấu hình mẫu Email', '/mailer-template', null, 'icon-dot', null, false, 260, ['mailer', 'template', 'view', 'menu']),
    new Menu(2607, 'Cấu hình mẫu Zalo', '/zns-template', null, 'icon-dot', null, false, 260, ['zns', 'template', 'view', 'menu']),
    new Menu(2608, 'Lịch sử gửi tin nhắn', '/sms-history', null, 'icon-dot', null, false, 260, ['sms', 'log' , 'get', 'all']),
    new Menu(2609, 'Lịch sử gửi Email', '/email-history', null, 'icon-dot', null, false, 260, ['email','log','get','all']),
    new Menu(2610, 'Lịch sử gửi Zalo', '/zns-history', null, 'icon-dot', null, false, 260, ['zns','log','get','all']),

    new Menu(2611, 'Thông báo sinh nhật khách hàng', '/notification-manual/birthday', null, 'icon-dot', null, false, 260, ['notification', 'manual', 'get', 'all']),


    new Menu(290, 'Quản lý thông báo Dxhome', null, null, 'leftmenu-thanhtoan', null, true, 0, []),
    new Menu(280, 'Quản lý thông báo 1', null, null, 'leftmenu-thanhtoan', null, true, 0, ['notification', 'manual', 'view', 'menu']),

    ///new Menu(2900, 'Quản lý thông báo Dxhome', null, null, 'leftmenu-thanhtoan', null, true, 0, ['notification', 'manual', 'dxhome', 'get', 'all']),
    new Menu(441, 'Quản lý thông báo DxHomes', null, null, 'leftmenu-thanhtoan', null, true, 0, ['notification', 'manual', 'dxhome', 'get', 'all']),
    new Menu(4410, 'Danh sách biểu mẫu DxHomes', '/notification-manual-dxhome/template', null, 'icon-dot', null, false, 441, ['notification', 'manual', 'template', 'dxhome', 'get', 'all']),
    new Menu(4411, 'Danh sách nhóm khách hàng DxHomes', '/notification-manual-dxhome/group-employee', null, 'icon-dot', null, false, 441, ['notification','manual', 'customer','group','dxhome','get','all']),
    new Menu(4412, 'Danh sách thông báo DxHomes', '/notification-manual-dxhome/list', null, 'icon-dot', null, false, 441, ['notification', 'manual', 'dxhome', 'get', 'all']),
    //new Menu(2900, 'Quản lý thông báo 1', null, null, 'leftmenu-thanhtoan', null, true, 0, ['notification', 'manual', 'view', 'menu']),
    // new Menu(2601, 'Danh sách biểu mẫu', '/notification-manual/template', null, 'icon-dot', null, false, 260, ['notification', 'manual', 'template', 'get', 'all']),
    // new Menu(2602, 'Danh sách nhóm khách hàng', '/notification-manual/group-employee', null, 'icon-dot', null, false, 260, ['notification','manual','customer','group','get','all']),
    // new Menu(2603, 'Danh sách thông báo', '/notification-manual/list', null, 'icon-dot', null, false, 260, ['notification', 'manual', 'get', 'all']),
    new Menu(4413, 'Thông báo sinh nhật khách hàng DxHomes', '/notification-manual-dxhome/birthday', null, 'icon-dot', null, false, 441, ['notification', 'manual', 'dxhome', 'get', 'all']),

    new Menu(270, 'Check-in ', null,null,'/leftmenu-checkin', null, true, 0, ['checkin','view','menu']),
    new Menu(271, 'Cấu hình check-in', '/checkin-checkout/config', null, 'icon-dot', null, false, 270, ['checkin','config','view','menu']),
    new Menu(272, 'Danh sách check-in', '/checkin-checkout/report-checkin-checkout', null, 'icon-dot', null, false, 270, ['checkin','report','view','menu']),
    new Menu(273, 'Cấu hình điểm check-in', '/checkin-checkout/point', null, 'icon-dot', null, false, 270, ['checkin','position','view','menu']),

    new Menu(400, 'E-voucher', null,null,'/leftmenu-thanhtoan', null, true, 0, ["evoucher","view","menu"]),
    new Menu(4001, 'Thiết lập e-voucher', '/e-voucher', null, 'icon-dot', null, false, 400, ['evoucher','get','all']),
    new Menu(4002, 'Danh sách e-voucher', '/e-voucher/distribution', null, 'icon-dot', null, false, 400, ['distribution','get','all']),
    new Menu(4003, 'Lịch sử e-voucher', '/e-voucher/history', null, 'icon-dot', null, false, 400, ['history', 'get', 'all']),
    new Menu(4004, 'Dashboard', '/e-voucher/dashboard', null, 'icon-dot', null, false, 400, ['evoucher', 'dashboard']),

    // new Menu(160, 'Báo cáo', null, null, 'leftmenu-baocao', null, true, 0, []),
    // new Menu(8001, 'DXS', '/report/statistic-pos', null, 'icon-dot', null, false, 160, ['report', 'dxs']),
    // new Menu(8004, 'Trung tâm giao dịch', '/report/statistic-employee', null, 'icon-dot', null, false, 160, ['report' ,'pos']),
    // new Menu(8005, 'Cá nhân', '/report/statistic-personal', null, 'icon-dot', null, false, 160, ['report', 'employee']),
    // new Menu(8006, 'Công nợ', '/report/statistic-transaction-order', null, 'icon-dot', null, false, 160, ['transaction-order','get','liability']),
    // new Menu(8001, 'Thu nhập theo hiệu quả', '/report/statistic-income', null, 'icon-dot', null, false, 160, ['report', 'commission', 'total']),




    // new Menu(301, 'Tư vấn môi giới', '/demand', null, 'call', null, false, 30, ['demand','get','all']),
    // new Menu(303, 'Lịch sử tư vấn môi giới', '/demand/history', null, 'history', null, false, 30, ['demand','get','all']),
    // new Menu(302, 'Tư vấn ký gửi', '/consignment', null, 'call', null, false, 30, ['consignment','get','all']),


    // new Menu(305, 'Hợp đồng môi giới đã duyệt', '/contract', null, 'receipt', null, false, 30, ['contract','get','all']),
    // new Menu(306, 'Hợp đồng môi giới chưa duyệt', '/contract/draft', null, 'receipt', null, false, 30, ['contract','get','all']),
    // new Menu(305, 'Lịch sử hợp đồng môi giới', '/coming-soon', null, 'receipt', null, false, 30, ['contract']),
    // new Menu(306, 'Tư vấn chốt deal', '/deal/dealing', null, 'receipt', null, false, 30, ['dealt','get','all']),


    // new Menu(307, 'Đặt cọc 24h', '/coming-soon', null, 'event_seat', null, false, 30, ['']),

    // new Menu(40, 'Quản lý danh mục', null, null, 'category', null, true, 0, []),
    // new Menu(402, 'Sản phẩm', '/coming-soon', null, 'business', null, false, 40, ['property', 'unit']),
    // new Menu(403, 'Nhân viên thuộc POS', '/employee-pos', null, 'account_box', null, false, 40, ['employee','get','all']),





    // new Menu(60, 'Kế toán', null, null, 'attach_money', null, true, 0, ['transaction-order', 'approve']),
    // new Menu(605, 'Phiếu thu', '/receipt/create', null, 'input', null, false, 60, ['transaction', 'create']),
    // new Menu(601, 'Phiếu thu chờ xử lý', '/money-order/accounting/waiting', null, 'list', null, false, 60, ['transaction-order', 'approve']),
    // new Menu(606, 'Phiếu thu đã xử lý', '/money-order/accounting', null, 'list', null, false, 60, ['transaction-order', 'approve']),
    // new Menu(603, 'Xác nhận doanh thu', '/money-order/liquidate', null, 'confirmation_number', null, false, 60, ['contract','confirm','revenue']),
    // new Menu(604, 'Danh sách hợp đồng', '/contract-read', null, 'reorder', null, false, 60, ['transaction-order', 'approve']),

    // new Menu(70, 'Quản lý (DXS)', null, null, 'group_work', null, true, 0, ['report', 'dxs']),

    // new Menu(704, 'Danh sách nhân viên', '/employee', null, 'supervisor_account', null, false, 70, ['employee']),



    // new Menu(88, 'Rổ yêu cầu tư vấn', '/ticket-common', null, '', null, false, 0, ['lead', 'import']),
    // new Menu(89, 'Rổ sản phẩm', '/property-common', null, '', null, false, 0, ['property', 'unit','import']),
    // new Menu(90, 'Admin', null, null, 'how_to_reg', null, true, 0, ['system', 'admin', 'view']),


    // new Menu(905, 'Sản phẩm', null, null, 'business', null, true, 90, ['property']),
    // new Menu(9050, 'Danh sách', '/property', null, 'business', null, false, 905, ['property','unit','get','all']),


    // new Menu(907, 'Trung tâm giao dịch ', null, null, 'center_focus_strong', null, true, 90, ['pos']),
    // new Menu(9072, 'Danh sách ', '/orgchart-pos', null, 'list_alt', null, false, 907, ['pos','get','all']),
    // new Menu(9073, 'Chờ duyệt ', '/orgchart-pos/waiting-for-approval', null, 'list_alt', null, false, 907, ['pos']),
    // new Menu(100, 'Khác', null, null, 'how_to_reg', null, true, 0, ['system', 'admin', 'view']),


    // new Menu(110, 'Lịch sử cuộc gọi', null , null, 'phone', null, true, 0, []),

    new Menu(300, 'Quản lý cấu hình mobile', null, null, 'leftmenu-content', null, true, 0, ['master', 'mobile', 'app', 'config']),
    new Menu(301, 'Danh sách cấu hình', '/mobile-configuration/list', null, 'icon-dot', null, false, 300, ['master', 'mobile', 'app', 'config', 'get']),

    new Menu(310, 'Đồng bộ Erp ', null, null, 'leftmenu-content', null, true, 0, []),
    new Menu(311, 'Cấu hình Erp', '/sync-erp/sync-config', null, 'icon-dot', null, false, 310, ['sync','config','view','menu']),
    new Menu(312, 'Lịch sử đồng bộ', '/sync-erp/sync-erp-history', null, 'icon-dot', null, false, 310, ['syncerp','get','all']),

];

export const verticalMenuItems = menus;
export const horizontalMenuItems = menus;
