import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'receiptType' })
export class TransformReceiptTypeTransaction implements PipeTransform {
  transform(value: string) {
    return TransformReceiptTypeTransaction.parse(value);
  }
  static transform(value: string) {
    return this.parse(value);
  }
  private static parse(typeInput: any) {
    if(!typeInput) return '';
    let text = '';
    let type = typeInput.toUpperCase();
    switch (type) {
      case 'ALL':
        text = 'Tất cả';
        break;
      case 'DEPOSIT':
        text = 'Hợp đồng dịch vụ';
        break;
      case 'YCDCH':
        text = 'Đặt chỗ';
        break;
      case 'YCDC':
        text = 'Đặt cọc';
        break;
      case 'KTTT':
        text = 'Kế toán tạo';
        break;
      default:
        text = typeInput;
        break;
    }
    return text;
  }
}
