import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Constant} from '../../constant/constant';
import {UploadFileService} from '../../services/common/upload-file.service';
import {MultiImage, MultiImages} from './image.model';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {ImagePopup} from '../image-popup/image-popup';

@Component({
    selector: 'app-upload-multi-images',
    templateUrl: './upload-multi-images.component.html',
    styleUrls: ['./upload-multi-images.component.scss'],
})

export class UploadMultiImagesComponent implements OnInit, OnChanges {
    @Input() data;
    @Input() type;
    @Input() isView;
    @Input() fileExtensions: string[];
    @Input() defaultListName: string = '';
    @Input() maxItemsUpload: number;
    @Input() requiredWidth: number;
    @Input() requiredHeight: number;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    Constant = Constant;
    public images: any = new MultiImages();
    public isUpload: boolean = false;
    file: any = null;
    @Input() name: string = '';
    constructor(
        public uploadFileService: UploadFileService,
        public toastr: ToastrService,
        public dialog: MatDialog,
    ) {
        this.fileExtensions = ['jpeg', 'jpg', 'png', 'svg'];
    }
    ngOnInit() {

    }
    ngOnChanges(event) {
        if (event && event.data && event.data.currentValue) {
            this.images = event.data.currentValue
        } else {
            this.images = new MultiImages();
        }
    }
    uploadFileMulti(item?) {
        this.file = null;
        this.images.selectedItem = item;
        $('#inputFileMulti-' + this.name).click();
        if (!item) {
            let image = new MultiImage();
            this.images.list.push(image);
            this.images.selectedItem = image;
        }
    }
    selectFileMulti(event) {
        // const file = event.target.files[0];
        // check maximum items upload
        let totalImages: number = 0;
        this.images.list.map(item => {
            if (item.url) totalImages++;
        });
        totalImages += event.target.files.length;
        if (this.maxItemsUpload ? totalImages > this.maxItemsUpload : totalImages > Constant.MAX_ITEMS_UPLOAD) {
            event.target.value = ''
            this.toastr.error('Lỗi!', 'Số lượng hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.');
            return;
        }
        for (let file of event.target.files) {
            const isValid = this.validateIfImage(file)
            if (!isValid) {
                event.target.value = ''
                this.toastr.error('Lỗi!', 'Hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.');
                return;
            }
            // check image dimension
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    if ((this.requiredWidth && width != this.requiredWidth) || (this.requiredHeight && height != this.requiredHeight)) {
                        event.target.value = ''
                        this.toastr.error('Lỗi!', 'Hình ảnh không đúng quy định. Vui lòng kiểm tra lại hình ảnh của bạn.');
                        return;
                    }
                    this.isUpload = true;
                    this.images.selectedItem.loading = true;
                    return this.uploadFileService.uploadFile(event.target.files, this.type)
                      .then((res: any) => {
                          event.target.value = ''
                          let data = res.json();
                          setTimeout(() => {
                              for (let item of data) {
                                  this.setImageData(item);
                                  this.images.selectedItem.loading = false;
                              }
                          }, 1000 * data.length);
                      })
                      .catch((error) =>{
                          event.target.value = ''
                          this.images.selectedItem.loading = false;
                          return;
                      });
                };
            };
        }
    }
    setImageData(file) {
        this.images.selectedItem.url = file.url;
    }
    emitData() {
        return {
            list: this.images.list.map((image) => {
                return new MultiImage(image).createObjectRequestAPI();
            })
        }
    }
    removeImage(zone, item) {
        if (zone && zone.list) {
            let position = zone.list.indexOf(item);
            zone.list.splice(position, 1);
        }
    }

    validateIfImage(file: any): boolean {
        if (!!!file) {
            return false;
        }
        // check max size image 5MB
        if (file.size > Constant.MAX_SIZE_IMAGE) {
            return false;
        }
        return this.fileExtensions.includes(file.name.split('.').pop().toLocaleLowerCase());
    }
    openFile(item) {
        if (this.checkImage(item.url)) {
            // image, open popup
            this.openImagePopup(item.url);
        } else {
            // other file type, download
            window.open(item.url);
        }
    }
    checkImage(url: string) {
        const a = url.toLowerCase().match(/\.(jpeg|jpg|gif|png|svg)$/);
        return a != null;
    }
    openImagePopup(url: string): void {
        this.dialog.open(ImagePopup, {
            width: '1000px',
            data: {
                url: url
            }
        });
    }
}
