import { PropertyCommonService } from './property-common.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { MASTER_DATA } from 'app/pages/master-data/master-data.data';
import { DefineAttribute } from '../define-attributes/define-attributes.model';
import { resolve } from 'path';
import { async } from '@angular/core/testing';
import { Property } from '../property/property.model';
import { Contract } from '../contract/contract.model';
import { DateToFormat } from 'app/shared/parse/date-to-format';
import { Constant } from '../../shared/constant/constant';
export enum CategoryEnum {
	nha_o	= "c9eabf52-b21f-456b-a4e4-a4093502cb03",
	dat_nen	= "604074fa-ab2c-417d-b7fe-f52da9fb4467",
	can_ho	= "baf92168-1529-462e-8687-d20d6acde1bc",
	officetel	= "78b0853d-d18e-470c-b616-c81e268ae98d",
	shophouse	= "4f37f2fa-0abf-4187-a1cb-add5d2881438",
	condotel	= "9d461e77-a834-4152-88d8-c9a6fa776659",
	penthouse	= "2589529c-0aec-4da7-9f2c-ee38ef178bd4",
	biet_thu	= "06d3ffea-ba90-4912-8901-4adf44651ef4",
	mat_bang	= "6232fddc-2b86-4b35-ada5-4ea2be57b135",
	van_phong	= "c31f293a-6f39-4de2-8113-61740f216057",
	dat_nen_du_an	= "fbbec112-df7f-4cf2-8e72-c5c546be9b85",
}

export enum AttributeEnum {
	ten_du_an = "c11f2d92-06a5-4346-9c6d-acab1a2f261c",
	chu_dau_tu = "8a04cb5f-5e6c-45cd-b153-651dbe1d93b0",
	ma_can_ho = "839edf7a-eb11-4ba2-a57e-c77096552a33",
	thua_dat_so = "576088b5-d35c-44b1-9b2f-01b718adc663",
	to_ban_do_so = "3debf3ab-c9b1-4c53-a499-1161b8257c1e",
	ngang = "fedabcf5-ee57-4a5e-ba78-d56d9e6ad665",//chieu rong
	dai = "bfadabc3-a321-44e4-8713-2eeb678cf49c",//chieu dai
	dien_tich_tim_tuong = "1e84c643-4620-46d6-822f-8cc6061d114e",
	dien_tich_thong_thuy = "8901fe31-f168-4ad5-b6f6-760b9f86c05b",
	dien_tich_xay_dung = "c5ad19a9-b038-46c0-b6a7-2e257cf8f245",
	tong_dien_tich_san = "aa7f21c0-44ec-492c-9bb6-dfa6bbd20046",
	gia_ban_cdt = "3c1ef919-25a8-4697-b753-f59d292e5140",
	dieu_kien_thanh_toan = "bad80b6f-a377-4fc3-98c5-b390d52061f7",
	phap_ly = "9f9f7c4b-aefd-4bda-8aaa-b6f4809051e3",
	tinh_trang_ban_giao = "4d4caa5f-ba61-48f3-b48e-5e22cf8390ef",
	trang_thai = "b5c26dd3-37ff-496f-9d65-a01ca7395131",
	phong_ngu = "739c19da-d714-4306-9db3-d85cff852adc",
	phong_ve_sinh = "de7858dc-1588-402b-a884-1ab21a872d06",
	phong_khach = "a0456591-933d-4a59-9a57-cd67ab918eee",
	phong_bep = "a6cec6e8-dc67-45a8-8157-8e9ee5e09206",
	gara = "d79f4de5-8056-4698-b9e9-971b25c72f07",
	ghi_chu = "a082e36e-bb37-4bbd-a376-b5920df59001",
	da_co_hinh_anh = "ddcbe689-5f44-4975-895c-954af4d49d8a",
	da_khao_sat = "8a4062e6-c4a9-47a5-b8ca-6e17e059e16b",
	phan_hoi = "807c66ab-76d9-47a1-97e9-65798074d6b5",

	PROVINCE = '9b612c0f-0808-4bf7-8dc1-276aeb29bb26',
	DISTRICT = '3733780f-7958-4bb8-a995-ce7e5f6761a8',
	WARD = '741e9056-9efb-4572-ae0e-5d5f24e8888c',
	STREET = '64878c9d-db06-449b-92bb-e2bca73028ce',
	PROJECT = '36517455-b567-400d-b572-6be6b241fef4',
	LAND_TYPE = 'a88eb6b2-2196-4729-b7a9-ddaaaca7a955',
	HOUSE_TYPE = '06925ce8-84ee-456c-a6bf-8f628552218c',
	ADDRESSNUMBER = '9ec84afd-baef-45a3-bef2-6f0845c05dd2',
	APARTMENTNUMBER = '839edf7a-eb11-4ba2-a57e-c77096552a33',
	APARTMENTFLOORTH = '5d4b1de4-607e-459a-9c8f-f6c5f7470805',
	LOCATION = 'e4bb6028-1b5b-4ea6-bb87-af3f1db4aad3',
	DISTINCETOSTREET = 'a2b6dd56-5232-42a5-876d-954693459db8',
	ALLEYTURNS = '76af2c18-eb33-4fbf-96cb-0a9966f78697',
	ALLEYWIDTH1 = '2d5e530d-a714-4b34-b9f1-dd5afc95bffb',
	ALLEYWIDTH2 = '92494731-5684-4b8b-baad-4cafba71b8d5',
	ALLEYWIDTH3 = '4cd293dc-8b29-4534-aff4-c36d46c32310',
	AREATOTAL = 'a58030e1-46c0-454c-b687-c75aef1ec99a',
	AREATOTALWIDTH = 'cdb321ee-ed65-4707-870a-1a9febce1a08',
	AREATOTALLENGTH = '09770f36-990f-4686-aa4a-523998278491',
	AREATOTALBACKWIDTH = 'b5385f11-7db4-4571-a0a5-584865322b32',
	AREALEGAL = '62039b75-2a0d-4a89-b4ec-7daddb9c4880',
	AREALEGALWIDTH = '9ecd563c-3b6d-4161-9ab3-785cb00431f9',
	AREALEGALLENGTH = 'd59d1ff2-2966-4371-8474-395b667f26fa',
	AREALEGALBACKWIDTH = '3e2ff8dc-945d-4f96-8d8f-7c19f734796e',
	BEDROOMS = 'fa4c4d7e-9eca-44ff-8a70-a5079ab5c0df',
	BATHROOMS = 'a9a70930-a78e-4f22-8c64-37b8a89c346e',
	AREAUSABLE = '2134532d-7899-43bb-bdd0-a81f51417a14',
	AREACONSTRUCTIONFLOOR = 'cc90cdf2-31ed-4acf-8efa-7f768b9c54ce',
	FLOORCOUNT = '300b2f10-5358-455b-8f9e-84a5f4de7ae6',
	ADVINTERIORTYPES = 'ca42283b-7e90-4e36-bbad-b16c5a5a2cbf',
	REMAININGVALUE = '4c60e0ec-acb3-42c2-8784-ac49a8bf9fca',
	ADV_CONSTRUCTION_BASEMENT = 'e8c4a6e3-bd11-4214-a3fd-07f60c7905f0',
	ADV_CONSTRUCTION_MEZZANINE = 'ce7481bd-327f-4d8e-8b3c-83b1c3dc1662',
	ADV_CONSTRUCTION_TERRACE = '491ea886-6383-44d7-b083-834a959aae37',
	ADV_CONSTRUCTION_GARAGE = '355a1dc5-6119-49a9-a17e-5bd6489d32dc',
	ADV_CONSTRUCTION_ELEVATOR = '131ea7d4-1a1a-429c-a559-67e41d452042',
	ADV_CONSTRUCTION_SWIMMINGPOOL = '45930984-e208-4343-8de0-c26fff80494b',
	ADV_CONSTRUCTION_GARDEN = 'ea846c79-6c17-453d-bf04-f0954f18e3d8',
	ADV_CONSTRUCTION_SKYLIGHT = '1f86f40c-bded-4f91-aea8-62848835b858',
	ADV_PROPERTY_CORNERSTREET = '4cab37b2-26d2-4dfb-b5fc-435f679dea28',
	ADV_PROPERTY_CORNERALLEY = 'c3dfdef1-9ae7-4c03-bbfd-ef0b713c4236',
	ADV_PROPERTY_BACKALLEY = '6a16315d-0cc3-4ef7-b674-e526b1b7b96c',
	ADV_PROPERTY_CONNECTALLEY = '58907aec-2d50-42c5-a63b-5d7a72fbceeb',
	ADV_PROPERTY_SUPERMARKET = '8a90ca3c-7382-46b6-975a-894a9cff86ca',
	ADV_PROPERTY_PARKCENTER = 'f56d6eed-616b-4cf3-8bc2-4db04eb85cf0',
	ADV_PROPERTY_SECURITY = 'fca3f091-a3ea-416b-91fb-005c017baf3f',
	ADV_PROPERTY_BESTLOCATION = 'fcffd3c3-b8b7-4d38-aef0-e573c30f3842',
	ADV_PROPERTY_GOODFORBUSINESS = 'e640b749-2570-4995-a3a4-c262bd02c1f2',
	DIS_PROPERTY_ALLEYSTRIKE = 'e1f6fc31-feb4-43a8-884d-71073f449b12',
	DIS_PROPERTY_CHURCHTEMPLE = '17b5ab30-c011-463c-ad6f-32f7481fa7a8',
	DIS_PROPERTY_FUNERALHOUSE = 'f6d666de-f892-4cd8-9ea3-17d3649bb34c',
	DIS_PROPERTY_BRIDGEVOLTAGE = 'c14f7c3f-4176-4ea5-b4c0-059106522e95',
	DIS_PROPERTY_FACEDRAIN = '3219bab9-cb73-4a9e-b657-c7240d757f92',
	DIS_PROPERTY_FACEPOLE = '7d26ee0b-d069-449c-a0f8-993cb0d7fccc',
	DIS_PROPERTY_FACETREE = '1ad50060-d15c-4ebc-a394-263fcd0ad90f',
	DIS_PROPERTY_CANTBUILD = '4d4e86f6-234d-4160-ba4e-d90875603d1f',
	DIS_PROPERTY_SUSPLANNING = '39b66608-fdd1-42f3-99cf-c16690fc574d',
}
const SHEET_NAMES = ["Template"];
const SHEET_COLUMN = {
	"stt" : 0,
	"loai_bds" : 1,
	"ngay_tiep_nhan" : 2,
	"thoi_han_ky_gui" : 3,
	"ttgd" : 4,
	"hinh_thuc_ky_gui" : 5,
	"tinh_sp" : 6,
	"quan_sp" : 7,
	"phuong_sp" : 8,
	"duong_sp" : 9,
  "huong" : 10,
	"ma_can_ho" : 11,
  "thua_dat_so" : 12,
	"to_ban_do_so" : 13,
	"ngang" : 14,
	"dai" : 15,
	"dien_tich_tim_tuong" : 16,
  "dien_tich_thong_thuy" : 17,
  "dien_tich_tren_so" : 18,
	"dien_tich_xay_dung" : 19,
  "tong_dien_tich_san" : 20,
	"gia_ban_cdt" : 21,
	"gia_khach_gui" : 22,
	"chenh_lech" : 23,
	"gioi_thieu_san_pham" : 24,
	"dieu_kien_thanh_toan" : 25,
	"phap_ly" : 26,
	"tinh_trang_ban_giao" : 27,
	"trang_thai" : 28,
	"phong_ngu" : 29,
  "phong_ve_sinh" : 30,
  "phong_khach" : 31,
	"phong_bep" : 32,
	"gara" : 33,
	"ghi_chu" : 34,
	"phi_moi_gioi" : 35,
	"hoa_hong_nguon" : 36,
	"hoa_hong_truc_tiep" : 37,
	"da_co_hinh_anh" : 38,
	"da_khao_sat" : 39,
	"phan_hoi" : 40,
	"ket_qua" : 41,
	"categoryId" : 42,
	"projectName" : 43,
	"projectId" : 44,
	"end" : 44,
};

const DATA_TEMPLATE = {
	"stt" : "",
	"loai_bds" : "",
	"ngay_tiep_nhan" : "",
	"thoi_han_ky_gui" : "",//0
	"ttgd" : "",
	"hinh_thuc_ky_gui" : "",
	"tinh_sp" : "",
	"quan_sp" : "",
	"phuong_sp" : "",
	"duong_sp" : "",
  "huong" : "",
	"ma_can_ho" : "",
  "thua_dat_so" : "",
	"to_ban_do_so" : "",
	"ngang" : "",
	"dai" : "",
	"dien_tich_tim_tuong" : "",
  "dien_tich_thong_thuy" : "",
  "dien_tich_tren_so" : "",//0
	"dien_tich_xay_dung" : "",
  "tong_dien_tich_san" : "",
	"gia_ban_cdt" : "",
	"gia_khach_gui" : "",//0
	"chenh_lech" : "",//0
	"gioi_thieu_san_pham" : "",
	"dieu_kien_thanh_toan" : "",
	"phap_ly" : "",
	"tinh_trang_ban_giao" : "",
	"trang_thai" : "",
	"phong_ngu" : "",
  "phong_ve_sinh" : "",
  "phong_khach" : "",
	"phong_bep" : "",
	"gara" : "",
	"ghi_chu" : "",
	"phi_moi_gioi" : "",
	"hoa_hong_nguon" : "",
	"hoa_hong_truc_tiep" : "",
	"da_co_hinh_anh" : "",
	"da_khao_sat" : "",
	"phan_hoi" : "",
	"ket_qua" : "",
	"categoryId" : "",
	"projectName" : "",
	"projectId" : "",
};

const CUSTOMER_TEMPLATE = {
  "personalInfo": {
    "name": "DVKH",
    "lastName": "DVKH",
    "email": "dvkh@dvkh.com",
    "phone": "1234567890",
    "identities": [
      {
        "type": "CMND",
        "value": "123456788",
        "date": "1990-12-31T17:00:00.000Z",
        "place": "HCM"
      }
    ]
  },
  "identities": [
    {
      "type": "CMND",
      "value": "123456788"
    }
  ],
  "pos": {
		"id": "49f40ecc-291d-467e-805b-355694342e94",
		"name": "Phòng Dịch vụ khách hàng",
		"code":"S020010"
	},
  "bankInfo": {},
  "info": {
    "gender": "male",
    "rootAddress": {
      "province": "Hồ Chí Minh",
      "district": "Bình Thạnh",
      "ward": "Phường 25",
      "address": "2W Ung Văn Khiêm"
    },
    "address": {
      "province": "Hồ Chí Minh",
      "district": "Bình Thạnh",
      "ward": "Phường 25",
      "address": "2W Ung Văn Khiêm"
    },
    "birthday": "01/01/1971"
  },
  "employee": null,
  "consignmentId": null,
	"timestamp": 0
}

const SUCCESS = {
	code : 0,
	message: (p1) => `Tải thành công dòng ${p1}.`,
	data: null,
}

const SUCCESS_ALL = {
	code : 0,
	message: `Tải lên tất cả thành công`,
	data: null,
}

const VALID = {
	code : 0,
	message: "File hợp lệ.\nĐang tải nhập sản phẩm ...",
	data: null,
}



const REQUIRED_ERROR = {
	code : 1,
	message: (p1, p2) => `Dữ liệu tại dòng ${p1} cột ${p2} rỗng.`,
	data: null,
}
const DATA_ERROR = {
	code : 2,
	message: (p1, p2)=>`Dữ liệu tại dòng ${p1} cột ${p2} không hợp lệ.`,
	data: null,
}

const DUPLICATE_ERROR = {
	code : 3,
	message: (p1, p2) => `Dữ liệu trùng lặp dòng ${p1} và dòng ${p2}.`,
	data: null,
}

const EXIST_ERROR = {
	code : 4,
	message: (p1) => `Dữ liệu của file excel đã tồn tại trên hệ thống. Dòng ${p1}.`,
	data: null,
}

const IMPORT_ERROR = {
	code : 6,
	message: (p1) => `Tải không thành công dòng ${p1}.`,
	data: null,
}

const INVALID_ERROR = {
	code : 7,
	message: "File không hợp lệ.",
	data: null,
}

const CATEGORY_ID_ERROR = {
	code : 8,
	message: (p1) => `CategoryId rỗng tại dòng ${p1}. Không được xóa hoặc sửa cột CategoryId trong file Template-SanPham.xlsx`,
	data: null,
}


export class PropertyCommonExcel{

	public static async getData(service, arr, projectId, projectName){
		let error = null;
		let dataTemplates = [];
		let row = 0;
		let preData = null;
		for(let i = 0; i < arr.length; i++){
			const item = arr[i];
			if(SHEET_NAMES.find(sheetName => sheetName === item.sheetName))
			{
				for (const sheet of item.sheet){
					let dataTemplate = {...DATA_TEMPLATE};
					let sheetColumn : any = SHEET_COLUMN;
					dataTemplate.loai_bds = item.loai_bds;
					dataTemplate.categoryId = item.categoryId;
					const keyColumns = Object.keys(sheet)
					let count  = 0;
					for (const col of keyColumns) {
						if (sheet.hasOwnProperty(col)) {
							const cellValue = sheet[col];
							const keyOfTemplate = this.getKeyByValue(sheetColumn, count);
							if((cellValue === "" || cellValue === null) && count === SHEET_COLUMN.stt && row === 0){
								error = {...REQUIRED_ERROR}
								error.message = REQUIRED_ERROR.message(row + 1, count + 1);
								return error;
							}
							dataTemplate[keyOfTemplate] = cellValue;
							count = count + 1;
						}
					}
					if(dataTemplate.stt === "" || dataTemplate.stt === null){
						break;
					}

					dataTemplate.projectId = projectId;
					dataTemplate.projectName = projectName;

					dataTemplates.push(dataTemplate);
					preData = {...dataTemplate};
					row = row + 1;
				}
			}
		}
		if(dataTemplates.length === 0){
			error = {...INVALID_ERROR};
			return error;
		}

		let cellError = this.checkRequired(dataTemplates);
		if(cellError){
			if(cellError.field === SHEET_COLUMN.categoryId){

				error = {...CATEGORY_ID_ERROR}
				error.message = CATEGORY_ID_ERROR.message(cellError.param1);
				return error;
			}
			error = {...REQUIRED_ERROR}
			error.message = REQUIRED_ERROR.message(cellError.param1, cellError.param2);
			return error;
	  } else {
			cellError = this.validate(dataTemplates);
		}

		if(cellError){
			error = {...DATA_ERROR}
			error.message = DATA_ERROR.message(cellError.param1, cellError.param2);
			return error;
	  } else {
			cellError = this.checkDuplicate(dataTemplates);
		}

		if(cellError){
			error = {...DUPLICATE_ERROR}
			error.message = DUPLICATE_ERROR.message(cellError.param1, cellError.param2);
			return error;
		}
		 else {
			cellError = await this.checkExist(service, dataTemplates);
		}
		if(cellError){

			error = {...EXIST_ERROR}
			error.message = EXIST_ERROR.message(cellError.param1);
			return error;
		}
		VALID.data = await this.calcLocations(service, dataTemplates);
		return VALID;
	}

	private static getAddress(data){
		let tinh = data.tinh_sp.toString();
		tinh = this.boDauTiengViet(tinh);
		tinh = tinh.replace(/tinh|tp|thanh pho|thanhpho|thu do|thudo|td|,|;|\s/g, '');
		if(tinh === 'hcm' || tinh === 'tphcm' ){
			tinh = 'Hồ chí minh'
		} else if(tinh === 'hn'  || tinh === 'tdhn' ){
			tinh = 'Hà nội'
		} else if(tinh === 'brvt'){
			tinh = 'Bà rịa vũng tàu'
		} else {
			tinh = data.tinh_sp.toString();
		}
		if(isNaN(data.phuong_sp.toString()) === false) {
			data.phuong_sp = "Phường " + data.phuong_sp
		}
		if(isNaN(data.quan_sp.toString()) === false) {
			data.quan_sp = "Quận " + data.quan_sp
		}
		return (data.duong_sp + ", " + data.phuong_sp + ", " + data.quan_sp + ", " + tinh + ", Việt Nam");
	}
	private static getFakeAddress(data){
		let tinh = data.tinh_sp.toString();
		tinh = this.boDauTiengViet(tinh);
		tinh = tinh.replace(/tinh|tp|thanh pho|thanhpho|thu do|thudo|td|,|;|\s/g, '');
		if(tinh === 'hcm' || tinh === 'tphcm' ){
			tinh = 'Hồ chí minh'
		} else if(tinh === 'hn'  || tinh === 'tdhn' ){
			tinh = 'Hà nội'
		} else if(tinh === 'brvt'){
			tinh = 'Bà rịa vũng tàu'
		}  else {
			tinh = data.tinh_sp.toString();
		}
		return (data.duong_sp.replace(/^[\d|\w]+\s/, '') + ", " + data.phuong_sp + ", " + data.quan_sp + ", " + tinh);
	}
	private static getAddressCode(data) : string{
		const tinh_sp = data.tinh_sp;
		const quan_sp = data.quan_sp;
		const phuong_sp = data.phuong_sp;
		const duong_sp = data.duong_sp;
		let tinh = tinh_sp.toString();
		tinh = this.boDauTiengViet(tinh);
		tinh = tinh.replace(/tinh|tp|thanh pho|thanhpho|thu do|thudo|td|,|;|\s/g, '');
		if(tinh === 'hcm' || tinh === 'tphcm' ){
			tinh = 'Hồ chí minh'
		} else if(tinh === 'hn'  || tinh === 'tdhn' ){
			tinh = 'Hà nội'
		} else if(tinh === 'brvt'){
			tinh = 'Bà rịa vũng tàu'
		}
		tinh = this.boDauTiengViet(tinh);
		tinh = tinh.replace(/tinh|tp|thanh pho|thanhpho|thu do|thudo|td|,|;|\s/g, '');
		let quan = this.boDauTiengViet(quan_sp.toString());
		quan = quan.replace(/quan|huyen|thanh pho|thanhpho|tp|thi xa|thixa|tx|,|;|\s/g, '');
		let phuong = this.boDauTiengViet(phuong_sp.toString());
		phuong = phuong.replace(/phuong|thon|xom|ngo|ngach|,|;|\s/g, '');
		let duong = this.boDauTiengViet(duong_sp.toString());
		duong = duong.replace(/duong|quoc lo|ql|,|;|\s/g, '');
		let addressCode = duong+phuong+quan+tinh;
		return addressCode;
	}
	private static boDauTiengViet(str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    // str = str.replace(/\W+/g, ' ');
    // str = str.replace(/\s/g, '-');
    return str;
	}
	private static checkDuplicate(dataTemplates): any{
		for (let row = 0; row < dataTemplates.length - 1; row++){
			const dataRow = dataTemplates[row];
			const address = this.getAddressCode(dataRow);
			for (let rowNext = row + 1; rowNext < dataTemplates.length; rowNext++){
				const dataRowNext = dataTemplates[rowNext];
				const addressNext = this.getAddressCode(dataRowNext);
				if(address !== addressNext || dataRow.loai_bds != dataRowNext.loai_bds){
					continue;
				}
				if(
					(dataRow.thua_dat_so.toString() === dataRowNext.thua_dat_so.toString() && dataRow.to_ban_do_so.toString() === dataRowNext.to_ban_do_so.toString())
					|| (dataRow.ma_can_ho.toString() === dataRowNext.ma_can_ho.toString())
				){
					return {param1: row + 1, param2: rowNext + 1};
				}
			}
		}
		return null;
	}
	private static async checkExist(service, dataTemplates){
		let i = 0;
		for(i = 0; i < dataTemplates.length; i++){
			const data = dataTemplates[i];
			const result : boolean = await  this.checkExistOne(service, data);
			if(result === true){
				return {param1: i + 1};
			}
		}
		return null;
	}
	private static async checkExistOne(service, data){
		let findModel =  {
			categoryId: data.categoryId,
			address : data.address,
			addressCode : this.getAddressCode(data),
			ma_can_ho: data.ma_can_ho,
			thua_dat_so: data.thua_dat_so ? data.thua_dat_so.toString() : "",
			to_ban_do_so: data.to_ban_do_so ? data.to_ban_do_so.toString() : ""
		}
		const result = await service.checkExist(findModel).toPromise();
		return result.json();
	}

	private static checkRequired(dataTemplates): any{
		for (let row = 0; row < dataTemplates.length; row++){
			const dataRow = dataTemplates[row];
			for(let col = 0; col < SHEET_COLUMN.end; col++){
				const keyOfTemplate = this.getKeyByValue(SHEET_COLUMN, col);
				const cellValue = dataRow[keyOfTemplate];
				if((cellValue === null || cellValue === '') &&
					(
						((col !== SHEET_COLUMN.ma_can_ho && col !== SHEET_COLUMN.projectId ) &&
						(
							col === SHEET_COLUMN.ngay_tiep_nhan ||
							col === SHEET_COLUMN.hinh_thuc_ky_gui ||
							col === SHEET_COLUMN.tinh_sp ||
							col === SHEET_COLUMN.quan_sp ||
							col === SHEET_COLUMN.phuong_sp ||
							col === SHEET_COLUMN.duong_sp ||
							col === SHEET_COLUMN.thua_dat_so ||
							col === SHEET_COLUMN.dien_tich_tren_so ||
							col === SHEET_COLUMN.gia_khach_gui ||
							col === SHEET_COLUMN.phi_moi_gioi ||
							col === SHEET_COLUMN.hoa_hong_nguon ||
							col === SHEET_COLUMN.hoa_hong_truc_tiep
						)) ||
						(
							(col === SHEET_COLUMN.ma_can_ho || col === SHEET_COLUMN.projectId ) &&
							(dataRow['categoryId'] === CategoryEnum.can_ho || dataRow['categoryId'] === CategoryEnum.dat_nen_du_an)
						) ||
						(col === SHEET_COLUMN.categoryId)
					)
				){
					return {param1: row + 1, param2: col + 1, field: col};
				}
			}
		}
		return null;
	}
	private static validate(dataTemplates): any{
		for (let row = 0; row < dataTemplates.length; row++){
			const dataRow = dataTemplates[row];
			for(let col = 0; col < SHEET_COLUMN.end; col++){
				const keyOfTemplate = this.getKeyByValue(SHEET_COLUMN, col)
				const cellValue = dataRow[keyOfTemplate];
				if(!cellValue){
					continue;
				}
				if(
					(col === SHEET_COLUMN.ngay_tiep_nhan && moment(cellValue.toString(), 'D/M/YYYY',true).isValid() === false) ||
					(
						(cellValue.toString().match(/^[\d\.]+$/) === null) &&
					  (col === SHEET_COLUMN.thoi_han_ky_gui||
					   col === SHEET_COLUMN.ngang ||
					   col === SHEET_COLUMN.dai ||
					   col === SHEET_COLUMN.dien_tich_thong_thuy ||
					   col === SHEET_COLUMN.dien_tich_tim_tuong ||
					   col === SHEET_COLUMN.dien_tich_tren_so ||
					   col === SHEET_COLUMN.dien_tich_xay_dung ||
					   col === SHEET_COLUMN.tong_dien_tich_san ||
					   col === SHEET_COLUMN.gia_khach_gui ||
					   col === SHEET_COLUMN.gia_ban_cdt ||
					   col === SHEET_COLUMN.phong_ngu ||
					   col === SHEET_COLUMN.phong_khach ||
					   col === SHEET_COLUMN.phong_bep ||
					   col === SHEET_COLUMN.gara ||
					   col === SHEET_COLUMN.phong_ve_sinh ||
					   col === SHEET_COLUMN.phi_moi_gioi ||
					   col === SHEET_COLUMN.hoa_hong_nguon ||
					   col === SHEET_COLUMN.hoa_hong_truc_tiep)
					)
				){
					return {param1: row + 1, param2: col + 1, field: col};
				}
			}
		}
		return null;
	}

	private static getInfoTrading(){
		const infoTrading = {
			"fakeAddress": "",
			"customerOwnerId": "",
			"employeeTakeCareId": "",
			"employeeSalesId": "",
			"posId": "",
			"transactionType": "",
			"brokerFee": "",
			"brokerPercent": "",
			"investment": "",
			"salePrice": 0,
			"description": ""
		};
		return infoTrading;
	}

	private static getImages(){
		const images = {
			"list": [],
			"videos": [
				{
					"url": ""
				}
			],
			"avatar": "",
			"zones": [
				{
					"nameChange": "Trong nhà",
					"name": "Trong nhà",
					"list": []
				},
				{
					"nameChange": "Trước nhà",
					"name": "Trước nhà",
					"list": []
				},
				{
					"nameChange": "Sổ hồng",
					"name": "Sổ hồng",
					"list": []
				},
				{
					"nameChange": "Image 3D",
					"name": "Image 3D",
					"list": []
				}
			]
		};
		return images;
	}
	private static async getLocation(service, address){
		let reslocation : any = await service.getGeoCode({address : address}).toPromise();
		let location = {
			"type": "Point",
			"coordinates": [
				Number(reslocation.json().longitude),
				Number(reslocation.json().latitude)
			]
		}
		return location;
	}

	private static async calcLocations(service, dataTemplates){
		let i = 0;
		for(i = 0; i < dataTemplates.length; i++){
			const data = dataTemplates[i];
			if(i > 0){
				let j = i;
				for(j = i-1; j >= 0; j--){
					const preData = dataTemplates[j];
					if(this.getAddressCode(data) === this.getAddressCode(preData)){
						dataTemplates[i].location = preData.location;
						break;
					}
				}
			}
			if(!dataTemplates[i].location){
				dataTemplates[i].location = await  this.getLocation(service, this.getAddress(dataTemplates[i]));
			}
		}
		return dataTemplates;
	}
	private static createCommonAttribute(data){
		let area = 0;
		if(data.dien_tich_tren_so){
			area = Number(data.dien_tich_tren_so.toString())
		} else if(data.dien_tich_xay_dung){
			area = Number(data.dien_tich_xay_dung.toString())
		} else if(data.dien_tich_tim_tuong){
			area = Number(data.dien_tich_tim_tuong.toString())
		} else if(data.dien_tich_thong_thuy){
			area = Number(data.dien_tich_thong_thuy.toString())
		}
		let price = 0;
		if(data.gia_khach_gui) {
			price = Number(data.gia_khach_gui.toString());
		} else {
			price = Number(data.gia_ban_cdt.toString());
		}

		return [
			{
				"name": "price",
				"type": "Number",
				"weight": price,
				"value": price
			},
			{
				"name": "area",
				"type": "Number",
				"value": area,
			},
			{
				"name": "direction",
				"type": "Text",
				"value": data.huong
			}
		];
	}

	private static getCommonContract(property, data){
		let contract : any = {};
		// contract.source = Constant.COMMON_SOURCE;
		contract.name = "";
		contract.contractId = "";
		contract.brokerFeePercent = Number(data.phi_moi_gioi.toString())*100;
		contract.brokerFee = property.price*contract.brokerFeePercent/100;
		contract.sourceCommissionPercent = Number(data.hoa_hong_nguon.toString())*100;
		contract.salesCommissionPercent = Number(data.hoa_hong_truc_tiep.toString())*100;
		contract.startDate  = property.createdDate;
		if(data.thoi_han_ky_gui === null){
			contract.expiredMonths = 0;
		} else {
			contract.expiredMonths = Number(data.thoi_han_ky_gui.toString());
		}
		contract.expiredDate = moment(data.ngay_tiep_nhan.toString().trim(), "DD/MM/YYYY").add(contract.expiredMonths, 'months');

		return contract;
	}

	public static async sendApprove(service, data)  : Promise<any>  {
		return new Promise((resolve, reject) => {

			return service.sendApprove(data).subscribe((result: any) => {
				if (result.data || result.status < 400) {
					if (result._body) {
						resolve({result: true, status: result.status, msg: "send approve contract success"});
					}
					resolve({result: false, status: result.status, msg: "send approve contractnot found response body"});
				}
				resolve({result: false, status: result.status, msg: "send approve contract unknow error"});
			})
			// resolve(true);
		});
	}

	public static async approveCommon(service, data)  : Promise<any>  {
		return new Promise((resolve, reject) => {
			const headers = { stage : 'FORCE_APPROVE'};
			data.version = 2;
			return service.approveCommon(data, headers).subscribe((result: any) => {
				if (result.data || result.status < 400) {
					if (result._body) {
						resolve({result: true, status: result.status, msg: "approve contract success"});
					}
					resolve({result: false, status: result.status, msg: "approve contractnot found response body"});
				}
				resolve({result: false, status: result.status, msg: "approve contract unknow error"});
			})
			// resolve(true);
		});
	}
	public static async createContract(service, storageService, consignment, data)  : Promise<any>  {
		const contract = await this.getContract(consignment, data);
		return new Promise((resolve, reject) => {
			const headers = { stage: 'CREATE_CONTRACT' };
			return service.createContract(contract, headers).subscribe((result: any) => {
				if (result.data || result.status < 400) {
					if (result._body) {
						resolve({result: true, status: result.status, msg: "create common contract success"});
					}
					resolve({result: false, status: result.status, msg: "create common contract not found response body"});
				}
				resolve({result: false, status: result.status, msg: "create common contract unknow error"});
			})
			// resolve(true);
		});
	}
	private static async getContract(consignment, data){
		let contract : any = {};
		// contract.source = Constant.COMMON_SOURCE;
		contract.name = "";
		contract.contractId = "";
		contract.type = consignment.propertyUnit.transactionType;
		contract.ticket = {};
		contract.customers = [consignment.customer];
		contract.propertyUnit = consignment.propertyUnit;
		contract.consignment = {
			"name": ""
		};
		contract.consignmentId = consignment.propertyUnit.consignmentId;

		if(data.phi_moi_gioi){
			contract.brokerFee = consignment.propertyUnit.price;
			contract.brokerFeePercent = data.phi_moi_gioi*100/consignment.propertyUnit.price;
		} else {
			contract.brokerFeePercent = 2;
			contract.brokerFee = consignment.propertyUnit.price*2/100;
		}

		if(data.hoa_hong_nguon){
			contract.sourceCommissionPercent = Number(data.hoa_hong_nguon.toString())*100/contract.brokerFee;
			contract.salesCommissionPercent = 100 - contract.sourceCommissionPercent;
		} else if(data.hoa_hong_truc_tiep){
			contract.salesCommissionPercent = Number(data.hoa_hong_truc_tiep.toString())*100/contract.brokerFee;
			contract.sourceCommissionPercent = 100 - contract.salesCommissionPercent;
		}
		contract.startDate  = moment(data.ngay_tiep_nhan.toString().trim(), "DD/MM/YYYY").toDate();
		if(data.thoi_han_ky_gui === null)
			contract.expiredMonths = 0;
		else
			contract.expiredMonths = Number(data.thoi_han_ky_gui.toString());
		contract.expiredDate = moment(data.ngay_tiep_nhan.toString().trim(), "DD/MM/YYYY").add(contract.expiredMonths, 'months');
		contract.status = "MODIFY";
		contract.posId = data.ttgd;//pos lay tu pos cua nhan vien
		// contract.employeeEmail = data.email_nhan_vien;
		return contract;
	}
	public static async createSurveyPropertyUnit(service, consignmentId, data)   : Promise<any> {
		const property : any = await this.getProperty(data);
		const resAtttibutes = await service.getAttributeForCategory(data.categoryId, property.transactionType).toPromise();
		property.category = resAtttibutes.category;
		property.attributes = this.getAttributes(resAtttibutes, data);
		property.commonAttributes = this.createCommonAttribute(data);
		property.location = data.location;
		property.consignmentId = consignmentId;
		return new Promise((resolve, reject) => {
			return service.insertSurveyPropertyUnit(property).subscribe((result: any) => {
				if (result.data || result.status < 400) {
					if (result._body) {
						resolve({result: true, status: result.status, msg: "create survey property success"});
					}
					resolve({result: false, status: result.status, msg: "create survey property not found response body"});
				}
				resolve({result: false, status: result.status, msg: "create survey property unknow error"});
			})
		});
	}

	public static async importProperty(service, data, dataIndex)   : Promise<any> {
		const customer = CUSTOMER_TEMPLATE;
		const property : any = await this.getProperty(data);
		const resAtttibutes = await service.getAttributeForCategory(data.categoryId, property.transactionType).toPromise();
		property.category = resAtttibutes.category;
		property.attributes = await this.getAttributes(resAtttibutes, data);
		property.commonAttributes = await this.createCommonAttribute(data);

		property.location = data.location;
		property.consignmentId = '';
		const contract = await this.getCommonContract(property, data);
		const commonProperty = {customer, property, consignment:{}, contract, dataIndex};
		return new Promise((resolve, reject) => {
			return service.importProperty(commonProperty).subscribe((result: any) => {
				let error = null;
				if (result.data || result.status < 400) {
					if (result._body) {
						error = {...SUCCESS}
						error.message = SUCCESS.message(dataIndex)
						resolve(error);
					}
					error = {...IMPORT_ERROR}
					error.message = IMPORT_ERROR.message(dataIndex);
					resolve(error);
				}
				error = {...IMPORT_ERROR}
				error.message = IMPORT_ERROR.message(dataIndex)
				resolve(error);
			})
			// resolve(true);
		});
	}

	public static createCustomer(service: PropertyCommonService)  : Promise<any> {
		let customer = CUSTOMER_TEMPLATE;
		customer.timestamp = new Date().getTime();
		return new Promise((resolve, reject) => {
			return service.insertCustomer(customer).subscribe((result: any) => {
				if (result.data || result.status < 400) {
					if (result._body) {
						resolve({result: true, status: result.status, msg: "create customer success"});
					}
					resolve({result: false, status: result.status, msg: "create customer not found response body"});
				}
				resolve({result: false, status: result.status, msg: "create customer unknow error"});
			})
		});
	}



	private static async getProperty(data){
		let area = data.dien_tich_tim_tuong;
		if(area === null)
			area = data.dien_tich_thong_thuy;
		if(area === null)
			area = data.dien_tich_tren_so;
		if(area === null)
			area = data.dien_tich_xay_dung;

		const property : any = {};
		property.address = this.getAddress(data);
		property.fakeAddress = this.getFakeAddress(data);
		property.addressCode = this.getAddressCode(data);
		property.ward = data.phuong_sp;
		property.district = data.quan_sp;
		property.province = data.tinh_sp;
		property.direction = data.huong;
		let  transactionType = "SELL";
		if(data.hinh_thuc_ky_gui == "Thuê"){
			transactionType = "RENT";
		} else if(data.hinh_thuc_ky_gui == "Cho thuê"){
			transactionType = "LEASE";
		} else if(data.hinh_thuc_ky_gui == "Mua"){
			transactionType = "BUY";
		} else {
			transactionType = "SELL";
		}
		property.transactionType = transactionType;
		if(data.gia_khach_gui) {
			property.price = Number(data.gia_khach_gui.toString());
		} else {
			property.price = Number(data.gia_ban_cdt.toString());
		}
		property.area = area;
		property.description = data.gioi_thieu_san_pham;
		property.source = Constant.LEAD_SOURCE[0].key;
		property.category = {id: data.categoryId, name: data.loai_bds};
		property.attributes = [];
		property.commonAttributes = [];
		property.location = data.location;
		property.infoTrading = this.getInfoTrading();
		property.images = this.getImages();
		property.consignmentId = "";
		property.createdDate = moment(data.ngay_tiep_nhan.toString().trim(), "DD/MM/YYYY").toDate();
		property.project = {id: data.projectId, name: data.projectName}
		return property;
	}

  public static getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
	}

	private static getAttributes(dataAttribues, data) {
			let template: any = dataAttribues;
			if (template.id) {
				return template.templates.map((attribute) => {
					let defineAttribute = {...attribute};
					this.setValueOfAttribute(defineAttribute, AttributeEnum.ma_can_ho, data.ma_can_ho ? data.ma_can_ho.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.ten_du_an, data.projectName ? data.projectName.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.chu_dau_tu, data.chu_dau_tu ? data.chu_dau_tu.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.thua_dat_so, data.thua_dat_so ? data.thua_dat_so.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.to_ban_do_so, data.to_ban_do_so ? data.to_ban_do_so.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.ngang, data.ngang ? data.ngang.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.dai, data.dai ? data.dai.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.dien_tich_tim_tuong, data.dien_tich_tim_tuong ? data.dien_tich_tim_tuong.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.dien_tich_thong_thuy, data.dien_tich_thong_thuy ? data.dien_tich_thong_thuy.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.dien_tich_xay_dung, data.dien_tich_xay_dung ? data.dien_tich_xay_dung.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.tong_dien_tich_san, data.tong_dien_tich_san ? data.tong_dien_tich_san.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.gia_ban_cdt, data.gia_ban_cdt ? data.gia_ban_cdt.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.dieu_kien_thanh_toan, data.dieu_kien_thanh_toan ? data.dieu_kien_thanh_toan.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phap_ly, data.phap_ly ? data.phap_ly.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.tinh_trang_ban_giao, data.tinh_trang_ban_giao ? data.tinh_trang_ban_giao.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.trang_thai, data.trang_thai ? data.trang_thai.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phong_ngu, data.phong_ngu ? data.phong_ngu.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phong_ve_sinh, data.phong_ve_sinh ? data.phong_ve_sinh.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phong_khach, data.phong_khach ? data.phong_khach.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phong_bep, data.phong_bep ? data.phong_bep.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.gara, data.gara ? data.gara.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.ghi_chu, data.ghi_chu ? data.ghi_chu.toString(): "");
					this.setValueOfAttribute(defineAttribute, AttributeEnum.phan_hoi, data.phan_hoi ? data.phan_hoi.toString(): "");
					return defineAttribute;
				});
			} else {
				return [];
			}
	}

	private static setValueOfAttributeName(attribute, attributeName, value){
		if(attribute.attributeName === attributeName){
			if(!attribute.list){
				attribute.value = value;
			} else if (attribute.list.length > 0) {
				attribute.list.forEach(i => {
							if (i.value === value) {
								attribute.value = i.key;
							}
					})
			} else {
				attribute.value = value;
			}
		}
	}

	private static setValueOfAttribute(attribute, attributeId, value){
		if(attribute.attributeId === attributeId){
			if(!attribute.list){
				attribute.value = value;
			} else if (attribute.list.length > 0) {
				attribute.list.forEach(i => {
							if (i.value === value) {
								attribute.value = i.key;
							}
					})
			} else {
				attribute.value = value;
			}
		}
	}
}
