import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPos',
  pure: false
})
export class SearchPosPipe implements PipeTransform {
  public transform(value, keys: string, term: string, exactly?: boolean, sort?: boolean, fieldSort?: string, reverse?: boolean, isDVKH?: boolean) {
    value = value || [];
    if (!term) term = '';
    if (exactly) {
      value = value.filter(item => keys.split(',').some(key => term === '' || (item.hasOwnProperty(key) && term === item[key] && item[key] && item[key] !== '')));
    } else {
      value = value.filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]) && item[key] && item[key] !== ''));
    }
    if (sort) {
      if (isDVKH) {
        value = value.sort((a, b) => (a.status === 'SUCCESS' && a.depositStatus === 'APPROVED' && (a.customerServiceStatus === '' || a.customerServiceStatus === 'PROCESSING') ? -1 : 1));
      } else {
        value = this.sortData(value, fieldSort, reverse);
      }
    }
    return value;
  }
  sortData(array: Array<number | string>, field, reverse): Array<number | string> {
    const filedArr: string[] = field.split('.');

    return array.sort((a, b) => {

      const aValue = filedArr.reduce((preV, curV, curIndex) => {
        return preV[curV];
      }, a);

      const bValue = filedArr.reduce((preV, curV, curIndex) => {
        return preV[curV];
      }, b);

      if (reverse) {
        return (aValue < bValue ? 1 : -1);
      }
      return (aValue < bValue ? -1 : 1);
    });
  }
}
