import { IDecode } from './i-decode';

export enum EDocumentFileType {
    Video = 'video',
    Image = 'image',
    Link = 'link'
}

export enum EDocumentType {
    File = 'file',
    Folder = 'folder',
}

export class CDocument implements IDecode<CDocument> {
    public id: string;
    public originalName: string;
    public uploadName: string;
    public size: number;
    public url: string;
    public file: File;
    public description: string;
    public type: EDocumentType;
    public fileType: EDocumentFileType;
    public parentId: string;
    public thumbnailUrl: string;


    constructor(params: Partial<CDocument> = {}) {
        this.copy(params);
    }

    copy(params: Partial<CDocument> = {}): CDocument {
        params = params || {};
        this.id = params.id || null;
        this.originalName = params.originalName || null;
        this.uploadName = params.uploadName || null;
        this.size = params.size || null;
        this.url = params.url || null;
        this.file = params.file || null;
        this.description = params.description || null;
        this.type = params.type || null;
        this.fileType = params.fileType || null;
        this.parentId = params.parentId || null;
        this.thumbnailUrl = params.thumbnailUrl || null;
        return this;
    }

    copyList(params: Partial<CDocument>[] = []): CDocument[] {
        return params.map(el => new CDocument().copy(el));
    }

    decode(paramsApi: any): CDocument {
        return new CDocument(paramsApi);
    }

    decodeFile(file: File): CDocument {
        return new CDocument(Object.assign(file, { file }));
    }

    decodeFileList(files: File[]): CDocument[] {
        return files.map(el => new CDocument().decodeFile(el));
    }

    decodeList(paramsApi: any[]): CDocument[] {
        paramsApi = paramsApi || [];
        return paramsApi.map(el => new CDocument().decode(el));
    }
}