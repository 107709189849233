import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AuthenticateUserService } from './authenticate-user.service';
import { CookieUtilsService } from './cookie.service';
import { AuthorizeService } from '../services/common/authorize.service';
@Injectable()
export class AuthenticateService {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();
    private cookieService: CookieUtilsService;
    private isRequesting = false;
    constructor(
        private account: AuthenticateUserService,
        public authorizeService: AuthorizeService,
        public injector: Injector,
    ) {
        this.cookieService = injector.get(CookieUtilsService);
    }

    authenticate (identity) {
        this.userIdentity = identity;
        this.authenticated = identity;
        this.authenticationState.next(this.userIdentity);
    }
    identity (force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }
        // return Promise.resolve(this.userIdentity);
        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.getAuthorities().toPromise().then(account => {
            if (account) {
                this.userIdentity = account;
                // this.authorizeService.setRoleId(account.id);
                this.authorizeService.setAuthorities(account.authorities);
                this.authenticated = true;
                this.cookieService.setIsLogged(true);
            } else {
                this.userIdentity = null;
                this.authorizeService.setAuthorities(null);
                this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch(err => {
            this.userIdentity = null;
            this.authorizeService.setAuthorities(null);
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }
    setAuthenticated(value) {
        this.authenticated = value;
    }
    isAuthenticated (): boolean {
        return this.authenticated;
    }

    isIdentityResolved (): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): String {
        return this.isIdentityResolved () ? this.userIdentity.imageUrl : null;
    }
}
