import { IDecode } from './i-decode';
import { CBaseDate } from './c-base-date';

export class CContract extends CBaseDate implements IDecode<CContract> {
    public id: string | number;
    public name: string;
    public commissionFee: number;
    public revenue: number;
    public price: number;
    public dealtDeposit: string;

    constructor(params: Partial<CContract> = {}) {
        super(params);
        this.copy(params);
    }

    static copyList(params: Partial<CContract>[] = []): CContract[] {
        return params.map(el => new CContract().copy(el));
    }

    copy(params: Partial<CContract> = {}): CContract {

        super.copy(params);
        this.id = params.id || null;
        this.name = params.name || null;
        this.commissionFee = params.commissionFee || null;
        this.revenue = params.revenue || null;
        this.price = params.price || null;
        this.dealtDeposit = params.dealtDeposit || null;

        return this;
    }

    decode(paramsApi: any): CContract {
        return new CContract(paramsApi);
    }

    decodeList(paramsApi: any[]): CContract[] {
        return paramsApi.map(el => new CContract().decode(el));
    }
}
