import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { Constant } from 'app/shared/constant/constant';
import { ToastrService } from 'ngx-toastr';
// https://medium.com/@dancornilov/angular-5-image-uploader-component-api-integration-69dadf3e2728
@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  // @Input() image?: Image;
  // @Output() imageChange = new EventEmitter<Image>();
  @Input() imageUrl?: string;
  @Input() disabled?: boolean;
  @Input() isValidate: boolean = false;
  @Input() type?: string;
  @Input() width: number = 1000;
  @Input() quantity: number = 1;
  @Input() height: number = 1000;
  @Input() maxSize: number = Constant.MAX_SIZE_IMAGE;
  @Input() fileExtensions: string[] = ['jpeg', 'jpg', 'png', 'svg', 'gif'];
  @Input() accept: string[] = ['*']
  @Output() selected = new EventEmitter<File>();
  @Output() imageUrlChange = new EventEmitter<string>();
  @Input() typeApi?: string;
  @Output() imageFile = new EventEmitter<string>();
  @Output() uploadChange = new EventEmitter<any>();
  @Output() loadingStatus = new EventEmitter<boolean>();
  @Input () componentHeight: boolean = false;
  private extentsionVideo:string[] = ['mp4'];
  title: string = '';
  error: string = '';
  errorSize: string = '';
  errorExtention: string = '';
  isLoading: boolean = false;
  viewerOptions: any = {
    navbar: false,
    toolbar: {
      zoomIn: { show: true, size: 'large' },
      zoomOut: { show: true, size: 'large' },
      // prev: 4,
      // play: {
      //   show: 4,
      //   size: 'large',
      // },
      // next: 4,
      oneToOne: { show: true, size: 'large' },
      reset: { show: true, size: 'large' },
    }
  };
  get hasImage() {
    return !!this.imageUrl;
    // return (this.image && this.image.url) || this.imageUrl;
  }

  constructor(
    public uploadFileService: UploadFileService,
    public toastr: ToastrService,
  ) {

  }

  ngOnInit() {
    const fileExtensions = this.fileExtensions.map(i => ' .' + i);
    this.title = `Tối đa ${this.maxSize/1024/1024 > 1 ? this.maxSize/1024/1024 + 'MB' : this.maxSize/1024 + 'kB'}, định dạng:${fileExtensions.toString()}`
    this.error= `Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại${fileExtensions.toString()}, tối đa ${this.maxSize/1024/1024 > 1 ? this.maxSize/1024/1024 + 'MB' : this.maxSize/1024 + 'kB'}, kích thước ${this.width} x ${this.height} pixels .`;
    this.errorExtention = `Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại${fileExtensions.toString()}.`
    this.errorSize = `Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại${fileExtensions.toString()}, Dung lượng tối đa ${this.maxSize/1024/1024 > 1 ? this.maxSize/1024/1024 + 'MB' : this.maxSize/1024 + 'kB'}.`;
  }

  checkExtentsionUrl(){
   let exts = this.imageUrl? this.imageUrl.split('.').pop().toLocaleLowerCase() : '';
    if(this.extentsionVideo.includes(exts)){
      return true
    }return false
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0] as File;
    this.selected.emit(selectedFile);
    // Validate image
    if (!selectedFile) {
      this.toastr.error('Lỗi!', this.error);
      return;
    }
    if(!this.fileExtensions.includes(selectedFile.name.split('.').pop().toLocaleLowerCase())) {
      this.toastr.error('Lỗi!', this.errorExtention);
      return;
    }
    if(selectedFile.size > this.maxSize){
      this.toastr.error('Lỗi!', this.errorSize);
      return;
    }
    if (this.typeApi === Constant.UPLOAD_FILE_TYPE.ESALEKIT) {
      return this.uploadFileService.uploadFileESaleKit(selectedFile).subscribe(res => {
        setTimeout(() => {
          this.imageUrl = res.absoluteUrl;
          this.imageFile.emit(res);
          this.imageUrlChange.emit(this.imageUrl);
          this.uploadChange.emit(res)
          this.isLoading = false;
          this.loadingStatus.emit(this.isLoading)
        }, 1000);
        // Wait render image
      },(error) => {
        this.toastr.error('Lỗi!', error);
      })
    }
    if (this.isValidate) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = async () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if (this.width != width || this.height != height) {
            this.toastr.error('Lỗi!', this.error);
            return;
          } else {
            this.isLoading = true;
            return this.uploadFileService.uploadFile([selectedFile], this.type)
              .then((res: any) => {
                const data = res.json();
                if (data && data[0]) {
                  // Emit image url
                  setTimeout(() => {  // Amazon s3 file return 403 right after file uploaded
                    this.imageUrl = data[0].url;
                    this.imageUrlChange.emit(this.imageUrl);
                    this.uploadChange.emit(data[0])
                    this.isLoading = false;
                  }, 500);
                  // Emit image
                  // this.image = new Image(data[0]);
                  // this.imageChange.emit(this.image);
                } else {
                  this.isLoading = false;
                  // this.toastr.error('Lỗi!', 'Có lỗi sãy ra vui lòng thử lại');
                }
              })
              .catch((error) => {
                this.isLoading = false;
                this.toastr.error('Lỗi!', error);
              });
          }
        };
      };
    } else {
      this.isLoading = true;
      return this.uploadFileService.uploadFile([selectedFile], this.type)
        .then((res: any) => {
          const data = res.json();
          if (data && data[0]) {
            // Emit image url
            setTimeout(() => {  // Amazon s3 file return 403 right after file uploaded
              this.imageUrl = data[0].url;
              this.imageUrlChange.emit(this.imageUrl);
              this.uploadChange.emit(data[0])
              this.isLoading = false;
            }, 500);
            // Emit image
            // this.image = new Image(data[0]);
            // this.imageChange.emit(this.image);
          } else {
            this.isLoading = false;
            // this.toastr.error('Lỗi!', 'Có lỗi sãy ra vui lòng thử lại');
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.toastr.error('Lỗi!', error);
        });
    }
  }

  clearImage() {
    this.selected.emit(null);
    // Emit image url
    this.imageUrl = null;
    this.imageUrlChange.emit(this.imageUrl);
    this.uploadChange.emit(null)
    // Emit image
    // const emptyImage = new Image();
    // emptyImage.setAllNull();
    // this.imageChange.emit(emptyImage);
  }

}
