import { RegExConst } from '../constant/regEx.constant';
import { PrimaryTransactionStatus } from '../enum/primary-transaction.enum';
import {environment} from '../../../environments/environment';
import { PropertyTicketPermissionConst, TransactionPermissionConst } from 'app/enums/e-permission.enum';
import { Constant } from '../constant/constant';
import { PropertyTable } from 'app/pages/project/event-sale/property-table.model';
import { PropertyUnitStatus } from '../enum/property-unit.enum';
import _ = require('lodash');

export const parseEmail = (email: string): string => {
    if (email) {
        return email.replace(RegExConst.REG_NUMBER_CHARACTERISTICS, '.');
    }
    return '';
};

export const parsePhone = (value) => {
    if (!value) {
      return '';
    }
    if (parseInt(value) > -1) {
      return '******' + value.slice(-4);
    }
    return value;
};

export const convertTime = (time) => {
    let minute = 0;
    let second = 0;
    let print = '';
    if (time > 59) {
        minute = Math.floor(time / 60);
    }
    second = time - (minute * 60);

    print = minute < 10 ? '0' + minute : minute.toString();
    print += ':';
    print += second < 10 ? '0' + second : second.toString();
    return print;
};

export const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })
}

export const trimString = (alias) => {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
}


export const getRatioImage = (file): Promise<number> => {
    return new Promise((resolve, reject) => {
        var img = new Image();

        img.onload = function () {
            URL.revokeObjectURL(img.src);
            resolve(img.width / img.height);
        }

        img.onerror = function () {
            reject();
        }
        var objectURL = URL.createObjectURL(file);
        img.src = objectURL;
    });
}

export const getRecordStatus = (status) => {
    let result = '';
    switch (status) {
        case PrimaryTransactionStatus.CLOSE:
        case PrimaryTransactionStatus.CS_APPROVED_TICKET:
        case PrimaryTransactionStatus.CS_REJECTED_TICKET:
        case PrimaryTransactionStatus.DEPOSIT_APPROVED:
        case PrimaryTransactionStatus.DEPOSIT_REJECTED:
            result = PropertyUnitStatus.CLOSE;
            break;
        case PrimaryTransactionStatus.COMING:
            result = PropertyUnitStatus.COMING;
            break;
        case PrimaryTransactionStatus.PROCESSING:
        case PrimaryTransactionStatus.PROCESSING_UNPAID:
        case PrimaryTransactionStatus.PROCESSING_LOCK:
            result = PropertyUnitStatus.PROCESSING;
            break;
        case PrimaryTransactionStatus.POS_CONFIRM:
        case PrimaryTransactionStatus.POS_CONFIRM_UNPAID:
        case PrimaryTransactionStatus.POS_CONFIRM_LOCK:
            result = PropertyUnitStatus.CONFIRM;
            break;
        case PrimaryTransactionStatus.UNSUCCESS:
        case PrimaryTransactionStatus.CS_REJECTED_ESCROW:
            result = PropertyUnitStatus.UNSUCCESS;
            break;
        case PrimaryTransactionStatus.SUCCESS:
            result = PropertyUnitStatus.SUCCESS;
            break;
        default:
            break;
    }
    return result;
};

export const getCampaignErp = (demandCategory) => {
  const campaign = environment.campaignErp.find(item => Object.keys(item)[0] === demandCategory);
  if (campaign) {
    return Object.values(campaign)[0];
  }
  return null;
};

export const isSmsOtp = (ticket) => {
  return ticket && ticket.smsOtp;
}
export const checkOtpPending = (ticket) => {
  return ticket && ticket.status === PrimaryTransactionStatus.WAITING_OTP;
}
export const checkSMSWaiting = (ticket) => {
  return ticket && [PrimaryTransactionStatus.WAITING_SMS, PrimaryTransactionStatus.WAITING_SMS_LOCK].includes(ticket.status) ;
}
export const getSaleItemWithSortValue = (propertyUnit, user, currentPriority = -1) => {
  let sortVal = 0;
  if (!propertyUnit) { return null; }

  const priority = this.getPropUnitTartgetTicket(propertyUnit, currentPriority);
  const ticketConfirmedOrOtpPending = priority && (priority.status === PrimaryTransactionStatus.POS_CONFIRM || checkOtpPending(priority));

  if (user && (user.hasPermission(PropertyTicketPermissionConst.CustomerService.ApproveTicket) || user.hasPermission(TransactionPermissionConst.Query.ByProject))) {
    switch (propertyUnit.primaryStatus) {
      case PropertyUnitStatus.CONFIRM:
        sortVal = ticketConfirmedOrOtpPending ? 500 : 350;
        break;
      case PropertyUnitStatus.LOCK_CONFIRM:
        sortVal = 400;
        break;
      case PropertyUnitStatus.PROCESSING:
        sortVal = 300;
        break;
      case PropertyUnitStatus.SUCCESS:
        sortVal = 200;
        break;
      case PropertyUnitStatus.COMING:
        sortVal = 100;
        break;
      default:
        sortVal = 0;
        break;
    }
  } else {
    switch (propertyUnit.primaryStatus) {
      case PropertyUnitStatus.PROCESSING:
        sortVal = 40;
        break;
      case PropertyUnitStatus.COMING:
        sortVal = 30;
        break;
      case PropertyUnitStatus.CONFIRM:
      case PrimaryTransactionStatus.LOCK_CONFIRM_LOCK:
        sortVal = ticketConfirmedOrOtpPending ? 50 : 10;
        break;
      case PropertyUnitStatus.LOCK_CONFIRM:
        sortVal = -20;
        break;
      case PropertyUnitStatus.SUCCESS:
        sortVal = -30;
        break;
      default:
        sortVal = 0;
        break;
    }
  }

  if (priority && priority.posId) {
    if (user.pos.id === priority.posId || user.pos.parentId === priority.posId ||
      (propertyUnit.registeredPos && (user.pos.id === propertyUnit.registeredPos.id || user.pos.parentId === propertyUnit.registeredPos.id))) {
      sortVal += 10;
    } else {
      sortVal -= 50;
    }
  }

  propertyUnit.sortVal = sortVal;
  return propertyUnit;
};

export const getCurrentTimeSigned = () => {
  const currentTime = new Date().getTime() + '';
  const ind = Math.floor(Math.random() * (currentTime.length - 2));
  return `${ind}${currentTime}${currentTime.substr(ind, 2)}`;
};

export const getListSource = () => {
  return Object.keys(Constant.LEAD_SOURCE).map(item => ({'_id': item, name: Constant.LEAD_SOURCE[item]}));
};

export const getCareListSource = () => {
  return Object.keys(Constant.LEAD_CARE_SOURCE).map(item => ({'_id': item, name: Constant.LEAD_CARE_SOURCE[item]}));
};

export const getPropUnitTartgetTicket = (unit: PropertyTable, priority = 1, returnSuccess = true) => {
  // Find ticket with status LOCK_CONFIRM or SUCCESS first
  let ticket = null;
  if (returnSuccess) {
    ticket = unit.priorities.find(item => (item.status === PrimaryTransactionStatus.SUCCESS || item.status === PrimaryTransactionStatus.LOCK_CONFIRM));
    if (ticket && ticket.customerName) {
      return ticket;
    }
  }

  if (unit && unit.priority0 && unit.priority0.posId) {
    return unit.priority0;
  }

  // if not found, find by current priority
  switch (priority) {
    case 1:
      ticket = unit.priority1;
      break;
    case 2:
      ticket = unit.priority2;
      break;
    case 3:
      ticket = unit.priority3;
      break;
    default:
      ticket = {};
      break;
  }

  return ticket;
};
export const getShortName = (fileName) => {
  const nameArray = fileName.split(" ");
  var res = '';
  nameArray.map(i => {
    res = res + i.charAt(0).toUpperCase();
  });
  return res;
};

export const sortCode = (a, b) => {
  const sortA = a.code.split('-');
  const sortB = b.code.split('-');
  for (let idx = 0; idx < sortA.length; idx++) {
    if (idx + 1 <= sortB.length) {
      if (sortA[idx] !== sortB[idx]) {
        if (!isNaN(sortA[idx]) && !isNaN(sortB[idx])) {
          if (parseInt(sortA[idx]) > parseInt(sortB[idx])) {
            return 1;
          }
          if (parseInt(sortA[idx]) < parseInt(sortB[idx])) {
            return -1;
          }
        } else {
          return sortA[idx].localeCompare(sortB[idx]);
        }
      }
    } else {
      return -1;
    }
  }
  return a.code.localeCompare(b.code);
}
export const descPlaceholders = (descPlaceholders, data) => {
  const string = descPlaceholders.replace(
    /[{]{1}[(.)\w]+[}]{1}/g,
    placeholderWithDelimiters => {
      const placeholderWithoutDelimiters = placeholderWithDelimiters.substring(
        1,
        placeholderWithDelimiters.length - 1,
      );
      const stringReplacement = _.get(data ,placeholderWithoutDelimiters) || placeholderWithDelimiters;
      return stringReplacement;
    },
  );
  return string;
}


export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const genPeriod = (num: any) => {
  if(num && num.length > 0 && num.length <= 4) {
    const year = num.trim().toString().padEnd(4, "0");
    return Constant.month.map(x => ({id: `${year}${x}`, name: `Tháng ${x}/${year}`}));
  }
  if(num && num.length > 4) {
    return [];
  }
};

