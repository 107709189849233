
import { Injectable } from '@angular/core';
import { BaseService } from '../components/base.service';
import { ConstantUrl } from '../constant/url';
import { DataService, StorageService } from '.';
import { NotificationMsg } from '../../shared/models/notification-msg.model';
import { of, BehaviorSubject, Observable, timer, Subject, fromEvent } from 'rxjs';
import { map, takeUntil, repeatWhen } from 'rxjs/operators';
@Injectable()
export class NotificationService extends BaseService {
    ConstantUrl = ConstantUrl;
    public notifications$: BehaviorSubject<NotificationMsg[]>;
    public notificationsUnReadCount$: BehaviorSubject<Number>;
    // readonly observable$: Observable<any>;
    private readonly _stop = new Subject<void>();
    private readonly _start = new Subject<void>();

    constructor(
        public service: DataService,
        private storageService: StorageService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_notification_domain + 'notification';
        this.queryUrl = ConstantUrl.url_notification_query + 'notification';
        this.notifications$ = new BehaviorSubject<NotificationMsg[]>([]);
        this.notificationsUnReadCount$ = new BehaviorSubject<number>(0);
        // this.observable$ = timer(0, 10000)
        //   .pipe(
        //     map(() => {}),
        //     takeUntil(this._stop),
        //     repeatWhen(() => this._start)
        //   );
    }

    public getNotifications(isRead = false) {
        // const url = this.queryUrl + '?isRead=' + isRead.toString();
        const url = this.queryUrl;
        // Observable.of(this.dummyNotifications.slice(0, 10))
        // .subscribe( results => {
        //     const notifications = results.map( n => new NotificationMsg(n));
        //     this.notifications$.next(notifications);
        // });
        this.service.get(url)
            .map( results => results as NotificationMsg[])
            .subscribe( (notifications) => {
              // notifications = notifications.slice(0 , 10);
              if (notifications) {
                notifications.sort((a: any , b: any) => {
                  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                });
                this.notifications$.next(notifications);
              }
            });
    }

    getPagingNotice(params) {
      const url = this.queryUrl + '/getPaging';
      return this.service.get(url, params ? params : null);
    }
    // public startPolling(isRead = false) {
    //   this._start.next();
    // }
    // public stopPolling(isRead = false) {
    //   this._stop.next();
    //   this.notifications$.next([]);
    // }

    public markAsRead(id: string) {
      return new Promise((resolve, reject) => {
        if (!id) return;
        const url = this.queryUrl + '/' + id;
        return this.service.put(url, {})
            .subscribe( result => {
                let newData = this.notifications$.value.find( n => n.id === id);
                newData.isRead = true;
                this.notificationsUnReadCount$.next(Number(this.notificationsUnReadCount$.value) - 1);
                this.notifications$.next(
                  [...this.notifications$.value]);
                
                resolve(true);
            });
      });
    }

    public receiveNotification(items: NotificationMsg[]) {
        this.notifications$.value.unshift(...items);
        this.notifications$.next(
            [...this.notifications$.value]
        );
    }

    readAllNotification() {
      let url = ConstantUrl.url_notification_query + 'notification/readAll';
      return this.service.put(url, {});
  }
}
