import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BankInfo } from 'app/pages/customer/customer.model';
import { Constant } from 'app/shared/constant/constant';
import { BankService, IBankDropdown } from 'app/shared/services/bank.service';

export interface ConfirmData {
  title: string;
  message: string;
  textCancel: string;
  textOk: string;

  isReason: boolean;
  isTextInput: boolean;
  isCancelTicket: boolean;
  isRequireReason: boolean;
  isRequireTextInput: boolean;
  checkBoxResult: boolean;
  isCheckBoxOtp: boolean;
  isCheckBox: boolean;
  bindCbTxtToReason: boolean;
  placeholderReason: string;
  reason: string;
  bankInfo: any;
  checkBoxText: string;
  radioSelect: string;
  listRadio: any [];
  contentPadding: 0;
  textInputLabel: string;
  textInputValue: string;
  execute: boolean;
}

@Component({
  selector: 'confirm-popup',
  templateUrl: 'confirm-popup.html'
})
export class ConfirmPopup implements OnInit {

  public numberBankMask = Constant.numberBankMask;
  public banksDropdown: IBankDropdown[] = [];
  bankName: string;
  accountNo: string;
  listRadio: any [] = null;

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopup>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData,
    public bankService: BankService,) { }

  ngOnInit() {
    if(!this.data.placeholderReason){
      this.data.placeholderReason = 'Ghi chú';
    }
    if (this.data.isCancelTicket) {
      this.bankService.banksDropdown().subscribe((banks: IBankDropdown[]) => {
        this.banksDropdown = banks || [];
      });
      if (this.data.bankInfo) {
        this.bankName = this.data.bankInfo.CODE;
        this.accountNo = this.data.bankInfo.accountNumber;
      }
    }
    if (this.data.listRadio) {
      this.listRadio = this.data.listRadio;
    }
  }
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    this.data.execute = true;
    if (this.data.isCancelTicket) {
      this.data.bankInfo = this.banksDropdown.find(x => x.CODE === this.bankName);
      this.data.bankInfo = new BankInfo(this.data.bankInfo);
      this.data.bankInfo.accountNumber = this.accountNo;
    }
    this.dialogRef.close(this.data);
  }
  checkRequireReason() {
    if (typeof this.data.isRequireReason !== "undefined") {
      return this.data.isRequireReason && !this.data.reason;
    } else {
      return this.data.isReason && !this.data.reason;
    }
  }
  checkRequireTextInput() {
    if (typeof this.data.isRequireTextInput) {
      return this.data.isRequireTextInput && !this.data.textInputValue;
    } else {
      return this.data.isTextInput && !this.data.textInputValue;
    }
  }

  checkRequireRadio() {
    if (this.data.listRadio) {
      return !this.data.radioSelect;
    } else {
      return false;
    }
  }
  onCbChange(checked) {
    if (this.data.bindCbTxtToReason) {
      if (checked) {
        this.data.reason = this.data.reason ? this.data.reason : this.data.checkBoxText;
      } else {
        this.data.reason = this.data.reason.replace(this.data.checkBoxText, '');
      }
    }
  }
}
