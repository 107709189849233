import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-detail-view',
  templateUrl: './popup-html-info.component.html',
  styleUrls: ['./popup-html-info.component.scss']
})
export class PopupHtmlInfo implements OnInit {
constructor(
    public dialogRef: MatDialogRef<PopupHtmlInfo>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    htmlContent = '';
    title = '';
    
    ngOnInit() {
    this.title = this.data.title;
    this.htmlContent = this.data.text
  }

}
