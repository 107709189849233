import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Constant } from 'app/shared/constant/constant';
import { Demand } from 'app/pages/demand/demand.model';
import { Address } from 'app/shared/models/address.model';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import { DemandService } from 'app/pages/demand/demand.service';
import { Category } from 'app/pages/category/category.model';
import { TemplateAttributes } from 'app/pages/define-attributes/template-attributes.model';
import { Project } from '../../../../pages/project/project.model';
import { ProjectService } from 'app/pages/project/project.service';
const VIET_NAM = 'Việt Nam';
const MILLION: number = 1000 * 1000;
const BILLION: number = 1000 * 1000 * 1000;

@Component({
  selector: 'app-form-input-demand',
  templateUrl: './form-input-demand.component.html',
  styleUrls: ['./form-input-demand.component.scss']
})
export class FormInputDemandComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() data: Demand = new Demand();
  @Output() dataChange: EventEmitter<Demand> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();

  public Constant = Constant;
  public mainForm: FormGroup = null;
  public attributes: any[] = [];
  public listCategory: Category[] = [];
  public listDemandType: SelectItemModel[] = [
    { value: 'BUY', label: 'Mua' },
    { value: 'RENT', label: 'Thuê' }
  ];
  public listDemandCategory: SelectItemModel[] = [];
  public listDemandPriceRange: SelectItemRangeModel[] = [];
  public listDemandPriceRangeForBuy: SelectItemRangeModel[] = [
    { value: 1, range: { from: 0, to: 990 * BILLION }, label: 'Bất kỳ' },
    { value: 2, range: { from: 0, to: (500 * MILLION) - 1 }, label: '< 500 triệu' },
    { value: 3, range: { from: (500 * MILLION), to: (800 * MILLION) }, label: '500 - 800 triệu' },
    { value: 4, range: { from: (800 * MILLION), to: (1 * BILLION) }, label: '800 triệu - 1 tỷ' },
    { value: 5, range: { from: (1 * BILLION), to: (2 * BILLION) }, label: '1 tỷ - 2 tỷ' },
    { value: 6, range: { from: (2 * BILLION), to: (3 * BILLION) }, label: '2 tỷ - 3 tỷ' },
    { value: 7, range: { from: (3 * BILLION), to: (5 * BILLION) }, label: '3 tỷ - 5 tỷ' },
    { value: 8, range: { from: (5 * BILLION), to: (7 * BILLION) }, label: '5 tỷ - 7 tỷ' },
    { value: 9, range: { from: (7 * BILLION), to: (10 * BILLION) }, label: '7 tỷ - 10 tỷ' },
    { value: 10, range: { from: (10 * BILLION), to: (20 * BILLION) }, label: '10 tỷ - 20 tỷ' },
    { value: 11, range: { from: (20 * BILLION), to: (30 * BILLION) }, label: '20 tỷ - 30 tỷ' },
    { value: 12, range: { from: (30 * BILLION), to: (999 * BILLION) }, label: '> 30 tỷ' }
  ];
  public listDemandPriceRangeForRent: SelectItemRangeModel[] = [
    { value: 1, range: { from: 0, to: 990 * BILLION }, label: 'Bất kỳ' },
    { value: 2, range: { from: 0, to: 1 * MILLION }, label: '≤ 1 triệu' },
    { value: 3, range: { from: (1 * MILLION), to: (3 * MILLION) }, label: '1 - 3 triệu' },
    { value: 4, range: { from: (3 * MILLION), to: (5 * MILLION) }, label: '3 - 5 triệu' },
    { value: 5, range: { from: (5 * MILLION), to: (10 * MILLION) }, label: '5 - 10 triệu' },
    { value: 6, range: { from: (10 * MILLION), to: (40 * MILLION) }, label: '10 - 40 triệu' },
    { value: 7, range: { from: (40 * MILLION), to: (70 * MILLION) }, label: '40 - 70 triệu' },
    { value: 8, range: { from: (70 * MILLION), to: (100 * MILLION) }, label: '70 - 100 triệu' },
    { value: 9, range: { from: (100 * MILLION), to: (999 * BILLION) }, label: '≥ 100 triệu' }
  ];
  public listDemandAreaRange: SelectItemRangeModel[] = [
    { value: 1, range: { from: 0, to: 990 }, label: 'Bất kỳ' },
    { value: 2, range: { from: 0, to: 29 }, label: '< 30m2' },
    { value: 3, range: { from: 30, to: 50 }, label: '30m2 - 50m2' },
    { value: 4, range: { from: 50, to: 80 }, label: '50m2 - 80m2' },
    { value: 5, range: { from: 80, to: 100 }, label: '80m2 - 100m2' },
    { value: 6, range: { from: 100, to: 150 }, label: '100m2 - 150m2' },
    { value: 7, range: { from: 150, to: 200 }, label: '150m2 - 200m2' },
    { value: 8, range: { from: 200, to: 250 }, label: '200m2 - 250m2' },
    { value: 9, range: { from: 250, to: 300 }, label: '250m2 - 300m2' },
    { value: 10, range: { from: 300, to: 500 }, label: '300m2 - 500m2' },
    { value: 11, range: { from: 500, to: 999 }, label: '> 500m2' }
  ];
  public listDemandDirection: SelectItemModel[] = [
    { value: 'BAC', label: 'Bắc' },
    { value: 'DONG_BAC', label: 'Đông Bắc' },
    { value: 'TAY_BAC', label: 'Tây Bắc' },
    { value: 'DONG', label: 'Đông' },
    { value: 'DONG_NAM', label: 'Đông Nam' },
    { value: 'NAM', label: 'Nam' },
    { value: 'TAY_NAM', label: 'Tây Nam' },
    { value: 'TAY', label: 'Tây' }
  ];
  public listProject: SelectItemModel[] = [];
  public listProjectData: Project[] = [];
  public listProvince: Province[] = JSON.parse(HANH_CHINH_VN);
  public listProvinceAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictAddress: District[] = [];
  public listWardAddress: string[] = [];
  public commonAttributes = {
    price: {
      minValue: 0,
      maxValue: 999,
      weight: 50,
      unit: {
        text: 'tỷ',
        value: 1000 * 1000 * 10000
      }
    },
    location: {
      province: '',
      district: '',
      ward: '',
      address: '',
      weight: 50
    },
    area: {
      minValue: 0,
      maxValue: 999,
      weight: 50
    },
    direction: {
      value: '',
      weight: 50
    },
    project: {
      value: '',
      weight: 50
    }
  };
  public get fullAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('addressText').value ? result += `${this.mainForm.get('addressText').value}` : result += '';
    this.mainForm.get('addressWard').value ? result += `${dashSpace + this.mainForm.get('addressWard').value}` : result += '';
    this.mainForm.get('addressDistrict').value ? result += `${dashSpace + this.mainForm.get('addressDistrict').value}` : result += '';
    this.mainForm.get('addressProvince').value ? result += `${dashSpace + this.mainForm.get('addressProvince').value}` : result += '';
    this.mainForm.get('addressCountry').value ? result += `${dashSpace + this.mainForm.get('addressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }

  constructor(
    public formBuilder: FormBuilder,
    public service: DemandService,
    private projectService: ProjectService) { }

  ngOnInit() {
    const selectedCategoryId: string = this.data.category ? this.data.category.id : null;
    const selectedProjectId: string = this.data.unit.project ? (this.data.unit.project.id ? this.data.unit.project.id : null) : null;
    this.initForm(this.data);
    this.getCategories(selectedCategoryId);
    this.searchProject({ term: '' });
  }

  searchProject(event) {
    return this.projectService.getProjectListByName(event.term)
      .subscribe((res: any) => {
        this.listProjectData = res.data;
        this.listProject = this.listProjectData.map((item: any) => ({ value: item.id, label: item.name }));
      });
  }

  getProjectById(projectId: string) {
    return this.projectService.getOne(projectId).subscribe((res: any) => {
      if (res) {
        this.listProjectData.push(res);
        this.listProject = this.listProjectData.map((item: any) => ({ value: item.id, label: item.name }));
      }
    });
  }

  getProject(selectedProjectId?: string) {
    if (selectedProjectId && this.listProjectData && this.listProjectData.length > 0) {
      let pj = this.listProjectData.find((project: Project) => project.id === selectedProjectId);
      this.data.unit.project.id = pj.id;
      this.dataChange.emit(this.data);
    }
    else {
      this.getProjectById(selectedProjectId);
    }
  }

  ngOnChanges() {
    const selectedCategoryId: string = this.data.category ? this.data.category.id : null;
    const selectedProjectId: string = this.data.unit.project ? (this.data.unit.project.id ? this.data.unit.project.id : null) : null;
    this.initForm(this.data);
    this.getCategories(selectedCategoryId);
    this.getProject(selectedProjectId);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(demand: Demand) {
    if (!demand) {
      return;
    }
    if (typeof demand.unit.project === 'undefined') demand.unit.project.id = '';
    if (typeof demand.unit.code === 'undefined') demand.unit.code = '';
    const selectedCategoryId = demand.category ? demand.category.id : null;
    let selectedPriceRange = null;
    let selectedAreaRange = null;
    let selectedDirection = null;
    let interestedArea: Address = new Address({ country: VIET_NAM });
    if (demand.commonAttributes) {
      demand.commonAttributes.forEach((attribute: any) => {
        // Load thông tin giá cả vào các lựa chọn tương ứng trong list priceRange
        if (attribute.name === 'price') {
          if (demand.type === 'BUY') {
            // Tìm trong list khoảng giá (mua)
            selectedPriceRange = this.listDemandPriceRangeForBuy.find((item: SelectItemRangeModel) => {
              // Bỏ không check option đầu tiên (vì nó là điều kiện bất kỳ - luôn thỏa mãn)
              if (item.value === 1) {
                return false;
              }
              return item.range.from <= attribute.from && item.range.to >= attribute.to;
            });
            // Nếu không có đk nào thỏa mãn => Set điều kiện bất kỳ
            if (!selectedPriceRange) {
              selectedPriceRange = this.listDemandPriceRangeForBuy.find((item: SelectItemRangeModel) => item.value === 1);
            }
          } else {
            // Tìm trong list khoảng giá (thuê)
            selectedPriceRange = this.listDemandPriceRangeForRent.find((item: SelectItemRangeModel) => {
              // Bỏ không check option đầu tiên (vì nó là điều kiện bất kỳ - luôn thỏa mãn)
              if (item.value === 1) {
                return false;
              }
              return item.range.from <= attribute.from && item.range.to >= attribute.to;
            });
            // Nếu không có đk nào thỏa mãn => Set điều kiện bất kỳ
            if (!selectedPriceRange) {
              selectedPriceRange = this.listDemandPriceRangeForRent.find((item: SelectItemRangeModel) => item.value === 1);
            }
          }
        }
        // Load thông tin diện tích vào các lựa chọn tương ứng trong list area
        if (attribute.name === 'area') {
          selectedAreaRange = this.listDemandAreaRange.find((item: SelectItemRangeModel) => {
            // Bỏ không check option đầu tiên (vì nó là điều kiện bất kỳ - luôn thỏa mãn)
            if (item.value === 1) {
              return false;
            }
            return item.range.from <= attribute.from && item.range.to >= attribute.to;
          });
          // Nếu không có đk nào thỏa mãn => Set điều kiện bất kỳ
          if (!selectedAreaRange) {
            selectedAreaRange = this.listDemandAreaRange.find((item: SelectItemRangeModel) => item.value === 1);
          }
        }
        // Load thông tin hướng vào các lựa chọn tương ứng trong list direction
        if (attribute.name === 'direction') {
          selectedDirection = attribute.value;
        }
        // Load thông tin khu vực quan tâm
        if (attribute.name === 'location') {
          interestedArea = new Address({
            country: VIET_NAM,
            province: attribute.city,
            district: attribute.district,
            ward: attribute.ward,
            address: attribute.street
          });
          this.loadAddressOptions(interestedArea);
        }
      });
    }
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        // Thông tin khảo sát chung
        demandType: demand.type,
        demandCategory: selectedCategoryId,
        demandPriceRange: selectedPriceRange,
        demandAreaRange: selectedAreaRange,
        demandDirection: selectedDirection,
        // Khu vực quan tâm
        addressCountry: interestedArea.country,
        addressProvince: interestedArea.province,
        addressDistrict: interestedArea.district,
        addressWard: interestedArea.ward,
        addressText: interestedArea.address,

        projectId: demand.unit.project.id || '',
        searchType: demand.searchType,
        propertyCode: demand.unit.code || '',
        contractCode: demand.contractCode || ''
      });
      // Update input disable/enable state
      if (this.disabled) {
        this.mainForm.disable();
      } else {
        this.mainForm.enable();
        this.mainForm.get('addressCountry').disable();
      }
    } else {
      // Init form
      this.mainForm = this.formBuilder.group({
        // Thông tin khảo sát chung
        demandType: [{ value: demand.type, disabled: this.disabled }, Validators.required],
        demandCategory: [{ value: selectedCategoryId, disabled: this.disabled }, Validators.required],
        demandPriceRange: [{ value: selectedPriceRange, disabled: this.disabled }, Validators.required],
        demandAreaRange: [{ value: selectedAreaRange, disabled: this.disabled }, Validators.required],
        demandDirection: [{ value: selectedDirection, disabled: this.disabled }],
        // Khu vực quan tâm
        addressCountry: [{ value: interestedArea.country, disabled: true }],
        addressProvince: [{ value: interestedArea.province, disabled: this.disabled }, Validators.required],
        addressDistrict: [{ value: interestedArea.district, disabled: this.disabled }],
        addressWard: [{ value: interestedArea.ward, disabled: this.disabled }],
        addressText: [{ value: interestedArea.address, disabled: this.disabled }],

        projectId: [{ value: demand.unit.project.id, disabled: this.disabled }],
        searchType: [{ value: demand.searchType, disabled: this.disabled }],
        contractCode: [{ value: demand.contractCode, disabled: this.disabled }],
        propertyCode: [{ value: demand.unit.code, disabled: this.disabled }]
      });
      this.mainForm.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          // Patch data
          this.data = this.patchFormValue(this.data, this.mainForm.getRawValue());
          // Emit new data
          this.dataChange.emit(this.data);
          // Emit form valid state
          if (this.mainForm.get('searchType').value === 'code') {
            this.mainForm.get('propertyCode').setValidators([Validators.required]);
            this.validChange.emit(this.mainForm.valid);
          } else if (this.mainForm.get('searchType').value === 'contractCode') {
            this.mainForm.get('contractCode').setValidators([Validators.required]);
            this.validChange.emit(this.mainForm.valid);
          } else {
            this.validChange.emit(this.mainForm.valid);
          }
        });
    }
    this.onChangeType(demand.type, false);
  }

  // PUBLIC
  public validateForm(): boolean {
    this.markAsTouched(this.mainForm);
    let formValid = false;
    if (this.mainForm.get('searchType').value === 'code') {
      formValid = (this.mainForm.get('propertyCode').value !== '');
    } else if (this.mainForm.get('searchType').value === 'contractCode') {
      formValid = (this.mainForm.get('contractCode').value !== '');
    } else {
      this.getFormControl('propertyCode').setErrors(null);
      this.getFormControl('contractCode').setErrors(null);
      formValid = this.mainForm.valid;
    }
    return this.mainForm.disabled || formValid;
  }

  onChangeType(type: string, clearValue: boolean) {
    if (type === 'BUY') {
      this.listDemandPriceRange = [...this.listDemandPriceRangeForBuy];
    } else if (type === 'RENT') {
      this.listDemandPriceRange = [...this.listDemandPriceRangeForRent];
    } else {
      this.listDemandPriceRange = [];
    }
    if (this.mainForm && clearValue) {
      this.mainForm.get('demandPriceRange').patchValue(null);
    }
  }

  /** BEGIN - Xử lý Select Khu vực quan tâm */
  onChangeProvinceAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    this.mainForm.patchValue({
      addressDistrict: null,
      addressWard: null
    });
    this.listDistrictAddress = currentProvince.d;
  }

  onChangeDistrictAddress(districtName: string) {
    const provinceName = this.mainForm.get('addressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      return;
    }
    this.mainForm.patchValue({
      addressWard: null
    });
    this.listWardAddress = currentDistrict.w;
  }
  /** END - Xử lý Select Khu vực quan tâm */

  loadAddressOptions(address: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === address.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === address.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardAddress = currentDistrict.w;
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  patchFormValue(model: Demand, formData: any): Demand {
    model.type = formData.demandType;
    const selectedCategory: Category = this.listCategory.find((item: Category) => item.id === formData.demandCategory);
    model.category = selectedCategory;
    // Update attribute info
    const commonAttributes: any[] = [];
    // 1: Giá cả
    if (formData.demandPriceRange) {
      commonAttributes.push({
        name: 'price',
        type: 'Number',
        from: formData.demandPriceRange ? formData.demandPriceRange.range.from : this.commonAttributes.price.minValue,
        to: formData.demandPriceRange ? formData.demandPriceRange.range.to : this.commonAttributes.price.maxValue,
        weight: this.commonAttributes.price.weight
      });
    }
    // 2: Diện tích
    if (formData.demandAreaRange) {
      commonAttributes.push({
        name: 'area',
        type: 'Number',
        from: formData.demandAreaRange ? formData.demandAreaRange.range.from : this.commonAttributes.area.minValue,
        to: formData.demandAreaRange ? formData.demandAreaRange.range.to : this.commonAttributes.area.maxValue,
        weight: this.commonAttributes.area.weight
      });
    }
    // 3: Hướng
    if (formData.demandDirection) {
      commonAttributes.push({
        name: 'direction',
        type: 'Text',
        value: formData.demandDirection,
        weight: this.commonAttributes.direction.weight
      });
    }
    // 4: Khu vực quan tâm
    // if (formData.addressProvince && formData.addressDistrict && formData.addressWard && formData.addressText) {
    if (formData.addressProvince) {
      commonAttributes.push({
        name: 'location',
        type: 'Text',
        city: formData.addressProvince,
        district: formData.addressDistrict,
        ward: formData.addressWard,
        street: formData.addressText,
        value: this.fullAddress,
        weight: this.commonAttributes.location.weight
      });
    }
    // project
    if (formData.projectId) {
      const project = this.listProject.find((item: any) => item.value === formData.projectId);
      const displayName = project ? project.label : '';
      commonAttributes.push({
        name: 'project',
        type: 'Text',
        value: formData.projectId,
        displayName: displayName,
        weight: this.commonAttributes.direction.weight
      });
    }
    model.commonAttributes = commonAttributes;
    model.attributes = this.attributes;
    model.searchType = formData.searchType;
    model.unit = {};
    model.unit['project'] = { id: formData.projectId };
    model.unit['code'] = formData.propertyCode;
    model.contractCode = formData.contractCode;
    return model;
  }

  getTemplate(categoryId: string) {
    this.attributes = [];
    if (!categoryId || !this.mainForm) {
      return;
    }
    const request = {
      type: this.mainForm.get('demandType').value,
      category: {
        id: categoryId
      }
    };
    this.service.searchTemplate(request)
      .subscribe((res) => {
        const list = res.json();
        const template = new TemplateAttributes(list[0]);
        this.attributes = template.createObjectRequestAPI().templates;
      });
  }

  getCategories(selectedCategoryId?: string) {
    if (selectedCategoryId && this.listCategory && this.listCategory.length > 0) {
      this.data.category = this.listCategory.find((category: Category) => category.id === selectedCategoryId);
      this.initForm(this.data);
      this.dataChange.emit(this.data);
    }
    return this.service.getCategories()
      .subscribe((res: any) => {
        this.listCategory = res.rows ? res.rows : (res ? res : []);
        this.listDemandCategory = this.listCategory.map((item: any) => ({ value: item.id, label: item.name }));
        if (selectedCategoryId) {
          this.data.category = this.listCategory.find((category: Category) => category.id === selectedCategoryId);
          this.initForm(this.data);
          this.dataChange.emit(this.data);
        }
      });
  }

}

interface SelectItemModel {
  value: any;
  label: string;
}
interface SelectItemRangeModel {
  value: any;
  range: any;
  label: string;
}
interface Province {
  n: string;  // Name
  d: District[]; // Districts
}
interface District {
  n: string;  // Name
  w: string[];  // Wards
}
