import { Injectable, OnInit } from '@angular/core';
import { StorageService } from './storage.service';
import { environment } from 'environments/environment';
import { ConstantUrl } from '../constant/url';
import { DialogService } from './dialog.service';
import { HttpService } from './http.service';
import { parsePhone, parseEmail, convertTime } from '../utility/utility';
import { UserV2Service } from './common/user-v2.service';
import { CUser } from 'app/api-models';
import { CallInfo, CallHistory } from '../models/call.model';
import { Subject } from 'rxjs';
import { ToastrService } from 'app/shared/services/common';
declare var StringeeCall: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceCallService {

  constructor(
    private storageService: StorageService,
    private http: HttpService,
    private dialogService: DialogService,
    private userV2Service: UserV2Service,
    private toastr: ToastrService
  ) {

  }

  public fromAlias = '';
  public connected = false;
  private isCaller = false;
  private localStream: any;
  public static isInCalling = false;
  public hangupCallback = new Subject<CallHistory>();
  hangupCallback$ = this.hangupCallback.asObservable();
  public isCalling = new Subject<boolean>();
  isCalling$ = this.isCalling.asObservable();

  stringeeClient: any;
  call: any;
  interval: any;
  time = 0;
  callInfo: CallInfo = new CallInfo();
  callHistory: CallHistory;

  getCallToken(): Promise<string> {
    const user: CUser = this.userV2Service.user;
    const token = this.storageService.retrieve('voice_call_token');
    const expToken = this.storageService.retrieve('voice_call_token_exp');
    const now = Math.floor(Date.now() / 1000) - 60;
    const exp = now + 3600;
    const userLiveStream = {
        userId: this.storageService.retrieve('userId'),
        email: this.storageService.retrieve('emailToken'),
        name: this.storageService.retrieve('name')
    }
    const callTokenUrl = user.id ?
      ConstantUrl.url_communication_query + `communication/callToken?email=${user.email}&name=${user.name}&phone=${user.phone}&avatarUrl=${user.avatar}` :
      ConstantUrl.url_communication_query + `communication/callToken?email=${userLiveStream.email}&name=${userLiveStream.name}&phone=${userLiveStream.email}`;
    return new Promise((resolve) => {
      if (token && expToken && now <= expToken && this.storageService.retrieve('voice_call_id')) {
        resolve(token);
        return;
      }
      if (!user.id && !sessionStorage.getItem('userId')) {
        resolve(null);
        return;
      }
      this.http.get(callTokenUrl).subscribe((res: any) => {
        if (res) {
          this.storageService.store('voice_call_token', res.callToken);
          this.storageService.store('voice_call_token_exp', exp);
          this.storageService.store('voice_call_id', user.id ? parseEmail(this.storageService.retrieve('email')) : parseEmail(userLiveStream.email));
          resolve(res.callToken);
        }
      });
    });
  }

  getAuthenticationToken(): Promise<string> {
    const now = Math.floor(Date.now() / 1000) - 60;
    const exp = now + 3600;
    const expToken = this.storageService.retrieve('voice_call_authen_exp');
    const token = this.storageService.retrieve('voice_call_authen_token');
    const url = ConstantUrl.url_communication_query + 'communication/authenToken';

    return new Promise((resolve) => {
      if (token && expToken && now <= expToken) {
        resolve(token);
        return;
      }
      this.http.get(url).subscribe((res: any) => {
        if (res) {
          this.storageService.store('voice_call_authen_token', res.authenToken);
          this.storageService.store('voice_call_authen_exp', exp);
          resolve(res.authenToken);
        }
      });
    });
  }

  callOut(toNumber: string, name: string, ticketId: string = '', fromNumber: string = '') {
    let _this = this;

    // checking have another call
    if (VoiceCallService.isInCalling) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn đang thực hiện một cuộc gọi. Vui lòng kết thúc nó để bắt đầu cuộc gọi mới'
      });
      return;
    }

    // check to call to my self
    if (this.storageService.retrieve('voice_call_id') === toNumber) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn không thể gọi tới chính mình.'
      });
      return;
    }
    VoiceCallService.isInCalling = true;
    this.isCalling.next(true);
    this.callHistory = new CallHistory({
      startCall: new Date(),
      ticketId: ticketId
    });

    this.call = new StringeeCall(this.stringeeClient, fromNumber, toNumber);
    this.settingCallEvents(this.call);
    this.call.makeCall(function (res) {
      if (res.r === 0) {
        _this.callHistory.callId = res.callId;
        $('#callWaitingPlayer').attr('currentTime', 0);
        $('#callWaitingPlayer').trigger('play');
        $('#call-out').show();
        $('#call-out-number').text(name.trim() ? parsePhone(name) : 'Khách Hàng');
      }
      else {
        let message = '';
        switch (res.r) {
          case 11:
            message = 'Hiện người này đang trong 1 cuộc gọi khác. Vui lòng gọi lại trong giây lát.';
            break;
          case 1000:
            message = 'Thiết bị của bạn không hỗ trợ cuộc gọi. Vui lòng kiểm tra lại.';
            break;
          case 10:
          case 14:
            message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
            break;
          case 19:
            message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
            break;
        }
        _this.call = null;
        _this.hideCallPopup();
        _this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: message });
      }
    });
  }


  callOutWithRouteCall(toNumber: string, name: string) {
    let _this = this;

    // checking have another call
    if (VoiceCallService.isInCalling) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn đang thực hiện một cuộc gọi. Vui lòng kết thúc nó để bắt đầu cuộc gọi mới'
      });
      return;
    }

    // check to call to my self
    if (this.storageService.retrieve('voice_call_id') === toNumber) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn không thể gọi tới chính mình.'
      });
      return;
    }
    this.routeCall(this.storageService.retrieve('voice_call_id'), toNumber).subscribe(res => {
      VoiceCallService.isInCalling = true;
      this.isCalling.next(true);

      this.call = new StringeeCall(this.stringeeClient, res.fromNumber, res.toNumber);
      this.settingCallEvents(this.call);
      this.call.makeCall(function (res) {
        if (res.r === 0) {
          $('#callWaitingPlayer').attr('currentTime', 0);
          $('#callWaitingPlayer').trigger('play');
          $('#call-out').show();
          $('#call-out-number').text(name.trim() ? parsePhone(name) : 'Khách Hàng');
        }
        else {
          let message = '';
          switch (res.r) {
            case 11:
              message = 'Hiện người này đang trong 1 cuộc gọi khác. Vui lòng gọi lại trong giây lát.';
              break;
            case 1000:
              message = 'Thiết bị của bạn không hỗ trợ cuộc gọi. Vui lòng kiểm tra lại.';
              break;
            case 10:
            case 14:
              message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
              break;
            case 19:
              message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
              break;
          }
          _this.call = null;
          _this.hideCallPopup();
          _this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: message });
        }
      });
    }, res => {
        if (res.error && res.error.errors)
        for (let err in res.error.errors)
            this.toastr.error(res.error.errors[err]);
    });

  }


  callVideo(toNumber: string, name: string) {
    let _this = this;

    // checking have another call
    if (VoiceCallService.isInCalling) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn đang thực hiện một cuộc gọi. Vui lòng kết thúc nó để bắt đầu cuộc gọi mới'
      });
      return;
    }

    // check to call to my self
    if (this.storageService.retrieve('voice_call_id') === toNumber) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn không thể gọi tới chính mình.'
      });
      return;
    }
    VoiceCallService.isInCalling = true;
    this.isCalling.next(true);

    this.call = new StringeeCall(this.stringeeClient, this.storageService.retrieve('voice_call_id'), toNumber, true);
    this.settingCallEvents(this.call);
    this.call.makeCall(function (res) {
      if (res.r === 0) {
        _this.isCaller = true;
        $('#callWaitingPlayer').attr('currentTime', 0);
        $('#callWaitingPlayer').trigger('play');
        $('#call-out').show();
        $('#call-out-number').text(name.trim() ? parsePhone(name) : 'Khách Hàng');
      }
      else {
        let message = '';
        switch (res.r) {
        case 11:
          message = 'Hiện người này đang trong 1 cuộc gọi khác. Vui lòng gọi lại trong giây lát.';
          break;
        case 1000:
          message = 'Thiết bị của bạn không hỗ trợ cuộc gọi. Vui lòng kiểm tra lại.';
          break;
        case 10:
        case 14:
          message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
          break;
        case 19:
          message = 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.';
          break;
        }
        _this.call = null;
        _this.hideCallPopup();
        _this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: message });
      }
    });

  }

  async answerCall() {
    $('#ringtonePlayer').trigger('pause');
    this.countTime();
    this.callHistory = new CallHistory({
      startCall: new Date()
    });

    this.call.answer(function (res) {
    });
  }

  rejectCall() {
    this.hideCallPopup();
    this.call.reject(function (res) {
    });
  }

  hangupCall() {
    this.hideCallPopup();
    $('#remoteVideo').prop('srcObject', null);
    this.call.hangup(function (res) {
    });
  }

  disconnect() {
    this.stringeeClient.disconnect();
  }

  settingClientEvents(client) {
    let _this = this;
    this.stringeeClient = client;
    client.on('connect', function () {
    });

    client.on('authen', function (res) {
      if (res.r !== 0) {
        setTimeout(() => {
          _this.storageService.remove('voice_call_token');
          _this.getCallToken().then((r) => {
            client.connect(r);
          });
        }, 1000);
      }
    });

    client.on('disconnect', function () {
    });

    client.on('incomingcall', function (incomingcall) {
      if (incomingcall.toNumber === _this.storageService.retrieve('voice_call_id')) {
        _this.isCaller = false;
        _this.fromAlias = incomingcall.fromAlias;
        $('#ringtonePlayer').attr('currentTime', 0);
        $('#ringtonePlayer').trigger('play');
        $('#incomming-call').show();
        $('#incomming-number').text(parsePhone(incomingcall.fromAlias));
        _this.settingCallEvents(incomingcall);
        VoiceCallService.isInCalling = true;
        _this.isCalling.next(true);

      }
    });

    client.on('requestnewtoken', function () {
      // please get new access_token from YourServer and call:
      // client.connect(new_access_token);
      _this.storageService.remove('voice_call_token');

      _this.getCallToken().then((r) => {
        client.connect(r);
      });
    });

    client.on('otherdeviceauthen', function (data) {
    });
  }
  settingCallEvents(call1) {
    this.call = call1;
    let _this = this;
    call1.on('addlocalstream', function (stream) {
      _this.localStream = stream;
      if (_this.isCaller) {
        $('#localVideo_caller').prop('srcObject', null);
        $('#localVideo_caller').prop('srcObject', stream);
        $('#localVideo_caller').prop('muted', true);
      } else {
        $('#localVideo_callee').prop('srcObject', null);
        $('#localVideo_callee').prop('srcObject', stream);
        $('#localVideo_callee').prop('muted', true);
      }
    });

    call1.on('addremotestream', function (stream) {
      // reset srcObject to work around minor bugs in Chrome and Edge.
      $('#remoteVideo').prop('srcObject', null);
      $('#remoteVideo').prop('srcObject', stream);
      if (_this.isCaller) {
        $('#remoteVideo_caller').prop('srcObject', null);
        $('#remoteVideo_caller').prop('srcObject', stream);
      } else {
        $('#remoteVideo_callee').prop('srcObject', null);
        $('#remoteVideo_callee').prop('srcObject', stream);
      }
    });

    call1.on('signalingstate', function (state) {
      _this.connected = false;

      switch (state.code) {
        case 3:
          _this.connected = true;
          _this.countTime();
          break;
        case 5:
          _this.hideCallPopup();
          break;
        case 6:
          _this.hideCallPopup();
          if (state.sipCode === 480) {
            _this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại' });
          }
          break;
      }
    });

    call1.on('mediastate', function (state) {
        _this.connected = state.code === 1;
    });

    call1.on('info', function (info) {
    });

    call1.on('error', function (info) {
      _this.call = null;
      _this.hideCallPopup();
      _this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: 'Thiết bị của bạn không hỗ trợ cuộc gọi. Vui lòng kiểm tra lại.' });
    });

    call1.on('otherdevice', function (data) {

      if ((data.type === 'CALL_STATE' && data.code >= 200) || data.type === 'CALL_END') {
        _this.hideCallPopup();
      }
    });
  }

  getAgent(agentId: string): any {
    const url = ConstantUrl.url_communication_query;
    return this.http.get(url + 'communication/pub/agent?agentId=' + agentId);
  }

  routeCall(fromUserId: string, toNumber: string) {
    const url = ConstantUrl.url_communication_query;
    const body = {
      fromUserId,
      toNumber
    };
    return this.http.post(url + 'communication/routeCall', body);
  }

  countTime() {
    this.time = 0;

    $('.call').addClass('is-answer');
    $('#callWaitingPlayer').trigger('pause');
    this.interval = setInterval(() => {
      this.time++;
      $('.count-time').text(convertTime(this.time));
    }, 1000);
  }

  hideCallPopup() {
    if (this.callHistory) {
      this.callHistory.endCall = new Date();
      this.callHistory.answerTime = this.time;
      this.hangupCallback.next(this.callHistory);
    }
    this.time = 0;
    this.isCalling.next(false);
    VoiceCallService.isInCalling = false;
    clearInterval(this.interval);
    $('#call-out').hide();
    $('#incomming-call').hide();
    $('.count-time').text('00:00');
    $('.call').removeClass('is-answer');
    $('#ringtonePlayer').trigger('pause');
    $('#callWaitingPlayer').trigger('pause');
    this.connected = false;
  }
  resetCallHistory() {
    this.callHistory = new CallHistory();
  }

  setVoice(state: boolean) {
    if (this.localStream) {
      const audio = this.localStream.getTracks().find(e => e.kind === 'audio');
      if (audio) {
        audio.enabled = state;
      }
    }
  }

  setVideo(state: boolean) {
    if (this.localStream) {
      const video = this.localStream.getTracks().find(e => e.kind === 'video');
      if (video) {
        video.enabled = state;
      }
    }
  }
}
