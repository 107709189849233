import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ConfirmPopup } from 'app/shared/components/confirm-popup/confirm-popup';
import { MatDialog } from '@angular/material';

@Injectable()
export class CommonService {

    constructor(
      private dialog: MatDialog
    ){
    }

    public disabledToggle(component: any) {
        component.disabled = !component.disabled;
    }

    public noWhitespaceValidator(control: FormControl) {
        if (control && (control.value === null || (control.value && control.value.length === 0))) {
            return null;
        } else {
            const isWhitespace = (control.value || '').trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : { 'whitespace': true };
        }
    }
    public getAmountByDemandCategory(category) {
      const amountInBil = parseInt(category.split(' ')[0]);
      if (amountInBil > 0) {
        return amountInBil * 1000000;
      } else {
        return 0;
      }
    }

    public showNonProductionNotice() {
      if (environment.productionMode) { return; }
      const dialogRef = this.dialog.open(ConfirmPopup, {
        width: '650px',
        data: {
          title: 'Website Demo!!!',
          isReason: false,
          message: 'Dữ liệu thao tác tại đây sẽ không được ghi nhận trên hệ thống chính thức.',
          textCancel: 'Ra khỏi đây',
          textOk: 'Tiếp tục sử dụng',
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.execute) {
          return false;
        } else {
          window.location.href = 'https://realagent.vn';
        }
      });
    }
}
