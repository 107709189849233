import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface EditorData {
  title: string;
  message: string;
  textCancel: string;
  textOk: string;
  body: any;
  execute: boolean;
  isReadOnly: boolean;
}

@Component({
  selector: 'textarea-popup',
  templateUrl: 'textarea-popup.html',
})
export class TextareaPopup implements OnInit {
  htmlContent = '';
  constructor(
    public dialogRef: MatDialogRef<TextareaPopup>,
    @Inject(MAT_DIALOG_DATA) public data: EditorData) { }

  ngOnInit() {
    this.htmlContent = this.data.body;
  }
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    this.data.execute = true;
    this.data.body = this.htmlContent;
    this.dialogRef.close(this.data);
  }
}
