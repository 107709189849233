import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-management',
  templateUrl: './request-management.component.html',
  styleUrls: ['./request-management.component.scss']
})
export class RequestManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
