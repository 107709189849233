import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {HttpService} from './http.service';
import {ConstantUrl} from '../constant/url';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  ConstantUrl = ConstantUrl;
  domainUrl: string;
  queryUrl: string;
  searchQuery: string;

  constructor(
    private service: DataService,
    private httpService: HttpService
  ) {
    this.domainUrl = ConstantUrl.url_employee_domain + 'employee/pub';
    this.queryUrl = ConstantUrl.url_employee_query + 'employee/pub';
    this.searchQuery = ConstantUrl.url_employee_query + 'employee';
  }

  getNearestEmployee(params) {
    const url = ConstantUrl.url_employee_query + 'employee/pub/by-public-pos';
    return this.service.get(url, params);
  }

  getNearestEmployeeById(id) {
    const url = `${this.queryUrl}/by-public-pos/${id}`
    return this.service.get(url)
  }

  getPos() {
    const url = `${ConstantUrl.url_orgchart_query}orgchart/pub/public-pos`
    return this.service.get(url)
  }

  getRole() {
    const url = `${ConstantUrl.url_sts_query}role/pub/public-role`
    return this.service.get(url)
  }

  getEmployeeBql(){
    const url = `${ConstantUrl.url_employee_query}employee/employee-bql`
    return this.service.get(url)
  }
}
