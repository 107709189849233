import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {MessageService} from '../message.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {EmployeeService} from '../../employee';
import {StorageService} from '../../../shared/services';
import {ConfirmPopup} from '../../../shared/components/confirm-popup/confirm-popup';
import {StringeeService} from '../stringee.service';
const CUSTOMER_PREFIX = 'cus_';

export interface GroupPopupData {
  title: string;
  action: string;
  execute: boolean;
  isEdit: boolean;
  query: string;
  conversations: any;
  currentUserId: string;
  selectedUser: any;
  currentConversation: any;
  stringeeService: any;
}

@Component({
  selector: 'group-popup',
  styleUrls: ['./group-popup.scss'],
  templateUrl: 'group-popup.html',
  providers: [MessageService, EmployeeService, StringeeService]
})
export class GroupPopup implements OnInit, OnDestroy {
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  debounceTime = 500;
  employees = [];
  isLoading = false;
  userId = '';
  existUsers = [];
  existStringeeUsers = [];
  @ViewChild('groupid') groupid: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<GroupPopup>,
    @Inject(MAT_DIALOG_DATA) public data: GroupPopupData,
    public service: MessageService,
    private employeeService: EmployeeService,
    private storageService: StorageService,
    private dialog: MatDialog,
    // private stringeeService: StringeeService
    ) {}

  ngOnInit() {
    // this.stringeeService.connectStringee();
    this.userId = this.storageService.retrieve('userId');
    this.existUsers = [];
    this.existStringeeUsers = [];
    // $.each(this.data.conversations, (idx, conv) => {
    //   this.data.conversations[idx].checked = false;
    // });
    this.subscription = this.modelChanged
      .pipe(
        debounceTime(this.debounceTime),
      )
      .subscribe(() => {
        this.search();
      });
    if (this.data.currentConversation && this.data.currentConversation.isGroup === true) {
      $.each(this.data.currentConversation.participants, (ind, part) => {
        this.existStringeeUsers.push(part.userId);
        if (this.existUsers.indexOf(part.userId) === -1) this.existUsers.push(part.userId);
        const info = this.getParticipantInfo(part);
        this.data.currentConversation.participants[ind].name = info.name;
        this.data.currentConversation.participants[ind].avatar = info.avatar;
        this.data.currentConversation.participants[ind].isCustomer = info.isCustomer;
      });
    }
    if (this.data.conversations && this.data.conversations.length > 0) {
      this.data.conversations = this.data.conversations.map(conv => {
        const info = this.getConversationInfo(conv);
        conv.name = info.name;
        conv.avatar = info.avatar;
        conv.isCustomer = info.isCustomer;
        return conv;
      });
    }
    // this.currentUserId = this.storageService.retrieve('email').replace('@', '.');
  }

  getEmployeeList() {
    this.employeeService.getEmployeeListByNameOrEmail({ key : this.data.query }).subscribe(res => {
      this.employees = res;
      this.checkExist();
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }

  execute() {
    this.data.execute = true;
    this.data.action = this.data.isEdit ? 'edit' : '';
    this.dialogRef.close(this.data);
  }

  inputChanged() {
    this.isLoading = true;
    this.modelChanged.next();
  }

  search() {
    // if (this.data.query === '') this.conversationPerPage = this.defaultConversationPerPage;
    this.getEmployeeList();
  }

  checkExist() {
    for (let i = 0; i < this.data.conversations.length; i++) {
      if (this.data.conversations[i].isGroup === false) {
        const name = this.data.conversations[i].name;
        if (this.existUsers.indexOf(name) === -1) this.existUsers.push(name);
      }
    }
    this.existUsers = [...this.existUsers, this.data.currentUserId];
  }

  getEmployeeEmail(emp) {
    return emp.email.replace('@', '.');
  }

  getCheckboxValues(ev, id, checked, conv) {
    let userId = conv.participants[0].userId == this.data.currentUserId ? conv.participants[1].userId : conv.participants[0].userId;
    let user = {
      'id': userId
    };

    if (checked) {
      // Pushing the object into array
      this.data.selectedUser.push(user.id);

    } else {
      let removeIndex = this.data.selectedUser.findIndex(itm => itm === user.id);

      if (removeIndex !== -1) {
        this.data.selectedUser.splice(removeIndex, 1);
      }
    }
  }

  changeSelectedUser(ev, id, checked) {

    if (checked) {
      // Pushing the object into array
      this.data.selectedUser.push(id);

    } else {
      let removeIndex = this.data.selectedUser.findIndex(itm => itm === id);

      if (removeIndex !== -1) {
        this.data.selectedUser.splice(removeIndex, 1);
      }
    }
  }

  removeParticipant(part) {
    // show popup
    const removeConfirm = this.dialog.open(ConfirmPopup, {
      width: '450px',
      data: {
        title: 'Bạn chắc chắn muốn xóa người này khỏi nhóm?',
        isReason: false,
        textCancel: 'Hủy',
        textOk: 'Xác nhận',
      }
    });
    removeConfirm.afterClosed().subscribe(result => {
      if (typeof result === 'undefined') {
        return;
      }
      if (result.execute) {
        this.data.stringeeService.removeParticipants(this.data.currentConversation.id, [part.userId]);
        this.removePart(part);
      }
    });
  }

  removePart(part) {
    let removeIndex = this.data.currentConversation.participants.findIndex(itm => itm.userId === part.userId);

    if (removeIndex !== -1) {
      this.data.currentConversation.participants.splice(removeIndex, 1);
    }
  }

  addParticipant() {
    // close current popup
    this.data.execute = true;
    this.data.action = 'add';
    this.dialogRef.close(this.data);
  }

  leaveGroup() {
    // show popup
    const leaveConfirm = this.dialog.open(ConfirmPopup, {
      width: '450px',
      data: {
        title: 'Bạn chắc chắn muốn rời nhóm?',
        isReason: false,
        textCancel: 'Hủy',
        textOk: 'Xác nhận',
      }
    });
    leaveConfirm.afterClosed().subscribe(result => {
      if (typeof result === 'undefined') {
        return;
      }
      if (result.execute) {
        this.data.execute = true;
        this.data.action = 'leave';
        this.dialogRef.close(this.data);
      }
    });
  }

  deleteGroup() {
    // show popup
    const deleteConfirm = this.dialog.open(ConfirmPopup, {
      width: '450px',
      data: {
        title: 'Bạn chắc chắn muốn xóa nhóm ?',
        isReason: false,
        textCancel: 'Hủy',
        textOk: 'Xác nhận',
      }
    });
    deleteConfirm.afterClosed().subscribe(result => {
      if (typeof result === 'undefined') {
        return;
      }
      if (result.execute) {
        this.data.execute = true;
        this.data.action = 'delete';
        this.dialogRef.close(this.data);
      }
    });
  }

  getConversationInfo(conversation) {
    if (conversation.isGroup) {
      return {
        name: conversation.name,
        avatar: './assets/img/users/icon_avatar_group.png'
      };
    } else {
      const sender = conversation.participants[0].userId !== this.data.currentUserId ? conversation.participants[0] : conversation.participants[1];
      const replacedUserId = this.replaceStringeeCustomerName(sender.userId);
      const avatar = (sender.avatar && sender.avatar !== 'null') ? sender.avatar : (parseInt(replacedUserId) > -1 ? './assets/img/users/icon_avatar_customer.png' : './assets/img/users/icon_avatar.png');
      return {
        name: sender.name || replacedUserId,
        avatar: avatar,
        isCustomer: sender.userId.indexOf(CUSTOMER_PREFIX) > -1 ? true : false
      };
    }
  }

  getParticipantInfo(part) {
    const replacedUserId = this.replaceStringeeCustomerName(part.userId);
    const avatar = (part.avatar && part.avatar !== 'null') ? part.avatar : (parseInt(replacedUserId) > -1 ? './assets/img/users/icon_avatar_customer.png' : './assets/img/users/icon_avatar.png');
    return {
      name: part.name || replacedUserId,
      avatar: avatar,
      isCustomer: part.userId.indexOf(CUSTOMER_PREFIX) > -1 ? true : false
    };
  }

  replaceStringeeCustomerName(name: string) {
    return name.replace(CUSTOMER_PREFIX, '');
  }

  copy() {
    this.groupid.nativeElement.select();
    document.execCommand('copy');
  }
}
