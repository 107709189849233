import { Injector, Injectable } from '@angular/core';
import { } from './base.component';
import {
    Message, StompService, ToastrService
} from '../services/common';
import { Observable, Subscription } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { EventChangeService } from '../services/event.change.service';
import { StorageService } from '../services';

@Injectable()
export class WsNotifyService {
    Entity: any;
    route: any;
    router: any;
    tableProperty: any;
    settings: any;
    active: Boolean;
    // stomp
    // Subscription status
    public subscribed: boolean;
    // Stream of messages
    public subscriptions: Subscription[] = [];
    public messages: Observable<Message>;
    public listMessages: Observable<Message>[] = [];
    // messaging pattern
    public static messagingPattern = '/exchange/datxanhres-online-to-offline-2019-msX.Exchange.';
    
    constructor(
        public stompService: StompService,
        public toastrService: ToastrService,
        public websocketService: WebsocketService,
        public eventChangeService: EventChangeService,
        public storageService: StorageService
    ) {
    }
    public subscribe(infoMessagingPattern: any) {
        // Stream of messages
        // info
        if (typeof infoMessagingPattern != 'object') {
            this.createSubscribeForPattern(infoMessagingPattern);
        } else {
            infoMessagingPattern.forEach((infoMessagingPatternItem) => {
                this.createSubscribeForPattern(infoMessagingPatternItem);
            })
        }
    }
    public createSubscribeForPattern(infoMessagingPattern) {
        if (infoMessagingPattern == WsNotifyService.messagingPattern) {
            return;
        }
        if (this.websocketService.getChannel(infoMessagingPattern)) {
            return;
        }
        let message = this.stompService.subscribe(infoMessagingPattern);
        let subscription = message.subscribe(this.infoSubscribe.bind(this));
        this.listMessages.push(message);
        this.websocketService.setChannel(infoMessagingPattern, subscription);
        this.subscriptions.push(subscription);
    }
    public unsubscribe() {
        if (!this.subscribed) {
            return;
        }
        // This will internally unsubscribe from Stomp Broker
        // There are two subscriptions - one created explicitly, the other created in the template by use of 'async'
        this.messages = null;
        this.subscribed = false;
    }
    /** Consume a message from the stompService */
    public infoSubscribe(message: Message) {
        if (message && message.body) {
            let object = JSON.parse(message.body);
            if (!object.eventName) {
                return;
            }
            if (object.actionName.replace(',','') != this.storageService.retrieve('url') + this.storageService.retrieve('timestamp')) {
                // exeception
                // if (object.eventName == 'contractSentApprove') {
                    this.eventChangeService.emitChange({
                        broadcast: object.eventName,
                        message: object
                    });
                // }
                return;
            }
            this.eventChangeService.emitChange({
                type: 'notification',
                msg: {
                    title: object.eventName,
                    content: object.eventName
                }
            });
            this.eventChangeService.emitChange({
                broadcast: object.eventName,
                oneTimes: true,
                message: object
            });
            if (object.belongTo) {
                this.eventChangeService.emitChange({
                    broadcast: object.belongTo,
                    message: object
                });
            }
            if (object.eventName.toLowerCase().includes('delete')) {
                return this.executeDelete();
            }
        }
    }
    /** Consume a message from the stompService */
    public errorSubscribe(message: Message) {
        if (message && message.body) {
            const error = JSON.parse(message.body);
            if (!error.code) {
                console.log('errorSubscribe', error);
            }
            // if (!error.code) {
            //     this.toastrService.error('Error!', error.code);
            // } else this.router.navigate([this.Entity.getType().toLowerCase()]);
        }
    }
    public executeDelete () {

    }
    public successNotice(text) {
        this.toastrService.success('Thành Công!', text);
    }

    public errorNotice(text) {
        this.toastrService.error('Lỗi!', text);
    }

    unsubscribeAll() {
        this.subscriptions.forEach(element => !element.closed && element.unsubscribe() );
        this.websocketService.cleanChannel();
        this.messages = null;
        this.listMessages = [];
        this.subscribed = false;
    }
}
