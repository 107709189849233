import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Injector } from "@angular/core";
import { BaseComponent } from "app/shared/components/base.component";
import moment = require("moment");
import { UserTrialService } from "../user-trial.service";
import { UserTrialModel } from "../user-trial.model";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { markAsTouched } from "../../../shared/utility/form-utility";
import {UpgradeTrialConstant} from "../../../shared/constant/userTrialAccount.constant";


@Component({
    selector: 'app-upgrade-account-trial',
    templateUrl: './upgrade-account-trial.component.html',
    styleUrls: ['./upgrade-account-trial.component.scss']
})

export class UpgradeAccountTrial extends BaseComponent implements OnInit {

    mainForm: FormGroup = null;
    startDateUpgrade = '';
    constructor(
        injector: Injector,
        public _service: UserTrialService,
        public dialogRef: MatDialogRef<UpgradeAccountTrial>,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(injector.get(UserTrialService), UserTrialModel, injector)
    }
    public upgradeTrialConstant = UpgradeTrialConstant    
    ngOnInit() {
        this.initForm()
        this.getInfoAccount(this.data.id);
    } 

    initForm() {
        this.mainForm = this.formBuilder.group({
            upgradePackage: [null, Validators.required],
            upgradePeriod: [null, Validators.required],
            startDateUpgrade: [null],
        },
        {
            validator: (controls: AbstractControl) => {
              const startDateUpgradeCtrls = controls.get("startDateUpgrade");
              startDateUpgradeCtrls.setErrors(null);
              if(!startDateUpgradeCtrls.value) {
                startDateUpgradeCtrls.setErrors({required: true})
              }
              if (`${startDateUpgradeCtrls.value}`) {  
                if (moment(`${startDateUpgradeCtrls.value}`).isBefore(moment().startOf('day'))) {
                    // Check trường hợp ngày bắt đầu nhỏ hơn ngày hiện tại
                    startDateUpgradeCtrls.setErrors({ invalidToDate: true });
                }
            }
            }
          })
    }

    getInfoAccount(id) {
        this._service.getCustomerRegisterById(id).subscribe(res=> {
            if(res) {
                if (res.endDateUpgrade) {
                    this.patchValue(res);
                }
            }
        })
    }

    patchValue(param) {
        this.mainForm.patchValue({
            startDateUpgrade: param.endDateUpgrade
        })
    }

    onSave() {
        markAsTouched(this.mainForm);
        if (!this.mainForm.valid) {
            this.toastr.error("Vui lòng kiểm tra lại thông tin");
            return;
        }
        let body = this.mainForm.getRawValue()
        body.upgradePeriod = body.upgradePeriod.toString()
        body.id = this.data.id
    
        this._service.upgradeAccount(body).subscribe(res => {
            if(res) {
                this.toastrService.success('Thành công', 'Nâng cấp tài khoản')
                this.dialogRef.close(res)
            }
        })
    }

    close() {
        this.dialogRef.close()
    }
}