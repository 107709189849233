import { IsNotEmpty, Length } from 'class-validator';
import { Constant } from 'app/shared/constant/constant';

export class Address {
  country: string;

  @Length(1, 20)
  @IsNotEmpty()
  province: string;

  @Length(1, 20)
  @IsNotEmpty()
  district: string;

  @Length(1, 20)
  @IsNotEmpty()
  ward: string;

  @Length(1, 20)
  @IsNotEmpty()
  address: string;

  fullAddress: string;

  constructor(object?: any) {
    if (object) {
      this.country = object.country || 'Việt Nam';
      this.province = object.province || null;
      this.district = object.district || null;
      this.ward = object.ward || null;
      this.address = object.address || null;
      this.fullAddress = object.fullAddress || this.getFullAddress(object);
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    this.country = 'Việt Nam';
    this.province = null;
    this.district = null;
    this.ward = null;
    this.address = null;
  }

  validate() {
    let errors: any = {};
    if (!this.province) {
      errors.province = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    if (!this.district) {
      errors.district = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    if (!this.ward) {
      errors.ward = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    if (!this.address) {
      errors.address = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    return errors;
  }

  createObjectRequestAPI() {
    let object: any = {};
    object.country = this.country || 'Việt Nam';
    object.province = this.province;
    object.district = this.district;
    object.ward = this.ward;
    object.address = this.address;
    object.fullAddress = this.fullAddress;
    return object;
  }

  static getType() {
    return 'customer';
  }

  getFullAddress(object?): string {
    let result = '';
    const dashSpace = ', ';
    object.address ? result += `${object.address}` : result += '';
    object.ward ? result += `${dashSpace + object.ward}` : result += '';
    object.district ? result += `${dashSpace + object.district}` : result += '';
    object.province ? result += `${dashSpace + object.province}` : result += '';
    object.country ? result += `${dashSpace + object.country}` : result += '';
    return result.indexOf(',') == 0 ? result.substring(1) : result;
  }
}
