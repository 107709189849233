import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';

@Injectable()
export class CategoryService extends BaseService {
    ConstantUrl = ConstantUrl;
    constructor(
        public service: DataService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_category_domain + 'category';
        this.queryUrl = ConstantUrl.url_category_query + 'category';
    }
}
