import { DateToFormat } from '../../../shared/parse/date-to-format';

export class ContractReport {
    projectName: string;
    productCode: string;
    contractName: string;
    cusName: string;
    loaiDoanhThu: string; // nhu cầu bán/cho thuê
    employeeCode: string;
    employeeName: string;
    roleName: string;
    ttgdName: string;
    sanName: string;
    doanhThuDatDuocPercent: number; // doanh thu đạt được
    tyLeChiaPercent: number; // 
    doanhThu: any;
    nguonTinhHoaHong: string;
    tyLeHoaHongPercent: number;
    tienLuongNangSuat: any;
    thuNhapCoDinh: any;
    hoTroChiPhi: any;
    tongThuNhapPhaiTra: any;

    constructor(
        param?: any
    ) {
      this.setValue(param);
    }

    setValue(params: any) {
      if (params && params._id) {
        this.projectName = params.contract.propertyUnit && params.contract.propertyUnit.project ? params.contract.propertyUnit.project.name : '';
        this.productCode = params.contract.propertyUnit.code;
        this.contractName = params.contract.name;
        const customer = params.isSale ? params.demand.customer : params.contract.mainCustomer;
        this.cusName = customer && customer.personalInfo ? `${customer.personalInfo.name} ${customer.personalInfo.lastName}` : ''; 
        const type = params.isSale && params.isSource ? (params.demand.type == 'BUY' ? 'Mua' : 'Thuê') + ', ' + (params.contract.propertyUnit.transactionType == 'SELL' ? 'Bán': 'Cho thuê')
              : params.isSale ? (params.demand.type == 'BUY' ? 'Mua' : 'Thuê') : (params.contract.propertyUnit.transactionType == 'SELL' ? 'Bán': 'Cho thuê');
        this.loaiDoanhThu = type;
        this.employeeCode = params.employee.code;
        this.employeeName = params.employee.name;
        this.roleName = params.employee.managerAt ?' Quản lý': 'Nhân viên';
        this.ttgdName = params.employee.pos.name;
        this.sanName = params.san.name;
        this.doanhThuDatDuocPercent = 1;
        this.tyLeChiaPercent = 1;
        this.doanhThu = params.revenue;
        this.nguonTinhHoaHong = (params.isSource ? 'Bên ký gửi' : '') + ' ' + (params.isSale ? 'Bên môi giới' : '');
        this.tyLeHoaHongPercent = (params.commissionRate || 0)/100;
        this.tienLuongNangSuat = params.salaryProductivity;
        this.thuNhapCoDinh = 0;
        this.hoTroChiPhi = 0;
        this.tongThuNhapPhaiTra = params.totalIncome;
      } else if (params) {
        this.doanhThu = params.revenue;
        this.tienLuongNangSuat = params.salaryProductivity;
        this.tongThuNhapPhaiTra = params.totalIncome;
      }
    }

  getHeaders() {
    return [
      { header: 'Dự án', key: 'projectName', width: 20 },
      { header: 'Sản phẩm', key: 'productCode', width: 20 },
      { header: 'Hợp đồng môi giới', key: 'contractName', width: 20 },
      { header: 'Họ và Tên Khách hàng', key: 'cusName', width: 20 },
      { header: 'Loại doanh thu', key: 'loaiDoanhThu', width: 20 },
      { header: 'TVV/CTV', key: 'employeeCode', width: 20 },
      { header: 'Họ và tên nhân viên', key: 'employeeName', width: 20 },
      { header: 'Chức vụ', key: 'roleName', width: 20 },
      { header: 'TTGD', key: 'ttgdName', width: 20 },
      { header: 'Sàn', key: 'sanName', width: 20 },
      { header: '% DT đạt được', key: 'doanhThuDatDuocPercent', width: 20, style: { numFmt: '0%' } },
      { header: '% TY LE CHIA', key: 'tyLeChiaPercent', width: 20, style: { numFmt: '0%' } },
      { header: 'Doanh thu', key: 'doanhThu', width: 30, style: { numFmt: '#,##0' }},
      { header: 'Nguồn tính hoa hồng', key: 'nguonTinhHoaHong', width: 20 },
      { header: 'Tỷ lệ hoa hồng', key: 'tyLeHoaHongPercent', width: 20, style: { numFmt: '0%' } },
      { header: 'Tiền lương năng suất', key: 'tienLuongNangSuat', width: 30, style: { numFmt: '#,##0' } },
      { header: 'TN cố định/thưởng nóng/thưởng thêm', key: 'thuNhapCoDinh', width: 20, style: { numFmt: '#,##0' } },
      { header: 'Hỗ trợ chi phí xăng xe, tiếp khách', key: 'hoTroChiPhi', width: 20, style: { numFmt: '#,##0' } },
      { header: 'Tổng TN phải trả', key: 'tongThuNhapPhaiTra', width: 30, style: { numFmt: '#,##0' } },
    ];
  }

  transformLoginList(list) {
    return list.map(ele => this.parseDate(ele.commitStamp, 'dd/MM/yyyy - hh:mm:ss')).join('\r\n');
  }
  parseDate(date, format) {
    return DateToFormat.parseDate(date, format);
  }
}
