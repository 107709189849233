import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "app/shared/services/common";

export interface NoticationPreviewData {
    title: string;
    message: string;
    textCancel: string;
    textOk: string;
    isReason: boolean;
    isTextInput: boolean;
    isCancelTicket: boolean;
    isRequireReason: boolean;
    checkBoxResult: boolean;
    isCheckBoxOtp: boolean;
    isCheckBox: boolean;
    bindCbTxtToReason: boolean;
    placeholderReason: string;
    reason: string;
    bankInfo: any;
    checkBoxText: string;
    radioSelect: string;
    listRadio: any[];
    contentPadding: 0;
    textInputLabel: string;
    textInputValue: string;
    execute: boolean;
    contentSMS:string;
    contentMail:string;
    contentNoti:string;
    notiTitle:string;
}
@Component({
    selector: "app-notication-preview-popup",
    templateUrl: "./notication-preview-popup.component.html",
    styleUrls: ["./notication-preview-popup.component.scss"],
})
export class NoticationPreviewPopupComponent implements OnInit {
    isShowSMSPreview:boolean = false;
    isShowMailPreview:boolean = false;
    isShowNotiPreview:boolean = false;
    constructor(
        injector: Injector,
        public toastr: ToastrService,
        public dialogRef: MatDialogRef<NoticationPreviewPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NoticationPreviewData
    ) {}

    ngOnInit() {
        if (!this.data.placeholderReason) {
            this.data.placeholderReason = "Ghi chú";
        }
        if(this.data.contentSMS){
            this.isShowSMSPreview = true
        }
        if(this.data.contentMail){
            this.isShowMailPreview = true
        }
        if(this.data.contentNoti){
            this.isShowNotiPreview = true
        }
    }
    onNoClick(): void {
        this.data.execute = false;
        this.dialogRef.close(this.data);
    }
    checkRequireReason() {
        if (typeof this.data.isRequireReason !== "undefined") {
            return this.data.isRequireReason && !this.data.reason;
        } else {
            return this.data.isReason && !this.data.reason;
        }
    }
    checkRequireRadio() {
        if (this.data.listRadio) {
            return !this.data.radioSelect;
        } else {
            return false;
        }
    }
}
