import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'email' })
export class TransformEmailPipe implements PipeTransform {
  transform(value: any) {
    return this.parseEmail(value);
  }
  parseEmail(value) {
    if (!value) {
      return '';
    }
    return '****' + value.slice(4);
  }
}
