import { ECallType, ECallStatus, ESignalingState } from '../enum/call.enum';

export class CallInfo {
    name: string;
    agentId: string;
    avatar: string;
    description: string;
    time: string;
    type: ECallType;
    status: ECallStatus;
    signalingState: ESignalingState;

    constructor(param?: any) {
        if (param) {
            this.setValue(param);
        }
        else {
            this.setNull();
        }
    }
    setNull() {
        this.name = '';
        this.agentId = '';
        this.avatar = './assets/images/v7/icon_avatar.svg';
        this.description = 'Nhân viên môi giới - Đất Xanh Services';
        this.time = '00:00';
        this.type = 0;
        this.status = 0;
        this.signalingState = 0;
    }
    setValue(param) {
        this.name = param.name || '';
        this.agentId = param.agentId || '';
        this.avatar = param.avatar || './assets/images/v7/icon_avatar.svg';
        this.description = param.description || 'Nhân viên môi giới - Đất Xanh Services';
        this.time = param.time || '00:00';
        this.type = param.type || 0;
        this.status = param.status || 0;
        this.signalingState = param.signalingState || 0;
    }
}

export class CallHistory {
    ticketId: string;
    callId: string;
    startCall: Date;
    endCall: Date;
    answerTime: number;

    constructor(param?: any) {
        if (param) {
            this.setValue(param);
        } else {
            this.setNull();
        }
    }
    setNull() {
        this.ticketId = '';
        this.callId = '';
        this.startCall = null;
        this.endCall = null;
        this.answerTime = 0;
    }
    setValue(param) {
        this.ticketId = param.ticketId || '';
        this.callId = param.callId || '';
        this.startCall = param.startCall || new Date();
        this.endCall = param.endCall || new Date();
        this.answerTime = param.answerTime || 0;
    }
}