import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RigisterTrialSuccessDialogComponent } from 'app/pages/login/rigister-trial-success-dialog/rigister-trial-success-dialog.component';
import { UpdateAccountComfirmPopupComponent } from '../update-account-comfirm-popup/update-account-comfirm-popup.component';

export interface UpdateAccountData {
  textCancel: string;
}
@Component({
  selector: 'app-update-account-popup',
  templateUrl: './update-account-popup.component.html',
  styleUrls: ['./update-account-popup.component.scss']
})
export class UpdateAccountPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RigisterTrialSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateAccountData,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
  updateAccountPopupComfirm(){
    this.dialogRef.close();
    this.dialog.open(UpdateAccountComfirmPopupComponent, {
      width: '500px',
      panelClass: 'register-trial-success-dialog',
      data: {
          isAddField: true,
          textCancel:"Đóng"
      }
  });
}
}
