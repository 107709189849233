import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constant } from 'app/shared/constant/constant';
import { CDocument } from 'app/api-models/c-document';
import { Property } from 'app/pages/property/property.model';
import { Project } from 'app/pages/project/project.model';
import { Address } from 'app/shared/models/address.model';
import { Category } from 'app/pages/category/category.model';
import { Images, ZoneImage, Image } from 'app/shared/components/upload-images/image.model';
import { DefineAttribute } from 'app/pages/define-attributes/define-attributes.model';
import { GroupsAttributes } from 'app/shared/components/groups-attribute/groups-attributes.model';
import { PropertyService } from 'app/pages/property/property.service';
import { FloorRoundFloatNumber } from 'app/shared/parse/floor-round-float-number';
import { MouseEvent } from '@agm/core';
import { MapsAPILoader } from '@agm/core';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import { MoneyToNumber } from 'app/shared/parse/money-to-number';
import { GeoLocation } from 'app/pages/property/location.model';
import { AttributeEnum } from 'app/pages/property-common/property-common.excel';
import { CEvalProperty } from 'app/api-models/c-evalProperty';
import { ToastrService } from 'app/shared/services/common';
import { ProjectService } from 'app/pages/project/project.service';
declare let google: any;
const VIET_NAM = 'Việt Nam';
const IMAGE_ZONE = {
  INDOOR: 'Trong nhà',
  OUTSITE: 'Trước nhà',
  HOUSE_3D: 'Image 3D',
  DOCUMENT: 'Sổ hồng'
};

@Component({
  selector: 'app-form-input-property',
  templateUrl: './form-input-property.component.html',
  styleUrls: ['./form-input-property.component.scss'],
  providers: [PropertyService]
})
export class FormInputPropertyComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() modeProperty: boolean = false;
  @Input() attributes: any[];
  @Input() attributesChange: EventEmitter<any[]> = new EventEmitter();
  @Input() data: Property = new Property();
  @Output() dataChange: EventEmitter<Property> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();
  private searchMapChange$: Subject<string> = new Subject();
  public Constant = Constant;
  public CONTROL_TYPES_OBJECT = {
    dropdown: 'dropdown',
    radio: 'radio',
    checkbox: 'checkbox',
    text: 'text',
    number: 'number',
    textarea: 'textare',  // Data cũ đã sai chính tả rồi
    slider: 'slider',
  };
  ALLOW_DINHGIA = [
    'c9eabf52-b21f-456b-a4e4-a4093502cb03'
  ]
  public GROUP_DINH_GIA = 'Thông Tin Định Giá';
  public mainForm: FormGroup = null;
  public groupsAttributes: GroupsAttributes = new GroupsAttributes();
  public listCategoryData: Category[] = [];
  public listProjectData: Project[] = [];
  public listCategory: SelectItemModel[] = [];
  public listProject: SelectItemModel[] = [];
  public listType: SelectItemModel[] = [
    { value: 'SELL', label: 'Bán' },
    { value: 'LEASE', label: 'Cho thuê' }
  ];
  public listDirection: SelectItemModel[] = [
    { value: 'BAT_KY', label: 'Bất kỳ' },
    { value: 'BAC', label: 'Bắc' },
    { value: 'DONG_BAC', label: 'Đông Bắc' },
    { value: 'TAY_BAC', label: 'Tây Bắc' },
    { value: 'DONG', label: 'Đông' },
    { value: 'DONG_NAM', label: 'Đông Nam' },
    { value: 'NAM', label: 'Nam' },
    { value: 'TAY_NAM', label: 'Tây Nam' },
    { value: 'TAY', label: 'Tây' }
  ];
  public listProvince: Province[] = JSON.parse(HANH_CHINH_VN);
  public listProvinceAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictAddress: District[] = [];
  public listWardAddress: string[] = [];
  public listEvalProvinces: any[] = [];
  public listEvalDistricts: any[] = [];
  public listEvalWards: any[] = [];
  public listEvalStreets: any[] = [];
  public listEvalProjects: any[] = [];
  public get fullAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('addressText').value ? result += `${this.mainForm.get('addressText').value}` : result += '';
    this.mainForm.get('addressWard').value ? result += `${dashSpace + this.mainForm.get('addressWard').value}` : result += '';
    this.mainForm.get('addressDistrict').value ? result += `${dashSpace + this.mainForm.get('addressDistrict').value}` : result += '';
    this.mainForm.get('addressProvince').value ? result += `${dashSpace + this.mainForm.get('addressProvince').value}` : result += '';
    this.mainForm.get('addressCountry').value ? result += `${dashSpace + this.mainForm.get('addressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }

  // Image
  public listImageIndoor: CDocument[] = [];
  public listImageOutside: CDocument[] = [];
  public listImageHouse: CDocument[] = [];
  public listImageDocument: CDocument[] = [];
  public typeSell: boolean = true;

  // Map init value
  geocoder: any;
  lat: number = 10.804933;
  lng: number = 106.721452;
  formattedAddress: string;
  zoom: number = 15;
  marker: any = {
    lat: this.lat,
    lng: this.lng,
    draggable: true
  };
  public cEvalProperty: CEvalProperty = new CEvalProperty();

  constructor(
    public formBuilder: FormBuilder,
    public service: PropertyService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public toastr: ToastrService,
    private projectService: ProjectService) {
    // Define search debounce
    this.searchMapChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe((input?: string) => this.searchMap(input));
  }

  ngOnInit() {
    const selectedCategoryId: string = this.data.category ? this.data.category.id : null;
    const selectedProjectId: string = this.data.project ? this.data.project.id : null;
    this.initForm(this.data);
    this.initImages();
    this.groupsAttributes = new GroupsAttributes({ attributes: this.attributes });
    this.getCategories(selectedCategoryId);
    this.searchProject({ term: '' });
  }
  getProvinces() {
    this.service.getProvinces().subscribe((res: any) => {
      this.listEvalProvinces = res.length > 0 ? res.map((item: any) => ({ key: item.value, value: item.label })) : [{ key: '', value: '' }];
      const group = this.getGroup(this.GROUP_DINH_GIA);
      group.attributes.forEach(attr => {
        if (attr.attributeId === AttributeEnum.PROVINCE) {
          attr.list = [...this.listEvalProvinces];
        }
      })
    });
  }

  searchProject(event) {
    return this.projectService.getProjectListByName(event.term)
      .subscribe((res: any) => {
        this.listProjectData = res.data;
        this.listProject = this.listProjectData.map((item: any) => ({ value: item.id, label: item.name }));
      });
  }

  onChangeProvince(id) {
    this.service.getDistricts(id).subscribe((res: any) => {
      this.listEvalDistricts = res && res.length > 0 ? res.map((item: any) => ({ key: item.value, value: item.label })) : [{ key: '', value: '' }];
      const group = this.getGroup(this.GROUP_DINH_GIA);
      group.attributes.forEach(attr => {
        if (attr.attributeId === AttributeEnum.DISTRICT) {
          attr.list = [...this.listEvalDistricts];
          attr.value = this.listEvalDistricts.some(function (item) { return item.key === attr.value }) ? attr.value : null;
          this.onChangeDistricts(attr.value || 0);
        }
      })
    });
  }
  onChangeDistricts(id) {
    this.service.getWards(id).subscribe((res: any) => {
      this.listEvalWards = res && res.length > 0 ? res.map((item: any) => ({ key: item.value, value: item.label })) : [{ key: '', value: '' }];
      const group = this.getGroup(this.GROUP_DINH_GIA);
      group.attributes.forEach(attr => {
        if (attr.attributeId === AttributeEnum.WARD) {
          attr.list = [...this.listEvalWards];
          attr.value = this.listEvalWards.some(function (item) { return item.key === attr.value }) ? attr.value : null;
        }
      })
      // this.groupsAttributes = new GroupsAttributes({ attributes: this.groupsAttributes.attributes });
    });
    this.service.getStreets(id).subscribe((res: any) => {
      this.listEvalStreets = res && res.length > 0 ? res.map((item: any) => ({ key: item.value, value: item.label })) : [{ key: '', value: '' }];
      const group = this.getGroup(this.GROUP_DINH_GIA);
      group.attributes.forEach(attr => {
        if (attr.attributeId === AttributeEnum.STREET) {
          attr.list = [...this.listEvalStreets];
          attr.value = this.listEvalStreets.some(function (item) { return item.key === attr.value }) ? attr.value : null;
        }
      })
    });

    this.service.getProjects(id).subscribe((res: any) => {
      this.listEvalProjects = res && res.length > 0 ? res.map((item: any) => ({ key: item.id, value: item.name })) : [{ key: '', value: '' }];
      const group = this.getGroup(this.GROUP_DINH_GIA);
      group.attributes.forEach(attr => {
        if (attr.attributeId === AttributeEnum.PROJECT) {
          attr.list = [...this.listEvalProjects];
          attr.value = this.listEvalProjects.some(function (item) { return item.key === attr.value }) ? attr.value : null;
        }
      })
    });
  }
  getGroup(name) {
    return this.groupsAttributes.groups.find(g => g.groupName === name)
  }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnChanges(value: any) {
    const selectedCategoryId: string = this.data.category ? this.data.category.id : null;
    const selectedProjectId: string = this.data.project ? this.data.project.id : null;
    this.initForm(this.data);
    this.initImages();
    // this.groupsAttributes.setValue({ attributes: this.data.attributes });  // Vì lý do nào đó mà angular không tracking được binding array bên trong binding object?
    this.groupsAttributes = new GroupsAttributes({ attributes: this.attributes });
    this.getCategories(selectedCategoryId);
    this.getProject(selectedProjectId);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(property: Property) {
    if (!property) {
      return;
    }
    let categoryId = (property.category && property.category.id) ? property.category.id : null;
    let projectId = (property.project && property.project.id) ? property.project.id : null;
    const addressItem: string[] = property.address ? property.address.split(',') : [];
    let address: Address = new Address({
      country: VIET_NAM,
      province: property.province || null,
      district: property.district || null,
      ward: property.ward || null,
      // address: property.address || null
      address: addressItem.length >= 5 ? addressItem[0] : null  // Chỉ gán addressItem[0] (street) khi có đầy đủ thông tin địa chỉ (country/province/district/ward/street)
    });
    this.loadAddressOptions(address);
    const videos: any[] = (property.images && property.images.videos) ? property.images.videos : [];
    const videoUrl: string = (videos && videos[0]) ? videos[0].url : null;
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        // Thông tin khảo sát chung
        type: property.transactionType,
        category: categoryId,
        price: property.price,
        area: property.area,
        direction: property.direction,
        project: projectId,
        isHot: property.isHot,
        addressCountry: address.country,
        addressProvince: address.province,
        addressDistrict: address.district,
        addressWard: address.ward,
        addressText: address.address,
        description: property.description,
        videoUrl: videoUrl,
        fakeAddress: property.fakeAddress,
        priceAbove: property.priceAbove ? property.priceAbove : '',
        priceEval: property.priceEval ? property.priceEval : '',
        linkListing1: property.linkListing[0] ? property.linkListing[0] : '',
        linkListing2: property.linkListing[1] ? property.linkListing[1] : '',
        linkListing3: property.linkListing[2] ? property.linkListing[2] : '',
        linkListing4: property.linkListing[3] ? property.linkListing[3] : '',
        linkListing5: property.linkListing[4] ? property.linkListing[4] : '',
        linkListing6: property.linkListing[5] ? property.linkListing[5] : '',
        linkListing7: property.linkListing[6] ? property.linkListing[6] : '',
        linkListing8: property.linkListing[7] ? property.linkListing[7] : '',
        linkListing9: property.linkListing[8] ? property.linkListing[8] : '',
        linkListing10: property.linkListing[9] ? property.linkListing[9] : '',
      });
      // Update input disable/enable state
      if (this.disabled) {
        this.mainForm.disable();
      } else {
        this.mainForm.enable();
        this.mainForm.get('addressCountry').disable();
      }
    } else {
      // Init form
      this.mainForm = this.formBuilder.group({
        // Thông tin khảo sát chung
        type: [{ value: property.transactionType, disabled: this.disabled }, Validators.required],
        category: [{ value: categoryId, disabled: this.disabled }, Validators.required],
        price: [{ value: property.price, disabled: this.disabled }, Validators.required],
        area: [{ value: property.area, disabled: this.disabled }, Validators.required],
        direction: [{ value: property.direction, disabled: this.disabled }],
        project: [{ value: projectId, disabled: this.disabled }],
        isHot: [{ value: property.isHot, disabled: this.disabled }],
        addressCountry: [{ value: address.country, disabled: true }],
        addressProvince: [{ value: address.province, disabled: this.disabled }, Validators.required],
        addressDistrict: [{ value: address.district, disabled: this.disabled }, Validators.required],
        addressWard: [{ value: address.ward, disabled: this.disabled }, Validators.required],
        addressText: [{ value: address.address, disabled: this.disabled }, Validators.required],
        description: [{ value: property.description, disabled: this.disabled }],
        videoUrl: [{ value: videoUrl, disabled: this.disabled }],
        fakeAddress: [{ value: property.fakeAddress, disabled: this.disabled }, Validators.required],
        priceAbove: [{ value: property.priceAbove, disable: this.disabled }],
        priceEval: [{ value: property.priceEval, disable: this.disabled }],
        linkListing1: [{ value: property.linkListing[0] ? property.linkListing[0] : '', disabled: this.disabled }],
        linkListing2: [{ value: property.linkListing[1] ? property.linkListing[1] : '', disabled: this.disabled }],
        linkListing3: [{ value: property.linkListing[2] ? property.linkListing[2] : '', disabled: this.disabled }],
        linkListing4: [{ value: property.linkListing[3] ? property.linkListing[3] : '', disabled: this.disabled }],
        linkListing5: [{ value: property.linkListing[4] ? property.linkListing[4] : '', disabled: this.disabled }],
        linkListing6: [{ value: property.linkListing[5] ? property.linkListing[5] : '', disabled: this.disabled }],
        linkListing7: [{ value: property.linkListing[6] ? property.linkListing[6] : '', disabled: this.disabled }],
        linkListing8: [{ value: property.linkListing[7] ? property.linkListing[7] : '', disabled: this.disabled }],
        linkListing9: [{ value: property.linkListing[8] ? property.linkListing[8] : '', disabled: this.disabled }],
        linkListing10: [{ value: property.linkListing[9] ? property.linkListing[9] : '', disabled: this.disabled }],
      });
      this.mainForm.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          // Patch data
          this.data = this.patchFormValue(this.data, this.mainForm.getRawValue(), this.groupsAttributes);
          // Emit new data
          this.dataChange.emit(this.data);
          // Emit form valid state
          this.validChange.emit(this.mainForm.valid);
        });
    }
    this.onSearchMap(property.fakeAddress);
  }

  // PUBLIC
  public validateForm(): boolean {
    this.markAsTouched(this.mainForm);
    return this.mainForm.disabled || this.mainForm.valid;
  }

  onAttributeChange(attribute?) {
    if (typeof attribute !== 'undefined' && attribute !== null) {
      if (attribute.attributeId === AttributeEnum.PROVINCE) {
        this.onChangeProvince(attribute.value || 0);
      }
      if (attribute.attributeId === AttributeEnum.DISTRICT) {
        this.onChangeDistricts(attribute.value || 0);
      }
    }

    // Emit new data
    const attributes = this.groupsAttributes.attributes;
    for (const group of this.groupsAttributes.groups) {
      const groupAttributesFlatData: any = group.attributes.reduce((obj: any, item: any) => {
        obj[item.attributeId] = item.value;
        return obj;
      }, {});
      attributes.forEach((attr: DefineAttribute) => attr.value = (groupAttributesFlatData[attr.attributeId] === null || typeof groupAttributesFlatData[attr.attributeId] === 'undefined') ? attr.value : groupAttributesFlatData[attr.attributeId]);
    }
    this.data.attributes = attributes;
    this.dataChange.emit(this.data);
    this.attributesChange.emit(this.groupsAttributes.attributes);
  }

  /** BEGIN - Xử lý Select Khu vực quan tâm */
  onChangeProvinceAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    this.mainForm.patchValue({
      addressDistrict: null,
      addressWard: null
    });
    this.listDistrictAddress = currentProvince.d;
    // Load map
    this.onSearchMap();
  }

  onChangeDistrictAddress(districtName: string) {
    const provinceName = this.mainForm.get('addressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      return;
    }
    this.mainForm.patchValue({
      addressWard: null
    });
    this.listWardAddress = currentDistrict.w;
    // Load map
    this.onSearchMap();
  }
  /** END - Xử lý Select Khu vực quan tâm */

  /** BEGIN - Xử lý ảnh */
  initImages() {
    const propertyImages: Images = this.data.images;
    if (propertyImages && propertyImages.zones && propertyImages.zones.length > 0) {
      // Ảnh trong nhà
      const imagesIndoor = propertyImages.zones.find((zone: ZoneImage) => zone.name === IMAGE_ZONE.INDOOR);
      this.listImageIndoor = imagesIndoor
        ? imagesIndoor.list.map((image: Image) => new CDocument({ originalName: image.name, uploadName: image.name, url: image.url }))
        : [];
      // Ảnh ngoài nhà
      const imagesOutside = propertyImages.zones.find((zone: ZoneImage) => zone.name === IMAGE_ZONE.OUTSITE);
      this.listImageOutside = imagesOutside
        ? imagesOutside.list.map((image: Image) => new CDocument({ originalName: image.name, uploadName: image.name, url: image.url }))
        : [];
      // Ảnh 3D
      const imagesHouse3d = propertyImages.zones.find((zone: ZoneImage) => zone.name === IMAGE_ZONE.HOUSE_3D);
      this.listImageHouse = imagesHouse3d
        ? imagesHouse3d.list.map((image: Image) => new CDocument({ originalName: image.name, uploadName: image.name, url: image.url }))
        : [];
      // Ảnh sổ hồng
      const imagesDocument = propertyImages.zones.find((zone: ZoneImage) => zone.name === IMAGE_ZONE.DOCUMENT);
      this.listImageDocument = imagesDocument
        ? imagesHouse3d.list.map((image: Image) => new CDocument({ originalName: image.name, uploadName: image.name, url: image.url }))
        : [];
    } else {
      const zones: ZoneImage[] = [
        new ZoneImage({ name: IMAGE_ZONE.INDOOR, list: [] }),
        new ZoneImage({ name: IMAGE_ZONE.OUTSITE, list: [] }),
        new ZoneImage({ name: IMAGE_ZONE.HOUSE_3D, list: [] }),
        new ZoneImage({ name: IMAGE_ZONE.DOCUMENT, list: [] })
      ];
      this.data.images = new Images({ zones: zones });
    }
    // Emit new data
    this.dataChange.emit(this.data);
  }

  onChangeImageIndoor(documents: CDocument[]) {
    const propertyImages: Images = this.data.images;
    if (propertyImages && propertyImages.zones) {
      // Ảnh trong nhà
      this.listImageIndoor = documents;
      const imagesIndoorIndex = propertyImages.zones.findIndex((zone: ZoneImage) => zone.name === IMAGE_ZONE.INDOOR);
      const newImages: ZoneImage = new ZoneImage({
        name: IMAGE_ZONE.INDOOR,
        nameChange: propertyImages.zones[imagesIndoorIndex].nameChange,
        list: documents.map((document: CDocument) => new Image({ name: document.originalName, url: document.url }))
      });
      if (imagesIndoorIndex > -1) {
        propertyImages.zones[imagesIndoorIndex] = newImages;
      } else {
        propertyImages.zones.push(newImages);
      }
    }
    // Emit new data
    this.data.images = propertyImages;
    this.dataChange.emit(this.data);
  }

  onChangeImageOutside(documents: CDocument[]) {
    const propertyImages: Images = this.data.images;
    if (propertyImages && propertyImages.zones) {
      // Ảnh ngoài nhà
      this.listImageOutside = documents;
      const imagesOutsideIndex = propertyImages.zones.findIndex((zone: ZoneImage) => zone.name === IMAGE_ZONE.OUTSITE);
      const newImages: ZoneImage = new ZoneImage({
        name: IMAGE_ZONE.OUTSITE,
        nameChange: propertyImages.zones[imagesOutsideIndex].nameChange,
        list: documents.map((document: CDocument) => new Image({ name: document.originalName, url: document.url }))
      });
      if (imagesOutsideIndex > -1) {
        propertyImages.zones[imagesOutsideIndex] = newImages;
      } else {
        propertyImages.zones.push(newImages);
      }
    }
    // Emit new data
    this.data.images = propertyImages;
    this.dataChange.emit(this.data);
  }

  onChangeImageHouse(documents: CDocument[]) {
    const propertyImages: Images = this.data.images;
    if (propertyImages && propertyImages.zones) {
      // Ảnh ngoài nhà
      this.listImageHouse = documents;
      const imagesHouseIndex = propertyImages.zones.findIndex((zone: ZoneImage) => zone.name === IMAGE_ZONE.HOUSE_3D);
      const newImages: ZoneImage = new ZoneImage({
        name: IMAGE_ZONE.HOUSE_3D,
        nameChange: propertyImages.zones[imagesHouseIndex].nameChange,
        list: documents.map((document: CDocument) => new Image({ name: document.originalName, url: document.url }))
      });
      if (imagesHouseIndex > -1) {
        propertyImages.zones[imagesHouseIndex] = newImages;
      } else {
        propertyImages.zones.push(newImages);
      }
    }
    // Emit new data
    this.data.images = propertyImages;
    this.dataChange.emit(this.data);
  }

  onChangeImageOther(documents: CDocument[]) {
    const propertyImages: Images = this.data.images;
    if (propertyImages && propertyImages.zones) {
      // Ảnh ngoài nhà
      this.listImageDocument = documents;
      const imagesDocumentIndex = propertyImages.zones.findIndex((zone: ZoneImage) => zone.name === IMAGE_ZONE.DOCUMENT);
      const newImages: ZoneImage = new ZoneImage({
        name: IMAGE_ZONE.DOCUMENT,
        nameChange: propertyImages.zones[imagesDocumentIndex].nameChange,
        list: documents.map((document: CDocument) => new Image({ name: document.originalName, url: document.url }))
      });
      if (imagesDocumentIndex > -1) {
        propertyImages.zones[imagesDocumentIndex] = newImages;
      } else {
        propertyImages.zones.push(newImages);
      }
    }
    // Emit new data
    this.data.images = propertyImages;
    this.dataChange.emit(this.data);
  }
  /** END - Xử lý ảnh */

  loadAddressOptions(address: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === address.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === address.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardAddress = currentDistrict.w;
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  patchFormValue(model: Property, formData: any, groupsAttributes: GroupsAttributes): Property {
    // Patch attributes
    model.attributes = groupsAttributes.attributes;
    // Patch form info
    model.transactionType = formData.type;
    model.category = this.listCategoryData.find((category: Category) => category.id === formData.category);
    model.price = MoneyToNumber.parse(formData.price);
    model.area = MoneyToNumber.parse(formData.area);
    model.direction = formData.direction;
    model.project = this.listProjectData.find((project: Project) => project.id === formData.project);
    model.isHot = formData.isHot;
    model.description = formData.description;
    model.priceAbove = MoneyToNumber.parse(formData.priceAbove);
    model.priceEval = MoneyToNumber.parse(formData.priceEval);

    // Patch address
    model.province = formData.addressProvince;
    model.city = formData.addressProvince;
    model.district = formData.addressDistrict;
    model.ward = formData.addressWard;
    // model.address = formData.addressText;
    model.address = this.fullAddress; // get full address
    model.fakeAddress = formData.fakeAddress;
    model.linkListing = [formData.linkListing1,
    formData.linkListing2,
    formData.linkListing3,
    formData.linkListing4,
    formData.linkListing5,
    formData.linkListing6,
    formData.linkListing7,
    formData.linkListing8,
    formData.linkListing9,
    formData.linkListing10]
    // Patch video url
    const images: Images = model.images;
    const newVideoUrl = { name: '', url: formData.videoUrl };
    images.videos = [newVideoUrl];
    return model;
  }

  getCategories(selectedCategoryId?: string) {
    if (selectedCategoryId && this.listCategoryData && this.listCategoryData.length > 0) {
      this.data.category = this.listCategoryData.find((category: Category) => category.id === selectedCategoryId);
      this.initForm(this.data);
      this.dataChange.emit(this.data);
    }
    return this.service.getCategories()
      .subscribe((res: any) => {
        this.listCategoryData = res.rows ? res.rows : (res ? res : []);
        this.listCategory = this.listCategoryData.map((item: any) => ({ value: item.id, label: item.name }));
        if (selectedCategoryId) {
          this.data.category = this.listCategoryData.find((category: Category) => category.id === selectedCategoryId);
          this.initForm(this.data);
          this.dataChange.emit(this.data);
        }
      });
  }

  getProject(selectedProjectId?: string) {
    if (selectedProjectId && this.listProjectData && this.listProjectData.length > 0) {
      this.data.project = this.listProjectData.find((project: Project) => project.id === selectedProjectId);
      this.initForm(this.data);
      this.dataChange.emit(this.data);
    }
    else {
      this.getProjectById(selectedProjectId);
    }
  }

  getProjectById(projectId: string) {
    return this.projectService.getOne(projectId).subscribe((res: any) => {
      if (res) {
        this.listProjectData.push(res);
        this.data.project = this.listProjectData.find((project: Project) => project.id === projectId);
        this.listProject = this.listProjectData.map((item: any) => ({ value: item.id, label: item.name }));
        this.initForm(this.data);
        this.dataChange.emit(this.data);
      }
    });
  }

  getTemplate() {
    const type: string = this.mainForm ? this.mainForm.get('type').value : null;
    const categotyId: string = this.mainForm ? this.mainForm.get('category').value : null;
    if (!type) {
      return;
    }
    this.typeSell = type === 'SELL';
    if (!categotyId) {
      // Xóa list attribute
      this.groupsAttributes = new GroupsAttributes({ attributes: [] });
      return;
    }
    return this.service.getAttributeForCategory(categotyId, type)
      .subscribe((res: any) => {
        const template: any = res;
        let allAttributes = [];
        if (template && template.id) {
          allAttributes = template.templates.map((attribute: any) => new DefineAttribute(attribute));
          allAttributes.forEach((attr: DefineAttribute) => {
            if (attr.attributeId === AttributeEnum.PROVINCE) {
              this.getProvinces();
            }
          });
        }
        // Nếu type + categotyId bằng giá trị ban đầu => copy giá trị attributes từ list cũ sang list mới
        if (type === this.data.transactionType
          && this.data.category
          && categotyId === this.data.category.id
          && this.data.attributes
          && this.data.attributes.length > 0) {
          // https://medium.com/dailyjs/rewriting-javascript-converting-an-array-of-objects-to-an-object-ec579cafbfc7
          const attributesFlatData: any = this.attributes.reduce((obj: any, item: any) => {
            obj[item.attributeId] = item.value;
            return obj;
          }, {});

          allAttributes.forEach((attr: DefineAttribute) => {
            attr.value = attributesFlatData[attr.attributeId] || null;
            if (attr.attributeId === AttributeEnum.PROVINCE) {
              this.onChangeProvince(attr.value || 0);
            }
            if (attr.attributeId === AttributeEnum.DISTRICT) {
              this.onChangeDistricts(attr.value || 0);
            }
          });
        }
        this.groupsAttributes = new GroupsAttributes({ attributes: allAttributes });
        // Emit data
        this.onAttributeChange();
      });
  }

  initMap() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder;
    });
  }

  onSearchMap(fakeAddress?) {
    this.searchMapChange$.next(this.fullAddress);
    if (!fakeAddress && !this.modeProperty) {
      this.mainForm.patchValue({
        fakeAddress: this.fullAddress
      });
    }
  }

  searchMap(address: string) {
    if (!this.geocoder) {
      return;
    }
    this.geocoder.geocode({ address: address }, (results: any[]) => {
      if (results && results[0]) {
        const place: any = results[0];
        // Update marker
        const lat = FloorRoundFloatNumber.floor(parseFloat(place.geometry.location.lat()), 5);
        const lng = FloorRoundFloatNumber.floor(parseFloat(place.geometry.location.lng()), 5);
        this.marker = Object.assign(this.marker, {
          lat: lat,
          lng: lng
        });
        this.lat = lat;
        this.lng = lng;
        this.formattedAddress = place.formatted_address;
        // Emit geo change
        this.data.location = new GeoLocation({ lat: lat, lng: lng });
        this.dataChange.emit(this.data);
      }
    });
  }

  mapClicked($event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.marker = Object.assign(this.marker, {
      lat: $event.coords.lat,
      lng: $event.coords.lng
    });
    // Get available address
    this.patchLocationAddress($event.coords);
  }

  markerDragEnd($event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    // Get available address
    this.patchLocationAddress($event.coords);
  }

  patchLocationAddress(coords: any) {
    if (!this.geocoder) {
      return;
    }
    this.data.location.setValue({
      lat: coords.lat,
      lng: coords.lng
    });
    this.geocoder.geocode({ location: coords }, (results: any[]) => {
      if (results && results[0]) {
        // TODO Patch address
        // this.projectForm.get('locationAddress').patchValue(results[0].formatted_address);
      }
    });
  }
  checkDinhGiaButton(groupName) {
    return groupName === this.GROUP_DINH_GIA;
  }
  sendDinhGia() {
    this.setEvalProperty();
    const res = this.getDinhGiaProperty(this.cEvalProperty);
  }
  getDinhGiaProperty(property) {
    return this.service.getDinhGiaProperty(property).pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        const responseBody = res.json();
        if (responseBody.isSuccess) {
          this.data.priceEval = responseBody.priceEstimatedInVNDRounded;
          this.mainForm.patchValue({
            priceEval: responseBody.priceEstimatedInVNDRounded
          });
          this.dataChange.emit(this.data);
          this.toastr.success('Thành công', 'Thông tin định giá đã được cập nhật');
        } else {
          this.toastr.error('Lỗi', 'Định giá');
        }

      }, err => {
        this.mainForm.patchValue({
          priceEval: ''
        });
        this.toastr.error('Lỗi', 'Định giá');
      });
  }
  setEvalProperty() {
    this.cEvalProperty = new CEvalProperty();
    this.cEvalProperty.PropertpropertyToEvaluate.externalId = 111;
    this.cEvalProperty.PropertpropertyToEvaluate.customerName = "DXS Api";
    this.cEvalProperty.PropertpropertyToEvaluate.customerPhone = "083312352xx";
    const group = this.getGroup(this.GROUP_DINH_GIA);
    group.attributes.forEach(attr => {
      if (attr.attributeId === AttributeEnum.PROVINCE && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.provinceId = attr.value;
      }
      if (attr.attributeId === AttributeEnum.DISTRICT && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.districtId = attr.value;
      }
      if (attr.attributeId === AttributeEnum.PROJECT && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.projectId = attr.value;
      }
      if (attr.attributeId === AttributeEnum.WARD && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.wardId = attr.value;
      }
      if (attr.attributeId === AttributeEnum.STREET && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.streetId = attr.value;
      }
      if (attr.attributeId === AttributeEnum.ADDRESSNUMBER && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.addressNumber = attr.value;
      }
      if (attr.attributeId === AttributeEnum.APARTMENTNUMBER && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.apartmentNumber = attr.value;
      }
      if (attr.attributeId === AttributeEnum.APARTMENTFLOORTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.address.apartmentFloorTh = attr.value;
      }
      if (attr.attributeId === AttributeEnum.LAND_TYPE && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.typeHouse.landType = attr.value;
      }
      if (attr.attributeId === AttributeEnum.HOUSE_TYPE && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.typeHouse.houseType = attr.value;
      }
      if (attr.attributeId === AttributeEnum.LOCATION && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.location = attr.value;
      }
      if (attr.attributeId === AttributeEnum.DISTINCETOSTREET && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.distanceToStreet = attr.value;
      }
      if (attr.attributeId === AttributeEnum.ALLEYTURNS && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.alleyTurns = attr.value;
      }
      if (attr.attributeId === AttributeEnum.ALLEYWIDTH1 && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.alleyWidth1 = attr.value;
      }
      if (attr.attributeId === AttributeEnum.ALLEYWIDTH2 && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.alleyWidth2 = attr.value;
      }
      if (attr.attributeId === AttributeEnum.ALLEYWIDTH3 && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.location.alleyWidth3 = attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREATOTAL && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaTotal = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREATOTALWIDTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaTotalWidth = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREATOTALLENGTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaTotalLength = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREATOTALBACKWIDTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaTotalBackWidth = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREALEGAL && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaLegal = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREALEGALWIDTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaLegalWidth = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREALEGALLENGTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaLegalLength = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREALEGALBACKWIDTH && attr.value != "") {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyLand.areaLegalBackWidth = +attr.value;
      }
      //Tiện ích
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_BASEMENT && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Basement');
      }
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_MEZZANINE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Mezzanine');
      }
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_TERRACE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Terrace');
      }
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_GARAGE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Garage');
      }
      // if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_ELEVATOR && attr.value) {
      //   this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Elevator');
      // }
      // if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_SWIMMINGPOOL && attr.value) {
      //   this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-SwimmingPool');
      // }
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_GARDEN && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Garden');
      }
      if (attr.attributeId === AttributeEnum.ADV_CONSTRUCTION_SKYLIGHT && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.advConstructionTypes.push('adv-construction-Skylight');
      }
      // Đặc điểm tốt
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_CORNERSTREET && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-CornerStreet');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_CORNERALLEY && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-CornerAlley');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_BACKALLEY && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-BackAlley');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_CONNECTALLEY && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-ConnectAlley');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_SUPERMARKET && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-SuperMarket');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_PARKCENTER && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-ParkCenter');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_SECURITY && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-Security');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_BESTLOCATION && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-BestLocation');
      }
      if (attr.attributeId === AttributeEnum.ADV_PROPERTY_GOODFORBUSINESS && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.advPropertyTypes.push('adv-property-GoodForBusiness');
      }

      // Đặc điểm xấu
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_ALLEYSTRIKE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-AlleyStrike');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_CHURCHTEMPLE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-ChurchTemple');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_FUNERALHOUSE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-FuneralHouse');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_BRIDGEVOLTAGE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-BridgeVoltage');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_FACEDRAIN && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-FaceDrain');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_FACEPOLE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-FacePole');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_FACETREE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-FaceTree');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_CANTBUILD && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-CantBuild');
      }
      if (attr.attributeId === AttributeEnum.DIS_PROPERTY_SUSPLANNING && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyAdvantage.disAvdPropertyTypes.push('dis-property-SusPlanning');
      }


      if (attr.attributeId === AttributeEnum.BEDROOMS && attr.value !== '') {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.bedrooms = (+attr.value).toString();
      }

      if (attr.attributeId === AttributeEnum.BATHROOMS && attr.value !== '') {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.bathrooms = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREAUSABLE && attr.value) {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.areaUsable = +attr.value;
      }
      if (attr.attributeId === AttributeEnum.AREACONSTRUCTIONFLOOR && attr.value !== '') {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.areaConstructionFloor = +attr.value;
      }

      if (attr.attributeId === AttributeEnum.FLOORCOUNT && attr.value !== '') {
        this.cEvalProperty.PropertpropertyToEvaluate.propertyConstruction.floorsCount = +attr.value;
      }

    });
  }
  allowDinhGia() {
    if (!this.data || !this.data.category || !this.data.category.id) {
      return false
    }
    return this.ALLOW_DINHGIA.includes(this.data.category.id);
  }
}

interface SelectItemModel {
  value: any;
  label: string;
}
interface Province {
  n: string;  // Name
  d: District[]; // Districts
}
interface District {
  n: string;  // Name
  w: string[];  // Wards
}
