import { Injector } from '@angular/core';
import { } from './base.component';
import {
    Message, StompService, ToastrService
} from '../services/common';
import { Observable, Subscription } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { EventChangeService } from '../services/event.change.service';
import { StorageService } from '../services';

export abstract class NotifyComponent {
    Entity: any;
    route: any;
    router: any;
    tableProperty: any;
    settings: any;
    public stompService: StompService;
    public toastrService: ToastrService;
    public websocketService: WebsocketService;
    public eventChangeService: EventChangeService;
    active: Boolean;
    // stomp
    // Subscription status
    public subscribed: boolean;
    // Stream of messages
    public subscription: Subscription;
    public subscriptions: Subscription[] = [];
    public messages: Observable<Message>;
    public listMessages: Observable<Message>[] = [];
    public storageService: StorageService;
    public errorSubscription: Subscription;
    // messaging pattern
    public messagingPattern = '/exchange/datxanhres-online-to-offline-2019-msX.Exchange.';
    // pattern type (info-error) + project (msx-qms) +  type (info-err) + aggregate name + event name
    public infoMessagingPattern : any = this.messagingPattern;
    public errorMessagingPattern = this.messagingPattern;
    public errorMessages: Observable<Message>;
    public errorMessage: string = 'error!';
    public entityID: string = '';
    constructor(
        injector: Injector
    ) {
        this.stompService = injector.get(StompService);
        this.toastrService = injector.get(ToastrService);
        this.websocketService = injector.get(WebsocketService);
        this.eventChangeService = injector.get(EventChangeService);
        this.storageService = injector.get(StorageService);
    }

    ngOnInit() {

    }
    ngOnDestroy() {
    }
    public subscribe(isReset?) {
        // Stream of messages
        // info
        if (typeof this.infoMessagingPattern != 'object') {
            this.createSubscribeForPattern(this.infoMessagingPattern);
        } else {
            this.infoMessagingPattern.forEach((infoMessagingPattern) => {
                this.createSubscribeForPattern(infoMessagingPattern);
            })
        }

        // error
        if (this.errorMessagingPattern != this.messagingPattern) {
            this.errorMessages = this.stompService.subscribe(this.errorMessagingPattern);
            this.errorSubscription = this.errorMessages.subscribe(this.errorSubscribe.bind(this));
        }
    }
    public createSubscribeForPattern(infoMessagingPattern) {
        if (infoMessagingPattern == this.messagingPattern) {
            return;
        }
        if (this.websocketService.getChannel(infoMessagingPattern)) {
            console.log('had channel', infoMessagingPattern)
            return;
        }
        let message = this.stompService.subscribe(infoMessagingPattern);
        let subscription = message.subscribe(this.infoSubscribe.bind(this));
        this.listMessages.push(message);
        this.websocketService.setChannel(infoMessagingPattern, subscription);
        this.subscriptions.push(subscription);
    }
    public unsubscribe() {
        if (!this.subscribed) {
            return;
        }
        // This will internally unsubscribe from Stomp Broker
        // There are two subscriptions - one created explicitly, the other created in the template by use of 'async'
        this.subscription.unsubscribe();
        this.websocketService.setChannel(this.infoMessagingPattern, null);
        this.subscription = null;
        this.messages = null;
        this.errorSubscription = null;
        this.errorMessages = null;
        this.subscribed = false;
    }
    /** Consume a message from the stompService */
    public infoSubscribe(message: Message) {
        if (message && message.body) {
            let object = JSON.parse(message.body);
            if (!object.eventName) {
                return;
            }
            if (object.actionName && (object.actionName.replace(',','') != this.storageService.retrieve('url') + this.storageService.retrieve('timestamp'))) {
                return;
            }
            this.eventChangeService.emitChange({
                type: 'notification',
                msg: {
                    title: object.eventName,
                    content: object.eventName
                }
            });
            this.eventChangeService.emitChange({
                broadcast: object.eventName,
                message: object
            });
            if (object.eventName.toLowerCase().includes('delete')) {
                return this.executeDelete();
            }
        }
    }
    /** Consume a message from the stompService */
    public errorSubscribe(message: Message) {

        if (message && message.body) {
            const error = JSON.parse(message.body);
            if (!error.code) {
                console.log('errorSubscribe', error);
            }
            // if (!error.code) {
            //     this.toastrService.error('Error!', error.code);
            // } else this.router.navigate([this.Entity.getType().toLowerCase()]);
        }

    }
    public executeDelete () {

    }
    public successNotice(text) {
        this.toastrService.success('Thành Công!', text);
    }

    public errorNotice(text) {
        this.toastrService.error('Lỗi!', text);
    }

    unsubscribeAll() {
        this.subscriptions.forEach(element => !element.closed && element.unsubscribe() );
        this.websocketService.cleanChannel();
        this.messages = null;
        this.listMessages = [];
        this.subscribed = false;
    }
}
