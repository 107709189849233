import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface ChoosePopupData {
  title: string;
  action: string;
  headers: any;
  execute: boolean;
  items: any;
  chooseValue: string;
}

@Component({
  selector: 'choose-popup',
  styleUrls: ['./choose-popup.scss'],
  templateUrl: 'choose-popup.html',
})
export class ChoosePopup implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChoosePopup>,
    @Inject(MAT_DIALOG_DATA) public data: ChoosePopupData) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }

  execute() {
    if (this.data.chooseValue) {
      this.data.execute = true;
      this.dialogRef.close(this.data);
    }
  }

}
