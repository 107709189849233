import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { MatDialog } from "@angular/material";
import { ErrorPopup } from "../components/error-popup/error-popup";
import { SuccessPopup } from "../components/success-popup/success-popup";
import { InfoDialogComponent } from '../components/info-dialog/popup-dialog';

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable()
export class DialogService {
  public modals: any = {};

  constructor(

    public matDialog: MatDialog
  ) {

  }
  showModal(modal) {
    if (!$("#"+ modal).hasClass("show") && !this.modals[modal]) {
      this.modals[modal] = true;
      setTimeout(() => {
        setTimeout(() => {
          this.modals[modal] = false;
        }, 500*3)
        $("#"+ modal).modal("show");
        $(document.body).addClass("modal-open");
      }, 500)
    }
  }
  removeModal(modal) {
    $("#"+ modal).modal("hide");
    $(document.body).removeClass("modal-open");
  }
  openInfoDialog(response, func?) {
    let data = {
        title: response.error,
        text: response.message,
        type: response.type || 'info'
    }
    const dialogRef = this.matDialog.open(InfoDialogComponent, {
        width: '300px',
        data: {
          title: data.title,
          body: data.text,
          type: data.type
        }
    }); 

    dialogRef.afterClosed().subscribe(result => {
      func(result);
    });
  }
  openErrorDialog(response) {
    let data = {
        title: response.error,
        text: response.message,
        errors: response.errors
    }
    const dialogRef = this.matDialog.open(ErrorPopup, {
        width: '500px',
        data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openSuccessDialog(response) {
    let data = {
        title: response.title,
        text: response.message
    }
    const dialogRef = this.matDialog.open(SuccessPopup, {
        width: '300px',
        data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
