export class TransactionStatusMapping {
    public static INITIAL = 'Chờ hạch toán';
    public static REJECTED = 'Từ chối';
    public static ACCOUNTED = 'Đã hạch toán';
    public static WAITING_TRANSFER = 'Chờ duyệt';
    public static PROCESSING = 'Bị từ chối';
    public static TRANSFERED = 'Đã duyệt';
    public static REFUNDED = 'Đã hoàn';
    public static CANCELED = 'Đã hủy';
}

export class TransactionTypeMapping {
    public static DEPOSIT = 'Thu phí HDMG';
    public static CONTRACT = 'Thu phí HDMG';
    public static DEPOSIT_24H = 'Đặt cọc 24h';
}

export class PrimaryTransactionActive {
    public static ACTIVE = 'Đã kích hoạt'
    public static INACTIVE = 'Chưa kích hoạt'
}

export enum TXN_STATUS  {
    CLOSE = 'Chờ ĐVBH xác nhận',
    ADMIN_APPROVED_TICKET= 'ĐVBH đã xác nhận YCDCH',
    DEPOSIT_APPROVED= 'KT đã xác nhận ĐNTT',
    CS_APPROVED_TICKET= 'DVKH đã xác nhận',
    BOOKING_APPROVED= 'Giữ chỗ thành công',
    PRIORITY_UPDATED = 'Đã ráp ưu tiên',
    COMING= 'Chờ KH xác nhận',
    PROCESSING = 'KH đã xác nhận',
    PROCESSING_LOCK = 'Chờ KH xác nhận GD2',
    POS_CONFIRM= 'ĐVBH đã xác nhận GD',
    LOCK_CONFIRM= 'Chuyển cọc thành công, chờ bổ sung hồ sơ',
    LOCK_CONFIRM_LOCK= 'Xác nhận cọc, chờ bổ sung tiền và hồ sơ',
    POS_CONFIRM_LOCK = 'ĐVBH xác nhận, chờ KT xác nhận tiền',
    SUCCESS= 'Chuyển cọc thành công',
    PROCESSING_UNPAID = 'Cọc mới, chưa thanh toán',
    POS_CONFIRM_UNPAID = 'ĐVBH xác nhận, chờ SA/DVKH xác nhận',
    SA_CONFIRM_UNPAID = 'SA/DVKH xác nhận, chờ KT xác nhận tiền',
    UNSUCCESS= 'GD không thành công',
    CANCEL_REQUESTED= 'Đề nghị hủy giữ chỗ',
    ADMIN_APPROVED_CANCEL_REQUESTED= 'ĐVBH đã duyệt hủy chỗ',
    ADMIN_CANCEL_REQUESTED= 'ĐVBH từ chối duyệt hủy chỗ',
    TICKET_CANCELLED= 'Hủy chỗ thành công',
    CS_APPROVED_CANCEL_REQUESTED= 'DVKH đã xác nhận hủy chỗ',
    CS_CANCEL_REQUESTED= 'DVKH từ chối duyệt hủy chỗ',
    ADMIN_REJECTED_TICKET= 'ĐVBH đã từ chối',
    DEPOSIT_REJECTED= 'KT đã trả về ĐNTT',
    CS_REJECTED_TICKET= 'DVKH đã từ chối',
    POS_REJECT= 'ĐVBH không xác nhận cọc',
    POS_REJECT_UNPAID = 'ĐVBH từ chối',
    SA_REJECT_UNPAID = 'SA/DVKH từ chối',
    CS_REJECTED_ESCROW = 'DVKH từ chối cọc',
    REFUNDED = 'Đã hoàn tiền',
    WAITING_OTP = 'Chờ xác nhận OTP',
    LIQUIDATED = 'Đã thanh lý',
    WAITING_SMS_LOCK = 'Chờ xác nhận SMS GD2',
    WAITING_SMS = 'Chờ xác nhận SMS',
    MCONFIRM = 'KH - ĐVBH đã xác nhận',
    MSUCCESS = 'KH-  Giao dịch thành công'
}

export enum UNIT_STATUS {
    LOCK = 'Khóa đầu tư',
    CLOSE = 'Chưa mở bán',
    COMING = 'Đang mở bán',
    PROCESSING = 'ĐVBH đã đăng ký GD',
    CONFIRM = 'ĐVBH đã xác nhận',
    LOCK_CONFIRM = 'Đang kiểm tra hồ sơ',
    SUCCESS = 'Chuyển cọc thành công',
    UNSUCCESS = 'Giao dịch không thành công',
    CANCEL = 'Thanh lý',
    MOVED = 'Đã chuyển CTBH',
    MCONFIRM = 'KH - Đang kiểm tra hồ sơ',
    MSUCCESS = 'KH - Đã kiểm tra hồ sơ',
    TRANSFER = 'Đổi tên/Chuyển nhượng',
    DEPOSIT = 'Hợp đồng',
}

export enum TXN_PLACE {
    CCSQL = 'Cục Cảnh sát quản lý hành chính về trật tự xã hội',
    CHSCS = 'Cục Hồ sơ Cảnh sát',
    DKQL = 'Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư',
    CQLXNC = 'Cục Quản lý xuất nhập cảnh',
    AG = 'CA. An Giang',
    VT = 'CA. Bà Rịa Vũng Tàu',
    BG = 'CA. Bắc Giang',
    BK = 'CA. Bắc Kạn',
    BL = 'CA. Bạc Liêu',
    BN = 'CA. Bắc Ninh',
    BTR = 'CA. Bến Tre',
    BDI = 'CA. Bình Định',
    BDU = 'CA. Bình Dương',
    BP = 'CA. Bình Phước',
    BTH = 'CA. Bình Thuận',
    CM = 'CA. Cà Mau',
    CT = 'CA. Cần Thơ',
    CB = 'CA. Cao Bằng',
    DNA = 'CA. Đà Nẵng',
    DL = 'CA. Đắk Lắk',
    DNO = 'CA. Đắk Nông',
    DB = 'CA. Điện Biên',
    DN = 'CA. Đồng Nai',
    DT = 'CA. Đồng Tháp',
    GL = 'CA. Gia Lai',
    HAG = 'CA. Hà Giang',
    HNA = 'CA. Hà Nam',
    HNO = 'CA. Hà Nội',
    HT = 'CA. Hà Tĩnh',
    HD = 'CA. Hải Dương',
    HP = 'CA. Hải Phòng',
    HUG = 'CA. Hậu Giang',
    HB = 'CA. Hoà Bình',
    HY = 'CA. Hưng Yên',
    KH = 'CA. Khánh Hoà',
    KG = 'CA. Kiên Giang',
    KT = 'CA. Kon Tum',
    LCH = 'CA. Lai Châu',
    LD = 'CA. Lâm Đồng',
    LS = 'CA. Lạng Sơn',
    LCA = 'CA. Lào Cai',
    LA = 'CA. Long An',
    ND = 'CA. Nam Định',
    NA = 'CA. Nghệ An',
    NB = 'CA. Ninh Bình',
    NT = 'CA. Ninh Thuận',
    PT = 'CA. Phú Thọ',
    PY = 'CA. Phú Yên',
    QB = 'CA. Quảng Bình',
    QNA = 'CA. Quảng Nam',
    QNG = 'CA. Quảng Ngãi',
    QNI = 'CA. Quảng Ninh',
    QT = 'CA. Quảng Trị',
    ST = 'CA. Sóc Trăng',
    SL = 'CA. Sơn La',
    TNI = 'CA. Tây Ninh',
    TB = 'CA. Thái Bình',
    TNG = 'CA. Thái Nguyên',
    TH = 'CA. Thanh Hoá',
    HCM = 'CA. TP Hồ Chí Minh',
    TTH = 'CA. Thừa Thiên Huế',
    TG = 'CA. Tiền Giang',
    TV = 'CA. Trà Vinh',
    TQ = 'CA. Tuyên Quang',
    VL = 'CA. Vĩnh Long',
    VP = 'CA. Vĩnh Phúc',
    YB = 'CA. Yên Bái'
}
