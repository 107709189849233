export class Employee {
    id: string;
    code: string;
    name: string;
    email: string;
    avatar: string;
    phone: string;
    area: string;
    role: any;
    distances: string;
    star: number;
    joinDate: string;
    slug: string;


    constructor( params?: any ) {
      if (params) {
        this.id = params.id || '';
        this.slug = params.slug || '';
        this.name = params.name || '';
        this.email = params.email || '';
        this.code = params.code || '';
        this.phone = params.phone || '';
        this.role = params.role || {};
        this.joinDate = params.createdAt || '';
        this.avatar = params.images ? params.images.avatar : 'assets/images/v7/icon_avatar.svg';
        const km = params.distance > 0 ? params.distance / 1000 : 0;
        this.distances = km !== 0 ? (Math.round(km * 10) / 10) + ' km' : '';
        this.area = params.interestedArea && params.interestedArea.district && params.interestedArea.province ? params.interestedArea.district + ', ' + params.interestedArea.province : 'N/A';
        this.star = params.value ? Math.round(params.value * 10) / 10 : 0;
      } else {
        this.setAllNull();
      }
    }
  setAllNull() {
    this.id = '';
    this.slug = '';
    this.name = '';
    this.email = '';
    this.code = '';
    this.phone = '';
    this.role = {};
    this.avatar = '';
    // const km = params.distances > 0 ? params.distances / 1000 : 0;
    this.distances = '';
    this.area = '';
    this.star = 0;
  }
}

export class PublicEmployee {
  name: string = '';
  email: string = '';
  id:  string = '';
  code:  string = '';
  phone: string = '';
  description:  string = '';
  interestedArea: any = {};
  projects: any = [];
  role: any = {};
  socialUrls: any = {};
  pos: any = {};
  images: any =  {};


  constructor(params) {
    if(params) {
      Object.assign({}, this,params)
    }
    

  }

}
