import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Constant } from 'app/shared/constant/constant';
import * as moment from 'moment';

export interface DialogData {
  type: string;
  title: string;
  action: string;
  reject: boolean;
  confirm: boolean;
  approve: boolean;
  reason: string;
  execute: boolean;
  items: any;
  edit: boolean;
}

@Component({
  selector: 'confirm-dialog',
  styleUrls: ['./confirm-dialog.scss'],
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog implements OnInit {
  Constant = Constant;
  public receiptDate = null;
  public receiptDateInvalid: boolean = false;
  public listTransferType: any[] = [
    {
      key: 'TRANSFER',
      value: 'Chuyển khoản'
    },
    {
      key: 'CASH',
      value: 'Tiền mặt'
    },
  ];

  public listBanks: any[] = [
    {
      key: 'B04003000001',
      value: 'BIDV-17710000001533'
    },
    {
      key: 'B04003000002',
      value: 'BIDV-13010002008168'
    },
    {
      key: 'B19003000001',
      value: 'Vietin-111000138086'
    },
    {
      key: 'B19003000002',
      value: 'Vietin-116002868211'
    },
    {
      key: 'B19005000001',
      value: 'Vietin-119000077968'
    },
    {
      key: 'B29001000001',
      value: 'NSI-028C600575'
    },
    {
      key: 'B30001000001',
      value: 'SeaBank-000005902369'
    },
    {
      key: 'B31001000001',
      value: 'TPB-'
    }
  ];
  public dropdownBanks: any[] = this.listBanks;
  public dropdownCash = [
    {
      key: 'TM',
      value: 'Tiền mặt'
    }
  ]
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    if (data.edit) {
      this.receiptDate = moment(this.data.items.receiptDate).format('DD/MM/YYYY HH:mm:ss');
      this.data.reason = this.data.items.reason;
      this.onChangeDate();

      if (data && data.items && data.items.bankInfo) {
        const findIndex = this.listBanks.find(el => el.key === data.items.bankInfo);
        data.items.stateKT = findIndex ? 'TRANSFER' : 'CASH';
        this.dropdownBanks = findIndex ? this.listBanks : this.dropdownCash;

      }
    }


  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  execute() {
    this.data.reject = true;
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }

  transactionTypeMapping(text: string) {
    let result = '';
    switch (text) {
      case 'DEPOSIT':
        result = 'Thu phí HDMG';
        break;
      case 'CONTRACT':
        result = 'Thu phí HDMG';
        break;
      case 'DEPOSIT_24H':
        result = 'Đặt cọc 24h';
        break;
    }
    return result;
  }
  onChangeDate() {
    const date = moment(this.receiptDate, 'DD-MM-YYYY HH:mm:ss');
    if (!date.isValid()) {
      this.receiptDateInvalid = true;
    } else {
      // this.receiptDate = moment(date).format('DD/MM/YYYY HH:mm:ss');
      this.data.items.receiptDate = moment(date, 'DD/MM/YYYY HH:mm:ss').toString();
      this.receiptDateInvalid = false;
    }
  }

  changeTransferType(event) {
    if (event.value === 'CASH') {
      this.dropdownBanks = this.dropdownCash;
      this.data.items.bankInfo = 'TM';

    } else {
      if (this.data.items.bankInfo === 'TM') {
        this.data.items.bankInfo = null;
      }
      this.dropdownBanks = this.listBanks;
    }
  }

}
