import {Component, ViewChild, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DataSource} from '@angular/cdk/collections';
import { MatCheckboxChange } from "@angular/material";
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';

export interface GridData {
  columns : any[],
  data : any[],
  buttonName: string,
}

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'grid-popup',
  templateUrl: 'grid-popup.component.html',
  styleUrls: [
    'grid-popup.component.scss'
  ],
})
export class GridPopupComponent {
  isCheckAll: boolean = true;
  buttonName: string;
  onClick = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  displayColumns = null;
  dataSource = null;
  constructor(
    public dialogRef: MatDialogRef<GridPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public gridData: GridData) {
      this.displayColumns = gridData.columns.map(c => c.columnDef);
      this.dataSource = new GridDataSource(gridData.data);
      this.buttonName = gridData.buttonName;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  onChangeItem(item) {
    this.setCheckStatus();
  }

  onChangeHeader(event: MatCheckboxChange) {
    this.dataSource.data.forEach(d => {
      d.checkbox = event.checked;
    });
  }

  setCheckStatus() {
    this.isCheckAll = this.dataSource.data.length > 0 ? this.dataSource.data.every(a => a.checkbox) : false;
  }

  onClickEvent() {
    this.onClick.emit(this.dataSource.data);
  }
  closeDialog() {
    this.dialogRef.close();
  }
}



export class GridDataSource extends DataSource<any> {
  constructor(public data : any[]){
    super();
  }
  connect(): Observable<Element[]> {
    return Observable.of(this.data);
  }

  disconnect() {}
}
