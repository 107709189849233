import { IDecode } from './i-decode';
import { CPOS } from './c-pos';

export class CEventPOS implements IDecode<CEventPOS> {

    public id: string;
    public pos: CPOS;
    public totalTransaction_1: number;
    public totalTransaction_2: number;
    public totalTransaction: number;

    constructor(params: Partial<CEventPOS> = {}) {
        this.copy(params);
    }

    copy(params: Partial<CEventPOS> = {}): CEventPOS {
        params = params || {};

        this.id = params.id || params['_id'] || null;
        this.pos =  new CPOS(params.pos);
        this.totalTransaction_1 =  params.totalTransaction_1 || 0;
        this.totalTransaction_2 =  params.totalTransaction_2 || 0;
        this.totalTransaction =  params.totalTransaction || 0;

        return this;
    }

    decode(paramsApi: any): CEventPOS {
        return new CEventPOS(paramsApi);
    }

    decodeList(paramsApi: any[]): CEventPOS[] {
        return paramsApi.map(el => new CEventPOS().decode(el));
    }
}


