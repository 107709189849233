import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Constant } from 'app/shared/constant/constant';

export interface ConfirmData {
  title: string;
  message: string;
  project: string;
  textCancel: string;
  textOk: string;
  isChecked: boolean;
  execute: boolean;
}

@Component({
  selector: 'confirm-erp-sales-popup',
  templateUrl: './confirm-erp-sales-popup.html',
  styleUrls: ['./confirm-erp-sales-popup.scss'],
})
export class ConfirmErpSalesPopup implements OnInit {
  isDisable : boolean = true;
  mainForm: FormGroup = null;
  project: string = '';
  constructor(
    public dialogRef: MatDialogRef<ConfirmErpSalesPopup>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) {}

  ngOnInit() {
    this.data.isChecked = true;
    this.initForm();
    
  }
  getFormControl(name: string) {
    return this.mainForm.get(name);
  }
  initForm(){
    this.mainForm = this.formBuilder.group({
        codeProject: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.pattern(Constant.REGEX_TEXT_AND_NUMBER)])],
    });

    this.getFormControl('codeProject').valueChanges
      .subscribe((value: string) => {
          if(value && Constant.REGEX_TEXT_AND_NUMBER.test(value)){
                this.isDisable = false;
                this.project = value;
          }else{
            this.isDisable = true;
          }
      });
  } 
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    this.data.execute = true;
    this.data.project = this.project;
    this.dialogRef.close(this.data);
  }
}
