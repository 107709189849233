import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, FormControl } from '@angular/forms';
import { isString } from 'util';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() isDisabled: boolean = false;
  @Input() disabledToggle: boolean = false;
  @Input() isFilter: boolean = false;
  @Input() isChangeDisabled: boolean = false;
  @Output() dateChange: EventEmitter<string> = new EventEmitter();
  private _changeCb: any;
  private _blurCb: any;
  public timePickerValue: string = '';

  constructor() { }

  ngOnInit() {
  }

  convertControlValueAccessorNgModel(time: any): string {
    return time ? time : null;
  }

  onDateSelection() {
    this.callChangeCb();
    this._blurCb();
    this.dateChange.emit(this.convertControlValueAccessorNgModel(this.timePickerValue));
  }

  writeValue(value: string) {
    this.timePickerValue = value ? value : null;
  }

  registerOnChange(fn: any) {
    this._changeCb = fn;
  }

  registerOnTouched(fn: any) {
    this._blurCb = fn;
  }

  callChangeCb() {
    this._changeCb(this.convertControlValueAccessorNgModel(this.timePickerValue));
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(form: FormControl) {
    return form.valid ? null : {
      type: {
        valid: false,
        actual: null
      }
    };
  }

  clearValue() {
    this.timePickerValue = '';
    this.callChangeCb();
    this._blurCb();
  }
  isInvalidTime(): boolean {
    return isString(this.timePickerValue);
  }
}

