import { Directive, HostListener, ElementRef, Output, EventEmitter, OnInit, Input, AfterViewInit } from '@angular/core';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { CPagination } from '../../api-models/c-pagination';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements OnInit {

  private unsubscribe$: Subject<any> = new Subject();
  public scrollEndSubject: Subject<void> = new Subject();
  public scrollEndSubject$: Observable<void> = this.scrollEndSubject.asObservable();

  private _pagination = new CPagination();
  @Input()
  set pagination(val: CPagination<any>) {
    this._pagination = val;
    this.isEmitViewMore();
  }
  get pagination(): CPagination<any> {
    return this._pagination;
  }

  @Input() isLoading: boolean = false;
  @Output() scrollEnd: EventEmitter<void> = new EventEmitter();

  @HostListener('scroll', ['$event'])
  private onScroll($event: Event): void {
    const elmRef: HTMLElement = this.elmRef.nativeElement;
    const pos = (elmRef.scrollTop || elmRef.scrollTop) + elmRef.offsetHeight;
    const max = elmRef.scrollHeight;


    if (
      pos >= (max - 200) &&
      !this.isLoading &&
      (!this.pagination || (this.pagination.total !== 0 && this.pagination.isLoadMore()))
    ) {
      this.scrollEndSubject.next();
    }
  }

  isEmitViewMore() {
    const elmReff: HTMLElement = this.elmRef.nativeElement;

    if (this.pagination.isLoadMore() && (elmReff.scrollHeight <= elmReff.clientHeight)) {
      this.scrollEnd.emit();
    }
  }


  constructor(private elmRef: ElementRef) {

  }

  ngOnInit() {
    this.scrollEndSubject$
    .pipe(
      // debounceTime(100),
      // distinctUntilChanged(),
      // takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.scrollEnd.emit();
    });
  }
}
