export class CHelper {
    static RemoveEmpty(obj: any) {
        Object.keys(obj).forEach(key => {

            if (!obj[key]) {
                delete obj[key];
            } else if (obj[key] && typeof obj[key] === 'object') {
                CHelper.RemoveEmpty(obj[key]);
            }
        });

        return obj;
    }

    static ConverUrlYoutue(url: string) {
        // url = `https://www.youtube.com/watch?v=tbgLfI1dvOg&list=RDtbgLfI1dvOg&start_radio=1`;
        const params = url.split(`?`)[1] || '';
        const paramVideo = params.split('&').find((el) => el.includes('v='));
        const videoId = paramVideo.split('=')[1] || '';

        return `https://www.youtube.com/embed/${videoId}`;
    }
}
