import {Component, OnInit, SimpleChange, OnDestroy, Input, Output, EventEmitter, ViewChild, OnChanges, AfterViewInit, ViewChildren} from '@angular/core';
import { JhiEventManager } from 'ng-jhipster';
import { TableItem } from './table.model';
import { PagingComponent } from '../paging';
import { Constant } from '../../constant/constant';
import { MoneyToNumber } from '../../parse/money-to-number';
import { MatTableDataSource } from '@angular/material';
import { StorageService } from 'app/shared/services';
@Component({
    selector: 'custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: [
        'custom-table.scss'
    ],
    providers: []
})
export class CustomTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input('ENTITY') ENTITY: any;
    @Input('tableProperty') tableProperty: any;
    @Input('methods') methods: any;
    @Input() noCreateButton: boolean=false;
    @ViewChild(PagingComponent) paging: PagingComponent;
    @Output('parentChangeItemPerPage') parentChangeItemPerPage: EventEmitter<any> = new EventEmitter();
    @Output('parentChangePage') parentChangePage: EventEmitter<any> = new EventEmitter();
  dataInOnePage: any = [];
    dataAll: any;
    sortList: any;
    filterList: any;
    listItemsFull: any;
    valueSearchAll: string = '';
    total: number = 0;
    totalPages: number = 0;
    currentPage: number = 1;
    currentPageSize: number = 10;
    Constant = Constant;
    public displayedColumns = ['name', 'parentName'];
    public dataSource: any;
    @Output() result: EventEmitter<any> = new EventEmitter();
    constructor(
        public storageService: StorageService,
        ) {
    }
    ngOnInit() {
        this.dataInOnePage = [];
        this.dataAll = [];
        this.sortList = [];
        this.filterList = {};
        let ENTITY = this.ENTITY;
    }
    ngOnDestroy() {
    }
    ngOnChanges() {
        // this.dataInOnePage = this.tableProperty.data.splice(0, Constant.ITEMS_PER_PAGE);
        this.dataSource = new MatTableDataSource(this.tableProperty.data);
    }
    resetPage() {
        this.paging && this.paging.setDefault();
    }
    getValue(root, childrens) {
        let value = root;
        childrens.forEach((children) => {
            value = value[children];
        })
        return value;
    }
    setFilter() {
        this.filterList = this.tableProperty.values.map((value) => {
            let object = {
                property: value,
                text: ''
            }
            return object;
        })
    }
    filter() {
        if (this.dataAll.length == 0) {
            this.dataAll = Object.assign([], this.tableProperty.data);
        }
        let list = [];
        this.dataAll.forEach((item) => {
            item.weight = 0;
            this.tableProperty.values.forEach((value, index) => {
                if (this.filterList[value]) {
                    if (item[value].includes(this.filterList[value])) {
                        item.weight++;
                    }
                }
            })
            if (item.weight > 0) {
                if (item.weight == 2) {
                }
                list.push(item);
            }
        })
        if (list.length > 0) {
            list = list.sort((a: any, b: any) => {
                return b.weight - a.weight;
            })
            this.paging.setDefault(list);
        } else {
            let isSearch = false;
            for (let property in this.filterList) {
                if (this.filterList[property]) {
                    isSearch = true;
                }
            }
        }
    }
    filterFollowProperty() {
        if (this.dataAll.length == 0) {
            this.dataAll = Object.assign([], this.tableProperty.data);
        }
        let list = [];
        this.dataAll.forEach((item) => {
            item.weight = 0;
            this.tableProperty.values.forEach((value, index) => {
                let valueItem = (item[value] + '').toLowerCase();
                let res = this.tableProperty.filters.find(filter => filter.property == value)
                if (res && res.value) {
                    if (res.type == 'money') {
                        if (valueItem.includes((MoneyToNumber.parse(res.value) + '').toLowerCase())) {
                            item.weight++;
                        }
                    } else {
                        if (valueItem.includes(res.value.toLowerCase())) {
                            item.weight++;
                        }
                    }

                } else {
                    if (this.valueSearchAll && valueItem.includes(this.valueSearchAll)) {
                        item.weight++;
                    }
                }
            })
            if (item.weight > 0) {
                if (item.weight == 2) {
                }
                list.push(item);
            }
        })
        if (list.length > 0) {
            list = list.sort((a: any, b: any) => {
                return b.weight - a.weight;
            })
            this.paging.setDefault(list);
        } else {
            let isSearch = false;
            this.tableProperty.filters.forEach((filter) => {
                if (filter.value) {
                    isSearch = true;
                }
            })
            if (this.valueSearchAll) {
                isSearch = true;
            }
            if (isSearch) {
                this.paging.setDefault(list);
            }
        }
    }
    sortApha(index) {
        let value = this.tableProperty.values[index];
        if (this.sortList[value] == undefined) {
            this.sortList = [];
            this.sortList[value] = true;
        } else {
          this.sortList[value] = !this.sortList[value];
        }
        // let list = Object.assign([], this.tableProperty.data);
        this.tableProperty.data.sort((a: any, b: any) => {
            // return this.sortList[value] ? a[value] > b[value] : b[value] < a[value];
            if (a[value] < b[value]) { return this.sortList[value] ? -1 : 1; }
            if (a[value] > b[value]) { return this.sortList[value] ? 1 : -1; }
            return 0;
        })
    }
    customSort(value, properties, all?) {
        const query = !all ? 'every' : 'some';
        let unique = false;
        if (!all) {
            this.sortList = this.sortList.map((item) => {
                let index = 0;
                let exist = true;
                item.properties.forEach((property) => {
                    if (exist && property === properties[index]) {
                        index++;
                    } else {
                        exist = false;
                    }
                })
                if (exist) {
                    item.value = value;
                    unique = true;
                }
                return item;
            })
            if (!unique) {
                this.sortList.push({
                    properties: properties,
                    value: value,
                    asc: true
                })
            }
        } else {
            this.valueSearchAll = value;
        }
        if (!this.listItemsFull || (this.listItemsFull && this.listItemsFull.length === 0)) {
            this.listItemsFull = JSON.parse(JSON.stringify(this.tableProperty.data));
        }
        let array: any = [];
        this.listItemsFull.forEach((item) => {
            let result = false;
            result = this.sortList[query]((sort) => {
                let valueDetech = JSON.parse(JSON.stringify(item));
                sort.properties.forEach((property) => {
                    valueDetech = valueDetech[property]
                })
                if (item && sort.value !== 'allitem' && valueDetech !== sort.value) {
                    return false;
                }
                return true;
            })
            if (this.valueSearchAll && result) {

                // tslint:disable-next-line:forin
                result = this.searchAll(item, this.valueSearchAll)
            }
            if (result) {
                array.push(item)
            }
        })
        this.tableProperty.data = array;
        // this.pageComponent.setDefault();
    }
    searchAll(item, value) {
        let result = false;
        value += "";
        // tslint:disable-next-line:forin
        for (let property in item) {
            if (!result && typeof item[property] === 'object') {
                result = this.searchAll(item[property], value)
            } else if (!result && typeof item[property] === 'string') {
                let contentSearch = item[property].toLowerCase();
                let val = value ? value.toLowerCase() : '';

                // includes not supported in IE
                contentSearch.includes(val) && (result = true)
            } else if (!result && typeof item[property] === 'number') {
                const str = item[property] + '', value1 = value + '';
                str.includes(value1) && (result = true)
            }
        }
        return result;
    }

    changeData(event) {
        setTimeout(() => {
            this.dataInOnePage = event;
        }, 100)
    }
    setData() {
        // this.pageComponent.setDefault();
    }
    delete(entity) {
        let position = -1;
    }
    sortOrder(index) {
        this.sortList[index].asc = !this.sortList[index].asc;
        const property = this.sortList[index].properties[0];
        const asc = this.sortList[index].asc;
        this.tableProperty.data = this.tableProperty.data.sort((a, b) => {
            if (a[property] > b[property]) {
                return asc ? 1 : -1;
            }
            if (a[property] < b[property]) {
                return asc ? -1 : 1;
            }
            return 0;
        })
        // this.pageComponent.setDefault();
    }
    checkSort(title, index) {
        if (title && title !== 'Edit') {
            if (this.tableProperty.types[this.tableProperty.values[index]] !== 'image') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    sortIcon(index) {
      return typeof this.sortList[this.tableProperty.values[index]] === 'undefined' ? 'unfold_more' : (this.sortList[this.tableProperty.values[index]] ? 'keyboard_arrow_up' : 'keyboard_arrow_down')
    }
    clickCheckbox(data, item, index) {
        data.forEach(element => {
            if (element.id !== item.id) {
                element.checked = false
            }
        });
        item.checked = !item.checked;
        if (item.checked) {
            this.result.emit(index)
        } else {
            this.result.emit(-1)
        }
    }
    setPaging(total, totalPages, currentPageSize) {
      this.total = total;
      this.totalPages = totalPages;
      this.currentPageSize = currentPageSize;
      setTimeout(() => {
        this.dataInOnePage = this.tableProperty.data;
      }, 100);
    }
    changePage(event: number) {
      this.currentPage = event || 1;
      this.parentChangePage.emit(this.currentPage);
    }
    changeItemPerPage(event: number) {
      this.currentPageSize = event || 10;
      this.currentPage = 1;
      this.parentChangeItemPerPage.emit(this.currentPageSize);
    }
}

