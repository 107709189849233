export class MultiImages {
    list: MultiImage[] = [];
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'image';
    }

    setValue(params: any) {
        if (params) {
            this.list = params ?  params.map((item) => {
                return new MultiImage( { url: item});
            }) : [];
        } else {
            this.setAllNull();
        }
    }
    setAllNull() {
        this.list = [];
    }
    createObjectRequestAPI() {
        let arr: any = [];
        if (this.list) {
            arr = this.list.filter(i => i.url).map((item) => {
                return item.createObjectRequestAPI();
            });
        }
        return arr;
      }
}
export class MultiImage {
    url: string;
    loading: boolean = false;

    constructor(
        param?: any
    ) {
        Object.assign(this, param);
    }

    static getType() {
        return 'image';
    }
    createObjectRequestAPI() {
        return this.url;
    }
}
