import { Injectable } from '@angular/core';
// import { CookieService } from 'angular2-cookie/core';
// import { SessionStorageService } from 'ng2-webstorage';

@Injectable()
export class CookieUtilsService {
    readonly NONCE = 'NONCE';
    readonly PASSWORD = 'PASSWORD';
    readonly USER_ID = 'USER_ID';
    readonly TOKEN = 'TOKEN';
    readonly USER_NAME = 'USER_NAME';
    readonly REMEMBER_ME = 'REMEMBER_ME';
    readonly RELOGIN = 'RELOGIN';
    readonly ISLOGGED = 'ISLOGGED';
    private cookieService: CookieUtilsService;
    private storage: any;

    constructor() {
        this.storage = new CookieUtilsService();
    }

    getNonce() {
        return this.storage.get(this.NONCE);
    }

    setNonce(value) {
        return this.storage.put(this.NONCE, value, this.getOption());
    }

    getUserId() {
        return this.storage.get(this.USER_ID);
    }

    setUserId(value) {
        return this.storage.put(this.USER_ID, value, this.getOption());
    }
    getToken() {
        return this.storage.get(this.TOKEN);
    }

    setToken(value) {
        return this.storage.put(this.TOKEN, value, this.getOption());
    }

    setUserName(value) {
        return this.storage.put(this.USER_NAME, value , this.getOption());
    }

    getRememberMe() {
        return this.storage.get(this.REMEMBER_ME);
    }

    setRememberMe(value) {
        return this.storage.put(this.REMEMBER_ME, value, this.getOption(true));
    }
    getRelogin() {
        return this.storage.get(this.RELOGIN);
    }

    setRelogin(value) {
        return this.storage.put(this.RELOGIN, value, this.getOption(true));
    }
    getIsLogged() {
        return this.storage.get(this.ISLOGGED);
    }

    setIsLogged(value) {
        return this.storage.put(this.ISLOGGED, value, this.getOption(true));
    }
    getPassword() {
        return this.storage.get(this.PASSWORD);
    }

    setPassword(value) {
        return this.storage.put(this.PASSWORD, value, this.getOption(true));
    }

    getOption(isSetExpired?:boolean) {
        if(this.getRememberMe() || isSetExpired) {
            let date = new Date();
            let month = date.getMonth();
            date.setMonth(month + 3);
            return {
                expires: date.toISOString()//'Wed, 21 Oct 2017 07:28:00 GMT'//
            }
        }
    }

    removeAll() {
        return this.storage.removeAll();
    }
}
