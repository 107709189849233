import { BaseModel } from "../../../shared/models/base.model";
import { BaseModelInterface } from "../../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";

export class ConfigItem  {
    @Length(10, 20)
    id: string;

    @Length(10, 20)
    key: string;

    @Length(10, 100)
    name: string;

    @Length(10, 20)
    value: string;

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'config';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.key = params.key || '';
            this.name = params.name || '';
            this.value = params.value || '';
        } else {
            this.setAllNull();
        }
    } 
    setAllNull() {
        this.id = '';
        this.key = '';
        this.name = '';
        this.value = '';
    }
    createObjectRequestAPI() {
        let object: any = {};
        object.id = this.id;
        object.key = this.key;
        object.name = this.name;
        object.value = this.value;
        return object;
    }
}
