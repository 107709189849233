import {DateToFormat} from '../../../shared/parse/date-to-format';
import {TransformTypeTransaction} from '../../../shared/pipe/transformType.pipe';
import {Constant} from '../../../shared/constant/constant';

export class ReportProperty {
  id: string;
  type: string;
  category: string;
  code: string;
  address: string;
  project: string;
  price: any;
  feePercent: string;
  fee: string;
  area: any;
  manager: string;
  createdDate: string;
  approvedDate: string;
  seller: string;
  tradeNumber: string;
  status: string;
  exchange: string;
  pos: string;

  constructor(
    param?: any
  ) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id;
      this.type = this.getCategoryName(params.propertyUnit.transactionType) || '';
      this.category = params.propertyUnit.category ? params.propertyUnit.category.name : '';
      this.code = params.propertyUnit.code || '';
      this.address = params.propertyUnit.address || '';
      this.project = params.propertyUnit.project ? params.propertyUnit.project.name : '';
      this.price = params.propertyUnit.price || '';
      this.feePercent = params.brokerFeePercent ? params.brokerFeePercent + '%' : '';
      this.fee = params.brokerFee || '';
      this.area = params.propertyUnit.area || '';
      this.manager = params.employeeTakeCare ? params.employeeTakeCare.name : '';
      this.createdDate = params.startDate ? DateToFormat.parseDate(params.startDate, 'dd/MM/yyyy') : '';
      this.approvedDate = params.approvedDate ? DateToFormat.parseDate(params.approvedDate, 'dd/MM/yyyy') : '';
      this.seller = params.mainCustomer ? (params.mainCustomer.personalInfo ? params.mainCustomer.personalInfo.name : '') : '';
      params.dealts = params.dealts ? params.dealts : [];
      this.tradeNumber = params.dealts.length > 0 ? params.dealts[0] : '';
      this.status = params.dealts.length > 0 ? 'Đã bán' : 'Chưa bán';
      this.exchange = params.san ?  params.san.name : '';
      this.pos = params.pos ? params.pos.name : '';
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    // set header
    this.id = '';
    this.type = '';
    this.category = '';
    this.code = '';
    this.address = '';
    this.project = '';
    this.price = '';
    this.feePercent = '';
    this.fee = '';
    this.area = '';
    this.manager = '';
    this.createdDate = '';
    this.approvedDate = '';
    this.seller = '';
    this.tradeNumber = '';
    this.status = '';
    this.exchange = '';
    this.pos = '';
  }

  getHeaders(index?) {
    if (index === 1) {
      return [
        { header: 'Loại nhu cầu', key: 'type', width: 20 },
        { header: 'Loại hình BĐS', key: 'category', width: 20 },
        { header: 'Mã sản phẩm', key: 'code', width: 20 },
        { header: 'Địa chỉ hiển thị', key: 'address', width: 30 },
        { header: 'Dự án', key: 'project', width: 30 },
        { header: 'Giá', key: 'price', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Tỉ lệ phí dịch vụ', key: 'feePercent', width: 20 },
        { header: 'Phí dịch vụ', key: 'fee', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Diện tích (m²)', key: 'area', width: 20 },
        { header: 'Người quản lý hợp đồng', key: 'manager', width: 20 },
        { header: 'Ngày tạo', key: 'createdDate', width: 13 },
        { header: 'Ngày duyệt', key: 'approvedDate', width: 13 },
        { header: 'Người bán', key: 'seller', width: 30 },
        { header: 'Số giao dịch', key: 'tradeNumber', width: 20 },
        { header: 'Trạng thái', key: 'status', width: 20 },
        { header: 'Sàn', key: 'exchange', width: 30 },
        { header: 'TTGD', key: 'pos', width: 30 },
      ];
    }
    return [
      { header: 'Loại nhu cầu', key: 'type', width: 20 },
      { header: 'Loại hình BĐS', key: 'category', width: 20 },
      { header: 'Mã sản phẩm', key: 'code', width: 20 },
      { header: 'Địa chỉ hiển thị', key: 'address', width: 30 },
      { header: 'Dự án', key: 'project', width: 30 },
      { header: 'Giá', key: 'price', width: 20, style: { numFmt: '#,##0' } },
      { header: 'Tỉ lệ phí dịch vụ', key: 'feePercent', width: 20 },
      { header: 'Phí dịch vụ', key: 'fee', width: 20, style: { numFmt: '#,##0' } },
      { header: 'Diện tích (m²)', key: 'area', width: 20 },
      { header: 'Người quản lý hợp đồng', key: 'manager', width: 20 },
      { header: 'Ngày tạo', key: 'createdDate', width: 13 },
      // { header: 'Ngày duyệt', key: 'approvedDate', width: 13 },
      { header: 'Người bán', key: 'seller', width: 30 },
      // { header: 'Số giao dịch', key: 'tradeNumber', width: 20 },
      { header: 'Trạng thái', key: 'status', width: 20 },
      { header: 'Sàn', key: 'exchange', width: 30 },
      { header: 'TTGD', key: 'pos', width: 30 },
    ];
  }
  getCategoryName(category: string): string {
    switch (category) {
      case Constant.BUY_TRANSACTION: {
        return 'Mua';
      }
      case Constant.RENT_TRANSACTION: {
        return 'Cần thuê';
      }
      case Constant.SELL_TRANSACTION: {
        return 'Bán';
      }
      case Constant.LEASE_TRANSACTION: {
        return 'Cho thuê';
      }
      default: {
        return '';
      }
    }
  }
  transactionTypeMapping(text: string) {
    let result = '';
    if (text.toUpperCase() === 'DONE') {
      result = 'Đã bán';
    } else {
      result = 'Chưa bán';
    }
    return result;
  }
}
