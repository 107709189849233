import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
    selector: 'app-health-decleration',
    templateUrl: './health-decleration.component.html',
    styleUrls: ['./health-decleration.component.scss']
})

export class HealthDeclerationComponent implements OnInit {

    @Input() listSurvey: any[] = [];
    @Input() isShowUserData: boolean = false
    @Input() userData: any = {}

    constructor() {

    }

    ngOnInit() {
       
    }

}