import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TextareaPopup } from '../textarea-popup/textarea-popup';

@Component({
  selector: 'app-show-json',
  templateUrl: './show-json.component.html',
  styleUrls: ['./show-json.component.scss']
})
export class ShowJsonComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShowJsonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    jsonString = ''

  ngOnInit() {
    this.jsonString = this.data.text
  }

}
