import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { Constant } from 'app/shared/constant/constant';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'app/shared/components/upload-images/image.model';
import { DialogImageCropperComponent } from './dialog-image-cropper.component';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarUploaderComponent),
      multi: true,
    }
  ]
})
export class AvatarUploaderComponent implements ControlValueAccessor {
  @Input() isDisabled: boolean = false;
  image?: Images;
  isLoading: boolean = false;
  fileExtensions: string[] = [];
  get hasImage() {
    return this.image && this.image.avatar ? true : false;
    // return (this.image && this.image.url) || this.imageUrl;
  }

  constructor(
    public uploadFileService: UploadFileService,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.fileExtensions = ['jpeg', 'jpg', 'png', 'svg', 'heic'];
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0] as File;
    // Validate image
    if (!this.validateIfImage(selectedFile)) {
      this.toastr.error('Lỗi!', 'Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại .png, .jpg hoặc .svg, tối đa 5MB.');
      return;
    }
    const dialogRef = this.dialog.open(DialogImageCropperComponent, {
      width: '600px',
      data: { file: selectedFile }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        const newFilename = new Date().toISOString() + '.png';
        const croppedImage = new File([result.data], newFilename);
        this.uploadAvatar(croppedImage);
      }
    });

  }

  uploadAvatar(file: File) {
    // Upload image
    this.isLoading = true;
    return this.uploadFileService.uploadFile([file])
      .then((res: any) => {
        const data = res.json();
        if (data && data[0]) {
          // Emit image url
          setTimeout(() => {  // Amazon s3 file return 403 right after file uploaded
            this.image.avatar = data[0].url;
            this.onChange(this.image);
            this.isLoading = false;
          }, 1000);
          // Emit image
          // this.image = new Image(data[0]);
          // this.imageChange.emit(this.image);
        } else {
          this.isLoading = false;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error('Lỗi!', error);
      });
  }

  clearImage() {
    // Emit image url
    this.image.avatar = null;
    this.onChange(this.image);
  }

  validateIfImage(file: File): boolean {
    if (!file) {
      return false;
    }
    // check max size image 5MB
    if (file.size > Constant.MAX_SIZE_IMAGE) {
      return false;
    }
    return this.fileExtensions.includes(file.name.split('.').pop().toLocaleLowerCase());
  }

  // ControlValueAccessor Implementation
  public onChange = (newVal: Images) => { };
  public onTouched = (_?: any) => { };

  public reset(data: Images) {
    this.image = data;
  }

  public markAsDirty() {
    // Nothing to do
  }

  public getValue() {
    return this.image;
  }

  writeValue(obj: any): void {
    this.image = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
