import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppHeaderComponent implements OnInit {

  public settings: Settings;
  constructor(
    public appSettings:AppSettings,
    public router: Router,
    ){
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }
  gotoHome(){
    this.router.navigateByUrl('/login')
  }

  onLogin(){
    this.router.navigateByUrl('/login');
}
}
