import { DirectivesModule } from 'app/shared/directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MatBadgeModule,
} from '@angular/material';
import { AgmCoreModule } from '@agm/core';
import { NgxViewerModule } from 'ngx-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { DatePickerModule } from 'app/shared/components/date-picker/date-picker.module';
import {TimePickerModule} from 'app/shared/components/v3/time-picker/time-picker.module';
import { SelectModule } from 'app/shared/components/select/select.module';
import { CheckboxGroupModule } from 'app/shared/components/checkbox-group/checkbox-group.module';
import { RadioGroupModule } from 'app/shared/components/radio-group/radio-group.module';
import { UploadImageProductModule } from 'app/shared/components/upload-image-product/upload-image-product.module';
import { StepProgressBarModule } from 'app/shared/components/step-progress-bar/step-progress-bar.module';

import { ConfigurationService } from './services/configuration.service';
import { StorageService } from './services/storage.service';
import { DataService } from './services/data.service';

import { BaseComponent } from './components/base.component';
import { CommonService } from './services/common/common.service';
import { StompConfig, StompService } from '@stomp/ng2-stompjs';
import { environment } from '../../environments/environment';
import { WebsocketService } from './components/websocket.service';
import { UserService } from './services/common/user.service';
import { TextMaskModule } from "angular2-text-mask";
import { HierarchyComponent } from '../pages/common/hierarchy/hierarchy.component';
import { UserAuthorityDirective } from './services/common/user-authority.directive';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { PagingComponent } from './components/paging';
import { RouterModule } from '@angular/router';
import { DynamicAttributeComponent } from './components/dynamic-attribute/dynamic-attribute.component';
import {TransformMoneyPipe, TransformMoneyTextPipe, TransformCustomDatePipe, TransformPhonePipe, SafeHtmlPipe, TransformEmailPipe, TransformTxnStatus, BasicFilterPipe} from './pipe';
import { UploadFileService } from './services/common/upload-file.service';
import { UploadImagesComponent } from './components/upload-images/upload-images.component';
import { DialogService } from './services/dialog.service';
import { GroupsAttributeComponent } from './components/groups-attribute/groups-attribute.component';
import { ContainerTagComponent } from './components/container-tag/container-tag.component';
import { InputAddressComponent } from './components/input-address/input-address.component';
import { ComponentService } from './components/component.service';
import { InputDateComponent } from './components/input-date/input-date.component';
import { TransformTypeTransaction } from './pipe/transformType.pipe';
import { TransformReceiptTypeTransaction } from './pipe/transformReceiptType.pipe';
import { TransformSummaryText } from './pipe/transformSummaryText.pipe';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { PhoneMaskDirective } from './components/phone-mask/phone-mask.directive';
import { DigitOnlyDirective } from './../theme/directives/digit-only/digit-only.directive';
import { IpadBreakpointsProvider, IpadLayoutDirective } from 'app/shared/break-points/ipad-breakpoints';
import { MobileBreakpointsProvider, MobileLayoutDirective } from 'app/shared/break-points/mobile-breakpoints';
import { Ng5SliderModule } from 'ng5-slider';
import { CustomMaxDirective } from 'app/theme/directives/number-validation/numbers-max';
import { CustomMinDirective } from 'app/theme/directives/number-validation/numbers-min';
import { TransformDecimal } from './pipe/transformDecimal.pipe';
import { CustomExcelComponent } from './components/custom-excel/custom-excel.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog';
import { DialogImageCropperComponent } from './components/avatar-uploader';
import { ImageUploaderComponent } from './components/image-uploader';
import { FileUploaderComponent } from './components/file-uploader';
import { AvatarUploaderComponent } from './components/avatar-uploader';
import { InputInterestedAreaComponent } from './components/input-interested-area';
import { InputInterestedAreaAppSelectComponent } from 'app/shared/components/input-interested-area-app-select';
import { GridPopupComponent } from './components/grid-popup/grid-popup.component';
import { CdkTableModule } from '@angular/cdk/table';
import { SearchPipe } from './pipe/transformSearch.pipe';
import { ButtonCallComponent } from './components/button-call/button-call.component';
import { ButtonMessageComponent } from './components/button-message/button-message.component';
import { ButtonV3Component } from './components/v3/button-v3/button-v3.component';
import { DialogMessageImageComponent } from './components/dialog-message-image/dialog-message-image.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { ButtonChipComponent } from './components/v3/button-chip/button-chip.component';
// Form input
import {
  FormInputCustomerComponent,
  FormInputPropertyComponent,
  FormInputContractComponent,
  FormInputDemandComponent,
  FormInputAddressComponent,
  FormInputLeadInfoComponent,
  FormInputLeadInNeedComponent,
  FormLeadHistoryCallComponent,
  FormInputDynamicComponent,
  FormInputCallHistoryComponent, FormInputLeadCareInfoComponent
} from './components/app-form-input';
// Paging
import {
  AppPagingComponent
} from './components/app-paging';
import { SidenavService } from './services/common/sidenav.service';
import { SearchPosPipe } from './pipe/transformSearchPos.pipe';
import { PopupDealChecker } from '../pages/project/event-sale/popup-deal-checker/popup-deal-checker';
import { SocketIoService } from './services/common/socket-io.service';
import { ImageProjectUploaderComponent } from './components/image-project-uploader';
import { FileProjectUploaderComponent } from './components/file-project-uploader';
import { UploadProjectImagesComponent } from './components/upload-project-images/upload-project-images.component';
import { SortDsghPipe } from './pipe/sort-dsgh.pipe';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { PopupConfirmRegister } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-register/popup-confirm-register.component';
import { PopupConfirmReturn } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-return/popup-confirm-register.component';
import { TemplateRendererComponent } from 'app/pages/project/event-sale/list/template-renderer/template-renderer.component';
import { PopupUpdateFeatureComponent } from 'app/pages/project/event-sale/popup-update-feature/popup-update-feature.component';
import { PopupConfirmQueue } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-queue/popup-confirm-queue.component';
import { ConfirmErpIndicatorPopup } from './components/confirm-erp-indicator-popup/confirm-erp-indicator-popup';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormActionComponent } from './components/app-form-action/form-action.component';
import { SearchFilterComponent } from './components/app-search-filter/search-filter.component';
import { ConfirmRecalculatePeriodComponent } from './components/confirm-recalculate-period/confirm-recalculate-period.component';
import { PopupConfirmWorkflowComponent } from './components/popup-confirm-workflow/popup-confirm-workflow.component';
import { RegisterTrialDialogComponent } from 'app/pages/login/register-trial-dialog/register-trial-dialog.component';
import { ImageUploaderContentBuilderComponent } from './components/image-uploader-contentbuilder';
import { CategoryService } from 'app/pages/category/category.service';
import { ProjectService } from 'app/pages/project/project.service';
import { PopupConfirmPhoneComponent } from './components/popup-confirm-phone/popup-confirm-phone.component';
import { FilterPropertyComponent } from './components/app-filter-property/filter-property.component';
import { TextareaPopup } from './components/textarea-popup/textarea-popup';
import { UploadMultiImagesComponent } from './components/upload-multi-images/upload-multi-images.component';
import { TransformUnitStatus } from './pipe/transformUnitStatus.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { CarouselImageV2Component } from './components/carousel-image-v2/carousel-image-v2.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import {FormInputDynamicV2Component} from './components/app-form-input/form-input-dynamic-v2/form-input-dynamic-v2.component';
import { NotificationCompletedComponent } from './components/notification-completed/notification-completed.component';
import { MemberFilterPipe } from './pipe/transform-member-filter.pipe';
import { HealthDeclerationComponent } from './components/health-decleration/health-decleration.component';
import {ProductPagingV2Component} from './components/product-paging-v2/product-paging-v2.component';
import {EmployeeCardComponent} from './components/employee-card/employee-card.component';
import { ESaleKitFileUploaderComponent } from './components/esale-kit-file-uploader/esale-kit-file-uploader.component';
import { AgGridModule } from 'ag-grid-angular';
import {SearchFilterV2Component} from './components/app-search-filter-v2/search-filter.component';
import {GroupMemberFilterPipe} from './pipe/transform-group-member-filter.pipe';
import { NoticationPreviewPopupComponent } from './components/notication-preview-popup/notication-preview-popup.component';
import { ConfirmErpCustomerPopup } from './components/confirm-erp-customer-popup/confirm-erp-customer-popup';
import { RigisterTrialSuccessDialogComponent } from 'app/pages/login/rigister-trial-success-dialog/rigister-trial-success-dialog.component';
import { UpgradeAccountTrial } from 'app/pages/user-trial/upgrade-account-trial/upgrade-account-trial.component';
import { UpdateAccountComfirmPopupComponent } from './components/update-account-comfirm-popup/update-account-comfirm-popup.component';
import { CheckboxGroupV3Module } from './components/v3/checkbox-group-v3/checkbox-group-v3.module';
import { TabComponentV3Component } from './components/v3/tab-component-v3/tab-component-v3.component';
import { UpdateAccountPopupComponent } from './components/update-account-popup/update-account-popup.component';
import { AppPagingV3Component } from './components/v3/app-paging-v3/app-paging-v3.component';
import { AddHolidayComponent } from 'app/pages/checkin-checkout/add-holiday-popup/add-holiday-popup.component';
import { ReportCheckinCheckoutPopupComponent } from 'app/pages/checkin-checkout/report-checkin-checkout-popup/report-checkin-checkout-popup.component';
import { ReportCheckinCheckoutComponent } from 'app/pages/checkin-checkout/report-checkin-checkout/report-checkin-checkout.component';
import { ShowJsonComponent } from './components/show-json/show-json.component';
import { PhoneAuthenRegisterComponent } from './components/authenticate-register-trial/phone-authen/phone-authen.component';
import { AuthenSuccessComponent } from './components/authenticate-register-trial/authen-success/authen-success.component';
import { SurveysConfigComponent } from './components/app-surveys/app-surveys-config.component';
import { SurveysFeedbackComponent } from './components/app-surveys-feedback/app-surveys-feedback.component';
import { DuplicateAuthenticate } from './components/authenticate-register-trial/check-duplicate-authen/check-duplicate-authen.component';
import { PopupJsonViewerComponent } from './components/popup-json-viewer/popup-json-viewer.component';
import { DialogMessageNoticeComponent } from './components/dialog-message-notice/dialog-message-notice.component';
import { PopupHtmlInfo } from './components/popup-html-info/popup-html-info.component';
import { SettingEmailDialogComponent } from './components/setting-email/setting-email-dialog.component';
import { SettingSmsDialogComponent } from './components/setting-sms/setting-sms-dialog.component';





const stompConfig: StompConfig = {
  // Which server?
  url: environment.rabbitmq.ws.url,

  // Headers
  // Typical keys: login, passcode, host
  headers: {
    login: environment.rabbitmq.ws.login,
    passcode: environment.rabbitmq.ws.passcode
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeat_in: 0, // Typical value 0 - disabled
  heartbeat_out: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnect_delay: 5000,

  // Will log diagnostics on console
  debug: false
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TextMaskModule,
    RouterModule,
    // mat component
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    Ng5SliderModule,
    CdkTableModule,
    AgmCoreModule,
    NgxViewerModule,
    ImageCropperModule,
    DatePickerModule,
    TimePickerModule,
    SelectModule,
    CheckboxGroupModule,

    RadioGroupModule,
    UploadImageProductModule,
    StepProgressBarModule,
    NgbTimepickerModule,
    DirectivesModule,
    NgxUpperCaseDirectiveModule,
    NgSelectModule,
    DragScrollModule,
    AgGridModule.withComponents([
      TemplateRendererComponent,
    ]),
    CheckboxGroupV3Module
  ],
  declarations: [
    HierarchyComponent,
    UserAuthorityDirective,
    CustomInputComponent,
    CustomFormComponent,
    CustomExcelComponent,
    CustomTableComponent,
    DynamicAttributeComponent,
    GroupsAttributeComponent,
    ContainerTagComponent,
    TransformCustomDatePipe,
    TransformMoneyPipe,
    TransformMoneyTextPipe,
    TransformDecimal,
    TransformPhonePipe,
    TransformEmailPipe,
    MemberFilterPipe,
    SafeHtmlPipe,
    TransformSummaryText,
    TransformTypeTransaction,
    TransformReceiptTypeTransaction,
    PagingComponent,
    UploadImagesComponent,
    InputAddressComponent,
    InputDateComponent,
    TypeaheadComponent,
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    ImageUploaderComponent,
    FileUploaderComponent,
    AvatarUploaderComponent,
    InputInterestedAreaComponent,
    InputInterestedAreaAppSelectComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    CustomMaxDirective,
    CustomMinDirective,
    IpadLayoutDirective,
    MobileLayoutDirective,
    GridPopupComponent,
    SearchPipe,
    SearchPosPipe,
    SortDsghPipe,
    ButtonCallComponent,
    DialogMessageImageComponent,
    DialogMessageNoticeComponent,
    DialogConfirmComponent,
    FormInputCustomerComponent,
    FormInputPropertyComponent,
    FormInputContractComponent,
    FormInputDemandComponent,
    AppPagingComponent,
    ButtonMessageComponent,
    ButtonV3Component,
    ButtonChipComponent,
    PopupDealChecker,
    ImageProjectUploaderComponent,
    FileProjectUploaderComponent,
    UploadProjectImagesComponent,
    FormInputAddressComponent,
    SortDsghPipe,
    PopupConfirmRegister,
    PopupConfirmQueue,
    PopupConfirmReturn,
    FormInputLeadInfoComponent,
    FormInputLeadCareInfoComponent,
    FormInputLeadInNeedComponent,
    FormInputCallHistoryComponent,
    FormLeadHistoryCallComponent,
    FormInputDynamicComponent,
    FormInputDynamicV2Component,
    TransformTxnStatus,
    TemplateRendererComponent,
    PopupUpdateFeatureComponent,
    ConfirmErpIndicatorPopup,
    FormActionComponent,
    SearchFilterComponent,
    ConfirmRecalculatePeriodComponent,
    PopupConfirmWorkflowComponent,
    RegisterTrialDialogComponent,
    ImageUploaderContentBuilderComponent,
    PopupConfirmPhoneComponent,
    FilterPropertyComponent,
    TextareaPopup,
    ShowJsonComponent,
    PopupHtmlInfo,
    UploadMultiImagesComponent,
    TransformUnitStatus,
    LoadingComponent,
    CarouselImageV2Component,
    BasicFilterPipe,
    NotificationCompletedComponent,
    ConfirmErpCustomerPopup,
    SearchFilterV2Component,
    GroupMemberFilterPipe,
    ProductPagingV2Component,
    EmployeeCardComponent,
    HealthDeclerationComponent,
    ESaleKitFileUploaderComponent,
    NoticationPreviewPopupComponent,
    NoticationPreviewPopupComponent,
    RigisterTrialSuccessDialogComponent,
    UpgradeAccountTrial,
    UpdateAccountPopupComponent,
    UpdateAccountComfirmPopupComponent,
    // UI V3
    TabComponentV3Component,
    // UI V3
    AppPagingV3Component,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    PhoneAuthenRegisterComponent,
    AuthenSuccessComponent,
    SurveysConfigComponent,
    SurveysFeedbackComponent,
    DuplicateAuthenticate,
    PopupJsonViewerComponent,
    SettingEmailDialogComponent,
    SettingSmsDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    DialogMessageImageComponent,
    DialogMessageNoticeComponent,
    DialogConfirmComponent,
    GridPopupComponent,
    PopupDealChecker,
    PopupConfirmRegister,
    PopupConfirmQueue,
    PopupConfirmReturn,
    PopupUpdateFeatureComponent,
    ConfirmErpIndicatorPopup,
    ConfirmRecalculatePeriodComponent,
    PopupConfirmWorkflowComponent,
    RegisterTrialDialogComponent,
    ImageUploaderContentBuilderComponent,
    TextareaPopup,
    ConfirmErpCustomerPopup,
    NoticationPreviewPopupComponent,
    UpgradeAccountTrial,
    UpdateAccountPopupComponent,
    UpdateAccountComfirmPopupComponent,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    ShowJsonComponent,
    PopupHtmlInfo,
    PhoneAuthenRegisterComponent,
    AuthenSuccessComponent,
    DuplicateAuthenticate,
    PopupJsonViewerComponent,
    SettingEmailDialogComponent,
    SettingSmsDialogComponent
  ],
  exports: [
    FlexLayoutModule,
    TextMaskModule,
    RouterModule,
    // mat component
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    NgbTimepickerModule,
    HierarchyComponent,
    UserAuthorityDirective,
    CustomInputComponent,
    CustomFormComponent,
    CustomTableComponent,
    CustomExcelComponent,
    DynamicAttributeComponent,
    GroupsAttributeComponent,
    ContainerTagComponent,
    PagingComponent,
    TransformMoneyPipe,
    TransformMoneyTextPipe,
    TransformDecimal,
    TransformPhonePipe,
    TransformEmailPipe,
    MemberFilterPipe,
    SafeHtmlPipe,
    TransformCustomDatePipe,
    TransformTypeTransaction,
    TransformReceiptTypeTransaction,
    TransformSummaryText,
    UploadImagesComponent,
    InputAddressComponent,
    InputDateComponent,
    TypeaheadComponent,
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    ImageUploaderComponent,
    FileUploaderComponent,
    AvatarUploaderComponent,
    InputInterestedAreaComponent,
    InputInterestedAreaAppSelectComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    CustomMaxDirective,
    CustomMinDirective,
    IpadLayoutDirective,
    MobileLayoutDirective,
    Ng5SliderModule,
    GridPopupComponent,
    AgmCoreModule,
    NgxViewerModule,
    DirectivesModule,
    ImageCropperModule,
    DatePickerModule,
    TimePickerModule,
    SelectModule,
    CheckboxGroupModule,
    RadioGroupModule,
    UploadImageProductModule,
    StepProgressBarModule,
    ButtonCallComponent,
    SearchPipe,
    SearchPosPipe,
    SortDsghPipe,
    ImageCropperModule,
    DialogMessageImageComponent,
    DialogMessageNoticeComponent,
    DialogConfirmComponent,
    FormInputCustomerComponent,
    FormInputPropertyComponent,
    FormInputContractComponent,
    FormInputDemandComponent,
    FormInputLeadInfoComponent,
    FormInputLeadCareInfoComponent,
    FormInputLeadInNeedComponent,
    FormInputCallHistoryComponent,
    FormLeadHistoryCallComponent,
    FormInputDynamicComponent,
    FormInputDynamicV2Component,
    AppPagingComponent,
    ButtonMessageComponent,
    ButtonV3Component,
    ButtonChipComponent,
    ImageProjectUploaderComponent,
    FileProjectUploaderComponent,
    UploadProjectImagesComponent,
    FormInputAddressComponent,
    NgxUpperCaseDirectiveModule,
    TransformTxnStatus,
    TemplateRendererComponent,
    PopupUpdateFeatureComponent,
    FormActionComponent,
    SearchFilterComponent,
    RegisterTrialDialogComponent,
    UpgradeAccountTrial,
    ImageUploaderContentBuilderComponent,
    FilterPropertyComponent,
    TransformUnitStatus,
    UploadMultiImagesComponent,
    LoadingComponent,
    CarouselImageV2Component,
    BasicFilterPipe,
    NotificationCompletedComponent,
    SearchFilterV2Component,
    GroupMemberFilterPipe,
    ProductPagingV2Component,
    EmployeeCardComponent,
    HealthDeclerationComponent,
    ESaleKitFileUploaderComponent,
    AgGridModule,
    RigisterTrialSuccessDialogComponent,
    UpdateAccountPopupComponent,
    UpdateAccountComfirmPopupComponent,
    TabComponentV3Component,
    CheckboxGroupV3Module,
    AppPagingV3Component,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    PhoneAuthenRegisterComponent,
    AuthenSuccessComponent,
    SurveysConfigComponent,
    SurveysFeedbackComponent,
    DuplicateAuthenticate,
    PopupJsonViewerComponent,
    SettingEmailDialogComponent,
    SettingSmsDialogComponent
  ],
  providers: [
    ComponentService,
    UserService,
    ConfigurationService,
    StorageService,
    DataService,
    CommonService,
    DialogService,
    StompService,
    UploadFileService,
    {
      provide: StompConfig,
      useValue: stompConfig
    },
    IpadBreakpointsProvider,
    MobileBreakpointsProvider,
    SidenavService,
    SocketIoService,
    CategoryService,
    ProjectService,
  ]
})
export class SharedModule { }
