import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  constructor(
    private toastService: ToastrService
  ) { }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastService.error(message, title, override);
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastService.success(message, title, override);
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastService.info(message, title, override);
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastService.warning(message, title, override);
  }

  show(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastService.show(message, title, override);
  }

}
