import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constant } from 'app/shared/constant/constant';
import { Customer, IdentityInfo, PersonalInfo, InfoCustomer, BankInfo } from 'app/pages/customer/customer.model';
import { Address } from 'app/shared/models/address.model';
import { StorageService } from 'app/shared/services';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import * as moment from 'moment';
import { BankService, IBankDropdown } from '../../../services/bank.service';
import { LeadModel } from 'app/pages/lead/lead.model';
const VIET_NAM = 'Việt Nam';


@Component({
  selector: 'app-form-input-lead-info',
  templateUrl: './form-input-lead-info.component.html',
  styleUrls: ['./form-input-lead-info.component.scss']
})
export class FormInputLeadInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() data: LeadModel;
  @Output() dataChange: EventEmitter<LeadModel> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();
  private validationChanged$: Subject<boolean> = new Subject<boolean>();

  public Constant = Constant;
  public mainForm: FormGroup = null;

  constructor(
    public formBuilder: FormBuilder,
    public storageService: StorageService) {
    this.validationChanged$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe((input?: boolean) => this.updateFormValidation());
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    this.validationChanged$.next();
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        // Thông tin cá nhân
        name: this.data.name,
        phone: this.data.phone,
        email: this.data.email,
        updatedName: this.data.updatedName,
        updatedPhone: this.data.updatedPhone,
        updatedEmail: this.data.updatedEmail,
        updatedProfileUrl: this.data.updatedProfileUrl,
        hasSendSmsAfterSurvey: false,
      });
    } else {
      // Init form
      this.mainForm = this.formBuilder.group({
        // Thông tin cá nhân
        name: [{ value: this.data.name, disabled: this.disabled }],
        phone: [{ value: this.data.phone, disabled: this.disabled }],
        email: [{ value: this.data.email, disabled: this.disabled }, Validators.email],
        updatedName: [{ value: this.data.updatedName, disabled: this.disabled }],
        updatedPhone: [{ value: this.data.updatedPhone, disabled: this.disabled }],
        updatedEmail: [{ value: this.data.updatedEmail, disabled: this.disabled }, Validators.email],
        updatedProfileUrl: [{ value: this.data.updatedProfileUrl, disabled: this.disabled }],
        hasSendSmsAfterSurvey: [{value: false}]
      });
    }

    this.mainForm.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {
      // Patch data
      this.data.name = res.name;
      this.data.phone = res.phone;
      this.data.email = res.email;
      this.data.updatedName = res.updatedName;
      this.data.updatedPhone = res.updatedPhone;
      this.data.updatedEmail = res.updatedEmail;
      this.data.updatedProfileUrl = res.updatedProfileUrl;
      this.data.hasSendSmsAfterSurvey = res.hasSendSmsAfterSurvey;
      // Emit new data
      this.dataChange.emit(this.data);
      // Emit form valid state
      this.validChange.emit(this.mainForm.valid);
    });
  }

  // PUBLIC
  public validateForm(): boolean {
    if (!this.mainForm) {
      return false;
    }
    this.markAsTouched(this.mainForm);
    return this.mainForm.disabled || this.mainForm.valid;
  }

  updateFormValidation() {
    if (!this.mainForm) {
      return;
    }
    this.mainForm.get('email').setValidators(Validators.email);
    this.markAsTouched(this.mainForm);
  }


  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }
  getDisplaySource(source: string) {
    if (!source) {return ''; };
    return Constant.LEAD_SOURCE[source];
  }
}
