import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Constant } from "app/shared/constant/constant";
import moment = require("moment");

export interface ConfirmData {
    title: string;
    message: string;
    textCancel: string;
    textOk: string;
    isChecked: boolean;
    execute: boolean;
}

@Component({
    selector: "confirm-erp-customer-popup",
    templateUrl: "./confirm-erp-customer-popup.html",
    styleUrls: ["./confirm-erp-customer-popup.scss"],
})
export class ConfirmErpCustomerPopup implements OnInit {
    mainForm: FormGroup = null;
    constructor(
        public dialogRef: MatDialogRef<ConfirmErpCustomerPopup>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {}

    ngOnInit() {
        this.data.isChecked = true;
        this.initForm();
    }
    getFormControl(name: string) {
        return this.mainForm.get(name);
    }
    initForm() {
        this.mainForm = this.formBuilder.group({
            createddate: ["", Validators.required],
        });
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    execute() {
        this.dialogRef.close({
            isChecked: true,
            execute: true,
            createddate: this.getFormControl("createddate").value ? moment(this.getFormControl("createddate").value).format('YYYY-MM-DDTHH:mm:SS') : '',
        });
    }
}
