import { IDecode } from '../i-decode';
import { CBaseDate } from '../c-base-date';
import { CPOS } from '../c-pos';
import { ExploitStatusEnum } from 'app/shared/enum/exploitStatus.enum';

export class CTicket extends CBaseDate implements IDecode<CTicket> {
  public id: string;
  public active: boolean;
  public code: string;
  public status: string;
  public lifeCycleStatus: string;
  public processBy: string;
  public timeOut: string;
  public description: string;
  public source: string;
  public processedHistory: any[];
  public phone: string;
  public email: string;
  public name: string;
  public address: string;
  public customerId: string;
  public type: string;
  public pos: CPOS;
  public t0: string;
  public t1: string;
  public t2: string;
  public t3: string;
  public timezoneclient: string;
  public processedDate: string;
  public isCalled: boolean;
  public advisingType: string;
  public exploitStatus: ExploitStatusEnum;

  constructor(params: Partial<CTicket> = {}) {
    super(params);
    this.copy(params);
  }

  static copyList(params: Partial<CTicket>[] = []): CTicket[] {
    return params.map(el => new CTicket().copy(el));
  }

  copy(params: Partial<CTicket> = {}): CTicket {
    super.copy(params);
    this.id = params.id || null;
    this.active = params.active || null;
    this.code = params.code || null;
    this.status = params.status || null;
    this.lifeCycleStatus = params.lifeCycleStatus || null;
    this.processBy = params.processBy || null;
    this.timeOut = params.timeOut || null;
    this.description = params.description || null;
    this.source = params.source || null;
    this.processedHistory = params.processedHistory || [];
    this.phone = params.phone || null;
    this.email = params.email || null;
    this.name = params.name || null;
    this.address = params.address || null;
    this.customerId = params.customerId || null;
    this.type = params.type || null;
    this.pos = params.pos ? new CPOS(params.pos) : new CPOS();
    this.t0 = params.t0 || null;
    this.t1 = params.t1 || null;
    this.t2 = params.t2 || null;
    this.t3 = params.t3 || null;
    this.timezoneclient = params.timezoneclient || null;
    this.processedDate = params.processedDate || null;
    this.isCalled = params.isCalled || false;
    this.advisingType = params.advisingType || null;
    return this;
  }

  decode(paramsApi: any): CTicket {
    return new CTicket(paramsApi);
  }

  decodeList(paramsApi: any[]): CTicket[] {
    return paramsApi.map(el => new CTicket().decode(el));
  }
}
