import { BaseModel } from 'app/shared/models/base.model';
import { BaseModelInterface } from 'app/shared/models/base.model.interface';
import { ValidateIf, IsNotEmpty, Length } from 'class-validator';
// Models
import { GeoLocation } from 'app/pages/property/location.model';
import { Images } from 'app/shared/components/upload-images/image.model';
import { FileModel } from 'app/shared/components/file-uploader';
import _ = require('lodash');
import {ProjectSetting} from './project-setting.model';
import {MultiImages} from '../../shared/components/upload-multi-images/image.model';
const DEFAULT_UNIT = '(triệu/m²)';

export class Project extends BaseModel implements BaseModelInterface {

  @ValidateIf(o => o.otherProperty === '')
  @IsNotEmpty()
  @Length(10, 20)
  id: string;
  active: boolean;
  location: GeoLocation;
  locationAddress: string;
  type: string;
  name: string;
  namePublic: string; // Tên công khai của dự án
  status: string;
  propertyTotal: number;
  unitPrice: string;
  priceFrom: number;
  priceTo: number;
  bankName: string;
  bankNumber: number;
  banks: any;
  investor: any;
  refUrl: string;
  emailFrom: string;
  address: string;
  hotline: string;
  apartmentsTotal: number;
  constructionArea: number;
  projectArea: number;
  parkArea: number;
  constructionFloorArea: number;
  trafficArea: number;
  greenAreaOnBuilding: number;
  constructionPercent: number;
  greenPercent: number;
  blocksTotal: number;
  floorsTotal: number;
  situation: string;
  isPublic: boolean;
  isDxAgent: boolean;
  isPublicView: boolean; // Công khai theo profile TVV
  sendMailApprovedTransaction: boolean;
  accountantDeposit: boolean;
  careInvestor: any;
  careTotalBlock: string;
  careTotalFloor: string;
  careTotalUnit: string;
  innerUtility: string[];
  outstandingAdv: string[];
  apartType: string[];
  isHot: boolean;
  imageUrl: string;
  projectGroundImageUrl: string;
  utilitiesImageUrl: string;
  images: Images;
  province: string;
  district: string;
  transactionType: string;
  block: string;
  zone: string;
  floor: string;
  plot: string;
  street: string;
  ownership: string;
  landTotal: number;
  country: string;
  ward: string;
  city: string;
  files: FileModel[];
  day: string;
  liveUrl: string;
  chatGroupId: string;
  code: string;
  currentStatus: string;
  designUnit: string;
  constructionUnit: string;
  guideVideoUrl: string;
  projectVideoUrl: string;
  hasUnitExcelTemplate: boolean;
  hasUniqueAddress: boolean;
  guideImages: FileModel[];
  groundImages: FileModel[];
  employeeSale: any;
  villasTotal: number;
  projectLeader: IEmployeeModel;
  emailReplyTo: IEmployeeModel;
  employeeSales: IEmployeeModel[];

  adviceType: number;
  isRetailCustomer: boolean;
  isManualReceipt: boolean;
  requireDocument: any[];
  blocks: any;
  accountants: any;
  customerServices: any;
  marketingEmps: any;
  emailTransaction: any;
  emailLiquidate: any;
  emailCheckUpdateUnits: any;
  setting: ProjectSetting;
  isPin: boolean;

  esalekit: boolean;
  urlEsalekit: string;
  isSyncTicket: boolean;
  isSyncReceipt: boolean;
  surveys: any;
  emailTemplate: any;
  unitConfig: any[];
  isSyncCRMInvestor: boolean;
  allowOnlinePayment: boolean;
  allowSubPayment: boolean;
  onlinePaymentMethods: any[];
  smsTemplate: any;
  outerUtility: any;
  f0: any;
  f1Pos: any;
  isF1: boolean;
  f1Projects: any;
  paymentConfig: string;
  txnStatusConfig: string;
  unitStatusConfig: string;
  isCustomTicketCode: boolean;
  unitTableTemplateUrl: any;
  finaUrl: any;
  registerConsignmentUrl: any;
  finaCode: any;
  isRegisterFina: boolean;
  softDelete: boolean;
  setOnTop: boolean;
  dashboardUrl: string;
  documentUrl: any;
  constructor(param?: any) {
    super();
    this.setValue(param);
  }

  setValue(params: any, loadFromF1 = false) {
    if (params) {
      this.unitTableTemplateUrl = params.unitTableTemplateUrl;
      this.active = params.active;
      this.location = new GeoLocation(params.location) || new GeoLocation();
      this.locationAddress = params.locationAddress;
      this.type = params.type;
      this.name = params.name;
      this.namePublic = params.namePublic || params.name;
      this.status = params.status;
      this.propertyTotal = params.propertyTotal ? Number(params.propertyTotal) : null;
      this.unitPrice = params.unitPrice || DEFAULT_UNIT;
      this.priceFrom = params.priceFrom ? Number(params.priceFrom) : null;
      this.priceTo = params.priceTo ? Number(params.priceTo) : null;
      this.investor = params.investor;
      this.refUrl = params.refUrl;
      this.finaUrl = params.finaUrl;
      this.registerConsignmentUrl = params.registerConsignmentUrl;
      this.documentUrl = params.documentUrl;
      this.finaCode = params.finaCode;
      this.isRegisterFina = params.isRegisterFina;
      this.emailFrom = params.emailFrom;
      this.address = params.address;
      this.apartmentsTotal = params.apartmentsTotal ? Number(params.apartmentsTotal) : null;
      this.constructionArea = params.constructionArea ? Number(params.constructionArea) : null;
      this.projectArea = params.projectArea ? Number(params.projectArea) : null;
      this.parkArea = params.parkArea ? Number(params.parkArea) : null;
      this.constructionFloorArea = params.constructionFloorArea ? Number(params.constructionFloorArea) : null;
      this.trafficArea = params.trafficArea ? Number(params.trafficArea) : null;
      this.greenAreaOnBuilding = params.greenAreaOnBuilding ? Number(params.greenAreaOnBuilding) : null;
      this.constructionPercent = params.constructionPercent ? Number(params.constructionPercent) : null;
      this.greenPercent = params.greenPercent ? Number(params.greenPercent) : null;
      this.blocksTotal = params.blocksTotal ? Number(params.blocksTotal) : null;
      this.floorsTotal = params.floorsTotal ? Number(params.floorsTotal) : null;
      this.situation = params.situation;
      this.isPublic = params.isPublic;
      this.isDxAgent = params.isDxAgent;
      this.isPublicView = params.isPublicView;
      this.sendMailApprovedTransaction = params.sendMailApprovedTransaction;
      this.careInvestor = params.careInvestor;
      this.careTotalBlock = params.careTotalBlock;
      this.careTotalFloor = params.careTotalFloor;
      this.careTotalUnit = params.careTotalUnit;
      this.innerUtility = params.innerUtility ? params.innerUtility : [];
      this.outstandingAdv = params.outstandingAdv ? params.outstandingAdv : [];
      this.apartType = params.apartType ? params.apartType : [];
      this.isHot = params.isHot;
      this.imageUrl = params.imageUrl;
      this.projectGroundImageUrl = params.projectGroundImageUrl;
      this.utilitiesImageUrl = params.utilitiesImageUrl;
      this.images = params.images ? new Images(params.images) : new Images();
      this.province = params.province;
      this.district = params.district;
      this.transactionType = params.transactionType;
      this.block = params.block;
      this.zone = params.zone;
      this.floor = params.floor;
      this.plot = params.plot;
      this.street = params.street;
      this.ownership = params.ownership;
      this.landTotal = params.landTotal;
      this.country = params.country;
      this.ward = params.ward;
      this.city = params.city;
      this.files = params.files || [];
      this.day = params.day;
      this.employeeSale = params.employeeSale;
      this.villasTotal = params.villasTotal;
      this.projectLeader = params.projectLeader;
      this.emailReplyTo = params.emailReplyTo;
      this.employeeSales = params.employeeSales || [];
      this.adviceType = params.setting ? params.setting.adviceType : null;
      this.isRetailCustomer = params.setting ? params.setting.isRetailCustomer : null;
      this.isManualReceipt = params.setting ? params.setting.isManualReceipt : null;
      this.requireDocument = params.setting ? params.setting.requireDocument : [];
      this.chatGroupId = params.chatGroupId ? params.chatGroupId : null;
      this.liveUrl = params.liveUrl ? params.liveUrl : null;
      this.code = params.code ? params.code : null;
      this.currentStatus = params.currentStatus ? params.currentStatus : null;
      this.designUnit = params.designUnit ? params.designUnit : null;
      this.constructionUnit = params.constructionUnit ? params.constructionUnit : null;
      this.guideVideoUrl = params.guideVideoUrl ? params.guideVideoUrl : null;
      this.projectVideoUrl = params.projectVideoUrl ? params.projectVideoUrl : null;
      this.guideImages = params.guideImages || [];
      this.groundImages = params.groundImages || [];
      this.blocks = params.blocks || [];
      this.banks = params.banks || [];
      this.dashboardUrl = params.dashboardUrl || '';
      if (!loadFromF1) {
        this.id = params.id;
        this.hotline = params.hotline;
        this.projectLeader = params.projectLeader;
        this.employeeSales = params.employeeSales || [];
        this.accountants =  params.accountants || [];
        this.customerServices = params.customerServices || [];
        this.marketingEmps = params.marketingEmps || [];
        this.emailTransaction = params.emailTransaction || [];
        this.emailLiquidate = params.emailLiquidate || [];
        this.emailCheckUpdateUnits = params.emailCheckUpdateUnits || [];
        this.accountantDeposit = !!params.accountantDeposit;
      }
      this.setting = new ProjectSetting(params.setting);
      this.isPin = params.isPin || false;
      this.esalekit = params.esalekit || false;
      this.urlEsalekit = params.urlEsalekit || '';
      this.surveys = params.surveys || [];
      this.emailTemplate = params.emailTemplate || [];
      this.unitConfig = params.unitConfig || [];
      this.isSyncCRMInvestor = params.isSyncCRMInvestor || [];
      this.smsTemplate = params.smsTemplate || [];
      this.outerUtility = new MultiImages(params.outerUtility);
      this.f0 = params.f0 ? params.f0.id : null;
      this.f1Pos = params.f1Pos ? params.f1Pos.id : null;
      this.isF1 = params.isF1 || false;
      this.f1Projects = params.f1Projects || [];
      this.paymentConfig = params.paymentConfig;
      this.txnStatusConfig = params.txnStatusConfig;
      this.unitStatusConfig = params.unitStatusConfig;
      this.isCustomTicketCode = params.isCustomTicketCode || false;
      this.softDelete = params.softDelete || false;
      this.setOnTop = params.setOnTop || false;
    } else {
      this.setAllNull();
    }
    super.setValue(params);
  }

  setAllNull() {
    this.unitTableTemplateUrl = [];
    this.id = '';
    this.active = false;
    this.location = new GeoLocation();
    this.locationAddress = null;
    this.type = null;
    this.name = '';
    this.namePublic = '';
    this.status = null;
    this.propertyTotal = null;
    this.unitPrice = DEFAULT_UNIT;
    this.priceFrom = null;
    this.priceTo = null;
    this.banks = null;
    this.investor = null;
    this.refUrl = null;
    this.finaUrl = null;
    this.registerConsignmentUrl = null;
    this.documentUrl = null;
    this.finaCode = null;
    this.isRegisterFina = false;
    this.emailFrom = null;
    this.address = null;
    this.hotline = null;
    this.apartmentsTotal = null;
    this.constructionArea = null;
    this.projectArea = null;
    this.parkArea = null;
    this.constructionFloorArea = null;
    this.trafficArea = null;
    this.greenAreaOnBuilding = null;
    this.constructionPercent = null;
    this.greenPercent = null;
    this.blocksTotal = null;
    this.floorsTotal = null;
    this.situation = null;
    this.isPublic = false;
    this.isDxAgent= false;
    this.isPublicView = true;
    this.sendMailApprovedTransaction = false;
    this.accountantDeposit = false;
    this.careInvestor = null;
    this.careTotalBlock = null;
    this.careTotalFloor = null;
    this.careTotalUnit = null;
    this.innerUtility = [];
    this.outerUtility = new MultiImages([]);
    this.outstandingAdv = [];
    this.apartType = [];
    this.isHot = false;
    this.imageUrl = null;
    this.projectGroundImageUrl = null;
    this.utilitiesImageUrl = null;
    this.images = new Images();
    this.province = null;
    this.district = null;
    this.transactionType = null;
    this.block = null;
    this.zone = null;
    this.floor = null;
    this.plot = null;
    this.street = null;
    this.ownership = null;
    this.landTotal = null;
    this.country = null;
    this.ward = null;
    this.city = null;
    this.files = [];
    this.day = null;
    this.employeeSale = null;
    this.villasTotal = null;
    this.projectLeader = null;
    this.emailReplyTo = null;
    this.liveUrl = null;
    this.chatGroupId = null;
    this.employeeSales = [];
    this.code = null;
    this.currentStatus = null;
    this.designUnit = null;
    this.constructionUnit = null;
    this.guideVideoUrl = null;
    this.projectVideoUrl = null;
    this.guideImages = [];
    this.groundImages = [];
    this.blocks = [];
    this.banks = [];
    this.accountants = [];
    this.customerServices = [];
    this.marketingEmps = [];
    this.setting = new ProjectSetting();
    this.isPin = false;
    this.surveys = [];
    this.emailTransaction = [];
    this.emailLiquidate = [];
    this.emailCheckUpdateUnits = [];
    this.emailTemplate = [];
    this.unitConfig = [];
    this.isSyncCRMInvestor = false;
    this.smsTemplate = [];
    this.f0 = null;
    this.f1Pos = null;
    this.isF1 = false;
    this.f1Projects = [];
    this.paymentConfig = null;
    this.txnStatusConfig = null;
    this.unitStatusConfig = null;
    this.isCustomTicketCode = false;
    this.softDelete = false;
    this.setOnTop = false;
    this.dashboardUrl = null
  }

  createObjectRequestAPI() {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.unitTableTemplateUrl = this.unitTableTemplateUrl;
    object.location = this.location.createObjectRequestAPI() || null;
    object.locationAddress = this.locationAddress;
    object.type = this.type;
    object.name = this.name;
    object.namePublic = this.namePublic;
    object.status = this.status;
    object.unitPrice = this.unitPrice;
    object.priceFrom = this.priceFrom;
    object.priceTo = this.priceTo;
    object.banks = this.banks;
    object.investor = this.investor;
    object.refUrl = this.refUrl;
    object.finaUrl = this.finaUrl;
    object.registerConsignmentUrl = this.registerConsignmentUrl;
    object.documentUrl = this.documentUrl;
    object.finaCode = this.finaCode;
    object.isRegisterFina = this.isRegisterFina;
    object.emailFrom = this.emailFrom;
    object.address = this.address;
    object.hotline = this.hotline;
    object.apartmentsTotal = this.apartmentsTotal;
    object.constructionArea = this.constructionArea;
    object.projectArea = this.projectArea;
    object.parkArea = this.parkArea;
    object.constructionFloorArea = this.constructionFloorArea;
    object.trafficArea = this.trafficArea;
    object.greenAreaOnBuilding = this.greenAreaOnBuilding;
    object.constructionPercent = this.constructionPercent;
    object.greenPercent = this.greenPercent;
    object.blocksTotal = this.blocksTotal;
    object.floorsTotal = this.floorsTotal;
    object.hasUnitExcelTemplate = this.hasUnitExcelTemplate;
    object.hasUniqueAddress = this.hasUniqueAddress;
    object.situation = this.situation;
    object.isPublic = this.isPublic;
    object.isDxAgent = this.isDxAgent;
    object.isPublicView = this.isPublicView; // Công khai profile TVV
    object.sendMailApprovedTransaction = this.sendMailApprovedTransaction;
    object.careInvestor = this.careInvestor;
    object.careTotalBlock = this.careTotalBlock;
    object.careTotalFloor = this.careTotalFloor;
    object.careTotalUnit = this.careTotalUnit;
    object.innerUtility = this.innerUtility;
    object.outstandingAdv = this.outstandingAdv;
    object.apartType = this.apartType;
    object.isHot = this.isHot;
    object.imageUrl = this.imageUrl;
    object.projectGroundImageUrl = this.projectGroundImageUrl;
    object.utilitiesImageUrl = this.utilitiesImageUrl;
    object.images = this.images.createObjectRequestAPI();
    object.province = this.province;
    object.district = this.district;
    object.transactionType = this.transactionType;
    object.block = this.block;
    object.zone = this.zone;
    object.floor = this.floor;
    object.plot = this.plot;
    object.street = this.street;
    object.ownership = this.ownership;
    object.landTotal = this.landTotal;
    object.country = this.country;
    object.ward = this.ward;
    object.city = this.city;
    object.files = this.files;
    object.day = this.day;
    object.employeeSale = this.employeeSale;
    object.villasTotal = this.villasTotal;
    object.projectLeader = this.projectLeader;
    object.emailReplyTo = this.emailReplyTo;
    object.employeeSales = this.employeeSales;
    object.liveUrl = this.liveUrl;
    object.chatGroupId = this.chatGroupId;
    object.code = this.code;
    object.currentStatus = this.currentStatus;
    object.designUnit = this.designUnit;
    object.constructionUnit = this.constructionUnit;
    object.guideVideoUrl = this.guideVideoUrl;
    object.projectVideoUrl = this.projectVideoUrl;
    object.guideImages = this.guideImages;
    object.groundImages = this.groundImages;
    object.accountants = this.accountants;
    object.customerServices = this.customerServices;
    object.marketingEmps = this.marketingEmps;
    object.setting = this.setting;
    object.isPin = this.isPin;
    object.esalekit = this.esalekit;
    if(object.esalekit) {
      object.urlEsalekit = this.urlEsalekit;
    }
    object.surveys = this.surveys;
    object.emailTransaction = this.emailTransaction;
    object.emailLiquidate = this.emailLiquidate;
    object.emailCheckUpdateUnits = this.emailCheckUpdateUnits;
    object.emailTemplate = this.emailTemplate;
    object.unitConfig = this.unitConfig;
    object.isSyncCRMInvestor = this.isSyncCRMInvestor;
    object.smsTemplate = this.smsTemplate;
    object.outerUtility = this.outerUtility ? this.outerUtility.createObjectRequestAPI() : [];
    object.f0 = this.f0;
    object.f1Pos = this.f1Pos;
    object.f1Projects = this.f1Projects;
    object.accountantDeposit = this.accountantDeposit;
    object.paymentConfig = this.paymentConfig;
    object.txnStatusConfig = this.txnStatusConfig;
    object.unitStatusConfig = this.unitStatusConfig;
    object.isCustomTicketCode = this.isCustomTicketCode;
    object.softDelete = this.softDelete;
    object.setOnTop = this.setOnTop;
    object.dashboardUrl = this.dashboardUrl;
    return object;
  }
  createObjectReference() {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.name = this.name;
    return object;
  }

  public isShowAdviceProduct(): boolean {
    // 1 - Tư vấn thiện chí, 2 - Tư vấn có mã sản phẩm
    return this.adviceType === 2;
  }
}
interface IEmployeeModel {
  id: string;
  name: string;
  code: string;
  phone: string;
  email: string;
}
class _EmployeeModel implements IEmployeeModel {
  id: string;
  name: string;
  code: string;
  phone: string;
  email: string;
}
