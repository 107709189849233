import {validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty} from "class-validator";
import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { IsLongerThan } from "../../shared/decorators/IsLongerThan.decorator";
import { DefineAttribute } from './define-attributes.model';
export class GroupAttributes extends BaseModel implements BaseModelInterface {

    @IsNotEmpty()
    groupName: string;

    groupOrder: number;

    attributes: any = [];

    orders: any = [];
    
    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'DefineAttributes';
    }

    setValue(params: any) {
        if (params) {
            this.groupName = params.groupName || '';
            this.groupOrder = params.groupOrder || 1;
            this.attributes = params.attributes ?  params.attributes.map((attribute) => {
                attribute = new DefineAttribute(attribute);
                return attribute;
            }) : [];
            this.setOrders();
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    } 

    setAllNull() {
        this.groupName = 'Nhóm mới';
        this.groupOrder = 1;
        this.attributes = [];
        this.orders = [];
    }
    setOrders() {
        this.orders = this.attributes.map((item, index) => {
            return index + 1;
        })
    }
    createObjectRequestAPI() {
        let object: any = {};
        object.groupName = this.groupName || '';
        object.groupOrder = this.groupOrder || 1;
        object.attributes = this.attributes || [];
        return object;
    }
}
