import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core'
import { StorageService } from 'app/shared/services'
import { DialogService } from 'app/shared/services/dialog.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-button-v3',
  templateUrl: './button-v3.component.html',
  styleUrls: ['./button-v3.component.scss']
})
export class ButtonV3Component implements OnInit, OnChanges {
    @Input() title : string;
    @Input() icon :string;
    @Input() color :string;
    @Input() disabled: boolean = false; 
    @Output() onClick = new EventEmitter<any>();
    constructor(){

    }
    ngOnInit() {

    }
    ngOnChanges() {
        
    }
    emitEvent() {
      this.onClick.emit()
    }
}
