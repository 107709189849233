import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { throwError, empty } from 'rxjs';
import { isArray } from 'util';
import { CHelper } from 'app/api-models/c-helper';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  get(url: string, body?: any, options?: any) {
    const params = CHelper.RemoveEmpty(body || {});
    return this.httpClient.get(url, Object.assign({}, options, { params }))
      .pipe(
        retry(3),
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }

  post(url: string, body?: any, options?: any) {
    return this.httpClient.post(url, body, options)
      .pipe(
        retry(3),
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }

  postErp(url: string, body: any, options?: any) {
    return this.httpClient.post(url, body, options)
      .pipe(
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }
  getERP(url: string, body?: any, options?: any) {
    const params = CHelper.RemoveEmpty(body || {});
    return this.httpClient.get(url, Object.assign({}, options, { params }))
      .pipe(
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }
  put(url: string, body: any, options?: any) {
    return this.httpClient.put(url, body, options)
      .pipe(
        retry(3),
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }

  delete(url: string, options?: any) {
    return this.httpClient.delete(url, options)
      .pipe(
        retry(3),
        catchError(this.handlerError),
        map(this.handlerResponse)
      );
  }

  patch(url: string, body?: any, options?: any) {
      return this.httpClient.patch(url, body, options)
        .pipe(
            retry(3),
            catchError(this.handlerError),
            map(this.handlerResponse)
        );
  }

  private handlerError(err: Error) {
    return throwError(err);
  }

  private handlerResponse(resApi: any) {
    return resApi;
  }

  // convertAnyToHttpParams(params: {}): HttpParams {
  //   params = Object.assign({}, params);
  //   let httpParams = new HttpParams();

  //   Object.keys(params).forEach(key => {
  //     if (typeof params[key] === 'object' && !isArray(params[key])) {
  //       httpParams = httpParams.set(key, JSON.stringify(params[key]));
  //     } else if (params[key]) {
  //       httpParams = httpParams.set(key, params[key]);
  //     }
  //   });

  //   return httpParams;
  // }

  convertAnyToFormData(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = (namespace) ? (isArray(object) ? `${namespace}` : `${namespace}[${property}]`) : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        this.convertAnyToFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

}
