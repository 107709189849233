import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConstantUrl } from '../constant/url';
import { EBankType } from 'app/enums/e-bank.enum';
import { Observable } from 'rxjs';

export interface IBankDropdown {
  CODE: string;
  INDEX: string;
  LOGO: string;
  NAME: string;
  TYPE: EBankType;
}

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    protected httpService: HttpService
  ) { }

  public banksDropdown(): Observable<IBankDropdown[]> {
    const uri = ConstantUrl.url_payment + 'payment/bank_list';
    return this.httpService.get(uri);
  }

}
