import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { DataService, SecurityService, StorageService } from 'app/shared/services';
import { EventChangeService } from 'app/shared/services/event.change.service';
import { ConstantUrl } from 'app/shared/constant/url';

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class PasswordService {
  ConstantUrl = ConstantUrl;
  isUnAuthen = false;
  constructor(
    private service: DataService,
    private securityService: SecurityService,
    private storageService: StorageService,
    private eventChangeService: EventChangeService,
  ) { }

  forgotPassword(body) {
    return this.service.post(ConstantUrl.url_sts_domain + 'user/forgotPwd', body);
  }

  checkUsername(body) {
    return this.service.post(ConstantUrl.url_sts_domain + 'user/checkUsername', body);
  }

  forgotPasswordByOtp(body) {
    return this.service.post(ConstantUrl.url_sts_domain + 'user/forgotPwdMobile', body);
  }

  changePassword(body) {
    return this.service.put(ConstantUrl.url_sts_domain + 'user/changePwd', body);
  }

  updatePassword(newPassword: string, token: string, otp: string) {
    return this.service.post(ConstantUrl.url_sts_domain + 'user/updatePwd', { password: newPassword, otp }, {
      token
    });
  }

  resetPassword(newPassword: string, token: string) {
    const headers = {
      'Authorization': 'Bearer ' + token
    };
    return this.service.put(ConstantUrl.url_sts_domain + 'user/resetPwd', { password: newPassword }, headers);
  }

  resetPasswordAdmin(newPassword: string, token: string) {
    const headers = {
      'Authorization': 'Bearer ' + token
    };
    return this.service.put(ConstantUrl.url_sts_domain + 'user/o2o/resetPwd', { password: newPassword }, headers);
  }

  checkToken(token: string) {
    return this.service.post(ConstantUrl.url_sts_domain + 'auth/verify', { token });
  }

}
