import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

import { AuthenticateService } from './authenticate.service';
import { JhiEventManager } from 'ng-jhipster';
import { StateStorageService } from './state-storage.service';
import { AuthorizeService } from '../services/common/authorize.service';
import { CookieUtilsService } from './cookie.service';
import { AuthenticateUserService } from './authenticate-user.service';
@Injectable()
export class UserRouteAccessService implements CanActivate {
    dialogLoginService: any;
    authenticateService: any;
    authorizeService: any;
    cookieService: any;
    userService: any;
    // loginServer: LoginService;
    eventManager: JhiEventManager;
    route: any;
    loadAuthority: Boolean = false;
    constructor(
        public injector: Injector,
        private stateStorageService: StateStorageService,
        private router: Router) {
        this.authenticateService = injector.get(AuthenticateService);
        this.authorizeService = injector.get(AuthorizeService);
        this.cookieService = injector.get(CookieUtilsService);
        this.eventManager = injector.get(JhiEventManager);
        this.userService = injector.get(AuthenticateUserService);
        // this.loginServer = injector.get(LoginService);
    }

    logout() {
        this.authenticateService.authenticate(null);
        this.cookieService.setToken(null);
        this.cookieService.setRelogin(null);
        this.stateStorageService.resetDestinationState();
        this.authorizeService.resetAuthorities();
        this.cookieService.setIsLogged(null);
        this.router.navigate(['login']);
    }

    canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        let authority = route.routeConfig.data.authority;
        if (!authority) {
            authority = route.routeConfig.path.split('/')[0]
        }
        this.route = {
            authority,
            path: route.routeConfig.path
        };
        
        if (!this.authorizeService.getAuthorities()) {
            if (this.cookieService.getIsLogged() == 'true' 
                && this.cookieService.getToken()
                && !this.cookieService.getRememberMe()) {
                // this.cookieService.setIsLogged(null);
                // this.router.navigate(['login']);

                this.logout();

            } else {
                if (this.cookieService.getToken() && this.loadAuthority === false) {
                    this.loadAuthority = true;
                    this.userService.getAuthorities()
                        .subscribe(() => {
                            this.loadAuthority = false;
                            this.router.navigate(['case']);
                        })
                } else if (this.loadAuthority) {
                    if (this.route.path !== 'login') {
                        this.router.navigate(['login']);
                    }
                }
            }
            return false;
        } else {
            this.authenticateService.setAuthenticated(true);
        }
        
        let isAuthenticated = this.authenticateService.isAuthenticated();
        if (isAuthenticated) {
            return this.authorize(false).then((canActivate) => {
                if (!canActivate) {
                    this.dialogLoginService.show();
                    this.router.navigate(['login']);
                }
                return canActivate;
            });
        } else {
            return false;
        }
    }

    authorize(force) {
        let authReturn = this.authenticateService.identity(force).then(authThen.bind(this));

        return authReturn;

        function authThen() {
            // check role of user
            return this.authorizeService.hasAnyAuthority()
                .then((hasAnyAuthority) => {
                    // no role but can has authenticate
                    if (!hasAnyAuthority) {
                        // if (isAuthenticated) {
                        //     // user is signed in but not authorized for desired state
                        //     this.router.navigate(['accessdenied']);
                        // } else 
                        {
                            const destinationState = this.stateStorageService.getDestinationState() || null;
                            if (destinationState) {
                                let toStateInfo = destinationState.destination;
                                if (toStateInfo && toStateInfo.data) {
                                    // user is not authenticated. Show the state they wanted before you
                                    // send them to the login service, so you can return them when you're done
                                    let toStateParamsInfo = this.stateStorageService.getDestinationState().params;
                                    this.stateStorageService.storePreviousState(toStateInfo.name, toStateParamsInfo);
                                    // now, send them to the signin state so they can log in
                                    return false;
                                } else if (!toStateInfo) {
                                    return false;
                                }
                            }
                        }
                    } else {
                        const destinationState = this.stateStorageService.getDestinationState() || null;
                        this.stateStorageService.storeDestinationState(
                            { name: this.route.path, data: [] },
                            {
                                userToken: this.cookieService.getToken()
                            },
                            { name: '' });
                        let login = false;
                        if (this.route.path === '' || this.route.path === 'login') {
                            this.route = {
                                authority: 'case',
                                path: 'case'
                            }
                            login = true;
                        }
                        if (this.authorizeService.getAuthority([this.route.authority])) {
                            if (login) {
                                this.router.navigate([this.route.path])
                            }
                            return true;
                        } else {
                            const authorities = this.authorizeService.authorities;
                            let authority = null;
                            // tslint:disable-next-line:forin
                            for (let property in authorities) {
                                !authority && (authority = property)
                            }
                            if (authority) {
                                this.router.navigate([authority])
                            } else {
                                this.router.navigate(['accessdenied']);
                            }
                            
                            return true;
                        }
                    }
                    return hasAnyAuthority;
                });
        }
    }
}
