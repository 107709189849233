import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { HttpModule } from "@angular/http";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";

import { AgmCoreModule } from "@agm/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true,
};
import { SharedModule } from "./shared/shared.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { routes, routing } from "./app.routing";

import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
// import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from "./pages/errors/not-found/not-found.component";
import { ErrorComponent } from "./pages/errors/error/error.component";
import { AppSettings } from "./app.settings";

import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { HorizontalMenuComponent } from "./theme/components/menu/horizontal-menu/horizontal-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { FlagsMenuComponent } from "./theme/components/flags-menu/flags-menu.component";
import { FullScreenComponent } from "./theme/components/fullscreen/fullscreen.component";
import { ApplicationsComponent } from "./theme/components/applications/applications.component";
import { MessagesComponent } from "./theme/components/messages/messages.component";
import { UserMenuComponent } from "./theme/components/user-menu/user-menu.component";
import { FileSaverModule } from "ngx-filesaver";
import { ToastrModule } from "ngx-toastr";
import { UserRouteAccessDeactivate } from "./shared/services/common/user.route.access.service";
import { UserAuthorityDirective } from "./shared/services/common/user-authority.directive";
import { AuthorizeService } from "./shared/services/common/authorize.service";
import { EventChangeService } from "./shared/services/event.change.service";
import { SearchComponent } from "./pages/search/search.component";
import { ReasonDialog } from "./pages/ticket/popup-dialog";
import { AppCustomPreloader } from "./AppCustomPreloader";
import { ReloadContractDialog } from "./pages/contract/pop-up-reload-contract/pop-up-reload-contract.component";
import { NotificationService } from "./shared/services/notification.service";
import { CommingSoonComponent } from "./pages/errors/coming-soon/coming-soon.component";
import { AgencyFeeDialog } from "./pages/demand/dialog/agency-fee-dialog";
import { HandleErrorRequestDialog } from "./shared/components/dialog-handle-error/popup-dialog";
import { InfoDialogComponent } from "./shared/components/info-dialog/popup-dialog";
import { ConfirmDialog } from "./pages/money-order/confirm-dialog/confirm-dialog";
import { ConfirmPopup } from "./shared/components/confirm-popup/confirm-popup";
import { NoteDialog } from "./pages/project/investor/note-dialog/note-dialog";
import { WebsocketService } from "./shared/components/websocket.service";
import { WsNotifyService } from "./shared/components/ws-notify.service";
import { ErrorPopup } from "./shared/components/error-popup/error-popup";
import { SuccessPopup } from "./shared/components/success-popup/success-popup";
import { ResetPasswordComponent } from "./pages/password/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./pages/password/forgot-password/forgot-password.component";
import { NgOtpInputModule } from "ng-otp-input";
import { PasswordService } from "./pages/password/password.service";
import { TokenInterceptor } from "./shared/services/interceptors/token.interceptor";
import { ImagePopup } from "./shared/components/image-popup/image-popup";
import { EmployeePOSComponent } from "./pages/employee/employee-pos/employee-pos.component";
import { ChoosePopup } from "./shared/components/choose-popup/choose-popup";
import { GroupPopup } from "./pages/message/group-popup/group-popup";
import { environment } from "environments/environment";
import { SecurityService } from "./shared/services/security.service";
import { OAuthModule } from "angular-oauth2-oidc";
import { ErpService } from "./shared/services/common/erp.service";
import { ConfirmErpPopup } from "./shared/components/confirm-erp-popup/confirm-erp-popup";
import { AgGridModule } from "ag-grid-angular";
import { ConfirmChangedEmailComponent } from "./pages/employee/confirm-changed-email/confirm-changed-email.component";
import { ConfirmErpSalesPopup } from "./shared/components/confirm-erp-sales-popup/confirm-erp-sales-popup";
import { EditorPopup } from "./shared/components/editor-popup/editor-popup";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { RequestManagementComponent } from "./pages/request-management/request-management.component";
import { ScriptService } from "./script.service";
import { WarningSystemDialog } from "./shared/components/warning-system/warning-system-dialog.component";
import { SuccessDialogPopup } from "./shared/components/success-dialog-popup/success-popup.component";
import { SearchEmployeePageComponent } from "./pages/search-employee-page/search-employee-page.component";
import { MailerLogComponent } from "./pages/mailer/mailer-log/mailer-log.component";
import { EmployeePublicComponent } from "./pages/employee-public/employee-public.component";
import { AppHeaderComponent } from "./theme/components/app-header/app-header.component";
import { AppFooterComponent } from "./theme/components/app-footer/app-footer.component";
import { QRCodeModule } from "angularx-qrcode";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { VerticalTrialMenuComponent } from "./theme/components/menu/vertical-trial-menu/vertical-trial-menu.component";
import { ConfirmPopupUpgrade } from "./shared/components/confirm-popup-upgrade-trial/confirm-popup-upgrade-trial.component";
import { TrialGuideModule } from "./pages/trial-guide/trial-guide.module";
import { NotiPopupTrial } from "./shared/components/noti-popup-trial/noti-popup-trial";
import { ConfirmPopupV3 } from "./shared/components/v3/confirm-popup-v3/confirm-popup-v3.component";
import { ModalResidenInfomationComponent } from "./pages/apartment-management/modal-resident-information/modal-resident-information.component";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@NgModule({
    imports: [
        TrialGuideModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleMap.apiKey,
        }),
        PerfectScrollbarModule,
        // CalendarModule.forRoot(),
        SharedModule,
        PipesModule,
        routing,
        FileSaverModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: "toast-top-right",
            // progressBar: true,
            progressAnimation: "increasing",
            closeButton: true,
            maxOpened: 3,
        }),
        AgGridModule.withComponents([]),
        OAuthModule.forRoot(),
        AngularEditorModule,
        QRCodeModule,
        NgOtpInputModule,
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        SearchComponent,
        NotFoundComponent,
        CommingSoonComponent,
        ErrorComponent,
        MailerLogComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SidenavComponent,
        VerticalMenuComponent,
        VerticalTrialMenuComponent,
        HorizontalMenuComponent,
        BreadcrumbComponent,
        FlagsMenuComponent,
        FullScreenComponent,
        ApplicationsComponent,
        MessagesComponent,
        UserMenuComponent,
        ReasonDialog,
        AgencyFeeDialog,
        ConfirmDialog,
        ConfirmPopup,
        NoteDialog,
        HandleErrorRequestDialog,
        InfoDialogComponent,
        ErrorPopup,
        SuccessPopup,
        ImagePopup,
        EmployeePOSComponent,
        ChoosePopup,
        GroupPopup,
        ConfirmErpPopup,
        ConfirmErpSalesPopup,
        ConfirmChangedEmailComponent,
        EditorPopup,
        RequestManagementComponent,
        WarningSystemDialog,
        SearchEmployeePageComponent,
        EmployeePublicComponent,
        AppHeaderComponent,
        AppFooterComponent,
        ConfirmPopupUpgrade,
        NotiPopupTrial,
        ConfirmPopupV3,
        ModalResidenInfomationComponent,
        SuccessDialogPopup,
    ],
    entryComponents: [
        VerticalMenuComponent,
        VerticalTrialMenuComponent,
        ReasonDialog,
        AgencyFeeDialog,
        ConfirmDialog,
        ConfirmPopup,
        NoteDialog,
        HandleErrorRequestDialog,
        InfoDialogComponent,
        ErrorPopup,
        SuccessPopup,
        ImagePopup,
        ChoosePopup,
        GroupPopup,
        ConfirmErpPopup,
        ConfirmErpSalesPopup,
        EditorPopup,
        WarningSystemDialog,
        ConfirmPopupUpgrade,
        SidenavComponent,
        NotiPopupTrial,
        ConfirmPopupV3,
        ModalResidenInfomationComponent,
        SuccessDialogPopup,
    ],
    providers: [
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: loadRoutes,
        //   deps: [Injector],
        //   multi: true,
        // },
        UserRouteAccessDeactivate,
        SecurityService,
        AuthorizeService,
        UserAuthorityDirective,
        EventChangeService,
        NotificationService,
        WebsocketService,
        WsNotifyService,
        PasswordService,
        AppSettings,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        AppCustomPreloader,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        ErpService,
        ScriptService,
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
