import { ContractConstants } from "../constant/contract.constant";
import { ContractEnum } from "../enum/contract.enum";
import { DateToFormat } from "../parse/date-to-format";

export class ContractDepositModel {
  stt: number;
  id: string;
  code: string;
  name: string;
  projectName: string;
  programName: string;
  codeProduct: string;
  codeTicket: string;
  customerName: string;
  deposit: any;
  createdDate: string;
  status: string;
  modifiedBy: string;
  createdBy: string;
  reason: string;
  isTransferred: boolean;
  type: string;
  liquidationCode: string;

  constructor(params?: any, index = 0) {
    params = params || {};

    this.stt = index + 1;
    this.id = params.id || '';
    this.code = params.code || '';
    this.name = params.name || '';
    this.projectName = params.primaryTransaction && params.primaryTransaction.project && params.primaryTransaction.project.name ? params.primaryTransaction.project.name : '';
    this.programName = params.primaryTransaction && params.primaryTransaction.propertyUnit && params.primaryTransaction.propertyUnit.salesProgram && params.primaryTransaction.propertyUnit.salesProgram.name ? params.primaryTransaction.propertyUnit.salesProgram.name : '';
    this.codeProduct = params.primaryTransaction && params.primaryTransaction.propertyUnit && params.primaryTransaction.propertyUnit.code ? params.primaryTransaction.propertyUnit.code : '';
    this.codeTicket = params.primaryTransaction && params.primaryTransaction.escrowTicketCode ? params.primaryTransaction.escrowTicketCode : '';
    this.customerName = params.primaryTransaction && params.primaryTransaction.customer && params.primaryTransaction.customer.personalInfo && params.primaryTransaction.customer.personalInfo.name ? params.primaryTransaction.customer.personalInfo.name : '';

    this.createdDate = params.createdDate || '';
    this.type = params.type || '';
    this.liquidationCode = params.liquidation ? params.liquidation.code : '';
    this.status = params.status || '';
    this.modifiedBy = params.modifiedBy || '';
    this.createdBy = params.createdBy || '';
    this.reason = params.reason || '';
    this.deposit = params.deposit || {};
    this.isTransferred = params.isTransferred || false;

  }

  createDataForList() {
    let object: any = Object.assign({}, this);
    object.createdDate = object.createdDate ? DateToFormat.parseDate(object.createdDate, 'dd/MM/yyyy') : '';
    object.status = this.checkStatus(object.status)
    return object;
  }

  checkStatus(status) {
    let listStatus = Object.keys(ContractConstants.Status).map(txn => {
      return { id: txn, name: ContractConstants.Status[txn] };
    });
    let item = listStatus.find(i => i.id === status);
    if (item) return item.name;
    return '';
  }

  createRequestObj(data) {
    let object: any = Object.assign({}, this, data);
    return object;
  }
}
