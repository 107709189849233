import { ConstantUrl } from './../../constant/url';
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AngularEditorConfig } from '@kolkov/angular-editor';

export interface EditorData {
  title: string;
  message: string;
  textCancel: string;
  textOk: string;
  body: any;
  execute: boolean;
  editable: boolean;
  showToolbar: boolean;
}

@Component({
  selector: 'editor-popup',
  templateUrl: 'editor-popup.html',
})
export class EditorPopup implements OnInit {
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Nhập ...',
    translate: 'no',
    sanitize: false,
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ['fontName'],
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: ConstantUrl.url_upload + '/upload-file-angular-editor'
  };
  constructor(
    public dialogRef: MatDialogRef<EditorPopup>,
    @Inject(MAT_DIALOG_DATA) public data: EditorData) { }

  ngOnInit() {
    this.htmlContent = this.data.body;
    if (this.data && this.data.editable) {
      this.config.editable = this.data.editable;
    }
    if (this.data && this.data.showToolbar) {
      this.config.showToolbar = this.data.showToolbar;
    }
  }
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    this.data.execute = true;
    this.data.body = this.htmlContent;
    this.dialogRef.close(this.data);
  }
}
