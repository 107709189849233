import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges } from '@angular/core';
import { DefineAttribute } from '../../../pages/define-attributes/define-attributes.model';
import { Constant } from '../../constant/constant';
import { StorageService } from '../../../shared/services';
import { Address } from '../../../shared/models/address.model';
import { HANH_CHINH_VN } from '../../constant/hanhchinhvn';

@Component({
    selector: 'input-address',
    templateUrl: './input-address.component.html',
    styleUrls: ['./input-address.component.scss'],
})

export class InputAddressComponent implements OnInit, OnChanges {
    @Input() data;
    @Input() text;
    @Input() isDisabled;
    @Input() errors;
    @Input() isRequired;
    @Input() isAddressHidden;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    orders: any = Constant.ORDERS;
    controlTypes: any = Constant.CONTROL_TYPES_OBJECT;
    Constant = Constant;
    hierarchy: any = {};
    provinces: any = [];
    selectedProvince: any = {};
    selectedDistrict: any = {};
    selectedWard: string;
    address: any;
    test: string;
    constructor(
        public storageService: StorageService,
    ) {
    }
    ngOnInit() {
        this.getMasterDataProvince();
    }
    ngOnChanges() {
        if (this.provinces.length == 0) {
            this.getMasterDataProvince();
        }
        setTimeout(() => {
            if (this.provinces && this.provinces.length > 0 && this.data) {
                let start = 0, findProvince, findDistrict, findWard;
                while ((!findProvince && start < this.provinces.length) && this.data.province) {
                    if (this.provinces[start].n == this.data.province) {
                        findProvince = true;
                        this.selectedProvince = this.provinces[start];

                        start = 0;
                        while ((!findDistrict && start < this.selectedProvince.d.length) && this.data.district) {
                            if (this.selectedProvince.d[start].n == this.data.district) {
                                findDistrict = true;
                                this.selectedDistrict = this.selectedProvince.d[start];
                                start = 0;
                                while ((!findWard && start < this.selectedDistrict.w.length) && this.data.ward) {
                                    if (this.selectedDistrict.w[start] == this.data.ward) {
                                        findWard = true;
                                        this.selectedWard = this.selectedDistrict.w[start];
                                        start = 0;
                                    } else start++;
                                }
                            } else start++;
                        }
                    } else start++;
                }
                this.address = this.data.address;
            }
        }, 300)
    }
    getData() {
        return new Address({
            province: this.selectedProvince.n && this.selectedProvince.n || '',
            district: this.selectedDistrict.n && this.selectedDistrict.n || '',
            ward: this.selectedWard && this.selectedWard || '',
            address: this.address && this.address || ''
        })
    }
    getMasterDataProvince() {
        let data = JSON.parse(HANH_CHINH_VN);
        // data = data.map((province) => {
        //     province.d = province.d.map((district) => {
        //         district.w = district.w.map(ward => ward);
        //         return district;
        //     })
        //     return province;
        // });

        this.provinces = data;
    }
    setProvince() {
        this.selectedProvince = this.provinces.find(province => province.n == this.selectedProvince.n);
    }

    onProvinceChange(event: any) {
        this.selectedDistrict = {}; // reset ward after change province
        this.selectedWard = "";
    }

    onDistrictChange(event: any) {
        this.selectedWard = "";
    }
}
