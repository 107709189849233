import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RigisterTrialSuccessDialogComponent } from 'app/pages/login/rigister-trial-success-dialog/rigister-trial-success-dialog.component';
export interface UpdateAccountDataComfirm {
  textCancel: string;
}
@Component({
  selector: 'app-update-account-comfirm-popup',
  templateUrl: './update-account-comfirm-popup.component.html',
  styleUrls: ['./update-account-comfirm-popup.component.scss']
})
export class UpdateAccountComfirmPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RigisterTrialSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateAccountDataComfirm
  ) { }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
