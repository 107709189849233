import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import moment = require("moment");
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { markAsTouched } from "../../../shared/utility/form-utility";
import {UpgradeTrialConstant} from "../../../shared/constant/userTrialAccount.constant";
import { Constant } from "app/shared/constant/constant";


@Component({
    selector: 'app-upgrade-account-trial',
    templateUrl: './add-holiday-popup.component.html',
    styleUrls: ['./add-holiday-popup.component.scss']
})

export class AddHolidayComponent implements OnInit {

    mainForm: FormGroup = null;
    startDateUpgrade = '';
    constant:any = Constant
    constructor(
        public dialogRef: MatDialogRef<AddHolidayComponent>,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }
    public upgradeTrialConstant = UpgradeTrialConstant    
    ngOnInit() {
        this.initForm()
    } 

    initForm() {
        this.mainForm = this.formBuilder.group({
            name: [null, [Validators.required, Validators.pattern(Constant.REGEX_VN_TEXT)]],
            date: [null, Validators.required],
        })
    }

    onSave() {
        markAsTouched(this.mainForm);
        if (!this.mainForm.valid) {
            this.toastr.error("Vui lòng kiểm tra lại thông tin");
            return;
        }
        let body = this.mainForm.getRawValue()
        this.dialogRef.close(body)
    
    }

    close() {
        this.dialogRef.close()
    }
}