import { Injectable } from "@angular/core";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { ConstantUrl } from "../../constant/url";
import { SecurityService } from "../security.service";
import { StorageService } from "../storage.service";
import { DataService } from "../data.service";
import { Observable } from "rxjs/Observable";
import { EventChangeService } from "../event.change.service";
import { DateToFormat } from "app/shared/parse/date-to-format";
import { VoiceCallService } from "../voicecall.service";
import { Constant } from "app/shared/constant/constant";
import { environment } from "environments/environment";
declare let ga: Function;
// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class UserService {
    Constant = Constant;
    ConstantUrl = ConstantUrl;
    isUnAuthen = false;
    private user: any;
    constructor(
        private service: DataService,
        private securityService: SecurityService,
        private storageService: StorageService,
        private eventChangeService: EventChangeService,
        private voiceCallService: VoiceCallService
    ) {}

    login(body) {
        return this.service
            .post(ConstantUrl.url_sts_domain + "auth/login", body)
            .map((res) => {
                let data: any = res.json() || {};
                this.storageService.store("userId", data.id);
                this.securityService.setAuthorizationData(
                    data.access_token,
                    null,
                    data.expires_in
                );
                return data;
            });
    }
    userProfile(isUnAuthen): any {
        if (!isUnAuthen) {
            this.securityService.setAuthor([], true);
            return Observable.of(true);
        }
        return this.service
            .get(
                ConstantUrl.url_user_query +
                    "/" +
                    this.storageService.retrieve("userId")
            )
            .map((res) => {
                let data: any = res || {};
                this.user = data;
                this.storageService.store("email", data.email);
                this.storageService.store("isManager", data.managerAt);
                this.storageService.store("isFirstLogin", data.isFirstLogin);
                this.storageService.store("pos", data.pos);
                this.storageService.store("user-profile", data);
                if (data.role.length > 0) {
                    this.storageService.store("roleName", data.role[0].name);
                    this.storageService.store("roleId", data.roleId);
                    this.storageService.store(
                        "routerLink",
                        data.role[0].routerLink
                    );
                } else {
                    this.storageService.store("roleName", "");
                }
                const isFullPermission =
                    data.type === "yoda.master" ? true : false;
                this.eventChangeService.emitChange({
                    boardcast: "login",
                });
                this.getMyOrgs();
                console.log("data.permissions", data.permissions);
                return this.securityService.setAuthor(
                    data.permissions,
                    isFullPermission
                );
            });
    }
    getEmployee() {
        return this.service
            .get(ConstantUrl.url_employee_query + "employee/getProfile")
            .map((res) => {
                let data: any = res || {};
                let gaUserId = data.name + " - " + data.email;
                let trackerUserId = data.id;
                this.user = data;
                data.avatar = data.images ? data.images.avatar : "";
                this.storageService.store("name", data.name);
                this.storageService.store("extPhone", data.extPhone);
                this.storageService.store("user-profile", data);
                this.storageService.store("phone", data.phone);
                localStorage.setItem("ga_user_id", gaUserId);
                localStorage.setItem("tracker_user_Id", trackerUserId);
                return res;
            });
    }

    getMyOrgs() {
        return this.service
            .get(ConstantUrl.url_orgchart_query + "orgchart/config/byUser")
            .subscribe((res) => {
                if (res && res.callConfig) {
                    this.storageService.store(
                        "allowCall",
                        res.callConfig.allowCall
                    );
                } else {
                    this.storageService.store("allowCall", false);
                }
            });
    }

    timekeeping() {
        let date = new Date();
        let body = {
            iodate: DateToFormat.parseDate(date, "yyyy-MM-dd hh:mm:ss"),
            ioday: date.getDate(),
            iomonth: date.getMonth() + 1,
            ioyear: date.getFullYear(),
            iohour: date.getHours(),
            iominute: date.getMinutes(),
            iosecond: date.getSeconds(),
        };
        const url =
            ConstantUrl.url_employee_query + "tavico/employee/timekeeping";
        return this.service.post(url, body);
    }
    getUser() {
        return this.user;
    }
    register(body) {
        return this.service
            .post(ConstantUrl.url_sts_domain + "auth/signup", body)
            .map((res) => {
                let data: any = res.json() || {};
                this.securityService.setAuthorizationData(
                    data.access_token,
                    null
                );
                return data;
            });
    }
    setUnAuthenticate() {
        this.isUnAuthen = true;
    }
}
