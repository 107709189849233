import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { MatMenu } from '@angular/material';
import { ProjectService } from 'app/pages/project/project.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [MenuService]
})
export class VerticalMenuComponent implements OnInit {

  parentMenu: Array<any>;
  childrenMenu: Menu[] = [];
  public settings: Settings;
  filterProject: string = '';

  @Input() menuItems;
  @Input() menuParentId;
  @Input() isManagerProject;
  @Input() small: boolean = false;
  @ViewChild(`smallMenu`) smallMenuRef: MatMenu;
  projectOptions: any[] = [];
  constructor(public appSettings: AppSettings, public menuService: MenuService,
    public router: Router,
    private projectService: ProjectService
    ) {
    this.settings = this.appSettings.settings;
    
  }
  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
    if (this.parentMenu[0] && this.parentMenu[0].isManagerProject)
      this.getLitProjects();
  }


  getLitProjects() {
    this.projectService.getAllProjectInResident().subscribe((res) => {
      if (res) {
          this.projectOptions = res;
          this.filterProject = res[0].id;
      }
    });
  }
  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  onChildrenMenu(menu: Menu) {
    this.childrenMenu = this.menuItems.filter((el: Menu) => el.parentId === menu.id);
  }

  onClick(menu) {

    this.menuService.toggleMenuItem(menu.id);
    this.menuService.closeOtherSubMenus(this.menuItems, menu.id);

    if (!menu.hasSubMenu) {
      this.menuService.menuClick.next(menu);
    }

  }

  // onMouseouverSmallMenu(menu) {
  //   this.onChildrenMenu(menu);
  // }
  onProjectChange(event) {
    if (event) {
      this.filterProject = event.id;
      this.router.navigate([`/management-documnet-rule/project-list`]);
    }
  }
}
