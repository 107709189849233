export const HANH_CHINH_VN_Str = `[
    {
       "d":[
          {
             "n":"Q. Ba Đình",
             "w":[
                "P. Phúc Xá",
                "P. Trúc Bạch",
                "P. Vĩnh Phúc",
                "P. Cống Vị",
                "P. Liễu Giai",
                "P. Nguyễn Trung Trực",
                "P. Quán Thánh",
                "P. Ngọc Hà",
                "P. Điện Biên",
                "P. Đội Cấn",
                "P. Ngọc Khánh",
                "P. Kim Mã",
                "P. Giảng Võ",
                "P. Thành Công"
             ],
             "codeErp":"0101"
          },
          {
             "n":"Q. Hoàn Kiếm",
             "w":[
                "P. Phúc Tân",
                "P. Đồng Xuân",
                "P. Hàng Mã",
                "P. Hàng Buồm",
                "P. Hàng Đào",
                "P. Hàng Bồ",
                "P. Cửa Đông",
                "P. Lý Thái Tổ",
                "P. Hàng Bạc",
                "P. Hàng Gai",
                "P. Chương Dương",
                "P. Hàng Trống",
                "P. Cửa Nam",
                "P. Hàng Bông",
                "P. Tràng Tiền",
                "P. Trần Hưng Đạo",
                "P. Phan Chu Trinh",
                "P. Hàng Bài"
             ],
             "codeErp":"0102"
          },
          {
             "n":"Q. Tây Hồ",
             "w":[
                "P. Phú Thượng",
                "P. Nhật Tân",
                "P. Tứ Liên",
                "P. Quảng An",
                "P. Xuân La",
                "P. Yên Phụ",
                "P. Bưởi",
                "P. Thụy Khuê"
             ],
             "codeErp":"0105"
          },
          {
             "n":"Q. Long Biên",
             "w":[
                "P. Thượng Thanh",
                "P. Ngọc Thụy",
                "P. Giang Biên",
                "P. Đức Giang",
                "P. Việt Hưng",
                "P. Gia Thụy",
                "P. Ngọc Lâm",
                "P. Phúc Lợi",
                "P. Bồ Đề",
                "P. Sài Đồng",
                "P. Long Biên",
                "P. Thạch Bàn",
                "P. Phúc Đồng",
                "P. Cự Khối"
             ],
             "codeErp":"0109"
          },
          {
             "n":"Q. Cầu Giấy",
             "w":[
                "P. Nghĩa Đô",
                "P. Nghĩa Tân",
                "P. Mai Dịch",
                "P. Dịch Vọng",
                "P. Dịch Vọng Hậu",
                "P. Quan Hoa",
                "P. Yên Hoà",
                "P. Trung Hoà"
             ],
             "codeErp":"0106"
          },
          {
             "n":"Q. Đống Đa",
             "w":[
                "P. Cát Linh",
                "P. Văn Miếu",
                "P. Quốc Tử Giám",
                "P. Láng Thượng",
                "P. Ô Chợ Dừa",
                "P. Văn Chương",
                "P. Hàng Bột",
                "P. Láng Hạ",
                "P. Khâm Thiên",
                "P. Thổ Quan",
                "P. Nam Đồng",
                "P. Trung Phụng",
                "P. Quang Trung",
                "P. Trung Liệt",
                "P. Phương Liên",
                "P. Thịnh Quang",
                "P. Trung Tự",
                "P. Kim Liên",
                "P. Phương Mai",
                "P. Ngã Tư Sở",
                "P. Khương Thượng"
             ],
             "codeErp":"0104"
          },
          {
             "n":"Q. Hai Bà Trưng",
             "w":[
                "P. Nguyễn Du",
                "P. Bạch Đằng",
                "P. Phạm Đình Hổ",
                "P. Lê Đại Hành",
                "P. Đồng Nhân",
                "P. Phố Huế",
                "P. Đống Mác",
                "P. Thanh Lương",
                "P. Thanh Nhàn",
                "P. Cầu Dền",
                "P. Bách Khoa",
                "P. Đồng Tâm",
                "P. Vĩnh Tuy",
                "P. Bạch Mai",
                "P. Quỳnh Mai",
                "P. Quỳnh Lôi",
                "P. Minh Khai",
                "P. Trương Định"
             ],
             "codeErp":"0103"
          },
          {
             "n":"Q. Hoàng Mai",
             "w":[
                "P. Thanh Trì",
                "P. Vĩnh Hưng",
                "P. Định Công",
                "P. Mai Động",
                "P. Tương Mai",
                "P. Đại Kim",
                "P. Tân Mai",
                "P. Hoàng Văn Thụ",
                "P. Giáp Bát",
                "P. Lĩnh Nam",
                "P. Thịnh Liệt",
                "P. Trần Phú",
                "P. Hoàng Liệt",
                "P. Yên Sở"
             ],
             "codeErp":"0108"
          },
          {
             "n":"Q. Thanh Xuân",
             "w":[
                "P. Nhân Chính",
                "P. Thượng Đình",
                "P. Khương Trung",
                "P. Khương Mai",
                "P. Thanh Xuân Trung",
                "P. Phương Liệt",
                "P. Hạ Đình",
                "P. Khương Đình",
                "P. Thanh Xuân Bắc",
                "P. Thanh Xuân Nam",
                "P. Kim Giang"
             ],
             "codeErp":"0107"
          },
          {
             "n":"H. Sóc Sơn",
             "w":[
                "TT. Sóc Sơn",
                "X. Bắc Sơn",
                "X. Minh Trí",
                "X. Hồng Kỳ",
                "X. Nam Sơn",
                "X. Trung Giã",
                "X. Tân Hưng",
                "X. Minh Phú",
                "X. Phù Linh",
                "X. Bắc Phú",
                "X. Tân Minh",
                "X. Quang Tiến",
                "X. Hiền Ninh",
                "X. Tân Dân",
                "X. Tiên Dược",
                "X. Việt Long",
                "X. Xuân Giang",
                "X. Mai Đình",
                "X. Đức Hoà",
                "X. Thanh Xuân",
                "X. Đông Xuân",
                "X. Kim Lũ",
                "X. Phú Cường",
                "X. Phú Minh",
                "X. Phù Lỗ",
                "X. Xuân Thu"
             ],
             "codeErp":"0114"
          },
          {
             "n":"H. Đông Anh",
             "w":[
                "TT. Đông Anh",
                "X. Xuân Nộn",
                "X. Thuỵ Lâm",
                "X. Bắc Hồng",
                "X. Nguyên Khê",
                "X. Nam Hồng",
                "X. Tiên Dương",
                "X. Vân Hà",
                "X. Uy Nỗ",
                "X. Vân Nội",
                "X. Liên Hà",
                "X. Việt Hùng",
                "X. Kim Nỗ",
                "X. Kim Chung",
                "X. Dục Tú",
                "X. Đại Mạch",
                "X. Vĩnh Ngọc",
                "X. Cổ Loa",
                "X. Hải Bối",
                "X. Xuân Canh",
                "X. Võng La",
                "X. Tàm Xá",
                "X. Mai Lâm",
                "X. Đông Hội"
             ],
             "codeErp":"0113"
          },
          {
             "n":"H. Gia Lâm",
             "w":[
                "TT. Yên Viên",
                "X. Yên Thường",
                "X. Yên Viên",
                "X. Ninh Hiệp",
                "X. Đình Xuyên",
                "X. Dương Hà",
                "X. Phù Đổng",
                "X. Trung Mầu",
                "X. Lệ Chi",
                "X. Cổ Bi",
                "X. Đặng Xá",
                "X. Phú Thị",
                "X. Kim Sơn",
                "TT. Trâu Quỳ",
                "X. Dương Quang",
                "X. Dương Xá",
                "X. Đông Dư",
                "X. Đa Tốn",
                "X. Kiêu Kỵ",
                "X. Bát Tràng",
                "X. Kim Lan",
                "X. Văn Đức"
             ],
             "codeErp":"0112"
          },
          {
             "n":"Q. Nam Từ Liêm",
             "w":[
                "P. Cầu Diễn",
                "P. Xuân Phương",
                "P. Phương Canh",
                "P. Mỹ Đình 1",
                "P. Mỹ Đình 2",
                "P. Tây Mỗ",
                "P. Mễ Trì",
                "P. Phú Đô",
                "P. Đại Mỗ",
                "P. Trung Văn"
             ],
             "codeErp":"0130"
          },
          {
             "n":"H. Thanh Trì",
             "w":[
                "TT. Văn Điển",
                "X. Tân Triều",
                "X. Thanh Liệt",
                "X. Tả Thanh Oai",
                "X. Hữu Hoà",
                "X. Tam Hiệp",
                "X. Tứ Hiệp",
                "X. Yên Mỹ",
                "X. Vĩnh Quỳnh",
                "X. Ngũ Hiệp",
                "X. Duyên Hà",
                "X. Ngọc Hồi",
                "X. Vạn Phúc",
                "X. Đại áng",
                "X. Liên Ninh",
                "X. Đông Mỹ"
             ],
             "codeErp":"0111"
          },
          {
             "n":"Q. Bắc Từ Liêm",
             "w":[
                "P. Thượng Cát",
                "P. Liên Mạc",
                "P. Đông Ngạc",
                "P. Đức Thắng",
                "P. Thụy Phương",
                "P. Tây Tựu",
                "P. Xuân Đỉnh",
                "P. Xuân Tảo",
                "P. Minh Khai",
                "P. Cổ Nhuế 1",
                "P. Cổ Nhuế 2",
                "P. Phú Diễn",
                "P. Phúc Diễn"
             ],
             "codeErp":"0110"
          },
          {
             "n":"H. Mê Linh",
             "w":[
                "TT. Chi Đông",
                "X. Đại Thịnh",
                "X. Kim Hoa",
                "X. Thạch Đà",
                "X. Tiến Thắng",
                "X. Tự Lập",
                "TT. Quang Minh",
                "X. Thanh Lâm",
                "X. Tam Đồng",
                "X. Liên Mạc",
                "X. Vạn Yên",
                "X. Chu Phan",
                "X. Tiến Thịnh",
                "X. Mê Linh",
                "X. Văn Khê",
                "X. Hoàng Kim",
                "X. Tiền Phong",
                "X. Tráng Việt"
             ],
             "codeErp":"0129"
          },
          {
             "n":"Q. Hà Đông",
             "w":[
                "P. Nguyễn Trãi",
                "P. Mộ Lao",
                "P. Văn Quán",
                "P. Vạn Phúc",
                "P. Yết Kiêu",
                "P. Quang Trung",
                "P. La Khê",
                "P. Phú La",
                "P. Phúc La",
                "P. Hà Cầu",
                "P. Yên Nghĩa",
                "P. Kiến Hưng",
                "P. Phú Lãm",
                "P. Phú Lương",
                "P. Dương Nội",
                "P. Đồng Mai",
                "P. Biên Giang"
             ],
             "codeErp":"0115"
          },
          {
             "n":"TX. Sơn Tây",
             "w":[
                "P. Lê Lợi",
                "P. Phú Thịnh",
                "P. Ngô Quyền",
                "P. Quang Trung",
                "P. Sơn Lộc",
                "P. Xuân Khanh",
                "X. Đường Lâm",
                "P. Viên Sơn",
                "X. Xuân Sơn",
                "P. Trung Hưng",
                "X. Thanh Mỹ",
                "P. Trung Sơn Trầm",
                "X. Kim Sơn",
                "X. Sơn Đông",
                "X. Cổ Đông"
             ],
             "codeErp":"0116"
          },
          {
             "n":"H. Ba Vì",
             "w":[
                "TT. Tây Đằng",
                "X. Phú Cường",
                "X. Cổ Đô",
                "X. Tản Hồng",
                "X. Vạn Thắng",
                "X. Châu Sơn",
                "X. Phong Vân",
                "X. Phú Đông",
                "X. Phú Phương",
                "X. Phú Châu",
                "X. Thái Hòa",
                "X. Đồng Thái",
                "X. Phú Sơn",
                "X. Minh Châu",
                "X. Vật Lại",
                "X. Chu Minh",
                "X. Tòng Bạt",
                "X. Cẩm Lĩnh",
                "X. Sơn Đà",
                "X. Đông Quang",
                "X. Tiên Phong",
                "X. Thụy An",
                "X. Cam Thượng",
                "X. Thuần Mỹ",
                "X. Tản Lĩnh",
                "X. Ba Trại",
                "X. Minh Quang",
                "X. Ba Vì",
                "X. Vân Hòa",
                "X. Yên Bài",
                "X. Khánh Thượng"
             ],
             "codeErp":"0117"
          },
          {
             "n":"H. Phúc Thọ",
             "w":[
                "TT. Phúc Thọ",
                "X. Vân Hà",
                "X. Vân Phúc",
                "X. Vân Nam",
                "X. Xuân Đình",
                "X. Sen Phương",
                "X. Võng Xuyên",
                "X. Thọ Lộc",
                "X. Long Xuyên",
                "X. Thượng Cốc",
                "X. Hát Môn",
                "X. Tích Giang",
                "X. Thanh Đa",
                "X. Trạch Mỹ Lộc",
                "X. Phúc Hòa",
                "X. Ngọc Tảo",
                "X. Phụng Thượng",
                "X. Tam Thuấn",
                "X. Tam Hiệp",
                "X. Hiệp Thuận",
                "X. Liên Hiệp"
             ],
             "codeErp":"0118"
          },
          {
             "n":"H. Đan Phượng",
             "w":[
                "TT. Phùng",
                "X. Trung Châu",
                "X. Thọ An",
                "X. Thọ Xuân",
                "X. Hồng Hà",
                "X. Liên Hồng",
                "X. Liên Hà",
                "X. Hạ Mỗ",
                "X. Liên Trung",
                "X. Phương Đình",
                "X. Thượng Mỗ",
                "X. Tân Hội",
                "X. Tân Lập",
                "X. Đan Phượng",
                "X. Đồng Tháp",
                "X. Song Phượng"
             ],
             "codeErp":"0122"
          },
          {
             "n":"H. Hoài Đức",
             "w":[
                "TT. Trạm Trôi",
                "X. Đức Thượng",
                "X. Minh Khai",
                "X. Dương Liễu",
                "X. Di Trạch",
                "X. Đức Giang",
                "X. Cát Quế",
                "X. Kim Chung",
                "X. Yên Sở",
                "X. Sơn Đồng",
                "X. Vân Canh",
                "X. Đắc Sở",
                "X. Lại Yên",
                "X. Tiền Yên",
                "X. Song Phương",
                "X. An Khánh",
                "X. An Thượng",
                "X. Vân Côn",
                "X. La Phù",
                "X. Đông La"
             ],
             "codeErp":"0123"
          },
          {
             "n":"H. Quốc Oai",
             "w":[
                "X. Đông Xuân",
                "TT. Quốc Oai",
                "X. Sài Sơn",
                "X. Phượng Cách",
                "X. Yên Sơn",
                "X. Ngọc Liệp",
                "X. Ngọc Mỹ",
                "X. Liệp Tuyết",
                "X. Thạch Thán",
                "X. Đồng Quang",
                "X. Phú Cát",
                "X. Tuyết Nghĩa",
                "X. Nghĩa Hương",
                "X. Cộng Hòa",
                "X. Tân Phú",
                "X. Đại Thành",
                "X. Phú Mãn",
                "X. Cấn Hữu",
                "X. Tân Hòa",
                "X. Hòa Thạch",
                "X. Đông Yên"
             ],
             "codeErp":"0120"
          },
          {
             "n":"H. Thạch Thất",
             "w":[
                "X. Yên Trung",
                "X. Yên Bình",
                "X. Tiến Xuân",
                "TT. Liên Quan",
                "X. Đại Đồng",
                "X. Cẩm Yên",
                "X. Lại Thượng",
                "X. Phú Kim",
                "X. Hương Ngải",
                "X. Canh Nậu",
                "X. Kim Quan",
                "X. Dị Nậu",
                "X. Bình Yên",
                "X. Chàng Sơn",
                "X. Thạch Hoà",
                "X. Cần Kiệm",
                "X. Hữu Bằng",
                "X. Phùng Xá",
                "X. Tân Xã",
                "X. Thạch Xá",
                "X. Bình Phú",
                "X. Hạ Bằng",
                "X. Đồng Trúc"
             ],
             "codeErp":"0119"
          },
          {
             "n":"H. Chương Mỹ",
             "w":[
                "TT. Chúc Sơn",
                "TT. Xuân Mai",
                "X. Phụng Châu",
                "X. Tiên Phương",
                "X. Đông Sơn",
                "X. Đông Phương Yên",
                "X. Phú Nghĩa",
                "X. Trường Yên",
                "X. Ngọc Hòa",
                "X. Thủy Xuân Tiên",
                "X. Thanh Bình",
                "X. Trung Hòa",
                "X. Đại Yên",
                "X. Thụy Hương",
                "X. Tốt Động",
                "X. Lam Điền",
                "X. Tân Tiến",
                "X. Nam Phương Tiến",
                "X. Hợp Đồng",
                "X. Hoàng Văn Thụ",
                "X. Hoàng Diệu",
                "X. Hữu Văn",
                "X. Quảng Bị",
                "X. Mỹ Lương",
                "X. Thượng Vực",
                "X. Hồng Phong",
                "X. Đồng Phú",
                "X. Trần Phú",
                "X. Văn Võ",
                "X. Đồng Lạc",
                "X. Hòa Chính",
                "X. Phú Nam An"
             ],
             "codeErp":"0121"
          },
          {
             "n":"H. Thanh Oai",
             "w":[
                "TT. Kim Bài",
                "X. Cự Khê",
                "X. Bích Hòa",
                "X. Mỹ Hưng",
                "X. Cao Viên",
                "X. Bình Minh",
                "X. Tam Hưng",
                "X. Thanh Cao",
                "X. Thanh Thùy",
                "X. Thanh Mai",
                "X. Thanh Văn",
                "X. Đỗ Động",
                "X. Kim An",
                "X. Kim Thư",
                "X. Phương Trung",
                "X. Tân Ước",
                "X. Dân Hòa",
                "X. Liên Châu",
                "X. Cao Dương",
                "X. Xuân Dương",
                "X. Hồng Dương"
             ],
             "codeErp":"0124"
          },
          {
             "n":"H. Thường Tín",
             "w":[
                "TT. Thường Tín",
                "X. Ninh Sở",
                "X. Nhị Khê",
                "X. Duyên Thái",
                "X. Khánh Hà",
                "X. Hòa Bình",
                "X. Văn Bình",
                "X. Hiền Giang",
                "X. Hồng Vân",
                "X. Vân Tảo",
                "X. Liên Phương",
                "X. Văn Phú",
                "X. Tự Nhiên",
                "X. Tiền Phong",
                "X. Hà Hồi",
                "X. Thư Phú",
                "X. Nguyễn Trãi",
                "X. Quất Động",
                "X. Chương Dương",
                "X. Tân Minh",
                "X. Lê Lợi",
                "X. Thắng Lợi",
                "X. Dũng Tiến",
                "X. Thống Nhất",
                "X. Nghiêm Xuyên",
                "X. Tô Hiệu",
                "X. Văn Tự",
                "X. Vạn Điểm",
                "X. Minh Cường"
             ],
             "codeErp":"0127"
          },
          {
             "n":"H. Phú Xuyên",
             "w":[
                "TT. Phú Minh",
                "TT. Phú Xuyên",
                "X. Hồng Minh",
                "X. Phượng Dực",
                "X. Nam Tiến",
                "X. Tri Trung",
                "X. Đại Thắng",
                "X. Phú Túc",
                "X. Văn Hoàng",
                "X. Hồng Thái",
                "X. Hoàng Long",
                "X. Quang Trung",
                "X. Nam Phong",
                "X. Nam Triều",
                "X. Tân Dân",
                "X. Sơn Hà",
                "X. Chuyên Mỹ",
                "X. Khai Thái",
                "X. Phúc Tiến",
                "X. Vân Từ",
                "X. Tri Thủy",
                "X. Đại Xuyên",
                "X. Phú Yên",
                "X. Bạch Hạ",
                "X. Quang Lãng",
                "X. Châu Can",
                "X. Minh Tân"
             ],
             "codeErp":"0128"
          },
          {
             "n":"H. Ứng Hòa",
             "w":[
                "TT. Vân Đình",
                "X. Viên An",
                "X. Viên Nội",
                "X. Hoa Sơn",
                "X. Quảng Phú Cầu",
                "X. Trường Thịnh",
                "X. Cao Thành",
                "X. Liên Bạt",
                "X. Sơn Công",
                "X. Đồng Tiến",
                "X. Phương Tú",
                "X. Trung Tú",
                "X. Đồng Tân",
                "X. Tảo Dương Văn",
                "X. Vạn Thái",
                "X. Minh Đức",
                "X. Hòa Lâm",
                "X. Hòa Xá",
                "X. Trầm Lộng",
                "X. Kim Đường",
                "X. Hòa Nam",
                "X. Hòa Phú",
                "X. Đội Bình",
                "X. Đại Hùng",
                "X. Đông Lỗ",
                "X. Phù Lưu",
                "X. Đại Cường",
                "X. Lưu Hoàng",
                "X. Hồng Quang"
             ],
             "codeErp":"0126"
          },
          {
             "n":"H. Mỹ Đức",
             "w":[
                "TT. Đại Nghĩa",
                "X. Đồng Tâm",
                "X. Thượng Lâm",
                "X. Tuy Lai",
                "X. Phúc Lâm",
                "X. Mỹ Thành",
                "X. Bột Xuyên",
                "X. An Mỹ",
                "X. Hồng Sơn",
                "X. Lê Thanh",
                "X. Xuy Xá",
                "X. Phùng Xá",
                "X. Phù Lưu Tế",
                "X. Đại Hưng",
                "X. Vạn Kim",
                "X. Đốc Tín",
                "X. Hương Sơn",
                "X. Hùng Tiến",
                "X. An Tiến",
                "X. Hợp Tiến",
                "X. Hợp Thanh",
                "X. An Phú"
             ],
             "codeErp":"0125"
          }
       ],
       "n":"Tp. Hà Nội",
       "codeErp":"01"
    },
    {
       "d":[
          {
             "n":"TP. Cao Bằng",
             "w":[
                "P. Sông Hiến",
                "P. Sông Bằng",
                "P. Hợp Giang",
                "P. Tân Giang",
                "P. Ngọc Xuân",
                "P. Đề Thám",
                "P. Hoà Chung",
                "P. Duyệt Trung",
                "X. Vĩnh Quang",
                "X. Hưng Đạo",
                "X. Chu Trinh"
             ],
             "codeErp":"0601"
          },
          {
             "n":"H. Bảo Lâm",
             "w":[
                "TT. Pác Miầu",
                "X. Đức Hạnh",
                "X. Lý Bôn",
                "X. Nam Cao",
                "X. Nam Quang",
                "X. Vĩnh Quang",
                "X. Quảng Lâm",
                "X. Thạch Lâm",
                "X. Vĩnh Phong",
                "X. Mông Ân",
                "X. Thái Học",
                "X. Thái Sơn",
                "X. Yên Thổ"
             ],
             "codeErp":"0612"
          },
          {
             "n":"H. Bảo Lạc",
             "w":[
                "TT. Bảo Lạc",
                "X. Cốc Pàng",
                "X. Thượng Hà",
                "X. Cô Ba",
                "X. Bảo Toàn",
                "X. Khánh Xuân",
                "X. Xuân Trường",
                "X. Hồng Trị",
                "X. Kim Cúc",
                "X. Phan Thanh",
                "X. Hồng An",
                "X. Hưng Đạo",
                "X. Hưng Thịnh",
                "X. Huy Giáp",
                "X. Đình Phùng",
                "X. Sơn Lập",
                "X. Sơn Lộ"
             ],
             "codeErp":"0602"
          },
          {
             "n":"H. Hà Quảng",
             "w":[
                "TT. Thông Nông",
                "X. Cần Yên",
                "X. Cần Nông",
                "X. Lương Thông",
                "X. Đa Thông",
                "X. Ngọc Động",
                "X. Yên Sơn",
                "X. Lương Can",
                "X. Thanh Long",
                "TT. Xuân Hòa",
                "X. Lũng Nặm",
                "X. Trường Hà",
                "X. Cải Viên",
                "X. Nội Thôn",
                "X. Tổng Cọt",
                "X. Sóc Hà",
                "X. Thượng Thôn",
                "X. Hồng Sỹ",
                "X. Quý Quân",
                "X. Mã Ba",
                "X. Ngọc Đào"
             ],
             "codeErp":"0604"
          },
          {
             "n":"H. Trùng Khánh",
             "w":[
                "TT. Trà Lĩnh",
                "X. Tri Phương",
                "X. Quang Hán",
                "X. Xuân Nội",
                "X. Quang Trung",
                "X. Quang Vinh",
                "X. Cao Chương",
                "TT. Trùng Khánh",
                "X. Ngọc Khê",
                "X. Ngọc Côn",
                "X. Phong Nậm",
                "X. Đình Phong",
                "X. Đàm Thuỷ",
                "X. Khâm Thành",
                "X. Chí Viễn",
                "X. Lăng Hiếu",
                "X. Phong Châu",
                "X. Trung Phúc",
                "X. Cao Thăng",
                "X. Đức Hồng",
                "X. Đoài Dương"
             ],
             "codeErp":"0606"
          },
          {
             "n":"H. Hạ Lang",
             "w":[
                "X. Minh Long",
                "X. Lý Quốc",
                "X. Thắng Lợi",
                "X. Đồng Loan",
                "X. Đức Quang",
                "X. Kim Loan",
                "X. Quang Long",
                "X. An Lạc",
                "TT. Thanh Nhật",
                "X. Vinh Quý",
                "X. Thống Nhất",
                "X. Cô Ngân",
                "X. Thị Hoa"
             ],
             "codeErp":"0611"
          },
          {
             "n":"H. Quảng Hòa",
             "w":[
                "X. Quốc Toản",
                "TT. Quảng Uyên",
                "X. Phi Hải",
                "X. Quảng Hưng",
                "X. Độc Lập",
                "X. Cai Bộ",
                "X. Phúc Sen",
                "X. Chí Thảo",
                "X. Tự Do",
                "X. Hồng Quang",
                "X. Ngọc Động",
                "X. Hạnh Phúc",
                "TT. Tà Lùng",
                "X. Bế Văn Đàn",
                "X. Cách Linh",
                "X. Đại Sơn",
                "X. Tiên Thành",
                "TT. Hoà Thuận",
                "X. Mỹ Hưng"
             ],
             "codeErp":""
          },
          {
             "n":"H. Hoà An",
             "w":[
                "TT. Nước Hai",
                "X. Dân Chủ",
                "X. Nam Tuấn",
                "X. Đại Tiến",
                "X. Đức Long",
                "X. Ngũ Lão",
                "X. Trương Lương",
                "X. Hồng Việt",
                "X. Hoàng Tung",
                "X. Nguyễn Huệ",
                "X. Quang Trung",
                "X. Bạch Đằng",
                "X. Bình Dương",
                "X. Lê Chung",
                "X. Hồng Nam"
             ],
             "codeErp":"0608"
          },
          {
             "n":"H. Nguyên Bình",
             "w":[
                "TT. Nguyên Bình",
                "TT. Tĩnh Túc",
                "X. Yên Lạc",
                "X. Triệu Nguyên",
                "X. Ca Thành",
                "X. Vũ Nông",
                "X. Minh Tâm",
                "X. Thể Dục",
                "X. Mai Long",
                "X. Vũ Minh",
                "X. Hoa Thám",
                "X. Phan Thanh",
                "X. Quang Thành",
                "X. Tam Kim",
                "X. Thành Công",
                "X. Thịnh Vượng",
                "X. Hưng Đạo"
             ],
             "codeErp":"0607"
          },
          {
             "n":"H. Thạch An",
             "w":[
                "TT. Đông Khê",
                "X. Canh Tân",
                "X. Kim Đồng",
                "X. Minh Khai",
                "X. Đức Thông",
                "X. Thái Cường",
                "X. Vân Trình",
                "X. Thụy Hùng",
                "X. Quang Trọng",
                "X. Trọng Con",
                "X. Lê Lai",
                "X. Đức Long",
                "X. Lê Lợi",
                "X. Đức Xuân"
             ],
             "codeErp":"0610"
          }
       ],
       "n":"Cao Bằng",
       "codeErp":"06"
    },
    {
       "d":[
          {
             "n":"TP. Hà Giang",
             "w":[
                "P. Quang Trung",
                "P. Trần Phú",
                "P. Ngọc Hà",
                "P. Nguyễn Trãi",
                "P. Minh Khai",
                "X. Ngọc Đường",
                "X. Phương Độ",
                "X. Phương Thiện"
             ],
             "codeErp":"0501"
          },
          {
             "n":"H. Đồng Văn",
             "w":[
                "TT. Phó Bảng",
                "X. Lũng Cú",
                "X. Má Lé",
                "TT. Đồng Văn",
                "X. Lũng Táo",
                "X. Phố Là",
                "X. Thài Phìn Tủng",
                "X. Sủng Là",
                "X. Xà Phìn",
                "X. Tả Phìn",
                "X. Tả Lủng",
                "X. Phố Cáo",
                "X. Sính Lủng",
                "X. Sảng Tủng",
                "X. Lũng Thầu",
                "X. Hố Quáng Phìn",
                "X. Vần Chải",
                "X. Lũng Phìn",
                "X. Sủng Trái"
             ],
             "codeErp":"0502"
          },
          {
             "n":"H. Mèo Vạc",
             "w":[
                "TT. Mèo Vạc",
                "X. Thượng Phùng",
                "X. Pải Lủng",
                "X. Xín Cái",
                "X. Pả Vi",
                "X. Giàng Chu Phìn",
                "X. Sủng Trà",
                "X. Sủng Máng",
                "X. Sơn Vĩ",
                "X. Tả Lủng",
                "X. Cán Chu Phìn",
                "X. Lũng Pù",
                "X. Lũng Chinh",
                "X. Tát Ngà",
                "X. Nậm Ban",
                "X. Khâu Vai",
                "X. Niêm Tòng",
                "X. Niêm Sơn"
             ],
             "codeErp":"0503"
          },
          {
             "n":"H. Yên Minh",
             "w":[
                "TT. Yên Minh",
                "X. Thắng Mố",
                "X. Phú Lũng",
                "X. Sủng Tráng",
                "X. Bạch Đích",
                "X. Na Khê",
                "X. Sủng Thài",
                "X. Hữu Vinh",
                "X. Lao Và Chải",
                "X. Mậu Duệ",
                "X. Đông Minh",
                "X. Mậu Long",
                "X. Ngam La",
                "X. Ngọc Long",
                "X. Đường Thượng",
                "X. Lũng Hồ",
                "X. Du Tiến",
                "X. Du Già"
             ],
             "codeErp":"0504"
          },
          {
             "n":"H. Quản Bạ",
             "w":[
                "TT. Tam Sơn",
                "X. Bát Đại Sơn",
                "X. Nghĩa Thuận",
                "X. Cán Tỷ",
                "X. Cao Mã Pờ",
                "X. Thanh Vân",
                "X. Tùng Vài",
                "X. Đông Hà",
                "X. Quản Bạ",
                "X. Lùng Tám",
                "X. Quyết Tiến",
                "X. Tả Ván",
                "X. Thái An"
             ],
             "codeErp":"0505"
          },
          {
             "n":"H. Vị Xuyên",
             "w":[
                "X. Kim Thạch",
                "X. Phú Linh",
                "X. Kim Linh",
                "TT. Vị Xuyên",
                "TT. Nông Trường Việt Lâm",
                "X. Minh Tân",
                "X. Thuận Hoà",
                "X. Tùng Bá",
                "X. Thanh Thủy",
                "X. Thanh Đức",
                "X. Phong Quang",
                "X. Xín Chải",
                "X. Phương Tiến",
                "X. Lao Chải",
                "X. Cao Bồ",
                "X. Đạo Đức",
                "X. Thượng Sơn",
                "X. Linh Hồ",
                "X. Quảng Ngần",
                "X. Việt Lâm",
                "X. Ngọc Linh",
                "X. Ngọc Minh",
                "X. Bạch Ngọc",
                "X. Trung Thành"
             ],
             "codeErp":"0506"
          },
          {
             "n":"H. Bắc Mê",
             "w":[
                "X. Minh Sơn",
                "X. Giáp Trung",
                "X. Yên Định",
                "TT. Yên Phú",
                "X. Minh Ngọc",
                "X. Yên Phong",
                "X. Lạc Nông",
                "X. Phú Nam",
                "X. Yên Cường",
                "X. Thượng Tân",
                "X. Đường Âm",
                "X. Đường Hồng",
                "X. Phiêng Luông"
             ],
             "codeErp":"0507"
          },
          {
             "n":"H. Hoàng Su Phì",
             "w":[
                "TT. Vinh Quang",
                "X. Bản Máy",
                "X. Thàng Tín",
                "X. Thèn Chu Phìn",
                "X. Pố Lồ",
                "X. Bản Phùng",
                "X. Túng Sán",
                "X. Chiến Phố",
                "X. Đản Ván",
                "X. Tụ Nhân",
                "X. Tân Tiến",
                "X. Nàng Đôn",
                "X. Pờ Ly Ngài",
                "X. Sán Xả Hồ",
                "X. Bản Luốc",
                "X. Ngàm Đăng Vài",
                "X. Bản Nhùng",
                "X. Tả Sử Choóng",
                "X. Nậm Dịch",
                "X. Hồ Thầu",
                "X. Nam Sơn",
                "X. Nậm Tỵ",
                "X. Thông Nguyên",
                "X. Nậm Khòa"
             ],
             "codeErp":"0508"
          },
          {
             "n":"H. Xín Mần",
             "w":[
                "TT. Cốc Pài",
                "X. Nàn Xỉn",
                "X. Bản Díu",
                "X. Chí Cà",
                "X. Xín Mần",
                "X. Thèn Phàng",
                "X. Trung Thịnh",
                "X. Pà Vầy Sủ",
                "X. Cốc Rế",
                "X. Thu Tà",
                "X. Nàn Ma",
                "X. Tả Nhìu",
                "X. Bản Ngò",
                "X. Chế Là",
                "X. Nấm Dẩn",
                "X. Quảng Nguyên",
                "X. Nà Chì",
                "X. Khuôn Lùng"
             ],
             "codeErp":"0509"
          },
          {
             "n":"H. Bắc Quang",
             "w":[
                "TT. Việt Quang",
                "TT. Vĩnh Tuy",
                "X. Tân Lập",
                "X. Tân Thành",
                "X. Đồng Tiến",
                "X. Đồng Tâm",
                "X. Tân Quang",
                "X. Thượng Bình",
                "X. Hữu Sản",
                "X. Kim Ngọc",
                "X. Việt Vinh",
                "X. Bằng Hành",
                "X. Quang Minh",
                "X. Liên Hiệp",
                "X. Vô Điếm",
                "X. Việt Hồng",
                "X. Hùng An",
                "X. Đức Xuân",
                "X. Tiên Kiều",
                "X. Vĩnh Hảo",
                "X. Vĩnh Phúc",
                "X. Đồng Yên",
                "X. Đông Thành"
             ],
             "codeErp":"0510"
          },
          {
             "n":"H. Quang Bình",
             "w":[
                "X. Xuân Minh",
                "X. Tiên Nguyên",
                "X. Tân Nam",
                "X. Bản Rịa",
                "X. Yên Thành",
                "TT. Yên Bình",
                "X. Tân Trịnh",
                "X. Tân Bắc",
                "X. Bằng Lang",
                "X. Yên Hà",
                "X. Hương Sơn",
                "X. Xuân Giang",
                "X. Nà Khương",
                "X. Tiên Yên",
                "X. Vĩ Thượng"
             ],
             "codeErp":"0511"
          }
       ],
       "n":"Hà Giang",
       "codeErp":"05"
    },
    {
       "d":[
          {
             "n":"TP. Tuyên Quang",
             "w":[
                "P. Phan Thiết",
                "P. Minh Xuân",
                "P. Tân Quang",
                "X. Tràng Đà",
                "P. Nông Tiến",
                "P. Ỷ La",
                "P. Tân Hà",
                "P. Hưng Thành",
                "X. Kim Phú",
                "X. An Khang",
                "P. Mỹ Lâm",
                "P. An Tường",
                "X. Lưỡng Vượng",
                "X. Thái Long",
                "P. Đội Cấn"
             ],
             "codeErp":"0901"
          },
          {
             "n":"H. Lâm Bình",
             "w":[
                "X. Phúc Yên",
                "X. Xuân Lập",
                "X. Khuôn Hà",
                "X. Lăng Can",
                "X. Thượng Lâm",
                "X. Bình An",
                "X. Hồng Quang",
                "X. Thổ Bình"
             ],
             "codeErp":"0902"
          },
          {
             "n":"H. Na Hang",
             "w":[
                "TT. Na Hang",
                "X. Sinh Long",
                "X. Thượng Giáp",
                "X. Thượng Nông",
                "X. Côn Lôn",
                "X. Yên Hoa",
                "X. Hồng Thái",
                "X. Đà Vị",
                "X. Khau Tinh",
                "X. Sơn Phú",
                "X. Năng Khả",
                "X. Thanh Tương"
             ],
             "codeErp":"0903"
          },
          {
             "n":"H. Chiêm Hóa",
             "w":[
                "TT. Vĩnh Lộc",
                "X. Phúc Sơn",
                "X. Minh Quang",
                "X. Trung Hà",
                "X. Tân Mỹ",
                "X. Hà Lang",
                "X. Hùng Mỹ",
                "X. Yên Lập",
                "X. Tân An",
                "X. Bình Phú",
                "X. Xuân Quang",
                "X. Ngọc Hội",
                "X. Phú Bình",
                "X. Hòa Phú",
                "X. Phúc Thịnh",
                "X. Kiên Đài",
                "X. Tân Thịnh",
                "X. Trung Hòa",
                "X. Kim Bình",
                "X. Hòa An",
                "X. Vinh Quang",
                "X. Tri Phú",
                "X. Nhân Lý",
                "X. Yên Nguyên",
                "X. Linh Phú",
                "X. Bình Nhân"
             ],
             "codeErp":"0904"
          },
          {
             "n":"H. Hàm Yên",
             "w":[
                "TT. Tân Yên",
                "X. Yên Thuận",
                "X. Bạch Xa",
                "X. Minh Khương",
                "X. Yên Lâm",
                "X. Minh Dân",
                "X. Phù Lưu",
                "X. Minh Hương",
                "X. Yên Phú",
                "X. Tân Thành",
                "X. Bình Xa",
                "X. Thái Sơn",
                "X. Nhân Mục",
                "X. Thành Long",
                "X. Bằng Cốc",
                "X. Thái Hòa",
                "X. Đức Ninh",
                "X. Hùng Đức"
             ],
             "codeErp":"0905"
          },
          {
             "n":"H. Yên Sơn",
             "w":[
                "X. Quí Quân",
                "X. Lực Hành",
                "X. Kiến Thiết",
                "X. Trung Minh",
                "X. Chiêu Yên",
                "X. Trung Trực",
                "X. Xuân Vân",
                "X. Phúc Ninh",
                "X. Hùng Lợi",
                "X. Trung Sơn",
                "X. Tân Tiến",
                "X. Tứ Quận",
                "X. Đạo Viện",
                "X. Tân Long",
                "X. Thắng Quân",
                "X. Kim Quan",
                "X. Lang Quán",
                "X. Phú Thịnh",
                "X. Công Đa",
                "X. Trung Môn",
                "X. Chân Sơn",
                "X. Thái Bình",
                "X. Tiến Bộ",
                "X. Mỹ Bằng",
                "X. Hoàng Khai",
                "X. Nhữ Hán",
                "X. Nhữ Khê",
                "X. Đội Bình"
             ],
             "codeErp":"0906"
          },
          {
             "n":"H. Sơn Dương",
             "w":[
                "TT. Sơn Dương",
                "X. Trung Yên",
                "X. Minh Thanh",
                "X. Tân Trào",
                "X. Vĩnh Lợi",
                "X. Thượng Ấm",
                "X. Bình Yên",
                "X. Lương Thiện",
                "X. Tú Thịnh",
                "X. Cấp Tiến",
                "X. Hợp Thành",
                "X. Phúc Ứng",
                "X. Đông Thọ",
                "X. Kháng Nhật",
                "X. Hợp Hòa",
                "X. Quyết Thắng",
                "X. Đồng Quý",
                "X. Tân Thanh",
                "X. Vân Sơn",
                "X. Văn Phú",
                "X. Chi Thiết",
                "X. Đông Lợi",
                "X. Thiện Kế",
                "X. Hồng Lạc",
                "X. Phú Lương",
                "X. Ninh Lai",
                "X. Đại Phú",
                "X. Sơn Nam",
                "X. Hào Phú",
                "X. Tam Đa",
                "X. Trường Sinh"
             ],
             "codeErp":"0907"
          }
       ],
       "n":"Tuyên Quang",
       "codeErp":"09"
    },
    {
       "d":[
          {
             "n":"TP. Bắc Kạn",
             "w":[
                "P. Nguyễn Thị Minh Khai",
                "P. Sông Cầu",
                "P. Đức Xuân",
                "P. Phùng Chí Kiên",
                "P. Huyền Tụng",
                "X. Dương Quang",
                "X. Nông Thượng",
                "P. Xuất Hóa"
             ],
             "codeErp":"1101"
          },
          {
             "n":"H. Pác Nặm",
             "w":[
                "X. Bằng Thành",
                "X. Nhạn Môn",
                "X. Bộc Bố",
                "X. Công Bằng",
                "X. Giáo Hiệu",
                "X. Xuân La",
                "X. An Thắng",
                "X. Cổ Linh",
                "X. Nghiên Loan",
                "X. Cao Tân"
             ],
             "codeErp":"1108"
          },
          {
             "n":"H. Ba Bể",
             "w":[
                "TT. Chợ Rã",
                "X. Bành Trạch",
                "X. Phúc Lộc",
                "X. Hà Hiệu",
                "X. Cao Thượng",
                "X. Khang Ninh",
                "X. Nam Mẫu",
                "X. Thượng Giáo",
                "X. Địa Linh",
                "X. Yến Dương",
                "X. Chu Hương",
                "X. Quảng Khê",
                "X. Mỹ Phương",
                "X. Hoàng Trĩ",
                "X. Đồng Phúc"
             ],
             "codeErp":"1106"
          },
          {
             "n":"H. Ngân Sơn",
             "w":[
                "TT. Nà Phặc",
                "X. Thượng Ân",
                "X. Bằng Vân",
                "X. Cốc Đán",
                "X. Trung Hoà",
                "X. Đức Vân",
                "X. Vân Tùng",
                "X. Thượng Quan",
                "X. Hiệp Lực",
                "X. Thuần Mang"
             ],
             "codeErp":"1105"
          },
          {
             "n":"H. Bạch Thông",
             "w":[
                "TT. Phủ Thông",
                "X. Vi Hương",
                "X. Sĩ Bình",
                "X. Vũ Muộn",
                "X. Đôn Phong",
                "X. Lục Bình",
                "X. Tân Tú",
                "X. Nguyên Phúc",
                "X. Cao Sơn",
                "X. Quân Hà",
                "X. Cẩm Giàng",
                "X. Mỹ Thanh",
                "X. Dương Phong",
                "X. Quang Thuận"
             ],
             "codeErp":"1103"
          },
          {
             "n":"H. Chợ Đồn",
             "w":[
                "TT. Bằng Lũng",
                "X. Xuân Lạc",
                "X. Nam Cường",
                "X. Đồng Lạc",
                "X. Tân Lập",
                "X. Bản Thi",
                "X. Quảng Bạch",
                "X. Bằng Phúc",
                "X. Yên Thịnh",
                "X. Yên Thượng",
                "X. Phương Viên",
                "X. Ngọc Phái",
                "X. Đồng Thắng",
                "X. Lương Bằng",
                "X. Bằng Lãng",
                "X. Đại Sảo",
                "X. Nghĩa Tá",
                "X. Yên Mỹ",
                "X. Bình Trung",
                "X. Yên Phong"
             ],
             "codeErp":"1102"
          },
          {
             "n":"H. Chợ Mới",
             "w":[
                "TT. Đồng Tâm",
                "X. Tân Sơn",
                "X. Thanh Vận",
                "X. Mai Lạp",
                "X. Hoà Mục",
                "X. Thanh Mai",
                "X. Cao Kỳ",
                "X. Nông Hạ",
                "X. Yên Cư",
                "X. Thanh Thịnh",
                "X. Yên Hân",
                "X. Như Cố",
                "X. Bình Văn",
                "X. Quảng Chu"
             ],
             "codeErp":"1107"
          },
          {
             "n":"H. Na Rì",
             "w":[
                "X. Văn Vũ",
                "X. Văn Lang",
                "X. Lương Thượng",
                "X. Kim Hỷ",
                "X. Cường Lợi",
                "TT. Yến Lạc",
                "X. Kim Lư",
                "X. Sơn Thành",
                "X. Văn Minh",
                "X. Côn Minh",
                "X. Cư Lễ",
                "X. Trần Phú",
                "X. Quang Phong",
                "X. Dương Sơn",
                "X. Xuân Dương",
                "X. Đổng Xá",
                "X. Liêm Thuỷ"
             ],
             "codeErp":"1104"
          }
       ],
       "n":"Bắc Kạn",
       "codeErp":"11"
    },
    {
       "d":[
          {
             "n":"TP. Điện Biên Phủ",
             "w":[
                "P. Noong Bua",
                "P. Him Lam",
                "P. Thanh Bình",
                "P. Tân Thanh",
                "P. Mường Thanh",
                "P. Nam Thanh",
                "P. Thanh Trường",
                "X. Thanh Minh",
                "X. Nà Tấu",
                "X. Nà Nhạn",
                "X. Mường Phăng",
                "X. Pá Khoang"
             ],
             "codeErp":"6201"
          },
          {
             "n":"TX. Mường Lay",
             "w":[
                "P. Sông Đà",
                "P. Na Lay",
                "X. Lay Nưa"
             ],
             "codeErp":"6202"
          },
          {
             "n":"H. Mường Nhé",
             "w":[
                "X. Sín Thầu",
                "X. Sen Thượng",
                "X. Chung Chải",
                "X. Leng Su Sìn",
                "X. Pá Mỳ",
                "X. Mường Nhé",
                "X. Nậm Vì",
                "X. Nậm Kè",
                "X. Mường Toong",
                "X. Quảng Lâm",
                "X. Huổi Lếnh"
             ],
             "codeErp":"6208"
          },
          {
             "n":"H. Mường Chà",
             "w":[
                "TT. Mường Chà",
                "X. Xá Tổng",
                "X. Mường Tùng",
                "X. Hừa Ngài",
                "X. Huổi Mí",
                "X. Pa Ham",
                "X. Nậm Nèn",
                "X. Huổi Lèng",
                "X. Sa Lông",
                "X. Ma Thì Hồ",
                "X. Na Sang",
                "X. Mường Mươn"
             ],
             "codeErp":"6205"
          },
          {
             "n":"H. Tủa Chùa",
             "w":[
                "TT. Tủa Chùa",
                "X. Huổi Só",
                "X. Xín Chải",
                "X. Tả Sìn Thàng",
                "X. Lao Xả Phình",
                "X. Tả Phìn",
                "X. Tủa Thàng",
                "X. Trung Thu",
                "X. Sính Phình",
                "X. Sáng Nhè",
                "X. Mường Đun",
                "X. Mường Báng"
             ],
             "codeErp":"6206"
          },
          {
             "n":"H. Tuần Giáo",
             "w":[
                "TT. Tuần Giáo",
                "X. Phình Sáng",
                "X. Rạng Đông",
                "X. Mùn Chung",
                "X. Nà Tòng",
                "X. Ta Ma",
                "X. Mường Mùn",
                "X. Pú Xi",
                "X. Pú Nhung",
                "X. Quài Nưa",
                "X. Mường Thín",
                "X. Tỏa Tình",
                "X. Nà Sáy",
                "X. Mường Khong",
                "X. Quài Cang",
                "X. Quài Tở",
                "X. Chiềng Sinh",
                "X. Chiềng Đông",
                "X. Tênh Phông"
             ],
             "codeErp":"6204"
          },
          {
             "n":"H. Điện Biên",
             "w":[
                "X. Mường Pồn",
                "X. Thanh Nưa",
                "X. Hua Thanh",
                "X. Thanh Luông",
                "X. Thanh Hưng",
                "X. Thanh Xương",
                "X. Thanh Chăn",
                "X. Pa Thơm",
                "X. Thanh An",
                "X. Thanh Yên",
                "X. Noong Luống",
                "X. Noọng Hẹt",
                "X. Sam Mứn",
                "X. Pom Lót",
                "X. Núa Ngam",
                "X. Hẹ Muông",
                "X. Na Ư",
                "X. Mường Nhà",
                "X. Na Tông",
                "X. Mường Lói",
                "X. Phu Luông"
             ],
             "codeErp":"6203"
          },
          {
             "n":"H. Điện Biên Đông",
             "w":[
                "TT. Điện Biên Đông",
                "X. Na Son",
                "X. Phì Nhừ",
                "X. Chiềng Sơ",
                "X. Mường Luân",
                "X. Pú Nhi",
                "X. Nong U",
                "X. Xa Dung",
                "X. Keo Lôm",
                "X. Luân Giới",
                "X. Phình Giàng",
                "X. Pú Hồng",
                "X. Tìa Dình",
                "X. Háng Lìa"
             ],
             "codeErp":"6207"
          },
          {
             "n":"H. Mường Ảng",
             "w":[
                "TT. Mường Ảng",
                "X. Mường Đăng",
                "X. Ngối Cáy",
                "X. Ẳng Tở",
                "X. Búng Lao",
                "X. Xuân Lao",
                "X. Ẳng Nưa",
                "X. Ẳng Cang",
                "X. Nặm Lịch",
                "X. Mường Lạn"
             ],
             "codeErp":"6209"
          },
          {
             "n":"H. Nậm Pồ",
             "w":[
                "X. Nậm Tin",
                "X. Pa Tần",
                "X. Chà Cang",
                "X. Na Cô Sa",
                "X. Nà Khoa",
                "X. Nà Hỳ",
                "X. Nà Bủng",
                "X. Nậm Nhừ",
                "X. Nậm Chua",
                "X. Nậm Khăn",
                "X. Chà Tở",
                "X. Vàng Đán",
                "X. Chà Nưa",
                "X. Phìn Hồ",
                "X. Si Pa Phìn"
             ],
             "codeErp":"6210"
          }
       ],
       "n":"Điện Biên",
       "codeErp":"62"
    },
    {
       "d":[
          {
             "n":"TP. Sơn La",
             "w":[
                "P. Chiềng Lề",
                "P. Tô Hiệu",
                "P. Quyết Thắng",
                "P. Quyết Tâm",
                "X. Chiềng Cọ",
                "X. Chiềng Đen",
                "X. Chiềng Xôm",
                "P. Chiềng An",
                "P. Chiềng Cơi",
                "X. Chiềng Ngần",
                "X. Hua La",
                "P. Chiềng Sinh"
             ],
             "codeErp":"1401"
          },
          {
             "n":"H. Quỳnh Nhai",
             "w":[
                "X. Mường Chiên",
                "X. Cà Nàng",
                "X. Chiềng Khay",
                "X. Mường Giôn",
                "X. Pá Ma Pha Khinh",
                "X. Chiềng Ơn",
                "X. Mường Giàng",
                "X. Chiềng Bằng",
                "X. Mường Sại",
                "X. Nậm ét",
                "X. Chiềng Khoang"
             ],
             "codeErp":"1402"
          },
          {
             "n":"H. Thuận Châu",
             "w":[
                "TT. Thuận Châu",
                "X. Phổng Lái",
                "X. Mường é",
                "X. Chiềng Pha",
                "X. Chiềng La",
                "X. Chiềng Ngàm",
                "X. Liệp Tè",
                "X. é Tòng",
                "X. Phổng Lập",
                "X. Phổng Lăng",
                "X. Chiềng Ly",
                "X. Noong Lay",
                "X. Mường Khiêng",
                "X. Mường Bám",
                "X. Long Hẹ",
                "X. Chiềng Bôm",
                "X. Thôm Mòn",
                "X. Tông Lạnh",
                "X. Tông Cọ",
                "X. Bó Mười",
                "X. Co Mạ",
                "X. Púng Tra",
                "X. Chiềng Pấc",
                "X. Nậm Lầu",
                "X. Bon Phặng",
                "X. Co Tòng",
                "X. Muổi Nọi",
                "X. Pá Lông",
                "X. Bản Lầm"
             ],
             "codeErp":"1404"
          },
          {
             "n":"H. Mường La",
             "w":[
                "TT. Ít Ong",
                "X. Nậm Giôn",
                "X. Chiềng Lao",
                "X. Hua Trai",
                "X. Ngọc Chiến",
                "X. Mường Trai",
                "X. Nậm Păm",
                "X. Chiềng Muôn",
                "X. Chiềng Ân",
                "X. Pi Toong",
                "X. Chiềng Công",
                "X. Tạ Bú",
                "X. Chiềng San",
                "X. Mường Bú",
                "X. Chiềng Hoa",
                "X. Mường Chùm"
             ],
             "codeErp":"1403"
          },
          {
             "n":"H. Bắc Yên",
             "w":[
                "TT. Bắc Yên",
                "X. Phiêng Ban",
                "X. Hang Chú",
                "X. Xím Vàng",
                "X. Tà Xùa",
                "X. Háng Đồng",
                "X. Pắc Ngà",
                "X. Làng Chếu",
                "X. Chim Vàn",
                "X. Mường Khoa",
                "X. Song Pe",
                "X. Hồng Ngài",
                "X. Tạ Khoa",
                "X. Hua Nhàn",
                "X. Phiêng Côn",
                "X. Chiềng Sại"
             ],
             "codeErp":"1405"
          },
          {
             "n":"H. Phù Yên",
             "w":[
                "TT. Phù Yên",
                "X. Suối Tọ",
                "X. Mường Thải",
                "X. Mường Cơi",
                "X. Quang Huy",
                "X. Huy Bắc",
                "X. Huy Thượng",
                "X. Tân Lang",
                "X. Gia Phù",
                "X. Tường Phù",
                "X. Huy Hạ",
                "X. Huy Tân",
                "X. Mường Lang",
                "X. Suối Bau",
                "X. Huy Tường",
                "X. Mường Do",
                "X. Sập Xa",
                "X. Tường Thượng",
                "X. Tường Tiến",
                "X. Tường Phong",
                "X. Tường Hạ",
                "X. Kim Bon",
                "X. Mường Bang",
                "X. Đá Đỏ",
                "X. Tân Phong",
                "X. Nam Phong",
                "X. Bắc Phong"
             ],
             "codeErp":"1406"
          },
          {
             "n":"H. Mộc Châu",
             "w":[
                "TT. Mộc Châu",
                "TT. NT Mộc Châu",
                "X. Chiềng Sơn",
                "X. Tân Hợp",
                "X. Qui Hướng",
                "X. Tân Lập",
                "X. Nà Mường",
                "X. Tà Lai",
                "X. Chiềng Hắc",
                "X. Hua Păng",
                "X. Chiềng Khừa",
                "X. Mường Sang",
                "X. Đông Sang",
                "X. Phiêng Luông",
                "X. Lóng Sập"
             ],
             "codeErp":"1410"
          },
          {
             "n":"H. Yên Châu",
             "w":[
                "TT. Yên Châu",
                "X. Chiềng Đông",
                "X. Sập Vạt",
                "X. Chiềng Sàng",
                "X. Chiềng Pằn",
                "X. Viêng Lán",
                "X. Chiềng Hặc",
                "X. Mường Lựm",
                "X. Chiềng On",
                "X. Yên Sơn",
                "X. Chiềng Khoi",
                "X. Tú Nang",
                "X. Lóng Phiêng",
                "X. Phiêng Khoài",
                "X. Chiềng Tương"
             ],
             "codeErp":"1408"
          },
          {
             "n":"H. Mai Sơn",
             "w":[
                "TT. Hát Lót",
                "X. Chiềng Sung",
                "X. Mường Bằng",
                "X. Chiềng Chăn",
                "X. Mương Chanh",
                "X. Chiềng Ban",
                "X. Chiềng Mung",
                "X. Mường Bon",
                "X. Chiềng Chung",
                "X. Chiềng Mai",
                "X. Hát Lót",
                "X. Nà Pó",
                "X. Cò  Nòi",
                "X. Chiềng Nơi",
                "X. Phiêng Cằm",
                "X. Chiềng Dong",
                "X. Chiềng Kheo",
                "X. Chiềng Ve",
                "X. Chiềng Lương",
                "X. Phiêng Pằn",
                "X. Nà Ơt",
                "X. Tà Hộc"
             ],
             "codeErp":"1407"
          },
          {
             "n":"H. Sông Mã",
             "w":[
                "TT. Sông Mã",
                "X. Bó Sinh",
                "X. Pú Pẩu",
                "X. Chiềng Phung",
                "X. Chiềng En",
                "X. Mường Lầm",
                "X. Nậm Ty",
                "X. Đứa Mòn",
                "X. Yên Hưng",
                "X. Chiềng Sơ",
                "X. Nà Nghịu",
                "X. Nậm Mằn",
                "X. Chiềng Khoong",
                "X. Chiềng Cang",
                "X. Huổi Một",
                "X. Mường Sai",
                "X. Mường Cai",
                "X. Mường Hung",
                "X. Chiềng Khương"
             ],
             "codeErp":"1409"
          },
          {
             "n":"H. Sốp Cộp",
             "w":[
                "X. Sam Kha",
                "X. Púng Bánh",
                "X. Sốp Cộp",
                "X. Dồm Cang",
                "X. Nậm Lạnh",
                "X. Mường Lèo",
                "X. Mường Và",
                "X. Mường Lạn"
             ],
             "codeErp":"1411"
          },
          {
             "n":"H. Vân Hồ",
             "w":[
                "X. Suối Bàng",
                "X. Song Khủa",
                "X. Liên Hoà",
                "X. Tô Múa",
                "X. Mường Tè",
                "X. Chiềng Khoa",
                "X. Mường Men",
                "X. Quang Minh",
                "X. Vân Hồ",
                "X. Lóng Luông",
                "X. Chiềng Yên",
                "X. Chiềng Xuân",
                "X. Xuân Nha",
                "X. Tân Xuân"
             ],
             "codeErp":"1412"
          }
       ],
       "n":"Sơn La",
       "codeErp":"14"
    },
    {
       "d":[
          {
             "n":"TP. Lào Cai",
             "w":[
                "P. Duyên Hải",
                "P. Lào Cai",
                "P. Cốc Lếu",
                "P. Kim Tân",
                "P. Bắc Lệnh",
                "P. Pom Hán",
                "P. Xuân Tăng",
                "P. Bình Minh",
                "X. Thống Nhất",
                "X. Đồng Tuyển",
                "X. Vạn Hoà",
                "P. Bắc Cường",
                "P. Nam Cường",
                "X. Cam Đường",
                "X. Tả Phời",
                "X. Hợp Thành",
                "X. Cốc San"
             ],
             "codeErp":"0805"
          },
          {
             "n":"H. Bát Xát",
             "w":[
                "TT. Bát Xát",
                "X. A Mú Sung",
                "X. Nậm Chạc",
                "X. A Lù",
                "X. Trịnh Tường",
                "X. Y Tý",
                "X. Cốc Mỳ",
                "X. Dền Sáng",
                "X. Bản Vược",
                "X. Sàng Ma Sáo",
                "X. Bản Qua",
                "X. Mường Vi",
                "X. Dền Thàng",
                "X. Bản Xèo",
                "X. Mường Hum",
                "X. Trung Lèng Hồ",
                "X. Quang Kim",
                "X. Pa Cheo",
                "X. Nậm Pung",
                "X. Phìn Ngan",
                "X. Tòng Sành"
             ],
             "codeErp":"0803"
          },
          {
             "n":"H. Mường Khương",
             "w":[
                "X. Pha Long",
                "X. Tả Ngải Chồ",
                "X. Tung Chung Phố",
                "TT. Mường Khương",
                "X. Dìn Chin",
                "X. Tả Gia Khâu",
                "X. Nậm Chảy",
                "X. Nấm Lư",
                "X. Lùng Khấu Nhin",
                "X. Thanh Bình",
                "X. Cao Sơn",
                "X. Lùng Vai",
                "X. Bản Lầu",
                "X. La Pan Tẩn",
                "X. Tả Thàng",
                "X. Bản Sen"
             ],
             "codeErp":"0806"
          },
          {
             "n":"H. Si Ma Cai",
             "w":[
                "X. Nàn Sán",
                "X. Thào Chư Phìn",
                "X. Bản Mế",
                "TT. Si Ma Cai",
                "X. Sán Chải",
                "X. Lùng Thẩn",
                "X. Cán Cấu",
                "X. Sín Chéng",
                "X. Quan Hồ Thẩn",
                "X. Nàn Xín"
             ],
             "codeErp":"0808"
          },
          {
             "n":"H. Bắc Hà",
             "w":[
                "TT. Bắc Hà",
                "X. Lùng Cải",
                "X. Lùng Phình",
                "X. Tả Van Chư",
                "X. Tả Củ Tỷ",
                "X. Thải Giàng Phố",
                "X. Hoàng Thu Phố",
                "X. Bản Phố",
                "X. Bản Liền",
                "X. Tà Chải",
                "X. Na Hối",
                "X. Cốc Ly",
                "X. Nậm Mòn",
                "X. Nậm Đét",
                "X. Nậm Khánh",
                "X. Bảo Nhai",
                "X. Nậm Lúc",
                "X. Cốc Lầu",
                "X. Bản Cái"
             ],
             "codeErp":"0804"
          },
          {
             "n":"H. Bảo Thắng",
             "w":[
                "TT. N.T Phong Hải",
                "TT. Phố Lu",
                "TT. Tằng Loỏng",
                "X. Bản Phiệt",
                "X. Bản Cầm",
                "X. Thái Niên",
                "X. Phong Niên",
                "X. Gia Phú",
                "X. Xuân Quang",
                "X. Sơn Hải",
                "X. Xuân Giao",
                "X. Trì Quang",
                "X. Sơn Hà",
                "X. Phú Nhuận"
             ],
             "codeErp":"0801"
          },
          {
             "n":"H. Bảo Yên",
             "w":[
                "TT. Phố Ràng",
                "X. Tân Tiến",
                "X. Nghĩa Đô",
                "X. Vĩnh Yên",
                "X. Điện Quan",
                "X. Xuân Hoà",
                "X. Tân Dương",
                "X. Thượng Hà",
                "X. Kim Sơn",
                "X. Cam Cọn",
                "X. Minh Tân",
                "X. Xuân Thượng",
                "X. Việt Tiến",
                "X. Yên Sơn",
                "X. Bảo Hà",
                "X. Lương Sơn",
                "X. Phúc Khánh"
             ],
             "codeErp":"0802"
          },
          {
             "n":"TX. Sa Pa",
             "w":[
                "P. Sa Pa",
                "P. Sa Pả",
                "P. Ô Quý Hồ",
                "X. Ngũ Chỉ Sơn",
                "P. Phan Si Păng",
                "X. Trung Chải",
                "X. Tả Phìn",
                "P. Hàm Rồng",
                "X. Hoàng Liên",
                "X. Thanh Bình",
                "P. Cầu Mây",
                "X. Mường Hoa",
                "X. Tả Van",
                "X. Mường Bo",
                "X. Bản Hồ",
                "X. Liên Minh"
             ],
             "codeErp":"0807"
          },
          {
             "n":"H. Văn Bàn",
             "w":[
                "TT. Khánh Yên",
                "X. Võ Lao",
                "X. Sơn Thuỷ",
                "X. Nậm Mả",
                "X. Tân Thượng",
                "X. Nậm Rạng",
                "X. Nậm Chầy",
                "X. Tân An",
                "X. Khánh Yên Thượng",
                "X. Nậm Xé",
                "X. Dần Thàng",
                "X. Chiềng Ken",
                "X. Làng Giàng",
                "X. Hoà Mạc",
                "X. Khánh Yên Trung",
                "X. Khánh Yên Hạ",
                "X. Dương Quỳ",
                "X. Nậm Tha",
                "X. Minh Lương",
                "X. Thẩm Dương",
                "X. Liêm Phú",
                "X. Nậm Xây"
             ],
             "codeErp":"0809"
          }
       ],
       "n":"Lào Cai",
       "codeErp":"08"
    },
    {
       "d":[
          {
             "n":"TP. Lai Châu",
             "w":[
                "P. Quyết Thắng",
                "P. Tân Phong",
                "P. Quyết Tiến",
                "P. Đoàn Kết",
                "X. Sùng Phài",
                "P. Đông Phong",
                "X. San Thàng"
             ],
             "codeErp":"0701"
          },
          {
             "n":"H. Tam Đường",
             "w":[
                "TT. Tam Đường",
                "X. Thèn Sin",
                "X. Tả Lèng",
                "X. Giang Ma",
                "X. Hồ Thầu",
                "X. Bình Lư",
                "X. Sơn Bình",
                "X. Nùng Nàng",
                "X. Bản Giang",
                "X. Bản Hon",
                "X. Bản Bo",
                "X. Nà Tăm",
                "X. Khun Há"
             ],
             "codeErp":"0702"
          },
          {
             "n":"H. Mường Tè",
             "w":[
                "TT. Mường Tè",
                "X. Thu Lũm",
                "X. Ka Lăng",
                "X. Tá Bạ",
                "X. Pa ủ",
                "X. Mường Tè",
                "X. Pa Vệ Sử",
                "X. Mù Cả",
                "X. Bum Tở",
                "X. Nậm Khao",
                "X. Tà Tổng",
                "X. Bum Nưa",
                "X. Vàng San",
                "X. Kan Hồ"
             ],
             "codeErp":"0705"
          },
          {
             "n":"H. Sìn Hồ",
             "w":[
                "TT. Sìn Hồ",
                "X. Chăn Nưa",
                "X. Pa Tần",
                "X. Phìn Hồ",
                "X. Hồng Thu",
                "X. Phăng Sô Lin",
                "X. Ma Quai",
                "X. Lùng Thàng",
                "X. Tả Phìn",
                "X. Sà Dề Phìn",
                "X. Nậm Tăm",
                "X. Tả Ngảo",
                "X. Pu Sam Cáp",
                "X. Nậm Cha",
                "X. Pa Khoá",
                "X. Làng Mô",
                "X. Noong Hẻo",
                "X. Nậm Mạ",
                "X. Căn Co",
                "X. Tủa Sín Chải",
                "X. Nậm Cuổi",
                "X. Nậm Hăn"
             ],
             "codeErp":"0704"
          },
          {
             "n":"H. Phong Thổ",
             "w":[
                "X. Lả Nhì Thàng",
                "X. Huổi Luông",
                "TT. Phong Thổ",
                "X. Sì Lở Lầu",
                "X. Mồ Sì San",
                "X. Pa Vây Sử",
                "X. Vàng Ma Chải",
                "X. Tông Qua Lìn",
                "X. Mù Sang",
                "X. Dào San",
                "X. Ma Ly Pho",
                "X. Bản Lang",
                "X. Hoang Thèn",
                "X. Khổng Lào",
                "X. Nậm Xe",
                "X. Mường So",
                "X. Sin Suối Hồ"
             ],
             "codeErp":"0703"
          },
          {
             "n":"H. Than Uyên",
             "w":[
                "TT. Than Uyên",
                "X. Phúc Than",
                "X. Mường Than",
                "X. Mường Mít",
                "X. Pha Mu",
                "X. Mường Cang",
                "X. Hua Nà",
                "X. Tà Hừa",
                "X. Mường Kim",
                "X. Tà Mung",
                "X. Tà Gia",
                "X. Khoen On"
             ],
             "codeErp":"0706"
          },
          {
             "n":"H. Tân Uyên",
             "w":[
                "TT. Tân Uyên",
                "X. Mường Khoa",
                "X. Phúc Khoa",
                "X. Thân Thuộc",
                "X. Trung Đồng",
                "X. Hố Mít",
                "X. Nậm Cần",
                "X. Nậm Sỏ",
                "X. Pắc Ta",
                "X. Tà Mít"
             ],
             "codeErp":"0707"
          },
          {
             "n":"H. Nậm Nhùn",
             "w":[
                "TT. Nậm Nhùn",
                "X. Hua Bun",
                "X. Mường Mô",
                "X. Nậm Chà",
                "X. Nậm Manh",
                "X. Nậm Hàng",
                "X. Lê Lợi",
                "X. Pú Đao",
                "X. Nậm Pì",
                "X. Nậm Ban",
                "X. Trung Chải"
             ],
             "codeErp":"0708"
          }
       ],
       "n":"Lai Châu",
       "codeErp":"07"
    },
    {
       "d":[
          {
             "n":"TP. Hòa Bình",
             "w":[
                "P. Thái Bình",
                "P. Tân Hòa",
                "P. Thịnh Lang",
                "P. Hữu Nghị",
                "P. Tân Thịnh",
                "P. Đồng Tiến",
                "P. Phương Lâm",
                "X. Yên Mông",
                "X. Sủ Ngòi",
                "P. Dân Chủ",
                "X. Hòa Bình",
                "P. Thống Nhất",
                "P. Kỳ Sơn",
                "X. Thịnh Minh",
                "X. Hợp Thành",
                "X. Quang Tiến",
                "X. Mông Hóa",
                "X. Trung Minh",
                "X. Độc Lập"
             ],
             "codeErp":"2301"
          },
          {
             "n":"H. Đà Bắc",
             "w":[
                "TT. Đà Bắc",
                "X. Nánh Nghê",
                "X. Giáp Đắt",
                "X. Mường Chiềng",
                "X. Tân Pheo",
                "X. Đồng Chum",
                "X. Tân Minh",
                "X. Đoàn Kết",
                "X. Đồng Ruộng",
                "X. Tú Lý",
                "X. Trung Thành",
                "X. Yên Hòa",
                "X. Cao Sơn",
                "X. Toàn Sơn",
                "X. Hiền Lương",
                "X. Tiền Phong",
                "X. Vầy Nưa"
             ],
             "codeErp":"2302"
          },
          {
             "n":"H. Lương Sơn",
             "w":[
                "TT. Lương Sơn",
                "X. Lâm Sơn",
                "X. Hòa Sơn",
                "X. Tân Vinh",
                "X. Nhuận Trạch",
                "X. Cao Sơn",
                "X. Cư Yên",
                "X. Liên Sơn",
                "X. Cao Dương",
                "X. Thanh Sơn",
                "X. Thanh Cao"
             ],
             "codeErp":"2307"
          },
          {
             "n":"H. Kim Bôi",
             "w":[
                "TT. Bo",
                "X. Đú Sáng",
                "X. Hùng Sơn",
                "X. Bình Sơn",
                "X. Tú Sơn",
                "X. Vĩnh Tiến",
                "X. Đông Bắc",
                "X. Xuân Thủy",
                "X. Vĩnh Đồng",
                "X. Kim Lập",
                "X. Hợp Tiến",
                "X. Kim Bôi",
                "X. Nam Thượng",
                "X. Cuối Hạ",
                "X. Sào Báy",
                "X. Mi Hòa",
                "X. Nuông Dăm"
             ],
             "codeErp":"2308"
          },
          {
             "n":"H. Cao Phong",
             "w":[
                "TT. Cao Phong",
                "X. Bình Thanh",
                "X. Thung Nai",
                "X. Bắc Phong",
                "X. Thu Phong",
                "X. Hợp Phong",
                "X. Tây Phong",
                "X. Dũng Phong",
                "X. Nam Phong",
                "X. Thạch Yên"
             ],
             "codeErp":"2311"
          },
          {
             "n":"H. Tân Lạc",
             "w":[
                "TT. Mãn Đức",
                "X. Suối Hoa",
                "X. Phú Vinh",
                "X. Phú Cường",
                "X. Mỹ Hòa",
                "X. Quyết Chiến",
                "X. Phong Phú",
                "X. Tử Nê",
                "X. Thanh Hối",
                "X. Ngọc Mỹ",
                "X. Đông Lai",
                "X. Vân Sơn",
                "X. Nhân Mỹ",
                "X. Lỗ Sơn",
                "X. Ngổ Luông",
                "X. Gia Mô"
             ],
             "codeErp":"2304"
          },
          {
             "n":"H. Mai Châu",
             "w":[
                "X. Tân Thành",
                "TT. Mai Châu",
                "X. Sơn Thủy",
                "X. Pà Cò",
                "X. Hang Kia",
                "X. Đồng Tân",
                "X. Cun Pheo",
                "X. Bao La",
                "X. Tòng Đậu",
                "X. Nà Phòn",
                "X. Săm Khóe",
                "X. Chiềng Châu",
                "X. Mai Hạ",
                "X. Thành Sơn",
                "X. Mai Hịch",
                "X. Vạn Mai"
             ],
             "codeErp":"2303"
          },
          {
             "n":"H. Lạc Sơn",
             "w":[
                "TT. Vụ Bản",
                "X. Quý Hòa",
                "X. Miền Đồi",
                "X. Mỹ Thành",
                "X. Tuân Đạo",
                "X. Văn Nghĩa",
                "X. Văn Sơn",
                "X. Tân Lập",
                "X. Nhân Nghĩa",
                "X. Thượng Cốc",
                "X. Quyết Thắng",
                "X. Xuất Hóa",
                "X. Yên Phú",
                "X. Bình Hẻm",
                "X. Định Cư",
                "X. Chí Đạo",
                "X. Ngọc Sơn",
                "X. Hương Nhượng",
                "X. Vũ Bình",
                "X. Tự Do",
                "X. Yên Nghiệp",
                "X. Tân Mỹ",
                "X. Ân Nghĩa",
                "X. Ngọc Lâu"
             ],
             "codeErp":"2305"
          },
          {
             "n":"H. Yên Thủy",
             "w":[
                "TT. Hàng Trạm",
                "X. Lạc Sỹ",
                "X. Lạc Lương",
                "X. Bảo Hiệu",
                "X. Đa Phúc",
                "X. Hữu Lợi",
                "X. Lạc Thịnh",
                "X. Đoàn Kết",
                "X. Phú Lai",
                "X. Yên Trị",
                "X. Ngọc Lương"
             ],
             "codeErp":"2310"
          },
          {
             "n":"H. Lạc Thủy",
             "w":[
                "TT. Ba Hàng Đồi",
                "TT. Chi Nê",
                "X. Phú Nghĩa",
                "X. Phú Thành",
                "X. Hưng Thi",
                "X. Khoan Dụ",
                "X. Đồng Tâm",
                "X. Yên Bồng",
                "X. Thống Nhất",
                "X. An Bình"
             ],
             "codeErp":"2309"
          }
       ],
       "n":"Hòa Bình",
       "codeErp":"23"
    },
    {
       "d":[
          {
             "n":"TP. Yên Bái",
             "w":[
                "P. Yên Thịnh",
                "P. Yên Ninh",
                "P. Minh Tân",
                "P. Nguyễn Thái Học",
                "P. Đồng Tâm",
                "P. Nguyễn Phúc",
                "P. Hồng Hà",
                "X. Minh Bảo",
                "P. Nam Cường",
                "X. Tuy Lộc",
                "X. Tân Thịnh",
                "X. Âu Lâu",
                "X. Giới Phiên",
                "P. Hợp Minh",
                "X. Văn Phú"
             ],
             "codeErp":"1301"
          },
          {
             "n":"TX. Nghĩa Lộ",
             "w":[
                "P. Pú Trạng",
                "P. Trung Tâm",
                "P. Tân An",
                "P. Cầu Thia",
                "X. Nghĩa Lợi",
                "X. Nghĩa Phúc",
                "X. Nghĩa An",
                "X. Nghĩa Lộ",
                "X. Sơn A",
                "X. Phù Nham",
                "X. Thanh Lương",
                "X. Hạnh Sơn",
                "X. Phúc Sơn",
                "X. Thạch Lương"
             ],
             "codeErp":"1302"
          },
          {
             "n":"H. Lục Yên",
             "w":[
                "TT. Yên Thế",
                "X. Tân Phượng",
                "X. Lâm Thượng",
                "X. Khánh Thiện",
                "X. Minh Chuẩn",
                "X. Mai Sơn",
                "X. Khai Trung",
                "X. Mường Lai",
                "X. An Lạc",
                "X. Minh Xuân",
                "X. Tô Mậu",
                "X. Tân Lĩnh",
                "X. Yên Thắng",
                "X. Khánh Hoà",
                "X. Vĩnh Lạc",
                "X. Liễu Đô",
                "X. Động Quan",
                "X. Tân Lập",
                "X. Minh Tiến",
                "X. Trúc Lâu",
                "X. Phúc Lợi",
                "X. Phan Thanh",
                "X. An Phú",
                "X. Trung Tâm"
             ],
             "codeErp":"1309"
          },
          {
             "n":"H. Văn Yên",
             "w":[
                "TT. Mậu A",
                "X. Lang Thíp",
                "X. Lâm Giang",
                "X. Châu Quế Thượng",
                "X. Châu Quế Hạ",
                "X. An Bình",
                "X. Quang Minh",
                "X. Đông An",
                "X. Đông Cuông",
                "X. Phong Dụ Hạ",
                "X. Mậu Đông",
                "X. Ngòi A",
                "X. Xuân Tầm",
                "X. Tân Hợp",
                "X. An Thịnh",
                "X. Yên Thái",
                "X. Phong Dụ Thượng",
                "X. Yên Hợp",
                "X. Đại Sơn",
                "X. Đại Phác",
                "X. Yên Phú",
                "X. Xuân Ái",
                "X. Viễn Sơn",
                "X. Mỏ Vàng",
                "X. Nà Hẩu"
             ],
             "codeErp":"1303"
          },
          {
             "n":"H. Mù Căng Chải",
             "w":[
                "TT. Mù Căng Chải",
                "X. Hồ Bốn",
                "X. Nậm Có",
                "X. Khao Mang",
                "X. Mồ Dề",
                "X. Chế Cu Nha",
                "X. Lao Chải",
                "X. Kim Nọi",
                "X. Cao Phạ",
                "X. La Pán Tẩn",
                "X. Dế Su Phình",
                "X. Chế Tạo",
                "X. Púng Luông",
                "X. Nậm Khắt"
             ],
             "codeErp":"1305"
          },
          {
             "n":"H. Trấn Yên",
             "w":[
                "TT. Cổ Phúc",
                "X. Tân Đồng",
                "X. Báo Đáp",
                "X. Đào Thịnh",
                "X. Việt Thành",
                "X. Hòa Cuông",
                "X. Minh Quán",
                "X. Quy Mông",
                "X. Cường Thịnh",
                "X. Kiên Thành",
                "X. Nga Quán",
                "X. Y Can",
                "X. Lương Thịnh",
                "X. Bảo Hưng",
                "X. Việt Cường",
                "X. Minh Quân",
                "X. Hồng Ca",
                "X. Hưng Thịnh",
                "X. Hưng Khánh",
                "X. Việt Hồng",
                "X. Vân Hội"
             ],
             "codeErp":"1307"
          },
          {
             "n":"H. Trạm Tấu",
             "w":[
                "TT. Trạm Tấu",
                "X. Túc Đán",
                "X. Pá Lau",
                "X. Xà Hồ",
                "X. Phình Hồ",
                "X. Trạm Tấu",
                "X. Tà Si Láng",
                "X. Pá Hu",
                "X. Làng Nhì",
                "X. Bản Công",
                "X. Bản Mù",
                "X. Hát Lìu"
             ],
             "codeErp":"1308"
          },
          {
             "n":"H. Văn Chấn",
             "w":[
                "TT. NT Liên Sơn",
                "TT. NT Trần Phú",
                "X. Tú Lệ",
                "X. Nậm Búng",
                "X. Gia Hội",
                "X. Sùng Đô",
                "X. Nậm Mười",
                "X. An Lương",
                "X. Nậm Lành",
                "X. Sơn Lương",
                "X. Suối Quyền",
                "X. Suối Giàng",
                "X. Nghĩa Sơn",
                "X. Suối Bu",
                "TT. Sơn Thịnh",
                "X. Đại Lịch",
                "X. Đồng Khê",
                "X. Cát Thịnh",
                "X. Tân Thịnh",
                "X. Chấn Thịnh",
                "X. Bình Thuận",
                "X. Thượng Bằng La",
                "X. Minh An",
                "X. Nghĩa Tâm"
             ],
             "codeErp":"1306"
          },
          {
             "n":"H. Yên Bình",
             "w":[
                "TT. Yên Bình",
                "TT. Thác Bà",
                "X. Xuân Long",
                "X. Cảm Nhân",
                "X. Ngọc Chấn",
                "X. Tân Nguyên",
                "X. Phúc Ninh",
                "X. Bảo Ái",
                "X. Mỹ Gia",
                "X. Xuân Lai",
                "X. Mông Sơn",
                "X. Cảm Ân",
                "X. Yên Thành",
                "X. Tân Hương",
                "X. Phúc An",
                "X. Bạch Hà",
                "X. Vũ Linh",
                "X. Đại Đồng",
                "X. Vĩnh Kiên",
                "X. Yên Bình",
                "X. Thịnh Hưng",
                "X. Hán Đà",
                "X. Phú Thịnh",
                "X. Đại Minh"
             ],
             "codeErp":"1304"
          }
       ],
       "n":"Yên Bái",
       "codeErp":"13"
    },
    {
       "d":[
          {
             "n":"TP. Thái Nguyên",
             "w":[
                "P. Quán Triều",
                "P. Quang Vinh",
                "P. Túc Duyên",
                "P. Hoàng Văn Thụ",
                "P. Trưng Vương",
                "P. Quang Trung",
                "P. Phan Đình Phùng",
                "P. Tân Thịnh",
                "P. Thịnh Đán",
                "P. Đồng Quang",
                "P. Gia Sàng",
                "P. Tân Lập",
                "P. Cam Giá",
                "P. Phú Xá",
                "P. Hương Sơn",
                "P. Trung Thành",
                "P. Tân Thành",
                "P. Tân Long",
                "X. Phúc Hà",
                "X. Phúc Xuân",
                "X. Quyết Thắng",
                "X. Phúc Trìu",
                "X. Thịnh Đức",
                "P. Tích Lương",
                "X. Tân Cương",
                "X. Sơn Cẩm",
                "P. Chùa Hang",
                "X. Cao Ngạn",
                "X. Linh Sơn",
                "P. Đồng Bẩm",
                "X. Huống Thượng",
                "X. Đồng Liên"
             ],
             "codeErp":"1201"
          },
          {
             "n":"TP. Sông Công",
             "w":[
                "P. Lương Sơn",
                "P. Châu Sơn",
                "P. Mỏ Chè",
                "P. Cải Đan",
                "P. Thắng Lợi",
                "P. Phố Cò",
                "X. Tân Quang",
                "P. Bách Quang",
                "X. Bình Sơn",
                "X. Bá Xuyên"
             ],
             "codeErp":"1202"
          },
          {
             "n":"H. Định Hóa",
             "w":[
                "TT. Chợ Chu",
                "X. Linh Thông",
                "X. Lam Vỹ",
                "X. Quy Kỳ",
                "X. Tân Thịnh",
                "X. Kim Phượng",
                "X. Bảo Linh",
                "X. Phúc Chu",
                "X. Tân Dương",
                "X. Phượng Tiến",
                "X. Bảo Cường",
                "X. Đồng Thịnh",
                "X. Định Biên",
                "X. Thanh Định",
                "X. Trung Hội",
                "X. Trung Lương",
                "X. Bình Yên",
                "X. Điềm Mặc",
                "X. Phú Tiến",
                "X. Bộc Nhiêu",
                "X. Sơn Phú",
                "X. Phú Đình",
                "X. Bình Thành"
             ],
             "codeErp":"1203"
          },
          {
             "n":"H. Phú Lương",
             "w":[
                "TT. Giang Tiên",
                "TT. Đu",
                "X. Yên Ninh",
                "X. Yên Trạch",
                "X. Yên Đổ",
                "X. Yên Lạc",
                "X. Ôn Lương",
                "X. Động Đạt",
                "X. Phủ Lý",
                "X. Phú Đô",
                "X. Hợp Thành",
                "X. Tức Tranh",
                "X. Phấn Mễ",
                "X. Vô Tranh",
                "X. Cổ Lũng"
             ],
             "codeErp":"1204"
          },
          {
             "n":"H. Đồng Hỷ",
             "w":[
                "TT. Sông Cầu",
                "TT. Trại Cau",
                "X. Văn Lăng",
                "X. Tân Long",
                "X. Hòa Bình",
                "X. Quang Sơn",
                "X. Minh Lập",
                "X. Văn Hán",
                "X. Hóa Trung",
                "X. Khe Mo",
                "X. Cây Thị",
                "X. Hóa Thượng",
                "X. Hợp Tiến",
                "X. Tân Lợi",
                "X. Nam Hòa"
             ],
             "codeErp":"1207"
          },
          {
             "n":"H. Võ Nhai",
             "w":[
                "TT. Đình Cả",
                "X. Sảng Mộc",
                "X. Nghinh Tường",
                "X. Thần Xa",
                "X. Vũ Chấn",
                "X. Thượng Nung",
                "X. Phú Thượng",
                "X. Cúc Đường",
                "X. La Hiên",
                "X. Lâu Thượng",
                "X. Tràng Xá",
                "X. Phương Giao",
                "X. Liên Minh",
                "X. Dân Tiến",
                "X. Bình Long"
             ],
             "codeErp":"1205"
          },
          {
             "n":"H. Đại Từ",
             "w":[
                "TT. Hùng Sơn",
                "TT. Quân Chu",
                "X. Phúc Lương",
                "X. Minh Tiến",
                "X. Yên Lãng",
                "X. Đức Lương",
                "X. Phú Cường",
                "X. Na Mao",
                "X. Phú Lạc",
                "X. Tân Linh",
                "X. Phú Thịnh",
                "X. Phục Linh",
                "X. Phú Xuyên",
                "X. Bản Ngoại",
                "X. Tiên Hội",
                "X. Cù Vân",
                "X. Hà Thượng",
                "X. La Bằng",
                "X. Hoàng Nông",
                "X. Khôi Kỳ",
                "X. An Khánh",
                "X. Tân Thái",
                "X. Bình Thuận",
                "X. Lục Ba",
                "X. Mỹ Yên",
                "X. Vạn Thọ",
                "X. Văn Yên",
                "X. Ký Phú",
                "X. Cát Nê",
                "X. Quân Chu"
             ],
             "codeErp":"1206"
          },
          {
             "n":"TX. Phổ Yên",
             "w":[
                "P. Bãi Bông",
                "P. Bắc Sơn",
                "P. Ba Hàng",
                "X. Phúc Tân",
                "X. Phúc Thuận",
                "X. Hồng Tiến",
                "X. Minh Đức",
                "X. Đắc Sơn",
                "P. Đồng Tiến",
                "X. Thành Công",
                "X. Tiên Phong",
                "X. Vạn Phái",
                "X. Nam Tiến",
                "X. Tân Hương",
                "X. Đông Cao",
                "X. Trung Thành",
                "X. Tân Phú",
                "X. Thuận Thành"
             ],
             "codeErp":"1209"
          },
          {
             "n":"H. Phú Bình",
             "w":[
                "TT. Hương Sơn",
                "X. Bàn Đạt",
                "X. Tân Khánh",
                "X. Tân Kim",
                "X. Tân Thành",
                "X. Đào Xá",
                "X. Bảo Lý",
                "X. Thượng Đình",
                "X. Tân Hòa",
                "X. Nhã Lộng",
                "X. Điềm Thụy",
                "X. Xuân Phương",
                "X. Tân Đức",
                "X. Úc Kỳ",
                "X. Lương Phú",
                "X. Nga My",
                "X. Kha Sơn",
                "X. Thanh Ninh",
                "X. Dương Thành",
                "X. Hà Châu"
             ],
             "codeErp":"1208"
          }
       ],
       "n":"Thái Nguyên",
       "codeErp":"12"
    },
    {
       "d":[
          {
             "n":"TP. Lạng Sơn",
             "w":[
                "P. Hoàng Văn Thụ",
                "P. Tam Thanh",
                "P. Vĩnh Trại",
                "P. Đông Kinh",
                "P. Chi Lăng",
                "X. Hoàng Đồng",
                "X. Quảng Lạc",
                "X. Mai Pha"
             ],
             "codeErp":"1001"
          },
          {
             "n":"H. Tràng Định",
             "w":[
                "TT. Thất Khê",
                "X. Khánh Long",
                "X. Đoàn Kết",
                "X. Quốc Khánh",
                "X. Vĩnh Tiến",
                "X. Cao Minh",
                "X. Chí Minh",
                "X. Tri Phương",
                "X. Tân Tiến",
                "X. Tân Yên",
                "X. Đội Cấn",
                "X. Tân Minh",
                "X. Kim Đồng",
                "X. Chi Lăng",
                "X. Trung Thành",
                "X. Đại Đồng",
                "X. Đào Viên",
                "X. Đề Thám",
                "X. Kháng Chiến",
                "X. Hùng Sơn",
                "X. Quốc Việt",
                "X. Hùng Việt"
             ],
             "codeErp":"1002"
          },
          {
             "n":"H. Bình Gia",
             "w":[
                "X. Hưng Đạo",
                "X. Vĩnh Yên",
                "X. Hoa Thám",
                "X. Quý Hòa",
                "X. Hồng Phong",
                "X. Yên Lỗ",
                "X. Thiện Hòa",
                "X. Quang Trung",
                "X. Thiện Thuật",
                "X. Minh Khai",
                "X. Thiện Long",
                "X. Hoàng Văn Thụ",
                "X. Hòa Bình",
                "X. Mông Ân",
                "X. Tân Hòa",
                "TT. Bình Gia",
                "X. Hồng Thái",
                "X. Bình La",
                "X. Tân Văn"
             ],
             "codeErp":"1003"
          },
          {
             "n":"H. Văn Lãng",
             "w":[
                "TT. Na Sầm",
                "X. Trùng Khánh",
                "X. Bắc La",
                "X. Thụy Hùng",
                "X. Bắc Hùng",
                "X. Tân Tác",
                "X. Thanh Long",
                "X. Hội Hoan",
                "X. Bắc Việt",
                "X. Hoàng Việt",
                "X. Gia Miễn",
                "X. Thành Hòa",
                "X. Tân Thanh",
                "X. Tân Mỹ",
                "X. Hồng Thái",
                "X.  Hoàng Văn Thụ",
                "X. Nhạc Kỳ"
             ],
             "codeErp":"1004"
          },
          {
             "n":"H. Cao Lộc",
             "w":[
                "TT. Đồng Đăng",
                "TT. Cao Lộc",
                "X. Bảo Lâm",
                "X. Thanh Lòa",
                "X. Cao Lâu",
                "X. Thạch Đạn",
                "X. Xuất Lễ",
                "X. Hồng Phong",
                "X. Thụy Hùng",
                "X. Lộc Yên",
                "X. Phú Xá",
                "X. Bình Trung",
                "X. Hải Yến",
                "X. Hòa Cư",
                "X. Hợp Thành",
                "X. Công Sơn",
                "X. Gia Cát",
                "X. Mẫu Sơn",
                "X. Xuân Long",
                "X. Tân Liên",
                "X. Yên Trạch",
                "X. Tân Thành"
             ],
             "codeErp":"1007"
          },
          {
             "n":"H. Văn Quan",
             "w":[
                "TT. Văn Quan",
                "X. Trấn Ninh",
                "X. Liên Hội",
                "X. Hòa Bình",
                "X. Tú Xuyên",
                "X. Điềm He",
                "X. An Sơn",
                "X. Khánh Khê",
                "X. Lương Năng",
                "X. Đồng Giáp",
                "X. Bình Phúc",
                "X. Tràng Các",
                "X. Tân Đoàn",
                "X. Tri Lễ",
                "X. Tràng Phái",
                "X. Yên Phúc",
                "X. Hữu Lễ"
             ],
             "codeErp":"1006"
          },
          {
             "n":"H. Bắc Sơn",
             "w":[
                "TT. Bắc Sơn",
                "X. Long Đống",
                "X. Vạn Thủy",
                "X. Đồng ý",
                "X. Tân Tri",
                "X. Bắc Quỳnh",
                "X. Hưng Vũ",
                "X. Tân Lập",
                "X. Vũ Sơn",
                "X. Chiêu Vũ",
                "X. Tân Hương",
                "X. Chiến Thắng",
                "X. Vũ Lăng",
                "X. Trấn Yên",
                "X. Vũ Lễ",
                "X. Nhất Hòa",
                "X. Tân Thành",
                "X. Nhất Tiến"
             ],
             "codeErp":"1005"
          },
          {
             "n":"H. Hữu Lũng",
             "w":[
                "TT. Hữu Lũng",
                "X. Hữu Liên",
                "X. Yên Bình",
                "X. Quyết Thắng",
                "X. Hòa Bình",
                "X. Yên Thịnh",
                "X. Yên Sơn",
                "X. Thiện Tân",
                "X. Yên Vượng",
                "X. Minh Tiến",
                "X. Nhật Tiến",
                "X. Thanh Sơn",
                "X. Đồng Tân",
                "X. Cai Kinh",
                "X. Hòa Lạc",
                "X. Vân Nham",
                "X. Đồng Tiến",
                "X. Tân Thành",
                "X. Hòa Sơn",
                "X. Minh Sơn",
                "X. Hồ Sơn",
                "X. Sơn Hà",
                "X. Minh Hòa",
                "X. Hòa Thắng"
             ],
             "codeErp":"1011"
          },
          {
             "n":"H. Chi Lăng",
             "w":[
                "TT. Đồng Mỏ",
                "TT. Chi Lăng",
                "X. Vân An",
                "X. Vân Thủy",
                "X. Gia Lộc",
                "X. Bắc Thủy",
                "X. Chiến Thắng",
                "X. Mai Sao",
                "X. Bằng Hữu",
                "X. Thượng Cường",
                "X. Bằng Mạc",
                "X. Nhân Lý",
                "X. Lâm Sơn",
                "X. Liên Sơn",
                "X. Vạn Linh",
                "X. Hòa Bình",
                "X. Hữu Kiên",
                "X. Quan Sơn",
                "X. Y Tịch",
                "X. Chi Lăng"
             ],
             "codeErp":"1009"
          },
          {
             "n":"H. Lộc Bình",
             "w":[
                "TT. Na Dương",
                "TT. Lộc Bình",
                "X. Mẫu Sơn",
                "X. Yên Khoái",
                "X. Khánh Xuân",
                "X. Tú Mịch",
                "X. Hữu Khánh",
                "X. Đồng Bục",
                "X. Tam Gia",
                "X. Tú Đoạn",
                "X. Khuất Xá",
                "X. Tĩnh Bắc",
                "X. Thống Nhất",
                "X. Sàn Viên",
                "X. Đông Quan",
                "X. Minh Hiệp",
                "X. Hữu Lân",
                "X. Lợi Bác",
                "X. Nam Quan",
                "X. Xuân Dương",
                "X. Ái Quốc"
             ],
             "codeErp":"1008"
          },
          {
             "n":"H. Đình Lập",
             "w":[
                "TT. Đình Lập",
                "TT. NT Thái Bình",
                "X. Bắc Xa",
                "X. Bính Xá",
                "X. Kiên Mộc",
                "X. Đình Lập",
                "X. Thái Bình",
                "X. Cường Lợi",
                "X. Châu Sơn",
                "X. Lâm Ca",
                "X. Đồng Thắng",
                "X. Bắc Lãng"
             ],
             "codeErp":"1010"
          }
       ],
       "n":"Lạng Sơn",
       "codeErp":"10"
    },
    {
       "d":[
          {
             "n":"TP. Hạ Long",
             "w":[
                "P. Hà Khánh",
                "P. Hà Phong",
                "P. Hà Khẩu",
                "P. Cao Xanh",
                "P. Giếng Đáy",
                "P. Hà Tu",
                "P. Hà Trung",
                "P. Hà Lầm",
                "P. Bãi Cháy",
                "P. Cao Thắng",
                "P. Hùng Thắng",
                "P. Yết Kiêu",
                "P. Trần Hưng Đạo",
                "P. Hồng Hải",
                "P. Hồng Gai",
                "P. Bạch Đằng",
                "P. Hồng Hà",
                "P. Tuần Châu",
                "P. Việt Hưng",
                "P. Đại Yên",
                "P. Hoành Bồ",
                "X. Kỳ Thượng",
                "X. Đồng Sơn",
                "X. Tân Dân",
                "X. Đồng Lâm",
                "X. Hòa Bình",
                "X. Vũ Oai",
                "X. Dân Chủ",
                "X. Quảng La",
                "X. Bằng Cả",
                "X. Thống Nhất",
                "X. Sơn Dương",
                "X. Lê Lợi"
             ],
             "codeErp":"1701"
          },
          {
             "n":"TP. Móng Cái",
             "w":[
                "P. Ka Long",
                "P. Trần Phú",
                "P. Ninh Dương",
                "P. Hoà Lạc",
                "P. Trà Cổ",
                "X. Hải Sơn",
                "X. Bắc Sơn",
                "X. Hải Đông",
                "X. Hải Tiến",
                "P. Hải Yên",
                "X. Quảng Nghĩa",
                "P. Hải Hoà",
                "X. Hải Xuân",
                "X. Vạn Ninh",
                "P. Bình Ngọc",
                "X. Vĩnh Trung",
                "X. Vĩnh Thực"
             ],
             "codeErp":"1704"
          },
          {
             "n":"TP. Cẩm Phả",
             "w":[
                "P. Mông Dương",
                "P. Cửa Ông",
                "P. Cẩm Sơn",
                "P. Cẩm Đông",
                "P. Cẩm Phú",
                "P. Cẩm Tây",
                "P. Quang Hanh",
                "P. Cẩm Thịnh",
                "P. Cẩm Thủy",
                "P. Cẩm Thạch",
                "P. Cẩm Thành",
                "P. Cẩm Trung",
                "P. Cẩm Bình",
                "X. Cộng Hòa",
                "X. Cẩm Hải",
                "X. Dương Huy"
             ],
             "codeErp":"1702"
          },
          {
             "n":"TP. Uông Bí",
             "w":[
                "P. Vàng Danh",
                "P. Thanh Sơn",
                "P. Bắc Sơn",
                "P. Quang Trung",
                "P. Trưng Vương",
                "P. Nam Khê",
                "P. Yên Thanh",
                "X. Thượng Yên Công",
                "P. Phương Đông",
                "P. Phương Nam"
             ],
             "codeErp":"1703"
          },
          {
             "n":"H. Bình Liêu",
             "w":[
                "TT. Bình Liêu",
                "X. Hoành Mô",
                "X. Đồng Tâm",
                "X. Đồng Văn",
                "X. Vô Ngại",
                "X. Lục Hồn",
                "X. Húc Động"
             ],
             "codeErp":"1705"
          },
          {
             "n":"H. Tiên Yên",
             "w":[
                "TT. Tiên Yên",
                "X. Hà Lâu",
                "X. Đại Dực",
                "X. Phong Dụ",
                "X. Điền Xá",
                "X. Đông Ngũ",
                "X. Yên Than",
                "X. Đông Hải",
                "X. Hải Lạng",
                "X. Tiên Lãng",
                "X. Đồng Rui"
             ],
             "codeErp":"1708"
          },
          {
             "n":"H. Đầm Hà",
             "w":[
                "TT. Đầm Hà",
                "X. Quảng Lâm",
                "X. Quảng An",
                "X. Tân Bình",
                "X. Dực Yên",
                "X. Quảng Tân",
                "X. Đầm Hà",
                "X. Tân Lập",
                "X. Đại Bình"
             ],
             "codeErp":"1706"
          },
          {
             "n":"H. Hải Hà",
             "w":[
                "TT. Quảng Hà",
                "X. Quảng Đức",
                "X. Quảng Sơn",
                "X. Quảng Thành",
                "X. Quảng Thịnh",
                "X. Quảng Minh",
                "X. Quảng Chính",
                "X. Quảng Long",
                "X. Đường Hoa",
                "X. Quảng Phong",
                "X. Cái Chiên"
             ],
             "codeErp":"1707"
          },
          {
             "n":"H. Ba Chẽ",
             "w":[
                "TT. Ba Chẽ",
                "X. Thanh Sơn",
                "X. Thanh Lâm",
                "X. Đạp Thanh",
                "X. Nam Sơn",
                "X. Lương Mông",
                "X. Đồn Đạc",
                "X. Minh Cầm"
             ],
             "codeErp":"1709"
          },
          {
             "n":"H. Vân Đồn",
             "w":[
                "TT. Cái Rồng",
                "X. Đài Xuyên",
                "X. Bình Dân",
                "X. Vạn Yên",
                "X. Minh Châu",
                "X. Đoàn Kết",
                "X. Hạ Long",
                "X. Đông Xá",
                "X. Bản Sen",
                "X. Thắng Lợi",
                "X. Quan Lạn",
                "X. Ngọc Vừng"
             ],
             "codeErp":"1713"
          },
          {
             "n":"TX. Đông Triều",
             "w":[
                "P. Mạo Khê",
                "P. Đông Triều",
                "X. An Sinh",
                "X. Tràng Lương",
                "X. Bình Khê",
                "X. Việt Dân",
                "X. Tân Việt",
                "X. Bình Dương",
                "P. Đức Chính",
                "P. Tràng An",
                "X. Nguyễn Huệ",
                "X. Thủy An",
                "P. Xuân Sơn",
                "X. Hồng Thái Tây",
                "X. Hồng Thái Đông",
                "P. Hoàng Quế",
                "P. Yên Thọ",
                "P. Hồng Phong",
                "P. Kim Sơn",
                "P. Hưng Đạo",
                "X. Yên Đức"
             ],
             "codeErp":"1710"
          },
          {
             "n":"TX. Quảng Yên",
             "w":[
                "P. Quảng Yên",
                "P. Đông Mai",
                "P. Minh Thành",
                "X. Sông Khoai",
                "X. Hiệp Hòa",
                "P. Cộng Hòa",
                "X. Tiền An",
                "X. Hoàng Tân",
                "P. Tân An",
                "P. Yên Giang",
                "P. Nam Hoà",
                "P. Hà An",
                "X. Cẩm La",
                "P. Phong Hải",
                "P. Yên Hải",
                "X. Liên Hòa",
                "P. Phong Cốc",
                "X. Liên Vị",
                "X. Tiền Phong"
             ],
             "codeErp":"1711"
          },
          {
             "n":"H. Cô Tô",
             "w":[
                "TT. Cô Tô",
                "X. Đồng Tiến",
                "X. Thanh Lân"
             ],
             "codeErp":"1714"
          }
       ],
       "n":"Quảng Ninh",
       "codeErp":"17"
    },
    {
       "d":[
          {
             "n":"TP. Bắc Giang",
             "w":[
                "P. Thọ Xương",
                "P. Trần Nguyên Hãn",
                "P. Ngô Quyền",
                "P. Hoàng Văn Thụ",
                "P. Trần Phú",
                "P. Mỹ Độ",
                "P. Lê Lợi",
                "X. Song Mai",
                "P. Xương Giang",
                "P. Đa Mai",
                "P. Dĩnh Kế",
                "X. Dĩnh Trì",
                "X. Tân Mỹ",
                "X. Đồng Sơn",
                "X. Tân Tiến",
                "X. Song Khê"
             ],
             "codeErp":"1801"
          },
          {
             "n":"H. Yên Thế",
             "w":[
                "X. Đồng Tiến",
                "X. Canh Nậu",
                "X. Xuân Lương",
                "X. Tam Tiến",
                "X. Đồng Vương",
                "X. Đồng Hưu",
                "X. Đồng Tâm",
                "X. Tam Hiệp",
                "X. Tiến Thắng",
                "X. Hồng Kỳ",
                "X. Đồng Lạc",
                "X. Đông Sơn",
                "X. Tân Hiệp",
                "X. Hương Vĩ",
                "X. Đồng Kỳ",
                "X. An Thượng",
                "TT. Phồn Xương",
                "X. Tân Sỏi",
                "TT. Bố Hạ"
             ],
             "codeErp":"1802"
          },
          {
             "n":"H. Tân Yên",
             "w":[
                "X. Lan Giới",
                "TT. Nhã Nam",
                "X. Tân Trung",
                "X. Đại Hóa",
                "X. Quang Tiến",
                "X. Phúc Sơn",
                "X. An Dương",
                "X. Phúc Hòa",
                "X. Liên Sơn",
                "X. Hợp Đức",
                "X. Lam Cốt",
                "X. Cao Xá",
                "TT. Cao Thượng",
                "X. Việt Ngọc",
                "X. Song Vân",
                "X. Ngọc Châu",
                "X. Ngọc Vân",
                "X. Việt Lập",
                "X. Liên Chung",
                "X. Ngọc Thiện",
                "X. Ngọc Lý",
                "X. Quế Nham"
             ],
             "codeErp":"1806"
          },
          {
             "n":"H. Lạng Giang",
             "w":[
                "TT. Vôi",
                "X. Nghĩa Hòa",
                "X. Nghĩa Hưng",
                "X. Quang Thịnh",
                "X. Hương Sơn",
                "X. Đào Mỹ",
                "X. Tiên Lục",
                "X. An Hà",
                "TT. Kép",
                "X. Mỹ Hà",
                "X. Hương Lạc",
                "X. Dương Đức",
                "X. Tân Thanh",
                "X. Yên Mỹ",
                "X. Tân Hưng",
                "X. Mỹ Thái",
                "X. Xương Lâm",
                "X. Xuân Hương",
                "X. Tân Dĩnh",
                "X. Đại Lâm",
                "X. Thái Đào"
             ],
             "codeErp":"1808"
          },
          {
             "n":"H. Lục Nam",
             "w":[
                "TT. Đồi Ngô",
                "X. Đông Hưng",
                "X. Đông Phú",
                "X. Tam Dị",
                "X. Bảo Sơn",
                "X. Bảo Đài",
                "X. Thanh Lâm",
                "X. Tiên Nha",
                "X. Trường Giang",
                "X. Phương Sơn",
                "X. Chu Điện",
                "X. Cương Sơn",
                "X. Nghĩa Phương",
                "X. Vô Tranh",
                "X. Bình Sơn",
                "X. Lan Mẫu",
                "X. Yên Sơn",
                "X. Khám Lạng",
                "X. Huyền Sơn",
                "X. Trường Sơn",
                "X. Lục Sơn",
                "X. Bắc Lũng",
                "X. Vũ Xá",
                "X. Cẩm Lý",
                "X. Đan Hội"
             ],
             "codeErp":"1805"
          },
          {
             "n":"H. Lục Ngạn",
             "w":[
                "TT. Chũ",
                "X. Cấm Sơn",
                "X. Tân Sơn",
                "X. Phong Minh",
                "X. Phong Vân",
                "X. Xa Lý",
                "X. Hộ Đáp",
                "X. Sơn Hải",
                "X. Thanh Hải",
                "X. Kiên Lao",
                "X. Biên Sơn",
                "X. Kiên Thành",
                "X. Hồng Giang",
                "X. Kim Sơn",
                "X. Tân Hoa",
                "X. Giáp Sơn",
                "X. Biển Động",
                "X. Quý Sơn",
                "X. Trù Hựu",
                "X. Phì Điền",
                "X. Tân Quang",
                "X. Đồng Cốc",
                "X. Tân Lập",
                "X. Phú Nhuận",
                "X. Mỹ An",
                "X. Nam Dương",
                "X. Tân Mộc",
                "X. Đèo Gia",
                "X. Phượng Sơn"
             ],
             "codeErp":"1803"
          },
          {
             "n":"H. Sơn Động",
             "w":[
                "TT. An Châu",
                "TT. Tây Yên Tử",
                "X. Vân Sơn",
                "X. Hữu Sản",
                "X. Đại Sơn",
                "X. Phúc Sơn",
                "X. Giáo Liêm",
                "X. Cẩm Đàn",
                "X. An Lạc",
                "X. Vĩnh An",
                "X. Yên Định",
                "X. Lệ Viễn",
                "X. An Bá",
                "X. Tuấn Đạo",
                "X. Dương Hưu",
                "X. Long Sơn",
                "X. Thanh Luận"
             ],
             "codeErp":"1804"
          },
          {
             "n":"H. Yên Dũng",
             "w":[
                "TT. Nham Biền",
                "TT. Tân An",
                "X. Lão Hộ",
                "X. Hương Gián",
                "X. Quỳnh Sơn",
                "X. Nội Hoàng",
                "X. Tiền Phong",
                "X. Xuân Phú",
                "X. Tân Liễu",
                "X. Trí Yên",
                "X. Lãng Sơn",
                "X. Yên Lư",
                "X. Tiến Dũng",
                "X. Đức Giang",
                "X. Cảnh Thụy",
                "X. Tư Mại",
                "X. Đồng Việt",
                "X. Đồng Phúc"
             ],
             "codeErp":"1810"
          },
          {
             "n":"H. Việt Yên",
             "w":[
                "X. Thượng Lan",
                "X. Việt Tiến",
                "X. Nghĩa Trung",
                "X. Minh Đức",
                "X. Hương Mai",
                "X. Tự Lạn",
                "TT. Bích Động",
                "X. Trung Sơn",
                "X. Hồng Thái",
                "X. Tiên Sơn",
                "X. Tăng Tiến",
                "X. Quảng Minh",
                "TT. Nếnh",
                "X. Ninh Sơn",
                "X. Vân Trung",
                "X. Vân Hà",
                "X. Quang Châu"
             ],
             "codeErp":"1809"
          },
          {
             "n":"H. Hiệp Hòa",
             "w":[
                "X. Đồng Tân",
                "X. Thanh Vân",
                "X. Hoàng Lương",
                "X. Hoàng Vân",
                "X. Hoàng Thanh",
                "X. Hoàng An",
                "X. Ngọc Sơn",
                "X. Thái Sơn",
                "X. Hòa Sơn",
                "TT. Thắng",
                "X. Quang Minh",
                "X. Lương Phong",
                "X. Hùng Sơn",
                "X. Đại Thành",
                "X. Thường Thắng",
                "X. Hợp Thịnh",
                "X. Danh Thắng",
                "X. Mai Trung",
                "X. Đoan Bái",
                "X. Bắc Lý",
                "X. Xuân Cẩm",
                "X. Hương Lâm",
                "X. Đông Lỗ",
                "X. Châu Minh",
                "X. Mai Đình"
             ],
             "codeErp":"1807"
          }
       ],
       "n":"Bắc Giang",
       "codeErp":"18"
    },
    {
       "d":[
          {
             "n":"TP. Việt Trì",
             "w":[
                "P. Dữu Lâu",
                "P. Vân Cơ",
                "P. Nông Trang",
                "P. Tân Dân",
                "P. Gia Cẩm",
                "P. Tiên Cát",
                "P. Thọ Sơn",
                "P. Thanh Miếu",
                "P. Bạch Hạc",
                "P. Bến Gót",
                "P. Vân Phú",
                "X. Phượng Lâu",
                "X. Thụy Vân",
                "P. Minh Phương",
                "X. Trưng Vương",
                "P. Minh Nông",
                "X. Sông Lô",
                "X. Kim Đức",
                "X. Hùng Lô",
                "X. Hy Cương",
                "X. Chu Hóa",
                "X. Thanh Đình"
             ],
             "codeErp":"1501"
          },
          {
             "n":"TX. Phú Thọ",
             "w":[
                "P. Hùng Vương",
                "P. Phong Châu",
                "P. Âu Cơ",
                "X. Hà Lộc",
                "X. Phú Hộ",
                "X. Văn Lung",
                "X. Thanh Minh",
                "X. Hà Thạch",
                "P. Thanh Vinh"
             ],
             "codeErp":"1502"
          },
          {
             "n":"H. Đoan Hùng",
             "w":[
                "TT. Đoan Hùng",
                "X. Hùng Xuyên",
                "X. Bằng Luân",
                "X. Vân Du",
                "X. Phú Lâm",
                "X. Minh Lương",
                "X. Bằng Doãn",
                "X. Chí Đám",
                "X. Phúc Lai",
                "X. Ngọc Quan",
                "X. Hợp Nhất",
                "X. Sóc Đăng",
                "X. Tây Cốc",
                "X. Yên Kiện",
                "X. Hùng Long",
                "X. Vụ Quang",
                "X. Vân Đồn",
                "X. Tiêu Sơn",
                "X. Minh Tiến",
                "X. Minh Phú",
                "X. Chân Mộng",
                "X. Ca Đình"
             ],
             "codeErp":"1503"
          },
          {
             "n":"H. Hạ Hoà",
             "w":[
                "TT. Hạ Hoà",
                "X. Đại Phạm",
                "X. Đan Thượng",
                "X. Hà Lương",
                "X. Tứ Hiệp",
                "X. Hiền Lương",
                "X. Phương Viên",
                "X. Gia Điền",
                "X. Ấm Hạ",
                "X. Hương Xạ",
                "X. Xuân Áng",
                "X. Yên Kỳ",
                "X. Minh Hạc",
                "X. Lang Sơn",
                "X. Bằng Giã",
                "X. Yên Luật",
                "X. Vô Tranh",
                "X. Văn Lang",
                "X. Minh Côi",
                "X. Vĩnh Chân"
             ],
             "codeErp":"1505"
          },
          {
             "n":"H. Thanh Ba",
             "w":[
                "TT. Thanh Ba",
                "X. Vân Lĩnh",
                "X. Đông Lĩnh",
                "X. Đại An",
                "X. Hanh Cù",
                "X. Đồng Xuân",
                "X. Quảng Yên",
                "X. Ninh Dân",
                "X. Võ Lao",
                "X. Khải Xuân",
                "X. Mạn Lạn",
                "X. Hoàng Cương",
                "X. Chí Tiên",
                "X. Đông Thành",
                "X. Sơn Cương",
                "X. Thanh Hà",
                "X. Đỗ Sơn",
                "X. Đỗ Xuyên",
                "X. Lương Lỗ"
             ],
             "codeErp":"1504"
          },
          {
             "n":"H. Phù Ninh",
             "w":[
                "TT. Phong Châu",
                "X. Phú Mỹ",
                "X. Lệ Mỹ",
                "X. Liên Hoa",
                "X. Trạm Thản",
                "X. Trị Quận",
                "X. Trung Giáp",
                "X. Tiên Phú",
                "X. Hạ Giáp",
                "X. Bảo Thanh",
                "X. Phú Lộc",
                "X. Gia Thanh",
                "X. Tiên Du",
                "X. Phú Nham",
                "X. An Đạo",
                "X. Bình Phú",
                "X. Phù Ninh"
             ],
             "codeErp":"1509"
          },
          {
             "n":"H. Yên Lập",
             "w":[
                "TT. Yên Lập",
                "X. Mỹ Lung",
                "X. Mỹ Lương",
                "X. Lương Sơn",
                "X. Xuân An",
                "X. Xuân Viên",
                "X. Xuân Thủy",
                "X. Trung Sơn",
                "X. Hưng Long",
                "X. Nga Hoàng",
                "X. Đồng Lạc",
                "X. Thượng Long",
                "X. Đồng Thịnh",
                "X. Phúc Khánh",
                "X. Minh Hòa",
                "X. Ngọc Lập",
                "X. Ngọc Đồng"
             ],
             "codeErp":"1507"
          },
          {
             "n":"H. Cẩm Khê",
             "w":[
                "TT. Cẩm Khê",
                "X. Tiên Lương",
                "X. Tuy Lộc",
                "X. Ngô Xá",
                "X. Minh Tân",
                "X. Phượng Vĩ",
                "X. Thụy Liễu",
                "X. Tùng Khê",
                "X. Tam Sơn",
                "X. Văn Bán",
                "X. Cấp Dẫn",
                "X. Xương Thịnh",
                "X. Phú Khê",
                "X. Sơn Tình",
                "X. Yên Tập",
                "X. Hương Lung",
                "X. Tạ Xá",
                "X. Phú Lạc",
                "X. Chương Xá",
                "X. Hùng Việt",
                "X. Văn Khúc",
                "X. Yên Dưỡng",
                "X. Điêu Lương",
                "X. Đồng Lương"
             ],
             "codeErp":"1506"
          },
          {
             "n":"H. Tam Nông",
             "w":[
                "TT. Hưng Hoá",
                "X. Hiền Quan",
                "X. Bắc Sơn",
                "X. Thanh Uyên",
                "X. Lam Sơn",
                "X. Vạn Xuân",
                "X. Quang Húc",
                "X. Hương Nộn",
                "X. Tề Lễ",
                "X. Thọ Văn",
                "X. Dị Nậu",
                "X. Dân Quyền"
             ],
             "codeErp":"1511"
          },
          {
             "n":"H. Lâm Thao",
             "w":[
                "TT. Lâm Thao",
                "X. Tiên Kiên",
                "TT. Hùng Sơn",
                "X. Xuân Lũng",
                "X. Xuân Huy",
                "X. Thạch Sơn",
                "X. Sơn Vi",
                "X. Phùng Nguyên",
                "X. Cao Xá",
                "X. Vĩnh Lại",
                "X. Tứ Xã",
                "X. Bản Nguyên"
             ],
             "codeErp":"1510"
          },
          {
             "n":"H. Thanh Sơn",
             "w":[
                "TT. Thanh Sơn",
                "X. Sơn Hùng",
                "X. Địch Quả",
                "X. Giáp Lai",
                "X. Thục Luyện",
                "X. Võ Miếu",
                "X. Thạch Khoán",
                "X. Cự Thắng",
                "X. Tất Thắng",
                "X. Văn Miếu",
                "X. Cự Đồng",
                "X. Thắng Sơn",
                "X. Tân Minh",
                "X. Hương Cần",
                "X. Khả Cửu",
                "X. Đông Cửu",
                "X. Tân Lập",
                "X. Yên Lãng",
                "X. Yên Lương",
                "X. Thượng Cửu",
                "X. Lương Nha",
                "X. Yên Sơn",
                "X. Tinh Nhuệ"
             ],
             "codeErp":"1508"
          },
          {
             "n":"H. Thanh Thuỷ",
             "w":[
                "X. Đào Xá",
                "X. Thạch Đồng",
                "X. Xuân Lộc",
                "X. Tân Phương",
                "TT. Thanh Thủy",
                "X. Sơn Thủy",
                "X. Bảo Yên",
                "X. Đoan Hạ",
                "X. Đồng Trung",
                "X. Hoàng Xá",
                "X. Tu Vũ"
             ],
             "codeErp":"1512"
          },
          {
             "n":"H. Tân Sơn",
             "w":[
                "X. Thu Cúc",
                "X. Thạch Kiệt",
                "X. Thu Ngạc",
                "X. Kiệt Sơn",
                "X. Đồng Sơn",
                "X. Lai Đồng",
                "X. Tân Phú",
                "X. Mỹ Thuận",
                "X. Tân Sơn",
                "X. Xuân Đài",
                "X. Minh Đài",
                "X. Văn Luông",
                "X. Xuân Sơn",
                "X. Long Cốc",
                "X. Kim Thượng",
                "X. Tam Thanh",
                "X. Vinh Tiền"
             ],
             "codeErp":"1513"
          }
       ],
       "n":"Phú Thọ",
       "codeErp":"15"
    },
    {
       "d":[
          {
             "n":"TP. Vĩnh Yên",
             "w":[
                "P. Tích Sơn",
                "P. Liên Bảo",
                "P. Hội Hợp",
                "P. Đống Đa",
                "P. Ngô Quyền",
                "P. Đồng Tâm",
                "X. Định Trung",
                "P. Khai Quang",
                "X. Thanh Trù"
             ],
             "codeErp":"1601"
          },
          {
             "n":"TP. Phúc Yên",
             "w":[
                "P. Trưng Trắc",
                "P. Hùng Vương",
                "P. Trưng Nhị",
                "P. Phúc Thắng",
                "P. Xuân Hoà",
                "P. Đồng Xuân",
                "X. Ngọc Thanh",
                "X. Cao Minh",
                "P. Nam Viêm",
                "P. Tiền Châu"
             ],
             "codeErp":"1608"
          },
          {
             "n":"H. Lập Thạch",
             "w":[
                "TT. Lập Thạch",
                "X. Quang Sơn",
                "X. Ngọc Mỹ",
                "X. Hợp Lý",
                "X. Bắc Bình",
                "X. Thái Hòa",
                "TT. Hoa Sơn",
                "X. Liễn Sơn",
                "X. Xuân Hòa",
                "X. Vân Trục",
                "X. Liên Hòa",
                "X. Tử Du",
                "X. Bàn Giản",
                "X. Xuân Lôi",
                "X. Đồng Ích",
                "X. Tiên Lữ",
                "X. Văn Quán",
                "X. Đình Chu",
                "X. Triệu Đề",
                "X. Sơn Đông"
             ],
             "codeErp":"1603"
          },
          {
             "n":"H. Tam Dương",
             "w":[
                "TT. Hợp Hòa",
                "X. Hoàng Hoa",
                "X. Đồng Tĩnh",
                "X. Kim Long",
                "X. Hướng Đạo",
                "X. Đạo Tú",
                "X. An Hòa",
                "X. Thanh Vân",
                "X. Duy Phiên",
                "X. Hoàng Đan",
                "X. Hoàng Lâu",
                "X. Vân Hội",
                "X. Hợp Thịnh"
             ],
             "codeErp":"1602"
          },
          {
             "n":"H. Tam Đảo",
             "w":[
                "TT. Tam Đảo",
                "TT. Hợp Châu",
                "X. Đạo Trù",
                "X. Yên Dương",
                "X. Bồ Lý",
                "TT. Đại Đình",
                "X. Tam Quan",
                "X. Hồ Sơn",
                "X. Minh Quang"
             ],
             "codeErp":"1609"
          },
          {
             "n":"H. Bình Xuyên",
             "w":[
                "TT. Hương Canh",
                "TT. Gia Khánh",
                "X. Trung Mỹ",
                "TT. Bá Hiến",
                "X. Thiện Kế",
                "X. Hương Sơn",
                "X. Tam Hợp",
                "X. Quất Lưu",
                "X. Sơn Lôi",
                "TT. Đạo Đức",
                "X. Tân Phong",
                "TT. Thanh Lãng",
                "X. Phú Xuân"
             ],
             "codeErp":"1606"
          },
          {
             "n":"H. Yên Lạc",
             "w":[
                "TT. Yên Lạc",
                "X. Đồng Cương",
                "X. Đồng Văn",
                "X. Bình Định",
                "X. Trung Nguyên",
                "X. Tề Lỗ",
                "X. Tam Hồng",
                "X. Yên Đồng",
                "X. Văn Tiến",
                "X. Nguyệt Đức",
                "X. Yên Phương",
                "X. Hồng Phương",
                "X. Trung Kiên",
                "X. Liên Châu",
                "X. Đại Tự",
                "X. Hồng Châu",
                "X. Trung Hà"
             ],
             "codeErp":"1605"
          },
          {
             "n":"H. Vĩnh Tường",
             "w":[
                "TT. Vĩnh Tường",
                "X. Kim Xá",
                "X. Yên Bình",
                "X. Chấn Hưng",
                "X. Nghĩa Hưng",
                "X. Yên Lập",
                "X. Việt Xuân",
                "X. Bồ Sao",
                "X. Đại Đồng",
                "X. Tân Tiến",
                "X. Lũng Hoà",
                "X. Cao Đại",
                "TT. Thổ Tang",
                "X. Vĩnh Sơn",
                "X. Bình Dương",
                "X. Tân Phú",
                "X. Thượng Trưng",
                "X. Vũ Di",
                "X. Lý Nhân",
                "X. Tuân Chính",
                "X. Vân Xuân",
                "X. Tam Phúc",
                "TT. Tứ Trưng",
                "X. Ngũ Kiên",
                "X. An Tường",
                "X. Vĩnh Thịnh",
                "X. Phú Đa",
                "X. Vĩnh Ninh"
             ],
             "codeErp":"1604"
          },
          {
             "n":"H. Sông Lô",
             "w":[
                "X. Lãng Công",
                "X. Quang Yên",
                "X. Bạch Lưu",
                "X. Hải Lựu",
                "X. Đồng Quế",
                "X. Nhân Đạo",
                "X. Đôn Nhân",
                "X. Phương Khoan",
                "X. Tân Lập",
                "X. Nhạo Sơn",
                "TT. Tam Sơn",
                "X. Như Thụy",
                "X. Yên Thạch",
                "X. Đồng Thịnh",
                "X. Tứ Yên",
                "X. Đức Bác",
                "X. Cao Phong"
             ],
             "codeErp":"1607"
          }
       ],
       "n":"Vĩnh Phúc",
       "codeErp":"16"
    },
    {
       "d":[
          {
             "n":"TP. Phủ Lý",
             "w":[
                "P. Quang Trung",
                "P. Lương Khánh Thiện",
                "P. Lê Hồng Phong",
                "P. Minh Khai",
                "P. Hai Bà Trưng",
                "P. Trần Hưng Đạo",
                "P. Lam Hạ",
                "X. Phù Vân",
                "P. Liêm Chính",
                "X. Liêm Chung",
                "P. Thanh Châu",
                "P. Châu Sơn",
                "X. Tiên Tân",
                "X. Tiên Hiệp",
                "X. Tiên Hải",
                "X. Kim Bình",
                "X. Liêm Tuyền",
                "X. Liêm Tiết",
                "P. Thanh Tuyền",
                "X. Đinh Xá",
                "X. Trịnh Xá"
             ],
             "codeErp":"2401"
          },
          {
             "n":"TX. Duy Tiên",
             "w":[
                "P. Đồng Văn",
                "P. Hòa Mạc",
                "X. Mộc Bắc",
                "P. Châu Giang",
                "P. Bạch Thượng",
                "P. Duy Minh",
                "X. Mộc Nam",
                "P. Duy Hải",
                "X. Chuyên Ngoại",
                "P. Yên Bắc",
                "X. Trác Văn",
                "P. Tiên Nội",
                "P. Hoàng Đông",
                "X. Yên Nam",
                "X. Tiên Ngoại",
                "X. Tiên Sơn"
             ],
             "codeErp":"2402"
          },
          {
             "n":"H. Kim Bảng",
             "w":[
                "TT. Quế",
                "X. Nguyễn Úy",
                "X. Đại Cương",
                "X. Lê Hồ",
                "X. Tượng Lĩnh",
                "X. Nhật Tựu",
                "X. Nhật Tân",
                "X. Đồng Hóa",
                "X. Hoàng Tây",
                "X. Tân Sơn",
                "X. Thụy Lôi",
                "X. Văn Xá",
                "X. Khả Phong",
                "X. Ngọc Sơn",
                "TT. Ba Sao",
                "X. Liên Sơn",
                "X. Thi Sơn",
                "X. Thanh Sơn"
             ],
             "codeErp":"2403"
          },
          {
             "n":"H. Thanh Liêm",
             "w":[
                "TT. Kiện Khê",
                "X. Liêm Phong",
                "X. Thanh Hà",
                "X. Liêm Cần",
                "X. Liêm Thuận",
                "X. Thanh Thủy",
                "X. Thanh Phong",
                "TT. Tân Thanh",
                "X. Thanh Tân",
                "X. Liêm Túc",
                "X. Liêm Sơn",
                "X. Thanh Hương",
                "X. Thanh Nghị",
                "X. Thanh Tâm",
                "X. Thanh Nguyên",
                "X. Thanh Hải"
             ],
             "codeErp":"2405"
          },
          {
             "n":"H. Bình Lục",
             "w":[
                "TT. Bình Mỹ",
                "X. Bình Nghĩa",
                "X. Tràng An",
                "X. Đồng Du",
                "X. Ngọc Lũ",
                "X. Hưng Công",
                "X. Đồn Xá",
                "X. An Ninh",
                "X. Bồ Đề",
                "X. Bối Cầu",
                "X. An Nội",
                "X. Vũ Bản",
                "X. Trung Lương",
                "X. An Đổ",
                "X. La Sơn",
                "X. Tiêu Động",
                "X. An Lão"
             ],
             "codeErp":"2406"
          },
          {
             "n":"H. Lý Nhân",
             "w":[
                "X. Hợp Lý",
                "X. Nguyên Lý",
                "X. Chính Lý",
                "X. Chân Lý",
                "X. Đạo Lý",
                "X. Công Lý",
                "X. Văn Lý",
                "X. Bắc Lý",
                "X. Đức Lý",
                "X. Trần Hưng Đạo",
                "TT. Vĩnh Trụ",
                "X. Nhân Thịnh",
                "X. Nhân Khang",
                "X. Nhân Mỹ",
                "X. Nhân Nghĩa",
                "X. Nhân Chính",
                "X. Nhân Bình",
                "X. Phú Phúc",
                "X. Xuân Khê",
                "X. Tiến Thắng",
                "X. Hòa Hậu"
             ],
             "codeErp":"2404"
          }
       ],
       "n":"Hà Nam",
       "codeErp":"24"
    },
    {
       "d":[
          {
             "n":"TP. Thái Bình",
             "w":[
                "P. Lê Hồng Phong",
                "P. Bồ Xuyên",
                "P. Đề Thám",
                "P. Kỳ Bá",
                "P. Quang Trung",
                "P. Phú Khánh",
                "P. Tiền Phong",
                "P. Trần Hưng Đạo",
                "P. Trần Lãm",
                "X. Đông Hòa",
                "P. Hoàng Diệu",
                "X. Phú Xuân",
                "X. Vũ Phúc",
                "X. Vũ Chính",
                "X. Đông Mỹ",
                "X. Đông Thọ",
                "X. Vũ Đông",
                "X. Vũ Lạc",
                "X. Tân Bình"
             ],
             "codeErp":"2601"
          },
          {
             "n":"H. Quỳnh Phụ",
             "w":[
                "TT. Quỳnh Côi",
                "X. An Khê",
                "X. An Đồng",
                "X. Quỳnh Hoa",
                "X. Quỳnh Lâm",
                "X. Quỳnh Thọ",
                "X. An Hiệp",
                "X. Quỳnh Hoàng",
                "X. Quỳnh Giao",
                "X. An Thái",
                "X. An Cầu",
                "X. Quỳnh Hồng",
                "X. Quỳnh Khê",
                "X. Quỳnh Minh",
                "X. An Ninh",
                "X. Quỳnh Ngọc",
                "X. Quỳnh Hải",
                "TT. An Bài",
                "X. An Ấp",
                "X. Quỳnh Hội",
                "X. Châu Sơn",
                "X. Quỳnh Mỹ",
                "X. An Quí",
                "X. An Thanh",
                "X. An Vũ",
                "X. An Lễ",
                "X. Quỳnh Hưng",
                "X. Quỳnh Bảo",
                "X. An Mỹ",
                "X. Quỳnh Nguyên",
                "X. An Vinh",
                "X. Quỳnh Xá",
                "X. An Dục",
                "X. Đông Hải",
                "X. Quỳnh Trang",
                "X. An Tràng",
                "X. Đồng Tiến"
             ],
             "codeErp":"2602"
          },
          {
             "n":"H. Hưng Hà",
             "w":[
                "TT. Hưng Hà",
                "X. Điệp Nông",
                "X. Tân Lễ",
                "X. Cộng Hòa",
                "X. Dân Chủ",
                "X. Canh Tân",
                "X. Hòa Tiến",
                "X. Hùng Dũng",
                "X. Tân Tiến",
                "TT. Hưng Nhân",
                "X. Đoan Hùng",
                "X. Duyên Hải",
                "X. Tân Hòa",
                "X. Văn Cẩm",
                "X. Bắc Sơn",
                "X. Đông Đô",
                "X. Phúc Khánh",
                "X. Liên Hiệp",
                "X. Tây Đô",
                "X. Thống Nhất",
                "X. Tiến Đức",
                "X. Thái Hưng",
                "X. Thái Phương",
                "X. Hòa Bình",
                "X. Chi Lăng",
                "X. Minh Khai",
                "X. Hồng An",
                "X. Kim Chung",
                "X. Hồng Lĩnh",
                "X. Minh Tân",
                "X. Văn Lang",
                "X. Độc Lập",
                "X. Chí Hòa",
                "X. Minh Hòa",
                "X. Hồng Minh"
             ],
             "codeErp":"2603"
          },
          {
             "n":"H. Đông Hưng",
             "w":[
                "TT. Đông Hưng",
                "X. Đô Lương",
                "X. Đông Phương",
                "X. Liên Giang",
                "X. An Châu",
                "X. Đông Sơn",
                "X. Đông Cường",
                "X. Phú Lương",
                "X. Mê Linh",
                "X. Lô Giang",
                "X. Đông La",
                "X. Minh Tân",
                "X. Đông Xá",
                "X. Chương Dương",
                "X. Nguyên Xá",
                "X. Phong Châu",
                "X. Hợp Tiến",
                "X. Hồng Việt",
                "X. Hà Giang",
                "X. Đông Kinh",
                "X. Đông Hợp",
                "X. Thăng Long",
                "X. Đông Các",
                "X. Phú Châu",
                "X. Liên Hoa",
                "X. Đông Tân",
                "X. Đông Vinh",
                "X. Đông Động",
                "X. Hồng Bạch",
                "X. Trọng Quan",
                "X. Hồng Giang",
                "X. Đông Quan",
                "X. Đông Quang",
                "X. Đông Xuân",
                "X. Đông Á",
                "X. Đông Hoàng",
                "X. Đông Dương",
                "X. Minh Phú"
             ],
             "codeErp":"2604"
          },
          {
             "n":"H. Thái Thụy",
             "w":[
                "TT. Diêm Điền",
                "X. Thụy Trường",
                "X. Hồng Dũng",
                "X. Thụy Quỳnh",
                "X. An Tân",
                "X. Thụy Ninh",
                "X. Thụy Hưng",
                "X. Thụy Việt",
                "X. Thụy Văn",
                "X. Thụy Xuân",
                "X. Dương Phúc",
                "X. Thụy Trình",
                "X. Thụy Bình",
                "X. Thụy Chính",
                "X. Thụy Dân",
                "X. Thụy Hải",
                "X. Thụy Liên",
                "X. Thụy Duyên",
                "X. Thụy Thanh",
                "X. Thụy Sơn",
                "X. Thụy Phong",
                "X. Thái Thượng",
                "X. Thái Nguyên",
                "X. Dương Hồng  Thủy",
                "X. Thái Giang",
                "X. Hòa An",
                "X. Sơn Hà",
                "X. Thái Phúc",
                "X. Thái Hưng",
                "X. Thái Đô",
                "X. Thái Xuyên",
                "X.  Mỹ Lộc",
                "X. Tân Học",
                "X. Thái Thịnh",
                "X. Thuần Thành",
                "X. Thái Thọ"
             ],
             "codeErp":"2608"
          },
          {
             "n":"H. Tiền Hải",
             "w":[
                "TT. Tiền Hải",
                "X. Đông Trà",
                "X. Đông Long",
                "X. Đông Quí",
                "X. Vũ Lăng",
                "X. Đông Xuyên",
                "X. Tây Lương",
                "X. Tây Ninh",
                "X. Đông Trung",
                "X. Đông Hoàng",
                "X. Đông Minh",
                "X. Đông Phong",
                "X. An Ninh",
                "X. Đông Cơ",
                "X. Tây Giang",
                "X. Đông Lâm",
                "X. Phương Công",
                "X. Tây Phong",
                "X. Tây Tiến",
                "X. Nam Cường",
                "X. Vân Trường",
                "X. Nam Thắng",
                "X. Nam Chính",
                "X. Bắc Hải",
                "X. Nam Thịnh",
                "X. Nam Hà",
                "X. Nam Thanh",
                "X. Nam Trung",
                "X. Nam Hồng",
                "X. Nam Hưng",
                "X. Nam Hải",
                "X. Nam Phú"
             ],
             "codeErp":"2607"
          },
          {
             "n":"H. Kiến Xương",
             "w":[
                "TT. Kiến Xương",
                "X. Trà Giang",
                "X. Quốc Tuấn",
                "X. An Bình",
                "X. Tây Sơn",
                "X. Hồng Thái",
                "X. Bình Nguyên",
                "X. Lê Lợi",
                "X. Vũ Lễ",
                "X. Thanh Tân",
                "X. Thượng Hiền",
                "X. Nam Cao",
                "X. Đình Phùng",
                "X. Vũ Ninh",
                "X. Vũ An",
                "X. Quang Lịch",
                "X. Hòa Bình",
                "X. Bình Minh",
                "X. Vũ Quí",
                "X. Quang Bình",
                "X. Vũ Trung",
                "X. Vũ Thắng",
                "X. Vũ Công",
                "X. Vũ Hòa",
                "X. Quang Minh",
                "X. Quang Trung",
                "X. Minh Quang",
                "X. Vũ Bình",
                "X. Minh Tân",
                "X. Nam Bình",
                "X. Bình Thanh",
                "X. Bình Định",
                "X. Hồng Tiến"
             ],
             "codeErp":"2606"
          },
          {
             "n":"H. Vũ Thư",
             "w":[
                "TT. Vũ Thư",
                "X. Hồng Lý",
                "X. Đồng Thanh",
                "X. Xuân Hòa",
                "X. Hiệp Hòa",
                "X. Phúc Thành",
                "X. Tân Phong",
                "X. Song Lãng",
                "X. Tân Hòa",
                "X. Việt Hùng",
                "X. Minh Lãng",
                "X. Minh Khai",
                "X. Dũng Nghĩa",
                "X. Minh Quang",
                "X. Tam Quang",
                "X. Tân Lập",
                "X. Bách Thuận",
                "X. Tự Tân",
                "X. Song An",
                "X. Trung An",
                "X. Vũ Hội",
                "X. Hòa Bình",
                "X. Nguyên Xá",
                "X. Việt Thuận",
                "X. Vũ Vinh",
                "X. Vũ Đoài",
                "X. Vũ Tiến",
                "X. Vũ Vân",
                "X. Duy Nhất",
                "X. Hồng Phong"
             ],
             "codeErp":"2605"
          }
       ],
       "n":"Thái Bình",
       "codeErp":"26"
    },
    {
       "d":[
          {
             "n":"TP. Hải Dương",
             "w":[
                "P. Cẩm Thượng",
                "P. Bình Hàn",
                "P. Ngọc Châu",
                "P. Nhị Châu",
                "P. Quang Trung",
                "P. Nguyễn Trãi",
                "P. Phạm Ngũ Lão",
                "P. Trần Hưng Đạo",
                "P. Trần Phú",
                "P. Thanh Bình",
                "P. Tân Bình",
                "P. Lê Thanh Nghị",
                "P. Hải Tân",
                "P. Tứ Minh",
                "P. Việt Hoà",
                "P. Ái Quốc",
                "X. An Thượng",
                "P. Nam Đồng",
                "X. Quyết Thắng",
                "X. Tiền Tiến",
                "P. Thạch Khôi",
                "X. Liên Hồng",
                "P. Tân Hưng",
                "X. Gia Xuyên",
                "X. Ngọc Sơn"
             ],
             "codeErp":"2101"
          },
          {
             "n":"TP. Chí Linh",
             "w":[
                "P. Phả Lại",
                "P. Sao Đỏ",
                "P. Bến Tắm",
                "X. Hoàng Hoa Thám",
                "X. Bắc An",
                "X. Hưng Đạo",
                "X. Lê Lợi",
                "P. Hoàng Tiến",
                "P. Cộng Hoà",
                "P. Hoàng Tân",
                "P. Cổ Thành",
                "P. Văn An",
                "P. Chí Minh",
                "P. Văn Đức",
                "P. Thái Học",
                "X. Nhân Huệ",
                "P. An Lạc",
                "P. Đồng Lạc",
                "P. Tân Dân"
             ],
             "codeErp":"2102"
          },
          {
             "n":"H. Nam Sách",
             "w":[
                "TT. Nam Sách",
                "X. Nam Hưng",
                "X. Nam Tân",
                "X. Hợp Tiến",
                "X. Hiệp Cát",
                "X. Thanh Quang",
                "X. Quốc Tuấn",
                "X. Nam Chính",
                "X. An Bình",
                "X. Nam Trung",
                "X. An Sơn",
                "X. Cộng Hòa",
                "X. Thái Tân",
                "X. An Lâm",
                "X. Phú Điền",
                "X. Nam Hồng",
                "X. Hồng Phong",
                "X. Đồng Lạc",
                "X. Minh Tân"
             ],
             "codeErp":"2103"
          },
          {
             "n":"TX. Kinh Môn",
             "w":[
                "P. An Lưu",
                "X. Bạch Đằng",
                "P. Thất Hùng",
                "X. Lê Ninh",
                "X. Hoành Sơn",
                "P. Phạm Thái",
                "P. Duy Tân",
                "P. Tân Dân",
                "P. Minh Tân",
                "X. Quang Thành",
                "X. Hiệp Hòa",
                "P. Phú Thứ",
                "X. Thăng Long",
                "X. Lạc Long",
                "P. An Sinh",
                "P. Hiệp Sơn",
                "X. Thượng Quận",
                "P. An Phụ",
                "P. Hiệp An",
                "P. Long Xuyên",
                "P. Thái Thịnh",
                "P. Hiến Thành",
                "X. Minh Hòa"
             ],
             "codeErp":"2104"
          },
          {
             "n":"H. Kim Thành",
             "w":[
                "TT. Phú Thái",
                "X. Lai Vu",
                "X. Cộng Hòa",
                "X. Thượng Vũ",
                "X. Cổ Dũng",
                "X. Tuấn Việt",
                "X. Kim Xuyên",
                "X. Phúc Thành A",
                "X. Ngũ Phúc",
                "X. Kim Anh",
                "X. Kim Liên",
                "X. Kim Tân",
                "X. Kim Đính",
                "X. Bình Dân",
                "X. Tam Kỳ",
                "X. Đồng Cẩm",
                "X. Liên Hòa",
                "X. Đại Đức"
             ],
             "codeErp":"2111"
          },
          {
             "n":"H. Thanh Hà",
             "w":[
                "TT. Thanh Hà",
                "X. Hồng Lạc",
                "X. Việt Hồng",
                "X. Tân Việt",
                "X. Cẩm Chế",
                "X. Thanh An",
                "X. Thanh Lang",
                "X. Tân An",
                "X. Liên Mạc",
                "X. Thanh Hải",
                "X. Thanh Khê",
                "X. Thanh Xá",
                "X. Thanh Xuân",
                "X. Thanh Thủy",
                "X. An Phượng",
                "X. Thanh Sơn",
                "X. Thanh Quang",
                "X. Thanh Hồng",
                "X. Thanh Cường",
                "X. Vĩnh Lập"
             ],
             "codeErp":"2110"
          },
          {
             "n":"H. Cẩm Giàng",
             "w":[
                "TT. Cẩm Giang",
                "TT. Lai Cách",
                "X. Cẩm Hưng",
                "X. Cẩm Hoàng",
                "X. Cẩm Văn",
                "X. Ngọc Liên",
                "X. Thạch Lỗi",
                "X. Cẩm Vũ",
                "X. Đức Chính",
                "X. Định Sơn",
                "X. Lương Điền",
                "X. Cao An",
                "X. Tân Trường",
                "X. Cẩm Phúc",
                "X. Cẩm Điền",
                "X. Cẩm Đông",
                "X. Cẩm Đoài"
             ],
             "codeErp":"2109"
          },
          {
             "n":"H. Bình Giang",
             "w":[
                "TT. Kẻ Sặt",
                "X. Vĩnh Hưng",
                "X. Hùng Thắng",
                "X. Vĩnh Hồng",
                "X. Long Xuyên",
                "X. Tân Việt",
                "X. Thúc Kháng",
                "X. Tân Hồng",
                "X. Bình Minh",
                "X. Hồng Khê",
                "X. Thái Học",
                "X. Cổ Bì",
                "X. Nhân Quyền",
                "X. Thái Dương",
                "X. Thái Hòa",
                "X. Bình Xuyên"
             ],
             "codeErp":"2112"
          },
          {
             "n":"H. Gia Lộc",
             "w":[
                "TT. Gia Lộc",
                "X. Thống Nhất",
                "X. Yết Kiêu",
                "X. Gia Tân",
                "X. Tân Tiến",
                "X. Gia Khánh",
                "X. Gia Lương",
                "X. Lê Lợi",
                "X. Toàn Thắng",
                "X. Hoàng Diệu",
                "X. Hồng Hưng",
                "X. Phạm Trấn",
                "X. Đoàn Thượng",
                "X. Thống Kênh",
                "X. Quang Minh",
                "X. Đồng Quang",
                "X. Nhật Tân",
                "X. Đức Xương"
             ],
             "codeErp":"2105"
          },
          {
             "n":"H. Tứ Kỳ",
             "w":[
                "TT. Tứ Kỳ",
                "X. Đại Sơn",
                "X. Hưng Đạo",
                "X. Ngọc Kỳ",
                "X. Bình Lăng",
                "X. Chí Minh",
                "X. Tái Sơn",
                "X. Quang Phục",
                "X. Dân Chủ",
                "X. Tân Kỳ",
                "X. Quang Khải",
                "X. Đại Hợp",
                "X. Quảng Nghiệp",
                "X. An Thanh",
                "X. Minh Đức",
                "X. Văn Tố",
                "X. Quang Trung",
                "X. Phượng Kỳ",
                "X. Cộng Lạc",
                "X. Tiên Động",
                "X. Nguyên Giáp",
                "X. Hà Kỳ",
                "X. Hà Thanh"
             ],
             "codeErp":"2106"
          },
          {
             "n":"H. Ninh Giang",
             "w":[
                "TT. Ninh Giang",
                "X. Ứng Hoè",
                "X. Nghĩa An",
                "X. Hồng Đức",
                "X. An Đức",
                "X. Vạn Phúc",
                "X. Tân Hương",
                "X. Vĩnh Hòa",
                "X. Đông Xuyên",
                "X. Tân Phong",
                "X. Ninh Hải",
                "X. Đồng Tâm",
                "X. Tân Quang",
                "X. Kiến Quốc",
                "X. Hồng Dụ",
                "X. Văn Hội",
                "X. Hồng Phong",
                "X. Hiệp Lực",
                "X. Hồng Phúc",
                "X. Hưng Long"
             ],
             "codeErp":"2108"
          },
          {
             "n":"H. Thanh Miện",
             "w":[
                "TT. Thanh Miện",
                "X. Thanh Tùng",
                "X. Phạm Kha",
                "X. Ngô Quyền",
                "X. Đoàn Tùng",
                "X. Hồng Quang",
                "X. Tân Trào",
                "X. Lam Sơn",
                "X. Đoàn Kết",
                "X. Lê Hồng",
                "X. Tứ Cường",
                "X. Ngũ Hùng",
                "X. Cao Thắng",
                "X. Chi Lăng Bắc",
                "X. Chi Lăng Nam",
                "X. Thanh Giang",
                "X. Hồng Phong"
             ],
             "codeErp":"2107"
          }
       ],
       "n":"Hải Dương",
       "codeErp":"21"
    },
    {
       "d":[
          {
             "n":"TP. Hưng Yên",
             "w":[
                "P. Lam Sơn",
                "P. Hiến Nam",
                "P. An Tảo",
                "P. Lê Lợi",
                "P. Minh Khai",
                "P. Quang Trung",
                "P. Hồng Châu",
                "X. Trung Nghĩa",
                "X. Liên Phương",
                "X. Hồng Nam",
                "X. Quảng Châu",
                "X. Bảo Khê",
                "X. Phú Cường",
                "X. Hùng Cường",
                "X. Phương Chiểu",
                "X. Tân Hưng",
                "X. Hoàng Hanh"
             ],
             "codeErp":"2201"
          },
          {
             "n":"H. Văn Lâm",
             "w":[
                "TT. Như Quỳnh",
                "X. Lạc Đạo",
                "X. Chỉ Đạo",
                "X. Đại Đồng",
                "X. Việt Hưng",
                "X. Tân Quang",
                "X. Đình Dù",
                "X. Minh Hải",
                "X. Lương Tài",
                "X. Trưng Trắc",
                "X. Lạc Hồng"
             ],
             "codeErp":"2209"
          },
          {
             "n":"H. Văn Giang",
             "w":[
                "TT. Văn Giang",
                "X. Xuân Quan",
                "X. Cửu Cao",
                "X. Phụng Công",
                "X. Nghĩa Trụ",
                "X. Long Hưng",
                "X. Vĩnh Khúc",
                "X. Liên Nghĩa",
                "X. Tân Tiến",
                "X. Thắng Lợi",
                "X. Mễ Sở"
             ],
             "codeErp":"2210"
          },
          {
             "n":"H. Yên Mỹ",
             "w":[
                "TT. Yên Mỹ",
                "X. Giai Phạm",
                "X. Nghĩa Hiệp",
                "X. Đồng Than",
                "X. Ngọc Long",
                "X. Liêu Xá",
                "X. Hoàn Long",
                "X. Tân Lập",
                "X. Thanh Long",
                "X. Yên Phú",
                "X. Việt Cường",
                "X. Trung Hòa",
                "X. Yên Hòa",
                "X. Minh Châu",
                "X. Trung Hưng",
                "X. Lý Thường Kiệt",
                "X. Tân Việt"
             ],
             "codeErp":"2205"
          },
          {
             "n":"TX. Mỹ Hào",
             "w":[
                "P. Bần Yên Nhân",
                "P. Phan Đình Phùng",
                "X. Cẩm Xá",
                "X. Dương Quang",
                "X. Hòa Phong",
                "P. Nhân Hòa",
                "P. Dị Sử",
                "P. Bạch Sam",
                "P. Minh Đức",
                "P. Phùng Chí Kiên",
                "X. Xuân Dục",
                "X. Ngọc Lâm",
                "X. Hưng Long"
             ],
             "codeErp":"2208"
          },
          {
             "n":"H. Ân Thi",
             "w":[
                "TT. Ân Thi",
                "X. Phù Ủng",
                "X. Bắc Sơn",
                "X. Bãi Sậy",
                "X. Đào Dương",
                "X. Tân Phúc",
                "X. Vân Du",
                "X. Quang Vinh",
                "X. Xuân Trúc",
                "X. Hoàng Hoa Thám",
                "X. Quảng Lãng",
                "X. Văn Nhuệ",
                "X. Đặng Lễ",
                "X. Cẩm Ninh",
                "X. Nguyễn Trãi",
                "X. Đa Lộc",
                "X. Hồ Tùng Mậu",
                "X. Tiền Phong",
                "X. Hồng Vân",
                "X. Hồng Quang",
                "X. Hạ Lễ"
             ],
             "codeErp":"2203"
          },
          {
             "n":"H. Khoái Châu",
             "w":[
                "TT. Khoái Châu",
                "X. Đông Tảo",
                "X. Bình Minh",
                "X. Dạ Trạch",
                "X. Hàm Tử",
                "X. Ông Đình",
                "X. Tân Dân",
                "X. Tứ Dân",
                "X. An Vĩ",
                "X. Đông Kết",
                "X. Bình Kiều",
                "X. Dân Tiến",
                "X. Đồng Tiến",
                "X. Hồng Tiến",
                "X. Tân Châu",
                "X. Liên Khê",
                "X. Phùng Hưng",
                "X. Việt Hòa",
                "X. Đông Ninh",
                "X. Đại Tập",
                "X. Chí Tân",
                "X. Đại Hưng",
                "X. Thuần Hưng",
                "X. Thành Công",
                "X. Nhuế Dương"
             ],
             "codeErp":"2204"
          },
          {
             "n":"H. Kim Động",
             "w":[
                "TT. Lương Bằng",
                "X. Nghĩa Dân",
                "X. Toàn Thắng",
                "X. Vĩnh Xá",
                "X. Phạm Ngũ Lão",
                "X. Thọ Vinh",
                "X. Đồng Thanh",
                "X. Song Mai",
                "X. Chính Nghĩa",
                "X. Nhân La",
                "X. Phú Thịnh",
                "X. Mai Động",
                "X. Đức Hợp",
                "X. Hùng An",
                "X. Ngọc Thanh",
                "X. Vũ Xá",
                "X. Hiệp Cường"
             ],
             "codeErp":"2202"
          },
          {
             "n":"H. Tiên Lữ",
             "w":[
                "TT. Vương",
                "X. Hưng Đạo",
                "X. Ngô Quyền",
                "X. Nhật Tân",
                "X. Dị Chế",
                "X. Lệ Xá",
                "X. An Viên",
                "X. Đức Thắng",
                "X. Trung Dũng",
                "X. Hải Triều",
                "X. Thủ Sỹ",
                "X. Thiện Phiến",
                "X. Thụy Lôi",
                "X. Cương Chính",
                "X. Minh Phượng"
             ],
             "codeErp":"2206"
          },
          {
             "n":"H. Phù Cừ",
             "w":[
                "TT. Trần Cao",
                "X. Minh Tân",
                "X. Phan Sào Nam",
                "X. Quang Hưng",
                "X. Minh Hoàng",
                "X. Đoàn Đào",
                "X. Tống Phan",
                "X. Đình Cao",
                "X. Nhật Quang",
                "X. Tiền Tiến",
                "X. Tam Đa",
                "X. Minh Tiến",
                "X. Nguyên Hòa",
                "X. Tống Trân"
             ],
             "codeErp":"2207"
          }
       ],
       "n":"Hưng Yên",
       "codeErp":"22"
    },
    {
       "d":[
          {
             "n":"TP. Nam Định",
             "w":[
                "P. Hạ Long",
                "P. Trần Tế Xương",
                "P. Vị Hoàng",
                "P. Vị Xuyên",
                "P. Quang Trung",
                "P. Cửa Bắc",
                "P. Nguyễn Du",
                "P. Bà Triệu",
                "P. Trường Thi",
                "P. Phan Đình Phùng",
                "P. Ngô Quyền",
                "P. Trần Hưng Đạo",
                "P. Trần Đăng Ninh",
                "P. Năng Tĩnh",
                "P. Văn Miếu",
                "P. Trần Quang Khải",
                "P. Thống Nhất",
                "P. Lộc Hạ",
                "P. Lộc Vượng",
                "P. Cửa Nam",
                "P. Lộc Hòa",
                "X. Nam Phong",
                "P. Mỹ Xá",
                "X. Lộc An",
                "X. Nam Vân"
             ],
             "codeErp":"2501"
          },
          {
             "n":"H. Mỹ Lộc",
             "w":[
                "TT. Mỹ Lộc",
                "X. Mỹ Hà",
                "X. Mỹ Tiến",
                "X. Mỹ Thắng",
                "X. Mỹ Trung",
                "X. Mỹ Tân",
                "X. Mỹ Phúc",
                "X. Mỹ Hưng",
                "X. Mỹ Thuận",
                "X. Mỹ Thịnh",
                "X. Mỹ Thành"
             ],
             "codeErp":"2502"
          },
          {
             "n":"H. Vụ Bản",
             "w":[
                "TT. Gôi",
                "X. Minh Thuận",
                "X. Hiển Khánh",
                "X. Tân Khánh",
                "X. Hợp Hưng",
                "X. Đại An",
                "X. Tân Thành",
                "X. Cộng Hòa",
                "X. Trung Thành",
                "X. Quang Trung",
                "X. Minh Tân",
                "X. Liên Bảo",
                "X. Thành Lợi",
                "X. Kim Thái",
                "X. Liên Minh",
                "X. Đại Thắng",
                "X. Tam Thanh",
                "X. Vĩnh Hào"
             ],
             "codeErp":""
          },
          {
             "n":"H. Ý Yên",
             "w":[
                "TT. Lâm",
                "X. Yên Trung",
                "X. Yên Thành",
                "X. Yên Tân",
                "X. Yên Lợi",
                "X. Yên Thọ",
                "X. Yên Nghĩa",
                "X. Yên Minh",
                "X. Yên Phương",
                "X. Yên Chính",
                "X. Yên Bình",
                "X. Yên Phú",
                "X. Yên Mỹ",
                "X. Yên Dương",
                "X. Yên Hưng",
                "X. Yên Khánh",
                "X. Yên Phong",
                "X. Yên Ninh",
                "X. Yên Lương",
                "X. Yên Hồng",
                "X. Yên Quang",
                "X. Yên Tiến",
                "X. Yên Thắng",
                "X. Yên Phúc",
                "X. Yên Cường",
                "X. Yên Lộc",
                "X. Yên Bằng",
                "X. Yên Đồng",
                "X. Yên Khang",
                "X. Yên Nhân",
                "X. Yên Trị"
             ],
             "codeErp":"2505"
          },
          {
             "n":"H. Nghĩa Hưng",
             "w":[
                "TT. Liễu Đề",
                "TT. Rạng Đông",
                "X. Nghĩa Đồng",
                "X. Nghĩa Thịnh",
                "X. Nghĩa Minh",
                "X. Nghĩa Thái",
                "X. Hoàng Nam",
                "X. Nghĩa Châu",
                "X. Nghĩa Trung",
                "X. Nghĩa Sơn",
                "X. Nghĩa Lạc",
                "X. Nghĩa Hồng",
                "X. Nghĩa Phong",
                "X. Nghĩa Phú",
                "X. Nghĩa Bình",
                "TT. Quỹ Nhất",
                "X. Nghĩa Tân",
                "X. Nghĩa Hùng",
                "X. Nghĩa Lâm",
                "X. Nghĩa Thành",
                "X. Phúc Thắng",
                "X. Nghĩa Lợi",
                "X. Nghĩa Hải",
                "X. Nam Điền"
             ],
             "codeErp":"2508"
          },
          {
             "n":"H. Nam Trực",
             "w":[
                "TT. Nam Giang",
                "X. Nam Mỹ",
                "X. Điền Xá",
                "X. Nghĩa An",
                "X. Nam Thắng",
                "X. Nam Toàn",
                "X. Hồng Quang",
                "X. Tân Thịnh",
                "X. Nam Cường",
                "X. Nam Hồng",
                "X. Nam Hùng",
                "X. Nam Hoa",
                "X. Nam Dương",
                "X. Nam Thanh",
                "X. Nam Lợi",
                "X. Bình Minh",
                "X. Đồng Sơn",
                "X. Nam Tiến",
                "X. Nam Hải",
                "X. Nam Thái"
             ],
             "codeErp":"2506"
          },
          {
             "n":"H. Trực Ninh",
             "w":[
                "TT. Cổ Lễ",
                "X. Phương Định",
                "X. Trực Chính",
                "X. Trung Đông",
                "X. Liêm Hải",
                "X. Trực Tuấn",
                "X. Việt Hùng",
                "X. Trực Đạo",
                "X. Trực Hưng",
                "X. Trực Nội",
                "TT. Cát Thành",
                "X. Trực Thanh",
                "X. Trực Khang",
                "X. Trực Thuận",
                "X. Trực Mỹ",
                "X. Trực Đại",
                "X. Trực Cường",
                "TT. Ninh Cường",
                "X. Trực Thái",
                "X. Trực Hùng",
                "X. Trực Thắng"
             ],
             "codeErp":"2507"
          },
          {
             "n":"H. Xuân Trường",
             "w":[
                "TT. Xuân Trường",
                "X. Xuân Châu",
                "X. Xuân Hồng",
                "X. Xuân Thành",
                "X. Xuân Thượng",
                "X. Xuân Phong",
                "X. Xuân Đài",
                "X. Xuân Tân",
                "X. Xuân Thủy",
                "X. Xuân Ngọc",
                "X. Xuân Bắc",
                "X. Xuân Phương",
                "X. Thọ Nghiệp",
                "X. Xuân Phú",
                "X. Xuân Trung",
                "X. Xuân Vinh",
                "X. Xuân Kiên",
                "X. Xuân Tiến",
                "X. Xuân Ninh",
                "X. Xuân Hòa"
             ],
             "codeErp":"2503"
          },
          {
             "n":"H. Giao Thủy",
             "w":[
                "TT. Ngô Đồng",
                "TT. Quất Lâm",
                "X. Giao Hương",
                "X. Hồng Thuận",
                "X. Giao Thiện",
                "X. Giao Thanh",
                "X. Hoành Sơn",
                "X. Bình Hòa",
                "X. Giao Tiến",
                "X. Giao Hà",
                "X. Giao Nhân",
                "X. Giao An",
                "X. Giao Lạc",
                "X. Giao Châu",
                "X. Giao Tân",
                "X. Giao Yến",
                "X. Giao Xuân",
                "X. Giao Thịnh",
                "X. Giao Hải",
                "X. Bạch Long",
                "X. Giao Long",
                "X. Giao Phong"
             ],
             "codeErp":"2504"
          },
          {
             "n":"H. Hải Hậu",
             "w":[
                "TT. Yên Định",
                "TT. Cồn",
                "TT. Thịnh Long",
                "X. Hải Nam",
                "X. Hải Trung",
                "X. Hải Vân",
                "X. Hải Minh",
                "X. Hải Anh",
                "X. Hải Hưng",
                "X. Hải Bắc",
                "X. Hải Phúc",
                "X. Hải Thanh",
                "X. Hải Hà",
                "X. Hải Long",
                "X. Hải Phương",
                "X. Hải Đường",
                "X. Hải Lộc",
                "X. Hải Quang",
                "X. Hải Đông",
                "X. Hải Sơn",
                "X. Hải Tân",
                "X. Hải Phong",
                "X. Hải An",
                "X. Hải Tây",
                "X. Hải Lý",
                "X. Hải Phú",
                "X. Hải Giang",
                "X. Hải Cường",
                "X. Hải Ninh",
                "X. Hải Chính",
                "X. Hải Xuân",
                "X. Hải Châu",
                "X. Hải Triều",
                "X. Hải Hòa"
             ],
             "codeErp":"2509"
          }
       ],
       "n":"Nam Định",
       "codeErp":"25"
    },
    {
       "d":[
          {
             "n":"Q. Hồng Bàng",
             "w":[
                "P. Quán Toan",
                "P. Hùng Vương",
                "P. Sở Dầu",
                "P. Thượng Lý",
                "P. Hạ Lý",
                "P. Minh Khai",
                "P. Trại Chuối",
                "P. Hoàng Văn Thụ",
                "P. Phan Bội Châu"
             ],
             "codeErp":"0301"
          },
          {
             "n":"Q. Ngô Quyền",
             "w":[
                "P. Máy Chai",
                "P. Máy Tơ",
                "P. Vạn Mỹ",
                "P. Cầu Tre",
                "P. Lạc Viên",
                "P. Gia Viên",
                "P. Đông Khê",
                "P. Cầu Đất",
                "P. Lê Lợi",
                "P. Đằng Giang",
                "P. Lạch Tray",
                "P. Đổng Quốc Bình"
             ],
             "codeErp":"0303"
          },
          {
             "n":"Q. Lê Chân",
             "w":[
                "P. Cát Dài",
                "P. An Biên",
                "P. Lam Sơn",
                "P. An Dương",
                "P. Trần Nguyên Hãn",
                "P. Hồ Nam",
                "P. Trại Cau",
                "P. Dư Hàng",
                "P. Hàng Kênh",
                "P. Đông Hải",
                "P. Niệm Nghĩa",
                "P. Nghĩa Xá",
                "P. Dư Hàng Kênh",
                "P. Kênh Dương",
                "P. Vĩnh Niệm"
             ],
             "codeErp":"0302"
          },
          {
             "n":"Q. Hải An",
             "w":[
                "P. Đông Hải 1",
                "P. Đông Hải 2",
                "P. Đằng Lâm",
                "P. Thành Tô",
                "P. Đằng Hải",
                "P. Nam Hải",
                "P. Cát Bi",
                "P. Tràng Cát"
             ],
             "codeErp":"0305"
          },
          {
             "n":"Q. Kiến An",
             "w":[
                "P. Quán Trữ",
                "P. Lãm Hà",
                "P. Đồng Hoà",
                "P. Bắc Sơn",
                "P. Nam Sơn",
                "P. Ngọc Sơn",
                "P. Trần Thành Ngọ",
                "P. Văn Đẩu",
                "P. Phù Liễn",
                "P. Tràng Minh"
             ],
             "codeErp":"0304"
          },
          {
             "n":"Q. Đồ Sơn",
             "w":[
                "P. Ngọc Xuyên",
                "P. Hải Sơn",
                "P. Vạn Hương",
                "P. Minh Đức",
                "P. Bàng La",
                "P. Hợp Đức"
             ],
             "codeErp":"0306"
          },
          {
             "n":"Q. Dương Kinh",
             "w":[
                "P. Đa Phúc",
                "P. Hưng Đạo",
                "P. Anh Dũng",
                "P. Hải Thành",
                "P. Hoà Nghĩa",
                "P. Tân Thành"
             ],
             "codeErp":"0315"
          },
          {
             "n":"H. Thuỷ Nguyên",
             "w":[
                "TT. Núi Đèo",
                "TT. Minh Đức",
                "X. Lại Xuân",
                "X. An Sơn",
                "X. Kỳ Sơn",
                "X. Liên Khê",
                "X. Lưu Kiếm",
                "X. Lưu Kỳ",
                "X. Gia Minh",
                "X. Gia Đức",
                "X. Minh Tân",
                "X. Phù Ninh",
                "X. Quảng Thanh",
                "X. Chính Mỹ",
                "X. Kênh Giang",
                "X. Hợp Thành",
                "X. Cao Nhân",
                "X. Mỹ Đồng",
                "X. Đông Sơn",
                "X. Hoà Bình",
                "X. Trung Hà",
                "X. An Lư",
                "X. Thuỷ Triều",
                "X. Ngũ Lão",
                "X. Phục Lễ",
                "X. Tam Hưng",
                "X. Phả Lễ",
                "X. Lập Lễ",
                "X. Kiền Bái",
                "X. Thiên Hương",
                "X. Thuỷ Sơn",
                "X. Thuỷ Đường",
                "X. Hoàng Động",
                "X. Lâm Động",
                "X. Hoa Động",
                "X. Tân Dương",
                "X. Dương Quan"
             ],
             "codeErp":"0309"
          },
          {
             "n":"H. An Dương",
             "w":[
                "TT. An Dương",
                "X. Lê Thiện",
                "X. Đại Bản",
                "X. An Hoà",
                "X. Hồng Phong",
                "X. Tân Tiến",
                "X. An Hưng",
                "X. An Hồng",
                "X. Bắc Sơn",
                "X. Nam Sơn",
                "X. Lê Lợi",
                "X. Đặng Cương",
                "X. Đồng Thái",
                "X. Quốc Tuấn",
                "X. An Đồng",
                "X. Hồng Thái"
             ],
             "codeErp":"0310"
          },
          {
             "n":"H. An Lão",
             "w":[
                "TT. An Lão",
                "X. Bát Trang",
                "X. Trường Thọ",
                "X. Trường Thành",
                "X. An Tiến",
                "X. Quang Hưng",
                "X. Quang Trung",
                "X. Quốc Tuấn",
                "X. An Thắng",
                "TT. Trường Sơn",
                "X. Tân Dân",
                "X. Thái Sơn",
                "X. Tân Viên",
                "X. Mỹ Đức",
                "X. Chiến Thắng",
                "X. An Thọ",
                "X. An Thái"
             ],
             "codeErp":"0307"
          },
          {
             "n":"H. Kiến Thuỵ",
             "w":[
                "TT. Núi Đối",
                "X. Đông Phương",
                "X. Thuận Thiên",
                "X. Hữu Bằng",
                "X. Đại Đồng",
                "X. Ngũ Phúc",
                "X. Kiến Quốc",
                "X. Du Lễ",
                "X. Thuỵ Hương",
                "X. Thanh Sơn",
                "X. Minh Tân",
                "X. Đại Hà",
                "X. Ngũ Đoan",
                "X. Tân Phong",
                "X. Tân Trào",
                "X. Đoàn Xá",
                "X. Tú Sơn",
                "X. Đại Hợp"
             ],
             "codeErp":"0308"
          },
          {
             "n":"H. Tiên Lãng",
             "w":[
                "TT. Tiên Lãng",
                "X. Đại Thắng",
                "X. Tiên Cường",
                "X. Tự Cường",
                "X. Quyết Tiến",
                "X. Khởi Nghĩa",
                "X. Tiên Thanh",
                "X. Cấp Tiến",
                "X. Kiến Thiết",
                "X. Đoàn Lập",
                "X. Bạch Đằng",
                "X. Quang Phục",
                "X. Toàn Thắng",
                "X. Tiên Thắng",
                "X. Tiên Minh",
                "X. Bắc Hưng",
                "X. Nam Hưng",
                "X. Hùng Thắng",
                "X. Tây Hưng",
                "X. Đông Hưng",
                "X. Vinh Quang"
             ],
             "codeErp":"0311"
          },
          {
             "n":"H. Vĩnh Bảo",
             "w":[
                "TT. Vĩnh Bảo",
                "X. Dũng Tiến",
                "X. Giang Biên",
                "X. Thắng Thuỷ",
                "X. Trung Lập",
                "X. Việt Tiến",
                "X. Vĩnh An",
                "X. Vĩnh Long",
                "X. Hiệp Hoà",
                "X. Hùng Tiến",
                "X. An Hoà",
                "X. Tân Hưng",
                "X. Tân Liên",
                "X. Nhân Hoà",
                "X. Tam Đa",
                "X. Hưng Nhân",
                "X. Vinh Quang",
                "X. Đồng Minh",
                "X. Thanh Lương",
                "X. Liên Am",
                "X. Lý Học",
                "X. Tam Cường",
                "X. Hoà Bình",
                "X. Tiền Phong",
                "X. Vĩnh Phong",
                "X. Cộng Hiền",
                "X. Cao Minh",
                "X. Cổ Am",
                "X. Vĩnh Tiến",
                "X. Trấn Dương"
             ],
             "codeErp":"0312"
          },
          {
             "n":"H. Cát Hải",
             "w":[
                "TT. Cát Bà",
                "TT. Cát Hải",
                "X. Nghĩa Lộ",
                "X. Đồng Bài",
                "X. Hoàng Châu",
                "X. Văn Phong",
                "X. Phù Long",
                "X. Gia Luận",
                "X. Hiền Hào",
                "X. Trân Châu",
                "X. Việt Hải",
                "X. Xuân Đám"
             ],
             "codeErp":"0313"
          },
          {
             "n":"H. Bạch Long Vĩ",
             "w":[
 
             ],
             "codeErp":"0314"
          }
       ],
       "n":"Tp. Hải Phòng",
       "codeErp":"03"
    },
    {
       "d":[
          {
             "n":"TP. Hà Tĩnh",
             "w":[
                "P. Trần Phú",
                "P. Nam Hà",
                "P. Bắc Hà",
                "P. Nguyễn Du",
                "P. Tân Giang",
                "P. Đại Nài",
                "P. Hà Huy Tập",
                "X. Thạch Trung",
                "P. Thạch Quý",
                "P. Thạch Linh",
                "P. Văn Yên",
                "X. Thạch Hạ",
                "X. Đồng Môn",
                "X. Thạch Hưng",
                "X. Thạch Bình"
             ],
             "codeErp":"3001"
          },
          {
             "n":"TX. Hồng Lĩnh",
             "w":[
                "P. Bắc Hồng",
                "P. Nam Hồng",
                "P. Trung Lương",
                "P. Đức Thuận",
                "P. Đậu Liêu",
                "X. Thuận Lộc"
             ],
             "codeErp":"3002"
          },
          {
             "n":"H. Hương Sơn",
             "w":[
                "TT. Phố Châu",
                "TT.  Tây Sơn",
                "X. Sơn Hồng",
                "X. Sơn Tiến",
                "X. Sơn Lâm",
                "X. Sơn Lễ",
                "X. Sơn Giang",
                "X. Sơn Lĩnh",
                "X. An Hòa Thịnh",
                "X. Sơn Tây",
                "X. Sơn Ninh",
                "X. Sơn Châu",
                "X. Tân Mỹ Hà",
                "X. Quang Diệm",
                "X. Sơn Trung",
                "X. Sơn Bằng",
                "X. Sơn Bình",
                "X. Sơn Kim 1",
                "X. Sơn Kim 2",
                "X. Sơn Trà",
                "X. Sơn Long",
                "X. Kim Hoa",
                "X. Sơn Hàm",
                "X. Sơn Phú",
                "X. Sơn Trường"
             ],
             "codeErp":"3003"
          },
          {
             "n":"H. Đức Thọ",
             "w":[
                "TT. Đức Thọ",
                "X. Quang Vĩnh",
                "X. Tùng Châu",
                "X. Trường Sơn",
                "X. Liên Minh",
                "X. Yên Hồ",
                "X. Tùng Ảnh",
                "X. Bùi La Nhân",
                "X. Thanh Bình Thịnh",
                "X. Lâm Trung Thủy",
                "X. Hòa Lạc",
                "X. Tân Dân",
                "X. An Dũng",
                "X. Đức Đồng",
                "X. Đức Lạng",
                "X. Tân Hương"
             ],
             "codeErp":"3004"
          },
          {
             "n":"H. Vũ Quang",
             "w":[
                "TT. Vũ Quang",
                "X. Ân Phú",
                "X. Đức Giang",
                "X. Đức Lĩnh",
                "X. Thọ Điền",
                "X. Đức Hương",
                "X. Đức Bồng",
                "X. Đức Liên",
                "X. Hương Minh",
                "X. Quang Thọ"
             ],
             "codeErp":"3011"
          },
          {
             "n":"H. Nghi Xuân",
             "w":[
                "TT. Xuân An",
                "X. Xuân Hội",
                "X. Đan Trường",
                "X. Xuân Phổ",
                "X. Xuân Hải",
                "X. Xuân Giang",
                "TT. Tiên Điền",
                "X. Xuân Yên",
                "X. Xuân Mỹ",
                "X. Xuân Thành",
                "X. Xuân Viên",
                "X. Xuân Hồng",
                "X. Cỗ Đạm",
                "X. Xuân Liên",
                "X. Xuân Lĩnh",
                "X. Xuân Lam",
                "X. Cương Gián"
             ],
             "codeErp":"3005"
          },
          {
             "n":"H. Can Lộc",
             "w":[
                "TT. Nghèn",
                "X. Thiên Lộc",
                "X. Thuần Thiện",
                "X. Vượng Lộc",
                "X. Thanh Lộc",
                "X. Kim Song Trường",
                "X. Thường Nga",
                "X. Tùng Lộc",
                "X. Phú Lộc",
                "X. Gia Hanh",
                "X. Khánh Vĩnh Yên",
                "X. Trung Lộc",
                "X. Xuân Lộc",
                "X. Thượng Lộc",
                "X. Quang Lộc",
                "TT. Đồng Lộc",
                "X. Mỹ Lộc",
                "X. Sơn Lộc"
             ],
             "codeErp":"3006"
          },
          {
             "n":"H. Hương Khê",
             "w":[
                "TT. Hương Khê",
                "X. Điền Mỹ",
                "X. Hà Linh",
                "X. Hương Thủy",
                "X. Hòa Hải",
                "X. Phúc Đồng",
                "X. Hương Giang",
                "X. Lộc Yên",
                "X. Hương Bình",
                "X. Hương Long",
                "X. Phú Gia",
                "X. Gia Phố",
                "X. Phú Phong",
                "X. Hương Đô",
                "X. Hương Vĩnh",
                "X. Hương Xuân",
                "X. Phúc Trạch",
                "X. Hương Trà",
                "X. Hương Trạch",
                "X. Hương Lâm",
                "X. Hương Liên"
             ],
             "codeErp":"3007"
          },
          {
             "n":"H. Thạch Hà",
             "w":[
                "TT. Thạch Hà",
                "X. Ngọc Sơn",
                "X. Thạch Hải",
                "X. Thạch Kênh",
                "X. Thạch Sơn",
                "X. Thạch Liên",
                "X. Đỉnh Bàn",
                "X. Việt Tiến",
                "X. Thạch Khê",
                "X. Thạch Long",
                "X. Thạch Trị",
                "X. Thạch Lạc",
                "X. Thạch Ngọc",
                "X. Tượng Sơn",
                "X. Thạch Văn",
                "X. Lưu Vĩnh Sơn",
                "X. Thạch Thắng",
                "X. Thạch Đài",
                "X. Thạch Hội",
                "X. Tân Lâm Hương",
                "X. Thạch Xuân",
                "X. Nam Điền"
             ],
             "codeErp":"3008"
          },
          {
             "n":"H. Cẩm Xuyên",
             "w":[
                "TT. Cẩm Xuyên",
                "TT. Thiên Cầm",
                "X. Yên Hòa",
                "X. Cẩm Dương",
                "X. Cẩm Bình",
                "X. Cẩm Vĩnh",
                "X. Cẩm Thành",
                "X. Cẩm Quang",
                "X. Cẩm Thạch",
                "X. Cẩm Nhượng",
                "X. Nam Phúc Thăng",
                "X. Cẩm Duệ",
                "X. Cẩm Lĩnh",
                "X. Cẩm Quan",
                "X. Cẩm Hà",
                "X. Cẩm Lộc",
                "X. Cẩm Hưng",
                "X. Cẩm Thịnh",
                "X. Cẩm Mỹ",
                "X. Cẩm Trung",
                "X. Cẩm Sơn",
                "X. Cẩm Lạc",
                "X. Cẩm Minh"
             ],
             "codeErp":"3009"
          },
          {
             "n":"H. Kỳ Anh",
             "w":[
                "X. Kỳ Xuân",
                "X. Kỳ Bắc",
                "X. Kỳ Phú",
                "X. Kỳ Phong",
                "X. Kỳ Tiến",
                "X. Kỳ Giang",
                "X. Kỳ Đồng",
                "X. Kỳ Khang",
                "X. Kỳ Văn",
                "X. Kỳ Trung",
                "X. Kỳ Thọ",
                "X. Kỳ Tây",
                "X. Kỳ Thượng",
                "X. Kỳ Hải",
                "X. Kỳ Thư",
                "X. Kỳ Châu",
                "X. Kỳ Tân",
                "X. Lâm Hợp",
                "X. Kỳ Sơn",
                "X. Kỳ Lạc"
             ],
             "codeErp":"3010"
          },
          {
             "n":"H. Lộc Hà",
             "w":[
                "X. Tân Lộc",
                "X. Hồng Lộc",
                "X. Thịnh Lộc",
                "X. Bình An",
                "X. Ích Hậu",
                "X. Phù Lưu",
                "TT. Lộc Hà",
                "X. Thạch Mỹ",
                "X. Thạch Kim",
                "X. Thạch Châu",
                "X. Hộ Độ",
                "X. Mai Phụ"
             ],
             "codeErp":"3012"
          },
          {
             "n":"TX. Kỳ Anh",
             "w":[
                "P. Hưng Trí",
                "X. Kỳ Ninh",
                "X. Kỳ Lợi",
                "X. Kỳ Hà",
                "P. Kỳ Trinh",
                "P. Kỳ Thịnh",
                "X. Kỳ Hoa",
                "P. Kỳ Phương",
                "P. Kỳ Long",
                "P. Kỳ Liên",
                "X. Kỳ Nam"
             ],
             "codeErp":"3010"
          }
       ],
       "n":"Hà Tĩnh",
       "codeErp":"30"
    },
    {
       "d":[
          {
             "n":"TP. Vinh",
             "w":[
                "P. Đông Vĩnh",
                "P. Hà Huy Tập",
                "P. Lê Lợi",
                "P. Quán Bàu",
                "P. Hưng Bình",
                "P. Hưng Phúc",
                "P. Hưng Dũng",
                "P. Cửa Nam",
                "P. Quang Trung",
                "P. Đội Cung",
                "P. Lê Mao",
                "P. Trường Thi",
                "P. Bến Thủy",
                "P. Hồng Sơn",
                "P. Trung Đô",
                "X. Nghi Phú",
                "X. Hưng Đông",
                "X. Hưng Lộc",
                "X. Hưng Hòa",
                "P. Vinh Tân",
                "X. Nghi Liên",
                "X. Nghi Ân",
                "X. Nghi Kim",
                "X. Nghi Đức",
                "X. Hưng Chính"
             ],
             "codeErp":"2901"
          },
          {
             "n":"TX. Cửa Lò",
             "w":[
                "P. Nghi Thuỷ",
                "P. Nghi Tân",
                "P. Thu Thuỷ",
                "P. Nghi Hòa",
                "P. Nghi Hải",
                "P. Nghi Hương",
                "P. Nghi Thu"
             ],
             "codeErp":"2902"
          },
          {
             "n":"TX. Thái Hoà",
             "w":[
                "P. Hoà Hiếu",
                "P. Quang Phong",
                "P. Quang Tiến",
                "P. Long Sơn",
                "X. Nghĩa Tiến",
                "X. Nghĩa Mỹ",
                "X. Tây Hiếu",
                "X. Nghĩa Thuận",
                "X. Đông Hiếu"
             ],
             "codeErp":"2920"
          },
          {
             "n":"H. Quế Phong",
             "w":[
                "TT. Kim Sơn",
                "X. Thông Thụ",
                "X. Đồng Văn",
                "X. Hạnh Dịch",
                "X. Tiền Phong",
                "X. Nậm Giải",
                "X. Tri Lễ",
                "X. Châu Kim",
                "X. Mường Nọc",
                "X. Châu Thôn",
                "X. Nậm Nhoóng",
                "X. Quang Phong",
                "X. Căm Muộn"
             ],
             "codeErp":"2919"
          },
          {
             "n":"H. Quỳ Châu",
             "w":[
                "TT. Tân Lạc",
                "X. Châu Bính",
                "X. Châu Thuận",
                "X. Châu Hội",
                "X. Châu Nga",
                "X. Châu Tiến",
                "X. Châu Hạnh",
                "X. Châu Thắng",
                "X. Châu Phong",
                "X. Châu Bình",
                "X. Châu Hoàn",
                "X. Diên Lãm"
             ],
             "codeErp":"2903"
          },
          {
             "n":"H. Kỳ Sơn",
             "w":[
                "TT. Mường Xén",
                "X. Mỹ Lý",
                "X. Bắc Lý",
                "X. Keng Đu",
                "X. Đoọc Mạy",
                "X. Huồi Tụ",
                "X. Mường Lống",
                "X. Na Loi",
                "X. Nậm Cắn",
                "X. Bảo Nam",
                "X. Phà Đánh",
                "X. Bảo Thắng",
                "X. Hữu Lập",
                "X. Tà Cạ",
                "X. Chiêu Lưu",
                "X. Mường Típ",
                "X. Hữu Kiệm",
                "X. Tây Sơn",
                "X. Mường Ải",
                "X. Na Ngoi",
                "X. Nậm Càn"
             ],
             "codeErp":"2907"
          },
          {
             "n":"H. Tương Dương",
             "w":[
                "TT. Thạch Giám",
                "X. Mai Sơn",
                "X. Nhôn Mai",
                "X. Hữu Khuông",
                "X. Yên Tĩnh",
                "X. Nga My",
                "X. Xiêng My",
                "X. Lưỡng Minh",
                "X. Yên Hòa",
                "X. Yên Na",
                "X. Lưu Kiền",
                "X. Xá Lượng",
                "X. Tam Thái",
                "X. Tam Đình",
                "X. Yên Thắng",
                "X. Tam Quang",
                "X. Tam Hợp"
             ],
             "codeErp":"2908"
          },
          {
             "n":"H. Nghĩa Đàn",
             "w":[
                "TT. Nghĩa Đàn",
                "X. Nghĩa Mai",
                "X. Nghĩa Yên",
                "X. Nghĩa Lạc",
                "X. Nghĩa Lâm",
                "X. Nghĩa Sơn",
                "X. Nghĩa Lợi",
                "X. Nghĩa Bình",
                "X. Nghĩa Thọ",
                "X. Nghĩa Minh",
                "X. Nghĩa Phú",
                "X. Nghĩa Hưng",
                "X. Nghĩa Hồng",
                "X. Nghĩa Thịnh",
                "X. Nghĩa Trung",
                "X. Nghĩa Hội",
                "X. Nghĩa Thành",
                "X. Nghĩa Hiếu",
                "X. Nghĩa Đức",
                "X. Nghĩa An",
                "X. Nghĩa Long",
                "X. Nghĩa Lộc",
                "X. Nghĩa Khánh"
             ],
             "codeErp":"2905"
          },
          {
             "n":"H. Quỳ Hợp",
             "w":[
                "TT. Quỳ Hợp",
                "X. Yên Hợp",
                "X. Châu Tiến",
                "X. Châu Hồng",
                "X. Đồng Hợp",
                "X. Châu Thành",
                "X. Liên Hợp",
                "X. Châu Lộc",
                "X. Tam Hợp",
                "X. Châu Cường",
                "X. Châu Quang",
                "X. Thọ Hợp",
                "X. Minh Hợp",
                "X. Nghĩa Xuân",
                "X. Châu Thái",
                "X. Châu Đình",
                "X. Văn Lợi",
                "X. Nam Sơn",
                "X. Châu Lý",
                "X. Hạ Sơn",
                "X. Bắc Sơn"
             ],
             "codeErp":"2904"
          },
          {
             "n":"H. Quỳnh Lưu",
             "w":[
                "TT. Cầu Giát",
                "X. Quỳnh Thắng",
                "X. Quỳnh Tân",
                "X. Quỳnh Châu",
                "X. Tân Sơn",
                "X. Quỳnh Văn",
                "X. Ngọc Sơn",
                "X. Quỳnh Tam",
                "X. Quỳnh Hoa",
                "X. Quỳnh Thạch",
                "X. Quỳnh Bảng",
                "X. Quỳnh Mỹ",
                "X. Quỳnh Thanh",
                "X. Quỳnh Hậu",
                "X. Quỳnh Lâm",
                "X. Quỳnh Đôi",
                "X. Quỳnh Lương",
                "X. Quỳnh Hồng",
                "X. Quỳnh Yên",
                "X. Quỳnh Bá",
                "X. Quỳnh Minh",
                "X. Quỳnh Diễn",
                "X. Quỳnh Hưng",
                "X. Quỳnh Giang",
                "X. Quỳnh Ngọc",
                "X. Quỳnh Nghĩa",
                "X. An Hòa",
                "X. Tiến Thủy",
                "X. Sơn Hải",
                "X. Quỳnh Thọ",
                "X. Quỳnh Thuận",
                "X. Quỳnh Long",
                "X. Tân Thắng"
             ],
             "codeErp":"2906"
          },
          {
             "n":"H. Con Cuông",
             "w":[
                "TT. Con Cuông",
                "X. Bình Chuẩn",
                "X. Lạng Khê",
                "X. Cam Lâm",
                "X. Thạch Ngàn",
                "X. Đôn Phục",
                "X. Mậu Đức",
                "X. Châu Khê",
                "X. Chi Khê",
                "X. Bồng Khê",
                "X. Yên Khê",
                "X. Lục Dạ",
                "X. Môn Sơn"
             ],
             "codeErp":"2909"
          },
          {
             "n":"H. Tân Kỳ",
             "w":[
                "TT. Tân Kỳ",
                "X. Tân Hợp",
                "X. Tân Phú",
                "X. Tân Xuân",
                "X. Giai Xuân",
                "X. Nghĩa Bình",
                "X. Nghĩa Đồng",
                "X. Đồng Văn",
                "X. Nghĩa Thái",
                "X. Nghĩa Hợp",
                "X. Nghĩa Hoàn",
                "X. Nghĩa Phúc",
                "X. Tiên Kỳ",
                "X. Tân An",
                "X. Nghĩa Dũng",
                "X. Tân Long",
                "X. Kỳ Sơn",
                "X. Hương Sơn",
                "X. Kỳ Tân",
                "X. Phú Sơn",
                "X. Tân Hương",
                "X. Nghĩa Hành"
             ],
             "codeErp":"2910"
          },
          {
             "n":"H. Anh Sơn",
             "w":[
                "TT. Anh Sơn",
                "X. Thọ Sơn",
                "X. Thành Sơn",
                "X. Bình Sơn",
                "X. Tam Sơn",
                "X. Đỉnh Sơn",
                "X. Hùng Sơn",
                "X. Cẩm Sơn",
                "X. Đức Sơn",
                "X. Tường Sơn",
                "X. Hoa Sơn",
                "X. Tào Sơn",
                "X. Vĩnh Sơn",
                "X. Lạng Sơn",
                "X. Hội Sơn",
                "X. Thạch Sơn",
                "X. Phúc Sơn",
                "X. Long Sơn",
                "X. Khai Sơn",
                "X. Lĩnh Sơn",
                "X. Cao Sơn"
             ],
             "codeErp":"2913"
          },
          {
             "n":"H. Diễn Châu",
             "w":[
                "TT. Diễn Châu",
                "X. Diễn Lâm",
                "X. Diễn Đoài",
                "X. Diễn Trường",
                "X. Diễn Yên",
                "X. Diễn Hoàng",
                "X. Diễn Hùng",
                "X. Diễn Mỹ",
                "X. Diễn Hồng",
                "X. Diễn Phong",
                "X. Diễn Hải",
                "X. Diễn Tháp",
                "X. Diễn Liên",
                "X. Diễn Vạn",
                "X. Diễn Kim",
                "X. Diễn Kỷ",
                "X. Diễn Xuân",
                "X. Diễn Thái",
                "X. Diễn Đồng",
                "X. Diễn Bích",
                "X. Diễn Hạnh",
                "X. Diễn Ngọc",
                "X. Diễn Quảng",
                "X. Diễn Nguyên",
                "X. Diễn Hoa",
                "X. Diễn Thành",
                "X. Diễn Phúc",
                "X. Diễn Cát",
                "X. Diễn Thịnh",
                "X. Diễn Tân",
                "X. Minh Châu",
                "X. Diễn Thọ",
                "X. Diễn Lợi",
                "X. Diễn Lộc",
                "X. Diễn Trung",
                "X. Diễn An",
                "X. Diễn Phú"
             ],
             "codeErp":"2912"
          },
          {
             "n":"H. Yên Thành",
             "w":[
                "TT. Yên Thành",
                "X. Mã Thành",
                "X. Tiến Thành",
                "X. Lăng Thành",
                "X. Tân Thành",
                "X. Đức Thành",
                "X. Kim Thành",
                "X. Hậu Thành",
                "X. Hùng Thành",
                "X. Đô Thành",
                "X. Thọ Thành",
                "X. Quang Thành",
                "X. Tây Thành",
                "X. Phúc Thành",
                "X. Hồng Thành",
                "X. Đồng Thành",
                "X. Phú Thành",
                "X. Hoa Thành",
                "X. Tăng Thành",
                "X. Văn Thành",
                "X. Thịnh Thành",
                "X. Hợp Thành",
                "X. Xuân Thành",
                "X. Bắc Thành",
                "X. Nhân Thành",
                "X. Trung Thành",
                "X. Long Thành",
                "X. Minh Thành",
                "X. Nam Thành",
                "X. Vĩnh Thành",
                "X. Lý Thành",
                "X. Khánh Thành",
                "X. Viên Thành",
                "X. Đại Thành",
                "X. Liên Thành",
                "X. Bảo Thành",
                "X. Mỹ Thành",
                "X. Công Thành",
                "X. Sơn Thành"
             ],
             "codeErp":"2911"
          },
          {
             "n":"H. Đô Lương",
             "w":[
                "TT. Đô Lương",
                "X. Giang Sơn Đông",
                "X. Giang Sơn Tây",
                "X. Lam Sơn",
                "X. Bồi Sơn",
                "X. Hồng Sơn",
                "X. Bài Sơn",
                "X. Ngọc Sơn",
                "X. Bắc Sơn",
                "X. Tràng Sơn",
                "X. Thượng Sơn",
                "X. Hòa Sơn",
                "X. Đặng Sơn",
                "X. Đông Sơn",
                "X. Nam Sơn",
                "X. Lưu Sơn",
                "X. Yên Sơn",
                "X. Văn Sơn",
                "X. Đà Sơn",
                "X. Lạc Sơn",
                "X. Tân Sơn",
                "X. Thái Sơn",
                "X. Quang Sơn",
                "X. Thịnh Sơn",
                "X. Trung Sơn",
                "X. Xuân Sơn",
                "X. Minh Sơn",
                "X. Thuận Sơn",
                "X. Nhân Sơn",
                "X. Hiến Sơn",
                "X. Mỹ Sơn",
                "X. Trù Sơn",
                "X. Đại Sơn"
             ],
             "codeErp":"2914"
          },
          {
             "n":"H. Thanh Chương",
             "w":[
                "TT. Thanh Chương",
                "X. Cát Văn",
                "X. Thanh Nho",
                "X. Hạnh Lâm",
                "X. Thanh Sơn",
                "X. Thanh Hòa",
                "X. Phong Thịnh",
                "X. Thanh Phong",
                "X. Thanh Mỹ",
                "X. Thanh Tiên",
                "X. Thanh Liên",
                "X. Đại Đồng",
                "X. Thanh Đồng",
                "X. Thanh Ngọc",
                "X. Thanh Hương",
                "X. Ngọc Lâm",
                "X. Thanh Lĩnh",
                "X. Đồng Văn",
                "X. Ngọc Sơn",
                "X. Thanh Thịnh",
                "X. Thanh An",
                "X. Thanh Chi",
                "X. Xuân Tường",
                "X. Thanh Dương",
                "X. Thanh Lương",
                "X. Thanh Khê",
                "X. Võ Liệt",
                "X. Thanh Long",
                "X. Thanh Thủy",
                "X. Thanh Khai",
                "X. Thanh Yên",
                "X. Thanh Hà",
                "X. Thanh Giang",
                "X. Thanh Tùng",
                "X. Thanh Lâm",
                "X. Thanh Mai",
                "X. Thanh Xuân",
                "X. Thanh Đức"
             ],
             "codeErp":"2915"
          },
          {
             "n":"H. Nghi Lộc",
             "w":[
                "TT. Quán Hành",
                "X. Nghi Văn",
                "X. Nghi Yên",
                "X. Nghi Tiến",
                "X. Nghi Hưng",
                "X. Nghi Đồng",
                "X. Nghi Thiết",
                "X. Nghi Lâm",
                "X. Nghi Quang",
                "X. Nghi Kiều",
                "X. Nghi Mỹ",
                "X. Nghi Phương",
                "X. Nghi Thuận",
                "X. Nghi Long",
                "X. Nghi Xá",
                "X. Nghi Hoa",
                "X. Khánh Hợp",
                "X. Nghi Thịnh",
                "X. Nghi Công Bắc",
                "X. Nghi Công Nam",
                "X. Nghi Thạch",
                "X. Nghi Trung",
                "X. Nghi Trường",
                "X. Nghi Diên",
                "X. Nghi Phong",
                "X. Nghi Xuân",
                "X. Nghi Vạn",
                "X. Phúc Thọ",
                "X. Nghi Thái"
             ],
             "codeErp":"2916"
          },
          {
             "n":"H. Nam Đàn",
             "w":[
                "X. Nam Hưng",
                "X. Nam Nghĩa",
                "X. Nam Thanh",
                "X. Nam Anh",
                "X. Nam Xuân",
                "X. Nam Thái",
                "TT. Nam Đàn",
                "X. Nam Lĩnh",
                "X. Nam Giang",
                "X. Xuân Hòa",
                "X. Hùng Tiến",
                "X. Thượng Tân Lộc",
                "X. Kim Liên",
                "X. Hồng Long",
                "X. Xuân Lâm",
                "X. Nam Cát",
                "X. Khánh Sơn",
                "X. Trung Phúc Cường",
                "X. Nam Kim"
             ],
             "codeErp":"2917"
          },
          {
             "n":"H. Hưng Nguyên",
             "w":[
                "TT. Hưng Nguyên",
                "X. Hưng Trung",
                "X. Hưng Yên",
                "X. Hưng Yên Bắc",
                "X. Hưng Tây",
                "X. Hưng Đạo",
                "X. Hưng Mỹ",
                "X. Hưng Thịnh",
                "X. Hưng Lĩnh",
                "X. Hưng Thông",
                "X. Hưng Tân",
                "X. Hưng Lợi",
                "X. Hưng Nghĩa",
                "X. Hưng Phúc",
                "X. Long Xá",
                "X. Châu Nhân",
                "X. Xuân Lam",
                "X. Hưng Thành"
             ],
             "codeErp":"2918"
          },
          {
             "n":"TX. Hoàng Mai",
             "w":[
                "X. Quỳnh Vinh",
                "X. Quỳnh Lộc",
                "P. Quỳnh Thiện",
                "X. Quỳnh Lập",
                "X. Quỳnh Trang",
                "P. Mai Hùng",
                "P. Quỳnh Dị",
                "P. Quỳnh Xuân",
                "P. Quỳnh Phương",
                "X. Quỳnh Liên"
             ],
             "codeErp":"2921"
          }
       ],
       "n":"Nghệ An",
       "codeErp":"29"
    },
    {
       "d":[
          {
             "n":"TP. Ninh Bình",
             "w":[
                "P. Đông Thành",
                "P. Tân Thành",
                "P. Thanh Bình",
                "P. Vân Giang",
                "P. Bích Đào",
                "P. Phúc Thành",
                "P. Nam Bình",
                "P. Nam Thành",
                "P. Ninh Khánh",
                "X. Ninh Nhất",
                "X. Ninh Tiến",
                "X. Ninh Phúc",
                "P. Ninh Sơn",
                "P. Ninh Phong"
             ],
             "codeErp":"2701"
          },
          {
             "n":"TP. Tam Điệp",
             "w":[
                "P. Bắc Sơn",
                "P. Trung Sơn",
                "P. Nam Sơn",
                "P. Tây Sơn",
                "X. Yên Sơn",
                "P. Yên Bình",
                "P. Tân Bình",
                "X. Quang Sơn",
                "X. Đông Sơn"
             ],
             "codeErp":"2702"
          },
          {
             "n":"H. Nho Quan",
             "w":[
                "TT. Nho Quan",
                "X. Xích Thổ",
                "X. Gia Lâm",
                "X. Gia Sơn",
                "X. Thạch Bình",
                "X. Gia Thủy",
                "X. Gia Tường",
                "X. Cúc Phương",
                "X. Phú Sơn",
                "X. Đức Long",
                "X. Lạc Vân",
                "X. Đồng Phong",
                "X. Yên Quang",
                "X. Lạng Phong",
                "X. Thượng Hòa",
                "X. Văn Phong",
                "X. Văn Phương",
                "X. Thanh Lạc",
                "X. Sơn Lai",
                "X. Sơn Thành",
                "X. Văn Phú",
                "X. Phú Lộc",
                "X. Kỳ Phú",
                "X. Quỳnh Lưu",
                "X. Sơn Hà",
                "X. Phú Long",
                "X. Quảng Lạc"
             ],
             "codeErp":"2703"
          },
          {
             "n":"H. Gia Viễn",
             "w":[
                "TT. Me",
                "X. Gia Hòa",
                "X. Gia Hưng",
                "X. Liên Sơn",
                "X. Gia Thanh",
                "X. Gia Vân",
                "X. Gia Phú",
                "X. Gia Xuân",
                "X. Gia Lập",
                "X. Gia Vượng",
                "X. Gia Trấn",
                "X. Gia Thịnh",
                "X. Gia Phương",
                "X. Gia Tân",
                "X. Gia Thắng",
                "X. Gia Trung",
                "X. Gia Minh",
                "X. Gia Lạc",
                "X. Gia Tiến",
                "X. Gia Sinh",
                "X. Gia Phong"
             ],
             "codeErp":"2704"
          },
          {
             "n":"H. Hoa Lư",
             "w":[
                "TT. Thiên Tôn",
                "X. Ninh Giang",
                "X. Trường Yên",
                "X. Ninh Khang",
                "X. Ninh Mỹ",
                "X. Ninh Hòa",
                "X. Ninh Xuân",
                "X. Ninh Hải",
                "X. Ninh Thắng",
                "X. Ninh Vân",
                "X. Ninh An"
             ],
             "codeErp":"2705"
          },
          {
             "n":"H. Yên Khánh",
             "w":[
                "TT. Yên Ninh",
                "X. Khánh Tiên",
                "X. Khánh Phú",
                "X. Khánh Hòa",
                "X. Khánh Lợi",
                "X. Khánh An",
                "X. Khánh Cường",
                "X. Khánh Cư",
                "X. Khánh Thiện",
                "X. Khánh Hải",
                "X. Khánh Trung",
                "X. Khánh Mậu",
                "X. Khánh Vân",
                "X. Khánh Hội",
                "X. Khánh Công",
                "X. Khánh Thành",
                "X. Khánh Nhạc",
                "X. Khánh Thủy",
                "X. Khánh Hồng"
             ],
             "codeErp":"2708"
          },
          {
             "n":"H. Kim Sơn",
             "w":[
                "TT. Phát Diệm",
                "TT. Bình Minh",
                "X. Hồi Ninh",
                "X. Xuân Chính",
                "X. Kim Định",
                "X. Ân Hòa",
                "X. Hùng Tiến",
                "X. Quang Thiện",
                "X. Như Hòa",
                "X. Chất Bình",
                "X. Đồng Hướng",
                "X. Kim Chính",
                "X. Thượng Kiệm",
                "X. Lưu Phương",
                "X. Tân Thành",
                "X. Yên Lộc",
                "X. Lai Thành",
                "X. Định Hóa",
                "X. Văn Hải",
                "X. Kim Tân",
                "X. Kim Mỹ",
                "X. Cồn Thoi",
                "X. Kim Hải",
                "X. Kim Trung",
                "X. Kim Đông"
             ],
             "codeErp":"2707"
          },
          {
             "n":"H. Yên Mô",
             "w":[
                "TT. Yên Thịnh",
                "X. Khánh Thượng",
                "X. Khánh Dương",
                "X. Mai Sơn",
                "X. Khánh Thịnh",
                "X. Yên Phong",
                "X. Yên Hòa",
                "X. Yên Thắng",
                "X. Yên Từ",
                "X. Yên Hưng",
                "X. Yên Thành",
                "X. Yên Nhân",
                "X. Yên Mỹ",
                "X. Yên Mạc",
                "X. Yên Đồng",
                "X. Yên Thái",
                "X. Yên Lâm"
             ],
             "codeErp":"2706"
          }
       ],
       "n":"Ninh Bình",
       "codeErp":"27"
    },
    {
       "d":[
          {
             "n":"TP. Tam Kỳ",
             "w":[
                "P. Tân Thạnh",
                "P. Phước Hòa",
                "P. An Mỹ",
                "P. Hòa Hương",
                "P. An Xuân",
                "P. An Sơn",
                "P. Trường Xuân",
                "P. An Phú",
                "X. Tam Thanh",
                "X. Tam Thăng",
                "X. Tam Phú",
                "P. Hoà Thuận",
                "X. Tam Ngọc"
             ],
             "codeErp":"3401"
          },
          {
             "n":"TP. Hội An",
             "w":[
                "P. Minh An",
                "P. Tân An",
                "P. Cẩm Phô",
                "P. Thanh Hà",
                "P. Sơn Phong",
                "P. Cẩm Châu",
                "P. Cửa Đại",
                "P. Cẩm An",
                "X. Cẩm Hà",
                "X. Cẩm Kim",
                "P. Cẩm Nam",
                "X. Cẩm Thanh",
                "X. Tân Hiệp"
             ],
             "codeErp":"3402"
          },
          {
             "n":"H. Tây Giang",
             "w":[
                "X. Ch'ơm",
                "X. Ga Ri",
                "X. A Xan",
                "X. Tr'Hy",
                "X. Lăng",
                "X. A Nông",
                "X. A Tiêng",
                "X. Bha Lê",
                "X. A Vương",
                "X. Dang"
             ],
             "codeErp":"3416"
          },
          {
             "n":"H. Đông Giang",
             "w":[
                "TT. P Rao",
                "X. Tà Lu",
                "X. Sông Kôn",
                "X. Jơ Ngây",
                "X. A Ting",
                "X.  Tư",
                "X. Ba",
                "X. A Rooi",
                "X. Za Hung",
                "X. Mà Cooi",
                "X. Ka Dăng"
             ],
             "codeErp":"3412"
          },
          {
             "n":"H. Đại Lộc",
             "w":[
                "TT. Ái Nghĩa",
                "X. Đại Sơn",
                "X. Đại Lãnh",
                "X. Đại Hưng",
                "X. Đại Hồng",
                "X. Đại Đồng",
                "X. Đại Quang",
                "X. Đại Nghĩa",
                "X. Đại Hiệp",
                "X. Đại Thạnh",
                "X. Đại Chánh",
                "X. Đại Tân",
                "X. Đại Phong",
                "X. Đại Minh",
                "X. Đại Thắng",
                "X. Đại Cường",
                "X. Đại An",
                "X. Đại Hòa"
             ],
             "codeErp":"3405"
          },
          {
             "n":"TX. Điện Bàn",
             "w":[
                "P. Vĩnh Điện",
                "X. Điện Tiến",
                "X. Điện Hòa",
                "X. Điện Thắng Bắc",
                "X. Điện Thắng Trung",
                "X. Điện Thắng Nam",
                "P. Điện Ngọc",
                "X. Điện Hồng",
                "X. Điện Thọ",
                "X. Điện Phước",
                "P. Điện An",
                "P. Điện Nam Bắc",
                "P. Điện Nam Trung",
                "P. Điện Nam Đông",
                "P. Điện Dương",
                "X. Điện Quang",
                "X. Điện Trung",
                "X. Điện Phong",
                "X. Điện Minh",
                "X. Điện Phương"
             ],
             "codeErp":"3404"
          },
          {
             "n":"H. Duy Xuyên",
             "w":[
                "TT. Nam Phước",
                "X. Duy Thu",
                "X. Duy Phú",
                "X. Duy Tân",
                "X. Duy Hòa",
                "X. Duy Châu",
                "X. Duy Trinh",
                "X. Duy Sơn",
                "X. Duy Trung",
                "X. Duy Phước",
                "X. Duy Thành",
                "X. Duy Vinh",
                "X. Duy Nghĩa",
                "X. Duy Hải"
             ],
             "codeErp":"3403"
          },
          {
             "n":"H. Quế Sơn",
             "w":[
                "TT. Đông Phú",
                "X. Quế Xuân 1",
                "X. Quế Xuân 2",
                "X. Quế Phú",
                "TT. Hương An",
                "X. Quế Hiệp",
                "X. Quế Thuận",
                "X. Quế Mỹ",
                "X. Quế Long",
                "X. Quế Châu",
                "X. Quế Phong",
                "X. Quế An",
                "X. Quế Minh"
             ],
             "codeErp":"3406"
          },
          {
             "n":"H. Nam Giang",
             "w":[
                "TT. Thạnh Mỹ",
                "X. Laêê",
                "X. Chơ Chun",
                "X. Zuôich",
                "X. Tà Pơơ",
                "X. La Dêê",
                "X. Đắc Tôi",
                "X. Chà Vàl",
                "X. Tà Bhinh",
                "X. Cà Dy",
                "X. Đắc Pre",
                "X. Đắc Pring"
             ],
             "codeErp":"3413"
          },
          {
             "n":"H. Phước Sơn",
             "w":[
                "TT. Khâm Đức",
                "X. Phước Xuân",
                "X. Phước Hiệp",
                "X. Phước Hoà",
                "X. Phước Đức",
                "X. Phước Năng",
                "X. Phước Mỹ",
                "X. Phước Chánh",
                "X. Phước Công",
                "X. Phước Kim",
                "X. Phước Lộc",
                "X. Phước Thành"
             ],
             "codeErp":"3414"
          },
          {
             "n":"H. Hiệp Đức",
             "w":[
                "X. Hiệp Hòa",
                "X. Hiệp Thuận",
                "X. Quế Thọ",
                "X. Bình Lâm",
                "X. Sông Trà",
                "X. Phước Trà",
                "X. Phước Gia",
                "TT. Tân Bình",
                "X. Quế Lưu",
                "X. Thăng Phước",
                "X. Bình Sơn"
             ],
             "codeErp":"3407"
          },
          {
             "n":"H. Thăng Bình",
             "w":[
                "TT. Hà Lam",
                "X. Bình Dương",
                "X. Bình Giang",
                "X. Bình Nguyên",
                "X. Bình Phục",
                "X. Bình Triều",
                "X. Bình Đào",
                "X. Bình Minh",
                "X. Bình Lãnh",
                "X. Bình Trị",
                "X. Bình Định Bắc",
                "X. Bình Định Nam",
                "X. Bình Quý",
                "X. Bình Phú",
                "X. Bình Chánh",
                "X. Bình Tú",
                "X. Bình Sa",
                "X. Bình Hải",
                "X. Bình Quế",
                "X. Bình An",
                "X. Bình Trung",
                "X. Bình Nam"
             ],
             "codeErp":"3408"
          },
          {
             "n":"H. Tiên Phước",
             "w":[
                "TT. Tiên Kỳ",
                "X. Tiên Sơn",
                "X. Tiên Hà",
                "X. Tiên Cẩm",
                "X. Tiên Châu",
                "X. Tiên Lãnh",
                "X. Tiên Ngọc",
                "X. Tiên Hiệp",
                "X. Tiên Cảnh",
                "X. Tiên Mỹ",
                "X. Tiên Phong",
                "X. Tiên Thọ",
                "X. Tiên An",
                "X. Tiên Lộc",
                "X. Tiên Lập"
             ],
             "codeErp":"3410"
          },
          {
             "n":"H. Bắc Trà My",
             "w":[
                "TT. Trà My",
                "X. Trà Sơn",
                "X. Trà Kót",
                "X. Trà Nú",
                "X. Trà Đông",
                "X. Trà Dương",
                "X. Trà Giang",
                "X. Trà Bui",
                "X. Trà Đốc",
                "X. Trà Tân",
                "X. Trà Giác",
                "X. Trà Giáp",
                "X. Trà Ka"
             ],
             "codeErp":"3411"
          },
          {
             "n":"H. Nam Trà My",
             "w":[
                "X. Trà Leng",
                "X. Trà Dơn",
                "X. Trà Tập",
                "X. Trà Mai",
                "X. Trà Cang",
                "X. Trà Linh",
                "X. Trà Nam",
                "X. Trà Don",
                "X. Trà Vân",
                "X. Trà Vinh"
             ],
             "codeErp":"3415"
          },
          {
             "n":"H. Núi Thành",
             "w":[
                "TT. Núi Thành",
                "X. Tam Xuân I",
                "X. Tam Xuân II",
                "X. Tam Tiến",
                "X. Tam Sơn",
                "X. Tam Thạnh",
                "X. Tam Anh Bắc",
                "X. Tam Anh Nam",
                "X. Tam Hòa",
                "X. Tam Hiệp",
                "X. Tam Hải",
                "X. Tam Giang",
                "X. Tam Quang",
                "X. Tam Nghĩa",
                "X. Tam Mỹ Tây",
                "X. Tam Mỹ Đông",
                "X. Tam Trà"
             ],
             "codeErp":"3409"
          },
          {
             "n":"H. Phú Ninh",
             "w":[
                "TT. Phú Thịnh",
                "X. Tam Thành",
                "X. Tam An",
                "X. Tam Đàn",
                "X. Tam Lộc",
                "X. Tam Phước",
                "X. Tam Vinh",
                "X. Tam Thái",
                "X. Tam Đại",
                "X. Tam Dân",
                "X. Tam Lãnh"
             ],
             "codeErp":"3417"
          },
          {
             "n":"H. Nông Sơn",
             "w":[
                "X. Quế Trung",
                "X. Ninh Phước",
                "X. Phước Ninh",
                "X. Quế Lộc",
                "X. Sơn Viên",
                "X. Quế Lâm"
             ],
             "codeErp":"3418"
          }
       ],
       "n":"Quảng Nam",
       "codeErp":"34"
    },
    {
       "d":[
          {
             "n":"TP. Đồng Hới",
             "w":[
                "P. Hải Thành",
                "P. Đồng Phú",
                "P. Bắc Lý",
                "P. Nam Lý",
                "P. Đồng Hải",
                "P. Đồng Sơn",
                "P. Phú Hải",
                "P. Bắc Nghĩa",
                "P. Đức Ninh Đông",
                "X. Quang Phú",
                "X. Lộc Ninh",
                "X. Bảo Ninh",
                "X. Nghĩa Ninh",
                "X. Thuận Đức",
                "X. Đức Ninh"
             ],
             "codeErp":"3101"
          },
          {
             "n":"H. Minh Hóa",
             "w":[
                "TT. Quy Đạt",
                "X. Dân Hóa",
                "X. Trọng Hóa",
                "X. Hóa Phúc",
                "X. Hồng Hóa",
                "X. Hóa Thanh",
                "X. Hóa Tiến",
                "X. Hóa Hợp",
                "X. Xuân Hóa",
                "X. Yên Hóa",
                "X. Minh Hóa",
                "X. Tân Hóa",
                "X. Hóa Sơn",
                "X. Trung Hóa",
                "X. Thượng Hóa"
             ],
             "codeErp":"3103"
          },
          {
             "n":"H. Tuyên Hóa",
             "w":[
                "TT. Đồng Lê",
                "X. Hương Hóa",
                "X. Kim Hóa",
                "X. Thanh Hóa",
                "X. Thanh Thạch",
                "X. Thuận Hóa",
                "X. Lâm Hóa",
                "X. Lê Hóa",
                "X. Sơn Hóa",
                "X. Đồng Hóa",
                "X. Ngư Hóa",
                "X. Thạch Hóa",
                "X. Đức Hóa",
                "X. Phong Hóa",
                "X. Mai Hóa",
                "X. Tiến Hóa",
                "X. Châu Hóa",
                "X. Cao Quảng",
                "X. Văn Hóa"
             ],
             "codeErp":"3102"
          },
          {
             "n":"H. Quảng Trạch",
             "w":[
                "X. Quảng Hợp",
                "X. Quảng Kim",
                "X. Quảng Đông",
                "X. Quảng Phú",
                "X. Quảng Châu",
                "X. Quảng Thạch",
                "X. Quảng Lưu",
                "X. Quảng Tùng",
                "X. Cảnh Dương",
                "X. Quảng Tiến",
                "X. Quảng Hưng",
                "X. Quảng Xuân",
                "X. Cảnh Hóa",
                "X. Liên Trường",
                "X. Quảng Phương",
                "X. Phù Hóa",
                "X. Quảng Thanh"
             ],
             "codeErp":"3104"
          },
          {
             "n":"H. Bố Trạch",
             "w":[
                "TT. Hoàn Lão",
                "TT. NT Việt Trung",
                "X. Xuân Trạch",
                "X. Mỹ Trạch",
                "X. Hạ Trạch",
                "X. Bắc Trạch",
                "X. Lâm Trạch",
                "X. Thanh Trạch",
                "X. Liên Trạch",
                "X. Phúc Trạch",
                "X. Cự Nẫm",
                "X. Hải Phú",
                "X. Thượng Trạch",
                "X. Sơn Lộc",
                "X. Hưng Trạch",
                "X. Đồng Trạch",
                "X. Đức Trạch",
                "TT. Phong Nha",
                "X. Vạn Trạch",
                "X. Phú Định",
                "X. Trung Trạch",
                "X. Tây Trạch",
                "X. Hòa Trạch",
                "X. Đại Trạch",
                "X. Nhân Trạch",
                "X. Tân Trạch",
                "X. Nam Trạch",
                "X. Lý Trạch"
             ],
             "codeErp":"3105"
          },
          {
             "n":"H. Quảng Ninh",
             "w":[
                "TT. Quán Hàu",
                "X. Trường Sơn",
                "X. Lương Ninh",
                "X. Vĩnh Ninh",
                "X. Võ Ninh",
                "X. Hải Ninh",
                "X. Hàm Ninh",
                "X. Duy Ninh",
                "X. Gia Ninh",
                "X. Trường Xuân",
                "X. Hiền Ninh",
                "X. Tân Ninh",
                "X. Xuân Ninh",
                "X. An Ninh",
                "X. Vạn Ninh"
             ],
             "codeErp":"3106"
          },
          {
             "n":"H. Lệ Thủy",
             "w":[
                "TT. NT Lệ Ninh",
                "TT. Kiến Giang",
                "X. Hồng Thủy",
                "X. Ngư Thủy Bắc",
                "X. Hoa Thủy",
                "X. Thanh Thủy",
                "X. An Thủy",
                "X. Phong Thủy",
                "X. Cam Thủy",
                "X. Ngân Thủy",
                "X. Sơn Thủy",
                "X. Lộc Thủy",
                "X. Liên Thủy",
                "X. Hưng Thủy",
                "X. Dương Thủy",
                "X. Tân Thủy",
                "X. Phú Thủy",
                "X. Xuân Thủy",
                "X. Mỹ Thủy",
                "X. Ngư Thủy ",
                "X. Mai Thủy",
                "X. Sen Thủy",
                "X. Thái Thủy",
                "X. Kim Thủy",
                "X. Trường Thủy",
                "X. Lâm Thủy"
             ],
             "codeErp":"3107"
          },
          {
             "n":"TX. Ba Đồn",
             "w":[
                "P. Ba Đồn",
                "P. Quảng Long",
                "P. Quảng Thọ",
                "X. Quảng Tiên",
                "X. Quảng Trung",
                "P. Quảng Phong",
                "P. Quảng Thuận",
                "X. Quảng Tân",
                "X. Quảng Hải",
                "X. Quảng Sơn",
                "X. Quảng Lộc",
                "X. Quảng Thủy",
                "X. Quảng Văn",
                "P. Quảng Phúc",
                "X. Quảng Hòa",
                "X. Quảng Minh"
             ],
             "codeErp":"3108"
          }
       ],
       "n":"Quảng Bình",
       "codeErp":"31"
    },
    {
       "d":[
          {
             "n":"TP. Nha Trang",
             "w":[
                "P. Vĩnh Hòa",
                "P. Vĩnh Hải",
                "P. Vĩnh Phước",
                "P. Ngọc Hiệp",
                "P. Vĩnh Thọ",
                "P. Xương Huân",
                "P. Vạn Thắng",
                "P. Vạn Thạnh",
                "P. Phương Sài",
                "P. Phương Sơn",
                "P. Phước Hải",
                "P. Phước Tân",
                "P. Lộc Thọ",
                "P. Phước Tiến",
                "P. Tân Lập",
                "P. Phước Hòa",
                "P. Vĩnh Nguyên",
                "P. Phước Long",
                "P. Vĩnh Trường",
                "X. Vĩnh Lương",
                "X. Vĩnh Phương",
                "X. Vĩnh Ngọc",
                "X. Vĩnh Thạnh",
                "X. Vĩnh Trung",
                "X. Vĩnh Hiệp",
                "X. Vĩnh Thái",
                "X. Phước Đồng"
             ],
             "codeErp":"4101"
          },
          {
             "n":"TP. Cam Ranh",
             "w":[
                "P. Cam Nghĩa",
                "P. Cam Phúc Bắc",
                "P. Cam Phúc Nam",
                "P. Cam Lộc",
                "P. Cam Phú",
                "P. Ba Ngòi",
                "P. Cam Thuận",
                "P. Cam Lợi",
                "P. Cam Linh",
                "X. Cam Thành Nam",
                "X. Cam Phước Đông",
                "X. Cam Thịnh Tây",
                "X. Cam Thịnh Đông",
                "X. Cam Lập",
                "X. Cam Bình"
             ],
             "codeErp":"4106"
          },
          {
             "n":"H. Cam Lâm",
             "w":[
                "X. Cam Tân",
                "X. Cam Hòa",
                "X. Cam Hải Đông",
                "X. Cam Hải Tây",
                "X. Sơn Tân",
                "X. Cam Hiệp Bắc",
                "TT. Cam Đức",
                "X. Cam Hiệp Nam",
                "X. Cam Phước Tây",
                "X. Cam Thành Bắc",
                "X. Cam An Bắc",
                "X. Cam An Nam",
                "X. Suối Cát",
                "X. Suối Tân"
             ],
             "codeErp":"4109"
          },
          {
             "n":"H. Vạn Ninh",
             "w":[
                "TT. Vạn Giã",
                "X. Đại Lãnh",
                "X. Vạn Phước",
                "X. Vạn Long",
                "X. Vạn Bình",
                "X. Vạn Thọ",
                "X. Vạn Khánh",
                "X. Vạn Phú",
                "X. Vạn Lương",
                "X. Vạn Thắng",
                "X. Vạn Thạnh",
                "X. Xuân Sơn",
                "X. Vạn Hưng"
             ],
             "codeErp":"4102"
          },
          {
             "n":"TX. Ninh Hòa",
             "w":[
                "P. Ninh Hiệp",
                "X. Ninh Sơn",
                "X. Ninh Tây",
                "X. Ninh Thượng",
                "X. Ninh An",
                "P. Ninh Hải",
                "X. Ninh Thọ",
                "X. Ninh Trung",
                "X. Ninh Sim",
                "X. Ninh Xuân",
                "X. Ninh Thân",
                "P. Ninh Diêm",
                "X. Ninh Đông",
                "P. Ninh Thủy",
                "P. Ninh Đa",
                "X. Ninh Phụng",
                "X. Ninh Bình",
                "X. Ninh Phước",
                "X. Ninh Phú",
                "X. Ninh Tân",
                "X. Ninh Quang",
                "P. Ninh Giang",
                "P. Ninh Hà",
                "X. Ninh Hưng",
                "X. Ninh Lộc",
                "X. Ninh Ích",
                "X. Ninh Vân"
             ],
             "codeErp":"4103"
          },
          {
             "n":"H. Khánh Vĩnh",
             "w":[
                "TT. Khánh Vĩnh",
                "X. Khánh Hiệp",
                "X. Khánh Bình",
                "X. Khánh Trung",
                "X. Khánh Đông",
                "X. Khánh Thượng",
                "X. Khánh Nam",
                "X. Sông Cầu",
                "X. Giang Ly",
                "X. Cầu Bà",
                "X. Liên Sang",
                "X. Khánh Thành",
                "X. Khánh Phú",
                "X. Sơn Thái"
             ],
             "codeErp":"4105"
          },
          {
             "n":"H. Diên Khánh",
             "w":[
                "TT. Diên Khánh",
                "X. Diên Lâm",
                "X. Diên Điền",
                "X. Diên Xuân",
                "X. Diên Sơn",
                "X. Diên Đồng",
                "X. Diên Phú",
                "X. Diên Thọ",
                "X. Diên Phước",
                "X. Diên Lạc",
                "X. Diên Tân",
                "X. Diên Hòa",
                "X. Diên Thạnh",
                "X. Diên Toàn",
                "X. Diên An",
                "X. Bình Lộc",
                "X. Suối Hiệp",
                "X. Suối Tiên"
             ],
             "codeErp":"4104"
          },
          {
             "n":"H. Khánh Sơn",
             "w":[
                "TT. Tô Hạp",
                "X. Thành Sơn",
                "X. Sơn Lâm",
                "X. Sơn Hiệp",
                "X. Sơn Bình",
                "X. Sơn Trung",
                "X. Ba Cụm Bắc",
                "X. Ba Cụm Nam"
             ],
             "codeErp":"4107"
          },
          {
             "n":"H. Trường Sa",
             "w":[
                "TT. Trường Sa",
                "X. Song Tử Tây",
                "X. Sinh Tồn"
             ],
             "codeErp":"4108"
          }
       ],
       "n":"Khánh Hòa",
       "codeErp":"41"
    },
    {
       "d":[
          {
             "n":"TP. Quy Nhơn",
             "w":[
                "P. Nhơn Bình",
                "P. Nhơn Phú",
                "P. Đống Đa",
                "P. Trần Quang Diệu",
                "P. Hải Cảng",
                "P. Quang Trung",
                "P. Thị Nại",
                "P. Lê Hồng Phong",
                "P. Trần Hưng Đạo",
                "P. Ngô Mây",
                "P. Lý Thường Kiệt",
                "P. Lê Lợi",
                "P. Trần Phú",
                "P. Bùi Thị Xuân",
                "P. Nguyễn Văn Cừ",
                "P. Ghềnh Ráng",
                "X. Nhơn Lý",
                "X. Nhơn Hội",
                "X. Nhơn Hải",
                "X. Nhơn Châu",
                "X. Phước Mỹ"
             ],
             "codeErp":"3701"
          },
          {
             "n":"H. An Lão",
             "w":[
                "TT. An Lão",
                "X. An Hưng",
                "X. An Trung",
                "X. An Dũng",
                "X. An Vinh",
                "X. An Toàn",
                "X. An Tân",
                "X. An Hòa",
                "X. An Quang",
                "X. An Nghĩa"
             ],
             "codeErp":"3702"
          },
          {
             "n":"TX. Hoài Nhơn",
             "w":[
                "P. Tam Quan",
                "P. Bồng Sơn",
                "X. Hoài Sơn",
                "X. Hoài Châu Bắc",
                "X. Hoài Châu",
                "X. Hoài Phú",
                "P. Tam Quan Bắc",
                "P. Tam Quan Nam",
                "P. Hoài Hảo",
                "P. Hoài Thanh Tây",
                "P. Hoài Thanh",
                "P. Hoài Hương",
                "P. Hoài Tân",
                "X. Hoài Hải",
                "P. Hoài Xuân",
                "X. Hoài Mỹ",
                "P. Hoài Đức"
             ],
             "codeErp":"3704"
          },
          {
             "n":"H. Hoài Ân",
             "w":[
                "TT. Tăng Bạt Hổ",
                "X. Ân Hảo Tây",
                "X. Ân Hảo Đông",
                "X. Ân Sơn",
                "X. Ân Mỹ",
                "X. Dak Mang",
                "X. Ân Tín",
                "X. Ân Thạnh",
                "X. Ân Phong",
                "X. Ân Đức",
                "X. Ân Hữu",
                "X. Bok Tới",
                "X. Ân Tường Tây",
                "X. Ân Tường Đông",
                "X. Ân Nghĩa"
             ],
             "codeErp":"3703"
          },
          {
             "n":"H. Phù Mỹ",
             "w":[
                "TT. Phù Mỹ",
                "TT. Bình Dương",
                "X. Mỹ Đức",
                "X. Mỹ Châu",
                "X. Mỹ Thắng",
                "X. Mỹ Lộc",
                "X. Mỹ Lợi",
                "X. Mỹ An",
                "X. Mỹ Phong",
                "X. Mỹ Trinh",
                "X. Mỹ Thọ",
                "X. Mỹ Hòa",
                "X. Mỹ Thành",
                "X. Mỹ Chánh",
                "X. Mỹ Quang",
                "X. Mỹ Hiệp",
                "X. Mỹ Tài",
                "X. Mỹ Cát",
                "X. Mỹ Chánh Tây"
             ],
             "codeErp":"3705"
          },
          {
             "n":"H. Vĩnh Thạnh",
             "w":[
                "TT. Vĩnh Thạnh",
                "X. Vĩnh Sơn",
                "X. Vĩnh Kim",
                "X. Vĩnh Hiệp",
                "X. Vĩnh Hảo",
                "X. Vĩnh Hòa",
                "X. Vĩnh Thịnh",
                "X. Vĩnh Thuận",
                "X. Vĩnh Quang"
             ],
             "codeErp":"3707"
          },
          {
             "n":"H. Tây Sơn",
             "w":[
                "TT. Phú Phong",
                "X. Bình Tân",
                "X. Tây Thuận",
                "X. Bình Thuận",
                "X. Tây Giang",
                "X. Bình Thành",
                "X. Tây An",
                "X. Bình Hòa",
                "X. Tây Bình",
                "X. Bình Tường",
                "X. Tây Vinh",
                "X. Vĩnh An",
                "X. Tây Xuân",
                "X. Bình Nghi",
                "X. Tây Phú"
             ],
             "codeErp":"3708"
          },
          {
             "n":"H. Phù Cát",
             "w":[
                "TT. Ngô Mây",
                "X. Cát Sơn",
                "X. Cát Minh",
                "X. Cát Khánh",
                "X. Cát Tài",
                "X. Cát Lâm",
                "X. Cát Hanh",
                "X. Cát Thành",
                "X. Cát Trinh",
                "X. Cát Hải",
                "X. Cát Hiệp",
                "X. Cát Nhơn",
                "X. Cát Hưng",
                "X. Cát Tường",
                "X. Cát Tân",
                "X. Cát Tiến",
                "X. Cát Thắng",
                "X. Cát Chánh"
             ],
             "codeErp":"3706"
          },
          {
             "n":"TX. An Nhơn",
             "w":[
                "P. Bình Định",
                "P. Đập Đá",
                "X. Nhơn Mỹ",
                "P. Nhơn Thành",
                "X. Nhơn Hạnh",
                "X. Nhơn Hậu",
                "X. Nhơn Phong",
                "X. Nhơn An",
                "X. Nhơn Phúc",
                "P. Nhơn Hưng",
                "X. Nhơn Khánh",
                "X. Nhơn Lộc",
                "P. Nhơn Hoà",
                "X. Nhơn Tân",
                "X. Nhơn Thọ"
             ],
             "codeErp":"3710"
          },
          {
             "n":"H. Tuy Phước",
             "w":[
                "TT. Tuy Phước",
                "TT. Diêu Trì",
                "X. Phước Thắng",
                "X. Phước Hưng",
                "X. Phước Quang",
                "X. Phước Hòa",
                "X. Phước Sơn",
                "X. Phước Hiệp",
                "X. Phước Lộc",
                "X. Phước Nghĩa",
                "X. Phước Thuận",
                "X. Phước An",
                "X. Phước Thành"
             ],
             "codeErp":"3711"
          },
          {
             "n":"H. Vân Canh",
             "w":[
                "TT. Vân Canh",
                "X. Canh Liên",
                "X. Canh Hiệp",
                "X. Canh Vinh",
                "X. Canh Hiển",
                "X. Canh Thuận",
                "X. Canh Hòa"
             ],
             "codeErp":"3709"
          }
       ],
       "n":"Bình Định",
       "codeErp":"37"
    },
    {
       "d":[
          {
             "n":"TP. Bắc Ninh",
             "w":[
                "P. Vũ Ninh",
                "P. Đáp Cầu",
                "P. Thị Cầu",
                "P. Kinh Bắc",
                "P. Vệ An",
                "P. Tiền An",
                "P. Đại Phúc",
                "P. Ninh Xá",
                "P. Suối Hoa",
                "P. Võ Cường",
                "P. Hòa Long",
                "P. Vạn An",
                "P. Khúc Xuyên",
                "P. Phong Khê",
                "P. Kim Chân",
                "P. Vân Dương",
                "P. Nam Sơn",
                "P. Khắc Niệm",
                "P. Hạp Lĩnh"
             ],
             "codeErp":"1901"
          },
          {
             "n":"H. Yên Phong",
             "w":[
                "TT. Chờ",
                "X. Dũng Liệt",
                "X. Tam Đa",
                "X. Tam Giang",
                "X. Yên Trung",
                "X. Thụy Hòa",
                "X. Hòa Tiến",
                "X. Đông Tiến",
                "X. Yên Phụ",
                "X. Trung Nghĩa",
                "X. Đông Phong",
                "X. Long Châu",
                "X. Văn Môn",
                "X. Đông Thọ"
             ],
             "codeErp":"1902"
          },
          {
             "n":"H. Quế Võ",
             "w":[
                "TT. Phố Mới",
                "X. Việt Thống",
                "X. Đại Xuân",
                "X. Nhân Hòa",
                "X. Bằng An",
                "X. Phương Liễu",
                "X. Quế Tân",
                "X. Phù Lương",
                "X. Phù Lãng",
                "X. Phượng Mao",
                "X. Việt Hùng",
                "X. Ngọc Xá",
                "X. Châu Phong",
                "X. Bồng Lai",
                "X. Cách Bi",
                "X. Đào Viên",
                "X. Yên Giả",
                "X. Mộ Đạo",
                "X. Đức Long",
                "X. Chi Lăng",
                "X. Hán Quảng"
             ],
             "codeErp":"1903"
          },
          {
             "n":"H. Tiên Du",
             "w":[
                "TT. Lim",
                "X. Phú Lâm",
                "X. Nội Duệ",
                "X. Liên Bão",
                "X. Hiên Vân",
                "X. Hoàn Sơn",
                "X. Lạc Vệ",
                "X. Việt Đoàn",
                "X. Phật Tích",
                "X. Tân Chi",
                "X. Đại Đồng",
                "X. Tri Phương",
                "X. Minh Đạo",
                "X. Cảnh Hưng"
             ],
             "codeErp":"1904"
          },
          {
             "n":"TX. Từ Sơn",
             "w":[
                "P. Đông Ngàn",
                "X. Tam Sơn",
                "X. Hương Mạc",
                "X. Tương Giang",
                "X. Phù Khê",
                "P. Đồng Kỵ",
                "P. Trang Hạ",
                "P. Đồng Nguyên",
                "P. Châu Khê",
                "P. Tân Hồng",
                "P. Đình Bảng",
                "X. Phù Chẩn"
             ],
             "codeErp":"1905"
          },
          {
             "n":"H. Thuận Thành",
             "w":[
                "TT. Hồ",
                "X. Hoài Thượng",
                "X. Đại Đồng Thành",
                "X. Mão Điền",
                "X. Song Hồ",
                "X. Đình Tổ",
                "X. An Bình",
                "X. Trí Quả",
                "X. Gia Đông",
                "X. Thanh Khương",
                "X. Trạm Lộ",
                "X. Xuân Lâm",
                "X. Hà Mãn",
                "X. Ngũ Thái",
                "X. Nguyệt Đức",
                "X. Ninh Xá",
                "X. Nghĩa Đạo",
                "X. Song Liễu"
             ],
             "codeErp":"1906"
          },
          {
             "n":"H. Gia Bình",
             "w":[
                "TT. Gia Bình",
                "X. Vạn Ninh",
                "X. Thái Bảo",
                "X. Giang Sơn",
                "X. Cao Đức",
                "X. Đại Lai",
                "X. Song Giang",
                "X. Bình Dương",
                "X. Lãng Ngâm",
                "X. Nhân Thắng",
                "X. Xuân Lai",
                "X. Đông Cứu",
                "X. Đại Bái",
                "X. Quỳnh Phú"
             ],
             "codeErp":"1907"
          },
          {
             "n":"H. Lương Tài",
             "w":[
                "TT. Thứa",
                "X. An Thịnh",
                "X. Trung Kênh",
                "X. Phú Hòa",
                "X. Mỹ Hương",
                "X. Tân Lãng",
                "X. Quảng Phú",
                "X. Trừng Xá",
                "X. Lai Hạ",
                "X. Trung Chính",
                "X. Minh Tân",
                "X. Bình Định",
                "X. Phú Lương",
                "X. Lâm Thao"
             ],
             "codeErp":"1908"
          }
       ],
       "n":"Bắc Ninh",
       "codeErp":"19"
    },
    {
       "d":[
          {
             "n":"TP. Huế",
             "w":[
                "P. Phú Thuận",
                "P. Phú Bình",
                "P. Tây Lộc",
                "P. Thuận Lộc",
                "P. Phú Hiệp",
                "P. Phú Hậu",
                "P. Thuận Hòa",
                "P. Thuận Thành",
                "P. Phú Hòa",
                "P. Phú Cát",
                "P. Kim Long",
                "P. Vĩ Dạ",
                "P. Phường Đúc",
                "P. Vĩnh Ninh",
                "P. Phú Hội",
                "P. Phú Nhuận",
                "P. Xuân Phú",
                "P. Trường An",
                "P. Phước Vĩnh",
                "P. An Cựu",
                "P. An Hòa",
                "P. Hương Sơ",
                "P. Thuỷ Biều",
                "P. Hương Long",
                "P. Thuỷ Xuân",
                "P. An Đông",
                "P. An Tây"
             ],
             "codeErp":"3301"
          },
          {
             "n":"H. Phong Điền",
             "w":[
                "TT. Phong Điền",
                "X. Điền Hương",
                "X. Điền Môn",
                "X. Điền Lộc",
                "X. Phong Bình",
                "X. Điền Hòa",
                "X. Phong Chương",
                "X. Phong Hải",
                "X. Điền Hải",
                "X. Phong Hòa",
                "X. Phong Thu",
                "X. Phong Hiền",
                "X. Phong Mỹ",
                "X. Phong An",
                "X. Phong Xuân",
                "X. Phong Sơn"
             ],
             "codeErp":"3302"
          },
          {
             "n":"H. Quảng Điền",
             "w":[
                "TT. Sịa",
                "X. Quảng Thái",
                "X. Quảng Ngạn",
                "X. Quảng Lợi",
                "X. Quảng Công",
                "X. Quảng Phước",
                "X. Quảng Vinh",
                "X. Quảng An",
                "X. Quảng Thành",
                "X. Quảng Thọ",
                "X. Quảng Phú"
             ],
             "codeErp":"3303"
          },
          {
             "n":"H. Phú Vang",
             "w":[
                "TT. Thuận An",
                "X. Phú Thuận",
                "X. Phú Dương",
                "X. Phú Mậu",
                "X. Phú An",
                "X. Phú Hải",
                "X. Phú Xuân",
                "X. Phú Diên",
                "X. Phú Thanh",
                "X. Phú Mỹ",
                "X. Phú Thượng",
                "X. Phú Hồ",
                "X. Vinh Xuân",
                "X. Phú Lương",
                "TT. Phú Đa",
                "X. Vinh Thanh",
                "X. Vinh An",
                "X. Phú Gia",
                "X. Vinh Hà"
             ],
             "codeErp":"3305"
          },
          {
             "n":"TX. Hương Thủy",
             "w":[
                "P. Phú Bài",
                "X. Thủy Vân",
                "X. Thủy Thanh",
                "P. Thủy Dương",
                "P. Thủy Phương",
                "P. Thủy Châu",
                "P. Thủy Lương",
                "X. Thủy Bằng",
                "X. Thủy Tân",
                "X. Thủy Phù",
                "X. Phú Sơn",
                "X. Dương Hòa"
             ],
             "codeErp":"3306"
          },
          {
             "n":"TX. Hương Trà",
             "w":[
                "P. Tứ Hạ",
                "X. Hải Dương",
                "X. Hương Phong",
                "X. Hương Toàn",
                "P. Hương Vân",
                "P. Hương Văn",
                "X. Hương Vinh",
                "P. Hương Xuân",
                "P. Hương Chữ",
                "P. Hương An",
                "X. Hương Bình",
                "P. Hương Hồ",
                "X. Hương Thọ",
                "X. Bình Tiến",
                "X. Bình Thành"
             ],
             "codeErp":"3304"
          },
          {
             "n":"H. A Lưới",
             "w":[
                "TT. A Lưới",
                "X. Hồng Vân",
                "X. Hồng Hạ",
                "X. Hồng Kim",
                "X. Trung Sơn",
                "X. Hương Nguyên",
                "X. Hồng Bắc",
                "X. A Ngo",
                "X. Sơn Thủy",
                "X. Phú Vinh",
                "X. Hương Phong",
                "X. Quảng Nhâm",
                "X. Hồng Thượng",
                "X. Hồng Thái",
                "X. A Roằng",
                "X. Đông Sơn",
                "X. Lâm Đớt",
                "X. Hồng Thủy"
             ],
             "codeErp":"3309"
          },
          {
             "n":"H. Phú Lộc",
             "w":[
                "TT. Phú Lộc",
                "TT. Lăng Cô",
                "X. Vinh Mỹ",
                "X. Vinh Hưng",
                "X. Giang Hải",
                "X. Vinh Hiền",
                "X. Lộc Bổn",
                "X. Lộc Sơn",
                "X. Lộc Bình",
                "X. Lộc Vĩnh",
                "X. Lộc An",
                "X. Lộc Điền",
                "X. Lộc Thủy",
                "X. Lộc Trì",
                "X. Lộc Tiến",
                "X. Lộc Hòa",
                "X. Xuân Lộc"
             ],
             "codeErp":"3307"
          },
          {
             "n":"H. Nam Đông",
             "w":[
                "TT. Khe Tre",
                "X. Hương Phú",
                "X. Hương Sơn",
                "X. Hương Lộc",
                "X. Thượng Quảng",
                "X. Hương Xuân",
                "X. Hương Hữu",
                "X. Thượng Lộ",
                "X. Thượng Long",
                "X. Thượng Nhật"
             ],
             "codeErp":"3308"
          }
       ],
       "n":"Thừa Thiên Huế",
       "codeErp":"33"
    },
    {
       "d":[
          {
             "n":"TP. Kon Tum",
             "w":[
                "P. Quang Trung",
                "P. Duy Tân",
                "P. Quyết Thắng",
                "P. Trường Chinh",
                "P. Thắng Lợi",
                "P. Ngô Mây",
                "P. Thống Nhất",
                "P. Lê Lợi",
                "P. Nguyễn Trãi",
                "P. Trần Hưng Đạo",
                "X. Đắk Cấm",
                "X. Kroong",
                "X. Ngọk Bay",
                "X. Vinh Quang",
                "X. Đắk Blà",
                "X. Ia Chim",
                "X. Đăk Năng",
                "X. Đoàn Kết",
                "X. Chư Hreng",
                "X. Đắk Rơ Wa",
                "X. Hòa Bình"
             ],
             "codeErp":"3601"
          },
          {
             "n":"H. Đắk Glei",
             "w":[
                "TT. Đắk Glei",
                "X. Đắk Blô",
                "X. Đắk Man",
                "X. Đắk Nhoong",
                "X. Đắk Pék",
                "X. Đắk Choong",
                "X. Xốp",
                "X. Mường Hoong",
                "X. Ngọc Linh",
                "X. Đắk Long",
                "X. Đắk KRoong",
                "X. Đắk Môn"
             ],
             "codeErp":"3602"
          },
          {
             "n":"H. Ngọc Hồi",
             "w":[
                "TT. Plei Kần",
                "X. Đắk Ang",
                "X. Đắk Dục",
                "X. Đắk Nông",
                "X. Đắk Xú",
                "X. Đắk Kan",
                "X. Bờ Y",
                "X. Sa Loong"
             ],
             "codeErp":"3603"
          },
          {
             "n":"H. Đắk Tô",
             "w":[
                "TT. Đắk Tô",
                "X. Đắk Rơ Nga",
                "X. Ngọk Tụ",
                "X. Đắk Trăm",
                "X. Văn Lem",
                "X. Kon Đào",
                "X. Tân Cảnh",
                "X. Diên Bình",
                "X. Pô Kô"
             ],
             "codeErp":"3604"
          },
          {
             "n":"H. Kon Plông",
             "w":[
                "X. Đắk Nên",
                "X. Đắk Ring",
                "X. Măng Buk",
                "X. Đắk Tăng",
                "X. Ngok Tem",
                "X. Pờ Ê",
                "X. Măng Cành",
                "TT. Măng Đen",
                "X. Hiếu"
             ],
             "codeErp":"3606"
          },
          {
             "n":"H. Kon Rẫy",
             "w":[
                "TT. Đắk Rve",
                "X. Đắk Kôi",
                "X. Đắk Tơ Lung",
                "X. Đắk Ruồng",
                "X. Đắk Pne",
                "X. Đắk Tờ Re",
                "X. Tân Lập"
             ],
             "codeErp":"3608"
          },
          {
             "n":"H. Đắk Hà",
             "w":[
                "TT. Đắk Hà",
                "X. Đắk PXi",
                "X. Đăk Long",
                "X. Đắk HRing",
                "X. Đắk Ui",
                "X. Đăk Ngọk",
                "X. Đắk Mar",
                "X. Ngok Wang",
                "X. Ngok Réo",
                "X. Hà Mòn",
                "X. Đắk La"
             ],
             "codeErp":"3607"
          },
          {
             "n":"H. Sa Thầy",
             "w":[
                "TT. Sa Thầy",
                "X. Rơ Kơi",
                "X. Sa Nhơn",
                "X. Hơ Moong",
                "X. Mô Rai",
                "X. Sa Sơn",
                "X. Sa Nghĩa",
                "X. Sa Bình",
                "X. Ya Xiêr",
                "X. Ya Tăng",
                "X. Ya ly"
             ],
             "codeErp":"3605"
          },
          {
             "n":"H. Tu Mơ Rông",
             "w":[
                "X. Ngọc Lây",
                "X. Đắk Na",
                "X. Măng Ri",
                "X. Ngọc Yêu",
                "X. Đắk Sao",
                "X. Đắk Rơ Ông",
                "X. Đắk Tờ Kan",
                "X. Tu Mơ Rông",
                "X. Đắk Hà",
                "X. Tê Xăng",
                "X. Văn Xuôi"
             ],
             "codeErp":"3609"
          },
          {
             "n":"H. Ia H' Drai",
             "w":[
                "X. Ia Đal",
                "X. Ia Dom",
                "X. Ia Tơi"
             ],
             "codeErp":""
          }
       ],
       "n":"Kon Tum",
       "codeErp":"36"
    },
    {
       "d":[
          {
             "n":"TP. Tuy Hoà",
             "w":[
                "P. 1",
                "P. 8",
                "P. 2",
                "P. 9",
                "P. 3",
                "P. 4",
                "P. 5",
                "P. 7",
                "P. 6",
                "P. Phú Thạnh",
                "P. Phú Đông",
                "X. Hòa Kiến",
                "X. Bình Kiến",
                "X. Bình Ngọc",
                "X. An Phú",
                "P. Phú Lâm"
             ],
             "codeErp":"3901"
          },
          {
             "n":"TX. Sông Cầu",
             "w":[
                "P. Xuân Phú",
                "X. Xuân Lâm",
                "P. Xuân Thành",
                "X. Xuân Hải",
                "X. Xuân Lộc",
                "X. Xuân Bình",
                "X. Xuân Cảnh",
                "X. Xuân Thịnh",
                "X. Xuân Phương",
                "P. Xuân Yên",
                "X. Xuân Thọ 1",
                "P. Xuân Đài",
                "X. Xuân Thọ 2"
             ],
             "codeErp":"3903"
          },
          {
             "n":"H. Đồng Xuân",
             "w":[
                "TT. La Hai",
                "X. Đa Lộc",
                "X. Phú Mỡ",
                "X. Xuân Lãnh",
                "X. Xuân Long",
                "X. Xuân Quang 1",
                "X. Xuân Sơn Bắc",
                "X. Xuân Quang 2",
                "X. Xuân Sơn Nam",
                "X. Xuân Quang 3",
                "X. Xuân Phước"
             ],
             "codeErp":"3902"
          },
          {
             "n":"H. Tuy An",
             "w":[
                "TT. Chí Thạnh",
                "X. An Dân",
                "X. An Ninh Tây",
                "X. An Ninh Đông",
                "X. An Thạch",
                "X. An Định",
                "X. An Nghiệp",
                "X. An Cư",
                "X. An Xuân",
                "X. An Lĩnh",
                "X. An Hòa Hải",
                "X. An Hiệp",
                "X. An Mỹ",
                "X. An Chấn",
                "X. An Thọ"
             ],
             "codeErp":"3904"
          },
          {
             "n":"H. Sơn Hòa",
             "w":[
                "TT. Củng Sơn",
                "X. Phước Tân",
                "X. Sơn Hội",
                "X. Sơn Định",
                "X. Sơn Long",
                "X. Cà Lúi",
                "X. Sơn Phước",
                "X. Sơn Xuân",
                "X. Sơn Nguyên",
                "X. Eachà Rang",
                "X. Krông Pa",
                "X. Suối Bạc",
                "X. Sơn Hà",
                "X. Suối Trai"
             ],
             "codeErp":"3905"
          },
          {
             "n":"H. Sông Hinh",
             "w":[
                "TT. Hai Riêng",
                "X. Ea Lâm",
                "X. Đức Bình Tây",
                "X. Ea Bá",
                "X. Sơn Giang",
                "X. Đức Bình Đông",
                "X. EaBar",
                "X. EaBia",
                "X. EaTrol",
                "X. Sông Hinh",
                "X. Ealy"
             ],
             "codeErp":"3906"
          },
          {
             "n":"H. Tây Hoà",
             "w":[
                "X. Sơn Thành Tây",
                "X. Sơn Thành Đông",
                "X. Hòa Bình 1",
                "TT. Phú Thứ",
                "X. Hòa Phong",
                "X. Hòa Phú",
                "X. Hòa Tân Tây",
                "X. Hòa Đồng",
                "X. Hòa Mỹ Đông",
                "X. Hòa Mỹ Tây",
                "X. Hòa Thịnh"
             ],
             "codeErp":"3901"
          },
          {
             "n":"H. Phú Hoà",
             "w":[
                "X. Hòa Quang Bắc",
                "X. Hòa Quang Nam",
                "X. Hòa Hội",
                "X. Hòa Trị",
                "X. Hòa An",
                "X. Hòa Định Đông",
                "TT. Phú Hoà",
                "X. Hòa Định Tây",
                "X. Hòa Thắng"
             ],
             "codeErp":"3908"
          },
          {
             "n":"TX. Đông Hòa",
             "w":[
                "X. Hòa Thành",
                "P. Hòa Hiệp Bắc",
                "P. Hoà Vinh",
                "P. Hoà Hiệp Trung",
                "X. Hòa Tân Đông",
                "P. Hòa Xuân Tây",
                "P. Hòa Hiệp Nam",
                "X. Hòa Xuân Đông",
                "X. Hòa Tâm",
                "X. Hòa Xuân Nam"
             ],
             "codeErp":"3907"
          }
       ],
       "n":"Phú Yên",
       "codeErp":"39"
    },
    {
       "d":[
          {
             "n":"Q. Liên Chiểu",
             "w":[
                "P. Hòa Hiệp Bắc",
                "P. Hòa Hiệp Nam",
                "P. Hòa Khánh Bắc",
                "P. Hòa Khánh Nam",
                "P. Hòa Minh"
             ],
             "codeErp":"0405"
          },
          {
             "n":"Q. Thanh Khê",
             "w":[
                "P. Tam Thuận",
                "P. Thanh Khê Tây",
                "P. Thanh Khê Đông",
                "P. Xuân Hà",
                "P. Tân Chính",
                "P. Chính Gián",
                "P. Vĩnh Trung",
                "P. Thạc Gián",
                "P. An Khê",
                "P. Hòa Khê"
             ],
             "codeErp":"0402"
          },
          {
             "n":"Q. Hải Châu",
             "w":[
                "P. Thanh Bình",
                "P. Thuận Phước",
                "P. Thạch Thang",
                "P. Hải Châu  I",
                "P. Hải Châu II",
                "P. Phước Ninh",
                "P. Hòa Thuận Tây",
                "P. Hòa Thuận Đông",
                "P. Nam Dương",
                "P. Bình Hiên",
                "P. Bình Thuận",
                "P. Hòa Cường Bắc",
                "P. Hòa Cường Nam"
             ],
             "codeErp":"0401"
          },
          {
             "n":"Q. Sơn Trà",
             "w":[
                "P. Thọ Quang",
                "P. Nại Hiên Đông",
                "P. Mân Thái",
                "P. An Hải Bắc",
                "P. Phước Mỹ",
                "P. An Hải Tây",
                "P. An Hải Đông"
             ],
             "codeErp":"0403"
          },
          {
             "n":"Q. Ngũ Hành Sơn",
             "w":[
                "P. Mỹ An",
                "P. Khuê Mỹ",
                "P. Hoà Quý",
                "P. Hoà Hải"
             ],
             "codeErp":"0404"
          },
          {
             "n":"Q. Cẩm Lệ",
             "w":[
                "P. Khuê Trung",
                "P. Hòa Phát",
                "P. Hòa An",
                "P. Hòa Thọ Tây",
                "P. Hòa Thọ Đông",
                "P. Hòa Xuân"
             ],
             "codeErp":"0407"
          },
          {
             "n":"H. Hòa Vang",
             "w":[
                "X. Hòa Bắc",
                "X. Hòa Liên",
                "X. Hòa Ninh",
                "X. Hòa Sơn",
                "X. Hòa Nhơn",
                "X. Hòa Phú",
                "X. Hòa Phong",
                "X. Hòa Châu",
                "X. Hòa Tiến",
                "X. Hòa Phước",
                "X. Hòa Khương"
             ],
             "codeErp":"0406"
          },
          {
             "n":"H. Hoàng Sa",
             "w":[
 
             ],
             "codeErp":"0408"
          }
       ],
       "n":"Tp. Đà Nẵng",
       "codeErp":"04"
    },
    {
       "d":[
          {
             "n":"TP. Thanh Hóa",
             "w":[
                "P. Hàm Rồng",
                "P. Đông Thọ",
                "P. Nam Ngạn",
                "P. Trường Thi",
                "P. Điện Biên",
                "P. Phú Sơn",
                "P. Lam Sơn",
                "P. Ba Đình",
                "P. Ngọc Trạo",
                "P. Đông Vệ",
                "P. Đông Sơn",
                "P. Tân Sơn",
                "P. Đông Cương",
                "P. Đông Hương",
                "P. Đông Hải",
                "P. Quảng Hưng",
                "P. Quảng Thắng",
                "P. Quảng Thành",
                "X. Thiệu Vân",
                "X. Thiệu Khánh",
                "X. Thiệu Dương",
                "P. Tào Xuyên",
                "X. Long Anh",
                "X. Hoằng Quang",
                "X. Hoằng Đại",
                "X. Đông Lĩnh",
                "X. Đông Vinh",
                "X. Đông Tân",
                "P. An Hưng",
                "X. Quảng Thịnh",
                "X. Quảng Đông",
                "X. Quảng Cát",
                "X. Quảng Phú",
                "X. Quảng Tâm"
             ],
             "codeErp":"2801"
          },
          {
             "n":"TX. Bỉm Sơn",
             "w":[
                "P. Bắc Sơn",
                "P. Ba Đình",
                "P. Lam Sơn",
                "P. Ngọc Trạo",
                "P. Đông Sơn",
                "P. Phú Sơn",
                "X. Quang Trung"
             ],
             "codeErp":"2802"
          },
          {
             "n":"TP. Sầm Sơn",
             "w":[
                "P. Trung Sơn",
                "P. Bắc Sơn",
                "P. Trường Sơn",
                "P. Quảng Cư",
                "P. Quảng Tiến",
                "X. Quảng Minh",
                "X. Quảng Hùng",
                "P. Quảng Thọ",
                "P. Quảng Châu",
                "P. Quảng Vinh",
                "X. Quảng Đại"
             ],
             "codeErp":"2803"
          },
          {
             "n":"H. Mường Lát",
             "w":[
                "TT. Mường Lát",
                "X. Tam Chung",
                "X. Mường Lý",
                "X. Trung Lý",
                "X. Quang Chiểu",
                "X. Pù Nhi",
                "X. Nhi Sơn",
                "X. Mường Chanh"
             ],
             "codeErp":"2806"
          },
          {
             "n":"H. Quan Hóa",
             "w":[
                "TT. Hồi Xuân",
                "X. Thành Sơn",
                "X. Trung Sơn",
                "X. Phú Thanh",
                "X. Trung Thành",
                "X. Phú Lệ",
                "X. Phú Sơn",
                "X. Phú Xuân",
                "X. Hiền Chung",
                "X. Hiền Kiệt",
                "X. Nam Tiến",
                "X. Thiên Phủ",
                "X. Phú Nghiêm",
                "X. Nam Xuân",
                "X. Nam Động"
             ],
             "codeErp":"2804"
          },
          {
             "n":"H. Bá Thước",
             "w":[
                "TT. Cành Nàng",
                "X. Điền Thượng",
                "X. Điền Hạ",
                "X. Điền Quang",
                "X. Điền Trung",
                "X. Thành Sơn",
                "X. Lương Ngoại",
                "X. Ái Thượng",
                "X. Lương Nội",
                "X. Điền Lư",
                "X. Lương Trung",
                "X. Lũng Niêm",
                "X. Lũng Cao",
                "X. Hạ Trung",
                "X. Cổ Lũng",
                "X. Thành Lâm",
                "X. Ban Công",
                "X. Kỳ Tân",
                "X. Văn Nho",
                "X. Thiết Ống",
                "X. Thiết Kế"
             ],
             "codeErp":"2807"
          },
          {
             "n":"H. Quan Sơn",
             "w":[
                "X. Trung Xuân",
                "X. Trung Thượng",
                "X. Trung Tiến",
                "X. Trung Hạ",
                "X. Sơn Hà",
                "X. Tam Thanh",
                "X. Sơn Thủy",
                "X. Na Mèo",
                "TT. Sơn Lư",
                "X. Tam Lư",
                "X. Sơn Điện",
                "X. Mường Mìn"
             ],
             "codeErp":"2805"
          },
          {
             "n":"H. Lang Chánh",
             "w":[
                "X. Yên Khương",
                "X. Yên Thắng",
                "X. Trí Nang",
                "X. Giao An",
                "X. Giao Thiện",
                "X. Tân Phúc",
                "X. Tam Văn",
                "X. Lâm Phú",
                "TT. Lang Chánh",
                "X. Đồng Lương"
             ],
             "codeErp":"2811"
          },
          {
             "n":"H. Ngọc Lặc",
             "w":[
                "TT. Ngọc Lặc",
                "X. Lam Sơn",
                "X. Mỹ Tân",
                "X. Thúy Sơn",
                "X. Thạch Lập",
                "X. Vân Âm",
                "X. Cao Ngọc",
                "X. Quang Trung",
                "X. Đồng Thịnh",
                "X. Ngọc Liên",
                "X. Ngọc Sơn",
                "X. Lộc Thịnh",
                "X. Cao Thịnh",
                "X. Ngọc Trung",
                "X. Phùng Giáo",
                "X. Phùng Minh",
                "X. Phúc Thịnh",
                "X. Nguyệt Ấn",
                "X. Kiên Thọ",
                "X. Minh Tiến",
                "X. Minh Sơn"
             ],
             "codeErp":"2812"
          },
          {
             "n":"H. Cẩm Thủy",
             "w":[
                "TT. Phong Sơn",
                "X. Cẩm Thành",
                "X. Cẩm Quý",
                "X. Cẩm Lương",
                "X. Cẩm Thạch",
                "X. Cẩm Liên",
                "X. Cẩm Giang",
                "X. Cẩm Bình",
                "X. Cẩm Tú",
                "X. Cẩm Châu",
                "X. Cẩm Tâm",
                "X. Cẩm Ngọc",
                "X. Cẩm Long",
                "X. Cẩm Yên",
                "X. Cẩm Tân",
                "X. Cẩm Phú",
                "X. Cẩm Vân"
             ],
             "codeErp":"2814"
          },
          {
             "n":"H. Thạch Thành",
             "w":[
                "TT. Kim Tân",
                "TT. Vân Du",
                "X. Thạch Lâm",
                "X. Thạch Quảng",
                "X. Thạch Tượng",
                "X. Thạch Cẩm",
                "X. Thạch Sơn",
                "X. Thạch Bình",
                "X. Thạch Định",
                "X. Thạch Đồng",
                "X. Thạch Long",
                "X. Thành Mỹ",
                "X. Thành Yên",
                "X. Thành Vinh",
                "X. Thành Minh",
                "X. Thành Công",
                "X. Thành Tân",
                "X. Thành Trực",
                "X. Thành Tâm",
                "X. Thành An",
                "X. Thành Thọ",
                "X. Thành Tiến",
                "X. Thành Long",
                "X. Thành Hưng",
                "X. Ngọc Trạo"
             ],
             "codeErp":"2813"
          },
          {
             "n":"H. Hà Trung",
             "w":[
                "TT. Hà Trung",
                "X. Hà Long",
                "X. Hà Vinh",
                "X. Hà Bắc",
                "X. Hoạt Giang",
                "X. Yên Dương",
                "X. Hà Giang",
                "X. Lĩnh Toại",
                "X. Hà Ngọc",
                "X. Yến Sơn",
                "X. Hà Sơn",
                "X. Hà Lĩnh",
                "X. Hà Đông",
                "X. Hà Tân",
                "X. Hà Tiến",
                "X. Hà Bình",
                "X. Hà Lai",
                "X. Hà Châu",
                "X. Hà Thái",
                "X. Hà Hải"
             ],
             "codeErp":"2821"
          },
          {
             "n":"H. Vĩnh Lộc",
             "w":[
                "TT. Vĩnh Lộc",
                "X. Vĩnh Quang",
                "X. Vĩnh Yên",
                "X. Vĩnh Tiến",
                "X. Vĩnh Long",
                "X. Vĩnh Phúc",
                "X. Vĩnh Hưng",
                "X. Vĩnh Hòa",
                "X. Vĩnh Hùng",
                "X. Minh Tân",
                "X. Ninh Khang",
                "X. Vĩnh Thịnh",
                "X. Vĩnh An"
             ],
             "codeErp":"2816"
          },
          {
             "n":"H. Yên Định",
             "w":[
                "TT. Thống Nhất",
                "X. Yên Lâm",
                "X. Yên Tâm",
                "X. Yên Phú",
                "X. Quí Lộc",
                "X. Yên Thọ",
                "X. Yên Trung",
                "X. Yên Trường",
                "X. Yên Phong",
                "X. Yên Thái",
                "X. Yên Hùng",
                "X. Yên Thịnh",
                "X. Yên Ninh",
                "X. Yên Lạc",
                "X. Định Tăng",
                "X. Định Hòa",
                "X. Định Thành",
                "X. Định Công",
                "X. Định Tân",
                "X. Định Tiến",
                "X. Định Long",
                "X. Định Liên",
                "TT. Quán Lào",
                "X. Định Hưng",
                "X. Định Hải",
                "X. Định Bình"
             ],
             "codeErp":"2827"
          },
          {
             "n":"H. Thọ Xuân",
             "w":[
                "X. Xuân Hồng",
                "TT. Thọ Xuân",
                "X. Bắc Lương",
                "X. Nam Giang",
                "X. Xuân Phong",
                "X. Thọ Lộc",
                "X. Xuân Trường",
                "X. Xuân Hòa",
                "X. Thọ Hải",
                "X. Tây Hồ",
                "X. Xuân Giang",
                "X. Xuân Sinh",
                "X. Xuân Hưng",
                "X. Thọ Diên",
                "X. Thọ Lâm",
                "X. Thọ Xương",
                "X. Xuân Bái",
                "X. Xuân Phú",
                "TT. Sao Vàng",
                "TT. Lam Sơn",
                "X. Xuân Thiên",
                "X. Thuận Minh",
                "X. Thọ Lập",
                "X. Quảng Phú",
                "X. Xuân Tín",
                "X. Phú Xuân",
                "X. Xuân Lai",
                "X. Xuân Lập",
                "X. Xuân Minh",
                "X. Trường Xuân"
             ],
             "codeErp":"2815"
          },
          {
             "n":"H. Thường Xuân",
             "w":[
                "X. Bát Mọt",
                "X. Yên Nhân",
                "X. Xuân Lẹ",
                "X. Vạn Xuân",
                "X. Lương Sơn",
                "X. Xuân Cao",
                "X. Luận Thành",
                "X. Luận Khê",
                "X. Xuân Thắng",
                "X. Xuân Lộc",
                "TT. Thường Xuân",
                "X. Xuân Dương",
                "X. Thọ Thanh",
                "X. Ngọc Phụng",
                "X. Xuân Chinh",
                "X. Tân Thành"
             ],
             "codeErp":"2808"
          },
          {
             "n":"H. Triệu Sơn",
             "w":[
                "TT. Triệu Sơn",
                "X. Thọ Sơn",
                "X. Thọ Bình",
                "X. Thọ Tiến",
                "X. Hợp Lý",
                "X. Hợp Tiến",
                "X. Hợp Thành",
                "X. Triệu Thành",
                "X. Hợp Thắng",
                "X. Minh Sơn",
                "X. Dân Lực",
                "X. Dân Lý",
                "X. Dân Quyền",
                "X. An Nông",
                "X. Văn Sơn",
                "X. Thái Hòa",
                "TT. Nưa",
                "X. Đồng Lợi",
                "X. Đồng Tiến",
                "X. Đồng Thắng",
                "X. Tiến Nông",
                "X. Khuyến Nông",
                "X. Xuân Thịnh",
                "X. Xuân Lộc",
                "X. Thọ Dân",
                "X. Xuân Thọ",
                "X. Thọ Tân",
                "X. Thọ Ngọc",
                "X. Thọ Cường",
                "X. Thọ Phú",
                "X. Thọ Vực",
                "X. Thọ Thế",
                "X. Nông Trường",
                "X. Bình Sơn"
             ],
             "codeErp":"2818"
          },
          {
             "n":"H. Thiệu Hóa",
             "w":[
                "TT. Thiệu Hóa",
                "X. Thiệu Ngọc",
                "X. Thiệu Vũ",
                "X. Thiệu Phúc",
                "X. Thiệu Tiến",
                "X. Thiệu Công",
                "X. Thiệu Phú",
                "X. Thiệu Long",
                "X. Thiệu Giang",
                "X. Thiệu Duy",
                "X. Thiệu Nguyên",
                "X. Thiệu Hợp",
                "X. Thiệu Thịnh",
                "X. Thiệu Quang",
                "X. Thiệu Thành",
                "X. Thiệu Toán",
                "X. Thiệu Chính",
                "X. Thiệu Hòa",
                "X. Minh Tâm",
                "X. Thiệu Viên",
                "X. Thiệu Lý",
                "X. Thiệu Vận",
                "X. Thiệu Trung",
                "X. Tân Châu",
                "X. Thiệu Giao"
             ],
             "codeErp":"2817"
          },
          {
             "n":"H. Hoằng Hóa",
             "w":[
                "TT. Bút Sơn",
                "X. Hoằng Giang",
                "X. Hoằng Xuân",
                "X. Hoằng Phượng",
                "X. Hoằng Phú",
                "X. Hoằng Quỳ",
                "X. Hoằng Kim",
                "X. Hoằng Trung",
                "X. Hoằng Trinh",
                "X. Hoằng Sơn",
                "X. Hoằng Cát",
                "X. Hoằng Xuyên",
                "X. Hoằng Quý",
                "X. Hoằng Hợp",
                "X. Hoằng Đức",
                "X. Hoằng Hà",
                "X. Hoằng Đạt",
                "X. Hoằng Đạo",
                "X. Hoằng Thắng",
                "X. Hoằng Đồng",
                "X. Hoằng Thái",
                "X. Hoằng Thịnh",
                "X. Hoằng Thành",
                "X. Hoằng Lộc",
                "X. Hoằng Trạch",
                "X. Hoằng Phong",
                "X. Hoằng Lưu",
                "X. Hoằng Châu",
                "X. Hoằng Tân",
                "X. Hoằng Yến",
                "X. Hoằng Tiến",
                "X. Hoằng Hải",
                "X. Hoằng Ngọc",
                "X. Hoằng Đông",
                "X. Hoằng Thanh",
                "X. Hoằng Phụ",
                "X. Hoằng Trường"
             ],
             "codeErp":"2822"
          },
          {
             "n":"H. Hậu Lộc",
             "w":[
                "TT. Hậu Lộc",
                "X. Đồng Lộc",
                "X. Đại Lộc",
                "X. Triệu Lộc",
                "X. Tiến Lộc",
                "X. Lộc Sơn",
                "X. Cầu Lộc",
                "X. Thành Lộc",
                "X. Tuy Lộc",
                "X. Phong Lộc",
                "X. Mỹ Lộc",
                "X. Thuần Lộc",
                "X. Xuân Lộc",
                "X. Hoa Lộc",
                "X. Liên Lộc",
                "X. Quang Lộc",
                "X. Phú Lộc",
                "X. Hòa Lộc",
                "X. Minh Lộc",
                "X. Hưng Lộc",
                "X. Hải Lộc",
                "X. Đa Lộc",
                "X. Ngư Lộc"
             ],
             "codeErp":"2824"
          },
          {
             "n":"H. Nga Sơn",
             "w":[
                "TT. Nga Sơn",
                "X. Ba Đình",
                "X. Nga Vịnh",
                "X. Nga Văn",
                "X. Nga Thiện",
                "X. Nga Tiến",
                "X. Nga Phượng",
                "X. Nga Trung",
                "X. Nga Bạch",
                "X. Nga Thanh",
                "X. Nga Yên",
                "X. Nga Giáp",
                "X. Nga Hải",
                "X. Nga Thành",
                "X. Nga An",
                "X. Nga Phú",
                "X. Nga Điền",
                "X. Nga Tân",
                "X. Nga Thủy",
                "X. Nga Liên",
                "X. Nga Thái",
                "X. Nga Thạch",
                "X. Nga Thắng",
                "X. Nga Trường"
             ],
             "codeErp":"2823"
          },
          {
             "n":"H. Như Xuân",
             "w":[
                "TT. Yên Cát",
                "X. Bãi Trành",
                "X. Xuân Hòa",
                "X. Xuân Bình",
                "X. Hóa Quỳ",
                "X. Cát Vân",
                "X. Cát Tân",
                "X. Tân Bình",
                "X. Bình Lương",
                "X. Thanh Quân",
                "X. Thanh Xuân",
                "X. Thanh Hòa",
                "X. Thanh Phong",
                "X. Thanh Lâm",
                "X. Thanh Sơn",
                "X. Thượng Ninh"
             ],
             "codeErp":"2809"
          },
          {
             "n":"H. Như Thanh",
             "w":[
                "TT. Bến Sung",
                "X. Cán Khê",
                "X. Xuân Du",
                "X. Phượng Nghi",
                "X. Mậu Lâm",
                "X. Xuân Khang",
                "X. Phú Nhuận",
                "X. Hải Long",
                "X. Xuân Thái",
                "X. Xuân Phúc",
                "X. Yên Thọ",
                "X. Yên Lạc",
                "X. Thanh Tân",
                "X. Thanh Kỳ"
             ],
             "codeErp":"2810"
          },
          {
             "n":"H. Nông Cống",
             "w":[
                "TT. Nông Cống",
                "X. Tân Phúc",
                "X. Tân Thọ",
                "X. Hoàng Sơn",
                "X. Tân Khang",
                "X. Hoàng Giang",
                "X. Trung Chính",
                "X. Trung Thành",
                "X. Tế Thắng",
                "X. Tế Lợi",
                "X. Tế Nông",
                "X. Minh Nghĩa",
                "X. Minh Khôi",
                "X. Vạn Hòa",
                "X. Trường Trung",
                "X. Vạn Thắng",
                "X. Trường Giang",
                "X. Vạn Thiện",
                "X. Thăng Long",
                "X. Trường Minh",
                "X. Trường Sơn",
                "X. Thăng Bình",
                "X. Công Liêm",
                "X. Tượng Văn",
                "X. Thăng Thọ",
                "X. Tượng Lĩnh",
                "X. Tượng Sơn",
                "X. Công Chính",
                "X. Yên Mỹ"
             ],
             "codeErp":"2819"
          },
          {
             "n":"H. Đông Sơn",
             "w":[
                "TT. Rừng Thông",
                "X. Đông Hoàng",
                "X. Đông Ninh",
                "X. Đông Hòa",
                "X. Đông Yên",
                "X. Đông Minh",
                "X. Đông Thanh",
                "X. Đông Tiến",
                "X. Đông Khê",
                "X. Đông Thịnh",
                "X. Đông Văn",
                "X. Đông Phú",
                "X. Đông Nam",
                "X. Đông Quang"
             ],
             "codeErp":"2820"
          },
          {
             "n":"H. Quảng Xương",
             "w":[
                "TT. Tân Phong",
                "X. Quảng Trạch",
                "X. Quảng Đức",
                "X. Quảng Định",
                "X. Quảng Nhân",
                "X. Quảng Ninh",
                "X. Quảng Bình",
                "X. Quảng Hợp",
                "X. Quảng Văn",
                "X. Quảng Long",
                "X. Quảng Yên",
                "X. Quảng Hòa",
                "X. Quảng Khê",
                "X. Quảng Trung",
                "X. Quảng Chính",
                "X. Quảng Ngọc",
                "X. Quảng Trường",
                "X. Quảng Phúc",
                "X. Quảng Giao",
                "X. Quảng Hải",
                "X. Quảng Lưu",
                "X. Quảng Lộc",
                "X. Tiên Trang",
                "X. Quảng Nham",
                "X. Quảng Thạch",
                "X. Quảng Thái"
             ],
             "codeErp":"2825"
          },
          {
             "n":"TX. Nghi Sơn",
             "w":[
                "P. Hải Hòa",
                "P. Hải Châu",
                "X. Thanh Thủy",
                "X. Thanh Sơn",
                "P. Hải Ninh",
                "X. Anh Sơn",
                "X. Ngọc Lĩnh",
                "P. Hải An",
                "X. Các Sơn",
                "P. Tân Dân",
                "P. Hải Lĩnh",
                "X. Định Hải",
                "X. Phú Sơn",
                "P. Ninh Hải",
                "P. Nguyên Bình",
                "X. Hải Nhân",
                "P. Bình Minh",
                "P. Hải Thanh",
                "X. Phú Lâm",
                "P. Xuân Lâm",
                "P. Trúc Lâm",
                "P. Hải Bình",
                "X. Tân Trường",
                "X. Tùng Lâm",
                "P. Tĩnh Hải",
                "P. Mai Lâm",
                "X. Trường Lâm",
                "X. Hải Yến",
                "P. Hải Thượng",
                "X. Nghi Sơn",
                "X. Hải Hà"
             ],
             "codeErp":""
          }
       ],
       "n":"Thanh Hóa",
       "codeErp":"28"
    },
    {
       "d":[
          {
             "n":"TP. Đông Hà",
             "w":[
                "P. Đông Giang",
                "P. 1",
                "P. Đông Lễ",
                "P. Đông Thanh",
                "P. 2",
                "P. 4",
                "P. 5",
                "P. Đông Lương",
                "P. 3"
             ],
             "codeErp":"3201"
          },
          {
             "n":"TX. Quảng Trị",
             "w":[
                "P. 1",
                "P. An Đôn",
                "P. 2",
                "P. 3",
                "X. Hải Lệ"
             ],
             "codeErp":"3202"
          },
          {
             "n":"H. Vĩnh Linh",
             "w":[
                "TT. Hồ Xá",
                "TT. Bến Quan",
                "X. Vĩnh Thái",
                "X. Vĩnh Tú",
                "X. Vĩnh Chấp",
                "X. Trung Nam",
                "X. Kim Thạch",
                "X. Vĩnh Long",
                "X. Vĩnh Khê",
                "X. Vĩnh Hòa",
                "X. Vĩnh Thủy",
                "X. Vĩnh Lâm",
                "X. Hiền Thành",
                "TT. Cửa Tùng",
                "X. Vĩnh Hà",
                "X. Vĩnh Sơn",
                "X. Vĩnh Giang",
                "X. Vĩnh Ô"
             ],
             "codeErp":"3203"
          },
          {
             "n":"H. Hướng Hóa",
             "w":[
                "TT. Khe Sanh",
                "TT. Lao Bảo",
                "X. Hướng Lập",
                "X. Hướng Việt",
                "X. Hướng Phùng",
                "X. Hướng Sơn",
                "X. Hướng Linh",
                "X. Tân Hợp",
                "X. Hướng Tân",
                "X. Tân Thành",
                "X. Tân Long",
                "X. Tân Lập",
                "X. Tân Liên",
                "X. Húc",
                "X. Thuận",
                "X. Hướng Lộc",
                "X. Ba Tầng",
                "X. Thanh",
                "X.  A Dơi",
                "X. Lìa",
                "X. Xy"
             ],
             "codeErp":"3208"
          },
          {
             "n":"H. Gio Linh",
             "w":[
                "TT. Gio Linh",
                "TT. Cửa Việt",
                "X. Trung Giang",
                "X. Trung Hải",
                "X. Trung Sơn",
                "X. Phong Bình",
                "X. Gio Mỹ",
                "X. Gio Hải",
                "X. Gio An",
                "X. Gio Châu",
                "X. Gio Việt",
                "X. Linh Trường",
                "X. Gio Sơn",
                "X. Gio Mai",
                "X. Hải Thái",
                "X. Linh Hải",
                "X. Gio Quang"
             ],
             "codeErp":"3204"
          },
          {
             "n":"H. Đa Krông",
             "w":[
                "TT. Krông Klang",
                "X. Mò Ó",
                "X. Hướng Hiệp",
                "X. Đa Krông",
                "X. Triệu Nguyên",
                "X. Ba Lòng",
                "X. Ba Nang",
                "X. Tà Long",
                "X. Húc Nghì",
                "X. A Vao",
                "X. Tà Rụt",
                "X. A Bung",
                "X. A Ngo"
             ],
             "codeErp":"3209"
          },
          {
             "n":"H. Cam Lộ",
             "w":[
                "TT. Cam Lộ",
                "X. Cam Tuyền",
                "X. Thanh An",
                "X. Cam Thủy",
                "X. Cam Thành",
                "X. Cam Hiếu",
                "X. Cam Chính",
                "X. Cam Nghĩa"
             ],
             "codeErp":"3205"
          },
          {
             "n":"H. Triệu Phong",
             "w":[
                "TT. Ái Tử",
                "X. Triệu An",
                "X. Triệu Vân",
                "X. Triệu Phước",
                "X. Triệu Độ",
                "X. Triệu Trạch",
                "X. Triệu Thuận",
                "X. Triệu Đại",
                "X. Triệu Hòa",
                "X. Triệu Lăng",
                "X. Triệu Sơn",
                "X. Triệu Long",
                "X. Triệu Tài",
                "X. Triệu Trung",
                "X. Triệu Ái",
                "X. Triệu Thượng",
                "X. Triệu Giang",
                "X. Triệu Thành"
             ],
             "codeErp":"3206"
          },
          {
             "n":"H. Hải Lăng",
             "w":[
                "TT. Diên Sanh",
                "X. Hải An",
                "X. Hải Ba",
                "X. Hải Quy",
                "X. Hải Quế",
                "X. Hải Hưng",
                "X. Hải Phú",
                "X. Hải Thượng",
                "X. Hải Dương",
                "X. Hải Định",
                "X. Hải Lâm",
                "X. Hải Phong",
                "X. Hải Trường",
                "X. Hải Sơn",
                "X. Hải Chánh",
                "X. Hải Khê"
             ],
             "codeErp":"3207"
          },
          {
             "n":"H. Cồn Cỏ",
             "w":[
 
             ],
             "codeErp":"3210"
          }
       ],
       "n":"Quảng Trị",
       "codeErp":"32"
    },
    {
       "d":[
          {
             "n":"TP. Pleiku",
             "w":[
                "P. Yên Đỗ",
                "P. Diên Hồng",
                "P. Ia Kring",
                "P. Hội Thương",
                "P. Hội Phú",
                "P. Phù Đổng",
                "P. Hoa Lư",
                "P. Tây Sơn",
                "P. Thống Nhất",
                "P. Đống Đa",
                "P. Trà Bá",
                "P. Thắng Lợi",
                "P. Yên Thế",
                "P. Chi Lăng",
                "X. Biển Hồ",
                "X. Tân Sơn",
                "X. Trà Đa",
                "X. Chư Á",
                "X. An Phú",
                "X. Diên Phú",
                "X. Ia Kênh",
                "X. Gào"
             ],
             "codeErp":"3801"
          },
          {
             "n":"TX. An Khê",
             "w":[
                "P. An Bình",
                "P. Tây Sơn",
                "P. An Phú",
                "P. An Tân",
                "X. Tú An",
                "X. Xuân An",
                "X. Cửu An",
                "P. An Phước",
                "X. Song An",
                "P. Ngô Mây",
                "X. Thành An"
             ],
             "codeErp":"3805"
          },
          {
             "n":"TX. Ayun Pa",
             "w":[
                "P. Cheo Reo",
                "P. Hòa Bình",
                "P. Đoàn Kết",
                "P. Sông Bờ",
                "X. Ia RBol",
                "X. Chư Băh",
                "X. Ia RTô",
                "X. Ia Sao"
             ],
             "codeErp":"3810"
          },
          {
             "n":"H. KBang",
             "w":[
                "TT. KBang",
                "X. Kon Pne",
                "X. Đăk Roong",
                "X. Sơn Lang",
                "X. KRong",
                "X. Sơ Pai",
                "X. Lơ Ku",
                "X. Đông",
                "X. Đak SMar",
                "X. Nghĩa An",
                "X. Tơ Tung",
                "X. Kông Lơng Khơng",
                "X. Kông Pla",
                "X. Đăk HLơ"
             ],
             "codeErp":"3804"
          },
          {
             "n":"H. Đăk Đoa",
             "w":[
                "TT. Đăk Đoa",
                "X. Hà Đông",
                "X. Đăk Sơmei",
                "X. Đăk Krong",
                "X. Hải Yang",
                "X. Kon Gang",
                "X. Hà Bầu",
                "X. Nam Yang",
                "X. K' Dang",
                "X. H' Neng",
                "X. Tân Bình",
                "X. Glar",
                "X. A Dơk",
                "X. Trang",
                "X. HNol",
                "X. Ia Pết",
                "X. Ia Băng"
             ],
             "codeErp":"3813"
          },
          {
             "n":"H. Chư Păh",
             "w":[
                "TT. Phú Hòa",
                "X. Hà Tây",
                "X. Ia Khươl",
                "X. Ia Phí",
                "TT. Ia Ly",
                "X. Ia Mơ Nông",
                "X. Ia Kreng",
                "X. Đăk Tơ Ver",
                "X. Hòa Phú",
                "X. Chư Đăng Ya",
                "X. Ia Ka",
                "X. Ia Nhin",
                "X. Nghĩa Hòa",
                "X. Nghĩa Hưng"
             ],
             "codeErp":"3802"
          },
          {
             "n":"H. Ia Grai",
             "w":[
                "TT. Ia Kha",
                "X. Ia Sao",
                "X. Ia Yok",
                "X. Ia Hrung",
                "X. Ia Bă",
                "X. Ia Khai",
                "X. Ia KRai",
                "X. Ia Grăng",
                "X. Ia Tô",
                "X. Ia O",
                "X. Ia Dêr",
                "X. Ia Chia",
                "X. Ia Pếch"
             ],
             "codeErp":"3812"
          },
          {
             "n":"H. Mang Yang",
             "w":[
                "TT. Kon Dơng",
                "X. Ayun",
                "X. Đak Jơ Ta",
                "X. Đak Ta Ley",
                "X. Hra",
                "X. Đăk Yă",
                "X. Đăk Djrăng",
                "X. Lơ Pang",
                "X. Kon Thụp",
                "X. Đê Ar",
                "X. Kon Chiêng",
                "X. Đăk Trôi"
             ],
             "codeErp":"3803"
          },
          {
             "n":"H. Kông Chro",
             "w":[
                "TT. Kông Chro",
                "X. Chư Krêy",
                "X. An Trung",
                "X. Kông Yang",
                "X. Đăk Tơ Pang",
                "X. SRó",
                "X. Đắk Kơ Ning",
                "X. Đăk Song",
                "X. Đăk Pling",
                "X. Yang Trung",
                "X. Đăk Pơ Pho",
                "X. Ya Ma",
                "X. Chơ Long",
                "X. Yang Nam"
             ],
             "codeErp":"3806"
          },
          {
             "n":"H. Đức Cơ",
             "w":[
                "TT. Chư Ty",
                "X. Ia Dơk",
                "X. Ia Krêl",
                "X. Ia Din",
                "X. Ia Kla",
                "X. Ia Dom",
                "X. Ia Lang",
                "X. Ia Kriêng",
                "X. Ia Pnôn",
                "X. Ia Nan"
             ],
             "codeErp":"3807"
          },
          {
             "n":"H. Chư Prông",
             "w":[
                "TT. Chư Prông",
                "X. Ia Kly",
                "X. Bình Giáo",
                "X. Ia Drăng",
                "X. Thăng Hưng",
                "X. Bàu Cạn",
                "X. Ia Phìn",
                "X. Ia Băng",
                "X. Ia Tôr",
                "X. Ia Boòng",
                "X. Ia O",
                "X. Ia Púch",
                "X. Ia Me",
                "X. Ia Vê",
                "X. Ia Bang",
                "X. Ia Pia",
                "X. Ia Ga",
                "X. Ia Lâu",
                "X. Ia Piơr",
                "X. Ia Mơ"
             ],
             "codeErp":"3808"
          },
          {
             "n":"H. Chư Sê",
             "w":[
                "TT. Chư Sê",
                "X. Ia Tiêm",
                "X. Chư Pơng",
                "X. Bar Măih",
                "X. Bờ Ngoong",
                "X. Ia Glai",
                "X. AL Bá",
                "X. Kông HTok",
                "X. AYun",
                "X. Ia HLốp",
                "X. Ia Blang",
                "X. Dun",
                "X. Ia Pal",
                "X. H Bông",
                "X. Ia Ko"
             ],
             "codeErp":"3809"
          },
          {
             "n":"H. Đăk Pơ",
             "w":[
                "X. Hà Tam",
                "X. An Thành",
                "TT. Đak Pơ",
                "X. Yang Bắc",
                "X. Cư An",
                "X. Tân An",
                "X. Phú An",
                "X. Ya Hội"
             ],
             "codeErp":"3815"
          },
          {
             "n":"H. Ia Pa",
             "w":[
                "X. Pờ Tó",
                "X. Chư Răng",
                "X. Ia KDăm",
                "X. Kim Tân",
                "X. Chư Mố",
                "X. Ia Tul",
                "X. Ia Ma Rơn",
                "X. Ia Broăi",
                "X. Ia Trok"
             ],
             "codeErp":"3814"
          },
          {
             "n":"H. Krông Pa",
             "w":[
                "TT. Phú Túc",
                "X. Ia RSai",
                "X. Ia RSươm",
                "X. Chư Gu",
                "X. Đất Bằng",
                "X. Ia Mláh",
                "X. Chư Drăng",
                "X. Phú Cần",
                "X. Ia HDreh",
                "X. Ia RMok",
                "X. Chư Ngọc",
                "X. Uar",
                "X. Chư Rcăm",
                "X. Krông Năng"
             ],
             "codeErp":"3811"
          },
          {
             "n":"H. Phú Thiện",
             "w":[
                "TT. Phú Thiện",
                "X. Chư A Thai",
                "X. Ayun Hạ",
                "X. Ia Ake",
                "X. Ia Sol",
                "X. Ia Piar",
                "X. Ia Peng",
                "X. Chrôh Pơnan",
                "X. Ia Hiao",
                "X. Ia Yeng"
             ],
             "codeErp":"3816"
          },
          {
             "n":"H. Chư Pưh",
             "w":[
                "TT. Nhơn Hoà",
                "X. Ia Hrú",
                "X. Ia Rong",
                "X. Ia Dreng",
                "X. Ia Hla",
                "X. Chư Don",
                "X. Ia Phang",
                "X. Ia Le",
                "X. Ia BLứ"
             ],
             "codeErp":"3817"
          }
       ],
       "n":"Gia Lai",
       "codeErp":"38"
    },
    {
       "d":[
          {
             "n":"TP. Quảng Ngãi",
             "w":[
                "P. Lê Hồng Phong",
                "P. Trần Phú",
                "P. Quảng Phú",
                "P. Nghĩa Chánh",
                "P. Trần Hưng Đạo",
                "P. Nguyễn Nghiêm",
                "P. Nghĩa Lộ",
                "P. Chánh Lộ",
                "X. Nghĩa Dũng",
                "X. Nghĩa Dõng",
                "P. Trương Quang Trọng",
                "X. Tịnh Hòa",
                "X. Tịnh Kỳ",
                "X. Tịnh Thiện",
                "X. Tịnh Ấn Đông",
                "X. Tịnh Châu",
                "X. Tịnh Khê",
                "X. Tịnh Long",
                "X. Tịnh Ấn Tây",
                "X. Tịnh An",
                "X. Nghĩa Phú",
                "X. Nghĩa Hà",
                "X. Nghĩa An"
             ],
             "codeErp":"3503"
          },
          {
             "n":"H. Bình Sơn",
             "w":[
                "TT. Châu Ổ",
                "X. Bình Thuận",
                "X. Bình Thạnh",
                "X. Bình Đông",
                "X. Bình Chánh",
                "X. Bình Nguyên",
                "X. Bình Khương",
                "X. Bình Trị",
                "X. Bình An",
                "X. Bình Hải",
                "X. Bình Dương",
                "X. Bình Phước",
                "X. Bình Hòa",
                "X. Bình Trung",
                "X. Bình Minh",
                "X. Bình Long",
                "X. Bình Thanh ",
                "X. Bình Chương",
                "X. Bình Hiệp",
                "X. Bình Mỹ",
                "X. Bình Tân Phú",
                "X. Bình Châu"
             ],
             "codeErp":"3501"
          },
          {
             "n":"H. Trà Bồng",
             "w":[
                "TT. Trà Xuân",
                "X. Trà Giang",
                "X. Trà Thủy",
                "X. Trà Hiệp",
                "X. Trà Bình",
                "X. Trà Phú",
                "X. Trà Lâm",
                "X. Trà Tân",
                "X. Trà Sơn",
                "X. Trà Bùi",
                "X. Trà Thanh",
                "X. Sơn Trà",
                "X. Trà Phong",
                "X. Hương Trà",
                "X. Trà Xinh",
                "X. Trà Tây"
             ],
             "codeErp":"3512"
          },
          {
             "n":"H. Sơn Tịnh",
             "w":[
                "X. Tịnh Thọ",
                "X. Tịnh Trà",
                "X. Tịnh Phong",
                "X. Tịnh Hiệp",
                "X. Tịnh Bình",
                "X. Tịnh Đông",
                "X. Tịnh Bắc",
                "X. Tịnh Sơn",
                "X. Tịnh Hà",
                "X. Tịnh Giang",
                "X. Tịnh Minh"
             ],
             "codeErp":"3502"
          },
          {
             "n":"H. Tư Nghĩa",
             "w":[
                "TT. La Hà",
                "TT. Sông Vệ",
                "X. Nghĩa Lâm",
                "X. Nghĩa Thắng",
                "X. Nghĩa Thuận",
                "X. Nghĩa Kỳ",
                "X. Nghĩa Sơn",
                "X. Nghĩa Hòa",
                "X. Nghĩa Điền",
                "X. Nghĩa Thương",
                "X. Nghĩa Trung",
                "X. Nghĩa Hiệp",
                "X. Nghĩa Phương",
                "X. Nghĩa Mỹ"
             ],
             "codeErp":"3504"
          },
          {
             "n":"H. Sơn Hà",
             "w":[
                "TT. Di Lăng",
                "X. Sơn Hạ",
                "X. Sơn Thành",
                "X. Sơn Nham",
                "X. Sơn Bao",
                "X. Sơn Linh",
                "X. Sơn Giang",
                "X. Sơn Trung",
                "X. Sơn Thượng",
                "X. Sơn Cao",
                "X. Sơn Hải",
                "X. Sơn Thủy",
                "X. Sơn Kỳ",
                "X. Sơn Ba"
             ],
             "codeErp":"3510"
          },
          {
             "n":"H. Sơn Tây",
             "w":[
                "X. Sơn Bua",
                "X. Sơn Mùa",
                "X. Sơn Liên",
                "X. Sơn Tân",
                "X. Sơn Màu",
                "X. Sơn Dung",
                "X. Sơn Long",
                "X. Sơn Tinh",
                "X. Sơn Lập"
             ],
             "codeErp":"3511"
          },
          {
             "n":"H. Minh Long",
             "w":[
                "X. Long Sơn",
                "X. Long Mai",
                "X. Thanh An",
                "X. Long Môn",
                "X. Long Hiệp"
             ],
             "codeErp":"3509"
          },
          {
             "n":"H. Nghĩa Hành",
             "w":[
                "TT. Chợ Chùa",
                "X. Hành Thuận",
                "X. Hành Dũng",
                "X. Hành Trung",
                "X. Hành Nhân",
                "X. Hành Đức",
                "X. Hành Minh",
                "X. Hành Phước",
                "X. Hành Thiện",
                "X. Hành Thịnh",
                "X. Hành Tín Tây",
                "X. Hành Tín  Đông"
             ],
             "codeErp":"3505"
          },
          {
             "n":"H. Mộ Đức",
             "w":[
                "TT. Mộ Đức",
                "X. Đức Lợi",
                "X. Đức Thắng",
                "X. Đức Nhuận",
                "X. Đức Chánh",
                "X. Đức Hiệp",
                "X. Đức Minh",
                "X. Đức Thạnh",
                "X. Đức Hòa",
                "X. Đức Tân",
                "X. Đức Phú",
                "X. Đức Phong",
                "X. Đức Lân"
             ],
             "codeErp":"3506"
          },
          {
             "n":"TX. Đức Phổ",
             "w":[
                "P. Nguyễn Nghiêm",
                "X. Phổ An",
                "X. Phổ Phong",
                "X. Phổ Thuận",
                "P. Phổ Văn",
                "P. Phổ Quang",
                "X. Phổ Nhơn",
                "P. Phổ Ninh",
                "P. Phổ Minh",
                "P. Phổ Vinh",
                "P. Phổ Hòa",
                "X. Phổ Cường",
                "X. Phổ Khánh",
                "P. Phổ Thạnh",
                "X. Phổ Châu"
             ],
             "codeErp":"3507"
          },
          {
             "n":"H. Ba Tơ",
             "w":[
                "TT. Ba Tơ",
                "X. Ba Điền",
                "X. Ba Vinh",
                "X. Ba Thành",
                "X. Ba Động",
                "X. Ba Dinh",
                "X. Ba Giang",
                "X. Ba Liên",
                "X. Ba Ngạc",
                "X. Ba Khâm",
                "X. Ba Cung",
                "X. Ba Tiêu",
                "X. Ba Trang",
                "X. Ba Tô",
                "X. Ba Bích",
                "X. Ba Vì",
                "X. Ba Lế",
                "X. Ba Nam",
                "X. Ba Xa"
             ],
             "codeErp":"3508"
          },
          {
             "n":"H. Lý Sơn",
             "w":[
 
             ],
             "codeErp":"3514"
          }
       ],
       "n":"Quảng Ngãi",
       "codeErp":"35"
    },
    {
       "d":[
          {
             "n":"TP. Phan Rang-Tháp Chàm",
             "w":[
                "P. Đô Vinh",
                "P. Phước Mỹ",
                "P. Bảo An",
                "P. Phủ Hà",
                "P. Thanh Sơn",
                "P. Mỹ Hương",
                "P. Tấn Tài",
                "P. Kinh Dinh",
                "P. Đạo Long",
                "P. Đài Sơn",
                "P. Đông Hải",
                "P. Mỹ Đông",
                "X. Thành Hải",
                "P. Văn Hải",
                "P. Mỹ Bình",
                "P. Mỹ Hải"
             ],
             "codeErp":"4501"
          },
          {
             "n":"H. Bác Ái",
             "w":[
                "X. Phước Bình",
                "X. Phước Hòa",
                "X. Phước Tân",
                "X. Phước Tiến",
                "X. Phước Thắng",
                "X. Phước Thành",
                "X. Phước Đại",
                "X. Phước Chính",
                "X. Phước Trung"
             ],
             "codeErp":"4505"
          },
          {
             "n":"H. Ninh Sơn",
             "w":[
                "TT. Tân Sơn",
                "X. Lâm Sơn",
                "X. Lương Sơn",
                "X. Quảng Sơn",
                "X. Mỹ Sơn",
                "X. Hòa Sơn",
                "X. Ma Nới",
                "X. Nhơn Sơn"
             ],
             "codeErp":"4502"
          },
          {
             "n":"H. Ninh Hải",
             "w":[
                "TT. Khánh Hải",
                "X. Vĩnh Hải",
                "X. Phương Hải",
                "X. Tân Hải",
                "X. Xuân Hải",
                "X. Hộ Hải",
                "X. Tri Hải",
                "X. Nhơn Hải",
                "X. Thanh Hải"
             ],
             "codeErp":"4503"
          },
          {
             "n":"H. Ninh Phước",
             "w":[
                "TT. Phước Dân",
                "X. Phước Sơn",
                "X. Phước Thái",
                "X. Phước Hậu",
                "X. Phước Thuận",
                "X. An Hải",
                "X. Phước Hữu",
                "X. Phước Hải",
                "X. Phước Vinh"
             ],
             "codeErp":"4504"
          },
          {
             "n":"H. Thuận Bắc",
             "w":[
                "X. Phước Chiến",
                "X. Công Hải",
                "X. Phước Kháng",
                "X. Lợi Hải",
                "X. Bắc Sơn",
                "X. Bắc Phong"
             ],
             "codeErp":"4506"
          },
          {
             "n":"H. Thuận Nam",
             "w":[
                "X. Phước Hà",
                "X. Phước Nam",
                "X. Phước Ninh",
                "X. Nhị Hà",
                "X. Phước Dinh",
                "X. Phước Minh",
                "X. Phước Diêm",
                "X. Cà Ná"
             ],
             "codeErp":"4507"
          }
       ],
       "n":"Ninh Thuận",
       "codeErp":"45"
    },
    {
       "d":[
          {
             "n":"TP. Phan Thiết",
             "w":[
                "P. Mũi Né",
                "P. Hàm Tiến",
                "P. Phú Hài",
                "P. Phú Thủy",
                "P. Phú Tài",
                "P. Phú Trinh",
                "P. Xuân An",
                "P. Thanh Hải",
                "P. Bình Hưng",
                "P. Đức Nghĩa",
                "P. Lạc Đạo",
                "P. Đức Thắng",
                "P. Hưng Long",
                "P. Đức Long",
                "X. Thiện Nghiệp",
                "X. Phong Nẫm",
                "X. Tiến Lợi",
                "X. Tiến Thành"
             ],
             "codeErp":"4701"
          },
          {
             "n":"TX. La Gi",
             "w":[
                "P. Phước Hội",
                "P. Phước Lộc",
                "P. Tân Thiện",
                "P. Tân An",
                "P. Bình Tân",
                "X. Tân Hải",
                "X. Tân Tiến",
                "X. Tân Bình",
                "X. Tân Phước"
             ],
             "codeErp":"4710"
          },
          {
             "n":"H. Tuy Phong",
             "w":[
                "TT. Liên Hương",
                "TT. Phan Rí Cửa",
                "X. Phan Dũng",
                "X. Phong Phú",
                "X. Vĩnh Hảo",
                "X. Vĩnh Tân",
                "X. Phú Lạc",
                "X. Phước Thể",
                "X. Hòa Minh",
                "X. Chí Công",
                "X. Bình Thạnh"
             ],
             "codeErp":"4702"
          },
          {
             "n":"H. Bắc Bình",
             "w":[
                "TT. Chợ Lầu",
                "X. Phan Sơn",
                "X. Phan Lâm",
                "X. Bình An",
                "X. Phan Điền",
                "X. Hải Ninh",
                "X. Sông Lũy",
                "X. Phan Tiến",
                "X. Sông Bình",
                "TT. Lương Sơn",
                "X. Phan Hòa",
                "X. Phan Thanh",
                "X. Hồng Thái",
                "X. Phan Hiệp",
                "X. Bình Tân",
                "X. Phan Rí Thành",
                "X. Hòa Thắng",
                "X. Hồng Phong"
             ],
             "codeErp":"4703"
          },
          {
             "n":"H. Hàm Thuận Bắc",
             "w":[
                "TT. Ma Lâm",
                "TT. Phú Long",
                "X. La Dạ",
                "X. Đông Tiến",
                "X. Thuận Hòa",
                "X. Đông Giang",
                "X. Hàm Phú",
                "X. Hồng Liêm",
                "X. Thuận Minh",
                "X. Hồng Sơn",
                "X. Hàm Trí",
                "X. Hàm Đức",
                "X. Hàm Liêm",
                "X. Hàm Chính",
                "X. Hàm Hiệp",
                "X. Hàm Thắng",
                "X. Đa Mi"
             ],
             "codeErp":"4704"
          },
          {
             "n":"H. Hàm Thuận Nam",
             "w":[
                "TT. Thuận Nam",
                "X. Mỹ Thạnh",
                "X. Hàm Cần",
                "X. Mương Mán",
                "X. Hàm Thạnh",
                "X. Hàm Kiệm",
                "X. Hàm Cường",
                "X. Hàm Mỹ",
                "X. Tân Lập",
                "X. Hàm Minh",
                "X. Thuận Quí",
                "X. Tân Thuận",
                "X. Tân Thành"
             ],
             "codeErp":"4705"
          },
          {
             "n":"H. Tánh Linh",
             "w":[
                "TT. Lạc Tánh",
                "X. Bắc Ruộng",
                "X. Nghị Đức",
                "X. La Ngâu",
                "X. Huy Khiêm",
                "X. Măng Tố",
                "X. Đức Phú",
                "X. Đồng Kho",
                "X. Gia An",
                "X. Đức Bình",
                "X. Gia Huynh",
                "X. Đức Thuận",
                "X. Suối Kiết"
             ],
             "codeErp":"4708"
          },
          {
             "n":"H. Đức Linh",
             "w":[
                "TT. Võ Xu",
                "TT. Đức Tài",
                "X. Đa Kai",
                "X. Sùng Nhơn",
                "X. Mê Pu",
                "X. Nam Chính",
                "X. Đức Hạnh",
                "X. Đức Tín",
                "X. Vũ Hoà",
                "X. Tân Hà",
                "X. Đông Hà",
                "X. Trà Tân"
             ],
             "codeErp":"4707"
          },
          {
             "n":"H. Hàm Tân",
             "w":[
                "TT. Tân Minh",
                "TT. Tân Nghĩa",
                "X. Sông Phan",
                "X. Tân Phúc",
                "X. Tân Đức",
                "X. Tân Thắng",
                "X. Thắng Hải",
                "X. Tân Hà",
                "X. Tân Xuân",
                "X. Sơn Mỹ"
             ],
             "codeErp":"4706"
          },
          {
             "n":"H. đảo Phú Quý",
             "w":[
                "X. Ngũ Phụng",
                "X. Long Hải",
                "X. Tam Thanh"
             ],
             "codeErp":"4709"
          }
       ],
       "n":"Bình Thuận",
       "codeErp":"47"
    },
    {
       "d":[
          {
             "n":"TP. Buôn Ma Thuột",
             "w":[
                "P. Tân Lập",
                "P. Tân Hòa",
                "P. Tân An",
                "P. Thống Nhất",
                "P. Thành Nhất",
                "P. Thắng Lợi",
                "P. Tân Lợi",
                "P. Thành Công",
                "P. Tân Thành",
                "P. Tân Tiến",
                "P. Tự An",
                "P. Ea Tam",
                "P. Khánh Xuân",
                "X. Hòa Thuận",
                "X. Cư ÊBur",
                "X. Ea Tu",
                "X. Hòa Thắng",
                "X. Ea Kao",
                "X. Hòa Phú",
                "X. Hòa Khánh",
                "X. Hòa Xuân"
             ],
             "codeErp":"4001"
          },
          {
             "n":"TX. Buôn Hồ",
             "w":[
                "P. An Lạc",
                "P. An Bình",
                "P. Thiện An",
                "P. Đạt Hiếu",
                "P. Đoàn Kết",
                "X. Ea Blang",
                "X. Ea Drông",
                "P. Thống Nhất",
                "P. Bình Tân",
                "X. Ea Siên",
                "X. Bình Thuận",
                "X. Cư Bao"
             ],
             "codeErp":"4015"
          },
          {
             "n":"H. Ea H'leo",
             "w":[
                "TT. Ea Drăng",
                "X. Ea H'leo",
                "X. Ea Sol",
                "X. Ea Ral",
                "X. Ea Wy",
                "X. Cư A Mung",
                "X. Cư Mốt",
                "X. Ea Hiao",
                "X. Ea Khal",
                "X. Dliê Yang",
                "X. Ea Tir",
                "X. Ea Nam"
             ],
             "codeErp":"4002"
          },
          {
             "n":"H. Ea Súp",
             "w":[
                "TT. Ea Súp",
                "X. Ia Lốp",
                "X. Ia JLơi",
                "X. Ea Rốk",
                "X. Ya Tờ Mốt",
                "X. Ia RVê",
                "X. Ea Lê",
                "X. Cư KBang",
                "X. Ea Bung",
                "X. Cư M'Lan"
             ],
             "codeErp":"4005"
          },
          {
             "n":"H. Buôn Đôn",
             "w":[
                "X. Krông Na",
                "X. Ea Huar",
                "X. Ea Wer",
                "X. Tân Hoà",
                "X. Cuôr KNia",
                "X. Ea Bar",
                "X. Ea Nuôl"
             ],
             "codeErp":"4013"
          },
          {
             "n":"H. Cư M'gar",
             "w":[
                "TT. Ea Pốk",
                "TT. Quảng Phú",
                "X. Quảng Tiến",
                "X. Ea Kuêh",
                "X. Ea Kiết",
                "X. Ea Tar",
                "X. Cư Dliê M'nông",
                "X. Ea H'đinh",
                "X. Ea Tul",
                "X. Ea KPam",
                "X. Ea M'DRóh",
                "X. Quảng Hiệp",
                "X. Cư M'gar",
                "X. Ea D'Rơng",
                "X. Ea M'nang",
                "X. Cư Suê",
                "X. Cuor Đăng"
             ],
             "codeErp":"4006"
          },
          {
             "n":"H. Krông Búk",
             "w":[
                "X. Cư Né",
                "X. Chư KBô",
                "X. Cư Pơng",
                "X. Ea Sin",
                "X. Pơng Drang",
                "X. Tân Lập",
                "X. Ea Ngai"
             ],
             "codeErp":"4003"
          },
          {
             "n":"H. Krông Năng",
             "w":[
                "TT. Krông Năng",
                "X. ĐLiê Ya",
                "X. Ea Tóh",
                "X. Ea Tam",
                "X. Phú Lộc",
                "X. Tam Giang",
                "X. Ea Puk",
                "X. Ea Dăh",
                "X. Ea Hồ",
                "X. Phú Xuân",
                "X. Cư Klông",
                "X. Ea Tân"
             ],
             "codeErp":"4004"
          },
          {
             "n":"H. Ea Kar",
             "w":[
                "TT. Ea Kar",
                "TT. Ea Knốp",
                "X. Ea Sô",
                "X. Ea Sar",
                "X. Xuân Phú",
                "X. Cư Huê",
                "X. Ea Tih",
                "X. Ea Đar",
                "X. Ea Kmút",
                "X. Cư Ni",
                "X. Ea Păl",
                "X. Cư Prông",
                "X. Ea Ô",
                "X. Cư ELang",
                "X. Cư Bông",
                "X. Cư Jang"
             ],
             "codeErp":"4008"
          },
          {
             "n":"H. M'Đrắk",
             "w":[
                "TT. M'Đrắk",
                "X. Cư Prao",
                "X. Ea Pil",
                "X. Ea Lai",
                "X. Ea H'MLay",
                "X. Krông Jing",
                "X. Ea M' Doal",
                "X. Ea Riêng",
                "X. Cư M'ta",
                "X. Cư K Róa",
                "X. Krông Á",
                "X. Cư San",
                "X. Ea Trang"
             ],
             "codeErp":"4009"
          },
          {
             "n":"H. Krông Bông",
             "w":[
                "TT. Krông Kmar",
                "X. Dang Kang",
                "X. Cư KTy",
                "X. Hòa Thành",
                "X. Hòa Tân",
                "X. Hòa Phong",
                "X. Hòa Lễ",
                "X. Yang Reh",
                "X. Ea Trul",
                "X. Khuê Ngọc Điền",
                "X. Cư Pui",
                "X. Hòa Sơn",
                "X. Cư Drăm",
                "X. Yang Mao"
             ],
             "codeErp":"4011"
          },
          {
             "n":"H. Krông Pắc",
             "w":[
                "TT. Phước An",
                "X. KRông Búk",
                "X. Ea Kly",
                "X. Ea Kênh",
                "X. Ea Phê",
                "X. Ea KNuec",
                "X. Ea Yông",
                "X. Hòa An",
                "X. Ea Kuăng",
                "X. Hòa Đông",
                "X. Ea Hiu",
                "X. Hòa Tiến",
                "X. Tân Tiến",
                "X. Vụ Bổn",
                "X. Ea Uy",
                "X. Ea Yiêng"
             ],
             "codeErp":"4007"
          },
          {
             "n":"H. Krông A Na",
             "w":[
                "TT. Buôn Trấp",
                "X. Dray Sáp",
                "X. Ea Na",
                "X. Ea Bông",
                "X. Băng A Drênh",
                "X. Dur KMăl",
                "X. Bình Hòa",
                "X. Quảng Điền"
             ],
             "codeErp":"4010"
          },
          {
             "n":"H. Lắk",
             "w":[
                "TT. Liên Sơn",
                "X. Yang Tao",
                "X. Bông Krang",
                "X. Đắk Liêng",
                "X. Buôn Triết",
                "X. Buôn Tría",
                "X. Đắk Phơi",
                "X. Đắk Nuê",
                "X. Krông Nô",
                "X. Nam Ka",
                "X. Ea R'Bin"
             ],
             "codeErp":"4012"
          },
          {
             "n":"H. Cư Kuin",
             "w":[
                "X. Ea Ning",
                "X. Cư Ê Wi",
                "X. Ea Ktur",
                "X. Ea Tiêu",
                "X. Ea BHốk",
                "X. Ea Hu",
                "X. Dray Bhăng",
                "X. Hòa Hiệp"
             ],
             "codeErp":"4014"
          }
       ],
       "n":"Đăk Lăk",
       "codeErp":"40"
    },
    {
       "d":[
          {
             "n":"TP. Gia Nghĩa",
             "w":[
                "P. Nghĩa Đức",
                "P. Nghĩa Thành",
                "P. Nghĩa Phú",
                "P. Nghĩa Tân",
                "P. Nghĩa Trung",
                "X. Đăk R'Moan",
                "P. Quảng Thành",
                "X. Đắk Nia"
             ],
             "codeErp":"6301"
          },
          {
             "n":"H. Đăk Glong",
             "w":[
                "X. Quảng Sơn",
                "X. Quảng Hoà",
                "X. Đắk Ha",
                "X. Đắk R'Măng",
                "X. Quảng Khê",
                "X. Đắk Plao",
                "X. Đắk Som"
             ],
             "codeErp":"6307"
          },
          {
             "n":"H. Cư Jút",
             "w":[
                "TT. Ea T'Ling",
                "X. Đắk Wil",
                "X. Ea Pô",
                "X. Nam Dong",
                "X. Đắk DRông",
                "X. Tâm Thắng",
                "X. Cư Knia",
                "X. Trúc Sơn"
             ],
             "codeErp":"6304"
          },
          {
             "n":"H. Đắk Mil",
             "w":[
                "TT. Đắk Mil",
                "X.  Đắk Lao",
                "X. Đắk R'La",
                "X. Đắk Gằn",
                "X. Đức Mạnh",
                "X. Đắk N'Drót",
                "X. Long Sơn",
                "X. Đắk Sắk",
                "X. Thuận An",
                "X. Đức Minh"
             ],
             "codeErp":"6303"
          },
          {
             "n":"H. Krông Nô",
             "w":[
                "TT. Đắk Mâm",
                "X. Đắk Sôr",
                "X. Nam Xuân",
                "X. Buôn Choah",
                "X. Nam Đà",
                "X. Tân Thành",
                "X. Đắk Drô",
                "X. Nâm Nung",
                "X. Đức Xuyên",
                "X. Đắk Nang",
                "X. Quảng Phú",
                "X. Nâm N'Đir"
             ],
             "codeErp":"6306"
          },
          {
             "n":"H. Đắk Song",
             "w":[
                "TT. Đức An",
                "X. Đắk Môl",
                "X. Đắk Hòa",
                "X. Nam Bình",
                "X. Thuận Hà",
                "X. Thuận Hạnh",
                "X. Đắk N'Dung",
                "X. Nâm N'Jang",
                "X. Trường Xuân"
             ],
             "codeErp":"6305"
          },
          {
             "n":"H. Đắk R'Lấp",
             "w":[
                "TT. Kiến Đức",
                "X. Quảng Tín",
                "X. Đắk Wer",
                "X. Nhân Cơ",
                "X. Kiến Thành",
                "X. Nghĩa Thắng",
                "X. Đạo Nghĩa",
                "X. Đắk Sin",
                "X. Hưng Bình",
                "X. Đắk Ru",
                "X. Nhân Đạo"
             ],
             "codeErp":"6302"
          },
          {
             "n":"H. Tuy Đức",
             "w":[
                "X. Quảng Trực",
                "X. Đắk Búk So",
                "X. Quảng Tâm",
                "X. Đắk R'Tíh",
                "X. Đắk Ngo",
                "X. Quảng Tân"
             ],
             "codeErp":"6308"
          }
       ],
       "n":"Đăk Nông",
       "codeErp":"63"
    },
    {
       "d":[
          {
             "n":"TP. Tây Ninh",
             "w":[
                "P. 1",
                "P. 3",
                "P. 4",
                "P. Hiệp Ninh",
                "P. 2",
                "X. Thạnh Tân",
                "X. Tân Bình",
                "X. Bình Minh",
                "P. Ninh Sơn",
                "P. Ninh Thạnh"
             ],
             "codeErp":"4601"
          },
          {
             "n":"H. Tân Biên",
             "w":[
                "TT. Tân Biên",
                "X. Tân Lập",
                "X. Thạnh Bắc",
                "X. Tân Bình",
                "X. Thạnh Bình",
                "X. Thạnh Tây",
                "X. Hòa Hiệp",
                "X. Tân Phong",
                "X. Mỏ Công",
                "X. Trà Vong"
             ],
             "codeErp":"4602"
          },
          {
             "n":"H. Tân Châu",
             "w":[
                "TT. Tân Châu",
                "X. Tân Hà",
                "X. Tân Đông",
                "X. Tân Hội",
                "X. Tân Hòa",
                "X. Suối Ngô",
                "X. Suối Dây",
                "X. Tân Hiệp",
                "X. Thạnh Đông",
                "X. Tân Thành",
                "X. Tân Phú",
                "X. Tân Hưng"
             ],
             "codeErp":"4603"
          },
          {
             "n":"H. Dương Minh Châu",
             "w":[
                "TT. Dương Minh Châu",
                "X. Suối Đá",
                "X. Phan",
                "X. Phước Ninh",
                "X. Phước Minh",
                "X. Bàu Năng",
                "X. Chà Là",
                "X. Cầu Khởi",
                "X. Bến Củi",
                "X. Lộc Ninh",
                "X. Truông Mít"
             ],
             "codeErp":"4604"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Châu Thành",
                "X. Hảo Đước",
                "X. Phước Vinh",
                "X. Đồng Khởi",
                "X. Thái Bình",
                "X. An Cơ",
                "X. Biên Giới",
                "X. Hòa Thạnh",
                "X. Trí Bình",
                "X. Hòa Hội",
                "X. An Bình",
                "X. Thanh Điền",
                "X. Thành Long",
                "X. Ninh Điền",
                "X. Long Vĩnh"
             ],
             "codeErp":"4605"
          },
          {
             "n":"TX. Hòa Thành",
             "w":[
                "P. Long Hoa",
                "P. Hiệp Tân",
                "P. Long Thành Bắc",
                "X. Trường Hòa",
                "X. Trường Đông",
                "P. Long Thành Trung",
                "X. Trường Tây",
                "X. Long Thành Nam"
             ],
             "codeErp":"4606"
          },
          {
             "n":"H. Gò Dầu",
             "w":[
                "TT. Gò Dầu",
                "X. Thạnh Đức",
                "X. Cẩm Giang",
                "X. Hiệp Thạnh",
                "X. Bàu Đồn",
                "X. Phước Thạnh",
                "X. Phước Đông",
                "X. Phước Trạch",
                "X. Thanh Phước"
             ],
             "codeErp":"4608"
          },
          {
             "n":"H. Bến Cầu",
             "w":[
                "TT. Bến Cầu",
                "X. Long Chữ",
                "X. Long Phước",
                "X. Long Giang",
                "X. Tiên Thuận",
                "X. Long Khánh",
                "X. Lợi Thuận",
                "X. Long Thuận",
                "X. An Thạnh"
             ],
             "codeErp":"4607"
          },
          {
             "n":"TX. Trảng Bàng",
             "w":[
                "P. Trảng Bàng",
                "X. Đôn Thuận",
                "X. Hưng Thuận",
                "P. Lộc Hưng",
                "P. Gia Lộc",
                "P. Gia Bình",
                "X. Phước Bình",
                "P. An Tịnh",
                "P. An Hòa",
                "X. Phước Chỉ"
             ],
             "codeErp":"4609"
          }
       ],
       "n":"Tây Ninh",
       "codeErp":"46"
    },
    {
       "d":[
          {
             "n":"TP. Thủ Dầu Một",
             "w":[
                "P. Hiệp Thành",
                "P. Phú Lợi",
                "P. Phú Cường",
                "P. Phú Hòa",
                "P. Phú Thọ",
                "P. Chánh Nghĩa",
                "P. Định Hoà",
                "P. Hoà Phú",
                "P. Phú Mỹ",
                "P. Phú Tân",
                "P. Tân An",
                "P. Hiệp An",
                "P. Tương Bình Hiệp",
                "P. Chánh Mỹ"
             ],
             "codeErp":"4401"
          },
          {
             "n":"H. Bàu Bàng",
             "w":[
                "X. Trừ Văn Thố",
                "X. Cây Trường II",
                "TT. Lai Uyên",
                "X. Tân Hưng",
                "X. Long Nguyên",
                "X. Hưng Hòa",
                "X. Lai Hưng"
             ],
             "codeErp":"4409"
          },
          {
             "n":"H. Dầu Tiếng",
             "w":[
                "TT. Dầu Tiếng",
                "X. Minh Hoà",
                "X. Minh Thạnh",
                "X. Minh Tân",
                "X. Định An",
                "X. Long Hoà",
                "X. Định Thành",
                "X. Định Hiệp",
                "X. An Lập",
                "X. Long Tân",
                "X. Thanh An",
                "X. Thanh Tuyền"
             ],
             "codeErp":"4407"
          },
          {
             "n":"TX. Bến Cát",
             "w":[
                "P. Mỹ Phước",
                "P. Chánh Phú Hòa",
                "X. An Điền",
                "X. An Tây",
                "P. Thới Hòa",
                "P. Hòa Lợi",
                "P. Tân Định",
                "X. Phú An"
             ],
             "codeErp":"4402"
          },
          {
             "n":"H. Phú Giáo",
             "w":[
                "TT. Phước Vĩnh",
                "X. An Linh",
                "X. Phước Sang",
                "X. An Thái",
                "X. An Long",
                "X. An Bình",
                "X. Tân Hiệp",
                "X. Tam Lập",
                "X. Tân Long",
                "X. Vĩnh Hoà",
                "X. Phước Hoà"
             ],
             "codeErp":"4406"
          },
          {
             "n":"TX. Tân Uyên",
             "w":[
                "P. Uyên Hưng",
                "P. Tân Phước Khánh",
                "P. Vĩnh Tân",
                "P. Hội Nghĩa",
                "P. Tân Hiệp",
                "P. Khánh Bình",
                "P. Phú Chánh",
                "X. Bạch Đằng",
                "P. Tân Vĩnh Hiệp",
                "P. Thạnh Phước",
                "X. Thạnh Hội",
                "P. Thái Hòa"
             ],
             "codeErp":"4403"
          },
          {
             "n":"TP. Dĩ An",
             "w":[
                "P. Dĩ An",
                "P. Tân Bình",
                "P. Tân Đông Hiệp",
                "P. Bình An",
                "P. Bình Thắng",
                "P. Đông Hòa",
                "P. An Bình"
             ],
             "codeErp":"4405"
          },
          {
             "n":"TP. Thuận An",
             "w":[
                "P. An Thạnh",
                "P. Lái Thiêu",
                "P. Bình Chuẩn",
                "P. Thuận Giao",
                "P. An Phú",
                "P. Hưng Định",
                "X. An Sơn",
                "P. Bình Nhâm",
                "P. Bình Hòa",
                "P. Vĩnh Phú"
             ],
             "codeErp":"4404"
          },
          {
             "n":"H. Bắc Tân Uyên",
             "w":[
                "X. Tân Định",
                "X. Bình Mỹ",
                "X. Tân Bình",
                "X. Tân Lập",
                "TT. Tân Thành",
                "X. Đất Cuốc",
                "X. Hiếu Liêm",
                "X. Lạc An",
                "X. Tân Mỹ",
                "X. Thường Tân"
             ],
             "codeErp":"4408"
          }
       ],
       "n":"Bình Dương",
       "codeErp":"44"
    },
    {
       "d":[
          {
             "n":"TX. Phước Long",
             "w":[
                "P. Thác Mơ",
                "P. Long Thủy",
                "P. Phước Bình",
                "P. Long Phước",
                "P. Sơn Giang",
                "X. Long Giang",
                "X. Phước Tín"
             ],
             "codeErp":"4307"
          },
          {
             "n":"TP. Đồng Xoài",
             "w":[
                "P. Tân Phú",
                "P. Tân Đồng",
                "P. Tân Bình",
                "P. Tân Xuân",
                "P. Tân Thiện",
                "X. Tân Thành",
                "P. Tiến Thành",
                "X. Tiến Hưng"
             ],
             "codeErp":"4301"
          },
          {
             "n":"TX. Bình Long",
             "w":[
                "P. Hưng Chiến",
                "P. An Lộc",
                "P. Phú Thịnh",
                "P. Phú Đức",
                "X. Thanh Lương",
                "X. Thanh Phú"
             ],
             "codeErp":"4304"
          },
          {
             "n":"H. Bù Gia Mập",
             "w":[
                "X. Bù Gia Mập",
                "X. Đak Ơ",
                "X. Đức Hạnh",
                "X. Phú Văn",
                "X. Đa Kia",
                "X. Phước Minh",
                "X. Bình Thắng",
                "X. Phú Nghĩa"
             ],
             "codeErp":"4310"
          },
          {
             "n":"H. Lộc Ninh",
             "w":[
                "TT. Lộc Ninh",
                "X. Lộc Hòa",
                "X. Lộc An",
                "X. Lộc Tấn",
                "X. Lộc Thạnh",
                "X. Lộc Hiệp",
                "X. Lộc Thiện",
                "X. Lộc Thuận",
                "X. Lộc Quang",
                "X. Lộc Phú",
                "X. Lộc Thành",
                "X. Lộc Thái",
                "X. Lộc Điền",
                "X. Lộc Hưng",
                "X. Lộc Thịnh",
                "X. Lộc Khánh"
             ],
             "codeErp":"4305"
          },
          {
             "n":"H. Bù Đốp",
             "w":[
                "TT. Thanh Bình",
                "X. Hưng Phước",
                "X. Phước Thiện",
                "X. Thiện Hưng",
                "X. Thanh Hòa",
                "X. Tân Thành",
                "X. Tân Tiến"
             ],
             "codeErp":"4306"
          },
          {
             "n":"H. Hớn Quản",
             "w":[
                "X. Thanh An",
                "X. An Khương",
                "X. An Phú",
                "X. Tân Lợi",
                "X. Tân Hưng",
                "X. Minh Đức",
                "X. Minh Tâm",
                "X. Phước An",
                "X. Thanh Bình",
                "TT. Tân Khai",
                "X. Đồng Nơ",
                "X. Tân Hiệp",
                "X. Tân Quan"
             ],
             "codeErp":"4309"
          },
          {
             "n":"H. Đồng Phú",
             "w":[
                "TT. Tân Phú",
                "X. Thuận Lợi",
                "X. Đồng Tâm",
                "X. Tân Phước",
                "X. Tân Hưng",
                "X. Tân Lợi",
                "X. Tân Lập",
                "X. Tân Hòa",
                "X. Thuận Phú",
                "X. Đồng Tiến",
                "X. Tân Tiến"
             ],
             "codeErp":"4302"
          },
          {
             "n":"H. Bù Đăng",
             "w":[
                "TT. Đức Phong",
                "X. Đường 10",
                "X. Đak Nhau",
                "X. Phú Sơn",
                "X. Thọ Sơn",
                "X. Bình Minh",
                "X. Bom Bo",
                "X. Minh Hưng",
                "X. Đoàn Kết",
                "X. Đồng Nai",
                "X. Đức Liễu",
                "X. Thống Nhất",
                "X. Nghĩa Trung",
                "X. Nghĩa Bình",
                "X. Đăng Hà",
                "X. Phước Sơn"
             ],
             "codeErp":"4308"
          },
          {
             "n":"H. Chơn Thành",
             "w":[
                "TT. Chơn Thành",
                "X. Thành Tâm",
                "X. Minh Lập",
                "X. Quang Minh",
                "X. Minh Hưng",
                "X. Minh Long",
                "X. Minh Thành",
                "X. Nha Bích",
                "X. Minh Thắng"
             ],
             "codeErp":"4303"
          },
          {
             "n":"H. Phú Riềng",
             "w":[
                "X. Long Bình",
                "X. Bình Tân",
                "X. Bình Sơn",
                "X. Long Hưng",
                "X. Phước Tân",
                "X. Bù Nho",
                "X. Long Hà",
                "X. Long Tân",
                "X. Phú Trung",
                "X. Phú Riềng"
             ],
             "codeErp":""
          }
       ],
       "n":"Bình Phước",
       "codeErp":"43"
    },
    {
       "d":[
          {
             "n":"TP. Vũng Tàu",
             "w":[
                "P. 1",
                "P. Thắng Tam",
                "P. 2",
                "P. 3",
                "P. 4",
                "P. 5",
                "P. Thắng Nhì",
                "P. 7",
                "P. Nguyễn An Ninh",
                "P. 8",
                "P. 9",
                "P. Thắng Nhất",
                "P. Rạch Dừa",
                "P. 10",
                "P. 11",
                "P. 12",
                "X. Long Sơn"
             ],
             "codeErp":"5201"
          },
          {
             "n":"TP. Bà Rịa",
             "w":[
                "P. Phước Hưng",
                "P. Phước Hiệp",
                "P. Phước Nguyên",
                "P. Long Toàn",
                "P. Long Tâm",
                "P. Phước Trung",
                "P. Long Hương",
                "P. Kim Dinh",
                "X. Tân Hưng",
                "X. Long Phước",
                "X. Hoà Long"
             ],
             "codeErp":"5202"
          },
          {
             "n":"H. Châu Đức",
             "w":[
                "X. Bàu Chinh",
                "TT. Ngãi Giao",
                "X. Bình Ba",
                "X. Suối Nghệ",
                "X. Xuân Sơn",
                "X. Sơn Bình",
                "X. Bình Giã",
                "X. Bình Trung",
                "X. Xà Bang",
                "X. Cù Bị",
                "X. Láng Lớn",
                "X. Quảng Thành",
                "X. Kim Long",
                "X. Suối Rao",
                "X. Đá Bạc",
                "X. Nghĩa Thành"
             ],
             "codeErp":"5207"
          },
          {
             "n":"H. Xuyên Mộc",
             "w":[
                "TT. Phước Bửu",
                "X. Phước Thuận",
                "X. Phước Tân",
                "X. Xuyên Mộc",
                "X. Bông Trang",
                "X. Tân Lâm",
                "X. Bàu Lâm",
                "X. Hòa Bình",
                "X. Hòa Hưng",
                "X. Hòa Hiệp",
                "X. Hòa Hội",
                "X. Bưng Riềng",
                "X. Bình Châu"
             ],
             "codeErp":"5203"
          },
          {
             "n":"H. Long Điền",
             "w":[
                "TT. Long Điền",
                "TT. Long Hải",
                "X. An Ngãi",
                "X. Tam Phước",
                "X. An Nhứt",
                "X. Phước Tỉnh",
                "X. Phước Hưng"
             ],
             "codeErp":"5204"
          },
          {
             "n":"H. Đất Đỏ",
             "w":[
                "TT. Đất Đỏ",
                "X. Phước Long Thọ",
                "X. Phước Hội",
                "X. Long Mỹ",
                "TT. Phước Hải",
                "X. Long Tân",
                "X. Láng Dài",
                "X. Lộc An"
             ],
             "codeErp":"5208"
          },
          {
             "n":"TX. Phú Mỹ",
             "w":[
                "P. Phú Mỹ",
                "X. Tân Hoà",
                "X. Tân Hải",
                "P. Phước Hoà",
                "P. Tân Phước",
                "P. Mỹ Xuân",
                "X. Sông Xoài",
                "P. Hắc Dịch",
                "X. Châu Pha",
                "X. Tóc Tiên"
             ],
             "codeErp":"5206"
          },
          {
             "n":"H. Côn Đảo",
             "w":[
 
             ],
             "codeErp":"5205"
          }
       ],
       "n":"Bà Rịa – Vũng Tàu",
       "codeErp":"52"
    },
    {
       "d":[
          {
             "n":"TP. Biên Hòa",
             "w":[
                "P. Trảng Dài",
                "P. Tân Phong",
                "P. Tân Biên",
                "P. Hố Nai",
                "P. Tân Hòa",
                "P. Tân Hiệp",
                "P. Bửu Long",
                "P. Tân Tiến",
                "P. Tam Hiệp",
                "P. Long Bình",
                "P. Quang Vinh",
                "P. Tân Mai",
                "P. Thống Nhất",
                "P. Trung Dũng",
                "P. Tam Hòa",
                "P. Hòa Bình",
                "P. Quyết Thắng",
                "P. Thanh Bình",
                "P. Bình Đa",
                "P. An Bình",
                "P. Bửu Hòa",
                "P. Long Bình Tân",
                "P. Tân Vạn",
                "P. Tân Hạnh",
                "P. Hiệp Hòa",
                "P. Hóa An",
                "P. An Hòa",
                "P. Tam Phước",
                "P. Phước Tân",
                "X. Long Hưng"
             ],
             "codeErp":"4801"
          },
          {
             "n":"TP. Long Khánh",
             "w":[
                "P. Xuân Trung",
                "P. Xuân Thanh",
                "P. Xuân Bình",
                "P. Xuân An",
                "P. Xuân Hoà",
                "P. Phú Bình",
                "X. Bình Lộc",
                "X. Bảo Quang",
                "P. Suối Tre",
                "P. Bảo Vinh",
                "P. Xuân Lập",
                "P. Bàu Sen",
                "X. Bàu Trâm",
                "P. Xuân Tân",
                "X. Hàng Gòn"
             ],
             "codeErp":"4806"
          },
          {
             "n":"H. Tân Phú",
             "w":[
                "TT. Tân Phú",
                "X. Dak Lua",
                "X. Nam Cát Tiên",
                "X. Phú An",
                "X. Núi Tượng",
                "X. Tà Lài",
                "X. Phú Lập",
                "X. Phú Sơn",
                "X. Phú Thịnh",
                "X. Thanh Sơn",
                "X. Phú Trung",
                "X. Phú Xuân",
                "X. Phú Lộc",
                "X. Phú Lâm",
                "X. Phú Bình",
                "X. Phú Thanh",
                "X. Trà Cổ",
                "X. Phú Điền"
             ],
             "codeErp":"4803"
          },
          {
             "n":"H. Vĩnh Cửu",
             "w":[
                "TT. Vĩnh An",
                "X. Phú Lý",
                "X. Trị An",
                "X. Tân An",
                "X. Vĩnh Tân",
                "X. Bình Lợi",
                "X. Thạnh Phú",
                "X. Thiện Tân",
                "X. Tân Bình",
                "X. Bình Hòa",
                "X. Mã Đà",
                "X. Hiếu Liêm"
             ],
             "codeErp":"4802"
          },
          {
             "n":"H. Định Quán",
             "w":[
                "TT. Định Quán",
                "X. Thanh Sơn",
                "X. Phú Tân",
                "X. Phú Vinh",
                "X. Phú Lợi",
                "X. Phú Hòa",
                "X. Ngọc Định",
                "X. La Ngà",
                "X. Gia Canh",
                "X. Phú Ngọc",
                "X. Phú Cường",
                "X. Túc Trưng",
                "X. Phú Túc",
                "X. Suối Nho"
             ],
             "codeErp":"4804"
          },
          {
             "n":"H. Trảng Bom",
             "w":[
                "TT. Trảng Bom",
                "X. Thanh Bình",
                "X. Cây Gáo",
                "X. Bàu Hàm",
                "X. Sông Thao",
                "X. Sông Trầu",
                "X. Đông Hoà",
                "X. Bắc Sơn",
                "X. Hố Nai 3",
                "X. Tây Hoà",
                "X. Bình Minh",
                "X. Trung Hoà",
                "X. Đồi 61",
                "X. Hưng Thịnh",
                "X. Quảng Tiến",
                "X. Giang Điền",
                "X. An Viễn"
             ],
             "codeErp":"4810"
          },
          {
             "n":"H. Thống Nhất",
             "w":[
                "X. Gia Tân 1",
                "X. Gia Tân 2",
                "X. Gia Tân 3",
                "X. Gia Kiệm",
                "X. Quang Trung",
                "X. Bàu Hàm 2",
                "X. Hưng Lộc",
                "X. Lộ 25",
                "X. Xuân Thiện",
                "TT. Dầu Giây"
             ],
             "codeErp":"4805"
          },
          {
             "n":"H. Cẩm Mỹ",
             "w":[
                "X. Sông Nhạn",
                "X. Xuân Quế",
                "X. Nhân Nghĩa",
                "X. Xuân Đường",
                "X. Long Giao",
                "X. Xuân Mỹ",
                "X. Thừa Đức",
                "X. Bảo Bình",
                "X. Xuân Bảo",
                "X. Xuân Tây",
                "X. Xuân Đông",
                "X. Sông Ray",
                "X. Lâm San"
             ],
             "codeErp":"4811"
          },
          {
             "n":"H. Long Thành",
             "w":[
                "TT. Long Thành",
                "X. An Phước",
                "X. Bình An",
                "X. Long Đức",
                "X. Lộc An",
                "X. Bình Sơn",
                "X. Tam An",
                "X. Cẩm Đường",
                "X. Long An",
                "X. Bàu Cạn",
                "X. Long Phước",
                "X. Phước Bình",
                "X. Tân Hiệp",
                "X. Phước Thái"
             ],
             "codeErp":"4808"
          },
          {
             "n":"H. Xuân Lộc",
             "w":[
                "TT. Gia Ray",
                "X. Xuân Bắc",
                "X. Suối Cao",
                "X. Xuân Thành",
                "X. Xuân Thọ",
                "X. Xuân Trường",
                "X. Xuân Hòa",
                "X. Xuân Hưng",
                "X. Xuân Tâm",
                "X. Suối Cát",
                "X. Xuân Hiệp",
                "X. Xuân Phú",
                "X. Xuân Định",
                "X. Bảo Hoà",
                "X. Lang Minh"
             ],
             "codeErp":"4807"
          },
          {
             "n":"H. Nhơn Trạch",
             "w":[
                "X. Phước Thiền",
                "X. Long Tân",
                "X. Đại Phước",
                "TT. Hiệp Phước",
                "X. Phú Hữu",
                "X. Phú Hội",
                "X. Phú Thạnh",
                "X. Phú Đông",
                "X. Long Thọ",
                "X. Vĩnh Thanh",
                "X. Phước Khánh",
                "X. Phước An"
             ],
             "codeErp":"4809"
          }
       ],
       "n":"Đồng Nai",
       "codeErp":"48"
    },
    {
       "d":[
          {
             "n":"TP. Đà Lạt",
             "w":[
                "P. 7",
                "P. 8",
                "P. 12",
                "P. 9",
                "P. 2",
                "P. 1",
                "P. 6",
                "P. 5",
                "P. 4",
                "P. 10",
                "P. 11",
                "P. 3",
                "X. Xuân Thọ",
                "X. Tà Nung",
                "X. Trạm Hành",
                "X. Xuân Trường"
             ],
             "codeErp":"4201"
          },
          {
             "n":"TP. Bảo Lộc",
             "w":[
                "P. Lộc Phát",
                "P. Lộc Tiến",
                "P. 2",
                "P. 1",
                "P. B'lao",
                "P. Lộc Sơn",
                "X. Đạm Bri",
                "X. Lộc Thanh",
                "X. Lộc Nga",
                "X. Lộc Châu",
                "X. Đại Lào"
             ],
             "codeErp":"4202"
          },
          {
             "n":"H. Đam Rông",
             "w":[
                "X. Đạ Tông",
                "X. Đạ Long",
                "X. Đạ M' Rong",
                "X. Liêng Srônh",
                "X. Đạ Rsal",
                "X. Rô Men",
                "X. Phi Liêng",
                "X. Đạ K' Nàng"
             ],
             "codeErp":"4212"
          },
          {
             "n":"H. Lạc Dương",
             "w":[
                "TT. Lạc Dương",
                "X. Đạ Chais",
                "X. Đạ Nhim",
                "X. Đưng KNớ",
                "X. Lát",
                "X. Đạ Sar"
             ],
             "codeErp":"4206"
          },
          {
             "n":"H. Lâm Hà",
             "w":[
                "TT. Nam Ban",
                "TT. Đinh Văn",
                "X. Phú Sơn",
                "X. Phi Tô",
                "X. Mê Linh",
                "X. Đạ Đờn",
                "X. Phúc Thọ",
                "X. Đông Thanh",
                "X. Gia Lâm",
                "X. Tân Thanh",
                "X. Tân Văn",
                "X. Hoài Đức",
                "X. Tân Hà",
                "X. Liên Hà",
                "X. Đan Phượng",
                "X. Nam Hà"
             ],
             "codeErp":"4210"
          },
          {
             "n":"H. Đơn Dương",
             "w":[
                "TT. D'Ran",
                "TT. Thạnh Mỹ",
                "X. Lạc Xuân",
                "X. Đạ Ròn",
                "X. Lạc Lâm",
                "X. Ka Đô",
                "X. Quảng Lập",
                "X. Ka Đơn",
                "X. Tu Tra",
                "X. Pró"
             ],
             "codeErp":"4205"
          },
          {
             "n":"H. Đức Trọng",
             "w":[
                "TT. Liên Nghĩa",
                "X. Hiệp An",
                "X. Liên Hiệp",
                "X. Hiệp Thạnh",
                "X. Bình Thạnh",
                "X. N'Thol Hạ",
                "X. Tân Hội",
                "X. Tân Thành",
                "X. Phú Hội",
                "X. Ninh Gia",
                "X. Tà Năng",
                "X. Đa Quyn",
                "X. Tà Hine",
                "X. Đà Loan",
                "X. Ninh Loan"
             ],
             "codeErp":"4203"
          },
          {
             "n":"H. Di Linh",
             "w":[
                "TT. Di Linh",
                "X. Đinh Trang Thượng",
                "X. Tân Thượng",
                "X. Tân Lâm",
                "X. Tân Châu",
                "X. Tân Nghĩa",
                "X. Gia Hiệp",
                "X. Đinh Lạc",
                "X. Tam Bố",
                "X. Đinh Trang Hòa",
                "X. Liên Đầm",
                "X. Gung Ré",
                "X. Bảo Thuận",
                "X. Hòa Ninh",
                "X. Hòa Trung",
                "X. Hòa Nam",
                "X. Hòa Bắc",
                "X. Sơn Điền",
                "X. Gia Bắc"
             ],
             "codeErp":"4204"
          },
          {
             "n":"H. Bảo Lâm",
             "w":[
                "TT. Lộc Thắng",
                "X. Lộc Bảo",
                "X. Lộc Lâm",
                "X. Lộc Phú",
                "X. Lộc Bắc",
                "X. B' Lá",
                "X. Lộc Ngãi",
                "X. Lộc Quảng",
                "X. Lộc Tân",
                "X. Lộc Đức",
                "X. Lộc An",
                "X. Tân Lạc",
                "X. Lộc Thành",
                "X. Lộc Nam"
             ],
             "codeErp":"4211"
          },
          {
             "n":"H. Đạ Huoai",
             "w":[
                "TT. Đạ M'ri",
                "TT. Ma Đa Guôi",
                "X. Hà Lâm",
                "X. Đạ Tồn",
                "X. Đạ Oai",
                "X. Đạ Ploa",
                "X. Ma Đa Guôi",
                "X. Đoàn Kết",
                "X. Phước Lộc"
             ],
             "codeErp":"4207"
          },
          {
             "n":"H. Đạ Tẻh",
             "w":[
                "TT. Đạ Tẻh",
                "X. An Nhơn",
                "X. Quốc Oai",
                "X. Mỹ Đức",
                "X. Quảng Trị",
                "X. Đạ Lây",
                "X. Triệu Hải",
                "X. Đạ Kho",
                "X. Đạ Pal"
             ],
             "codeErp":"4208"
          },
          {
             "n":"H. Cát Tiên",
             "w":[
                "TT. Cát Tiên",
                "X. Tiên Hoàng",
                "X. Phước Cát 2",
                "X. Gia Viễn",
                "X. Nam Ninh",
                "TT. Phước Cát ",
                "X. Đức Phổ",
                "X. Quảng Ngãi",
                "X. Đồng Nai Thượng"
             ],
             "codeErp":"4209"
          }
       ],
       "n":"Lâm Đồng",
       "codeErp":"42"
    },
    {
       "d":[
          {
             "n":"Q. 1",
             "w":[
                "P. Tân Định",
                "P. Đa Kao",
                "P. Bến Nghé",
                "P. Bến Thành",
                "P. Nguyễn Thái Bình",
                "P. Phạm Ngũ Lão",
                "P. Cầu Ông Lãnh",
                "P. Cô Giang",
                "P. Nguyễn Cư Trinh",
                "P. Cầu Kho"
             ],
             "codeErp":"0201"
          },
          {
             "n":"Q. 12",
             "w":[
                "P. Thạnh Xuân",
                "P. Thạnh Lộc",
                "P. Hiệp Thành",
                "P. Thới An",
                "P. Tân Chánh Hiệp",
                "P. An Phú Đông",
                "P. Tân Thới Hiệp",
                "P. Trung Mỹ Tây",
                "P. Tân Hưng Thuận",
                "P. Đông Hưng Thuận",
                "P. Tân Thới Nhất"
             ],
             "codeErp":"0212"
          },
          {
             "n":"Tp. Thủ Đức",
             "w":[
               "P. An Khánh",
               "P. An Lợi Đông",
               "P. An Phú",
               "P. Bình Chiểu",
               "P. Bình Thọ",
               "P. Bình Trưng Đông",
               "P. Bình Trưng Tây",
               "P. Cát Lái",
               "P. Hiệp Bình Chánh",
               "P. Hiệp Bình Phước",
               "P. Hiệp Phú",
               "P. Linh Chiểu",
               "P. Linh Đông",
               "P. Linh Tây",
               "P. Linh Trung",
               "P. Linh Xuân",
               "P. Long Bình",
               "P. Long Phước",
               "P. Long Thạnh Mỹ",
               "P. Long Trường",
               "P. Phú Hữu",
               "P. Phước Bình",
               "P. Phước Long A",
               "P. Phước Long B",
               "P. Tam Bình",
               "P. Tam Phú",
               "P. Tăng Nhơn Phú A",
               "P. Tăng Nhơn Phú B",
               "P. Tân Phú",
               "P. Thạnh Mỹ Lợi",
               "P. Thảo Điền",
               "P. Thủ Thiêm",
               "P. Trường Thạnh",
               "P. Trường Thọ"
             ],
             "codeErp":"0218"
          },
          {
             "n":"Q. Gò Vấp",
             "w":[
                "P. 15",
                "P. 13",
                "P. 17",
                "P. 6",
                "P. 16",
                "P. 12",
                "P. 14",
                "P. 10",
                "P. 5",
                "P. 7",
                "P. 4",
                "P. 1",
                "P. 9",
                "P. 8",
                "P. 11",
                "P. 3"
             ],
             "codeErp":"0213"
          },
          {
             "n":"Q. Bình Thạnh",
             "w":[
                "P. 13",
                "P. 11",
                "P. 27",
                "P. 26",
                "P. 12",
                "P. 25",
                "P. 5",
                "P. 7",
                "P. 24",
                "P. 6",
                "P. 14",
                "P. 15",
                "P. 2",
                "P. 1",
                "P. 3",
                "P. 17",
                "P. 21",
                "P. 22",
                "P. 19",
                "P. 28"
             ],
             "codeErp":"0216"
          },
          {
             "n":"Q. Tân Bình",
             "w":[
                "P. 2",
                "P. 4",
                "P. 12",
                "P. 13",
                "P. 1",
                "P. 3",
                "P. 11",
                "P. 7",
                "P. 5",
                "P. 10",
                "P. 6",
                "P. 8",
                "P. 9",
                "P. 14",
                "P. 15"
             ],
             "codeErp":"0214"
          },
          {
             "n":"Q. Tân Phú",
             "w":[
                "P. Tân Sơn Nhì",
                "P. Tây Thạnh",
                "P. Sơn Kỳ",
                "P. Tân Quý",
                "P. Tân Thành",
                "P. Phú Thọ Hòa",
                "P. Phú Thạnh",
                "P. Phú Trung",
                "P. Hòa Thạnh",
                "P. Hiệp Tân",
                "P. Tân Thới Hòa"
             ],
             "codeErp":"0215"
          },
          {
             "n":"Q. Phú Nhuận",
             "w":[
                "P. 4",
                "P. 5",
                "P. 9",
                "P. 7",
                "P. 3",
                "P. 1",
                "P. 2",
                "P. 8",
                "P. 15",
                "P. 10",
                "P. 11",
                "P. 17",
                "P. 14",
                "P. 12",
                "P. 13"
             ],
             "codeErp":"0217"
          },
          {
             "n":"Q. 3",
             "w":[
                "P. 8",
                "P. 7",
                "P. 14",
                "P. 12",
                "P. 11",
                "P. 13",
                "P. 6",
                "P. 9",
                "P. 10",
                "P. 4",
                "P. 5",
                "P. 3",
                "P. 2",
                "P. 1"
             ],
             "codeErp":"0203"
          },
          {
             "n":"Q. 10",
             "w":[
                "P. 15",
                "P. 13",
                "P. 14",
                "P. 12",
                "P. 11",
                "P. 10",
                "P. 9",
                "P. 1",
                "P. 8",
                "P. 2",
                "P. 4",
                "P. 7",
                "P. 5",
                "P. 6",
                "P. 3"
             ],
             "codeErp":"0210"
          },
          {
             "n":"Q. 11",
             "w":[
                "P. 15",
                "P. 5",
                "P. 14",
                "P. 11",
                "P. 3",
                "P. 10",
                "P. 13",
                "P. 8",
                "P. 9",
                "P. 12",
                "P. 7",
                "P. 6",
                "P. 4",
                "P. 1",
                "P. 2",
                "P. 16"
             ],
             "codeErp":"0211"
          },
          {
             "n":"Q. 4",
             "w":[
                "P. 12",
                "P. 13",
                "P. 9",
                "P. 6",
                "P. 8",
                "P. 10",
                "P. 5",
                "P. 18",
                "P. 14",
                "P. 4",
                "P. 3",
                "P. 16",
                "P. 2",
                "P. 15",
                "P. 1"
             ],
             "codeErp":"0204"
          },
          {
             "n":"Q. 5",
             "w":[
                "P. 4",
                "P. 9",
                "P. 3",
                "P. 12",
                "P. 2",
                "P. 8",
                "P. 15",
                "P. 7",
                "P. 1",
                "P. 11",
                "P. 14",
                "P. 5",
                "P. 6",
                "P. 10",
                "P. 13"
             ],
             "codeErp":"0205"
          },
          {
             "n":"Q. 6",
             "w":[
                "P. 14",
                "P. 13",
                "P. 9",
                "P. 6",
                "P. 12",
                "P. 5",
                "P. 11",
                "P. 2",
                "P. 1",
                "P. 4",
                "P. 8",
                "P. 3",
                "P. 7",
                "P. 10"
             ],
             "codeErp":"0206"
          },
          {
             "n":"Q. 8",
             "w":[
                "P. 8",
                "P. 2",
                "P. 1",
                "P. 3",
                "P. 11",
                "P. 9",
                "P. 10",
                "P. 4",
                "P. 13",
                "P. 12",
                "P. 5",
                "P. 14",
                "P. 6",
                "P. 15",
                "P. 16",
                "P. 7"
             ],
             "codeErp":"0208"
          },
          {
             "n":"Q. Bình Tân",
             "w":[
                "P. Bình Hưng Hòa",
                "P. Bình Hưng Hoà A",
                "P. Bình Hưng Hoà B",
                "P. Bình Trị Đông",
                "P. Bình Trị Đông A",
                "P. Bình Trị Đông B",
                "P. Tân Tạo",
                "P. Tân Tạo A",
                "P.  An Lạc",
                "P. An Lạc A"
             ],
             "codeErp":"0219"
          },
          {
             "n":"Q. 7",
             "w":[
                "P. Tân Thuận Đông",
                "P. Tân Thuận Tây",
                "P. Tân Kiểng",
                "P. Tân Hưng",
                "P. Bình Thuận",
                "P. Tân Quy",
                "P. Phú Thuận",
                "P. Tân Phú",
                "P. Tân Phong",
                "P. Phú Mỹ"
             ],
             "codeErp":"0207"
          },
          {
             "n":"H. Củ Chi",
             "w":[
                "TT. Củ Chi",
                "X. Phú Mỹ Hưng",
                "X. An Phú",
                "X. Trung Lập Thượng",
                "X. An Nhơn Tây",
                "X. Nhuận Đức",
                "X. Phạm Văn Cội",
                "X. Phú Hòa Đông",
                "X. Trung Lập Hạ",
                "X. Trung An",
                "X. Phước Thạnh",
                "X. Phước Hiệp",
                "X. Tân An Hội",
                "X. Phước Vĩnh An",
                "X. Thái Mỹ",
                "X. Tân Thạnh Tây",
                "X. Hòa Phú",
                "X. Tân Thạnh Đông",
                "X. Bình Mỹ",
                "X. Tân Phú Trung",
                "X. Tân Thông Hội"
             ],
             "codeErp":"0221"
          },
          {
             "n":"H. Hóc Môn",
             "w":[
                "TT. Hóc Môn",
                "X. Tân Hiệp",
                "X. Nhị Bình",
                "X. Đông Thạnh",
                "X. Tân Thới Nhì",
                "X. Thới Tam Thôn",
                "X. Xuân Thới Sơn",
                "X. Tân Xuân",
                "X. Xuân Thới Đông",
                "X. Trung Chánh",
                "X. Xuân Thới Thượng",
                "X. Bà Điểm"
             ],
             "codeErp":"0222"
          },
          {
             "n":"H. Bình Chánh",
             "w":[
                "TT. Tân Túc",
                "X. Phạm Văn Hai",
                "X. Vĩnh Lộc A",
                "X. Vĩnh Lộc B",
                "X. Bình Lợi",
                "X. Lê Minh Xuân",
                "X. Tân Nhựt",
                "X. Tân Kiên",
                "X. Bình Hưng",
                "X. Phong Phú",
                "X. An Phú Tây",
                "X. Hưng Long",
                "X. Đa Phước",
                "X. Tân Quý Tây",
                "X. Bình Chánh",
                "X. Quy Đức"
             ],
             "codeErp":"0220"
          },
          {
             "n":"H. Nhà Bè",
             "w":[
                "TT. Nhà Bè",
                "X. Phước Kiển",
                "X. Phước Lộc",
                "X. Nhơn Đức",
                "X. Phú Xuân",
                "X. Long Thới",
                "X. Hiệp Phước"
             ],
             "codeErp":"0223"
          },
          {
             "n":"H. Cần Giờ",
             "w":[
                "TT. Cần Thạnh",
                "X. Bình Khánh",
                "X. Tam Thôn Hiệp",
                "X. An Thới Đông",
                "X. Thạnh An",
                "X. Long Hòa",
                "X. Lý Nhơn"
             ],
             "codeErp":"0224"
          }
       ],
       "n":"Tp. Hồ Chí Minh",
       "codeErp":"02"
    },
    {
       "d":[
          {
             "n":"TP. Tân An",
             "w":[
                "P. 5",
                "P. 2",
                "P. 4",
                "P. Tân Khánh",
                "P. 1",
                "P. 3",
                "P. 7",
                "P. 6",
                "X. Hướng Thọ Phú",
                "X. Nhơn Thạnh Trung",
                "X. Lợi Bình Nhơn",
                "X. Bình Tâm",
                "P. Khánh Hậu",
                "X. An Vĩnh Ngãi"
             ],
             "codeErp":"4901"
          },
          {
             "n":"TX. Kiến Tường",
             "w":[
                "P. 1",
                "P. 2",
                "X. Thạnh Trị",
                "X. Bình Hiệp",
                "X. Bình Tân",
                "X. Tuyên Thạnh",
                "P. 3",
                "X. Thạnh Hưng"
             ],
             "codeErp":"4915"
          },
          {
             "n":"H. Tân Hưng",
             "w":[
                "TT. Tân Hưng",
                "X. Hưng Hà",
                "X. Hưng Điền B",
                "X. Hưng Điền",
                "X. Thạnh Hưng",
                "X. Hưng Thạnh",
                "X. Vĩnh Thạnh",
                "X. Vĩnh Châu B",
                "X. Vĩnh Lợi",
                "X. Vĩnh Đại",
                "X. Vĩnh Châu A",
                "X. Vĩnh Bửu"
             ],
             "codeErp":"4914"
          },
          {
             "n":"H. Vĩnh Hưng",
             "w":[
                "TT. Vĩnh Hưng",
                "X. Hưng Điền A",
                "X. Khánh Hưng",
                "X. Thái Trị",
                "X. Vĩnh Trị",
                "X. Thái Bình Trung",
                "X. Vĩnh Bình",
                "X. Vĩnh Thuận",
                "X. Tuyên Bình",
                "X. Tuyên Bình Tây"
             ],
             "codeErp":"4902"
          },
          {
             "n":"H. Mộc Hóa",
             "w":[
                "X. Bình Hòa Tây",
                "X. Bình Thạnh",
                "X. Bình Hòa Trung",
                "X. Bình Hòa Đông",
                "TT. Bình Phong Thạnh",
                "X. Tân Lập",
                "X. Tân Thành"
             ],
             "codeErp":"4903"
          },
          {
             "n":"H. Tân Thạnh",
             "w":[
                "TT. Tân Thạnh",
                "X. Bắc Hòa",
                "X. Hậu Thạnh Tây",
                "X. Nhơn Hòa Lập",
                "X. Tân Lập",
                "X. Hậu Thạnh Đông",
                "X. Nhơn Hoà",
                "X. Kiến Bình",
                "X. Tân Thành",
                "X. Tân Bình",
                "X. Tân Ninh",
                "X. Nhơn Ninh",
                "X. Tân Hòa"
             ],
             "codeErp":"4904"
          },
          {
             "n":"H. Thạnh Hóa",
             "w":[
                "TT. Thạnh Hóa",
                "X. Tân Hiệp",
                "X. Thuận Bình",
                "X. Thạnh Phước",
                "X. Thạnh Phú",
                "X. Thuận Nghĩa Hòa",
                "X. Thủy Đông",
                "X. Thủy Tây",
                "X. Tân Tây",
                "X. Tân Đông",
                "X. Thạnh An"
             ],
             "codeErp":"4905"
          },
          {
             "n":"H. Đức Huệ",
             "w":[
                "TT. Đông Thành",
                "X. Mỹ Quý Đông",
                "X. Mỹ Thạnh Bắc",
                "X. Mỹ Quý Tây",
                "X. Mỹ Thạnh Tây",
                "X. Mỹ Thạnh Đông",
                "X. Bình Thành",
                "X. Bình Hòa Bắc",
                "X. Bình Hòa Hưng",
                "X. Bình Hòa Nam",
                "X. Mỹ Bình"
             ],
             "codeErp":"4906"
          },
          {
             "n":"H. Đức Hòa",
             "w":[
                "TT. Hậu Nghĩa",
                "TT. Hiệp Hòa",
                "TT. Đức Hòa",
                "X. Lộc Giang",
                "X. An Ninh Đông",
                "X. An Ninh Tây",
                "X. Tân Mỹ",
                "X. Hiệp Hòa",
                "X. Đức Lập Thượng",
                "X. Đức Lập Hạ",
                "X. Tân Phú",
                "X. Mỹ Hạnh Bắc",
                "X. Đức Hòa Thượng",
                "X. Hòa Khánh Tây",
                "X. Hòa Khánh Đông",
                "X. Mỹ Hạnh Nam",
                "X. Hòa Khánh Nam",
                "X. Đức Hòa Đông",
                "X. Đức Hòa Hạ",
                "X. Hựu Thạnh"
             ],
             "codeErp":"4903"
          },
          {
             "n":"H. Bến Lức",
             "w":[
                "TT. Bến Lức",
                "X. Thạnh Lợi",
                "X. Lương Bình",
                "X. Thạnh Hòa",
                "X. Lương Hòa",
                "X. Tân Hòa",
                "X. Tân Bửu",
                "X. An Thạnh",
                "X. Bình Đức",
                "X. Mỹ Yên",
                "X. Thanh Phú",
                "X. Long Hiệp",
                "X. Thạnh Đức",
                "X. Phước Lợi",
                "X. Nhựt Chánh"
             ],
             "codeErp":"4908"
          },
          {
             "n":"H. Thủ Thừa",
             "w":[
                "TT. Thủ Thừa",
                "X. Long Thạnh",
                "X. Tân Thành",
                "X. Long Thuận",
                "X. Mỹ Lạc",
                "X. Mỹ Thạnh",
                "X. Bình An",
                "X. Nhị Thành",
                "X. Mỹ An",
                "X. Bình Thạnh",
                "X. Mỹ Phú",
                "X. Tân Long"
             ],
             "codeErp":"4909"
          },
          {
             "n":"H. Tân Trụ",
             "w":[
                "TT. Tân Trụ",
                "X. Tân Bình",
                "X. Quê Mỹ Thạnh",
                "X. Lạc Tấn",
                "X. Bình Trinh Đông",
                "X. Tân Phước Tây",
                "X. Bình Lãng",
                "X. Bình Tịnh",
                "X. Đức Tân",
                "X. Nhựt Ninh"
             ],
             "codeErp":"4911"
          },
          {
             "n":"H. Cần Đước",
             "w":[
                "TT. Cần Đước",
                "X. Long Trạch",
                "X. Long Khê",
                "X. Long Định",
                "X. Phước Vân",
                "X. Long Hòa",
                "X. Long Cang",
                "X. Long Sơn",
                "X. Tân Trạch",
                "X. Mỹ Lệ",
                "X. Tân Lân",
                "X. Phước Tuy",
                "X. Long Hựu Đông",
                "X. Tân Ân",
                "X. Phước Đông",
                "X. Long Hựu Tây",
                "X. Tân Chánh"
             ],
             "codeErp":"4912"
          },
          {
             "n":"H. Cần Giuộc",
             "w":[
                "TT. Cần Giuộc",
                "X. Phước Lý",
                "X. Long Thượng",
                "X. Long Hậu",
                "X. Phước Hậu",
                "X. Mỹ Lộc",
                "X. Phước Lại",
                "X. Phước Lâm",
                "X. Thuận Thành",
                "X. Phước Vĩnh Tây",
                "X. Phước Vĩnh Đông",
                "X. Long An",
                "X. Long Phụng",
                "X. Đông Thạnh",
                "X. Tân Tập"
             ],
             "codeErp":"4913"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Tầm Vu",
                "X. Bình Quới",
                "X. Hòa Phú",
                "X. Phú Ngãi Trị",
                "X. Vĩnh Công",
                "X. Thuận Mỹ",
                "X. Hiệp Thạnh",
                "X. Phước Tân Hưng",
                "X. Thanh Phú Long",
                "X. Dương Xuân Hội",
                "X. An Lục Long",
                "X. Long Trì",
                "X. Thanh Vĩnh Đông"
             ],
             "codeErp":"4910"
          }
       ],
       "n":"Long An",
       "codeErp":"49"
    },
    {
       "d":[
          {
             "n":"TP. Mỹ Tho",
             "w":[
                "P. 5",
                "P. 4",
                "P. 7",
                "P. 3",
                "P. 1",
                "P. 2",
                "P. 8",
                "P. 6",
                "P. 9",
                "P. 10",
                "P. Tân Long",
                "X. Đạo Thạnh",
                "X. Trung An",
                "X. Mỹ Phong",
                "X. Tân Mỹ Chánh",
                "X. Phước Thạnh",
                "X. Thới Sơn"
             ],
             "codeErp":"5301"
          },
          {
             "n":"TX. Gò Công",
             "w":[
                "P. 3",
                "P. 2",
                "P. 4",
                "P. 1",
                "P. 5",
                "X. Long Hưng",
                "X. Long Thuận",
                "X. Long Chánh",
                "X. Long Hòa",
                "X. Bình Đông",
                "X. Bình Xuân",
                "X. Tân Trung"
             ],
             "codeErp":"5302"
          },
          {
             "n":"TX. Cai Lậy",
             "w":[
                "P. 1",
                "P. 2",
                "P. 3",
                "P. 4",
                "P. 5",
                "X. Mỹ Phước Tây",
                "X. Mỹ Hạnh Đông",
                "X. Mỹ Hạnh Trung",
                "X. Tân Phú",
                "X. Tân Bình",
                "X. Tân Hội",
                "P. Nhị Mỹ",
                "X. Nhị Quý",
                "X. Thanh Hòa",
                "X. Phú Quý",
                "X. Long Khánh"
             ],
             "codeErp":"5311"
          },
          {
             "n":"H. Tân Phước",
             "w":[
                "TT. Mỹ Phước",
                "X. Tân Hòa Đông",
                "X. Thạnh Tân",
                "X. Thạnh Mỹ",
                "X. Thạnh Hoà",
                "X. Phú Mỹ",
                "X. Tân Hòa Thành",
                "X. Hưng Thạnh",
                "X. Tân Lập 1",
                "X. Tân Hòa Tây",
                "X. Tân Lập 2",
                "X. Phước Lập"
             ],
             "codeErp":"5309"
          },
          {
             "n":"H. Cái Bè",
             "w":[
                "TT. Cái Bè",
                "X. Hậu Mỹ Bắc B",
                "X. Hậu Mỹ Bắc A",
                "X. Mỹ Trung",
                "X. Hậu Mỹ Trinh",
                "X. Hậu Mỹ Phú",
                "X. Mỹ Tân",
                "X. Mỹ Lợi B",
                "X. Thiện Trung",
                "X. Mỹ Hội",
                "X. An Cư",
                "X. Hậu Thành",
                "X. Mỹ Lợi A",
                "X. Hòa Khánh",
                "X. Thiện Trí",
                "X. Mỹ Đức Đông",
                "X. Mỹ Đức Tây",
                "X. Đông Hòa Hiệp",
                "X. An Thái Đông",
                "X. Tân Hưng",
                "X. Mỹ Lương",
                "X. Tân Thanh",
                "X. An Thái Trung",
                "X. An Hữu",
                "X. Hòa Hưng"
             ],
             "codeErp":"5303"
          },
          {
             "n":"H. Cai Lậy",
             "w":[
                "X. Thạnh Lộc",
                "X. Mỹ Thành Bắc",
                "X. Phú Cường",
                "X. Mỹ Thành Nam",
                "X. Phú Nhuận",
                "X. Bình Phú",
                "X. Cẩm Sơn",
                "X. Phú An",
                "X. Mỹ Long",
                "X. Long Tiên",
                "X. Hiệp Đức",
                "X. Long Trung",
                "X. Hội Xuân",
                "X. Tân Phong",
                "X. Tam Bình",
                "X. Ngũ Hiệp"
             ],
             "codeErp":"5304"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Tân Hiệp",
                "X. Tân Hội Đông",
                "X. Tân Hương",
                "X. Tân Lý Đông",
                "X. Tân Lý Tây",
                "X. Thân Cửu Nghĩa",
                "X. Tam Hiệp",
                "X. Điềm Hy",
                "X. Nhị Bình",
                "X. Dưỡng Điềm",
                "X. Đông Hòa",
                "X. Long Định",
                "X. Hữu Đạo",
                "X. Long An",
                "X. Long Hưng",
                "X. Bình Trưng",
                "X. Thạnh Phú",
                "X. Bàn Long",
                "X. Vĩnh Kim",
                "X. Bình Đức",
                "X. Song Thuận",
                "X. Kim Sơn",
                "X. Phú Phong"
             ],
             "codeErp":"5305"
          },
          {
             "n":"H. Chợ Gạo",
             "w":[
                "TT. Chợ Gạo",
                "X. Trung Hòa",
                "X. Hòa Tịnh",
                "X. Mỹ Tịnh An",
                "X. Tân Bình Thạnh",
                "X. Phú Kiết",
                "X. Lương Hòa Lạc",
                "X. Thanh Bình",
                "X. Quơn Long",
                "X. Bình Phục Nhứt",
                "X. Đăng Hưng Phước",
                "X. Tân Thuận Bình",
                "X. Song Bình",
                "X. Bình Phan",
                "X. Long Bình Điền",
                "X. An Thạnh Thủy",
                "X. Xuân Đông",
                "X. Hòa Định",
                "X. Bình Ninh"
             ],
             "codeErp":"5306"
          },
          {
             "n":"H. Gò Công Tây",
             "w":[
                "TT. Vĩnh Bình",
                "X. Đồng Sơn",
                "X. Bình Phú",
                "X. Đồng Thạnh",
                "X. Thành Công",
                "X. Bình Nhì",
                "X. Yên Luông",
                "X. Thạnh Trị",
                "X. Thạnh Nhựt",
                "X. Long Vĩnh",
                "X. Bình Tân",
                "X. Vĩnh Hựu",
                "X. Long Bình"
             ],
             "codeErp":"5307"
          },
          {
             "n":"H. Gò Công Đông",
             "w":[
                "TT. Tân Hòa",
                "X. Tăng Hoà",
                "X. Tân Phước",
                "X. Gia Thuận",
                "TT. Vàm Láng",
                "X. Tân Tây",
                "X. Kiểng Phước",
                "X. Tân Đông",
                "X. Bình Ân",
                "X. Tân Điền",
                "X. Bình Nghị",
                "X. Phước Trung",
                "X. Tân Thành"
             ],
             "codeErp":"5308"
          },
          {
             "n":"H. Tân Phú Đông",
             "w":[
                "X. Tân Thới",
                "X. Tân Phú",
                "X. Phú Thạnh",
                "X. Tân Thạnh",
                "X. Phú Đông",
                "X. Phú Tân"
             ],
             "codeErp":"5310"
          }
       ],
       "n":"Tiền Giang",
       "codeErp":"53"
    },
    {
       "d":[
          {
             "n":"TP. Bến Tre",
             "w":[
                "P. Phú Khương",
                "P. Phú Tân",
                "P. 8",
                "P. 6",
                "P. 4",
                "P. 5",
                "P. An Hội",
                "P. 7",
                "X. Sơn Đông",
                "X. Phú Hưng",
                "X. Bình Phú",
                "X. Mỹ Thạnh An",
                "X. Nhơn Thạnh",
                "X. Phú Nhuận"
             ],
             "codeErp":"5601"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Châu Thành",
                "X. Tân Thạch",
                "X. Qưới Sơn",
                "X. An Khánh",
                "X. Giao Long",
                "X. Phú Túc",
                "X. Phú Đức",
                "X. Phú An Hòa",
                "X. An Phước",
                "X. Tam Phước",
                "X. Thành Triệu",
                "X. Tường Đa",
                "X. Tân Phú",
                "X. Quới Thành",
                "X. Phước Thạnh",
                "X. An Hóa",
                "X. Tiên Long",
                "X. An Hiệp",
                "X. Hữu Định",
                "X. Tiên Thủy",
                "X. Sơn Hòa"
             ],
             "codeErp":"5602"
          },
          {
             "n":"H. Chợ Lách",
             "w":[
                "TT. Chợ Lách",
                "X. Phú Phụng",
                "X. Sơn Định",
                "X. Vĩnh Bình",
                "X. Hòa Nghĩa",
                "X. Long Thới",
                "X. Phú Sơn",
                "X. Tân Thiềng",
                "X. Vĩnh Thành",
                "X. Vĩnh Hòa",
                "X. Hưng Khánh Trung B"
             ],
             "codeErp":"5603"
          },
          {
             "n":"H. Mỏ Cày Nam",
             "w":[
                "TT. Mỏ Cày",
                "X. Định Thủy",
                "X. Đa Phước Hội",
                "X. Tân Hội",
                "X. Phước Hiệp",
                "X. Bình Khánh ",
                "X. An Thạnh",
                "X. An Định",
                "X. Thành Thới B",
                "X. Tân Trung",
                "X. An Thới",
                "X. Thành Thới A",
                "X. Minh Đức",
                "X. Ngãi Đăng",
                "X. Cẩm Sơn",
                "X. Hương Mỹ"
             ],
             "codeErp":"5609"
          },
          {
             "n":"H. Giồng Trôm",
             "w":[
                "TT. Giồng Trôm",
                "X. Phong Nẫm",
                "X. Mỹ Thạnh",
                "X. Châu Hòa",
                "X. Lương Hòa",
                "X. Lương Quới",
                "X. Lương Phú",
                "X. Châu Bình",
                "X. Thuận Điền",
                "X. Sơn Phú",
                "X. Bình Hoà",
                "X. Phước Long",
                "X. Hưng Phong",
                "X. Long Mỹ",
                "X. Tân Hào",
                "X. Bình Thành",
                "X. Tân Thanh",
                "X. Tân Lợi Thạnh",
                "X. Thạnh Phú Đông",
                "X. Hưng Nhượng",
                "X. Hưng Lễ"
             ],
             "codeErp":"5605"
          },
          {
             "n":"H. Bình Đại",
             "w":[
                "TT. Bình Đại",
                "X. Tam Hiệp",
                "X. Long Định",
                "X. Long Hòa",
                "X. Phú Thuận",
                "X. Vang Quới Tây",
                "X. Vang Quới Đông",
                "X. Châu Hưng",
                "X. Phú Vang",
                "X. Lộc Thuận",
                "X. Định Trung",
                "X. Thới Lai",
                "X. Bình Thới",
                "X. Phú Long",
                "X. Bình Thắng",
                "X. Thạnh Trị",
                "X. Đại Hòa Lộc",
                "X. Thừa Đức",
                "X. Thạnh Phước",
                "X. Thới Thuận"
             ],
             "codeErp":"5606"
          },
          {
             "n":"H. Ba Tri",
             "w":[
                "TT. Ba Tri",
                "X. Tân Mỹ",
                "X. Mỹ Hòa",
                "X. Tân Xuân",
                "X. Mỹ Chánh",
                "X. Bảo Thạnh",
                "X. An Phú Trung",
                "X. Mỹ Thạnh",
                "X. Mỹ Nhơn",
                "X. Phước Ngãi",
                "X. An Ngãi Trung",
                "X. Phú Lễ",
                "X. An Bình Tây",
                "X. Bảo Thuận",
                "X. Tân Hưng",
                "X. An Ngãi Tây",
                "X. An Hiệp",
                "X. Vĩnh Hòa",
                "X. Tân Thủy",
                "X. Vĩnh An",
                "X. An Đức",
                "X. An Hòa Tây",
                "X. An Thủy"
             ],
             "codeErp":"5607"
          },
          {
             "n":"H. Thạnh Phú",
             "w":[
                "TT. Thạnh Phú",
                "X. Phú Khánh",
                "X. Đại Điền",
                "X. Quới Điền",
                "X. Tân Phong",
                "X. Mỹ Hưng",
                "X. An Thạnh",
                "X. Thới Thạnh",
                "X. Hòa Lợi",
                "X. An Điền",
                "X. Bình Thạnh",
                "X. An Thuận",
                "X. An Quy",
                "X. Thạnh Hải",
                "X. An Nhơn",
                "X. Giao Thạnh",
                "X. Thạnh Phong",
                "X. Mỹ An"
             ],
             "codeErp":"5608"
          },
          {
             "n":"H. Mỏ Cày Bắc",
             "w":[
                "X. Phú Mỹ",
                "X. Hưng Khánh Trung A",
                "X. Thanh Tân",
                "X. Thạnh Ngãi",
                "X. Tân Phú Tây",
                "X. Phước Mỹ Trung",
                "X. Tân Thành Bình",
                "X. Thành An",
                "X. Hòa Lộc",
                "X. Tân Thanh Tây",
                "X. Tân Bình",
                "X. Nhuận Phú Tân",
                "X. Khánh Thạnh Tân"
             ],
             "codeErp":"5604"
          }
       ],
       "n":"Bến Tre",
       "codeErp":"56"
    },
    {
       "d":[
          {
             "n":"Q. Ninh Kiều",
             "w":[
                "P. Cái Khế",
                "P. An Hòa",
                "P. Thới Bình",
                "P. An Nghiệp",
                "P. An Cư",
                "P. Tân An",
                "P. An Phú",
                "P. Xuân Khánh",
                "P. Hưng Lợi",
                "P. An Khánh",
                "P. An Bình"
             ],
             "codeErp":"5501"
          },
          {
             "n":"Q. Ô Môn",
             "w":[
                "P. Châu Văn Liêm",
                "P. Thới Hòa",
                "P. Thới Long",
                "P. Long Hưng",
                "P. Thới An",
                "P. Phước Thới",
                "P. Trường Lạc"
             ],
             "codeErp":"5504"
          },
          {
             "n":"Q. Bình Thuỷ",
             "w":[
                "P. Bình Thủy",
                "P. Trà An",
                "P. Trà Nóc",
                "P. Thới An Đông",
                "P. An Thới",
                "P. Bùi Hữu Nghĩa",
                "P. Long Hòa",
                "P. Long Tuyền"
             ],
             "codeErp":"5502"
          },
          {
             "n":"Q. Cái Răng",
             "w":[
                "P. Lê Bình",
                "P. Hưng Phú",
                "P. Hưng Thạnh",
                "P. Ba Láng",
                "P. Thường Thạnh",
                "P. Phú Thứ",
                "P. Tân Phú"
             ],
             "codeErp":"5503"
          },
          {
             "n":"Q. Thốt Nốt",
             "w":[
                "P. Thốt Nốt",
                "P. Thới Thuận",
                "P. Thuận An",
                "P. Tân Lộc",
                "P. Trung Nhứt",
                "P. Thạnh Hoà",
                "P. Trung Kiên",
                "P. Tân Hưng",
                "P. Thuận Hưng"
             ],
             "codeErp":"5508"
          },
          {
             "n":"H. Vĩnh Thạnh",
             "w":[
                "X. Vĩnh Bình",
                "TT. Thanh An",
                "TT. Vĩnh Thạnh",
                "X. Thạnh Mỹ",
                "X. Vĩnh Trinh",
                "X. Thạnh An",
                "X. Thạnh Tiến",
                "X. Thạnh Thắng",
                "X. Thạnh Lợi",
                "X. Thạnh Qưới",
                "X. Thạnh Lộc"
             ],
             "codeErp":"5507"
          },
          {
             "n":"H. Cờ Đỏ",
             "w":[
                "X. Trung An",
                "X. Trung Thạnh",
                "X. Thạnh Phú",
                "X. Trung Hưng",
                "TT. Cờ Đỏ",
                "X. Thới Hưng",
                "X. Đông Hiệp",
                "X. Đông Thắng",
                "X. Thới Đông",
                "X. Thới Xuân"
             ],
             "codeErp":"5506"
          },
          {
             "n":"H. Phong Điền",
             "w":[
                "TT. Phong Điền",
                "X. Nhơn Ái",
                "X. Giai Xuân",
                "X. Tân Thới",
                "X. Trường Long",
                "X. Mỹ Khánh",
                "X. Nhơn Nghĩa"
             ],
             "codeErp":"5505"
          },
          {
             "n":"H. Thới Lai",
             "w":[
                "TT. Thới Lai",
                "X. Thới Thạnh",
                "X. Tân Thạnh",
                "X. Xuân Thắng",
                "X. Đông Bình",
                "X. Đông Thuận",
                "X. Thới Tân",
                "X. Trường Thắng",
                "X. Định Môn",
                "X. Trường Thành",
                "X. Trường Xuân",
                "X. Trường Xuân A",
                "X. Trường Xuân B"
             ],
             "codeErp":"5509"
          }
       ],
       "n":"Tp. Cần Thơ",
       "codeErp":"55"
    },
    {
       "d":[
          {
             "n":"TP. Cao Lãnh",
             "w":[
                "P. 11",
                "P. 1",
                "P. 2",
                "P. 4",
                "P. 3",
                "P. 6",
                "X. Mỹ Ngãi",
                "X. Mỹ Tân",
                "X. Mỹ Trà",
                "P. Mỹ Phú",
                "X. Tân Thuận Tây",
                "P. Hoà Thuận",
                "X. Hòa An",
                "X. Tân Thuận Đông",
                "X. Tịnh Thới"
             ],
             "codeErp":"5005"
          },
          {
             "n":"TP. Sa Đéc",
             "w":[
                "P. 3",
                "P. 1",
                "P. 4",
                "P. 2",
                "X. Tân Khánh Đông",
                "P. Tân Quy Đông",
                "P. An Hoà",
                "X. Tân Quy Tây",
                "X. Tân Phú Đông"
             ],
             "codeErp":"5004"
          },
          {
             "n":"TX. Hồng Ngự",
             "w":[
                "P. An Lộc",
                "P. An Thạnh",
                "X. Bình Thạnh",
                "X. Tân Hội",
                "P. An Lạc",
                "X. An Bình B",
                "X. An Bình A"
             ],
             "codeErp":"5010"
          },
          {
             "n":"H. Tân Hồng",
             "w":[
                "TT. Sa Rài",
                "X. Tân Hộ Cơ",
                "X. Thông Bình",
                "X. Bình Phú",
                "X. Tân Thành A",
                "X. Tân Thành B",
                "X. Tân Phước",
                "X. Tân Công Chí",
                "X. An Phước"
             ],
             "codeErp":"5012"
          },
          {
             "n":"H. Hồng Ngự",
             "w":[
                "X. Thường Phước 1",
                "X. Thường Thới Hậu A",
                "TT. Thường Thới Tiền",
                "X. Thường Phước 2",
                "X. Thường Lạc",
                "X. Long Khánh A",
                "X. Long Khánh B",
                "X. Long Thuận",
                "X. Phú Thuận B",
                "X. Phú Thuận A"
             ],
             "codeErp":"5011"
          },
          {
             "n":"H. Tam Nông",
             "w":[
                "TT. Tràm Chim",
                "X. Hoà Bình",
                "X. Tân Công Sính",
                "X. Phú Hiệp",
                "X. Phú Đức",
                "X. Phú Thành B",
                "X. An Hòa",
                "X. An Long",
                "X. Phú Cường",
                "X. Phú Ninh",
                "X. Phú Thọ",
                "X. Phú Thành A"
             ],
             "codeErp":"5008"
          },
          {
             "n":"H. Tháp Mười",
             "w":[
                "TT. Mỹ An",
                "X. Thạnh Lợi",
                "X. Hưng Thạnh",
                "X. Trường Xuân",
                "X. Tân Kiều",
                "X. Mỹ Hòa",
                "X. Mỹ Quý",
                "X. Mỹ Đông",
                "X. Đốc Binh Kiều",
                "X. Mỹ An",
                "X. Phú Điền",
                "X. Láng Biển",
                "X. Thanh Mỹ"
             ],
             "codeErp":"5007"
          },
          {
             "n":"H. Cao Lãnh",
             "w":[
                "TT. Mỹ Thọ",
                "X. Gáo Giồng",
                "X. Phương Thịnh",
                "X. Ba Sao",
                "X. Phong Mỹ",
                "X. Tân Nghĩa",
                "X. Phương Trà",
                "X. Nhị Mỹ",
                "X. Mỹ Thọ",
                "X. Tân Hội Trung",
                "X. An Bình",
                "X. Mỹ Hội",
                "X. Mỹ Hiệp",
                "X. Mỹ Long",
                "X. Bình Hàng Trung",
                "X. Mỹ Xương",
                "X. Bình Hàng Tây",
                "X. Bình Thạnh"
             ],
             "codeErp":"5006"
          },
          {
             "n":"H. Thanh Bình",
             "w":[
                "TT. Thanh Bình",
                "X. Tân Quới",
                "X. Tân Hòa",
                "X. An Phong",
                "X. Phú Lợi",
                "X. Tân Mỹ",
                "X. Bình Tấn",
                "X. Tân Huề",
                "X. Tân Bình",
                "X. Tân Thạnh",
                "X. Tân Phú",
                "X. Bình Thành",
                "X. Tân Long"
             ],
             "codeErp":"5009"
          },
          {
             "n":"H. Lấp Vò",
             "w":[
                "TT. Lấp Vò",
                "X. Mỹ An Hưng A",
                "X. Tân Mỹ",
                "X. Mỹ An Hưng B",
                "X. Tân  Khánh Trung",
                "X. Long Hưng A",
                "X. Vĩnh Thạnh",
                "X. Long Hưng B",
                "X. Bình Thành",
                "X. Định An",
                "X. Định Yên",
                "X. Hội An Đông",
                "X. Bình Thạnh Trung"
             ],
             "codeErp":"5003"
          },
          {
             "n":"H. Lai Vung",
             "w":[
                "TT. Lai Vung",
                "X. Tân Dương",
                "X. Hòa Thành",
                "X. Long Hậu",
                "X. Tân Phước",
                "X. Hòa Long",
                "X. Tân Thành",
                "X. Long Thắng",
                "X. Vĩnh Thới",
                "X. Tân Hòa",
                "X. Định Hòa",
                "X. Phong Hòa"
             ],
             "codeErp":"5002"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Cái Tàu Hạ",
                "X. An Hiệp",
                "X. An Nhơn",
                "X. Tân Nhuận Đông",
                "X. Tân Bình",
                "X. Tân Phú Trung",
                "X. Phú Long",
                "X. An Phú Thuận",
                "X. Phú Hựu",
                "X. An Khánh",
                "X. Tân Phú",
                "X. Hòa Tân"
             ],
             "codeErp":"5001"
          }
       ],
       "n":"Đồng Tháp",
       "codeErp":"50"
    },
    {
       "d":[
          {
             "n":"TP. Trà Vinh",
             "w":[
                "P. 4",
                "P. 1",
                "P. 3",
                "P. 2",
                "P. 5",
                "P. 6",
                "P. 7",
                "P. 8",
                "P. 9",
                "X. Long Đức"
             ],
             "codeErp":"5801"
          },
          {
             "n":"H. Càng Long",
             "w":[
                "TT. Càng Long",
                "X. Mỹ Cẩm",
                "X. An Trường A",
                "X. An Trường",
                "X. Huyền Hội",
                "X. Tân An",
                "X. Tân Bình",
                "X. Bình Phú",
                "X. Phương Thạnh",
                "X. Đại Phúc",
                "X. Đại Phước",
                "X. Nhị Long Phú",
                "X. Nhị Long",
                "X. Đức Mỹ"
             ],
             "codeErp":"5802"
          },
          {
             "n":"H. Cầu Kè",
             "w":[
                "TT. Cầu Kè",
                "X. Hòa Ân",
                "X. Châu Điền",
                "X. An Phú Tân",
                "X. Hoà Tân",
                "X. Ninh Thới",
                "X. Phong Phú",
                "X. Phong Thạnh",
                "X. Tam Ngãi",
                "X. Thông Hòa",
                "X. Thạnh Phú"
             ],
             "codeErp":"5803"
          },
          {
             "n":"H. Tiểu Cần",
             "w":[
                "TT. Tiểu Cần",
                "TT. Cầu Quan",
                "X. Phú Cần",
                "X. Hiếu Tử",
                "X. Hiếu Trung",
                "X. Long Thới",
                "X. Hùng Hòa",
                "X. Tân Hùng",
                "X. Tập Ngãi",
                "X. Ngãi Hùng",
                "X. Tân Hòa"
             ],
             "codeErp":"5804"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Châu Thành",
                "X. Đa Lộc",
                "X. Mỹ Chánh",
                "X. Thanh Mỹ",
                "X. Lương Hoà A",
                "X. Lương Hòa",
                "X. Song Lộc",
                "X. Nguyệt Hóa",
                "X. Hòa Thuận",
                "X. Hòa Lợi",
                "X. Phước Hảo",
                "X. Hưng Mỹ",
                "X. Hòa Minh",
                "X. Long Hòa"
             ],
             "codeErp":"5805"
          },
          {
             "n":"H. Cầu Ngang",
             "w":[
                "TT. Cầu Ngang",
                "TT. Mỹ Long",
                "X. Mỹ Long Bắc",
                "X. Mỹ Long Nam",
                "X. Mỹ Hòa",
                "X. Vĩnh Kim",
                "X. Kim Hòa",
                "X. Hiệp Hòa",
                "X. Thuận Hòa",
                "X. Long Sơn",
                "X. Nhị Trường",
                "X. Trường Thọ",
                "X. Hiệp Mỹ Đông",
                "X. Hiệp Mỹ Tây",
                "X. Thạnh Hòa Sơn"
             ],
             "codeErp":"5807"
          },
          {
             "n":"H. Trà Cú",
             "w":[
                "TT. Trà Cú",
                "TT. Định An",
                "X. Phước Hưng",
                "X. Tập Sơn",
                "X. Tân Sơn",
                "X. An Quảng Hữu",
                "X. Lưu Nghiệp Anh",
                "X. Ngãi Xuyên",
                "X. Kim Sơn",
                "X. Thanh Sơn",
                "X. Hàm Giang",
                "X. Hàm Tân",
                "X. Đại An",
                "X. Định An",
                "X. Ngọc Biên",
                "X. Long Hiệp",
                "X. Tân Hiệp"
             ],
             "codeErp":"5806"
          },
          {
             "n":"H. Duyên Hải",
             "w":[
                "X. Đôn Xuân",
                "X. Đôn Châu",
                "TT. Long Thành",
                "X. Long Khánh",
                "X. Ngũ Lạc",
                "X. Long Vĩnh",
                "X. Đông Hải"
             ],
             "codeErp":"5808"
          },
          {
             "n":"TX. Duyên Hải",
             "w":[
                "P. 1",
                "X. Long Toàn",
                "P. 2",
                "X. Long Hữu",
                "X. Dân Thành",
                "X. Trường Long Hòa",
                "X. Hiệp Thạnh"
             ],
             "codeErp":"5808"
          }
       ],
       "n":"Trà Vinh",
       "codeErp":"58"
    },
    {
       "d":[
          {
             "n":"TP. Cà Mau",
             "w":[
                "P. 9",
                "P. 4",
                "P. 1",
                "P. 5",
                "P. 2",
                "P. 8",
                "P. 6",
                "P. 7",
                "P. Tân Xuyên",
                "X. An Xuyên",
                "P. Tân Thành",
                "X. Tân Thành",
                "X. Tắc Vân",
                "X. Lý Văn Lâm",
                "X. Định Bình",
                "X. Hòa Thành",
                "X. Hòa Tân"
             ],
             "codeErp":"6101"
          },
          {
             "n":"H. U Minh",
             "w":[
                "TT. U Minh",
                "X. Khánh Hòa",
                "X. Khánh Thuận",
                "X. Khánh Tiến",
                "X. Nguyễn Phích",
                "X. Khánh Lâm",
                "X. Khánh An",
                "X. Khánh Hội"
             ],
             "codeErp":"6103"
          },
          {
             "n":"H. Thới Bình",
             "w":[
                "TT. Thới Bình",
                "X. Biển Bạch",
                "X. Tân Bằng",
                "X. Trí Phải",
                "X. Trí Lực",
                "X. Biển Bạch Đông",
                "X. Thới Bình",
                "X. Tân Phú",
                "X. Tân Lộc Bắc",
                "X. Tân Lộc",
                "X. Tân Lộc Đông",
                "X. Hồ Thị Kỷ"
             ],
             "codeErp":"6102"
          },
          {
             "n":"H. Trần Văn Thời",
             "w":[
                "TT. Trần Văn Thời",
                "TT. Sông Đốc",
                "X. Khánh Bình Tây Bắc",
                "X. Khánh Bình Tây",
                "X. Trần Hợi",
                "X. Khánh Lộc",
                "X. Khánh Bình",
                "X. Khánh Hưng",
                "X. Khánh Bình Đông",
                "X. Khánh Hải",
                "X. Lợi An",
                "X. Phong Điền",
                "X. Phong Lạc"
             ],
             "codeErp":"6104"
          },
          {
             "n":"H. Cái Nước",
             "w":[
                "TT. Cái Nước",
                "X. Thạnh Phú",
                "X. Lương Thế Trân",
                "X. Phú Hưng",
                "X. Tân Hưng",
                "X. Hưng Mỹ",
                "X. Hoà Mỹ",
                "X. Đông Hưng",
                "X. Đông Thới",
                "X. Tân Hưng Đông",
                "X. Trần Thới"
             ],
             "codeErp":"6105"
          },
          {
             "n":"H. Đầm Dơi",
             "w":[
                "TT. Đầm Dơi",
                "X. Tạ An Khương",
                "X. Tạ An Khương  Đông",
                "X. Trần Phán",
                "X. Tân Trung",
                "X. Tân Đức",
                "X. Tân Thuận",
                "X. Tạ An Khương  Nam",
                "X. Tân Duyệt",
                "X. Tân Dân",
                "X. Tân Tiến",
                "X. Quách Phẩm Bắc",
                "X. Quách Phẩm",
                "X. Thanh Tùng",
                "X. Ngọc Chánh",
                "X. Nguyễn Huân"
             ],
             "codeErp":"6106"
          },
          {
             "n":"H. Năm Căn",
             "w":[
                "TT. Năm Căn",
                "X. Hàm Rồng",
                "X. Hiệp Tùng",
                "X. Đất Mới",
                "X. Lâm Hải",
                "X. Hàng Vịnh",
                "X. Tam Giang",
                "X. Tam Giang Đông"
             ],
             "codeErp":"6108"
          },
          {
             "n":"H. Phú Tân",
             "w":[
                "TT. Cái Đôi Vàm",
                "X. Phú Thuận",
                "X. Phú Mỹ",
                "X. Phú Tân",
                "X. Tân Hải",
                "X. Việt Thắng",
                "X. Tân Hưng Tây",
                "X. Rạch Chèo",
                "X. Nguyễn Việt Khái"
             ],
             "codeErp":"6109"
          },
          {
             "n":"H. Ngọc Hiển",
             "w":[
                "X. Tam Giang Tây",
                "X. Tân Ân Tây",
                "X. Viên An Đông",
                "X. Viên An",
                "TT. Rạch Gốc",
                "X. Tân Ân",
                "X. Đất Mũi"
             ],
             "codeErp":"6107"
          }
       ],
       "n":"Cà Mau",
       "codeErp":"61"
    },
    {
       "d":[
          {
             "n":"TP. Rạch Giá",
             "w":[
                "P. Vĩnh Thanh Vân",
                "P. Vĩnh Thanh",
                "P. Vĩnh Quang",
                "P. Vĩnh Hiệp",
                "P. Vĩnh Bảo",
                "P. Vĩnh Lạc",
                "P. An Hòa",
                "P. An Bình",
                "P. Rạch Sỏi",
                "P. Vĩnh Lợi",
                "P. Vĩnh Thông",
                "X. Phi Thông"
             ],
             "codeErp":"5401"
          },
          {
             "n":"TP. Hà Tiên",
             "w":[
                "P. Tô Châu",
                "P. Đông Hồ",
                "P. Bình San",
                "P. Pháo Đài",
                "P. Mỹ Đức",
                "X. Tiên Hải",
                "X. Thuận Yên"
             ],
             "codeErp":"5402"
          },
          {
             "n":"H. Kiên Lương",
             "w":[
                "TT. Kiên Lương",
                "X. Kiên Bình",
                "X. Hòa Điền",
                "X. Dương Hòa",
                "X. Bình An",
                "X. Bình Trị",
                "X. Sơn Hải",
                "X. Hòn Nghệ"
             ],
             "codeErp":"5403"
          },
          {
             "n":"H. Hòn Đất",
             "w":[
                "TT. Hòn Đất",
                "TT. Sóc Sơn",
                "X. Bình Sơn",
                "X. Bình Giang",
                "X. Mỹ Thái",
                "X. Nam Thái Sơn",
                "X. Mỹ Hiệp Sơn",
                "X. Sơn Kiên",
                "X. Sơn Bình",
                "X. Mỹ Thuận",
                "X. Lình Huỳnh",
                "X. Thổ Sơn",
                "X. Mỹ Lâm",
                "X. Mỹ Phước"
             ],
             "codeErp":"5404"
          },
          {
             "n":"H. Tân Hiệp",
             "w":[
                "TT. Tân Hiệp",
                "X. Tân Hội",
                "X. Tân Thành",
                "X. Tân Hiệp B",
                "X. Tân Hoà",
                "X. Thạnh Đông B",
                "X. Thạnh Đông",
                "X. Tân Hiệp A",
                "X. Tân An",
                "X. Thạnh Đông A",
                "X. Thạnh Trị"
             ],
             "codeErp":"5405"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Minh Lương",
                "X. Mong Thọ A",
                "X. Mong Thọ B",
                "X. Mong Thọ",
                "X. Giục Tượng",
                "X. Vĩnh Hòa Hiệp",
                "X. Vĩnh Hoà Phú",
                "X. Minh Hòa",
                "X. Bình An",
                "X. Thạnh Lộc"
             ],
             "codeErp":"5406"
          },
          {
             "n":"H. Giồng Riềng",
             "w":[
                "TT. Giồng Riềng",
                "X. Thạnh Hưng",
                "X. Thạnh Phước",
                "X. Thạnh Lộc",
                "X. Thạnh Hòa",
                "X. Thạnh Bình",
                "X. Bàn Thạch",
                "X. Bàn Tân Định",
                "X. Ngọc Thành",
                "X. Ngọc Chúc",
                "X. Ngọc Thuận",
                "X. Hòa Hưng",
                "X. Hoà Lợi",
                "X. Hoà An",
                "X. Long Thạnh",
                "X. Vĩnh Thạnh",
                "X. Vĩnh Phú",
                "X.  Hòa Thuận",
                "X. Ngọc Hoà"
             ],
             "codeErp":"5407"
          },
          {
             "n":"H. Gò Quao",
             "w":[
                "TT. Gò Quao",
                "X. Vĩnh Hòa Hưng Bắc",
                "X. Định Hòa",
                "X. Thới Quản",
                "X. Định An",
                "X. Thủy Liễu",
                "X. Vĩnh Hòa Hưng Nam",
                "X. Vĩnh Phước A",
                "X. Vĩnh Phước B",
                "X. Vĩnh Tuy",
                "X. Vĩnh Thắng"
             ],
             "codeErp":"5408"
          },
          {
             "n":"H. An Biên",
             "w":[
                "TT. Thứ Ba",
                "X. Tây Yên",
                "X. Tây Yên A",
                "X. Nam Yên",
                "X. Hưng Yên",
                "X. Nam Thái",
                "X. Nam Thái A",
                "X. Đông Thái",
                "X. Đông Yên"
             ],
             "codeErp":"5409"
          },
          {
             "n":"H. An Minh",
             "w":[
                "TT. Thứ Mười Một",
                "X. Thuận Hoà",
                "X. Đông Hòa",
                "X. Đông Thạnh",
                "X. Tân Thạnh",
                "X. Đông Hưng",
                "X. Đông Hưng A",
                "X. Đông Hưng B",
                "X. Vân Khánh",
                "X. Vân Khánh Đông",
                "X. Vân Khánh Tây"
             ],
             "codeErp":"5410"
          },
          {
             "n":"H. Vĩnh Thuận",
             "w":[
                "TT. Vĩnh Thuận",
                "X. Vĩnh Bình Bắc",
                "X. Vĩnh Bình Nam",
                "X. Bình Minh",
                "X. Vĩnh Thuận",
                "X. Tân Thuận",
                "X. Phong Đông",
                "X. Vĩnh Phong"
             ],
             "codeErp":"5411"
          },
          {
             "n":"H. Phú Quốc",
             "w":[
                "TT. Dương Đông",
                "TT. An Thới",
                "X. Cửa Cạn",
                "X. Gành Dầu",
                "X. Cửa Dương",
                "X. Hàm Ninh",
                "X. Dương Tơ",
                "X. Hòn Thơm",
                "X. Bãi Thơm",
                "X. Thổ Châu"
             ],
             "codeErp":"5412"
          },
          {
             "n":"H. Kiên Hải",
             "w":[
                "X. Hòn Tre",
                "X. Lại Sơn",
                "X. An Sơn",
                "X. Nam Du"
             ],
             "codeErp":"5413"
          },
          {
             "n":"H. U Minh Thượng",
             "w":[
                "X. Thạnh Yên",
                "X. Thạnh Yên A",
                "X. An Minh Bắc",
                "X. Vĩnh Hòa",
                "X. Hoà Chánh",
                "X. Minh Thuận"
             ],
             "codeErp":"5414"
          },
          {
             "n":"H. Giang Thành",
             "w":[
                "X. Vĩnh Phú",
                "X. Vĩnh Điều",
                "X. Tân Khánh Hòa",
                "X. Phú Lợi",
                "X. Phú Mỹ"
             ],
             "codeErp":"5415"
          }
       ],
       "n":"Kiên Giang",
       "codeErp":"54"
    },
    {
       "d":[
          {
             "n":"TP. Long Xuyên",
             "w":[
                "P. Mỹ Bình",
                "P. Mỹ Long",
                "P. Đông Xuyên",
                "P. Mỹ Xuyên",
                "P. Bình Đức",
                "P. Bình Khánh",
                "P. Mỹ Phước",
                "P. Mỹ Quý",
                "P. Mỹ Thới",
                "P. Mỹ Thạnh",
                "P. Mỹ Hòa",
                "X. Mỹ Khánh",
                "X. Mỹ Hoà Hưng"
             ],
             "codeErp":"5102"
          },
          {
             "n":"TP. Châu Đốc",
             "w":[
                "P. Châu Phú B",
                "P. Châu Phú A",
                "P. Vĩnh Mỹ",
                "P. Núi Sam",
                "P. Vĩnh Ngươn",
                "X. Vĩnh Tế",
                "X. Vĩnh Châu"
             ],
             "codeErp":"5103"
          },
          {
             "n":"H. An Phú",
             "w":[
                "TT. An Phú",
                "X. Khánh An",
                "TT. Long Bình",
                "X. Khánh Bình",
                "X. Quốc Thái",
                "X. Nhơn Hội",
                "X. Phú Hữu",
                "X. Phú Hội",
                "X. Phước Hưng",
                "X. Vĩnh Lộc",
                "X. Vĩnh Hậu",
                "X. Vĩnh Trường",
                "X. Vĩnh Hội Đông",
                "X. Đa Phước"
             ],
             "codeErp":"5104"
          },
          {
             "n":"TX. Tân Châu",
             "w":[
                "P. Long Thạnh",
                "P. Long Hưng",
                "P. Long Châu",
                "X. Phú Lộc",
                "X. Vĩnh Xương",
                "X. Vĩnh Hòa",
                "X. Tân Thạnh",
                "X. Tân An",
                "X. Long An",
                "P. Long Phú",
                "X. Châu Phong",
                "X. Phú Vĩnh",
                "X. Lê Chánh",
                "P. Long Sơn"
             ],
             "codeErp":"5105"
          },
          {
             "n":"H. Phú Tân",
             "w":[
                "TT. Phú Mỹ",
                "TT. Chợ Vàm",
                "X. Long Hoà",
                "X. Phú Long",
                "X. Phú Lâm",
                "X. Phú Hiệp",
                "X. Phú Thạnh",
                "X. Hoà Lạc",
                "X. Phú Thành",
                "X. Phú An",
                "X. Phú Xuân",
                "X. Hiệp Xương",
                "X. Phú Bình",
                "X. Phú Thọ",
                "X. Phú Hưng",
                "X. Bình Thạnh Đông",
                "X. Tân Hòa",
                "X. Tân Trung"
             ],
             "codeErp":"5106"
          },
          {
             "n":"H. Châu Phú",
             "w":[
                "TT. Cái Dầu",
                "X. Khánh Hòa",
                "X. Mỹ Đức",
                "X. Mỹ Phú",
                "X. Ô Long Vỹ",
                "X. Vĩnh Thạnh Trung",
                "X. Thạnh Mỹ Tây",
                "X. Bình Long",
                "X. Bình Mỹ",
                "X. Bình Thủy",
                "X. Đào Hữu Cảnh",
                "X. Bình Phú",
                "X. Bình Chánh"
             ],
             "codeErp":"5109"
          },
          {
             "n":"H. Tịnh Biên",
             "w":[
                "TT. Nhà Bàng",
                "TT. Chi Lăng",
                "X. Núi Voi",
                "X. Nhơn Hưng",
                "X. An Phú",
                "X. Thới Sơn",
                "TT. Tịnh Biên",
                "X. Văn Giáo",
                "X. An Cư",
                "X. An Nông",
                "X. Vĩnh Trung",
                "X. Tân Lợi",
                "X. An Hảo",
                "X. Tân Lập"
             ],
             "codeErp":"5107"
          },
          {
             "n":"H. Tri Tôn",
             "w":[
                "TT. Tri Tôn",
                "TT. Ba Chúc",
                "X. Lạc Quới",
                "X. Lê Trì",
                "X. Vĩnh Gia",
                "X. Vĩnh Phước",
                "X. Châu Lăng",
                "X. Lương Phi",
                "X. Lương An Trà",
                "X. Tà Đảnh",
                "X. Núi Tô",
                "X. An Tức",
                "X. Cô Tô",
                "X. Tân Tuyến",
                "X. Ô Lâm"
             ],
             "codeErp":"5108"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. An Châu",
                "X. An Hòa",
                "X. Cần Đăng",
                "X. Vĩnh Hanh",
                "X. Bình Thạnh",
                "X. Vĩnh Bình",
                "X. Bình Hòa",
                "X. Vĩnh An",
                "X. Hòa Bình Thạnh",
                "X. Vĩnh Lợi",
                "X. Vĩnh Nhuận",
                "X. Tân Phú",
                "X. Vĩnh Thành"
             ],
             "codeErp":"5111"
          },
          {
             "n":"H. Chợ Mới",
             "w":[
                "TT. Chợ Mới",
                "TT. Mỹ Luông",
                "X. Kiến An",
                "X. Mỹ Hội Đông",
                "X. Long Điền A",
                "X. Tấn Mỹ",
                "X. Long Điền B",
                "X. Kiến Thành",
                "X. Mỹ Hiệp",
                "X. Mỹ An",
                "X. Nhơn Mỹ",
                "X. Long Giang",
                "X. Long Kiến",
                "X. Bình Phước Xuân",
                "X. An Thạnh Trung",
                "X. Hội An",
                "X. Hòa Bình",
                "X. Hòa An"
             ],
             "codeErp":"5110"
          },
          {
             "n":"H. Thoại Sơn",
             "w":[
                "TT. Núi Sập",
                "TT. Phú Hoà",
                "TT. Óc Eo",
                "X. Tây Phú",
                "X. An Bình",
                "X. Vĩnh Phú",
                "X. Vĩnh Trạch",
                "X. Phú Thuận",
                "X. Vĩnh Chánh",
                "X. Định Mỹ",
                "X. Định Thành",
                "X. Mỹ Phú Đông",
                "X. Vọng Đông",
                "X. Vĩnh Khánh",
                "X. Thoại Giang",
                "X. Bình Thành",
                "X. Vọng Thê"
             ],
             "codeErp":"5112"
          }
       ],
       "n":"An Giang",
       "codeErp":"51"
    },
    {
       "d":[
          {
             "n":"TP. Sóc Trăng",
             "w":[
                "P. 5",
                "P. 7",
                "P. 8",
                "P. 6",
                "P. 2",
                "P. 1",
                "P. 4",
                "P. 3",
                "P. 9",
                "P. 10"
             ],
             "codeErp":"5901"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Châu Thành",
                "X. Hồ Đắc Kiện",
                "X. Phú Tâm",
                "X. Thuận Hòa",
                "X. Phú Tân",
                "X. Thiện Mỹ",
                "X. An Hiệp",
                "X. An Ninh"
             ],
             "codeErp":"5910"
          },
          {
             "n":"H. Kế Sách",
             "w":[
                "TT. Kế Sách",
                "TT. An Lạc Thôn",
                "X. Xuân Hòa",
                "X. Phong Nẫm",
                "X. An Lạc Tây",
                "X. Trinh Phú",
                "X. Ba Trinh",
                "X. Thới An Hội",
                "X. Nhơn Mỹ",
                "X. Kế Thành",
                "X. Kế An",
                "X. Đại Hải",
                "X. An Mỹ"
             ],
             "codeErp":"5902"
          },
          {
             "n":"H. Mỹ Tú",
             "w":[
                "TT. Huỳnh Hữu Nghĩa",
                "X. Long Hưng",
                "X. Hưng Phú",
                "X. Mỹ Hương",
                "X. Mỹ Tú",
                "X. Mỹ Phước",
                "X. Thuận Hưng",
                "X. Mỹ Thuận",
                "X. Phú Mỹ"
             ],
             "codeErp":"5903"
          },
          {
             "n":"H. Cù Lao Dung",
             "w":[
                "TT. Cù Lao Dung",
                "X. An Thạnh 1",
                "X. An Thạnh Tây",
                "X. An Thạnh Đông",
                "X. Đại Ân 1",
                "X. An Thạnh 2",
                "X. An Thạnh 3",
                "X. An Thạnh Nam"
             ],
             "codeErp":"5908"
          },
          {
             "n":"H. Long Phú",
             "w":[
                "TT. Long Phú",
                "X. Song Phụng",
                "TT. Đại Ngãi",
                "X. Hậu Thạnh",
                "X. Long Đức",
                "X. Trường Khánh",
                "X. Phú Hữu",
                "X. Tân Hưng",
                "X. Châu Khánh",
                "X. Tân Thạnh",
                "X. Long Phú"
             ],
             "codeErp":"5906"
          },
          {
             "n":"H. Mỹ Xuyên",
             "w":[
                "TT. Mỹ Xuyên",
                "X. Đại Tâm",
                "X. Tham Đôn",
                "X. Thạnh Phú",
                "X. Ngọc Đông",
                "X. Thạnh Quới",
                "X. Hòa Tú 1",
                "X. Gia Hòa 1",
                "X. Ngọc Tố",
                "X. Gia Hòa 2",
                "X. Hòa Tú II"
             ],
             "codeErp":"5904"
          },
          {
             "n":"TX. Ngã Năm",
             "w":[
                "P. 1",
                "P. 2",
                "X. Vĩnh Quới",
                "X. Tân Long",
                "X. Long Bình",
                "P. 3",
                "X. Mỹ Bình",
                "X. Mỹ Quới"
             ],
             "codeErp":"5909"
          },
          {
             "n":"H. Thạnh Trị",
             "w":[
                "TT. Phú Lộc",
                "TT. Hưng Lợi",
                "X. Lâm Tân",
                "X. Thạnh Tân",
                "X. Lâm Kiết",
                "X. Tuân Tức",
                "X. Vĩnh Thành",
                "X. Thạnh Trị",
                "X. Vĩnh Lợi",
                "X. Châu Hưng"
             ],
             "codeErp":"5905"
          },
          {
             "n":"TX. Vĩnh Châu",
             "w":[
                "P. 1",
                "X. Hòa Đông",
                "P. Khánh Hòa",
                "X. Vĩnh Hiệp",
                "X. Vĩnh Hải",
                "X. Lạc Hòa",
                "P. 2",
                "P. Vĩnh Phước",
                "X. Vĩnh Tân",
                "X. Lai Hòa"
             ],
             "codeErp":"5907"
          },
          {
             "n":"H. Trần Đề",
             "w":[
                "X. Đại Ân  2",
                "TT. Trần Đề",
                "X. Liêu Tú",
                "X. Lịch Hội Thượng",
                "TT. Lịch Hội Thượng",
                "X. Trung Bình",
                "X. Tài Văn",
                "X. Viên An",
                "X. Thạnh Thới An",
                "X. Thạnh Thới Thuận",
                "X. Viên Bình"
             ],
             "codeErp":"5911"
          }
       ],
       "n":"Sóc Trăng",
       "codeErp":"59"
    },
    {
       "d":[
          {
             "n":"TP. Vị Thanh",
             "w":[
                "P. I",
                "P. III",
                "P. IV",
                "P. V",
                "P. VII",
                "X. Vị Tân",
                "X. Hoả Lựu",
                "X. Tân Tiến",
                "X. Hoả Tiến"
             ],
             "codeErp":"6401"
          },
          {
             "n":"TP. Ngã Bảy",
             "w":[
                "P. Ngã Bảy",
                "P. Lái Hiếu",
                "P. Hiệp Thành",
                "P. Hiệp Lợi",
                "X. Đại Thành",
                "X. Tân Thành"
             ],
             "codeErp":"6407"
          },
          {
             "n":"H. Châu Thành A",
             "w":[
                "TT. Một Ngàn",
                "X. Tân Hoà",
                "TT. Bảy Ngàn",
                "X. Trường Long Tây",
                "X. Trường Long A",
                "X. Nhơn Nghĩa A",
                "TT. Rạch Gòi",
                "X. Thạnh Xuân",
                "TT. Cái Tắc",
                "X. Tân Phú Thạnh"
             ],
             "codeErp":"6406"
          },
          {
             "n":"H. Châu Thành",
             "w":[
                "TT. Ngã Sáu",
                "X. Đông Thạnh",
                "X. Đông Phú",
                "X. Phú Hữu",
                "X. Phú Tân",
                "TT. Mái Dầm",
                "X. Đông Phước",
                "X. Đông Phước A"
             ],
             "codeErp":"6405"
          },
          {
             "n":"H. Phụng Hiệp",
             "w":[
                "TT. Kinh Cùng",
                "TT. Cây Dương",
                "X. Tân Bình",
                "X. Bình Thành",
                "X. Thạnh Hòa",
                "X. Long Thạnh",
                "X. Phụng Hiệp",
                "X. Hòa Mỹ",
                "X. Hòa An",
                "X. Phương Bình",
                "X. Hiệp Hưng",
                "X. Tân Phước Hưng",
                "TT. Búng Tàu",
                "X. Phương Phú",
                "X. Tân Long"
             ],
             "codeErp":"6404"
          },
          {
             "n":"H. Vị Thuỷ",
             "w":[
                "TT. Nàng Mau",
                "X. Vị Trung",
                "X. Vị Thuỷ",
                "X. Vị Thắng",
                "X. Vĩnh Thuận Tây",
                "X. Vĩnh Trung",
                "X. Vĩnh Tường",
                "X. Vị Đông",
                "X. Vị Thanh",
                "X. Vị Bình"
             ],
             "codeErp":"6402"
          },
          {
             "n":"H. Long Mỹ",
             "w":[
                "X. Thuận Hưng",
                "X. Thuận Hòa",
                "X. Vĩnh Thuận Đông",
                "TT. Vĩnh Viễn",
                "X. Vĩnh Viễn A",
                "X. Lương Tâm",
                "X. Lương Nghĩa",
                "X. Xà Phiên"
             ],
             "codeErp":"6403"
          },
          {
             "n":"TX. Long Mỹ",
             "w":[
                "P. Thuận An",
                "P. Trà Lồng",
                "P. Bình Thạnh",
                "X. Long Bình",
                "P. Vĩnh Tường",
                "X. Long Trị",
                "X. Long Trị A",
                "X. Long Phú",
                "X. Tân Phú"
             ],
             "codeErp":"6403"
          }
       ],
       "n":"Hậu Giang",
       "codeErp":"64"
    },
    {
       "d":[
          {
             "n":"TP. Bạc Liêu",
             "w":[
                "P. 2",
                "P. 3",
                "P. 5",
                "P. 7",
                "P. 1",
                "P. 8",
                "P. Nhà Mát",
                "X. Vĩnh Trạch",
                "X. Vĩnh Trạch Đông",
                "X. Hiệp Thành"
             ],
             "codeErp":"6001"
          },
          {
             "n":"H. Hồng Dân",
             "w":[
                "TT. Ngan Dừa",
                "X. Ninh Quới",
                "X. Ninh Quới A",
                "X. Ninh Hòa",
                "X. Lộc Ninh",
                "X. Vĩnh Lộc",
                "X. Vĩnh Lộc A",
                "X. Ninh Thạnh Lợi A",
                "X. Ninh Thạnh Lợi"
             ],
             "codeErp":"6003"
          },
          {
             "n":"H. Phước Long",
             "w":[
                "TT. Phước Long",
                "X. Vĩnh Phú Đông",
                "X. Vĩnh Phú Tây",
                "X. Phước Long",
                "X. Hưng Phú",
                "X. Vĩnh Thanh",
                "X. Phong Thạnh Tây A",
                "X. Phong Thạnh Tây B"
             ],
             "codeErp":"6005"
          },
          {
             "n":"H. Vĩnh Lợi",
             "w":[
                "X. Vĩnh Hưng",
                "X. Vĩnh Hưng A",
                "TT. Châu Hưng",
                "X. Châu Hưng A",
                "X. Hưng Thành",
                "X. Hưng Hội",
                "X. Châu Thới",
                "X. Long Thạnh"
             ],
             "codeErp":"6002"
          },
          {
             "n":"TX. Giá Rai",
             "w":[
                "P. 1",
                "P. Hộ Phòng",
                "X. Phong Thạnh Đông",
                "P. Láng Tròn",
                "X. Phong Tân",
                "X. Tân Phong",
                "X. Phong Thạnh",
                "X. Phong Thạnh A",
                "X. Phong Thạnh Tây",
                "X. Tân Thạnh"
             ],
             "codeErp":"6004"
          },
          {
             "n":"H. Đông Hải",
             "w":[
                "TT. Gành Hào",
                "X. Long Điền Đông",
                "X. Long Điền Đông A",
                "X. Long Điền",
                "X. Long Điền Tây",
                "X. Điền Hải",
                "X. An Trạch",
                "X. An Trạch A",
                "X. An Phúc",
                "X. Định Thành",
                "X. Định Thành A"
             ],
             "codeErp":"6006"
          },
          {
             "n":"H. Hoà Bình",
             "w":[
                "TT. Hòa Bình",
                "X. Minh Diệu",
                "X. Vĩnh Bình",
                "X. Vĩnh Mỹ B",
                "X. Vĩnh Hậu",
                "X. Vĩnh Hậu A",
                "X. Vĩnh Mỹ A",
                "X. Vĩnh Thịnh"
             ],
             "codeErp":"6007"
          }
       ],
       "n":"Bạc Liêu",
       "codeErp":"60"
    },
    {
       "d":[
          {
             "n":"TP. Vĩnh Long",
             "w":[
                "P. 9",
                "P. 5",
                "P. 1",
                "P. 2",
                "P. 4",
                "P. 3",
                "P. 8",
                "P. Tân Ngãi",
                "P. Tân Hòa",
                "P. Tân Hội",
                "P. Trường An"
             ],
             "codeErp":"5701"
          },
          {
             "n":"H. Long Hồ",
             "w":[
                "TT. Long Hồ",
                "X. Đồng Phú",
                "X. Bình Hòa Phước",
                "X. Hòa Ninh",
                "X. An Bình",
                "X. Thanh Đức",
                "X. Tân Hạnh",
                "X. Phước Hậu",
                "X. Long Phước",
                "X. Phú Đức",
                "X. Lộc Hòa",
                "X. Long An",
                "X. Phú Quới",
                "X. Thạnh Quới",
                "X. Hòa Phú"
             ],
             "codeErp":"5702"
          },
          {
             "n":"H. Mang Thít",
             "w":[
                "X. Mỹ An",
                "X. Mỹ Phước",
                "X. An Phước",
                "X. Nhơn Phú",
                "X. Long Mỹ",
                "X. Hòa Tịnh",
                "TT. Cái Nhum",
                "X. Bình Phước",
                "X. Chánh An",
                "X. Tân An Hội",
                "X. Tân Long",
                "X. Tân Long Hội"
             ],
             "codeErp":"5703"
          },
          {
             "n":"H. Vũng Liêm",
             "w":[
                "TT. Vũng Liêm",
                "X. Tân Quới Trung",
                "X. Quới Thiện",
                "X. Quới An",
                "X. Trung Chánh",
                "X. Tân An Luông",
                "X. Thanh Bình",
                "X. Trung Thành Tây",
                "X. Trung Hiệp",
                "X. Hiếu Phụng",
                "X. Trung Thành Đông",
                "X. Trung Thành",
                "X. Trung Hiếu",
                "X. Trung Ngãi",
                "X. Hiếu Thuận",
                "X. Trung Nghĩa",
                "X. Trung An",
                "X. Hiếu Nhơn",
                "X. Hiếu Thành",
                "X. Hiếu Nghĩa"
             ],
             "codeErp":"5707"
          },
          {
             "n":"H. Tam Bình",
             "w":[
                "TT. Tam Bình",
                "X. Tân Lộc",
                "X. Phú Thịnh",
                "X. Hậu Lộc",
                "X. Hòa Thạnh",
                "X. Hoà Lộc",
                "X. Phú Lộc",
                "X. Song Phú",
                "X. Hòa Hiệp",
                "X. Mỹ Lộc",
                "X. Tân Phú",
                "X. Long Phú",
                "X. Mỹ Thạnh Trung",
                "X. Tường Lộc",
                "X. Loan Mỹ",
                "X. Ngãi Tứ",
                "X. Bình Ninh"
             ],
             "codeErp":"5705"
          },
          {
             "n":"TX. Bình Minh",
             "w":[
                "P. Cái Vồn",
                "P. Thành Phước",
                "X. Thuận An",
                "X. Đông Thạnh",
                "X. Đông Bình",
                "P. Đông Thuận",
                "X. Mỹ Hòa",
                "X. Đông Thành"
             ],
             "codeErp":"5704"
          },
          {
             "n":"H. Trà Ôn",
             "w":[
                "TT. Trà Ôn",
                "X. Xuân Hiệp",
                "X. Nhơn Bình",
                "X. Hòa Bình",
                "X. Thới Hòa",
                "X. Trà Côn",
                "X. Tân Mỹ",
                "X. Hựu Thành",
                "X. Vĩnh Xuân",
                "X. Thuận Thới",
                "X. Phú Thành",
                "X. Thiện Mỹ",
                "X. Lục Sỹ Thành",
                "X. Tích Thiện"
             ],
             "codeErp":"5706"
          },
          {
             "n":"H. Bình Tân",
             "w":[
                "X. Tân Hưng",
                "X. Tân Thành",
                "X. Thành Trung",
                "X. Tân An Thạnh",
                "X. Tân Lược",
                "X. Nguyễn Văn Thảnh",
                "X. Thành Lợi",
                "X. Mỹ Thuận",
                "X. Tân Bình",
                "TT. Tân Quới"
             ],
             "codeErp":"5708"
          }
       ],
       "n":"Vĩnh Long",
       "codeErp":"57"
    }
 ]`;

let HANH_CHINH_VN_JSON = JSON.parse(HANH_CHINH_VN_Str).sort((a, b) => {
    return a.n.localeCompare(b.n);
})

HANH_CHINH_VN_JSON = HANH_CHINH_VN_JSON.map((city: any) => {
    city.d.sort((a, b) => {
        return a.n.localeCompare(b.n);
    })
    city.d = city.d.map((district: any) => {
        district.w.sort((c, d) => {
            return c.localeCompare(d);
        })
        return district;
    })
    return city;
})

export const HANH_CHINH_VN = JSON.stringify(HANH_CHINH_VN_JSON);