import { MoneyToNumber } from "../../shared/parse/money-to-number";

export class GeoLocation {
    lat: number;
    lng: number;
    type: string;
    coordinates: any = [];
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.lat = params.lat || 0;
            this.lng = params.lng || 0;
            if (!this.lat && !this.lng)  {
                this.lat = params.coordinates && params.coordinates.length > 0 ? params.coordinates[1] : 0;
                this.lng = params.coordinates && params.coordinates.length > 0 ? params.coordinates[0] : 0;
            }
        } else {
            this.setAllNull();
        }
    } 

    setAllNull() {
        this.lat = 0;
        this.lng = 0;
    }
    validate() {
        let errors: any = {};
        errors.isExist = false;
        // if (!this.lat) {
        //     errors.lat = 'Location Lat phải có giá trị';
        //     errors.isExist = true;
        // }
        // if (!this.lng) {
        //     errors.lng = 'Location Lng phải có giá trị';
        //     errors.isExist = true;
        // }
        // if (!this.lat || !this.lng) {
        //     errors.error = 'Vị trí chưa được định hình rõ ràng ! Vui lòng chọn địa chỉ theo ô gợi ý hoặc chọn trên bản đồ.';
        // }
        return errors;
    }
    createObjectRequestAPI() {
        let object: any = {
            type: "Point",
            coordinates: [MoneyToNumber.parse(this.lng), MoneyToNumber.parse(this.lat)]
        };
        return object;
    }
}
