export class DeliveryConfigurationModel {
    stt: number;
    id: string;
    project: any;
    expectedStartDate: any;
    expectedEndDate: any;
    numberOfSession: number;
    status: boolean;
    accountingConfirm: boolean;
    sessions: DeliverySessionModel[];
    items: DeliveryItemGroupModel[];
    employeeImplements: any;
    hotline: string;
    paymentPercent: number;
    emailTemplate: string;
    emailTitle: string;
    emailFrom: string;
    emailCC: string;
    emailBCC: string;
    smsBrandName: string;
    smsTemplate: string;
    mondayFrame: any;
    tuesdayFrame: any;
    wednesdayFrame: any;
    thursdayFrame: any;
    fridayFrame: any;
    saturdayFrame: any;
    sundayFrame: any;

    orgCharts: any;

    constructor(params?: any) {
        if (params) {
            this.id = params.id || '';
            this.project = params.project || null;
            this.expectedStartDate = params.expectedStartDate || null;
            this.expectedEndDate = params.expectedEndDate || null;
            this.numberOfSession = params.numberOfSession || 0;
            this.status = params.status || false;
            this.accountingConfirm = params.accountingConfirm || false;
            this.sessions = params.sessions || [];
            this.items = params.items || [];
            this.employeeImplements = params.employeeImplements || [];
            this.hotline = params.hotline ? params.hotline : '';
            this.paymentPercent = params.paymentPercent ? Number(params.paymentPercent) : null;
            this.emailTemplate = params.emailTemplate || '';
            this.emailTitle = params.emailTitle || '';
            this.emailFrom = params.emailFrom || '';
            this.emailCC = params.emailCC || '';
            this.emailBCC = params.emailBCC || '';
            this.smsBrandName = params.smsBrandName || '';
            this.smsTemplate = params.smsTemplate || '';
            this.mondayFrame = params.mondayFrame || [];
            this.tuesdayFrame = params.tuesdayFrame || [];
            this.wednesdayFrame = params.wednesdayFrame || [];
            this.thursdayFrame = params.thursdayFrame || [];
            this.fridayFrame = params.fridayFrame || [];
            this.saturdayFrame = params.saturdayFrame || [];
            this.sundayFrame = params.sundayFrame || [];

            this.orgCharts = params.orgCharts || [];
        } else {
            this.id = '';
            this.project = null;
            this.expectedStartDate = null;
            this.expectedEndDate = null;
            this.numberOfSession = 0;
            this.status = true;
            this.accountingConfirm = false;
            this.sessions = [];
            this.items = [];
            this.employeeImplements = [];
            this.hotline = '';
            this.paymentPercent = null;
            this.emailTemplate = '';
            this.smsBrandName = '';
            this.smsTemplate = '';
            this.emailTitle = '';
            this.emailFrom = '';
            this.emailCC = '';
            this.emailBCC = '';
            this.mondayFrame = [];
            this.tuesdayFrame = [];
            this.wednesdayFrame = [];
            this.thursdayFrame = [];
            this.fridayFrame = [];
            this.saturdayFrame = [];
            this.sundayFrame = [];
            this.orgCharts = []
        }
    }
}

export class DeliverySessionModel {
    stt: number;
    name: string;
    startDate: any;
    endDate: any;
    productCodes: string[];
    emailTemplate: string;
    emailTitle: string;
    emailFrom: string;
    emailCC: string;
    emailBCC: string;
    smsBrandName: string;
    smsTemplate: string;
    status: boolean;
    exception: DeliveryExceptionModel;

    constructor (params?: any) {
        this.name = params ? params.name || null : null;
        this.startDate = params ? params.startDate || null : null;
        this.endDate = params ? params.endDate || null : null;
        this.productCodes = params ? params.productCodes || [] : [];
        this.emailTemplate = params ? params.emailTemplate || null : null;
        this.emailTitle = params ? params.emailTitle || null : null;
        this.emailFrom = params ? params.emailFrom || null : null;
        this.emailCC = params ? params.emailCC || null : null;
        this.emailBCC = params ? params.emailBCC || null : null;
        this.smsBrandName = params ? params.smsBrandName || null : null;
        this.smsTemplate = params ? params.smsTemplate || null : null;
        this.status = params ? params.status || true : true;
        this.exception = params ? params.exception || new DeliveryExceptionModel() : new DeliveryExceptionModel();
    }
}

export class DeliveryExceptionModel {
    priority: boolean;
    productCodes: string[];
    reason: string;

    constructor (params?: any) {
        this.priority = params ? params.priority || false : false;
        this.productCodes = params ? params.productCodes || [] : [];
        this.reason = params ? params.reason || null : null;
    }
}

export class DeliveryItemGroupModel {
    stt: number;
    name: string;
    list: DeliveryItemModel[];

    constructor(params?: any) {
        this.name = params ? params.name || null : null;
        this.list = params ? params.list || [] : [];
    }
}

export class DeliveryItemModel {
    stt: number;
    title: string;
    type: string;
    indexValue: string;
    description: string;
    reason: string;
    isPass: boolean;

    constructor(params?: any) {
        this.title = params ? params.title || null : null;
        this.type = params ? params.type || null : null;
        this.indexValue = params ? params.indexValue || '' : '';
        this.description = params ? params.description || null : null;
        this.reason = params ? params.reason || '' : '';
        this.isPass = params ? params.isPass || true : true;
    }
}

export class DeliveryModel {
    stt: number;
    id: string;
    primaryTransaction: any;
    handoverStatus: string;
    isSendDelivery: boolean;
    deliveryDate: any;
    deliveryResult: DeliveryResultModel;
    projectType: string;
    checklist: boolean;
    session: string;
    smsBrandName: string;
    smsTemplate: string;
    emailTemplate: string;
    emailContentPreview: string;
    careInvestor: any;
    handoverSchedule: any;

    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.primaryTransaction = params ? params.primaryTransaction || null : null;
        this.handoverStatus = params ? params.handoverStatus || null : null;
        this.isSendDelivery = params ? params.isSendDelivery || false : false;
        this.deliveryDate = params ? params.deliveryDate || null : null;
        this.deliveryResult = params ? params.deliveryResult || null : null;
        this.projectType = params ? params.projectType || null : null;
        this.checklist = params ? params.checklist || false : false;
        this.session = params ? params.session || null : null;
        this.smsBrandName = params ? params.smsBrandName || null : null;
        this.smsTemplate = params ? params.smsTemplate || null : null;
        this.emailTemplate = params ? params.emailTemplate || null : null;
        this.careInvestor = params ? params.careInvestor || null : null;
        this.handoverSchedule = params ? params.handoverSchedule || null : null;
    }
}

export class DeliveryResultModel {
    rate: number;
    quality: string;
    employeeQuality: string;
}

export enum DeliveryStatus {
    INIT = 'init', // Chưa bàn giao
    LATER = 'later', // Bàn giao sau
    HANDED = 'handed', // Đã bàn giao
    SCHEDULED = 'scheduled' // Đã lên lịch
}

export enum DeliveryQuality {
    GOOD = 'good',
    NORMAL = 'normal',
    BAD = 'bad'
}

export enum statusSyncErpDataCode {
    BLANK = 'BLANK',
    XD00 = 'XD00',
    XD01 = 'XD01',
    XD02 = 'XD02',
    XD03 = 'XD03',
    XD04 = 'XD04',
    XD05 = 'XD05',
    XD06 = 'XD06',
    XD07 = 'XD07',
}

export enum statusSyncErpDataName {
    BLANK = 'Khóa',
    XD00 = 'Sản phẩm trong quá trình XD / Dở dang',
    XD01 = 'Sản phẩm cần Ban QLDA tiến hành kiểm tra',
    XD02 = 'Sản phẩm cần Ban QA/QC tiến hành kiểm tra',
    XD03 = 'Sản phẩm đã hoàn thiện sẵn sàng bàn giao',
    XD04 = 'Sản phẩm đã bàn giao',
    XD05 = 'Ban quản lý cần sửa chữa trong tối đa 3 ngày',
    XD06 = 'Sản phẩm đủ điều kiện bàn giao',
    XD07 = 'Sản phẩm đã có lịch bàn giao',
}

