import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-form-action',
  templateUrl: './form-action.component.html',
  styleUrls: ['./form-action.component.scss']
})
export class FormActionComponent implements OnInit {
    @Input() isPost: boolean = false;
    @Input() postTitle: string = '';
    @Input() isChangeHistory: boolean = false;
    @Input() changeHistoryTitle: string = '';
    @Input() isShowStatus: boolean = false;
    @Input() showStatusType: string = '';

    @Input() isCreatePurchase: boolean = false;
    @Input() isBack: boolean = false;
    @Input() isSave: boolean = false;
    @Input() isEdit: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isReject: boolean = false;
    @Input() isStatus: boolean = false;
    @Input() isReceipt: boolean = false;
    @Input() isSubmit: boolean = false;
    @Input() isDownload: boolean = false;
    @Input() dropdownListDownload: any[] = [];
    @Input() statusTitle: string = '';
    @Input() statusContent: string = '';
    @Input() isInterest: boolean = false;
    @Input() interestTitle: string = '';
    @Input() isContract: boolean = false;
    @Input() contractTitle: string = '';
    @Input() isCreate: boolean = false;
    @Input() createTitle: string = '';
    @Input() isInterestSlip: boolean = false;
    @Input() isSaveDocument: boolean = false;
    @Input() isDeliveryAppointment: boolean = false;
    @Input() isNoticationPreview:boolean = false;
    @Input() isEditRed: boolean = false;
    @Input() isExportPdf: boolean = false;

    @Input() approveTitle: String = 'Duyệt';
    @Input() rejectTitle: String = 'Từ chối';
    @Input() submitTitle: String = 'Trình duyệt';

    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() changeHistory: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() approve: EventEmitter<any> = new EventEmitter();
    @Output() submit: EventEmitter<any> = new EventEmitter();
    @Output() reject: EventEmitter<any> = new EventEmitter();
    @Output() receipt: EventEmitter<any> = new EventEmitter();
    @Output() download: EventEmitter<any> = new EventEmitter();
    @Output() interest: EventEmitter<any> = new EventEmitter();
    @Output() contract: EventEmitter<any> = new EventEmitter();
    @Output() create: EventEmitter<any> = new EventEmitter();
    @Output() saveDocument: EventEmitter<any> = new EventEmitter();
    @Output() deliveryAppointment: EventEmitter<any> = new EventEmitter();
    @Output() deliveryTime: EventEmitter<any> = new EventEmitter();
    @Output() noticationPreview:EventEmitter<any> = new EventEmitter();
    @Output() exportPdf:EventEmitter<any> = new EventEmitter();
    @Input() isDisabled: boolean = false;

    constructor() {
    }

    ngOnInit() {

    }

    onBack() {
        this.back.emit();
    }
    onSave() {
        this.save.emit();
    }
    onChangeHistory() {
        this.changeHistory.emit();
    }
    onEdit() {
        this.edit.emit();
    }
    onApprove() {
        this.approve.emit();
    }
    onSubmit() {
        this.submit.emit();
    }
    onReject() {
        this.reject.emit();
    }
    onReceipt() {
        this.receipt.emit();
    }
    onDownload(data) {
        this.download.emit(data);
    }
    onInterest() {
        this.interest.emit();
    }
    onContract() {
        this.contract.emit();
    }
    onCreate() {
        this.create.emit();
    }
    onSaveDocument() {
        this.saveDocument.emit();
    }
    onDeliveryAppointment() {
        this.deliveryAppointment.emit();
    }
    onDeliveryTime() {
        this.deliveryTime.emit();
    }

    onNoticationPreview(){
        this.noticationPreview.emit();
    }

    onExportPdf(){
        this.exportPdf.emit();
    }
}
