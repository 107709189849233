import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core'
import { StorageService } from 'app/shared/services'
import { DialogService } from 'app/shared/services/dialog.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-button-chip',
  templateUrl: './button-chip.component.html',
  styleUrls: ['./button-chip.component.scss']
})
export class ButtonChipComponent implements OnInit, OnChanges {
    @Input() title : string;
    @Input() icon :string;
    @Output() onClick = new EventEmitter<any>();
    constructor(){

    }
    ngOnInit() {

    }
    ngOnChanges() {
        
    }
    emitEvent() {
      this.onClick.emit()
    }
}
