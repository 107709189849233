export enum NotificationEntityNameEnum {
    LEAD = 'lead',
    CONTRACT = 'contract',
    DEALT = 'dealt',
    DEALING = 'dealing',
    CALL = 'call',
    OTHER = 'other'
}

export enum NOTIFICATION_MESSAGE {
    ticketCreated_YCDCH = 'ticketCreated_YCDCH', // [Ticket YCDCH/YCDC] Tạo YCDCH
    ticketApproved_YCDCH_by_DVBH = 'ticketApproved_YCDCH_by_DVBH', // [Ticket YCDCH/YCDC] ĐVBH duyệt YCDCH
    ticketRejected_YCDCH_by_DVKH_or_DVBH = 'ticketRejected_YCDCH_by_DVKH_or_DVBH', // [Ticket YCDCH/YCDC] ĐVBH/DVKH từ chối YCDCH
    ticketApproved_YCDCH_by_DVKH = 'ticketApproved_YCDCH_by_DVKH', // [Ticket YCDCH/YCDC] DVKH duyệt YCDCH
    ticketCreated_DNTT = 'ticketCreated_DNTT', // [Ticket YCDCH/YCDC] Tạo ĐNTT
    ticketApproved_DNTT = 'ticketApproved_DNTT', // [Ticket YCDCH/YCDC] Duyệt ĐNTT
    ticketProcessed = 'ticketProcessed', // [Ticket YCDCH/YCDC] Ráp ưu tiên
    propertyUnitExpired = 'propertyUnitExpired', // [Sản phẩm] Server trả sp đã đăng ký: [Mã SP] đã bị trả về do hết thời gian
    propertyUnitWithdrawed = 'propertyUnitWithdrawed', // [Sản phẩm] Thu hồi SP: [Mã SP] đã bị thu hồi bởi [người thu thồi]
    propertyUnitTransfered = 'propertyUnitTransfered', // [Sản phẩm] Chuyển SP: [Mã SP] đã bị chuyển từ [pos1.name] qua [pos2.nam] bởi [người chuyển]
    propertyUnitInfo = 'propertyUnitInfo', // Cập nhật trang thái SP
    transactionSuccessed = 'transactionSuccessed', // [Giao dịch] Giao dịch thành công (chuyển đỏ sản phẩm)
    transactionWaitingForDocument = 'transactionWaitingForDocument', // [Giao dịch] Chờ bổ sung hồ sơ (chuyển cam sản phẩm)
  }