import { Pipe, PipeTransform } from '@angular/core';
import { TXN_STATUS } from '../constant/transaction.constant';
@Pipe({name: 'txnStatus'})
export class TransformTxnStatus implements PipeTransform {
  transform(value: string) {
    return TransformTxnStatus.parse(value);
  }
  static transform(value: string) {
    return this.parse(value);
  }
  public static TXN_STATUS  = TXN_STATUS;

  private static parse(typeInput) {
    if(!typeInput) return '';
    let type = typeInput.toUpperCase();
    const text = TXN_STATUS[type];
    if (text) {
      return text;
    };
    return type;
  }
}
