import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges } from '@angular/core';
import { DefineAttribute } from '../../../pages/define-attributes/define-attributes.model';
import { Constant } from '../../constant/constant';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { MASTER_DATA } from '../../../pages/master-data/master-data.data';
@Component({
    selector: 'container-tag',
    templateUrl: './container-tag.component.html',
    styleUrls: ['./container-tag.component.scss'],
})

export class ContainerTagComponent implements OnInit, OnChanges {
  @Input() parent;
  @Input() list;
  @Input() properties;
  @Input() isInput;
  @Output() result: EventEmitter<any> = new EventEmitter();
  orders: any = Constant.ORDERS;
  controlTypes: any = Constant.CONTROL_TYPES_OBJECT;
  ATTRIBUTES_DATA: any = MASTER_DATA.ATTRIBUTES;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  firstProperty: string;
  key: string;
  value: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
  ) {
  }
  ngOnInit() {
  }
  ngOnChanges(){
    if (!this.firstProperty)  {

    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our item
    if ((value || '').trim()) {
      this.list.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  addDataIntoList() {
    if (!this.key || !this.value) {
      return;
    }
    this.list.push({
      key: this.key.trim(),
      value: this.value.trim()
    });
    this.key = '' ;
    this.value = '';
    this.result.emit({
      parent: this.parent,
      list: this.list
    })
  }
  remove(item): void {
    const index = this.list.indexOf(item);

    if (index >= 0) {
      this.list.splice(index, 1);
    }
  }
}
