
declare let $: any;
export class NumberToMoneyVN {
  static parse(number, returnNull?) {
    let balance: number = parseInt(number);
    let value;
    let balanceText = '';
    value = balance/(1*1000*1000*1000);
    if (value >= 1) {
        balanceText += this.getSubText(Math.floor(value));
        balanceText+= value >= 1 ? ' tỷ ' : '';
        balance = balance - Math.floor(value)*1000*1000*1000
    }
    value = balance/(1*1000*1000);
    if (value >= 1) {
      balanceText += this.getSubText(Math.floor(value));
      balanceText+= value >= 1  ? ' triệu ' : '';
      balance = balance - Math.floor(value)*1000*1000
    }
    value = balance/(1*1000);
    if (value >= 1) {
        balanceText += this.getSubText(Math.floor(value));
        balanceText+= value >= 1 ? ' nghìn ' : '';
        balance = balance - Math.floor(value)*1000;
    }
    value = balance;
    if (value >= 1) {
      balanceText += this.getSubText(Math.floor(value));
      balance = balance - Math.floor(value)
    }
    balanceText = balanceText.replace(/một mươi/g, 'mười')
    balanceText = balanceText.replace(/lẻ lẻ/g, '');
    balanceText = balanceText.replace(/trăm một/g, 'trăm mốt');
    balanceText = balanceText.replace(/mươi một/g, 'mươi mốt');
    balanceText = balanceText.substring(0,1).toUpperCase() + balanceText.substring(1, balanceText.length)
    balanceText += ' đồng';
    return balanceText;
  }
  static getSubText(balance) {
    let text='', isHundred = false;
    // if (balance/(1*100) >= 1) {
    //   text += this.parseNumberToStringNumber(Math.floor(balance/(1*100))) + ' trăm '
    //   balance = balance - Math.floor(balance/(1*100))*100;
    //   isHundred = true;
    // }
    // if (balance/(1*10) >= 1) {
    //     text += this.parseNumberToStringNumber(Math.floor(balance/(1*10))) + ' mươi '
    //     balance = balance - Math.floor(balance/(1*10))*10
    // } else if (isHundred && balance != 0) {
    //   text += 'lẻ '
    // }
    // if (balance < 10 && balance != 0) {
    //   text += this.parseNumberToStringNumber(Math.floor(balance)) + ' '
    //   balance = balance - Math.floor(balance)
    // }
    return balance;
  }
  static parseNumberToString(number) {
    number = parseInt(number);
    switch (number) {
      case 1 :
        return 'một'
      case 2 :
        return 'hai'
      case 3 :
        return 'ba'
      case 4 :
        return 'bốn'
      case 5 :
        return 'năm'
      case 6 :
        return 'sáu'
      case 7 :
        return 'bảy'
      case 8 :
        return 'tám'
      case 9 :
        return 'chín'
      case 0 :
        return 'lẻ'
    }
  }
  static parseNumberToStringNumber(number) {
    number = parseInt(number);
    switch (number) {
      case 1 :
        return '1'
      case 2 :
        return '2'
      case 3 :
        return '3'
      case 4 :
        return '4'
      case 5 :
        return '5'
      case 6 :
        return '6'
      case 7 :
        return '7'
      case 8 :
        return '8'
      case 9 :
        return '9'
      case 0 :
        return '10'
    }
  }
  
  static summary(price) {
    let text = '';
    if (price >= 1000*1000*1000) {
      text = (price/(1000*1000*1000)).toString().substring(0, 4) + ' Tỷ'
    } else if (price >= 1000*1000) {
        text = (price/(1000*1000)).toString().substring(0, 4) + ' Triệu'
    } else if (price >= 1000) {
      text = (price/(1000)).toString().substring(0, 4) + ' Nghìn'
    }
    return text;
  }
  static parseToMoney(text) {
    if (text) {
      text = text.toString().replace(/,/g, '');
      const number = parseInt(text);
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }
}