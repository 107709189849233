export enum AppSearchType {
  NEWS = 'NEWS', // Filter [Tin tức]
  GROUP = 'GROUP', // Filter [Nhóm]
  GROUP_REPORT = 'GROUP_REPORT', // Filter [Nhóm - Báo cáo]
  POST = 'POST', // Filter [Nhóm]
  GROUP_MEMBER = 'GROUP_MEMBER', // Filter [Nhóm - thành viên]
  GROUP_POST = 'GROUP_POST', // Filter [Nhóm - thành viên]
  GROUP_POST_COMMENT = 'GROUP_POST_COMMENT', // Filter [Nhóm - thành viên]
  NOTI_CATEGORY = 'NOTI_CATEGORY',
  NOTIFICATION_GLOBAL = 'NOTIFICATION_GLOBAL',
  NOTI_MESS = "NOTI_MESS"
}
