import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges } from '@angular/core';
import { DefineAttribute } from '../../../pages/define-attributes/define-attributes.model';
import { Constant } from '../../constant/constant';

@Component({
    selector: 'input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
})

export class InputDateComponent implements OnInit, OnChanges {
    @Input() data;
    @Input() errors;
    @Input() isDisabled;
    @Input() required = false;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    Constant = Constant;
    constructor(
    ) {
    }
    ngOnInit() {
        !this.data && (this.data = {});
    }
    ngOnChanges(){
    }
    getData() {
       let text= '';
       let day = this.data.day + '';
       if (this.data.day.length == 1) {
        text+= '0' + day + '/';
       } else text+= day + '/';
       let month = this.data.month + '';
       if (month.length == 1) {
        text+= '0' + month + '/';
       } else text+= month + '/';

       text+= this.data.year;
       return text;
    }
}
