import { HttpErrorResponse } from '@angular/common/http';
import { CTransaction } from './../../api-models/c-transaction';
import { CEventPOS } from './../../api-models/c-event-pos';
import { Injectable } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import { ConstantUrl } from 'app/shared/constant/url';
import { BaseService } from 'app/shared/components/base.service';
// Model
import { Project } from './project.model';
import { Observable, throwError } from 'rxjs';
import { CPagination, CUser } from '../../api-models';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { CEventProjectManager } from './event-dashboard-page/c-event-project-manager';

import { IEventSaleUnitStatistic } from './event-dashboard-page/i-event-statistic';
@Injectable()
export class ProjectService extends BaseService {
  ConstantUrl = ConstantUrl;

  constructor(public service: DataService,
    public httpService: HttpService) {
    super(service);
    this.domainUrl = ConstantUrl.url_property_domain + 'project';
    this.queryUrl = ConstantUrl.url_property_query + 'project';
  }

  public downloadPropertyByQuery(body = {}, fileName) {
    const url = ConstantUrl.url_property_query + 'propertyUnit/property/download-query';
    return this.service.dowloadFile(url, body, fileName);
  }
  public printPropertyByQuery(body = {}) {
    const url = ConstantUrl.url_property_query + 'propertyUnit/property/download-query';
    return this.service.printFilePdf(url, body);
  }

  public getAll(params?: any) {
    return this.service.get(this.queryUrl + '/admin', params);
  }

  public getAllProjectInResident(params?: any) {
    return this.service.get(this.queryUrl + '/admin/getAllProjectInResident', params);
  }


  public getAllByDVKH(params?: any) {
    return this.service.get(this.queryUrl + '/private/customerService', params);
  }

  public getAllStatus() {
    return this.service.get(this.queryUrl + '/admin/status');
  }

  public getAllInvestor() {
    return this.service.get(this.queryUrl + '/admin/investor');
  }

  public getAllOrgchart(params?: any) {
    return this.service.get(`${ConstantUrl.url_orgchart_query}orgchart/allByQuery`, params) 
  } 

  public getOne(id: string, params?: any) {
    const url = this.queryUrl + '/admin/' + id;
    return this.service.get(url, params);
  }
  public getF1San(params?: any) {
    const url = this.queryUrl + '/admin/' + 'getF1San';
    return this.service.get(url, params);
  }

  public getEventStatus(id: string, salesProgramId) {
    const url = ConstantUrl.url_property_query + 'eventProject/' + id;
    return this.service.get(url,{salesProgramId});
  }

  public create(obj: Project) {
    return this.httpService.post(this.domainUrl, obj);
  }

  public update(obj: Project) {
    return this.service.put(this.domainUrl, obj);
  }

  public deactive(id: string) {
    const url = this.domainUrl + '/deactive/' + id;
    return this.service.put(url, {});
  }
  public hide(id: string) {
    const url = this.domainUrl + '/hide/' + id;
    return this.service.put(url, {});
  }
  public unhide(id: string) {
    const url = this.domainUrl + '/unhide/' + id;
    return this.service.put(url, {});
  }
  public active(id: string) {
    const url = this.domainUrl + '/active/' + id;
    return this.service.put(url, {});
  }

  getCategories() {
    const url = ConstantUrl.url_category_query + 'category/pub/all';
    return this.service.get(url);
  }
  searchEmployee(q: string) {
    const url = ConstantUrl.url_property_query + 'employee/report/search?q=' + q;
    return this.service.get(url);
  }
  searchEmployeeQuery(query) {
    const url = ConstantUrl.url_property_query + 'employee/report/search';
    return this.service.get(url, query);
  }
  public getAllPos() {
    const uri = ConstantUrl.url_orgchart_query + `orgchart/all`;
    return this.httpService.get(uri);
  }
  public getAllSan() {
    const uri = ConstantUrl.url_orgchart_query + `orgchart/exchanges/all`;
    return this.httpService.get(uri);
  }
  public settingSalesUnit(id, params?) {
    const uri = ConstantUrl.url_property_query + `project/admin/settingSalesUnit/` + id;
    return this.httpService.get(uri, params);
  }
  public getAllPosActive() {
    const uri = ConstantUrl.url_orgchart_query + `orgchart/pub/all/typePos`;
    return this.service.get(uri);
  }
  public saveSetting(params) {
    const uri = this.domainUrl + `/updateTable`;
    return this.httpService.put(uri, params);
  }
  public getTable(params) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/primary/assign`;
    return this.httpService.get(uri, params);
  }

  public getPropertyUnitUpdate(params) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/primary/assign/propertyUnit`;
    return this.httpService.get(uri, params);
  }

  public getLstTable(params) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/property/primary/assign`;
    return this.httpService.get(uri, params);
  }

  public getPrimaryByProject(params, projectId) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/getPrimaryByProject/` + projectId;
    return this.httpService.get(uri, params);
  }

  public exportPrimaryByProject(params, projectId) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/exportPrimaryByProject/` + projectId;
    return this.service.dowloadFile(uri, params);
  }

  public getFilterDataByProject(projectId) {
    const uri = ConstantUrl.url_property_query + `propertyUnit/getFilterDataByProject/` + projectId;
    return this.httpService.get(uri);
  }

  public getProjectList() {
    //fix bug DXGAP-600
    const uri = ConstantUrl.url_property_query + `project/admin/dropdownList?sortBy=name&orderBy=asc`;
    return this.httpService.get(uri);
  }

  public getProjectListByName(searchText: string = '', status: string = '', page: number = 1, isF0 = false, _fields = null) {
    let uri = ConstantUrl.url_property_query + 'project/admin/dropdownList?search=' + searchText + '&status=' + status + '&page=' + page;
    if (isF0) {
      uri += '&isF0=true';
    }
    if (_fields) {
      uri += `&_fields=${_fields}`;
    }
    
    return this.httpService.get(uri);
  }

  public getProjectListViewOnly() {
    const uri = ConstantUrl.url_property_query + `project/hasProperty`;
    return this.httpService.get(uri);
  }


  public getPropertyInProject(projectId: string, stage?: number, primaryStatus?: string) {
    let url = ConstantUrl.url_property_query + 'propertyUnit/primary/assign?projectId=' + projectId;
    if (stage) {
      url = url + '&stage=' + stage;
    }
    if (primaryStatus) {
      url = url + '&primaryStatus=' + primaryStatus;
    }
    return this.httpService.get(url);
  }

  public searchPropertyUnit(code: string) {
    // Chỉ search tối đa 20 bản ghi đầu tiên
    const url = `${ConstantUrl.url_property_query}propertyUnit/autocomplete`;
    return this.httpService.get(url, { code });
  }

  public searchPropertyUnitInProject(projectId: string, code: string) {
    // Chỉ search tối đa 20 bản ghi đầu tiên
    const url = `${ConstantUrl.url_property_query}propertyUnit/autocomplete`;
    return this.httpService.get(url, { projectId, code });
  }

  public searchProjects(name: string) {
    // Chỉ search tối đa 20 bản ghi đầu tiên
    const url = `${this.queryUrl}/autocomplete?name=${name}`;
    return this.httpService.get(url);
  }

  public endStageEventDashboad(projectId: string, salesProgramId: string) {
    const uri = ConstantUrl.url_property_query + 'eventProject/endStage';
    return this.httpService.post(uri, { projectId, salesProgramId})
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }

  public startEventDashboad(projectId: string, salesProgramId: string, solveUnitType: string) {
    const uri = ConstantUrl.url_property_query + 'eventProject/startEvent';
    return this.httpService.post(uri, { projectId, salesProgramId , solveUnitType})
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public sendBroadCastMessage(projectId: string, message: string, toPos: boolean, toCustomer: boolean) {
    const uri = ConstantUrl.url_property_query + 'eventProject/sendBroadcastMessage';
    return this.httpService.post(uri, { projectId, message, toPos, toCustomer})
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public sendSuccessCustomer(projectId: string, message: string) {
    const uri = ConstantUrl.url_property_query + 'eventProject/sendSuccessCustomer';
    return this.httpService.post(uri, { projectId, message })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public openConnectionEvent(projectId: string) {
    const uri = ConstantUrl.url_property_query + 'eventProject/openConnection';
    return this.httpService.post(uri, { projectId });
  }

  public endEventDashboad(projectId: string, salesProgramId: string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/endEvent';
    return this.httpService.post(uri, { projectId, salesProgramId })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }

  public liveStreamEventDashboad(projectId: string, salesProgramId:string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + `eventProject/${projectId}`;
    return this.httpService.get(uri, { salesProgramId })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }

  public nextPriorityEventDashboad(projectId: string, salesProgramId:string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/nextPriority';
    return this.httpService.post(uri, { projectId, salesProgramId })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public solveUnitNotSuccEventDashboad(projectId: string, salesProgramId:string, action: string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/solveUnitNotSucc';
    return this.httpService.post(uri, { projectId, salesProgramId, action })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public startPriorityEventDashboad(projectId: string, salesProgramId:string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/startPriority';
    return this.httpService.post(uri, { projectId, salesProgramId })
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }
  public endPriorityEventDashboad(projectId: string, salesProgramId:string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/endPriority';
    return this.httpService.post(uri, { projectId, salesProgramId})
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }

  public nextStageEventDashboad(projectId: string, salesProgramId: string): Observable<CEventProjectManager> {
    const uri = ConstantUrl.url_property_query + 'eventProject/nextStage';
    return this.httpService.post(uri, { projectId , salesProgramId})
      .pipe(
        map((resApi: any) => new CEventProjectManager().decode(resApi))
      );
  }

  public saleUnitStatistics(projectId: string, salesProgramId:string): Observable<IEventSaleUnitStatistic> {
    const uri = ConstantUrl.url_property_query + `project/admin/countSalesUnit/${projectId}`;
    return this.httpService.get(uri, {salesProgramId});
  }

  public eventSaleUnits(projectId: string, salesProgramId:string, pagination: CPagination<any>, paramsSort = {}): Observable<CPagination<CEventPOS>> {
    const uri = ConstantUrl.url_property_query + `project/admin/salesUnit/${projectId}`;
    return this.httpService.get(uri, Object.assign({}, { page: 1, pageSize: 99999999, salesProgramId }, paramsSort))
      .pipe(
        map((resApi: any) => {
          let paginationApi = new CPagination<CEventPOS>().decode(resApi);
          paginationApi.items = new CEventPOS().decodeList(paginationApi.items);
          return paginationApi;
        })
      );
  }

  public eventEmployees(posId: string, pagination: CPagination<any>, paramsSort = {}): Observable<CPagination<CUser>> {
    const uri = ConstantUrl.url_orgchart_query + `orgchart/employee/${posId}`;
    return this.httpService.get(uri, Object.assign({}, { page: 1, pageSize: 99999999 }, paramsSort))
      .pipe(
        map((resApi: any) => {
          let paginationApi = new CPagination<CUser>().decode(resApi);
          paginationApi.items = new CUser().decodeList(paginationApi.items);
          return paginationApi;
        })
      );
  }

  getImportHistories(params): Observable<Response> {
    const url = ConstantUrl.url_property_query + 'importHistory';
    // const url = ConstantUrl.url_property_query + 'propertyPrimaryTransaction/importHistory';
    return this.httpService.get(url, params);
  }

  getListMailer(params) {
    const url = ConstantUrl.url_property_query + `mailer/history`;
    return this.service.get(url, params);
  }

  public lockExtendPriority(projectId) {
    const url = ConstantUrl.url_property_domain + 'project/lockExtendPriority/' + projectId;
    return this.httpService.get(url);
  }

  getAllBankLocal(): Observable<any[]> {
    const url = `${ConstantUrl.url_payment}payment/bank_list`;
    const params = {
      type: 'type-local'
    };
    return this.httpService.get(url, params);
  }

  public sendEmailComfirm(params: any) {
    const url = `${ConstantUrl.url_property_query}mailer/send`;
    return this.httpService.get(url, params);
  }
  public sendEmailComfirmSalesUnit(params: any) {
    const url = `${ConstantUrl.url_property_query}mailer/sendBySalesUnit`;
    return this.httpService.get(url, params);
  }


  public pinESalekit(projectId: string) {
    const uri = ConstantUrl.url_property + `esalekit-pin/${projectId}`;
    return this.httpService.post(uri);
  }


  public unPinESalekit(projectId: string) {
    const uri = ConstantUrl.url_property + `esalekit-pin/${projectId}`;
    return this.httpService.delete(uri);
  }

  public setOnTopBanner(projectId: string) {
    const uri = ConstantUrl.url_property_domain + `project/setontop/` + projectId;
    return this.httpService.put(uri, null);
  }
  public sendEmailEndPriority(params: any) {
    const url = `${ConstantUrl.url_property_query}mailer/sendEndPriority`;
    return this.httpService.get(url, params);
  }

  public enableEditTicket(p: any) {
    const uri = ConstantUrl.url_property_query + 'eventProject/enableOrderEdit';
    return this.httpService.post(uri, p);
  }

  public disableEditTicket(p: any) {
    const uri = ConstantUrl.url_property_query + 'eventProject/disableOrderEdit';
    return this.httpService.post(uri, p);
  }

  public updateTicket(p: any) {
    const uri = ConstantUrl.url_property_domain + 'primaryTransaction/customer';
    return this.httpService.put(uri, p);
  }
  public updateTicketProcessing(p: any) {
    const uri = ConstantUrl.url_property_domain + 'primaryTransaction/customerProcessing';
    return this.httpService.put(uri, p);
  }

  public createDocumentsForCustomer(projectId: string, salesProgramId: string) {
    const uri = ConstantUrl.url_property_query + 'eventProject/createDocumentForCustomer';
    return this.httpService.post(uri, { projectId, salesProgramId });
  }

  getSyncHistories(params): Observable<Response> {
    const url = ConstantUrl.url_property_query + 'logs-sync/history';
    return this.httpService.get(url, params);
  }

  getListProductCodes(id: string, params?: any) {
      return this.httpService.get(`${ConstantUrl.url_property_query}propertyUnit/primaryByProject/${id}`, params);
  }

  getUserCareHistories(params): Observable<Response> {
    const url = ConstantUrl.url_property_query + 'userCareHistory';
    return this.httpService.get(url, params);
  }

  getProjects(params?) {
    let url = ConstantUrl.url_property_query + 'project/admin';
    return this.service.get(url, params || {});
  }
  getPaymentMethod() {
    return this.httpService.get(
        `${ConstantUrl.url_payment}online-payment/method`,
        null
    );
  }

  getPayment(params) {
    const url = ConstantUrl.url_property_query
    return this.service.get(url, params)
  }

  registerFina(params) {
    const url = ConstantUrl.url_property + `admin/finaRegister`
    return this.service.post(url, params)
  }

  getPropertyUnitSyncErp(projectId, params) {
    return this.httpService.get(`${ConstantUrl.url_property_query}propertyUnit/primary/assign/${projectId}`, params);
  }

}
