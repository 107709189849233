import moment = require("moment");
export class mailerTemplateModel {
    stt: string;
    subject: string;
    content: string;
    system: string;
    name: string;
    createdDate: Date;
    constructor(params? : any) {
        Object.assign(this,{}, params)
    }
    createDataForList() {
        let object: any = Object.assign({}, this);
        object.createdDate = object.createdDate ? moment(object.createdDate).format('DD/MM/YYYY HH:mm:ss '): ' ';
        return object;
    }
}
export class MailerLogModel {
    stt: string; 
    id: string;
    from: string;
    to: [];
    cc: [];
    bcc: [];
    replyTo: string;
    priority: string;
    subject: string;
    notiSystem: string;
    createdAt: string;
    content: string;
    attachments: Boolean;
    isSuccess: Boolean;
    response: any;

    constructor(params? : any) {
        Object.assign(this, {}, params); 
        this.isSuccess = params && params.response ? params.response.isSuccess : false;
    }
}
