import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppSearchModel } from './app-search-model';
import { AppSearchType } from './app-search-type.enum';
import { ActivatedRoute } from '@angular/router';
import _ = require('lodash');
import { Constant } from 'app/shared/constant/constant';
import { StorageService } from 'app/shared/services';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-search-filter-v2',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterV2Component implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() selectNdkv: EventEmitter<any> = new EventEmitter();
  @Output() getNvkd: EventEmitter<any> = new EventEmitter();
  @Input() type: AppSearchType = null;
  @Input() filters: AppSearchModel[] = [];
  @Input() resetCheckFilter: boolean = false;
  @Input() isDatePicker: boolean = false;
  @Input() isResetDate: boolean = false;
  @Input() title :string ='';
  @Input() listNvkd: any[] = [];
  @Input() isHideInput: boolean = false;
  @Input() isEvoucher: boolean = false;
  @Input() searchKeyword: string = '';
  @Input() isCloseMenu: boolean = false;
  public searchKey = new FormControl();
  createdDateStart = '';
  createdDateEnd = '';
  private unsubscribe$: Subject<any> = new Subject();
  countCheck = 0;
  checkDate = 0;
  selectCheckBox = 0;

  constructor(
    public storageService: StorageService,
    public route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    if (this.isEvoucher) {
      if (this.filters && this.filters.length) {
        this.countCheckBox();
      }
      this.searchKey.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe(() => this.searchCode());
    }
    if (this.resetCheckFilter) {
      this.uncheckFilter();
    }
    this.route.queryParams.subscribe((params) => {
      if (!_.isEmpty(params)) {
        this.fetchHistoryFilter(params);
      } else if (this.storageService.retrieve(Constant.historyFilter)) { // Get value storage if query params empty
        const historyFilter = this.storageService.retrieve(Constant.historyFilter);
        this.fetchHistoryFilter(historyFilter[`${historyFilter.name}`]);
      }
    })
  }

  countCheckBox() {
    this.selectCheckBox = 0
    this.filters.forEach(element => {
      this.selectCheckBox += element.selectedValue.length;
    });
  }

  ngOnChanges() {
    if (this.isEvoucher && this.filters && this.filters.length) {
      this.countCheckBox();
    }
    if (this.resetCheckFilter) {
      this.uncheckFilter();
    }
    this.route.queryParams.subscribe((params) => {
      if (!_.isEmpty(params)) {
        this.fetchHistoryFilter(params);
      } else if (this.storageService.retrieve(Constant.historyFilter)) { // Get value storage if query params empty
        const historyFilter = this.storageService.retrieve(Constant.historyFilter);
        this.fetchHistoryFilter(historyFilter[`${historyFilter.name}`]);
      }
    })
  }

  fetchHistoryFilter(historyFilter) {
    this.storageService.remove(Constant.historyFilter);
    historyFilter.keywords ? this.searchKey.setValue(historyFilter.keywords) : this.searchKey.setValue(historyFilter.q);
    this.filters = this.filters.map((filter: AppSearchModel) => {
      const data = historyFilter[`${filter.key}`];
      filter.queryParam = data;
      filter.selectedValue = [];
      filter.selectedValueApplied = [];
      if (data) {
        filter.selectedValue = data.split(",");
        filter.selectedValueApplied = data.split(",");
      }
      filter.options = filter.options.map((option => {
        if (filter.selectedValueApplied.includes(option.id)) {
          option.checked = true;
          return option;
        }
        return option;
      }))
      return filter;
    })
  }

  onClickCheckBox(checked: boolean, item: any, filter: AppSearchModel) {
    item.checked = checked;
    if (checked) {
      if (!filter.selectedValue.includes(item.id)) {
        filter.selectedValue.push(item.id);
      }
    } else {
      if (filter.selectedValue.length > 0) {
        filter.selectedValue = filter.selectedValue.filter(i => i !== item.id);
      }
    }
    filter.queryParam = filter.selectedValue.toString() || '';
  }

  searchCode() {
    this.selectCheckBox = 0;
    let keyword = '';
    if (this.searchKey.value) {
      keyword = this.searchKey.value.trim();
    }
    let params: any = { keywords: keyword, q: keyword };
    if (this.filters && Array.isArray(this.filters)) {
      for (const filter of this.filters) {
        params[filter.key] = filter.queryParam;
        filter.selectedValueApplied = [...filter.selectedValue];
        this.selectCheckBox += filter.selectedValue.length;
      }
    }

    if (this.createdDateStart) {
      params.createdFrom = moment(this.createdDateStart).startOf('date').toISOString();
    }
    if (this.createdDateEnd) {
        params.createdTo = moment(this.createdDateEnd).endOf('date').toISOString();
    }
    if (this.createdDateStart || this.createdDateEnd) {
      this.checkDate = 1;
    } else {
      this.checkDate = 0;
    }
    this.search.emit(params);
    this.closeMenu();
  }

  closeMenu() {
    if (this.isCloseMenu) {
      this.trigger.closeMenu();
    }
  }

  resetFilter() {
    this.uncheckFilter();
    this.searchCode();
  }

  selectNdkvFilter(event) {
    this.selectNdkv.emit(event);
    this.searchKey.setValue('');
  }

  getNvkdFilter() {
    this.getNvkd.emit(this.searchKey)
  }

  uncheckFilter() {
    if (this.filters && Array.isArray(this.filters)) {
      for (const filter of this.filters) {
        filter.selectedValue = [];
        filter.selectedValueApplied = [];
        filter.options.map(item => {
          item.checked = false;
          return item;
        });
        filter.queryParam = '';
      }
    }
    if(this.isResetDate) {
      this.createdDateStart = null
      this.createdDateEnd = null
    }
    this.searchKey = new FormControl();
  }

  menuClosed() {
    if (this.filters && Array.isArray(this.filters)) {
      for (const filter of this.filters) {
        filter.selectedValue = [...filter.selectedValueApplied];
        filter.options.map(item => {
          item.checked = filter.selectedValue.includes(item.id);
          return item;
        });
      }
    }
  }
  
  get isShowNumber() {
    this.countCheck = this.checkDate + this.selectCheckBox;
    return this.countCheck;
  }

  get getNumber() {
    this.countCheck = this.checkDate + this.selectCheckBox;
    return this.countCheck
}
}
