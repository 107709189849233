import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface SuccessDialogData {
    title: string;
    message: string;
}


@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})

export class SuccessDialogPopup implements OnInit {

    mainForm: FormGroup
    constructor(
        public dialogRef: MatDialogRef<SuccessDialogPopup>,
        public dialog: MatDialog,
        private fb: FormBuilder,
        public toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: SuccessDialogData,
      ) { }


    ngOnInit() {
    }
    
    closePopup() {
      this.dialogRef.close()
    }
 }