export const ERoleId = {
    NVKD: '69f84947-a647-4b58-9cf9-86b2096c6f1f',
    TRUONG_PHONG_POS: '2949687c-74f4-4efb-8959-1a3441cb4229',
    GD_SAN: '93f772c1-21f2-40d4-bdfd-7cc3c45bbfed',
    KE_TOAN_TRUONG: 'd6781f24-9b5d-4a68-9cc9-31f8981342dd',
    PHO_TGD_KD: 'a3f52f30-64c8-4071-a751-df737e9d6d03',
    DVKH_IMPORT_SP: 'aadf4061-7c41-41b0-95a9-895702d3ec5c',
    DVKH_HAU_KIEM_SP: 'f8a1101a-6651-4494-983b-3ce20a0e30ba',
    MARKETING: '41f34233-decf-4e3e-b854-730d9dddac93',
    KE_TOAN: '1d3cd2d3-cb36-4cc3-8eb1-80de6569c2de',
    IMPORT_YEU_CAU: '86098c05-9a18-4e20-8854-a60782f953bc',
    KHAI_THAC: '09dd99d2-df7e-44ee-a044-890e62c8fe85',
    DVKH: '07f0e8bf-23e0-4e9d-a2a5-d1b350556cdc',
    ADMIN: '',
    REPORT : 'ad6c7679-ccb9-48da-b87b-fafab91043fc'
};
