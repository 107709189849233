import { BaseModelInterface } from 'app/shared/models/base.model.interface';

export class CEvalProperty implements BaseModelInterface {
    PropertpropertyToEvaluate: PropertyToEvaluateDto;
    ReferenceProperties: object = [];
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.PropertpropertyToEvaluate = new PropertyToEvaluateDto(params.PropertpropertyToEvaluate);
            this.ReferenceProperties = []

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.PropertpropertyToEvaluate = new PropertyToEvaluateDto();
        this.ReferenceProperties = []
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}

export class PropertyToEvaluateDto implements BaseModelInterface{
    externalId: number;
    customerName: string;
    customerPhone: string;
    typeHouse: TypeHouse;
    address: Address;
    location: Location;
    propertyLand: PropertyLand;
    propertyConstruction: PropertyConstruction;
    propertyAdvantage: PropertyAdvantage;
    adsProperty: AdsProperty;
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.externalId = params.externalId;
            this.customerName= params.customerName;
            this.customerPhone= params.customerPhone;
            this.typeHouse= new TypeHouse(params.typeHouse);
            this.address= new Address(params.address);
            this.location= new Location(params.location);
            this.propertyLand= new PropertyLand(params.propertyLand);
            this.propertyConstruction= new PropertyConstruction(params.propertyConstruction);
            this.propertyAdvantage= new PropertyAdvantage(params.propertyAdvantage);
            this.adsProperty= new AdsProperty(params.adsProperty);

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.externalId = null;
        this.customerName= null;
        this.customerPhone= null;
        this.typeHouse= new TypeHouse();
        this.address= new Address();
        this.location= new Location();
        this.propertyLand= new PropertyLand();
        this.propertyConstruction= new PropertyConstruction();
        this.propertyAdvantage= new PropertyAdvantage();
        this.adsProperty= new AdsProperty();
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}

export class TypeHouse implements BaseModelInterface{
    landType: string;
    houseType: string
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.landType = params.landType;
            this.houseType= params.houseType;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.landType = null;
        this.houseType= null;
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
export class Address implements BaseModelInterface{
    provinceId: number;
    districtId: number;
    projectId: number;
    wardId: number;
    streetId: number;
    addressNumber: string;
    blockId: number;
    apartmentNumber: string;
    apartmentFloorTh: number;
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.provinceId = params.provinceId;
            this.districtId= params.districtId;
            this.projectId= params.projectId;
            this.wardId= params.wardId;
            this.streetId= params.streetId;
            this.addressNumber= params.addressNumber;
            this.blockId= params.blockId;
            this.apartmentNumber= params.apartmentNumber;
            this.apartmentFloorTh= params.apartmentFloorTh;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.provinceId = null;
        this.districtId= null;
        this.projectId= null;
        this.wardId= null;
        this.streetId= null;
        this.addressNumber= null;
        this.blockId= null;
        this.apartmentNumber= null;
        this.apartmentFloorTh= null;
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
export class Location implements BaseModelInterface {
    location: string;
    distanceToStreet: number;
    alleyTurns: number;
    alleyWidth1: number;
    alleyWidth2: number;
    alleyWidth3: number;
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.location = params.location;
            this.distanceToStreet= params.distanceToStreet;
            this.alleyTurns= params.alleyTurns;
            this.alleyWidth1= params.alleyWidth1;
            this.alleyWidth2= params.alleyWidth2;
            this.alleyWidth3= params.alleyWidth3;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.location = null;
        this.distanceToStreet= null;
        this.alleyTurns= null;
        this.alleyWidth1= null;
        this.alleyWidth2= null;
        this.alleyWidth3= null;
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
export class PropertyLand implements BaseModelInterface{
    areaTotal: number;
    areaTotalWidth: number;
    areaTotalLength: number;
    areaTotalBackWidth: number;
    areaLegal: number;
    areaLegalWidth: number;
    areaLegalLength: number;
    areaLegalBackWidth: number;
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.areaTotal = params.areaLegalBackWidth;
            this.areaTotalWidth= params.areaTotalWidth;
            this.areaTotalLength= params.areaTotalLength;
            this.areaTotalBackWidth= params.areaTotalBackWidth;
            this.areaLegal= params.areaLegal;
            this.areaLegalWidth= params.areaLegalWidth;
            this.areaLegalLength= params.areaLegalLength;
            this.areaLegalBackWidth= params.areaLegalBackWidth;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.areaTotal = null;
        this.areaTotalWidth= null;
        this.areaTotalLength= null;
        this.areaTotalBackWidth= null;
        this.areaLegal= null;
        this.areaLegalWidth= null;
        this.areaLegalLength= null;
        this.areaLegalBackWidth= null;
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
export class PropertyConstruction implements BaseModelInterface {
    advConstructionTypes: string[];
    bedrooms: string;
    bathrooms: number;
    areaUsable: number;
    areaConstructionFloor: number;
    floorsCount: number;
    interiorType: string;
    balconies: number;
    typeConstructionId = 'tp-house-104';
    remainingValue: number;
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.advConstructionTypes = params.advConstructionTypes;
            this.bedrooms = params.bedrooms;
            this.bathrooms= params.bathrooms;
            this.areaUsable= params.areaUsable;
            this.areaConstructionFloor= params.areaConstructionFloor;
            this.floorsCount= params.floorsCount;
            this.interiorType = params.interiorType;
            this.balconies = params.balconies;
            this.typeConstructionId = 'tp-house-104';
            this.remainingValue= params.remainingValue;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.advConstructionTypes = [];
        this.bedrooms = null;
        this.bathrooms= null;
        this.areaUsable= null;
        this.areaConstructionFloor= null;
        this.floorsCount= null;
        this.interiorType = null;
        this.balconies = null;
        this.typeConstructionId = 'tp-house-104';
        this.remainingValue= null;
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
export class PropertyAdvantage implements BaseModelInterface {
    advPropertyTypes: string[];
    disAvdPropertyTypes: string[];
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.advPropertyTypes =params.advPropertyTypes;
            this.disAvdPropertyTypes = params.disAvdPropertyTypes;

        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.advPropertyTypes =[];
        this.disAvdPropertyTypes = [];
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
    
}
export class AdsProperty  implements BaseModelInterface {
    priceProposed: number;
    paymentMethod = 'pm-vnd-b';
    paymentUnit = 'unit-total';
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.priceProposed = params.priceProposed;
            this.paymentMethod = 'pm-vnd-b';
            this.paymentUnit = 'unit-total';

        } else {
            this.setAllNull();
        }

    }

    setAllNull() {
        this.priceProposed = null;
        this.paymentMethod = 'pm-vnd-b';
        this.paymentUnit = 'unit-total';
    }
    createObjectRequestAPI(isAmendment?) {
        let object: any = {};
        return object;
    }
}
