import { DateToFormat } from '../../../shared/parse/date-to-format';

export class EmployeeReport {
    stt: number;
    id: string;
    code: string;
    email: string;
    name: string;
    firstLogin: string;
    totalTickets: number;
    totalDemands: number;
    totalConsignments: number;
    totalApprovalContract: number;
    totalIncomingCall: number;
    totalCallOut: number;
    totalMissingCall: number;
    pos: string;
    exchange: string;
    lastLogin: string;
    loginList: any;
    loginCount: number;
    ticketByReceive: number;
    ticketBySelf: number;
    demandByReceive: number;
    demandBySelf: number;
    consignmentByReceive: number;
    consignmentBySelf: number;
    customerDemandByReceive: number;
    customerDemandBySelf: number;
    customerConsignmentByReceive: number;
    customerConsignmentBySelf: number;
    totalCustomer: number;
    propertyApprove: number;
    propertyNotApprove: number;
    totalProperty: number;

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.stt = params.stt || '';
            this.id = params.id || '';
            this.code = params.code || '';
            this.email = params.email || '';
            this.name = params.name || '';
            this.firstLogin = params.firstLogin ? DateToFormat.parseDate(params.firstLogin, 'dd/MM/yyyy - HH: mm:ss') : '';
            this.totalTickets = params.countLeadTicket || 0;
            this.totalDemands = (params.countDemandCustomer + params.countDemandEmployee) || 0;
            this.totalConsignments = (params.countConsignmentCustomer + params.countConsignmentEmployee) || 0;
            this.totalApprovalContract = params.countContract || 0;
            this.totalIncomingCall = params.totalIncomingCall || 0;
            this.totalCallOut = params.totalCallOut || 0;
            this.totalMissingCall = params.totalMissingCall || 0;
            this.pos = params.pos ? params.pos.name : '';
            this.exchange = params.san ? params.san.name : '';
            params.loginList = params.loginList ? params.loginList : [];
            this.loginList = params.loginList.length > 0 ? this.transformLoginList(params.loginList) : '';
            this.firstLogin = params.loginList.length > 0 ? this.parseDate(params.loginList[0].commitStamp, 'dd/MM/yyyy - hh:mm:ss') : '';
            this.lastLogin = params.loginList.length > 0 ? this.parseDate(params.loginList[params.loginList.length - 1].commitStamp, 'dd/MM/yyyy - hh:mm:ss') : '';
            this.loginCount = params.loginList.length || 0;
            this.ticketByReceive = params.ticketByReceive || 0;
            this.ticketBySelf = params.ticketBySelf || 0;
            this.demandByReceive = params.countDemandCustomer || 0;
            this.demandBySelf = params.countDemandEmployee || 0;
            this.consignmentByReceive = params.countConsignmentCustomer || 0;
            this.consignmentBySelf = params.countConsignmentEmployee || 0;
            this.customerDemandByReceive = params.countDemandCustomerCustomer || 0;
            this.customerDemandBySelf = params.countDemandCustomerEmployee || 0;
            this.customerConsignmentByReceive = params.countConsignmentCustomerCustomer || 0;
            this.customerConsignmentBySelf = params.countConsignmentCustomerEmployee || 0;
            this.totalCustomer = params.sumConsignmentDemand || 0;
            this.propertyApprove = params.countPropertyApprove || 0;
            this.propertyNotApprove = params.countPropertyNotApprove || 0;
            this.totalProperty = params.sumProperty || 0;
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.stt = 1;
        this.id = '';
        this.code = '';
        this.email = '';
        this.name = '';
        this.firstLogin = '';
        this.totalTickets = 0;
        this.totalDemands = 0;
        this.totalConsignments = 0;
        this.totalApprovalContract = 0;
        this.totalIncomingCall = 0;
        this.totalCallOut = 0;
        this.totalMissingCall = 0;
        this.pos = '';
        this.exchange = '';
        this.firstLogin = '';
        this.lastLogin = '';
        this.loginList = '';
        this.loginCount = 0;
        this.ticketByReceive = 0;
        this.ticketBySelf = 0;
        this.demandByReceive = 0;
        this.demandBySelf = 0;
        this.consignmentByReceive = 0;
        this.consignmentBySelf = 0;
        this.customerDemandByReceive = 0;
        this.customerDemandBySelf = 0;
        this.customerConsignmentByReceive = 0;
        this.customerConsignmentBySelf = 0;
        this.totalCustomer = 0;
        this.propertyApprove = 0;
        this.propertyNotApprove = 0;
        this.totalProperty = 0;
    }

  getHeaders() {
    return [
      { header: 'STT', key: 'stt', width: 20 },
      { header: 'Mã CBNV O2O', key: 'code', width: 20 },
      { header: 'Tên tài khoản', key: 'email', width: 30 },
      { header: 'Họ tên CBNV', key: 'name', width: 20 },
      { header: 'Sàn', key: 'exchange', width: 30 },
      { header: 'Trung tâm giao dịch', key: 'pos', width: 30 },
      { header: 'Lần đầu đăng nhập', key: 'firstLogin', width: 20 },
      { header: 'Lần cuối đăng nhập', key: 'lastLogin', width: 20 },
      { header: 'Danh sách thời gian đăng nhập', key: 'loginList', width: 25 },
      { header: 'Số lần đăng nhập', key: 'loginCount', width: 20 },
      { header: 'Số lượng YCTV đã xử lý', key: 'totalTickets', width: 10 },
      { header: 'Số lượng TVMG đã tạo', key: 'demandByReceive', width: 12 },
      { header: 'Số lượng TVMG đã tạo', key: 'demandBySelf', width: 12 },
      { header: 'Số lượng TVKG đã tạo', key: 'consignmentByReceive', width: 12 },
      { header: 'Số lượng TVKG đã tạo', key: 'consignmentBySelf', width: 12 },
      { header: 'Số lượng HĐMG đã được duyệt', key: 'totalApprovalContract', width: 10 },
      { header: 'Số lượng cuộc gọi đi', key: 'totalCallOut', width: 12 },
      { header: 'Số lượng cuộc gọi đến', key: 'totalIncomingCall', width: 12 },
      { header: 'Số lượng cuộc gọi nhỡ', key: 'totalMissingCall', width: 12 },
      { header: 'Số lượng khách hàng yêu cầu TVMG', key: 'customerDemandByReceive', width: 12 },
      { header: 'Số lượng khách hàng yêu cầu TVMG', key: 'customerDemandBySelf', width: 12 },
      { header: 'Số lượng khách hàng yêu cầu TVKG', key: 'customerConsignmentByReceive', width: 12 },
      { header: 'Số lượng khách hàng yêu cầu TVKG', key: 'customerConsignmentBySelf', width: 12 },
      { header: 'Số lượng khách hàng đã nhập', key: 'totalCustomer', width: 15 },
      { header: 'Số lượng sản phẩm chưa duyệt', key: 'propertyNotApprove', width: 15 },
      { header: 'Số lượng sản phẩm đã duyệt', key: 'propertyApprove', width: 15 },
      { header: 'Số lượng sản phẩm', key: 'totalProperty', width: 15 },
    ];
  }

  transformLoginList(list) {
    return list.map(ele => this.parseDate(ele.commitStamp, 'dd/MM/yyyy - hh:mm:ss')).join('\r\n');
  }
  parseDate(date, format) {
    return DateToFormat.parseDate(date, format);
  }
}
