import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { markAsTouched } from "app/shared/utility/form-utility";
import { ToastrService } from "ngx-toastr";
import { TypeSendOtp } from "app/shared/enum/trial-register.enum";
import { PhoneAuthenRegisterComponent } from "../phone-authen/phone-authen.component";
import { LoginService } from "app/pages/login/login.service";


@Component({
  selector: 'app-authen-duplicate',
  templateUrl: 'check-duplicate-authen.component.html',
  styleUrls: ['check-duplicate-authen.component.scss']
})


export class DuplicateAuthenticate implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DuplicateAuthenticate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
    private _service: LoginService
    ) { }

    typeSend = TypeSendOtp
    phoneNumber: string
    email: string
    accessToken: string;
  ngOnInit() {
    if (this.data && this.data.accessToken) {
      this.accessToken = this.data.accessToken;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  openPhoneAuthenPopup() {
      this.resendOtp()
      this.dialog.open(PhoneAuthenRegisterComponent, {
          width: '500px',
          autoFocus: true,
          data: {
            authenType: TypeSendOtp.PHONE,
            authenText: 'số điện thoại',
            phoneNumber: this.data.phoneNumber,
            email: this.data.email,
            isShowAuthenList: true,
            accessToken: this.accessToken
          }
      })
      this.dialogRef.close()
  }

  resendOtp() {
    let dto = {
      phoneNumber: this.data.phoneNumber,
      email: this.data.email,
      type: TypeSendOtp.PHONE
    }
    this._service.sendOtp(dto, this.accessToken).subscribe((res: any) => {
      if(res) {
        const data = res.json();
        if (data.accessToken) {
          this.accessToken = data.accessToken;
        }
        this.toastr.success(`Vui lòng kiểm tra mã otp đã được gửi về số điện thoại của bạn`)
      }
    })
  }

}