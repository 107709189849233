export class ContractConstants {
    public static ListPayments = [
        {
            key: 'CASH',
            value: 'Tiền mặt',
        },
        {
            key: 'TRANSFER',
            value: 'Chuyển khoản',
        },
        {
            key: 'OTHER',
            value: 'Khác',
        },
    ];

    public static Status = {
        init: 'Khởi tạo',
        waiting: 'Chờ duyệt',
        accountant_waiting : 'Chờ KT thu tiền',
        approved: 'Đã duyệt',
        rejected: 'Đã từ chối',
        cancelled: 'Đã hủy',
        liquidated: "Đã thanh lý",
        accountant_waiting_confirm_refund: 'Chờ KT xác nhận thu và hoàn tiền'
    }

    public static LiquidationType = { //loại thanh lý
        termination: "Thanh lý chấm dứt",
        transfer: "Thanh lý chuyển nhượng",
    }
    public static ContractType = {
        deposit: "HĐ Cọc",
        // loan: "HĐ góp vốn",
        purchase: "HĐ mua bán",
        rent: "HĐ thuê",
        // LIQUID: "liquid",
    }

    public static LiquidateStatus = {
        init: 'Khởi tạo',
        waiting: 'Chờ duyệt',
        approved: 'Đã duyệt',
        accountant_waiting_confirm_refund: 'Chờ KT xác nhận thu và hoàn tiền'
    }
}
