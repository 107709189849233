import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmployeeService} from '../../shared/services/employee.service';
import {Employee, PublicEmployee} from '../../shared/models/employee.model';
import * as _html2canvas from 'html2canvas';
import { BehaviorSubject } from 'rxjs-compat';
import { ToastrService } from 'app/shared/services/common';
import { Meta } from '@angular/platform-browser';


@Component({
    selector: 'app-employee-public',
    templateUrl: './employee-public.component.html',
    styleUrls: ['./employee-public.component.scss']
})

export class EmployeePublicComponent implements OnInit {

    @ViewChild('screen') screen: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    @ViewChild('downloadLink') downloadLink: ElementRef;
    @ViewChild('copyUrlBtn') copyUrlBtn: ElementRef;
    @ViewChild('copyZaloBtn') copyZaloBtn: ElementRef;
    @ViewChild('copyViberBtn') copyViberBtn: ElementRef;
    @ViewChild('button') button: ElementRef;
    @ViewChild('downloadBtn') downloadBtn: ElementRef;
    @ViewChild('linkAbout') linkAbout: ElementRef;
    @ViewChild('breadcrumb') breadcrumb: ElementRef;
    @ViewChild('backBtn') backBtn: ElementRef;

    public qrCodeInfo = window.location.href;
    isFacebook: boolean = false;
    isLinkedIn: boolean = false;
    isZalo: boolean = false;
    isViber: boolean = false;

    facebookLink: string;
    linkedInLink: string;
    zaloLink: string;
    viberLink: string;
    address: string = '';
    id: string;
    employee: PublicEmployee = new PublicEmployee({});
    projectsActive: any = [];
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        private employeeService: EmployeeService,
        public route: ActivatedRoute,
        public router: Router,
        public toastr: ToastrService,
        private _metaService: Meta
    ) {}

    ngOnInit() {
        const params = this.route.snapshot.params;
        this.id = params.id;
        this.getData(this.id);
    }

    private setMetaTags(image, description, title) {
        this._metaService.updateTag({ property: 'og:description', content: description });
        this._metaService.updateTag({ property: 'og:image', content: image });
        this._metaService.updateTag({ property: 'og:url', content: window.location.href });
        this._metaService.updateTag({ property: 'og:title', content: title });
   }

    getData(id) {
        this.employeeService.getNearestEmployeeById(id).subscribe(res => {
            this.employee = res;
            const timestamp = new Date().getTime();
            const image = res.images && res.images.avatar ?  (res.images.avatar.includes('?') ? `${res.images.avatar}&v=${timestamp}` : `${res.images.avatar}?v=${timestamp}`) : ''
            const description = res.name;
            const title = res.role.name;
            this.setMetaTags(image, description, title);
            if (this.employee.images) {
                this.employee.images.avatar = this.employee.images && this.employee.images.avatar ?  (this.employee.images.avatar.includes('?') ? `${this.employee.images.avatar}&v=${timestamp}` : `${this.employee.images.avatar}?v=${timestamp}`) : '';
            }
            this.projectsActive = this.employee.projects.map(project => {
                project.imageUrl =  project.imageUrl ? (project.imageUrl.includes('?') ? `${project.imageUrl}&v=${timestamp}` : `${project.imageUrl}?v=${timestamp}`) : '';
                return project;
            });
            if (this.employee.socialUrls.length) {
                this.employee.socialUrls.forEach((element: any) => {
                    switch (element.name) {
                        case 'Facebook':
                            this.isFacebook = element.url;
                            this.facebookLink = element.url;
                            break;
                        case 'LinkedIn':
                            this.isLinkedIn = element.url;
                            this.linkedInLink = element.url;
                            break;
                        case 'Viber':
                            this.isViber = element.url;
                            this.viberLink = element.url;
                            break;
                        case 'Zalo':
                            this.isZalo = element.url;
                            this.zaloLink = element.url;
                            break;

                    }
                });
            }
            if (this.employee.interestedArea) {
                if (this.employee.interestedArea.address) {
                    this.address = this.address + this.employee.interestedArea.address;
                }
                if (this.employee.interestedArea.ward) {
                    this.address = this.address + `, ${this.employee.interestedArea.ward}`;
                }
                if (this.employee.interestedArea.district) {
                    this.address = this.address + `, ${this.employee.interestedArea.district}`;
                }
                if (this.employee.interestedArea.province) {
                    this.address = this.address + `, ${this.employee.interestedArea.province}`;
                }
            }
            if (this.address.charAt(0) === ',') {
                this.address = this.address.replace(',', '');
            }
            window.scrollTo(0, 0);
        });
    }
    scroll($element) {
        $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
    copyUrl() {
        this.copyUrlBtn.nativeElement.click();
    }
    copyViberUrl() {
        this.copyViberBtn.nativeElement.click();
    }
    copyZaloUrl() {
        this.copyZaloBtn.nativeElement.click();
    }

    notify($event) {
        this.toastr.success('Thành công!', 'Đã sao chép');
    }

    goToLink(url: string) {
        window.open(url,  '_blank');
    }

    downloadImage() {
        this.isLoading$.next(true);
        const html2canvas: any = _html2canvas;
        this.button.nativeElement.style['max-height'] = '1px';
        this.linkAbout.nativeElement.style.display = 'none';
        this.downloadBtn.nativeElement.style.display = 'none';
        this.backBtn.nativeElement.style.display = 'none ';
        this.breadcrumb.nativeElement.style.display = 'none';
        html2canvas(this.screen.nativeElement, {
            allowTaint : false,
            useCORS: true
        }).then(canvas => {
            this.canvas.nativeElement.src = canvas.toDataURL();
            this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            this.downloadLink.nativeElement.download = `${this.employee.name}.png`;
            this.downloadLink.nativeElement.click();
            this.button.nativeElement.style['max-height'] = 'unset';
            this.linkAbout.nativeElement.style.display = 'block';
            this.downloadBtn.nativeElement.style.display = 'block';
            this.backBtn.nativeElement.style.display = '';
            this.breadcrumb.nativeElement.style.display = '';
            this.isLoading$.next(false);
            // canvas.toBlob((blob) => {
            //     window.open(URL.createObjectURL(blob), '_blank');
            //     this.isLoading$.next(false);
            // });
        });
    }

    goToHome() {
        this.router.navigate(['/tu-van-vien'], { queryParamsHandling: 'merge' });
    }
}
