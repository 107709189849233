import { AuthConfig } from 'angular-oauth2-oidc';

export const oauthConfig: AuthConfig = {
  clientId: '',
  redirectUri: window.location.origin,
  postLogoutRedirectUri: '',
  loginUrl: '',
  scope: '',
  resource: '',
  rngUrl: '',
  oidc: false,
  requestAccessToken: true,
  options: null,
  issuer: '',
  clearHashAfterLogin: true,
  tokenEndpoint: '',
  userinfoEndpoint: '',
  responseType: '',
  showDebugInformation: true,
  silentRefreshRedirectUri: window.location.origin,
  silentRefreshMessagePrefix: '',
  silentRefreshShowIFrame: false,
  silentRefreshTimeout: 20000,
  dummyClientSecret: '',
  requireHttps: 'remoteOnly',
  strictDiscoveryDocumentValidation: false,
  customQueryParams: null,
  timeoutFactor: 0.75,
  sessionCheckIntervall: 3000,
  disableAtHashCheck: false,
  skipSubjectCheck: false
};

