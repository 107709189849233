import { BREAKPOINT, LayoutDirective } from '@angular/flex-layout';
import { Directive } from '@angular/core';

const IPAD_BREAKPOINTS = [
  {
    alias: 'ipad',
    suffix: 'Ipad',
    mediaQuery: 'screen and (max-width: 768px)',
    overlapping: true,
  }
];

export const IpadBreakpointsProvider = {
  provide: BREAKPOINT,
  useValue: IPAD_BREAKPOINTS,
  multi: true
};

const inputs = ['fxLayout.ipad'];
const selector = `[fxLayout.ipad]`;

@Directive({selector, inputs})
export class IpadLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
