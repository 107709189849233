import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const markAsTouched = (group: FormGroup | FormArray) => {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
        const control = group.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            markAsTouched(control);
        }
    });
}