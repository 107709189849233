import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface ImageData {
  url: string;
}

@Component({
  selector: 'image-popup',
  templateUrl: 'image-popup.html',
  styleUrls: ['image-popup.scss']
})
export class ImagePopup implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImagePopup>,
    @Inject(MAT_DIALOG_DATA) public data: ImageData) {}

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.dialogRef.close(this.data);
  }
}
