import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab-component-v3',
  templateUrl: './tab-component-v3.component.html',
  styleUrls: ['./tab-component-v3.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabComponentV3Component implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
