import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-popup-confirm-phone',
  templateUrl: './popup-confirm-phone.component.html',
  styleUrls: ['./popup-confirm-phone.component.scss']
})
export class PopupConfirmPhoneComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupConfirmPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onYesClick(){
    this.dialogRef.close({isConfirm: true});
  }
  onNoClick(){
    this.dialogRef.close();
  }

}
