import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Constant } from '../../constant/constant';
import { GroupsAttributes } from './groups-attributes.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { DefineAttribute } from '../../../pages/define-attributes/define-attributes.model';
import { GroupAttributes } from '../../../pages/define-attributes/group-attributes.model';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
@Component({
    selector: 'groups-attribute',
    templateUrl: './groups-attribute.component.html',
    styleUrls: ['./groups-attribute.component.scss']
})

export class GroupsAttributeComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() attributes;
    @Input() isEdit;
    @Input() isView;
    @Input() isSetDisplay;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    fullAttributes: any = [];
    groups: any = [];
    orders: any = Constant.ORDERS;
    controlTypes: any = Constant.CONTROL_TYPES;
    controlTypesObject: any = Constant.CONTROL_TYPES_OBJECT;
    dataInList: any = {};
    filteredControl: FormControl = new FormControl();
    filteredOptions: Observable<string[]>;
    changeGroupName: number;
    groupName: string;
    public step = 0;

    constructor(
        private cdf: ChangeDetectorRef
    ) {
    }
    ngOnInit() {
        this.cdf.detectChanges();
    }
    ngOnChanges(event) {
        this.cdf.detectChanges();
        this.setAttributes(this.fullAttributes, this.attributes);
    }
    ngAfterViewInit() {
        this.cdf.detectChanges();
    }
    setAttributes(fullAttributes, attributes?) {
        this.fullAttributes = fullAttributes || this.fullAttributes;
        this.attributes = attributes || this.attributes;
        if (this.fullAttributes && this.fullAttributes.length > 0) {
            this.fullAttributes.filter = (pred) => {
                let array = [];
                this.fullAttributes.forEach((attribute) => {
                    if (pred(attribute)) {
                        array.push(attribute);
                    }
                })
                return array;
            };
            this.filteredOptions = this.filteredControl.valueChanges
                .pipe(startWith(''),
                    map(val => this.filter(val))
                );
        }
        if (this.attributes && this.attributes.length > 0) {
            this.groups = new GroupsAttributes({
                attributes: this.attributes
            }).groups || [];
        }
    }
    filter(val): string[] {
        if (!val || typeof val !== 'string') {
            val = ''
        }
        return this.fullAttributes.filter(attribute => attribute.name.toLowerCase().includes(val.toLowerCase()));
    }
    addAttribute(group?) {
        if (group.selectedAttribute && group.selectedAttribute.id) {
            if (!group.attributes.some(attribute => attribute.id === group.selectedAttribute.id)) {
                group.attributes.push(new DefineAttribute({
                    id: group.selectedAttribute.id,
                    name: group.selectedAttribute.name,
                    groupName: group.groupName,
                    displayOrder: group.attributes.length + 1
                }));
                group.showed = true;
                group.setOrders();
                setTimeout(() => {
                    group.selectedAttribute = '';
                }, 10)
            }
        }
    }
    removeAttribute(attribute) {
        this.groups.forEach((group) => {
            if (group.groupName == attribute.groupName) {
                let position = group.attributes.indexOf(attribute);
                group.attributes.splice(position, 1);
            }
        })
    }
    changeContainerTag(event) {
        let parent = event.parent;
        this.groups.forEach((group) => {
            group.attributes.forEach((attribute) => {
                if (attribute.id == parent.id && group.groupName == parent.groupName) {
                    attribute = parent;
                }
            })
        })
    }
    addGroup() {
        let nameGroup = NAMES_GROUP[this.groups.length] || 'Chưa chủ đề';
        this.groups.push(new GroupAttributes({
            groupName: nameGroup,
            groupOrder: this.groups.length + 1,
            showed: true
        }));
        this.step = this.groups.length - 1;
    }
    removeGroup(group) {
        let position = this.groups.indexOf(group);
        this.groups.splice(position, 1);
    }
    createInputChangeName(group, index) {
        group.isChange = true;
        group.nameChange = group.groupName;
        this.changeGroupName = index;
    }
    changeNameGroup() {
        let group = this.groups[this.changeGroupName];
        group.groupName = group.nameChange;
        group.isChange = false;
        this.changeGroupName = null;
        group.attributes.forEach((attribute) => {
            attribute.groupName = group.groupName;
        });
    }
    cancelChangeName(object, type) {
        object.isChange = false;
    }
    upwardOrderGroup(index) {
        if (index == 0) {
            return;
        }
        this.groups[index - 1].groupOrder -= 1;
        this.groups[index].groupOrder += 1;
        let temp = Object.assign({}, this.groups[index - 1]);
        this.groups[index - 1] = this.groups[index];
        this.groups[index] = temp;
    }
    downwardOrderGroup(index) {
        if (index == this.groups.length - 1) {
            return;
        }
        this.groups[index + 1].groupOrder -= 1;
        this.groups[index].groupOrder += 1;
        let temp = Object.assign({}, this.groups[index]);
        this.groups[index] = this.groups[index + 1];
        this.groups[index + 1] = temp;
    }
    changeOrder(group, attribute) {
        let beforePosition = 0;
        group.attributes.find((item, index) => {
            if (item.id == attribute.id) {
                beforePosition = index;
            }
        });
        if (beforePosition < attribute.displayOrder) {
            group.attributes.splice(attribute.displayOrder, 0, attribute);
        } else group.attributes.splice(attribute.displayOrder - 1, 0, attribute);
        let position = -1;
        group.attributes.forEach((item, index) => {
            if (item.id == attribute.id && index != attribute.displayOrder - 1 && position < 0) {
                position = index;
            } else if (item.id != attribute.id) {
                if (index > attribute.displayOrder - 1) {
                    if (group.attributes[index - 1].displayOrder == item.displayOrder) {
                        item.displayOrder++;
                    }
                } else if (index == attribute.displayOrder - 1) {
                    item.displayOrder--;
                }
            }
        });
        group.attributes.splice(position, 1);
    }
    changeDataAttribute(value, attribute) {
        attribute.value = value;
    }
    getAttributes() {
        return GroupsAttributes.parseAttributesFromGroups(this.groups);
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }
}

const NAMES_GROUP = [
    'Tiện ích',
    'Nội Thất'
];

