import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import { HttpService } from 'app/shared/services/http.service';
import { map } from 'rxjs/operators';
import { Consignment } from './consignment.model';
import { CPagination } from 'app/api-models/c-pagination';
import { Observable } from 'rxjs';

@Injectable()
export class ConsignmentService extends BaseService {
    ConstantUrl = ConstantUrl;
    constructor(
        public service: DataService,
        public httpService: HttpService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_property_domain + 'consignment';
        this.queryUrl = ConstantUrl.url_property_query + 'consignment';
    }
    insertCustomer(customer) {
        const url = ConstantUrl.url_property_domain + 'propertyCustomer';
        return this.service.post(url, customer);
    }
    insertSurveyPropertyUnit(property) {
        const url = ConstantUrl.url_property_domain + 'surveyPropertyUnit';
        return this.service.post(url, property);
    }
    getTicketById(id) {
        const url = ConstantUrl.url_ticket_query + 'lead/' + id;
        return this.service.get(url);
    }
    checkIdentity(request) {
        const queryParams = new URLSearchParams(request);
        let url = ConstantUrl.url_property_query + 'propertyCustomer/checkCustomerExist?' + queryParams.toString();
        return this.service.get(url);
    }
    createContract(object, headers) {

        const url = ConstantUrl.url_contract_domain + 'contract';
        return this.service.post(url, object, headers);
    }

    searchWithFilter(request) {
        const queryParams = new URLSearchParams(request);
        let url = ConstantUrl.url_property_query + 'consignment/filter?' + queryParams.toString();
        return this.service.get(url);
    }
    get(params?) {
        let url = ConstantUrl.url_property_query + 'consignment';
        return this.httpService.get(url, params ? params : null);
    }
    getFilterList() {
        let url = ConstantUrl.url_property_query + 'consignment/filterList';
        return this.service.get(url);
    }
    getCustomerById(id, type) {
        let url = type === 'pos' ? ConstantUrl.url_property_query + 'propertyCustomer/' + id : ConstantUrl.url_customer_query + 'customer/' + id;
        return this.service.get(url);
    }

    getConsignment(params?: any): Observable<CPagination<Consignment>> {
        const url = ConstantUrl.url_property_query + 'consignment';
        return this.httpService.get(url, params ? params : null)
            .pipe(
                map((res: any) => {
                    const pagination = new CPagination<Consignment>().decode(res);
                    pagination.items = pagination.items.map((item: any) => new Consignment(item));
                    return pagination;
                })
            );
    }

    getConsignmentById(id: string): Observable<Consignment> {
        const url = this.queryUrl + '/' + id;
        return this.httpService.get(url)
            .pipe(
                map((res: any) => new Consignment(res))
            );
    }
    getByCustomerId(params?) {
      let url = ConstantUrl.url_property_query + 'consignment/customer';
      return this.service.get(url, params ? params : null);
    }
    getAllStatus() {
      let url = ConstantUrl.url_property_query + 'consignment/pub/status';
      return this.service.get(url);
    }
}
