
export enum EPermission {
    Report = 'report',
    Property = 'property',
    Primary = 'primary',
    POS = 'pos',
    Get = 'get',
    All = 'all',
    Update = 'update',
    Print = 'print',
    Recall = 'recall',
    TransactionReceipt = 'transaction-receipt',
    EditProcessContent= 'property.project.edit.process.content'
}


export const ConstPermissionReport = {
    POS: [EPermission.Report, EPermission.POS].join('.')
};

export const ConstPermissionPOS = {
    GetAll: [EPermission.POS, EPermission.Get, EPermission.All].join('.')
};

export const ConstPermissionTransactionReceipt = {
    Update: [EPermission.TransactionReceipt, EPermission.Update].join('.'),
    Print: [EPermission.TransactionReceipt, EPermission.Print].join('.'),
    Recall: [EPermission.TransactionReceipt, EPermission.Recall].join('.')
};

export const ConstPermissionProperty = {
    Primary: {
        Get: [EPermission.Property, EPermission.Primary, EPermission.Get].join('.'),
        GetAll: [EPermission.Property, EPermission.Primary, EPermission.Get, EPermission.All].join('.'),
        ViewMarketAll: 'property.primary.view.market.all'
    }
};

export const PropertyTicketPermissionConst = {
    Admin: {
        ApproveTicket: 'admin.approved.ticket',
        ApproveTicketCancelRequest: 'admin.approved.cancel.ticket',
        ConfirmLock: 'admin.confirm.lock',
        ApproveTransaction: 'admin.approved.transaction',
    },
    SaleAdmin: {
        SaCreateTicket: 'sa.create.ticket',
        TicketCreateForEmployee: 'ticket.create.for.employee',
        TicketEditForEmployee: 'ticket.edit.for.employee'
    },
    CustomerService: {
        ApproveTicket: 'cs.approved.ticket',
        SyncErpTicket: 'cs.sync.erp.ticket',
        ApproveTicketCancelRequest: 'cs.approved.cancel.ticket'
    },
    Others: {
        TransferEscrow: 'create.escrow.from.booking',
    }
};
export const TransactionPermissionConst = {
    Query: {
      ByProject: 'transaction.query.by.project'
    }
};
export const PropertyUnitPermissionConst = {
    GetAllPropertyUnit: 'property.primary.get.all',
    GetAllSaleList: 'property.primary.transaction.get.all',
    RegisterUnit: 'property.unit.register',
    QueueUnit: 'property.unit.queue',
    UpdateDesc: 'property.unit.update.desc',
    PropertyCSSendEmail: 'property.primary.cs.send.email',
    PropertyPrimaryGetByPos: 'property.primary.get.by.pos',
    ViewHistories: 'property.primary.view.histories',
    PropertyPrimaryUpdatePriceForce: 'property.primary.update.price.force'
};

export const PrimaryTransactionPermissionConst = {
    ExtendBookingTime: 'primary.transaction.extend.booking.time',
};


export const CommissionPermissionConst = {
    GetGroupPublish: 'commission.public.get.group',
};
export const RolesAsConst = {
    accountant: 'role.as.accountant',
};
export const TransactionSync = {
    Crm: 'transaction.sync.crm',
};