import { Component, OnInit, OnDestroy, Injector, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
export interface EventSalePopupData {
}
@Component({
  selector: 'app-popup-update-feature',
  templateUrl: './popup-update-feature.component.html',
  styleUrls: ['./popup-update-feature.component.scss'],
})

export class PopupUpdateFeatureComponent implements OnInit, OnDestroy, AfterViewInit {
  public updateFeature: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PopupUpdateFeatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EventSalePopupData,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
  }

  onChangeViewAll() {
  }
  
  onClose(data) { 
    if(data) {
      this.dialogRef.close();
      if(this.updateFeature) {
        this.dialogRef.close({updateFeature : this.updateFeature});
      }
    } else {
      this.dialogRef.close();
    }
  }
}
