import { environment } from '../../../environments/environment';
export class ConstantUrl {
    public static url_sts_query = environment.urlBackEnd + 'msx-sts/api/query/v1/';
    public static url_sts_domain = environment.urlBackEnd + 'msx-sts/api/domain/v1/';
    // public static url_sts_query = 'http://localhost:3200/api/query/v1/';
    // public static url_sts_domain = 'http://localhost:3200/api/domain/v1/';
    // public static url_mailer = 'http://localhost:3021/api/v1/';
    public static url_mailer = environment.urlBackEnd + 'msx-mailer/api/v1/';

    public static url_category_query = environment.urlBackEnd + 'msx-category/api/query/v1/';
    public static url_category_domain = environment.urlBackEnd + 'msx-category/api/domain/v1/';
    public static url_zone_query = environment.urlBackEnd + 'msx-zone/api/query/v1/';
    public static url_zone_domain = environment.urlBackEnd + 'msx-zone/api/domain/v1/';
    public static url_campaign_query = environment.urlBackEnd + 'msx-campaign/api/query/v1/';
    public static url_campaign_domain = environment.urlBackEnd + 'msx-campaign/api/domain/v1/';
    public static url_customer_query = environment.urlBackEnd + 'msx-customer/api/query/v1/';
    public static url_customer_domain = environment.urlBackEnd + 'msx-customer/api/domain/v1/';
    public static url_employee_query = environment.urlBackEnd + 'msx-employee/api/query/v1/';
    public static url_employee_domain = environment.urlBackEnd + 'msx-employee/api/domain/v1/';
    public static url_report_query = environment.urlBackEnd + 'msx-dxs-report/api/query/v1/';
    public static url_report_domain = environment.urlBackEnd + 'msx-dxs-report/api/domain/v1/';
    public static url_realtime_query = environment.urlBackEnd + 'msx-realtime/api/query/v1/';
    public static url_realtime_domain = environment.urlBackEnd + 'msx-realtime/api/domain/v1/';
    public static url_agency_query = environment.urlBackEnd + 'msx-agency/api/query/v1/';
    public static url_agency_domain = environment.urlBackEnd + 'msx-agency/api/domain/v1/';
    public static url_profile_query = environment.urlBackEnd + 'msx-profile/api/query/v1/';
    public static url_profile_domain = environment.urlBackEnd + 'msx-profile/api/domain/v1/';
    public static url_contract_query = environment.urlBackEnd + 'msx-contract/api/query/v1/';
    public static url_contract_domain = environment.urlBackEnd + 'msx-contract/api/domain/v1/';
    // public static url_transaction_query = 'http://localhost:3046/api/query/v1/';
    // public static url_transaction_domain = 'http://localhost:3046/api/domain/v1/';
    public static url_transaction_query = environment.urlBackEnd + 'msx-transaction/api/query/v1/';
    public static url_transaction_domain = environment.urlBackEnd + 'msx-transaction/api/domain/v1/';
    public static url_product_query = environment.urlBackEnd + 'msx-product/api/query/v1/';
    public static url_product_domain = environment.urlBackEnd + 'msx-product/api/domain/v1/';
    // public static url_property_query = 'http://localhost:3040/api/query/v1/';
    // public static url_property_domain = 'http://localhost:3040/api/domain/v1/';
    public static url_property_query = environment.urlBackEnd + 'msx-property/api/query/v1/';
    public static url_property_domain = environment.urlBackEnd + 'msx-property/api/domain/v1/';
    public static url_pos_query = environment.urlBackEnd + 'msx-pos/api/query/v1/';
    public static url_pos_domain = environment.urlBackEnd + 'msx-pos/api/domain/v1/';
    // public static url_ticket_query = 'http://localhost:3052/' + 'api/query/v1/';
    // public static url_ticket_domain = 'http://localhost:3052/' + 'api/domain/v1/';
    public static url_ticket_query = environment.urlBackEnd + 'msx-lead/api/query/v1/';
    public static url_ticket_domain = environment.urlBackEnd + 'msx-lead/api/domain/v1/';
    public static url_demand_query = environment.urlBackEnd + 'msx-demand/api/query/v1/';
    public static url_demand_domain = environment.urlBackEnd + 'msx-demand/api/domain/v1/';
    // public static url_lead_repo_query = 'http://localhost:3052/' + 'api/query/v1/';
    // public static url_lead_repo_domain = 'http://localhost:3052/' + 'api/domain/v1/';
    public static url_lead_repo_query = environment.urlBackEnd + 'msx-lead/api/query/v1/';
    public static url_lead_repo_domain = environment.urlBackEnd + 'msx-lead/api/domain/v1/';
    public static url_lead_query = environment.urlBackEnd + 'msx-lead/api/query/v1/';
    public static url_lead_domain = environment.urlBackEnd + 'msx-lead/api/domain/v1/';
    public static url_lead = environment.urlBackEnd + 'msx-lead/api/v1/';
    // public static url_lead = 'http://localhost:3052/api/v1/';
    // public static url_lead_query = 'http://localhost:3052/' + 'api/query/v1/';
    // public static url_lead_domain = 'http://localhost:3052/' + 'api/domain/v1/';
    public static url_matching_query = environment.urlBackEnd + 'msx-matching/api/query/v1/';
    public static url_matching_domain = environment.urlBackEnd + 'msx-matching/api/domain/v1/';
    public static url_consignment_query = environment.urlBackEnd + 'msx-consignment/api/query/v1/';
    public static url_consignment_domain = environment.urlBackEnd + 'msx-consignment/api/domain/v1/';

    // public static url_orgchart = 'http://localhost:3038/api/v1/';
    // public static url_orgchart_domain = 'http://localhost:3038/' + 'api/domain/v1/';
    // public static url_orgchart_query = 'http://localhost:3038/' + 'api/query/v1/';

    public static url_orgchart_query = environment.urlBackEnd + 'msx-orgchart/api/query/v1/';
    public static url_orgchart_domain = environment.urlBackEnd + 'msx-orgchart/api/domain/v1/';

    public static url_deal_query = environment.urlBackEnd + 'msx-dealing/api/query/v1/';
    public static url_deal_domain = environment.urlBackEnd + 'msx-dealing/api/domain/v1/';
    public static url_dealt_query = environment.urlBackEnd + 'msx-dealt/api/query/v1/';
    public static url_dealt_domain = environment.urlBackEnd + 'msx-dealt/api/domain/v1/';
    public static url_user_query = ConstantUrl.url_sts_query + 'user';
    public static url_user_domain = ConstantUrl.url_sts_domain + 'user';
    public static url_get_all_role = ConstantUrl.url_sts_query + 'role';
    public static url_notification_domain = environment.urlBackEnd + 'msx-notification/api/domain/v1/';
    public static url_notification_query = environment.urlBackEnd + 'msx-notification/api/query/v1/';
    public static url_notification = environment.urlBackEnd + 'msx-notification/api/v1/';


    public static url_booking_query = environment.urlBackEnd + 'msx-booking-calendar/api/v1/';
    public static url_booking_domain = environment.urlBackEnd + 'msx-booking-calendar/api/v1/';
    public static url_geocoder_query = environment.urlBackEnd + 'msx-geocoder/api/query/v1/';
    public static url_communication_query = environment.urlBackEnd + 'msx-communication/api/query/v1/';
    public static url_communication_domain = environment.urlBackEnd + 'msx-communication/api/domain/v1/';
    public static url_upload = environment.urlBackEnd + 'msx-uploader/api/v1/fileupload';
    public static url_payment = environment.urlBackEnd + 'msx-payment/api/v1/';
    // public static url_payment = 'http://localhost:3055/api/v1/'

    public static url_property = environment.urlBackEnd + 'msx-property/api/v1/';
    //public static url_property = 'http://localhost:3040/api/v1/admin';
    public static url_commission_query = environment.urlBackEndFpt + 'msx-commission-ihz/api/query/v1/';
    public static url_commission_domain = environment.urlBackEndFpt + 'msx-commission-ihz/api/domain/v1/';
    public static url_sale_policy_query = environment.urlBackEnd + 'msx-primary-contract/api/query/v1/';
    public static url_sale_policy_domain = environment.urlBackEnd + 'msx-primary-contract/api/domain/v1/';

    // public static url_sale_policy_query = 'http://localhost:3050/api/query/v1/';
    // public static url_sale_policy_domain = 'http://localhost:3050/api/domain/v1/';

    public static url_logger = environment.urlBackEnd + 'msx-logger/api/query/v1/';
    public static url_workflow_step_query = environment.urlBackEnd + 'msx-workflow/api/query/v1/';
    public static url_workflow_step_domain = environment.urlBackEnd + 'msx-workflow/api/domain/v1/';
    public static url_news_query = environment.urlBackEnd + 'msx-news/api/query/v1/';
    public static url_news_domain = environment.urlBackEnd + 'msx-news/api/domain/v1/';
    // public static url_training_query = 'http://localhost:3057/api/query/v1/';
    // public static url_training_domain = 'http://localhost:3057/api/domain/v1/';
    public static url_training_query = environment.urlBackEnd + 'msx-training/api/query/v1/';
    public static url_training_domain = environment.urlBackEnd + 'msx-training/api/domain/v1/';
    // Voice Call
    public static url_voice_call_call_log = environment.voiceCall.uriAPI + 'v1/call/log';
    // tavico
    public static url_erp = environment.urlErp;

    public static url_care_query = environment.urlBackEnd + 'msx-care/api/query/v1/';
    public static url_care_domain = environment.urlBackEnd + 'msx-care/api/domain/v1/';
    public static url_care = environment.urlBackEnd + 'msx-care/api/v1/';
    // public static url_care = 'http://localhost:3058/api/v1/';
    // public static url_master = 'http://localhost:3029/api/v1/';
    public static url_master_data = environment.urlBackEnd + 'msx-master-data/api/v1/';

    public static url_social = environment.urlBackEnd + 'msx-social/api/v1/';
    public static url_social_query = environment.urlBackEnd + 'msx-social/api/query/v1/';
    public static url_social_domain = environment.urlBackEnd + 'msx-social/api/domain/v1/';
    public static url_rating_lead_care_domain = environment.urlBackEnd + 'msx-rating/api/query/v1/';
    // public static url_apartment_query = environment.urlBackEnd + 'api/query/v1/';
    // public static url_apartment_domain = environment.urlBackEnd + '/api/query/v1/';

    public static url_utility_query = environment.urlBackEnd + 'msx-utility/api/query/v1/';
    public static url_utility_domain = environment.urlBackEnd + 'msx-utility/api/domain/v1/';
    public static url_employee = environment.urlBackEnd + 'msx-employee/api/v1/';
    // public static url_checkin =  'http://localhost:3062/api/v1/';
    public static url_checkin = environment.urlBackEnd + 'msx-checkin/api/v1/';
    // public static url_sync_erp = 'http://localhost:3065/api/v1/';
    public static url_sync_erp = environment.urlBackEnd + 'msx-sync-erp/api/v1/';
    public static url_evoucher = environment.urlBackEnd + 'msx-e-voucher/api/v1/';
    // public static url_evoucher = 'http://localhost:3064/api/v1/';
    public static url_project_market = environment.urlBackEnd + 'msx-project-market/api/v1/';
}
