import { Component, OnInit, OnDestroy, Injector, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-popup-json-viewer',
  templateUrl: './popup-json-viewer.component.html',
  styleUrls: ['./popup-json-viewer.component.scss']
})
export class PopupJsonViewerComponent implements OnInit {

  title = '';
  content = '';

  constructor(
    public dialogRef: MatDialogRef<PopupJsonViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.title = this.data.title;

    // for (const [key, value] of Object.entries(this.data.body)) {
    //   this.content += `<p> <b>${key}:</b>   ${value} </p>`;
    // }
    this.content = this.data.body;
  }

  onClose() {
    this.dialogRef.close();
  }

}
