import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { DomSanitizer } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CDocument, EDocumentFileType } from 'app/api-models/c-document';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@Component({
  selector: 'app-carousel-image-v2',
  templateUrl: './carousel-image-v2.component.html',
  styleUrls: ['./carousel-image-v2.component.scss'],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
})
export class CarouselImageV2Component implements OnInit, AfterViewInit {
  // @ViewChild('nav', { static: true, read: DragScrollComponent }) ds: DragScrollComponent;
  @ViewChild('nav') nav: DragScrollComponent;

  
  @Input() images = [];
  @Input() indexImage = 0;
  @Input() control = true;


  public selectedImage: any;
  public selectedIndex: number = 0;
  public selectedUrl: any = '';

  public get isFirst(): boolean {
    return this.selectedIndex === 0;
  }

  public get isLast(): boolean {
    return this.selectedIndex === this.images.length - 1;
  }

  constructor(public domSanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.images && this.images.length) {
      this.selectedImage = this.images[0];
      this.selectedIndex = 0;
      this.updateImageUrl(this.selectedImage);
    }
  }

  onSwipe(evt) {
    let x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left'):'';
    if (this.images && this.images.length > 2 && this.isImage(this.images[0]) && x ==='left') {
      this.nextImage();
    }
    if (this.images && this.images.length > 2 && this.isImage(this.images[0]) && x ==='right') {
      this.previousImage();
    }
}

  public updateImageUrl(image) {
    const url = image && image.url ? image.url : `./assets/img/v2/img/img-can-ho.svg`;
    this.selectedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit() {
    this.moveTo(this.indexImage);
    this.selectImage(this.images[this.indexImage]);
  }

  public isSelected(image: any) {
    return this.selectedImage === image;
  }

  public getImage(image: any) {

    return (image && image.url)
    ? `${image.url}`
    : `./assets/img/v2/img/img-can-ho.svg`;

    // return (image && image.url)
    //   ? `url('${image.url}'), url('./assets/img/v2/img/img-can-ho.svg')`
    //   : `url('./assets/img/v2/img/img-can-ho.svg')`;
  }

  public selectImage(image: any) {
    this.selectedImage = image;
    this.selectedIndex = this.images.indexOf(image);
    this.updateImageUrl(this.selectedImage);
  }

  public previousImage() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
      this.moveTo(this.selectedIndex);
    }
  }

  public nextImage() {
    if (this.selectedIndex < this.images.length - 1) {
      this.selectedIndex++;
      this.moveTo(this.selectedIndex);
    }
  }

  private moveTo(index: number = 0) {
    this.nav.moveTo(index);
    this.selectedImage = this.images[index];
    this.updateImageUrl(this.selectedImage);
  }

  isImage(document: CDocument): boolean {
    return !document.fileType || document.fileType === EDocumentFileType.Image;
  }

  isVideo(document: CDocument): boolean {
    return document.fileType === EDocumentFileType.Video;
  }

  isVideoLink(document: CDocument): boolean {
    return document.fileType === EDocumentFileType.Link;
  }

}

