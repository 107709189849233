import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import { Project } from '../project/project.model';
import { HttpService } from 'app/shared/services/http.service';
import { map } from 'rxjs/operators';
import {CPagination} from '../../api-models';
import {ReportProperty} from './report/report.model';
import { Property } from '../property/property.model';
import { PropertyPrimaryReport } from './primary-report/primary-report.model';

@Injectable()
export class PropertyService extends BaseService {
    ConstantUrl = ConstantUrl;
    constructor(
        public service: DataService,
        public httpService: HttpService,
        private storageService: StorageService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_property_domain + 'propertyUnit';
        this.queryUrl = ConstantUrl.url_property_query + 'propertyUnit';

    }
    getUnitById(id) {
        return this.service.get(this.queryUrl + '/' + id);
    }
    createProperty(property): Observable<Response> {
        return this.service.post(this.domainUrl, property);
    }
    getTypeProperty() {
        return this.service.get(this.domainUrl);
    }
    getAttributeForCategory(categoryId, type) {
        const url = ConstantUrl.url_property_query + 'propertyTemplate/pub/category/attr/' + categoryId + '/type/' + type;
        return this.service.get(url);
    }
    getPOS() {
        let url = ConstantUrl.url_pos_query + 'pos';
        return this.service.get(url);
    }
    getCategories(params?) {
        let url = ConstantUrl.url_category_query + 'category';
        return this.service.get(url, params ? params : {});
    }
    getEmployees() {
        let url = ConstantUrl.url_employee_query + 'employee';
        return this.service.get(url);
    }
    getCustomers() {
        let url = ConstantUrl.url_customer_query + 'customer';
        return this.service.get(url);
    }
    getMasterDataProvince() {
        let url = this.queryUrl + '/pub/hanhchinhvn';
        return this.service.get(url);
    }

    public searchWithFilter(request: any) {
        const queryParams = new URLSearchParams(request);
        const url = ConstantUrl.url_property_query + 'propertyUnit/filter?' + queryParams.toString();
        return this.service.get(url);
    }

    public getAll(params?: any) {
        return this.service.get(this.queryUrl, params);
    }
    public getProjectDropdownList() {
        return this.service.get(this.ConstantUrl.url_property_query + 'project/admin/dropdownList');
    }

    public getProject(): Observable<Project[]> {
        const url = this.ConstantUrl.url_property_query + 'project/admin/dropdownList';
        return this.httpService.get(url)
            .pipe(
                map((res: any) => {
                    if (res && res.rows && Array.isArray(res.rows)) {
                        return res.rows.map((item: any) => new Project(item));
                    }
                    return res && Array.isArray(res) ? res.map((item: any) => new Project(item)) : [];
                })
            );
    }
    getPropertyReport(p): Observable<CPagination<ReportProperty>> {
        const url = ConstantUrl.url_contract_query + 'contract/report';
      return this.service.get(url, p).pipe(
        map((res: any) => {
          const pagination = new CPagination<ReportProperty>().decode(res);
          pagination.items = pagination.items.map((item: any) => new ReportProperty(item));
          return pagination;
        })
      );
    }
    getPropertyPrimaryReport(p): Observable<CPagination<PropertyPrimaryReport>> {
        const url = ConstantUrl.url_property_query + 'primaryTransaction/primaryReport';
      return this.service.get(url, p).pipe(
        map((res: any) => {
          const pagination = new CPagination<PropertyPrimaryReport>().decode(res);
          pagination.items = pagination.items.map((item: any) => new PropertyPrimaryReport(item));
          return pagination;
        })
      );
    }
    getSalesProgramByProjectId(projectId) {
        const url = ConstantUrl.url_property_query + `salesProgram/getByProjectId/${projectId}`;
        return this.service.get(url);
    }
    exportPropertyPrimaryReport(p)  {
        const url = ConstantUrl.url_property_query + 'primaryTransaction/primaryReport';
        return this.service.get(url, p);
    }

    getPropertyReportExport(p) {
      p.page = '';
      p.pageSize = '';
      const url = ConstantUrl.url_contract_query + 'contract/report';
      return this.service.get(url, p);
    }
    getPropertyReportSummary(p) {
      const url = ConstantUrl.url_property_query + 'surveyProperty/report/count';
      return this.service.get(url, p);
    }

    getProperty(params?) : Observable<CPagination<Property>> {
        const url = ConstantUrl.url_property_query + 'propertyUnit';
            return this.service.get(url, params ? params : null)
                .pipe(
                    map((res: any) => {
                        const pagination = new CPagination<Property>().decode(res);
                        pagination.items = pagination.items.map((item: any) =>  new Property(item));
                        return pagination;
                    })
                );
      }

    public getProperties(params): Observable<CPagination<Property>> {
        const url = ConstantUrl.url_property_query + 'propertyUnit';
        return this.service.get(url, params).pipe(
            map((res: any) => {
            const pagination = new CPagination<Property>().decode(res);
            pagination.items = pagination.items.map((item: any) => new Property(item));
            return pagination;
            })
        );
    }

    getFilterList() {
        let url = ConstantUrl.url_property_query + 'consignment/filterList';
        return this.service.get(url);
    }


    getProvinces() :Observable<any[]>{
        let url = ConstantUrl.url_property_query + 'evaluation/getProvinces';
        return this.service.get(url);
    }

    getDistricts(id):Observable<any[]> {
        if(id === null || id === 0 || id === ''){
            return Observable.of(null);
        }
        let url = ConstantUrl.url_property_query + 'evaluation/getDistricts/' + id;
        return this.service.get(url);
    }

    getWards(id):Observable<any> {
        if(id === null || id === 0 || id === ''){
            return Observable.of(null);
        }
        let url = ConstantUrl.url_property_query + 'evaluation/getWards/' + id;
        return this.service.get(url);
    }

    getStreets(id) :Observable<any>{
        if(id === null || id === 0 || id === ''){
            return Observable.of(null);
        }
        let url = ConstantUrl.url_property_query + 'evaluation/getStreets/' + id;
        return this.service.get(url);
    }

    getProjects(id) :Observable<any>{
        if(id === null || id === 0 || id === ''){
            return Observable.of(null);
        }
        let url = ConstantUrl.url_property_query + 'evaluation/getProjects/' + id;
        return this.service.get(url);
    }

    getProjectList(params?) {
        let url = ConstantUrl.url_property_query + 'project/admin';
        return this.service.get(url, params || {});
    }
    getProjectDropdown(params?) {
        let url = ConstantUrl.url_property_query + 'project/admin/dropdownList?search=&status=Sắp mở bán;Đang giao dịch&page=1';
        return this.service.get(url, params || {});
    }
    getDinhGiaProperty(property) {
        const url = ConstantUrl.url_property_query + 'evaluation';
        return this.service.post(url, property);
    }

    extendPos(body: any) {
        const url = this.domainUrl + '/extendPos';
        return this.httpService.post(url, body);
    }

    downloadUpdatePrioritiesTemplate(params) {
      const uri = this.queryUrl + '/primary/download-update-primary-template';
      return this.service.dowloadFile(uri, params);
    }

    dowloadMatchingPriorities(params, fileName?) {
      const uri = this.queryUrl + '/report/download-matching-priorities';
      return this.service.dowloadFile(uri, params, fileName);
    }
    dowloadPrioritiesOfPos(params, fileName?) {
      const uri = this.queryUrl + '/report/download-pos-priorities';
      return this.service.dowloadFile(uri, params, fileName);
    }

    getUnitTransaction(params: any) {
        const url = this.queryUrl + '/primary/tranx';
        return this.httpService.get(url, params);
    }
    getOutPriority(params: any) {
        const url = this.queryUrl + '/primary/getOutPriority';
        return this.httpService.get(url, params);
    }

    registerUnit(body: any) {
      const url = this.queryUrl + '/registerUnit';
      return this.httpService.post(url, body);
    }
    cancelRegisterUnit(body: any) {
      const url = this.domainUrl + '/cancelRegisterUnit';
      return this.httpService.post(url, body);
    }
    queueUnit(body: any) {
      const url = this.domainUrl + '/queueUnit';
      return this.httpService.post(url, body);
    }

    saveDescription(params) {
        const url = this.domainUrl + '/update-description';
        return this.httpService.put(url, params);
    }
    lockExtendPriority(params) {
        const url = this.domainUrl + '/lock-extension-priority';
        return this.httpService.put(url, params);
    }
    setExeptionalUnit(body) {
        const url =  this.domainUrl  + '/exceptional-unit';
        return this.service.post(url, body);
    }
    sortPriorities(id, params){
        const url =  this.domainUrl  + `/sort-priority/${id}`;
        return this.service.put(url, params);
    }
    getFileFromUrl(url) {
        return this.service.getFileFromUrl(url);
      }

    public importTransferFile(files: File, body) {
        const url = this.domainUrl + '/importTransfer';
        let formData = new FormData();
        formData.append('files', files);
        formData.append('projectId', body.projectId);
        formData.append('projectName', body.projectName);
        formData.append('salesProgramId', body.salesProgramId);
        return this.service.post(url, formData);
    }

    getAllowBookingUnit(salesProgramId: string) {
        const params = {
            _fields: 'id,code'
        };
        return this.service.get(this.queryUrl + `/booking/${salesProgramId}`, params);
    }
    removePropertyImage(data) {
        return this.service.put(`${this.queryUrl}/removePropertyImage`, data);
    }
    public downloadPropertyByQuery(body = {}, fileName) {
        const url = ConstantUrl.url_property_query + 'propertyUnit/property/download-query';
        return this.service.dowloadFile(url, body, fileName);
    }
}
