import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-notification-completed',
  templateUrl: './notification-completed.component.html',
  styleUrls: ['./notification-completed.component.scss']
})
export class NotificationCompletedComponent implements OnInit {
  @Input() customerName?: string;
  @Output() remove = new EventEmitter();
  public isRemoved = false;

  constructor() { }

  ngOnInit() {
    // Animate fade out
    // setTimeout(() => this.isRemoved = true, 4500);
    // Remove notification after finish animation
    // setTimeout(() => this.remove.emit(this.customerName), 5000);

    // Nếu chưa có giao dịch thành công mới, 2s sau đẩy lần lượt lại những giao dịch cũ
    // Do mỗi 2s lại có 1 notice mới nên notice cũ chỉ dc phép hiển thị trong 2s thôi, không là dính bug UI :(\
  }

}
