import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import { ToastrService } from 'app/shared/services/common';
import * as moment from 'moment';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
    @Output() search: EventEmitter<any> = new EventEmitter();
    @Input() categories: any[] = [];
    @Input() projects: any[] = [];
    @Input() statuses: any[] = [];
    @Input() isActiveStatus: any[] = [];
    @Input() statusesUnName: any[] = [];
    @Input() roles: any[] = [];
    @Input() blocks: any[] = [];
    @Input() floors: any[] = [];
    @Input() companyNames: any[] = [];
    @Input() apartTypes: any[] = [];
    @Input() shortCodes: any[] = [];
    @Input() regions: any[] = [];
    @Input() employees: any[] = [];
    @Input() saleUnits: any[] = [];
    @Input() isShowMenuSearch: boolean = true;
    @Input() isDatePicker: boolean = false;
    @Input() isDateCreate: boolean = false;
    @Input() isDateApprove: boolean = false;
    @Input() isDefaultCurrentDate: boolean = false;
    @Input() isDefaultCurrentMonth: boolean = false;
    @Input() isResetDate: boolean = false;
    @Input() channels: any[] = [];
    @Input() upgradePacks: any[] = [];
    @Input() statusTrial: any[] = [];
    @Input() workLists: any[] = [];
    @Input() paymentPolicy: any[] = [];
    @Input() discountPolicy: any[] = [];
    @Input() statusTitle: string = 'Trạng thái'
    @Input() listSyncRequest: any[] = []
    @Input() listTypeSyncRequest: any[] = []

    @Input() lstQuestionType: any[] = [];

    @Input() createdDateStartTitle: string = 'Từ ngày';
    @Input() createdDateEndTitle: string = 'Đến ngày';

    public selectStatus: any = [];
    public selectStatusesUnName: any = [];
    public selectCategory: any = [];
    public selectProject: any = [];
    public selectPaymentPolicy: any = [];
    public selectDiscountPolicy: any = [];
    public selectRole: any = [];
    public selectBlock: any = [];
    public selectFloor: any = [];
    public selectCompanyName: any = [];
    public selectApartType: any = [];
    public selectShortCode: any = [];
    public selectRegion: any = [];
    public selectEmployee: any = [];
    public selectSaleUnit: any = [];
    public selectChannel: any = [];
    public selectIsActiveStatus: any = [];
    public selectPack: any = [];
    public selectStatusTrial: any = [];
    public selectWork: any = [];
    public selectRequest: any = [];


    public selectStatusRadioApplied: string = null;
    public selectStatusApplied: any = [];
    public selectStatusesUnNameApplied: any = [];
    public selectCategoryApplied: any = [];
    public selectProjectApplied: any = [];
    public selectPaymentPolicyApplied: any = [];
    public selectDiscountPolicyApplied: any = [];
    public selectRoleApplied: any = [];
    public selectBlockApplied: any = [];
    public selectFloorApplied: any = [];
    public selectCompanyNameApplied: any = [];
    public selectApartTypeApplied: any = [];
    public selectShortCodeApplied: any = [];
    public selectRegionApplied: any = [];
    public selectEmployeeApplied: any = [];
    public selectSaleUnitApplied: any = [];
    public selectChannelApplied: any = [];
    public selectIsActiveApplied: any = [];
    public selectPackApplied: any = [];
    public selectStatusTrialApplied: any = [];
    public selectWorkApplied: any = [];
    public selectRequestApplied: any = [];
    public selectTypeRequestApplied: any = [];
    public selectTypeRequest: any[] = [];
    public selectQuestionType: any[] = [];
    public selectQuestionTypeApplied: any[] = [];

    public searchIsActiveStatusRadio: string = '';
    public searchStatus: string = '';
    public searchStatusesUnName: string = '';
    public searchCategory: string = '';
    public searchProject: string = '';
    public searchPaymentPolicy: string = '';
    public searchDiscountPolicy: string = '';
    public searchRole: string = '';
    public searchBlock: string = '';
    public searchFloor: string = '';
    public searchCompanyName: string = '';
    public searchApartType: string = '';
    public searchShortCode: string = '';
    public searchRegion: string = '';
    public searchEmployee: string = '';
    public searchSaleUnit: string = '';
    public searchKey = new FormControl();
    public searchChannel: string = '';
    public searchPack: string = '';
    public searchStatusTrial: string = '';
    public searchWork: string = '';
    public searchRequest: string = '';
    public searchTypeRequest: string = '';
    public searchQuestionType: string = '';

    filterKeyword = '';
    createdDateStart = '';
    createdDateEnd = '';
    approvedDateStart = '';
    approvedDateEnd = '';
    firstDayOfMonth = moment().startOf('month');


    constructor(
        public toastr: ToastrService,
    ) {
    }

    ngOnInit() {
        if (this.isDefaultCurrentDate) {
            this.createdDateStart = moment().format('YYYY/MM/DD HH:mm');
            this.createdDateEnd = moment().format('YYYY/MM/DD HH:mm');
            this.approvedDateStart = moment().format('YYYY/MM/DD HH:mm');
            this.approvedDateEnd = moment().format('YYYY/MM/DD HH:mm');
        }
        if (this.isDefaultCurrentMonth) {
            this.createdDateStart = this.firstDayOfMonth.format('YYYY/MM/DD HH:mm');
            this.createdDateEnd = moment().format('YYYY/MM/DD HH:mm');
            this.approvedDateStart = this.firstDayOfMonth.format('YYYY/MM/DD HH:mm');
            this.approvedDateEnd = moment().format('YYYY/MM/DD HH:mm');
        }
    }

    searchCode() {
        let keyword = '';
        if (this.searchKey.value) {
            keyword = this.searchKey.value.trim();
        }
        let params: any = {keywords: keyword};
        if (this.statuses.length > 0) {
            params.status = this.searchStatus;
            this.selectStatusApplied = [...this.selectStatus];
        }
        if(this.isActiveStatus.length>0){
            params.isActive = this.searchIsActiveStatusRadio;
            this.selectIsActiveApplied[0] = this.searchIsActiveStatusRadio;
        }
        if (this.workLists.length > 0) {
            params.position = this.searchWork;
            this.selectWorkApplied = [...this.selectWork]
        }
        if (this.upgradePacks.length > 0) {
            params.upgradePackage = this.searchPack
            this.selectPackApplied = [...this.selectPack]
        }
        if(this.statusTrial.length > 0) {
            params.statusTrial = this.searchStatusTrial
            this.selectStatusTrialApplied = [...this.selectStatusTrial]
        }
        if(this.channels.length > 0) {
            params.channel = this.searchChannel;
            this.selectChannelApplied = [...this.selectChannel]
        }
        if (this.statusesUnName.length > 0) {
            params.statusesUnName = this.searchStatusesUnName;
            this.selectStatusesUnNameApplied = [...this.selectStatusesUnName];
        }
        if (this.categories.length > 0) {
            params.categories = this.searchCategory;
            this.selectCategoryApplied = [...this.selectCategory];
        }
        if (this.projects.length > 0) {
            params.projectIds = this.searchProject;
            this.selectProjectApplied = [...this.selectProject];
        }
        if (this.listSyncRequest.length > 0) {
            params.syncRequestType = this.searchRequest
            this.selectRequestApplied = [...this.selectRequest]
        }
        if (this.listTypeSyncRequest.length > 0) {
            params.typeSyncRequest = this.searchTypeRequest
            this.selectTypeRequestApplied = [...this.selectTypeRequest]
        }
        if (this.paymentPolicy.length > 0) {
            params.paymentPolicyIds = this.searchPaymentPolicy;
            this.selectPaymentPolicyApplied = [...this.selectPaymentPolicy];
        }
        if (this.discountPolicy.length > 0) {
            params.discountPolicyIds = this.searchDiscountPolicy;
            this.selectDiscountPolicyApplied = [...this.selectDiscountPolicy];
        }
        if (this.roles.length > 0) {
            params.roleIds = this.searchRole;
            this.selectRoleApplied = [...this.selectRole];
        }
        if (this.blocks.length > 0) {
            params.block = this.searchBlock;
            this.selectBlockApplied = [...this.selectBlock];
        }
        if (this.floors.length > 0) {
            params.floor = this.searchFloor;
            this.selectFloorApplied = [...this.selectFloor];
        }
        if (this.companyNames.length > 0) {
            params.companyName = this.searchCompanyName;
            this.selectCompanyNameApplied = [...this.selectCompanyName];
        }
        if (this.apartTypes.length > 0) {
            params.apartType = this.searchApartType;
            this.selectApartTypeApplied = [...this.selectApartType];
        }
        if (this.shortCodes.length > 0) {
            params.shortCode = this.searchShortCode;
            this.selectShortCodeApplied = [...this.selectShortCode];
        }
        if (this.regions.length > 0) {
            params.regionIds = this.searchRegion;
            this.selectRegionApplied = [...this.selectRegion];
        }
        if (this.employees.length > 0) {
            params.employeeIds = this.searchEmployee;
            this.selectEmployeeApplied = [...this.selectEmployee];
        }
        if (this.saleUnits.length > 0) {
            params.saleIds = this.searchSaleUnit;
            this.selectSaleUnitApplied = [...this.selectSaleUnit];
        }

        if (this.lstQuestionType.length > 0) {
            params.questionTypes = this.searchQuestionType;
            this.selectQuestionTypeApplied = [...this.selectQuestionType];
        }
        if (this.createdDateStart) {
            params.createdFrom = moment(this.createdDateStart).startOf('date').toISOString();
        }
        if (this.createdDateEnd) {
            params.createdTo = moment(this.createdDateEnd).endOf('date').toISOString();
        }
        if (this.approvedDateStart) {
            params.approvedFrom = moment(this.approvedDateStart).startOf('date').toISOString();
        }
        if (this.approvedDateEnd) {
            params.approvedTo = moment(this.approvedDateEnd).endOf('date').toISOString();
        }
        // validate Search Date
        if (params.createdFrom && params.createdTo && params.createdFrom > params.createdTo) {
            this.toastr.error('Lỗi!', 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc.');
            params.error = true;
        }

        if (params.approvedFrom && params.approvedTo && params.approvedFrom > params.approvedTo) {
            this.toastr.error('Lỗi!', 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc.');
            params.error = true;
        }
        this.search.emit(params);
    }

    onClickCheckBoxStatus(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectStatus.includes(item.id)) {
                this.selectStatus.push(item.id);
            }
        } else {
            if (this.selectStatus.length > 0) {
                this.selectStatus = this.selectStatus.filter(i => i !== item.id);
            }
        }
        this.searchStatus = this.selectStatus.toString() || '';
    }

    onClickCheckBoxWorklist(checked: boolean, item) {
        item.checked = checked
        if(checked) {
            if(!this.selectWork.includes(item.id)) {
                this.selectWork.push(item.id);
            }
        } else {
            if (this.selectWork.length > 0) {
                this.selectWork = this.selectWork.filter(i => i !== item.id);
            }
        }
        this.selectWork = this.selectWork.map(item => {
            return item.replaceAll(",", ";")
        })
        this.searchWork = this.selectWork.toString() || ''
    }

    onClickCheckBoxUpgradePacks(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectPack.includes(item.id)) {
                this.selectPack.push(item.id);
            }
        } else {
            if (this.selectPack.length > 0) {
                this.selectPack = this.selectPack.filter(i => i !== item.id);
            }
        }
        this.searchPack = this.selectPack.toString() || '';
    }

    onClickRadioStatus(event){
        this.searchIsActiveStatusRadio = event.value;
        this.selectIsActiveApplied[0] = event.value;
        this.selectIsActiveStatus[0] = event.value;
    }

    onclickCheckBoxStatusTrial(checked: boolean, item) {
        item.checked = checked;
        if(checked) {
            if(!this.selectStatusTrial.includes(item.id)) {
                this.selectStatusTrial.push(item.id);
            }
        }else {
            if(this.selectStatusTrial.length > 0){
                this.selectStatusTrial = this.selectStatusTrial.filter(i => i !== item.id)
            }
        }
        this.searchStatusTrial = this.selectStatusTrial.toString() || '';
    }
    onClickBoxChannel(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectChannel.includes(item.id)) {
                this.selectChannel.push(item.id);
            }
        } else {
            if (this.selectChannel.length > 0) {
                this.selectChannel = this.selectChannel.filter(i => i !== item.id);
            }
        }
        this.searchChannel = this.selectChannel.toString() || '';
    }
    onClickCheckBoxStatusesUnName(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectStatusesUnName.includes(item.id)) {
                this.selectStatusesUnName.push(item.id);
            }
        } else {
            if (this.selectStatusesUnName.length > 0) {
                this.selectStatusesUnName = this.selectStatusesUnName.filter(i => i !== item.id);
            }
        }
        this.searchStatusesUnName = this.selectStatusesUnName.toString() || '';
    }

    onClickCheckBoxCategory(checked: boolean, item: any) {
        item.checked = checked;
        this.selectCategory = this.categories.filter(item => item.checked).map(item => item.id);
        this.searchCategory = this.selectCategory.toString() || '';
    }

    onClickCheckBoxProject(checked: boolean, item: any) {
        item.checked = checked;
        this.selectProject = this.projects.filter(item => item.checked).map(item => item.id);
        this.searchProject = this.selectProject.toString() || '';
    }

    onClickCheckBoxSyncRequestType(checked: boolean, item: any) {
        item.checked = checked;
        this.selectRequest = this.listSyncRequest.filter(item => item.checked).map(item => item.id);
        this.searchRequest = this.selectRequest.toString() || ''
    }

    onClickCheckBoxTypeSyncRequest(checked: boolean, item: any) {
        item.checked = checked;
        this.selectTypeRequest = this.listTypeSyncRequest.filter(item => item.checked).map(item => item.id);
        this.searchTypeRequest = this.selectTypeRequest.toString() || ''
    }

    onClickCheckBoxPaymentPolicy(checked: boolean, item: any) {
        item.checked = checked;
        this.selectPaymentPolicy = this.paymentPolicy.filter(item => item.checked).map(item => item.id);
        this.searchPaymentPolicy= this.selectPaymentPolicy.toString() || '';
    }

    onClickCheckBoxDiscountPolicy(checked: boolean, item: any) {
        item.checked = checked;
        this.selectDiscountPolicy = this.discountPolicy.filter(item => item.checked).map(item => item.id);
        this.searchDiscountPolicy = this.selectDiscountPolicy.toString() || '';
    }

    onClickCheckBoxRole(checked: boolean, item: any) {
        item.checked = checked;
        this.selectRole = this.roles.filter(item => item.checked).map(item => item.id);
        this.searchRole = this.selectRole.toString() || '';
    }
    onClickCheckBoxBlock(checked: boolean, item: any) {
        item.checked = checked;
        this.selectBlock = this.blocks.filter(item => item.checked).map(item => item.id);
        this.searchBlock = this.selectBlock.toString() || '';
    }
    onClickCheckBoxFloor(checked: boolean, item: any) {
        item.checked = checked;
        this.selectFloor = this.floors.filter(item => item.checked).map(item => item.id);
        this.searchFloor = this.selectFloor.toString() || '';
    }
    onClickCheckBoxCompanyName(checked: boolean, item: any) {
        item.checked = checked;
        this.selectCompanyName = this.companyNames.filter(item => item.checked).map(item => item.id);
        this.searchCompanyName = this.selectCompanyName.toString() || '';
    }
    onClickCheckBoxApartType(checked: boolean, item: any) {
        item.checked = checked;
        this.selectApartType = this.apartTypes.filter(item => item.checked).map(item => item.id);
        this.searchApartType = this.selectApartType.toString() || '';
    }
    onClickCheckBoxShortCode(checked: boolean, item: any) {
        item.checked = checked;
        this.selectShortCode = this.shortCodes.filter(item => item.checked).map(item => item.id);
        this.searchShortCode = this.selectShortCode.toString() || '';
    }
    onClickCheckBoxRegion(checked: boolean, item: any) {
        item.checked = checked;
        this.selectRegion = this.regions.filter(item => item.checked).map(item => item.id);
        this.searchRegion = this.selectRegion.toString() || '';
    }
    onClickCheckBoxEmployee(checked: boolean, item: any) {
        item.checked = checked;
        this.selectEmployee = this.employees.filter(item => item.checked).map(item => item.id);
        this.searchEmployee = this.selectEmployee.toString() || '';
    }
    onClickCheckBoxSaleUnit(checked: boolean, item: any) {
        item.checked = checked;
        this.selectSaleUnit = this.saleUnits.filter(item => item.checked).map(item => item.id);
        this.searchSaleUnit = this.selectSaleUnit.toString() || '';
    }


    onClickCheckBoxQuestionType(checked: boolean, item: any) {
        item.checked = checked;
        this.selectQuestionType = this.lstQuestionType.filter(item => item.checked).map(item => item.id);
        this.searchQuestionType = this.selectQuestionType.toString() || '';
    }

    resetFilter() {
        this.uncheckFilter();
        this.searchCode();
    }

    uncheckFilter() {
        if (this.statuses.length) {
            this.selectStatus = [];
            this.selectStatusApplied = [];
            this.statuses.map(item => {
                item.checked = false;
                return item;
            });
            this.searchStatus = '';
        }
        if(this.isActiveStatus.length) {
            this.searchIsActiveStatusRadio ='';
            this.selectIsActiveApplied = [];
            this.selectIsActiveStatus = []
        }
        if(this.upgradePacks.length) {
            this.selectPack = [];
            this.selectPackApplied = [];
            this.upgradePacks.map(item => {
                item.checked = false;
                return item
            })
            this.searchPack = '';
        }
        if(this.workLists.length) {
            this.selectWork = [];
            this.selectWorkApplied = [];
            this.workLists.map(item => {
                item.checked = false;
                return item
            })
            this.searchWork = ''
        }
        if(this.statusTrial.length) {
            this.selectStatusTrial = [];
            this.selectStatusTrialApplied = [];
            this.statusTrial.map(item => {
                item.checked = false;
                return item
            })
            this.searchStatusTrial = '';
        }
        if (this.channels.length) {
            this.selectChannel = [];
            this.selectChannelApplied = [];
            this.channels.map(item => {
                item.checked = false;
                return item;
            });
            this.searchChannel = '';
        }
        if (this.statusesUnName.length) {
            this.selectStatusesUnName = [];
            this.selectStatusesUnNameApplied = [];
            this.statusesUnName.map(item => {
                item.checked = false;
                return item;
            });
            this.searchStatusesUnName = '';
        }
        if (this.categories.length) {
            this.selectCategory = [];
            this.selectCategoryApplied = [];
            this.categories.map(item => {
                item.checked = false;
                return item;
            });
            this.searchCategory = '';
        }
        if (this.projects.length) {
            this.selectProject = [];
            this.selectProjectApplied = [];
            this.projects.map(item => {
                item.checked = false;
                return item;
            });
            this.searchProject = '';
        }
        if (this.listSyncRequest.length) {
            this.selectRequest = [];
            this.selectRequestApplied = [];
            this.listSyncRequest.map(item => {
                item.checked = false;
                return item
            })
            this.searchRequest = '';
        }
        if (this.listTypeSyncRequest.length) {
            this.selectTypeRequest = [];
            this.selectTypeRequestApplied = [];
            this.listTypeSyncRequest.map(item => {
                item.checked = false;
                return item
            })
            this.searchTypeRequest = '';
        }
        if (this.paymentPolicy.length) {
            this.selectPaymentPolicy = [];
            this.selectPaymentPolicyApplied = [];
            this.selectPaymentPolicy.map(item => {
                item.checked = false;
                return item;
            });
            this.searchPaymentPolicy = '';
        }
        if (this.discountPolicy.length) {
            this.selectDiscountPolicy = [];
            this.selectDiscountPolicyApplied = [];
            this.discountPolicy.map(item => {
                item.checked = false;
                return item;
            });
            this.searchDiscountPolicy = '';
        }
        if (this.roles.length) {
            this.selectRole = [];
            this.selectRoleApplied = [];
            this.roles.map(item => {
                item.checked = false;
                return item;
            });
            this.searchRole = '';
        }
        if (this.blocks.length) {
            this.selectBlock = [];
            this.selectBlockApplied = [];
            this.blocks.map(item => {
                item.checked = false;
                return item;
            });
            this.searchBlock = '';
        }
        if (this.floors.length) {
            this.selectFloor = [];
            this.selectFloorApplied = [];
            this.floors.map(item => {
                item.checked = false;
                return item;
            });
            this.searchFloor = '';
        }
        if (this.companyNames.length) {
            this.selectCompanyName = [];
            this.selectCompanyNameApplied = [];
            this.companyNames.map(item => {
                item.checked = false;
                return item;
            });
            this.searchCompanyName = '';
        }
        if (this.apartTypes.length) {
            this.selectApartType = [];
            this.selectApartTypeApplied = [];
            this.apartTypes.map(item => {
                item.checked = false;
                return item;
            });
            this.searchApartType = '';
        }
        if (this.shortCodes.length) {
            this.selectShortCode = [];
            this.selectShortCodeApplied = [];
            this.shortCodes.map(item => {
                item.checked = false;
                return item;
            });
            this.searchShortCode = '';
        }
        if (this.regions.length) {
            this.selectRegion = [];
            this.selectRegionApplied = [];
            this.regions.map(item => {
                item.checked = false;
                return item;
            });
            this.searchRegion = '';
        }
        if (this.employees.length) {
            this.selectEmployee = [];
            this.selectEmployeeApplied = [];
            this.employees.map(item => {
                item.checked = false;
                return item;
            });
            this.searchEmployee = '';
        }
        if (this.saleUnits.length) {
            this.selectSaleUnit = [];
            this.selectSaleUnitApplied = [];
            this.saleUnits.map(item => {
                item.checked = false;
                return item;
            });
            this.searchSaleUnit = '';
        }
        if (this.lstQuestionType.length) {
            this.selectQuestionType = [];
            this.selectQuestionTypeApplied = [];
            this.lstQuestionType.map(item => {
                item.checked = false;
                return item;
            });
            this.searchQuestionType = '';
        }
        if (this.isDefaultCurrentDate) {
            this.createdDateStart = moment().format('YYYY/MM/DD HH:mm');
            this.createdDateEnd = moment().format('YYYY/MM/DD HH:mm');
            this.approvedDateStart = moment().format('YYYY/MM/DD HH:mm');
            this.approvedDateEnd = moment().format('YYYY/MM/DD HH:mm');
        }
        if(this.isResetDate) {
            this.createdDateStart = null
            this.createdDateEnd = null
            this.approvedDateStart = null
            this.approvedDateEnd = null
        }

        this.searchKey = new FormControl();
    }

    menuClosed() {
        if (this.statuses.length) {
            this.selectStatus = [...this.selectStatusApplied];
            this.statuses.map(status => {
                status.checked = this.selectStatus.includes(status.id);
                return status;
            });
        }
        if(this.workLists.length) {
            this.selectWork = [...this.selectWorkApplied];
            this.workLists.map(work => {
                work.checked = this.selectWork.includes(work.id);
                return work;
            })
        }
        if(this.upgradePacks.length) {
            this.selectPack = [...this.selectPackApplied];
            this.upgradePacks.map(pack => {
                pack.checked = this.selectPack.includes(pack.id)
                return pack
            })
        }
        if(this.statusTrial.length) {
            this.selectStatusTrial = [...this.selectStatusTrialApplied];
            this.statusTrial.map(status => {
                status.checked = this.selectStatusTrial.includes(status.id)
                return status
            })
        }
        if (this.channels.length) {
            this.selectChannel = [...this.selectChannelApplied];
            this.channels.map(channel => {
                channel.checked = this.selectChannel.includes(channel.id);
                return channel;
            });
        }
        if(this.isActiveStatus.length) {
            this.selectIsActiveStatus = [...this.selectIsActiveApplied];
        }
        if (this.statusesUnName.length) {
            this.selectStatusesUnName = [...this.selectStatusesUnNameApplied];
            this.statusesUnName.map(app => {
                app.checked = this.selectStatusesUnName.includes(app.id);
                return app;
            });
        }
        if (this.categories.length) {
            this.selectCategory = [...this.selectCategoryApplied];
            this.categories.map(category => {
                category.checked = this.selectCategory.includes(category.id);
                return category;
            });
        }
        if (this.projects.length) {
            this.selectProject = [...this.selectProjectApplied];
            this.projects.map(project => {
                project.checked = this.selectProject.includes(project.id);
                return project;
            });
        }
        if (this.listSyncRequest.length) {
            this.selectRequest = [...this.selectRequestApplied];
            this.listSyncRequest.map(request => {
                request.checked = this.selectRequest.includes(request.id)
                return request;
            })
        }
        if (this.listTypeSyncRequest.length) {
            this.selectRequest = [...this.selectTypeRequestApplied];
            this.listTypeSyncRequest.map(request => {
                request.checked = this.selectRequest.includes(request.id)
                return request;
            })
        }
        if (this.paymentPolicy.length) {
            this.selectPaymentPolicy = [...this.selectPaymentPolicyApplied];
            this.paymentPolicy.map(policy => {
                policy.checked = this.selectPaymentPolicy.includes(policy.id);
                return policy;
            });
        }
        if (this.discountPolicy.length) {
            this.selectDiscountPolicy = [...this.selectDiscountPolicyApplied];
            this.discountPolicy.map(policy => {
                policy.checked = this.selectDiscountPolicy.includes(policy.id);
                return policy;
            });
        }
        if (this.roles.length) {
            this.selectRole = [...this.selectRoleApplied];
            this.roles.map(role => {
                role.checked = this.selectRole.includes(role.id);
                return role;
            });
        }
        if (this.blocks.length) {
            this.selectBlock = [...this.selectBlockApplied];
            this.blocks.map(block => {
                block.checked = this.selectBlock.includes(block.id);
                return block;
            });
        }
        if (this.floors.length) {
            this.selectFloor = [...this.selectFloorApplied];
            this.floors.map(floor => {
                floor.checked = this.selectFloor.includes(floor.id);
                return floor;
            });
        }
        if (this.companyNames.length) {
            this.selectCompanyName = [...this.selectCompanyNameApplied];
            this.companyNames.map(c => {
                c.checked = this.selectCompanyName.includes(c.id);
                return c;
            });
        }
        if (this.apartTypes.length) {
            this.selectApartType = [...this.selectApartTypeApplied];
            this.apartTypes.map(apartType => {
                apartType.checked = this.selectApartType.includes(apartType.id);
                return apartType;
            });
        }
        if (this.shortCodes.length) {
            this.selectShortCode = [...this.selectShortCodeApplied];
            this.shortCodes.map(shortCode => {
                shortCode.checked = this.selectShortCode.includes(shortCode.id);
                return shortCode;
            });
        }
        if (this.regions.length) {
            this.selectRegion = [...this.selectRegionApplied];
            this.regions.map(region => {
                region.checked = this.selectRegion.includes(region.id);
                return region;
            });
        }
        if (this.employees.length) {
            this.selectEmployee = [...this.selectEmployeeApplied];
            this.employees.map(employee => {
                employee.checked = this.selectEmployee.includes(employee.id);
                return employee;
            });
        }
        if (this.saleUnits.length) {
            this.selectSaleUnit = [...this.selectSaleUnitApplied];
            this.saleUnits.map(saleUnit => {
                saleUnit.checked = this.selectSaleUnit.includes(saleUnit.id);
                return saleUnit;
            });
        }

        if (this.lstQuestionType.length) {
            this.selectQuestionType = [...this.selectQuestionTypeApplied];
            this.lstQuestionType.map(questionType => {
                questionType.checked = this.selectQuestionType.includes(questionType.id);
                return questionType;
            });
        }
    }

    get isShowNumber() {
        return this.selectCategoryApplied.length > 0 || this.selectProjectApplied.length > 0 || this.selectStatusApplied.length > 0
        || this.selectRoleApplied.length > 0
        || this.selectBlockApplied.length > 0
        || this.selectFloorApplied.length > 0
        || this.selectApartTypeApplied.length > 0
        || this.selectShortCodeApplied.length > 0
        || this.selectRegionApplied.length > 0
        || this.selectChannelApplied.length > 0
        || this.selectIsActiveApplied.length > 0
        || this.selectPaymentPolicyApplied.length > 0
        || this.selectDiscountPolicyApplied.length > 0
        || this.selectWorkApplied.length > 0
        || this.selectRequestApplied.length > 0
        || (this.createdDateStart || this.createdDateEnd );
    }

    get getNumber() {
        return this.selectCategoryApplied.length + this.selectProjectApplied.length + this.selectStatusApplied.length
        + this.selectRoleApplied.length
        + this.selectBlockApplied.length
        + this.selectFloorApplied.length
        + this.selectApartTypeApplied.length
        + this.selectShortCodeApplied.length
        + this.selectRegionApplied.length
        + this.selectChannelApplied.length
        + this.selectIsActiveApplied.length
        + this.selectPaymentPolicyApplied.length
        + this.selectDiscountPolicyApplied.length
        + this.selectWorkApplied.length
        + this.selectRequestApplied.length
        + ((this.createdDateStart || this.createdDateEnd ) ? 1 : 0);
    }
}
