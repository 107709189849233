import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { ParseToNumber } from 'app/theme/utils/custom-parse-number';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true }]
})
export class CustomMaxDirective implements Validator {
  @Input()
  customMax: number;

  validate(c: FormControl): { [key: string]: any } {
    let value = ParseToNumber.Parse(c.value);
    return (value > this.customMax) ? { "customMax": true } : null;
  }
} 