import { } from './base.component';
import { DataService } from '../services';
import { ConstantUrl } from '../constant/url';

export abstract class BaseService {
    private dataService: DataService;
    public queryUrl: string;
    public domainUrl: string;
    ConstantUrl = ConstantUrl;
    constructor(
        service: DataService
    ) {
        this.dataService = service;
    }
    get(params?) {
        const url = this.queryUrl;
        return this.dataService.get(url, params ? params : {});
    }
    getById(id) {
        const url = this.queryUrl + '/' + id;
        return this.dataService.get(url);
    }
    getList(url) {
        return this.dataService.get(url);
    }
    insert(category) {
        return this.dataService.post(this.domainUrl, category, null, null, true);
    }
    update(categoryId, category) {
        return this.dataService.put(this.domainUrl, category);
    }
    delete(categoryId) {
        return this.dataService.delete(this.domainUrl + '/' + categoryId);
    }
    active(id) {
        return this.dataService.put(this.domainUrl + '/active/' + id, { id });
    }
    deactive(id) {
        return this.dataService.put(this.domainUrl + '/deactive/' + id, { id });
    }
    createBqlAccount(category) {
        return this.dataService.post(this.domainUrl + '/bql', category);
    }
}
