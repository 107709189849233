import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-message-image',
  templateUrl: './dialog-message-image.component.html',
  styleUrls: ['./dialog-message-image.component.scss']
})
export class DialogMessageImageComponent {
  public imageUrl: string;
  public message: string;
  public title: string;

  constructor(
    public dialogRef: MatDialogRef<DialogMessageImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.imageUrl = data.imageUrl;
      this.message = data.message;
      this.title = data.title;
    }

}
