import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface RegisterTrialData {
  textCancel: string;
}
@Component({
  selector: 'app-rigister-trial-success-dialog',
  templateUrl: './rigister-trial-success-dialog.component.html',
  styleUrls: ['./rigister-trial-success-dialog.component.scss']
})
export class RigisterTrialSuccessDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RigisterTrialSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegisterTrialData
  ) { }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
