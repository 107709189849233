
export class PropertyPrimaryReport {
  id: string;
  posName: string;
  countStatus: number;
  countProduct: number;
  countTicketDCH: number;
  countAmountDCH: number;
  countTicketRefund: number;
  countTicketRemand: number;
  countTicketDCH2DCO: number;
  countTicketDCO: number;
  countAmountDCO: number;
  countAmount: number;

  constructor(
    param?: any
  ) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
	  this.posName = params.posName || 'Chưa được giao';
	  this.countStatus = params.countStatus || 0;
	  this.countProduct = params.countProduct || 0;
	  this.countTicketDCH = params.countTicketDCH || 0;
	  this.countAmountDCH = params.countAmountDCH || 0;
	  this.countTicketRefund = params.countTicketRefund || 0;
	  this.countTicketRemand = params.countTicketRemand || 0;
	  this.countTicketDCH2DCO = params.countTicketDCH2DCO || 0;
	  this.countTicketDCO = params.countTicketDCO || 0;
	  this.countAmountDCO = params.countAmountDCO || 0;
	  this.countAmount = params.countAmount || 0;
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    // set header
    this.posName = '';
    this.countStatus = 0;
    this.countProduct = 0;
    this.countTicketDCH = 0;
    this.countAmountDCH = 0;
    this.countTicketRefund = 0;
    this.countTicketRemand = 0;
    this.countTicketDCH2DCO = 0;
    this.countTicketDCO = 0;
    this.countAmountDCO = 0;
    this.countAmount = 0;
  }

  getHeaders(index?) {
    return [
        { header: 'ĐVBH được giao', key: 'posName', width: 30 },
        { header: 'Tổng SL giao', key: 'countProduct', width: 10 },
        { header: 'Tổng SL YCDCH TC', key: 'countTicketDCH', width: 10 },
        // { header: 'Tổng số tiền YCDCH TC', key: 'countAmountDCH', width: 20 },
        { header: 'Tổng SL YCDCH hoàn tiền', key: 'countTicketRefund', width: 10 },
        // { header: 'Tổng SL YCDCH còn', key: 'countTicketRemand', width: 20 },
        { header: 'Tổng SL YCDCO từ chỗ', key: 'countTicketDCH2DCO', width: 10 },
        { header: 'Tổng SL YCDCO mới', key: 'countTicketDCO', width: 20 },
        // { header: 'Tổng số tiền YCDCO', key: 'countAmount', width: 20 },
      ];
  }
  getHideRows() {
    return null;
  }
}
