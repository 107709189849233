import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from 'app/shared/services';
import { DialogService } from 'app/shared/services/dialog.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-button-message',
  templateUrl: './button-message.component.html',
  styleUrls: ['./button-message.component.scss']
})
export class ButtonMessageComponent implements OnInit, OnChanges {
  @Input() toName: string;
  @Input() toNumber: string;
  @Input() type: string;
  @Input() employeeTakeCare: any;
  @Input() userId: any;
  @Input() employeeContractEmail: any;

  disabled: boolean;

  constructor(
    public storageService: StorageService,
    private dialogService: DialogService,
    public router: Router,
    ) { }

  ngOnInit() {
    if (this.employeeTakeCare === this.storageService.retrieve('userId')) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  ngOnChanges() {
    if (this.employeeTakeCare === this.storageService.retrieve('userId')) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  goMsg() {
    if (this.toNumber == this.storageService.retrieve('email')) {
      this.dialogService.openErrorDialog({
        error: 'Đã có lỗi xảy ra',
        message: 'Bạn không thể nhắn tin cho chính mình'
      });
      return;
    } else {
      window.open('/message/' + this.toNumber, "_blank");
    }
  }
}
