import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from 'app/shared/services';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { parsePhone, parseEmail } from 'app/shared/utility/utility';
import { DialogService } from 'app/shared/services/dialog.service';
import { Constant } from 'app/shared/constant/constant';
@Component({
  selector: 'app-button-call',
  templateUrl: './button-call.component.html',
  styleUrls: ['./button-call.component.scss']
})
export class ButtonCallComponent implements OnInit, OnChanges {
  @Input() toName: string;
  @Input() toNumber: string;
  @Input() type: string;
  @Input() employeeTakeCare: any;
  @Input() disabled: boolean;
  @Input() ticketId: string;
  @Output() startCalling: EventEmitter<any> = new EventEmitter();

  isCalling = false;

  constructor(
    public storageService: StorageService,
    public voiceCallService: VoiceCallService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.voiceCallService.isCalling$.subscribe(res => {
      if (res) {
        this.isCalling = true;
      } else {
        this.isCalling = false;
      }
    })
  }

  ngOnChanges() {
  }

  async callApiPhone() {
    if (this.isCalling) {
      return;
    }
    let toNumber = this.toNumber;

    // check valid toNumber
    // todo: refactor business here
    if (this.toNumber && Constant.REGEX_NUMBER.test(this.toNumber) && !Constant.REGEX_VN_PHONE.test(this.toNumber)) {
      this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: 'Số điện thoại không tồn tại, Vui lòng kiểm tra lại.' });
      return;
    }
    if (this.toNumber.indexOf('@') > -1) {
      toNumber = parseEmail(this.toNumber);
    }

    this.voiceCallService.routeCall(this.storageService.retrieve('voice_call_id'), this.toNumber).subscribe(res => {
      if (res) {
        this.voiceCallService.callOut(res.toNumber, this.toName, this.ticketId, res.fromNumber);
        this.startCalling.emit();
      } else {
        this.dialogService.openErrorDialog({ error: 'Đã có lỗi xảy ra', message: 'Mạng không ổn định, Vui lòng kiểm tra lại.' });
      }
    });
  }
}
