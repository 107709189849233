import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { GuideComponent } from './guide/guide.component';

@Component({
  selector: 'app-trial-guide',
  templateUrl: './trial-guide.component.html',
  styleUrls: ['./trial-guide.component.scss']
})
export class TrialGuideComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TrialGuideComponent>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onCloseDialog() {

  }

  public closeDialog(): void {
    this.dialogRef.close(true);
  }

  goStart() {
    this.dialogRef.close(true);
  }
}
