import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'environments/environment';

@Injectable()
export class SocketIoService extends Socket {

  constructor() {
    super({ url: environment.urlBackEnd, options: { path: '/msx-property/socket.io', transports: ['websocket'] } });
  }

}