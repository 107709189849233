import { Component, OnInit, ViewEncapsulation, ViewChild, Injector, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { NotificationService } from '../../../shared/services/notification.service';
import { Router } from '@angular/router';
import { NotificationMsg } from '../../../shared/models/notification-msg.model';
import { EventChangeService } from '../../../shared/services/event.change.service';
import { DataService, StorageService, SecurityService } from '../../../shared/services';
import { NotifyComponent } from '../../../shared/components/notify.component';
import {
  Message, StompService, ToastrService
} from '../../../shared/services/common';
import { MessagesService } from './messages.service';
import { StringeeService } from 'app/pages/message/stringee.service';
import { Conversation } from 'app/shared/models/conversation.model';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UserV2Service } from 'app/shared/services/common/user-v2.service';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { WsNotifyService } from 'app/shared/components/ws-notify.service';
import { environment } from 'environments/environment';
import { NotificationEntityNameEnum, NOTIFICATION_MESSAGE } from 'app/shared/enum/notification-entity-name.enum';
import { AuthorizeService } from 'app/shared/authenticate';
import { SocketIoService } from 'app/shared/services/common/socket-io.service';
import { takeUntil } from 'rxjs/operators';
import moment = require('moment');

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  providers: [MessagesService],
  encapsulation: ViewEncapsulation.None
})
export class MessagesComponent extends NotifyComponent implements OnInit, OnDestroy {
  [x: string]: any;
  public notificationCount: number = 0;
  authenticated: boolean = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab: number = 1;
  public msgs: any = [];
  public msgsTicket: any = [];
  public msgsContract: any = [];
  public msgsDeal: any = [];
  public msgsCall: any = [];
  public msgsOther: any = [];
  public isShow: boolean = false;
  public isShowDialpad: boolean = false;
  public allowCall: boolean = false;
  public isActive: boolean = false;
  tabSelected: number = 1;
  public files: Array<Object>;
  public meetings: Array<Object>;
  conversations: Conversation[] = [];
  private updateConversationsCallback = new Subject<boolean>();
  updateConversationsCallback$ = this.updateConversationsCallback.asObservable();
  public totalUnreadMessage: any = 0;

  authSubscription: Subscription;

  dialpadNumber: string = '+84 ';

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isMsgsLoading = false;
  pageSize = 10;
  page = 1;
  entityName: any;
  eventName: any = Object.values(NOTIFICATION_MESSAGE);
  throttle = 1000;
  scrollDistance = 10;
  private listNoticeMessEventName = Object.keys(NOTIFICATION_MESSAGE);
  private listNoticeMessEntityName = Object.keys(NotificationEntityNameEnum);
  canViewPropertyPrimaryTransaction: boolean = false;
  public unsubscribe$: Subject<any> = new Subject();
  eventSubscribe: any;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.isShow = false;
      this.isShowDialpad = false;
    }
  }

  constructor(
    private injector: Injector,
    public router: Router,
    private notificationService: NotificationService,
    public eventChangeService: EventChangeService,
    private service: DataService,
    public storageService: StorageService,
    private messagesService: MessagesService,
    private stringeeService: StringeeService,
    private eRef: ElementRef,
    private securityService: SecurityService,
    private userV2Service: UserV2Service,
    private voicallService: VoiceCallService,
    private wsNotifyService: WsNotifyService,
    private authorizeService: AuthorizeService,
    private socketIoService: SocketIoService,
  ) {
    super(injector);
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();
    this.authenticated = securityService.IsAuthorized;
    this.allowCall = this.storageService.retrieve('allowCall') || false;
  }
  // ngOnDestroy() {
  //   this.notificationService.stopPolling();
  // }

  onScrollDown() {
    this.isMsgsLoading = true;
    this.page += 1;
    this.fetchData();
  }

  reloadNotice() {
    this.notificationService.notifications$.next([]);
    this.page = 1;
    this.fetchData();
  }

  async fetchData() {
    // Tạo request
    let params;
    params = this.currentFilter || {};
    params.page = this.page;
    params.pageSize = this.pageSize;
    // params.sort = "-createdAt";
    params.entityName = this.entityName ? this.entityName.toString() : '';
    this.isMsgsLoading = true;
    await this.notificationService.getPagingNotice(params).subscribe(response => {
      // this.notificationService.notifications$.next([...this.notificationService.notifications$.value].concat(response.rows));
      this.notificationService.notificationsUnReadCount$.next(response.unRead);
      this.notificationCount = response.unRead;
      this.isMsgsLoading = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.wsNotifyService.unsubscribeAll();
    this.socketIoService.disconnect();
    this.eventSubscribe && this.eventSubscribe.unsubscribe();
    this.notificationService.notifications$.next([]);
    this.notificationService.notificationsUnReadCount$.next(0);
  }

  ngOnInit() {
    this.fetchData();
    this.checkUserTrial();
    // Counting
    this.notificationService.notificationsUnReadCount$.subscribe(
      (count) => {
        this.notificationCount = count.valueOf();
        if (count === 0) {
          this.notificationService.notifications$.value.forEach((item) => {
            item.isRead = true;
          });
        }
      });

    // Notice list
    this.notificationService.notifications$.subscribe(
      (notifications) => {

        this.msgs = this.tabSelected === 1 ? notifications : [];

        this.msgsTicket = this.tabSelected === 2 ? notifications : [];

        this.msgsContract = this.tabSelected === 3 ? notifications : [];

        this.msgsDeal = this.tabSelected === 4 ? notifications : [];

        this.msgsCall = this.tabSelected === 5 ? notifications : [];

        this.msgsOther = this.tabSelected === 6 ? notifications : [];
      }
    );

    // Subscribe to login and logout observable
    this.authSubscription = this.securityService.IsAuthenticated.subscribe(res => {
      this.authenticated = res;
    });
    let userId = this.storageService.retrieve('userId');
    userId && (this.infoMessagingPattern = this.messagingPattern + 'msx-adsg.notification.user-' + userId);
    // this.wsNotifyService.subscribe([...this.infoMessagingPattern]);

    // Start polling request notification service
    //this.notificationService.startPolling();
    // this.notificationService.observable$.subscribe(() => {
    //   if (this.authenticated) {
    //     this.infoMessagingPattern !== this.messagingPattern && this.subscribeMessage();
    //     return this.notificationService.getNotifications(false);
    //   }
    // });

    // Check Add notice realTime
    this.eventSubscribe = this.eventChangeService.emitChangeSource.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      try {
        if (data.message && data.message.msg
          && ((data.message.msg.eventName && this.listNoticeMessEventName.includes(data.message.msg.eventName))
            || (data.message.msg.entityName && this.listNoticeMessEntityName.includes(data.message.msg.entityName)))) {
          // Cộng thêm notice
          this.notificationService.notificationsUnReadCount$.next(Number(this.notificationService.notificationsUnReadCount$.value) + 1);

          if (this.eventName.includes(data.message.msg.eventName) || this.entityName.includes(data.message.msg.entityName)) {
            // Add notice to List
            // this.notificationService.notifications$.next([data.message].concat([...this.notificationService.notifications$.value]));
          }
        }
      } catch (error) {
        console.log('MessagesComponent', error);
      }
      // switch (data.broadcast) {
      //   case 'contractDeleted':
      //     // show toast
      //     this.toastr.success('Thành công', 'Xóa hợp đồng');
      //   break;
      //   case this.userId:
      //     this.setDefault();
      //   break;
      // }
    });

    this.stringeeService.isMessage = false;
    this.userV2Service.user$.subscribe(res => {
      if (res && res.id) {
        this.stringeeService.connectStringee();
      }
    });
    this.updateConversationsCallback = this.stringeeService.updateConversationsCallback;
    this.updateConversationsCallback.subscribe((res) => {
      this.conversations = StringeeService.conversations;
      this.totalUnreadMessage = _.sumBy(this.conversations, function (conv) {
        return conv.unreadCount;
      });
      this.totalUnreadMessage = (this.totalUnreadMessage > 99) ? '99+' : this.totalUnreadMessage;
    });
  }

  markAsReadAll(even: any) {
    this.notificationService.readAllNotification().pipe()
      .subscribe((res) => {
        this.notificationService.notificationsUnReadCount$.next(0);
      })
  }

  tabChanged(event) {
    let tab = event.tab.textLabel;
    this.page = 1;
    this.notificationService.notifications$.next([]);
    switch (tab) {
      case 'Tất cả': {
        this.tabSelected = 1;
        this.entityName = '';
        this.eventName = Object.values(NOTIFICATION_MESSAGE);
        this.msgs = [];
        this.fetchData();
        break;
      }
      case 'Yêu cầu': {
        this.tabSelected = 2;
        this.entityName = NotificationEntityNameEnum.LEAD;
        this.eventName = [NOTIFICATION_MESSAGE.ticketCreated_YCDCH,
        NOTIFICATION_MESSAGE.ticketApproved_YCDCH_by_DVBH,
        NOTIFICATION_MESSAGE.ticketRejected_YCDCH_by_DVKH_or_DVBH,
        NOTIFICATION_MESSAGE.ticketApproved_YCDCH_by_DVKH];
        this.msgsTicket = [];
        this.fetchData();
        break;
      }
      case 'Hợp đồng': {
        this.tabSelected = 3;
        this.entityName = NotificationEntityNameEnum.CONTRACT;
        this.eventName = '';
        this.msgsContract = [];
        this.fetchData();
        break;
      }
      case 'Giao dịch': {
        this.tabSelected = 4;
        this.entityName = [NotificationEntityNameEnum.DEALT, NotificationEntityNameEnum.DEALING];
        this.eventName = [NOTIFICATION_MESSAGE.propertyUnitExpired,
        NOTIFICATION_MESSAGE.transactionSuccessed,
        NOTIFICATION_MESSAGE.transactionWaitingForDocument];
        this.msgsDeal = [];
        this.fetchData();
        break;
      }
      case 'Cuộc gọi': {
        this.tabSelected = 5;
        this.entityName = NotificationEntityNameEnum.CALL;
        this.eventName = '';
        this.msgsCall = [];
        this.fetchData();
        break;
      }
      default:
        this.tabSelected = 6;
        this.entityName = NotificationEntityNameEnum.OTHER;
        this.eventName = [NOTIFICATION_MESSAGE.ticketCreated_DNTT,
        NOTIFICATION_MESSAGE.ticketApproved_DNTT,
        NOTIFICATION_MESSAGE.ticketProcessed,
        NOTIFICATION_MESSAGE.propertyUnitWithdrawed,
        NOTIFICATION_MESSAGE.propertyUnitTransfered];
        this.msgsOther = [];
        this.fetchData();
    }

  }

  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  markAsRead(message) {
    this.isShow = false;
    if (!message.isRead) {
      return this.notificationService.markAsRead(message.id)
        .then(() => {
          this.directUrl(message);
        });
    } else {
      this.directUrl(message);
    }
  }
  directUrl(message) {
    if (message.url) {
      message.url = message.url.replace('{{id}}', message.msg.entityId);
      this.reloadCurrentRoute(message);
    } else {
      this.toastrService.info('Do not url', 'Notifcation');
    }
  }
  async reloadCurrentRoute(message) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
      let myUrl = '';

      // check permission màn hình giao dịch
      await this.authorizeService.hasAuthority(['PROPERTY', 'PRIMARY', 'TRANSACTION', 'GET'])
        .then(isHasRole => {
          this.canViewPropertyPrimaryTransaction = isHasRole;
        });
      if (!this.canViewPropertyPrimaryTransaction
        && (NOTIFICATION_MESSAGE.transactionSuccessed === message.msg.eventName
          || NOTIFICATION_MESSAGE.transactionWaitingForDocument === message.msg.eventName)) {

        // Vào danh sách YCDCO
        myUrl = '/project/event-sale/' + message.msg.projectId;
        const ticketId = message.msg.entityId ? message.msg.entityId : '';
        this.router.navigate([myUrl], { queryParams: { tab: 3, ticketId } });

      } else if (NOTIFICATION_MESSAGE.ticketCreated_YCDCH === message.msg.eventName
        || NOTIFICATION_MESSAGE.ticketApproved_YCDCH_by_DVBH === message.msg.eventName
        || NOTIFICATION_MESSAGE.ticketRejected_YCDCH_by_DVKH_or_DVBH === message.msg.eventName
        || NOTIFICATION_MESSAGE.ticketApproved_YCDCH_by_DVKH === message.msg.eventName
        || (!this.canViewPropertyPrimaryTransaction && NOTIFICATION_MESSAGE.ticketProcessed === message.msg.eventName)) {

        // Danh sách YCDCH
        myUrl = '/project/event-sale/' + message.msg.projectId;
        const ticketId = message.msg.entityId ? message.msg.entityId : '';
        this.router.navigate([myUrl], { queryParams: { tab: 2, ticketId } });

      } else if (NOTIFICATION_MESSAGE.ticketProcessed === message.msg.eventName
        || NOTIFICATION_MESSAGE.propertyUnitExpired === message.msg.eventName
        || NOTIFICATION_MESSAGE.propertyUnitWithdrawed === message.msg.eventName
        || NOTIFICATION_MESSAGE.propertyUnitTransfered === message.msg.eventName
        || NOTIFICATION_MESSAGE.propertyUnitInfo === message.msg.eventName
        || NOTIFICATION_MESSAGE.transactionSuccessed === message.msg.eventName
        || NOTIFICATION_MESSAGE.transactionWaitingForDocument === message.msg.eventName) {

        // Danh sách giao dịch
        myUrl = '/project/event-sale/' + message.msg.projectId;
        let salesProgramId = message.msg.saleProgramId ? message.msg.saleProgramId : '';
        const unitCode = message.msg.data && message.msg.data.unitCode ? message.msg.data.unitCode : '';
        this.router.navigate([myUrl], { queryParams: { tab: 4, salesProgramId, unitCode } });
      } else {
        const code = message.msg.entityId ? message.msg.entityId : '';
        if (message.url.indexOf('/money-order') > -1) {
          this.router.navigate([message.url], { queryParams: { code } });
          return
        }
        this.router.navigate([message.url]);
      }
    });
  }
  getList() {
    let url = '';
    return this.service.get(url)
      .subscribe((res) => {
        this.notificationService.getNotifications(false);
      });
  }

  goNotification() {
    this.isShow = false;
    this.router.navigate(['/notification', this.tabSelected]);
  }

  public subscribeMessage(isReset?) {
    if (!isReset) {
      if (this.websocketService.getChannel(this.infoMessagingPattern)) {
        return;
      } else {
        this.websocketService.setChannel(this.infoMessagingPattern, null);
      }
    } else {
      this.websocketService.setChannel(this.infoMessagingPattern, null);
      this.websocketService.getChannel(this.infoMessagingPattern);
    }
    // Stream of messages
    // info
    if (this.infoMessagingPattern !== this.messagingPattern) {
      this.messages = this.stompService.subscribe(this.infoMessagingPattern);
      this.subscription = this.messages.subscribe(this.infoSubscribe.bind(this));
    }
  }
  /** Consume a message from the stompService */
  public infoSubscribe(message: Message) {
    if (message && message.body) {
      let object = JSON.parse(message.body);
      object = new NotificationMsg(object);
      if (!object.msg.eventName) {
        return;
      }
      this.notificationService.receiveNotification([object]);
      !object.msg.eventName && (object.msg.eventName = '');
      let title = object.msg.eventName.toUpperCase();
      // this.toastrService.success('Thành Công!', title);
      if (object.msg.eventName.toLowerCase().includes('delete')) {
        return this.executeDelete();
      }
    }
  }

  // showAll() {
  //   this.isActive = true;
  // }

  // showContract() {
  //   this.isActive = true;
  //   this.notificationService.notifications$.subscribe(
  //     (notifications) => {
  //       let count = 0;
  //       notifications.forEach((item) => {
  //         !item.isRead && count++;
  //       })
  //       this.notificationCount = count;
  //       this.msgs = notifications.filter(item => item.msg.entityName == 'booking-calendar');
  //       this.msgs = this.msgs.slice(0,5);
  //     }
  //   );
  // }

  pressDialpad(key: any) {
    this.dialpadNumber += key;
  }

  clearDialpad() {
    this.dialpadNumber = '+84 ';
  }

  callDialpad() {
    this.isShowDialpad = false;
    const toNumber = this.dialpadNumber.replace('+', '').replace(' ', '');
    this.voicallService.callOutWithRouteCall(toNumber, this.dialpadNumber);
  }

  msgCancle(msg: any): string {
    if (msg && msg.msg && msg.msg.content && msg.msg.content.includes('Từ chối')) return 'red';
    return;
  }

  checkUserTrial() {
    this.userV2Service.getCurrentEmployee().subscribe((res) => {
      if (res && res.isTrialRegister) {
        this.isTrialUser = true
      }
    });
  }
}
