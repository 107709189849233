import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { Subject, Observable, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import moment = require('moment');

@Directive({
    selector: '[counter]'
})
export class CounterDirective implements OnChanges, OnDestroy {

    private _counterSource$ = new Subject<any>();
    private _subscription = Subscription.EMPTY;

    @Input() counter: Date;
    @Input() interval: number;
    @Output() value = new EventEmitter<string>();

    constructor() {
        this._subscription = this._counterSource$.pipe(
            switchMap(({ interval, date }) =>
                timer(0, interval).pipe(
                    take(date),
                    tap(() => {
                        const diff = moment().diff(moment(date).subtract(1, 'm'));
                        this.value.emit(moment.utc(diff).format('HH:mm'));
                    })
                )
            )
        ).subscribe();
    }

    ngOnChanges() {
        if (this.counter) {
            this._counterSource$.next({ date: this.counter, interval: this.interval });
        } else {
            this.value.emit('');
            this._subscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

}