import { Injectable } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { BaseService } from 'app/shared/components/base.service';
import { ConstantUrl } from 'app/shared/constant/url';
import { DataService } from 'app/shared/services';
import { CPagination } from 'app/api-models/c-pagination';
import { MailerLogModel, mailerTemplateModel } from './mailer.model';
import { mergeMap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailerService extends BaseService {
  ConstantUrl = ConstantUrl;
  constructor(
    public service: DataService,
    public httpService: HttpService
  ) {
    super(service);
    this.queryUrl = ConstantUrl.url_mailer;
  }

  getMailerTemplate(params) {
    const url = this.queryUrl;
    return this.httpService.get(url, params).pipe(
      map((response: any) => {
        let pagination = new CPagination<mailerTemplateModel>().decode(response);
        pagination.items = response.rows.map((item: any, index) => {
          const email = new mailerTemplateModel(item)
          email.stt = index + 1 + params.pageSize * (params.page - 1);
          return email.createDataForList();
        })
        return pagination
      })
    )
  }

  getMailerLog(params) {
    const url = ConstantUrl.url_mailer + 'emailLog';
    return this.service.get(url, params).pipe(
      map((response: any) => {
        let pagination = new CPagination<MailerLogModel>().decode(response);
        pagination.items = response.rows.map((item: any) => {
          return new MailerLogModel(item);
        })
        return pagination;
      })
    )
  }

  getNotiSystem() {
    const url = this.queryUrl + `notisystem`;
    return this.httpService.get(url).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getTemplateById(id) {
    const url = this.queryUrl + `get/${id}`;
    return this.httpService.get(url).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateTemplate(id, data) {
    const url = this.queryUrl + `update/${id}`;
    return this.httpService.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createTemplate(data) {
    const url = this.queryUrl + 'create';
    return this.httpService.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteTemplate(id) {
    const url = this.queryUrl + `delete/${id}`;
    return this.httpService.delete(url).pipe(
      map((response: any) => {
        return response;
      })
    )
  }


  exportHistory(params) {
    const url = ConstantUrl.url_mailer + 'emailLog/export';
    return this.service.dowloadFile(url, params);
  }
}
