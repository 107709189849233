import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { markAsTouched } from "app/shared/utility/form-utility";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "app/pages/login/login.service";
import { getMaxListeners } from "process";
import { TypeSendOtp } from "app/shared/enum/trial-register.enum";
import { AuthenSuccessComponent } from "../authen-success/authen-success.component";

export interface authenRegister {
  authenType: string;
  authenText: string;
  id: string;
  phoneNumber: string;
  email: string;
  isShowAuthenList: boolean
  accessToken: string;
}
@Component({
  selector: 'app-phone-authen',
  templateUrl: 'phone-authen.component.html',
  styleUrls: ['phone-authen.component.scss']
})


export class PhoneAuthenRegisterComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<PhoneAuthenRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: authenRegister,
    private readonly dialog: MatDialog,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
    public _service: LoginService
    ) { }

    isShowAuthenForm: boolean = true
    isShowAuthenListMethod: boolean = false
    typeSend = TypeSendOtp
    mainForm: FormGroup = null
    phoneNumber: string
    email: string;
    accessToken: string;

  ngOnInit() {
    this.initForm()
    if(this.data.phoneNumber) this.phoneNumber = this.data.phoneNumber.replace(this.data.phoneNumber.substring(3,8), '*****');
    if(this.data.email) {
      const[name, domain] = this.data.email.split('@')
      const {length: len} = name
      const maskedName = name[0] + "*****" + name[len - 1]
      this.email = maskedName + "@" + domain 
    };
    if (this.data.accessToken) {
      this.accessToken = this.data.accessToken;
    }
    
  }

  initForm() {
    this.mainForm = this.formBuilder.group({
        smsOtp: [null, Validators.required]
    })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSave() {
    markAsTouched(this.mainForm)
    if (!this.mainForm.valid) {
      this.toastr.error('Vui lòng kiểm tra lại thông tin');
      return;
    }

    const body = this.mainForm.getRawValue()
    body.typeSend = this.data.authenType
    // body.email = this.data.email
    // body.phoneNumber = this.data.phoneNumber
    this._service.sendOtpSms(body, this.accessToken).subscribe(res => {
      if(res) {
        this.dialog.open(AuthenSuccessComponent, {
          width: '500px',
          data: {
            authenType: this.data.authenType,
            authenText: this.data.authenText,
            email: this.data.email,
            phoneNumber: this.data.phoneNumber,
            accessToken: this.data.accessToken
          }
        })
        this.dialogRef.close()
      }
    })
  }

  openAuthenListMethod() {
    this.isShowAuthenForm = false
    this.isShowAuthenListMethod = true
  }

  openAuthenMethod(method, text) {
    this.resendOtp(method)
    this.isShowAuthenForm = true
    this.isShowAuthenListMethod = false
    this.data.authenType = method
    this.data.authenText = text
    this.data.isShowAuthenList = false
  }

  onBack() {
    this.isShowAuthenForm = true
    this.isShowAuthenListMethod = false
    this.data.authenType = this.typeSend.PHONE
    this.data.authenText = 'số điện thoại'
    this.data.isShowAuthenList = true
  }

  resendOtp(method) {
    let dto = {
      phoneNumber: this.data.phoneNumber,
      email: this.data.email,
      type: method
    }
    this._service.sendOtp(dto, this.accessToken).subscribe((res: any) => {
      if(res) {
        const data = res.json();
        if (data.accessToken) {
          this.accessToken = data.accessToken;
        }
        let textMethod = ''
        switch(method) {
          case this.typeSend.PHONE:
            textMethod = 'số điện thoại';
            break;
          case this.typeSend.ZALO: 
            textMethod = 'zalo';
            break;
          case this.typeSend.EMAIL: 
            textMethod = 'email';
            break;
        }

        this.toastr.success(`Vui lòng kiểm tra mã otp đã được gửi về ${textMethod} của bạn`)

      }
    })
  }
}