import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-popup-confirm-workflow',
    templateUrl: './popup-confirm-workflow.component.html',
    styleUrls: ['./popup-confirm-workflow.component.scss']
})
export class PopupConfirmWorkflowComponent implements OnInit {
    public uploadedFiles: any[] = [];
    public reason = '';
    public isReason = true;
    name = '';
    step = '';
    title = '';
    executeStepCode = '';
    rollbackStep = '';
    constructor(
        public dialogRef: MatDialogRef<PopupConfirmWorkflowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (this.data) {
            this.step = this.data.step;
            this.title = '';
            this.name = this.data.modifiedBy && this.data.modifiedBy.name;
            this.executeStepCode = this.data.nextStep;
            this.rollbackStep = this.data.rollbackStep;
        }
    }
    submit(isApprove: boolean) {
        if (!this.reason) return this.isReason = false;
        const p = {
            extraData: {
                comment: this.reason,
                files: this.uploadedFiles,
            },
            executeStepCode: this.rollbackStep,
            id: this.data.id,
            dataType: this.data.dataType,
            data: this.data.data,
            workflow: this.data.workflow,
            history: this.data.history,
        }
        if (isApprove) {
            p.executeStepCode = this.executeStepCode;
        }
        this.dialogRef.close(p);
    }
}
