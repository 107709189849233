import { Component, OnInit, OnDestroy, ViewChild, Injector, TemplateRef } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { MailerService } from '../mailer.service';
import { BaseComponent } from '../../../shared/components/base.component';
import { PagingComponent } from '../../../shared/components/paging';
import _ = require('lodash');
import { BehaviorSubject } from 'rxjs';
import { MailerLogModel } from '../mailer.model'; 
import { CPagination } from 'app/api-models';
import { ColDef, ColGroupDef, GridApi, GridOptions } from 'ag-grid-community';
import { TemplateRendererComponent } from 'app/pages/project/event-sale/list/template-renderer/template-renderer.component';
import { EditorPopup } from '../../../shared/components/editor-popup/editor-popup';
import { MatDialog } from '@angular/material';
import { ShowJsonComponent } from 'app/shared/components/show-json/show-json.component';
import { ToastrService } from 'ngx-toastr';
import { PopupJsonViewerComponent } from 'app/shared/components/popup-json-viewer/popup-json-viewer.component';

@Component({
  selector: 'app-mailer-log',
  templateUrl: './mailer-log.component.html',
  styleUrls: ['./mailer-log.component.scss']
})
export class MailerLogComponent extends BaseComponent implements OnInit, OnDestroy {
  public isTableLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild('paging') paging: PagingComponent;
  object: any;
  errors: any;
  histories: any[] = [];
  public createdByFilter: any = [];
  currentPage: number = 1;
  currentPageSize: number = 10;
  
  isResetDate = true; // Reset date filter
  pagination: CPagination<MailerLogModel> = new CPagination<MailerLogModel>();
  columnDefs: ColDef[] | ColGroupDef;
  gridApi: GridApi;
  isExportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      tooltipComponent: 'customTooltip',
      sortable: false,
      enableCellChangeFlash: false
    },
    autoSizePadding: 10,
    cellFlashDelay: 500,
    rowBuffer: 30,
    rowHeight: 60,
    animateRows: false,
    suppressRowTransform: true,
    enableCellTextSelection: true,
    singleClickEdit: true,
    enableBrowserTooltips: true,
    tooltipShowDelay: 0,
    overlayNoRowsTemplate: '<span>Không có dữ liệu</span>'
  };
  
  currentFilter: any = {};
  @ViewChild("response") response: TemplateRef<any>;
  @ViewChild("attachments") attachments: TemplateRef<any>;
  @ViewChild("dataJson") dataJson: TemplateRef<any>;
  @ViewChild("content") content: TemplateRef<any>;
  @ViewChild("createdAt") createdAt: TemplateRef<any>;

  constructor(
    injector: Injector,
    public appSettings: AppSettings,
    public service: MailerService,
    private dialog: MatDialog,
    public toastr: ToastrService,
  ) {
    super(injector.get(MailerService), MailerLogModel, injector);
    this.settings = this.appSettings.settings;
  };
  statuses = [
    {
      id: true,
      name: 'Thành công'
    },
    {
      id: false,
      name: 'Thất bại'
    }
  ];

  ngOnChanges(changes: any) {
    this.pagination.page = 1;
    this.fetchData(changes.currentFilter.currentValue);
  }
  
  ngOnInit() {
      this.fetchData(this.currentFilter)
      this.getFilter();
  }

  fetchData(params?: any) {
    params = params || {};
    params.page = params.page || this.pagination.page;
    params.pageSize = params.pageSize || this.pagination.pageSize;
    delete params.typeSyncRequest;
    this.isTableLoading$.next(false);
    this.service.getMailerLog(params).subscribe(payload => {
      this.pagination = payload
    });
  }

  ngAfterViewInit() {
    this.columnDefs = [
      {
        headerName: 'STT',
        valueGetter: (params) => {
            return (params.node.rowIndex + 1)
        },
        width: 30,
      },
      {
        headerName: 'mailFrom',
        field: 'from',
        width: 60,
      },
      {
        headerName: 'mailTo',
        field: 'to',
        width: 60,
      },
      {
        headerName: 'CC',
        field: 'cc',
        width: 60,
      },
      {
        headerName: 'Tiêu đề',
        field: 'subject',
        width: 180,
      },
      {
        headerName: 'Nội dung',
        width: 80,
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.content,
        },
      },
      {
        headerName: "Trạng thái",
        field: 'response',
        width: 80,
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.response,
        },
      },
      {
        headerName: 'Ngày gửi',
        width: 80,
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.createdAt,
        },
      },
      {
        headerName: 'Tạo bởi',
        field: 'notiSystem',
        width: 60,
      },
      {
        headerName: 'Data Json',
        width: 100,
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.dataJson,
        },
      },
    ];
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.sizeColumnsToFit();
  }
  
  onPagingChange(event: any) {
    this.pagination = event;
    const params = Object.assign(this.currentFilter, { page: this.pagination.page, pageSize: this.pagination.pageSize });
    this.fetchData(params);
  }
  
  onFilterApply(event) {
    this.currentFilter = event;
    let filterName = this.createdByFilter.filter((e) => {
      return e.checked == true;
    })
    if (filterName.length > 0) {
      this.currentFilter.notiSystem = filterName.map(e => e.name).join(',');
    }
    this.fetchData(this.currentFilter);
  }

  getFilter() {
    this.service.getNotiSystem().subscribe(response => {
      const filterName = response.map((e) => {
        return {  
          name: e,
          checked: false
        }
      })
      this.createdByFilter = filterName;
    })
  }
  
  openContent(row) {
    const contentPopup = this.dialog.open(EditorPopup, {
      width: '700px',
      data: {
        title: 'Nội dung Email',
        textOk: 'OK',
        body: row.content,
        editable: false,
        showToolbar: false,
      }
    });
  }

  openDataJson(row) {
    const upgradePopup = this.dialog.open(PopupJsonViewerComponent, {
      width: '700px',
      data: {
        title: 'Data JSON',
        body: row
      }
    })
  }

  exportExcel() {
    this.isExportLoading$.next(true);
    const params: any = Object.assign({}, this.currentFilter);
    params.query = params.keywords || '';
    delete params.page;
    delete params.pageSize;
    this.service.exportHistory(params).subscribe(_ => {
        this.isExportLoading$.next(false);
    }, _ => {
        this.isExportLoading$.next(false);
    });
}

}
