import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ConfigurationService } from "./configuration.service";
import { StorageService } from "./storage.service";
// import { Session } from 'aws-sdk/clients/mobileanalytics';
import { AuthorizeService } from "./common/authorize.service";
import { EventChangeService } from "./event.change.service";
import { UserV2Service } from "./common/user-v2.service";
import { CUser } from "app/api-models";
import { VoiceCallService } from "./voicecall.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class SecurityService {
    private actionUrl: string;
    private headers: Headers;
    private storage: StorageService;
    private authenticationSource = new ReplaySubject<boolean>(1);
    public IsAuthenticated = this.authenticationSource.asObservable();
    private authorityUrl = "";
    private timeExpired: any;
    public IsAuthorized: boolean;
    public UserData: any;

    constructor(
        private _http: Http,
        private _router: Router,
        private route: ActivatedRoute,
        private configurationService: ConfigurationService,
        private authorizeService: AuthorizeService,
        private _storageService: StorageService,
        private userV2Service: UserV2Service,
        public eventChangeService: EventChangeService,
        private voiceCallService: VoiceCallService,
        private cookieService: CookieService
    ) {
        this.headers = new Headers();
        this.headers.append("Content-Type", "application/json");
        this.headers.append("Accept", "application/json");
        this.storage = _storageService;

        this.configurationService.settingsLoaded$.subscribe((x) => {
            this.authorityUrl =
                this.configurationService.serverSettings.systemApis.identityUrl;
            this.storage.store("identityUrl", this.authorityUrl);
        });

        if (this.storage.retrieve("IsAuthorized") !== "") {
            this.IsAuthorized = this.storage.retrieve("IsAuthorized");
            this.authenticationSource.next(this.IsAuthorized);
            this.UserData = this.storage.retrieve("userData");
        } else {
            this.IsAuthorized = false;
            this.storage.store("IsAuthorized", false);
        }
        if (!this.authorizeService.checkAuthorities()) {
            let string = this.storage.retrieve("permissions");
            if (string) {
                setTimeout(() => {
                    this.eventChangeService.emitChange({
                        boardcast: "login",
                    });
                }, 3000);
                let authorities = JSON.parse(string);
                if (authorities) {
                    this.authorizeService.setAuthorities(authorities);
                }
            }
            string = this.storage.retrieve("isFullPermission");
            this.authorizeService.setIsFullPermission(string);
        }
    }
    // Lấy token từ cookie

    public setCookieToken(
        name: string,
        value: string,
        days: number,
        domain: string = ""
    ): void {
        const expires = new Date(Date.now() + days * 864e5).toUTCString(); // Tính thời gian hết hạn
        document.cookie = `${name}=${encodeURIComponent(
            value
        )}; expires=${expires}; path=/; domain=${domain}`;
    }

    // public setCookieToken(token) {
    //     this.cookieService.set('authorizationData', token, 1, 'esalekit-uat-crm.datxanh.com.vn'); // Lưu cookie 1 ngày
    //     console.log('Cookie đã được lưu!');
    //   }

    // Lấy token từ cookie
    public getCookieToken() {
        const token = this.cookieService.get("authorizationData");
        console.log("Token:", token);
        return token;
    }

    public GetToken(): any {
        return this.storage.retrieve("authorizationData");
    }
    public setToken(token) {
        this.storage.store("authorizationData", token);
    }
    setAuthor(perms, isFullPermission?) {
        if (isFullPermission) {
            this.storage.store("isFullPermission", "true");
            this.authorizeService.setIsFullPermission(true);
        } else {
            this.storage.store("isFullPermission", "");
            this.authorizeService.setIsFullPermission(false);
        }
        let permissions = {};
        if (!perms) {
            this.storage.store(
                "permissions",
                JSON.stringify({ dashboard: {} })
            );
            this.authorizeService.setAuthorities(null);
            return;
        }
        perms.forEach((permission) => {
            permission.featureName = permission.featureName.replace(/ /g, "");
            let features = permission.featureName.split(".");
            let feature: any = permissions;
            features.forEach((name, index) => {
                if (!feature[name]) {
                    feature = feature[name] = {};
                } else {
                    feature = feature[name];
                }
                if (index === features.length - 1) {
                    let isAuthor = false;
                    for (let property in permission.action) {
                        let actions = property.split(":");
                        let action = actions[1].toUpperCase();
                        if (permission.action[property]) {
                            isAuthor = true;
                        }
                    }
                    if (isAuthor) {
                        feature.isAuthor = true;
                    } else feature.isAuthor = false;
                }
            });
        });

        // other add more, not dynamic,
        if (permissions["customer"] || permissions["employee"]) {
            permissions["profile"] = {};
        }

        this.storage.store("permissions", JSON.stringify(permissions));
        this.authorizeService.setAuthorities(permissions);
        this.eventChangeService.emitChange({
            boardcast: "reload-menu",
        });
    }
    public resetAuthorizationData() {
        this.storage.store("authorizationData", "");
        this.storage.store("authorizationDataIdToken", "");

        this.IsAuthorized = false;
        this.storage.store("IsAuthorized", false);
        this.authenticationSource.next(false);
    }
    getMail() {
        return this.storage.retrieve("emailBDSG");
    }
    public setAuthorizationData(token: any, email: any, expires_in?: any) {
        if (this.storage.retrieve("authorizationData") !== "") {
            this.storage.store("authorizationData", "");
        }

        this.storage.store("authorizationData", token);
        this.storage.store("emailBDSG", email);

        if (expires_in) {
            const hours = parseInt(
                expires_in.substring(0, expires_in.length - 1)
            );
            this.timeExpired =
                new Date().getTime() + hours * 60 * 60 * 1000 * 1000;
            this.storage.store("timeExpired", this.timeExpired);
        }
        this.IsAuthorized = true;
        this.storage.store("IsAuthorized", true);
        this.authenticationSource.next(true);
    }
    public isExpired() {
        let authorizationData = this.storage.retrieve("authorizationData");
        if (authorizationData) {
            this.timeExpired = this.storage.retrieve("timeExpired");
            if (!this.timeExpired || this.timeExpired <= new Date().getTime()) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    }
    public authorize() {
        this.resetAuthorizationData();

        const authorizationUrl = this.authorityUrl + "/connect/authorize";
        const client_id = "js";
        const redirect_uri = location.origin + "/";
        const response_type = "id_token token";
        const scope =
            "openid profile orders basket marketing locations webshoppingagg";
        const nonce = "N";
        const state = "";

        this.storage.store("authStateControl", state);
        this.storage.store("authNonce", nonce);

        const url =
            authorizationUrl +
            "?" +
            "response_type=" +
            encodeURI(response_type) +
            "&" +
            "client_id=" +
            encodeURI(client_id) +
            "&" +
            "redirect_uri=" +
            encodeURI(redirect_uri) +
            "&" +
            "scope=" +
            encodeURI(scope) +
            "&" +
            "nonce=" +
            encodeURI(nonce) +
            "&" +
            "state=" +
            encodeURI(state);

        window.location.href = url;
    }

    public authorizedCallback() {
        this.resetAuthorizationData();

        const hash = window.location.hash.substr(1);

        const result: any = hash
            .split("&")
            .reduce(function (res: any, item: string) {
                const parts = item.split("=");
                res[parts[0]] = parts[1];
                return res;
            }, {});
        let token = "";
        let id_token = "";
        let authResponseIsValid = false;

        if (!result.error) {
            if (result.state !== this.storage.retrieve("authStateControl")) {
            } else {
                token = result.access_token;
                id_token = result.id_token;

                const dataIdToken: any = this.getDataFromToken(id_token);
                // validate nonce
                if (dataIdToken.nonce !== this.storage.retrieve("authNonce")) {
                } else {
                    this.storage.store("authNonce", "");
                    this.storage.store("authStateControl", "");

                    authResponseIsValid = true;
                }
            }
        }

        if (authResponseIsValid) {
            this.setAuthorizationData(token, id_token);
        }
    }

    public logoff() {
        const authorizationUrl = this.authorityUrl + "/connect/endsession";
        const id_token_hint = this.storage.retrieve("authorizationDataIdToken");
        const post_logout_redirect_uri = location.origin + "/";

        const url =
            authorizationUrl +
            "?" +
            "id_token_hint=" +
            encodeURI(id_token_hint) +
            "&" +
            "post_logout_redirect_uri=" +
            encodeURI(post_logout_redirect_uri);

        this.resetAuthorizationData();

        // emit observable
        this.authenticationSource.next(false);
        //window.location.href = url;
        this._router.navigate(["/login"]);
    }

    public HandleError(error: any) {
        if (error.status === 403) {
            this._router.navigate(["/Forbidden"]);
        } else if (error.status === 401) {
            this._router.navigate(["/Unauthorized"]);
        }
    }

    private urlBase64Decode(str: string) {
        let output = str.replace("-", "+").replace("_", "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                throw new Error("Illegal base64url string!");
        }

        return window.atob(output);
    }

    private getDataFromToken(token: any) {
        let data = {};
        if (typeof token !== "undefined") {
            const encoded = token.split(".")[1];
            data = JSON.parse(this.urlBase64Decode(encoded));
        }

        return data;
    }

    private setHeaders() {
        this.headers = new Headers();
        this.headers.append("Content-Type", "application/json");
        this.headers.append("Accept", "application/json");

        const token = this.GetToken();

        if (token !== "") {
            this.headers.append("Authorization", "Bearer " + token);
        }
    }
    logout() {
        this.userV2Service.updateUserBehaviorSubject(new CUser());

        this.storage.remove("isFullPermission");
        this.storage.remove("permissions");
        this.storage.remove("timeExpired");
        this.storage.remove("userId");
        this.storage.remove("IsAuthorized");
        this.storage.remove("voice_call_token");
        this.storage.remove("voice_call_authen_token");
        this.IsAuthorized = false;
        this.authenticationSource.next(false);
        this.eventChangeService.emitChange({
            broadcast: "log-out",
        });
        this.voiceCallService.disconnect();
    }
}
