import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Constant } from "app/shared/constant/constant";

export interface ConfirmData {
    title: string;
    message: string;
    textCancel: string;
    textOk: string;
    isChecked: boolean;
    execute: boolean;
}

@Component({
    selector: "confirm-erp-indicator-popup",
    templateUrl: "./confirm-erp-indicator-popup.html",
    styleUrls: ["./confirm-erp-indicator-popup.scss"],
})
export class ConfirmErpIndicatorPopup implements OnInit {
    public readonly listPeriods = Constant.LIST_PERIOD_COMM;
    mainForm: FormGroup = null;
    constructor(
        public dialogRef: MatDialogRef<ConfirmErpIndicatorPopup>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {}

    ngOnInit() {
        this.data.isChecked = true;
        this.initForm();
    }
    getFormControl(name: string) {
        return this.mainForm.get(name);
    }
    initForm() {
        this.mainForm = this.formBuilder.group({
            selectedPeriod: ["", Validators.required],
        });
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    execute() {
        this.dialogRef.close({
            isChecked: true,
            period: this.getFormControl("selectedPeriod").value,
        });
    }
}
