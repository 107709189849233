import { IDecode } from '../i-decode';

export class CTicketSummary implements IDecode<CTicketSummary> {
  public removed: number;
  public compeleted: number;
  public assigned: number;
  public pending: number;
  public processing: number;
  public total: number;
  public timePullLatest: string;
  public isPenalty: boolean;
  public removedLatest: number;
  public completedLatest: number;
  public assignedLatest: number;
  public pendingLatest: number;
  public processingLatest: number;

  constructor(params: Partial<CTicketSummary> = {}) {
    this.copy(params);
  }

  static copyList(params: Partial<CTicketSummary>[] = []): CTicketSummary[] {
    return params.map(el => new CTicketSummary().copy(el));
  }

  copy(params: Partial<CTicketSummary> = {}): CTicketSummary {
    this.removed = this.isNullOrUndefined(params.removed) ? null : params.removed;
    this.compeleted = this.isNullOrUndefined(params.compeleted) ? null : params.compeleted;
    this.assigned = this.isNullOrUndefined(params.assigned) ? null : params.assigned;
    this.pending = this.isNullOrUndefined(params.pending) ? null : params.pending;
    this.processing = this.isNullOrUndefined(params.processing) ? null : params.processing;
    this.total = this.isNullOrUndefined(params.total) ? null : params.total;
    this.timePullLatest = params.timePullLatest || null;
    this.isPenalty = params.isPenalty || false;
    this.removedLatest = this.isNullOrUndefined(params.removedLatest) ? null : params.removedLatest;
    this.completedLatest = this.isNullOrUndefined(params.completedLatest) ? null : params.completedLatest;
    this.assignedLatest = this.isNullOrUndefined(params.assignedLatest) ? null : params.assignedLatest;
    this.pendingLatest = this.isNullOrUndefined(params.pendingLatest) ? null : params.pendingLatest;
    this.processingLatest = this.isNullOrUndefined(params.processingLatest) ? null : params.processingLatest;
    return this;
  }

  decode(paramsApi: any): CTicketSummary {
    return new CTicketSummary(paramsApi);
  }

  decodeList(paramsApi: any[]): CTicketSummary[] {
    return paramsApi.map(el => new CTicketSummary().decode(el));
  }

  private isNullOrUndefined(param: any): boolean {
    return param === null || param === undefined;
  }

}
