export enum EEventStatusProjet {
    INITIAL = 'initial',
    STREAMING = 'streaming',
    FINISHED = 'finished'
}

export enum EEventStatusStageProject {
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished'
}
export enum EEventStatusPriorityProject {
    IN_PROGRESS = 'in_progress',
    MIDDLE = 'middle',
    FINISHED = 'finished'
}
