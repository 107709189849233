import { IDecode } from './i-decode';

export class CPOS implements IDecode<CPOS> {

    public id: string;
    public parentId: string;
    public name: string;
    public code: string;
    public type: string;
    public staffIds: string[];
    public erpConfig: any;

    constructor(params: Partial<CPOS> = {}) {
        this.copy(params);
    }

    isTeam(userId: string): boolean {
        return this.staffIds.some((el: string) => el && el === userId);
    }

    copy(params: Partial<CPOS> = {}): CPOS {
        params = params || {};

        this.id = params.id || null;
        this.parentId = params.parentId || null;
        this.name = params.name || null;
        this.code = params.code || null;
        this.type = params.type || null;
        this.staffIds =  params.staffIds || [];
        this.erpConfig =  params.erpConfig || null;
        return this;
    }

    decode(paramsApi: any): CPOS {
        return new CPOS(paramsApi);
    }

    decodeList(paramsApi: any[]): CPOS[] {
        return paramsApi.map(el => new CPOS().decode(el));
    }
}
