import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Contract } from 'app/pages/contract/contract.model';
import { ContractService } from 'app/pages/contract/contract.service';
import { Constant } from 'app/shared/constant/constant';
import { MoneyToNumber } from 'app/shared/parse/money-to-number';
import { FloorRoundFloatNumber } from 'app/shared/parse/floor-round-float-number';
import { Decimal } from 'decimal.js';
import { ParseToNumber } from 'app/theme/utils/custom-parse-number';

@Component({
  selector: 'app-form-input-contract',
  templateUrl: './form-input-contract.component.html',
  styleUrls: ['./form-input-contract.component.scss'],
  providers: [ContractService]
})
export class FormInputContractComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() data: Contract = new Contract();
  @Output() dataChange: EventEmitter<Contract> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();

  public Constant = Constant;
  public mainForm: FormGroup = null;
  public typeSell: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    public service: ContractService) { }

  ngOnInit() {
    this.initForm(this.data);
  }

  ngOnChanges() {
    this.initForm(this.data);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initForm(contract: Contract) {
    if (!contract) {
      return;
    }
    this.typeSell = contract.type === 'SELL';

    let expiredMonths = null;
    if (contract.draftAmendment && contract.draftAmendment.expiredMonths) {
      expiredMonths = contract.draftAmendment.expiredMonths;
    } else {
      expiredMonths = contract.expiredMonths;
    }
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        expiredMonths: expiredMonths || '',
        brokerFeePercent: contract.brokerFeePercent || '',
        brokerFee: contract.brokerFee,  // brokerFee sẽ tự động được tính dựa trên brokerFeePercent
        sourceCommissionPercent: contract.sourceCommissionPercent || '',
        salesCommissionPercent: contract.salesCommissionPercent || ''
      });
      // Update input disable/enable state
      if (this.disabled) {
        this.mainForm.disable();
      } else {
        this.mainForm.enable();
        this.mainForm.get('salesCommissionPercent').disable();
      }
      // Update validate
      // this.validateByType();
    } else {
      // Init form
      this.mainForm = this.formBuilder.group({
        expiredMonths: [{ value: expiredMonths || '', disabled: this.disabled }, Validators.required],
        brokerFeePercent: [{ value: contract.brokerFeePercent || '', disabled: this.disabled }, Validators.required],
        brokerFee: [{ value: contract.brokerFee, disabled: this.disabled }, Validators.required], // brokerFee sẽ tự động được tính dựa trên brokerFeePercent
        sourceCommissionPercent: [{ value: contract.sourceCommissionPercent || '', disabled: this.disabled }, Validators.required],
        salesCommissionPercent: [{ value: contract.salesCommissionPercent || '', disabled: true }, Validators.required]
      });
      // Update validate
      // this.validateByType();
      this.mainForm.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          // Patch data
          this.data = this.patchFormValue(this.data, this.mainForm.getRawValue());
          // Emit new data
          this.dataChange.emit(this.data);
          // Emit form valid state
          this.validChange.emit(this.mainForm.valid);
        });
      // changeBrokerFee
      if (!this.typeSell) {
        this.mainForm.get('brokerFee').valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => this.changeBrokerFee(FloorRoundFloatNumber.floor(MoneyToNumber.parse(res), 2)));
      } else {
      // changeBrokerFeePercent
        this.mainForm.get('brokerFeePercent').valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => this.changeBrokerFeePercent(FloorRoundFloatNumber.floor(MoneyToNumber.parse(res), 2)));
      }
      // changeComission
      this.mainForm.get('sourceCommissionPercent').valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => this.changeComission(FloorRoundFloatNumber.floor(MoneyToNumber.parse(res), 2)));
    }
  }

  onChangeBrokerFee() {
    this.changeBrokerFee(FloorRoundFloatNumber.floor(MoneyToNumber.parse(this.mainForm.get('brokerFee').value), 2))
  }
  onChangeBrokerFeePercent() {
    this.changeBrokerFeePercent(FloorRoundFloatNumber.floor(MoneyToNumber.parse(this.mainForm.get('brokerFeePercent').value), 2));
  }

  validateByType(){
    if (this.typeSell) {
      this.mainForm.get('brokerFeePercent').setValidators(this.validateCustomMax(Constant.SELL_BROKER_FEE_PRECENT_MAX))
    } else {
      this.mainForm.get('brokerFeePercent').setValidators(this.validateCustomMin(Constant.LEASE_BROKER_FEE_PRECENT_MIN));
      if(this.mainForm.get('brokerFeePercent').value < Constant.SELL_BROKER_FEE_PRECENT_MAX) {
        this.mainForm.get('brokerFee').setValidators(this.validateCustomMin(Constant.LEASE_BROKER_FEE_MIN));
      } else{
        this.mainForm.get('brokerFee').setValidators(this.validateCustomMin(0));
      }    
    }
  }
  validateCustomMax(customMax: number): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      const valueAsNumber: number = ParseToNumber.Parse(control.value);
      return (valueAsNumber > customMax) ? { "customMax": true } : null;
    };
  }

  validateCustomMin(customMin: number): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      const valueAsNumber: number = ParseToNumber.Parse(control.value);
      return (valueAsNumber < customMin) ? { "customMin": true } : null;
    };
  }

  // PUBLIC
  public validateForm(): boolean {
    this.markAsTouched(this.mainForm);
    return this.mainForm.disabled || this.mainForm.valid;
  }

  changeBrokerFee(brokerFee: number) {
    if (!brokerFee) {
      return;
    }
    let brokerFeePercent = null;
    if (brokerFee >= 0) {
      brokerFeePercent = Number(new Decimal(brokerFee).dividedBy(this.data.property.price).times(100).toFixed(2));
    }
    if (brokerFeePercent || brokerFeePercent >= 0) {
      this.mainForm.get('brokerFeePercent').patchValue(brokerFeePercent, { emitEvent: false, onlySelf: true });
      this.data.divideFee = Number(new Decimal(brokerFee).times(this.data.commissionPercent).dividedBy(100).toFixed(0));
      this.changeComission(this.mainForm.get('sourceCommissionPercent').value);
    }
    this.validateByType();
  }

  changeBrokerFeePercent(brokerFeePercent: number) {
    if (!brokerFeePercent) {
      return;
    }
    let brokerFee = null;
    if (brokerFeePercent >= 0) {
      brokerFee = Number(new Decimal(brokerFeePercent).dividedBy(100).times(this.data.property.price).toFixed(0));
    }
    if (brokerFee || brokerFee >= 0) {
      this.mainForm.get('brokerFee').patchValue(brokerFee, { emitEvent: false, onlySelf: true });
      this.data.divideFee = Number(new Decimal(brokerFee).times(this.data.commissionPercent).dividedBy(100).toFixed(0));
      this.changeComission(this.mainForm.get('sourceCommissionPercent').value);
    }
    this.validateByType();
  }

  changeComission(sourceCommissionPercent: number) {
    if (!sourceCommissionPercent) {
      return;
    }
    let salesCommissionPercent = null;
    if (sourceCommissionPercent >= 0) {
      salesCommissionPercent = 100 - sourceCommissionPercent;
      this.mainForm.get('salesCommissionPercent').patchValue(salesCommissionPercent);
    }
    if (this.data.divideFee || this.data.divideFee >= 0) {
      this.data.sourceCommission = Number(new Decimal(this.data.divideFee).times(sourceCommissionPercent).dividedBy(100).toFixed(0));
      this.data.salesCommission = Number(new Decimal(this.data.divideFee).times(salesCommissionPercent).dividedBy(100).toFixed(0));
    }
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  patchFormValue(model: Contract, formData: any): Contract {
    if (model.draftAmendment) {
      model.draftAmendment.expiredMonths = formData.expiredMonths;
    }
    model.expiredMonths = formData.expiredMonths;
    model.brokerFeePercent = FloorRoundFloatNumber.floor(MoneyToNumber.parse(formData.brokerFeePercent), 2);
    model.brokerFee = FloorRoundFloatNumber.floor(MoneyToNumber.parse(formData.brokerFee), 0);
    model.sourceCommissionPercent = FloorRoundFloatNumber.floor(MoneyToNumber.parse(formData.sourceCommissionPercent), 2);
    model.salesCommissionPercent = FloorRoundFloatNumber.floor(MoneyToNumber.parse(formData.salesCommissionPercent), 2);
    return model;
  }

}
