import { Injectable } from '@angular/core';
import { CPagination } from 'app/api-models';
import { BaseService } from 'app/shared/components/base.service';
import { ConstantUrl } from 'app/shared/constant/url';
import { DataService } from 'app/shared/services';
import { HttpService } from 'app/shared/services/http.service';
import { map } from 'rxjs/operators';
import { UserTrialModel } from './user-trial.model';

@Injectable()
export class UserTrialService  extends BaseService {

  constructor(
    private _service: DataService,
    private _httpService: HttpService
  ) {
    super(_service);
  }

  getCustomerTrial(params) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/getAllLstEmployeeTrial`
    return this._service.get(url, params || {}).pipe(
      map((response: any) => {
          const pagination = new CPagination<UserTrialModel>().decode(response);
          pagination.items = pagination.items.map((item: any, index) => {
              const user = new UserTrialModel(item);
              user.stt = index + 1 + params.pageSize * (params.page - 1);
              return user.createDataForList();
          });
          return pagination;
      })
    );
  }

  // getAllLstTrialRegister
  getCustomerRegister(params) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/getAllLstTrialRegister`
    return this._service.get(url, params || {}).pipe(
      map((response: any) => {
          const pagination = new CPagination<UserTrialModel>().decode(response);
          pagination.items = pagination.items.map((item: any, index) => {
              const user = new UserTrialModel(item);
              user.stt = index + 1 + params.pageSize * (params.page - 1);
              return user.createDataForList();
          });
          return pagination;
      })
    );
  }

  getCustomerRegisterById(id) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/${id}`
    return this._service.get(url)
  }

  getListCompanyNameTrialRegister(type) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/getListCompanyNameTrialRegister/${type}`
    return this._service.get(url)
  }

  getCustomerUpgrade(params) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/getAllLstEmployeeUpgrade`
    return this._service.get(url, params || {}).pipe(
      map((response: any) => {
          const pagination = new CPagination<UserTrialModel>().decode(response);
          pagination.items = pagination.items.map((item: any, index) => {
              const user = new UserTrialModel(item);
              user.stt = index + 1 + params.pageSize * (params.page - 1);
              return user.createDataForList();
          });
          return pagination;
      })
    );
  }

  confirmRegisterAccount(params) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/updateTrialregister`
    return this._service.put(url, params)
  }
  upgradeAccount(params) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/upgradeEmployeeAccount`
    return this._service.put(url,params)
  }
  changeActivation(id) {
    const url = `${ConstantUrl.url_employee}admin/trialRegister/changeActivation/${id}`
    return this._service.put(url, {})
  }
  resetPassword(params: any) {
    const url = ConstantUrl.url_sts_domain + 'user/admin/resetPwdAccountTrial';
    return this._httpService.put(url, params);
  }

  updateUserInfor(params) {
    const url = ConstantUrl.url_employee + `admin/trialRegister/updateInfoEmployeeAccountTrial`
    return this._service.put(url, params)
  }
}
