import { Constant } from 'app/shared/constant/constant';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-esale-kit-file-uploader',
  templateUrl: './esale-kit-file-uploader.component.html',
  styleUrls: ['./esale-kit-file-uploader.component.scss']
})
export class ESaleKitFileUploaderComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() items: FileElement;
  @Input() typeFolderUpload: string;
  @Output() itemsChange = new EventEmitter();
  isLoading: boolean = false;
  isDragOver: boolean = false;
  isUploading: boolean = false;
  fileExtensionsExcluded: string[] = ['exe','application'];
  constantTypeFolder = Constant.TYPE_FOLDER_ESALEKIT;
  title: string;

  @Input() accept = '*';

  constructor(
    public uploadFileService: UploadFileService,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    switch (this.typeFolderUpload) {
      case this.constantTypeFolder.MAIN:
        // Image, Video, PDF
        this.title = 'Tải files video, image, pdf'
        break;
      case this.constantTypeFolder.VIDEO:
        // Only accept type video
        this.title = 'Tải files video'
        break;
      case this.constantTypeFolder.IMAGE:
        // Only accept type image
        this.title = 'Tải files image'
        break;
    }
  }

  onDragOver(event: any) {
    this.isDragOver = true;
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event: any) {
    this.isDragOver = false;
    event.stopPropagation();
    event.preventDefault();
  }

  onDrop(event: any) {
    this.isDragOver = false;
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    this.addFile(selectedFile);
  }

  onFileSelected(event: any) {
    const selectedFiles: File = event.target.files[0];
    this.addFile(selectedFiles);
  }

  addFile(file) {
    const fileExtension = file.name.split('.').pop();
    const fileExtensionType = file.type.split('/').shift();
    if (this.fileExtensionsExcluded.includes(fileExtension)) {
      this.toastr.error('Lỗi!', 'File tải lên không hợp lệ: ' + file.name);
      return;
    }
    switch (this.typeFolderUpload) {
      case this.constantTypeFolder.MAIN:
        if (this.fileExtensionsExcluded.includes(fileExtensionType)) {
          // Only accept pdf
          if (fileExtension !== 'pdf') return this.toastr.error('Lỗi!', 'Kiểu file tải lên không hợp lệ: ' + fileExtension);
        }
        break;
      case this.constantTypeFolder.VIDEO:
        // Only accept type video
        if (fileExtensionType !== 'video') {
          return this.toastr.error('Lỗi!', 'Kiểu file tải lên không hợp lệ: ' + fileExtension);
        }
        break;
      case this.constantTypeFolder.IMAGE:
        // Only accept type image
        if (fileExtensionType !== 'image') {
          return this.toastr.error('Lỗi!', 'Kiểu file tải lên không hợp lệ: ' + fileExtension);
        }
        break;
    }

    // Upload file
    this.isUploading = true;
    this.uploadFileService.uploadFileESaleKit(file).subscribe((res) => {
      this.isUploading = false;
      this.itemsChange.emit(res);
    })
  }
}

export interface FileElement {
  originalName: string,
  uploadName: string,
  absoluteUrl: string,
  url: string,
  fileType: string,
}
