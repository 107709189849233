import {Component, Input, Injector, AfterViewInit, Output, EventEmitter} from '@angular/core';
import {Jsonp, URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'typeahead',
  templateUrl: './typeahead.component.html',
  styles: [`.form-control { width: 300px; display: inline;margin-bottom: 0px !important;}`]
})
export class TypeaheadComponent implements AfterViewInit {
    @Input('service') serviceName?: string;
    @Input('searchFunction') searchFunction?: string;
    @Input('selectedItem') selectedItem?: any;
    @Input('inputFormatter') inputFormatter?: any;
    @Input('resultFormatter') resultFormatter?: any;
    @Output() itemUpdated = new EventEmitter();
    service: any;
    searching = false;
    searchFailed = false;

  constructor(
      private injector: Injector
    ) {
        this.itemUpdated.emit(this.selectedItem);
    }
    ngAfterViewInit() {
        this.service = this.injector.get(this.serviceName);
        this.searchFunction = this.searchFunction || 'search';
    }
    handleUserUpdated(event) {
        this.selectedItem = null;
        this.selectedItem = event.item ? event.item : event 
        this.itemUpdated.emit(this.selectedItem);
    }

    search = (text$: Observable<string>) =>
    text$
        .debounceTime(300)
        .distinctUntilChanged()
        .do(() => this.searching = true)
        .switchMap(term =>
        this.service[this.searchFunction](term)
            .do(() => {
                this.searchFailed = false
            })
            .catch(() => {
                this.searchFailed = true;
                return Observable.of([]);
            }))
        .do(() => {
            this.searching = false;
        });
}