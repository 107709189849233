import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from './time-picker.component';
import { NgbDatepickerModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from 'app/pages/share-components/filter/custom-datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'


@NgModule({
  declarations: [TimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgxMaterialTimepickerModule,
    NgbModule,
    FormsModule
  ],

  exports: [
    TimePickerComponent
  ],

  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class TimePickerModule { }
