import { IDecode } from './i-decode';
import { CPOS } from './c-pos';
import { CPermission } from './c-permission';
import { ERoleId } from 'app/enums/e-role-id.enum';
import { CAddress } from './c-address';
import { ILevelPoint, ELevelPoint, EStartRank } from 'app/enums/e-level-point.enum';
import { EConnectionStatus } from 'app/enums/e-connection-status';
import { RolesAsConst } from 'app/enums/e-permission.enum';

export class CUser implements IDecode<CUser> {

    public id: string;
    public code: string;
    public active: boolean;
    public workingAt: string;
    public managerAt: string;
    public avatar: string;
    public email: string;
    public name: string;
    public phone: number;
    public roleId: string;
    public identityCode: string;
    public identityDate: string;
    public identityAddress: string;
    public secondEmail: string;
    public taxId: string;
    public point: number;
    public pos: CPOS;
    public f1San: CPOS;
    public interestedArea: CAddress;
    public permissions: CPermission[];
    public connectedTime: string;
    public codeDX: string;
    public connectionStatus: EConnectionStatus;
    public role: any;
    public staffIds: any;
    public isAdmin: boolean;
    public roleCanAssign: String[];
    public socialUrls: [];
    public endDateTrial: any;

    constructor(params: Partial<CUser> = {}) {
        this.copy(params);
    }

    public get indexRankLevelPoint(): number {
        return ELevelPoint.findIndex((el: ILevelPoint) => this.point < el.MaxPoint);
    }

    public get rankLevel(): ILevelPoint {
        return ELevelPoint.find((el: ILevelPoint) => this.point < el.MaxPoint) || (EStartRank[EStartRank.length - 1]);
    }

    public get rankDownLevel(): ILevelPoint {
        return ELevelPoint[this.indexRankLevelPoint - 1] || { Rank: 'New', Level: 0, MaxPoint: 0 };
    }

    public get remainPercentUpLevel(): number {
        return (this.point - this.rankDownLevel.MaxPoint) / (this.rankLevel.MaxPoint - this.rankDownLevel.MaxPoint) * 100;
    }

    public get touchedMaximumRank(): boolean {
        const indexRank = EStartRank.findIndex((el: ILevelPoint) => this.rankLevel.Rank === el.Rank);
        if (indexRank > 0 && indexRank === EStartRank.length - 1) {
            return true;
        }
        return false;
    }

    public get nametUpRank(): string {
        return EStartRank[EStartRank.findIndex((el: ILevelPoint) => this.rankLevel.Rank === el.Rank) + 1].Rank;
    }

    public get remainPointUpRank(): number {
        return EStartRank[EStartRank.findIndex((el: ILevelPoint) => this.rankLevel.Rank === el.Rank)].MaxPoint - this.point;
    }

    public get roleName(): string {
        return this.role ? this.role[0].name:'';
    }

    public get roleFullName(): string {
        return this.role ? this.role[0].name:'';
    }

    public isRoleNVKD(): boolean {
        return this.roleId === ERoleId.NVKD;
    }

    public isRoleTPPOS(): boolean {
        return this.roleId === ERoleId.TRUONG_PHONG_POS;
    }

    public isRolePTKD(): boolean {
        return this.roleId === ERoleId.PHO_TGD_KD;
    }

    public isRoleDVKH(): boolean {
        return this.roleId === ERoleId.DVKH || this.roleId === ERoleId.DVKH_IMPORT_SP || this.roleId === ERoleId.DVKH_HAU_KIEM_SP;
    }

    public isRoleAdmin(): boolean {
        return this.permissions.length === 0;
    }

    public isRoleKhaiThac(): boolean {
        return this.roleId === ERoleId.KHAI_THAC;
    }

    public isRoleGDS(): boolean {
        return this.roleId === ERoleId.GD_SAN;
    }

    public isRoleKT(): boolean {
        return this.roleId === ERoleId.KE_TOAN;
    }

    public isRoleKTT(): boolean {
        return this.roleId === ERoleId.KE_TOAN_TRUONG || (this.hasPermission(RolesAsConst.accountant));
    }

    public isRoleKTOrKTT(): boolean {
        return this.isRoleKT() || this.isRoleKTT();
    }

    hasPermission(params: string): boolean {
      return this.permissions.some((permission: CPermission) => params === permission.featureName);
    }

    isPOSMatched(posId: string) {
        return this.pos.id === posId || this.pos.parentId === posId;
    }

    copy(params: Partial<CUser> = {}): CUser {
        params = params || {};

        this.id = params.id || null;
        this.code = params.code || null;
        this.active = params.active || false;
        this.phone = params.phone || null;
        this.name = params.name;
        this.avatar = params.avatar || null;
        this.email = params.email || null;
        this.point = params.point || 0;
        this.identityCode = params.identityCode || null;
        this.roleId = params.roleId || null;
        this.role = params.role || null;
        this.staffIds = params.staffIds || null;
        this.isAdmin = !!params.isAdmin;
        this.roleCanAssign = params.roleCanAssign;
        this.socialUrls = params.socialUrls;
        this.endDateTrial = params.endDateTrial;

        this.identityDate = params.identityDate;
        this.identityAddress = params.identityAddress;
        this.secondEmail = params.secondEmail;
        this.taxId = params.taxId;

        this.interestedArea = new CAddress(params.interestedArea);
        this.pos = new CPOS(params.pos);
        this.f1San = new CPOS(params.f1San);
        this.permissions = CPermission.copyList(params.permissions);

        this.connectedTime = params.connectedTime || null;
        this.connectionStatus = params.connectionStatus || EConnectionStatus.Offline;
        this.codeDX = params.codeDX || null;
        this.managerAt = params.managerAt || null;
        this.workingAt = params.workingAt || null;

        return this;
    }

    decode(paramsApi: any): CUser {
        paramsApi.avatar = paramsApi && paramsApi.images && paramsApi.images.avatar;
        return new CUser(paramsApi);
    }

    decodeList(paramsApi: any[]): CUser[] {
        return paramsApi.map(el => new CUser().decode(el));
    }
}
