import { Pipe, PipeTransform } from '@angular/core';
import { FloorRoundFloatNumber } from '../parse/floor-round-float-number';

const TEXT_VALUE: string[] = [' không ', ' một ', ' hai ', ' ba ', ' bốn ', ' năm ', ' sáu ', ' bảy ', ' tám ', ' chín '];
const TEXT_UNIT: string[] = ['', ' nghìn', ' triệu', ' tỷ', ' nghìn tỷ', ' triệu tỷ'];

@Pipe({ name: 'moneyText' })
export class TransformMoneyTextPipe implements PipeTransform {

  static transform(value: number) {
    let result = '';
    if (value) {
      result = this.docTienBangChu(value) + ' đồng';
    }
    return result;
  }

  // Hàm đọc số có ba chữ số;
  static docSoBaChuSo(baso: number): string {
    let tram: number;
    let chuc: number;
    let donvi: number;
    let result = '';
    tram = Math.trunc(baso / 100);
    chuc = Math.trunc((baso % 100) / 10);
    donvi = baso % 10;
    if (tram === 0 && chuc === 0 && donvi === 0) {
      return '';
    }
    if (tram !== 0) {
      result += TEXT_VALUE[tram] + ' trăm ';
      if ((chuc === 0) && (donvi !== 0)) {
        result += ' linh ';
      }
    }
    if ((chuc !== 0) && (chuc !== 1)) {
      result += TEXT_VALUE[chuc] + ' mươi ';
      if ((chuc === 0) && (donvi !== 0)) {
        result += ' linh ';
      }
    }
    if (chuc === 1) {
      result += ' mười ';
    }
    switch (donvi) {
      case 1:
        if ((chuc !== 0) && (chuc !== 1)) {
          result += ' mốt ';
        } else {
          result += TEXT_VALUE[donvi];
        }
        break;
      case 5:
        if (chuc === 0) {
          result += TEXT_VALUE[donvi];
        } else {
          result += ' lăm ';
        }
        break;
      default:
        if (donvi !== 0) {
          result += TEXT_VALUE[donvi];
        }
        break;
    }
    return result;
  }

  // Hàm đọc số thành chữ (Sử dụng hàm đọc số có ba chữ số)
  static docTienBangChu(money: number): string {
    let lan = 0;
    let i = 0;
    let so = 0;
    let result = '';
    let tmp = '';
    let mapping: number[] = [];
    if (money < 0) {
      return '';
    }
    if (money === 0) {
      return 'Không';
    }
    if (money > 0) {
      so = money;
    } else {
      so = -money;
    }
    mapping[5] = Math.trunc(so / 1000000000000000);
    if (isNaN(mapping[5])) {
      mapping[5] = 0;
    }
    so = so - parseFloat(mapping[5].toString()) * 1000000000000000;
    mapping[4] = Math.trunc(so / 1000000000000);
    if (isNaN(mapping[4])) {
      mapping[4] = 0;
    }
    so = so - parseFloat(mapping[4].toString()) * 1000000000000;
    mapping[3] = Math.trunc(so / 1000000000);
    if (isNaN(mapping[3])) {
      mapping[3] = 0;
    }
    so = so - parseFloat(mapping[3].toString()) * 1000000000;
    mapping[2] = Math.trunc(so / 1000000);
    if (isNaN(mapping[2])) {
      mapping[2] = 0;
    }
    mapping[1] = Math.trunc((so % 1000000) / 1000);
    if (isNaN(mapping[1])) {
      mapping[1] = 0;
    }
    mapping[0] = Math.trunc(so % 1000);
    if (isNaN(mapping[0])) {
      mapping[0] = 0;
    }
    if (mapping[5] > 0) {
      lan = 5;
    } else if (mapping[4] > 0) {
      lan = 4;
    } else if (mapping[3] > 0) {
      lan = 3;
    } else if (mapping[2] > 0) {
      lan = 2;
    } else if (mapping[1] > 0) {
      lan = 1;
    } else {
      lan = 0;
    }
    for (i = lan; i >= 0; i--) {
      tmp = this.docSoBaChuSo(mapping[i]);
      result += tmp;
      if (mapping[i] > 0) {
        result += TEXT_UNIT[i];
      }
      if ((i > 0) && (tmp.length > 0)) {
        result += ',';
      }
    }
    if (result.substring(result.length - 1) === ',') {
      result = result.substring(0, result.length - 1);
    }
    result = result.substring(1, 2).toUpperCase() + result.substring(2);
    return result;
  }
  transform(value: number) {
    let result = '';
    if (value) {
      result = TransformMoneyTextPipe.docTienBangChu(value) + ' đồng';
    }
    return result;
  }
}
