import { environment } from "../../../environments/environment";
import { createNumberMask } from "text-mask-addons/dist/textMaskAddons";
import {IHotline} from 'app/shared/interfaces/hotline.interface';
import { TXN_STATUS, UNIT_STATUS } from "./transaction.constant";
export enum CategoryEnum {
  'DAT_NEN' = 'fbbec112-df7f-4cf2-8e72-c5c546be9b85',
  'CAN_HO' = 'baf92168-1529-462e-8687-d20d6acde1bc',
}


export const EMAIL_REGEXP =/^ *(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{2,}\.[0-9]{2,}\.[0-9]{2,}\.[0-9]{2,}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z +*]{2,}))$/;

export const PHONE_REGEXP = /^[0-9]{8,10}$/;

export class Constant {
  //
  public static UPLOAD_FILE_TYPE = {
    RECEIPT: 'receipt',
    CONTRACT: 'contract',
    CONTRACT_LIQUIDATE: 'contract-liquidate',
    PROPERTY_TICKET: 'property-ticket',
    PROJECT: 'project',
    SALE_POLICY: 'sales-policy',
    ESALEKIT: 'esalekit'
  };

  public static ProjectType = {
    CanHo: 'Căn hộ'
  };

  public static month = Array.from({length: 12}, (_, i) => i + 1).map(x => x.toString().padStart(2, "0"));

  //TODO Icon s3
  public static icon_url_images = "/assets/images/";
  public static icon_url_emoji = "./assets/emoji/";

  // Voice Call
  public static voice_call_available = 'AVAILABLE';
  public static voice_call_invisible = 'INVISIBLE';
  public static voice_call_prefix_customer = 'cus_';

  //Key localStorage
  public static key_local_token = "token";
  public static key_local_version = "version";
  public static key_local_language = "language";
  public static key_local_status = "status";

  //Error code
  public static error_force_logout = 401;
  public static error_not_connect_server = 0;
  public static error_server_error = 500;
  public static error_server_maintenance = 503;
  public static error_code = 400;

  // Error Message
  public static ERROR_REQUIRED = 'Dữ liệu không để trống';
  public static ERROR_INVALID = 'Dữ liệu không hợp lệ';
  public static ERROR_MAXLENGTH = 'Dữ liệu quá dài';
  public static MESSAGE_ERROR_PERIOD = 'Tổng tỉ lệ giao dịch của các đợt thanh toán không được quá 100%';
  public static ERROR_REQUIRED_2 = 'Đây là trường bắt buộc';
  public static ERROR_INVALID_2 = 'Sai định dạng. Vui lòng kiểm tra lại thông tin';

  // Dropdown Search
  public static PLACEHOLDER_SEARCH = 'Tìm kiếm ...';
  public static NO_ENTRIES_FOUND = 'Không tồn tại kết quả tìm kiếm';

  //Success code
  public static success_code = 200;
  public static ERROR_CODE_RENEW_PASSWORD = 40027;

  public static const_timeout_dowload_file = 30 * 60 * 1000;
  //API

  // Regex
  public static REGEX_EMAIL = /^[0-9-A-z][A-z0-9_\.-]{1,32}@[A-z0-9-_]{2,}(\.[A-z0-9]{2,}){1,2}$/;

  public static REGEX_STRIM_HTML = /<[^>]*>?/gm;

  public static REGEX_NUMBER_COMMA = /^[0-9]+(,[0-9]+)*,?$/;
  public static REGEX_NOT_SPACE = /^\S*$/;
  public static REGEX_TEXT = /^ *[^0-9 ]+.*$/;
  public static REGEX_NUMBER = /^[0-9]*$/;
  public static REGEX_TEXT_AND_NUMBER = /^[a-z0-9]+$/i;
  public static REGEX_VN_PHONE = /((09|03|07|08|05|849|843|847|848|845)+([0-9]{8})\b)/;
  public static REGEX_VN_TEXT = /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ0-9 ]*$/;
  public static REGEX_CODE = /^[a-zA-Z0-9 -]*$/;
  public static ITEMS_PER_PAGE = 10;
  public static LENGTH_50 = 50;
  public static LENGTH_100 = 100;
  public static LENGTH_200 = 200;
  public static LENGTH_1000 = 1000;

  public static SOCIAL_FACEBOOK = 'Facebook';
  public static SOCIAL_LINKEDIN = 'LinkedIn';
  public static SOCIAL_VIBER = 'Viber';
  public static SOCIAL_ZALO = 'Zalo';


  public static MAX_SIZE_IMAGE = 5 * 1024 * 1024; // size 5MB
  public static MAX_ITEMS_UPLOAD = 10;
  public static TYPE_IMAGES_LIST = [
    {
      key: 'icon',
      value: 'Icon'
    },
    {
      key: 'thumnail',
      value: 'Thumnail'
    }
  ];
  public static TYPE_IMAGES = {
    icon: 'Icon',
    thumnail: 'Thumnail'
  }
  // Regex
  public static CONTROL_TYPES = [
    { id: 1, code: "dropdown", name: "Drop-down list" },
    { id: 2, code: "radio", name: "Radio button list" },
    { id: 3, code: "checkbox", name: "Checkboxes" },
    { id: 4, code: "text", name: "Textbox" },
    { id: 5, code: "textare", name: "Multiline textbox" },
    // { id: 6, code: "slider", name: "Slider" },
    { id: 7, code: "number", name: "Number" },
    // {id: 1, code: "datepicker", name: "Date picker"},
    // {id: 1, code: "file", name: "File upload"},
    // {id: 1, code: "color", name: "Color squares"}
  ];

  public static CONTROL_POSITION = [
    {code: "all" , name: 'Tất cả'},
    {code: "ycdch" , name: 'Yêu cầu đặt chỗ'},
    {code: "ycdc" , name: 'Yêu cầu đặt cọc'},
  ];
  public static CONTROL_TYPES_OBJECT = {
    "dropdown": "dropdown",
    "radio": "radio",
    "checkbox": "checkbox",
    "text": "text",
    "textare": "textare",
    // "slider": "slider",
    "number": "number",
    // "file": "file",
    // "color": "color"
  };
  public static ORDERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  // public static MASTER_DATA.ID_CARDS = [{
  //   key: 'CMND',
  //   value: 'CMND',
  // },{
  //   key: 'Passport',
  //   value: 'Hộ chiếu',
  // }];
  // public static DIRECTIONS = [{
  //   key: 'BAC',
  //   value: 'Bắc',
  // },{
  //   key: 'DONG_BAC',
  //   value: 'Đông Bắc',
  // },{
  //   key: 'TAY_BAC',
  //   value: 'Tây Bắc',
  // },{
  //   key: 'DONG',
  //   value: 'Đông',
  // },{
  //   key: 'DONG_NAM',
  //   value: 'Đông Nam',
  // },{
  //   key: 'NAM',
  //   value: 'Nam',
  // },{
  //   key: 'TAY_NAM',
  //   value: 'Tây Nam',
  // },{
  //   key: 'TAY',
  //   value: 'Tây',
  // }];
  public static LEAD_SOURCE = {
    all: 'Tất cả',
    SHARE_HOLDER: 'Cổ đông',
    OLD_CUS: 'Khách hàng đã giao dịch',
    KTN: 'Kho Tài Nguyên',
    PKT: 'Phòng Khai Thác',
    MKT: 'Marketing DXS',
    MKT_DXG: 'Marketing DXG'
  };
  public static LEAD_CARE_SOURCE = {
    Care: 'Care'
  };
  public static TYPES_TRANSACTION = [{
    key: 'BUY',
    value: 'Mua',
  }, {
    key: 'RENT',
    value: 'Thuê',
  }, {
    key: 'SELL',
    value: 'Bán',
  }, {
    key: 'LEASE',
    value: 'Cho thuê',
  }];

  public static LIST_PERIOD_COMM = [
    { id: '202201', name: 'Tháng 01/2022' },
    { id: '202202', name: 'Tháng 02/2022' },
    { id: '202203', name: 'Tháng 03/2022' },
    { id: '202204', name: 'Tháng 04/2022' },
    { id: '202205', name: 'Tháng 05/2022' },
    { id: '202206', name: 'Tháng 06/2022' },
    { id: '202207', name: 'Tháng 07/2022' },
    { id: '202208', name: 'Tháng 08/2022' },
    { id: '202209', name: 'Tháng 09/2022' },
    { id: '202210', name: 'Tháng 10/2022' },
    { id: '202211', name: 'Tháng 11/2022' },
    { id: '202212', name: 'Tháng 12/2022' },

    { id: '202301', name: 'Tháng 01/2023' },
    { id: '202302', name: 'Tháng 02/2023' },
    { id: '202303', name: 'Tháng 03/2023' },
    { id: '202304', name: 'Tháng 04/2023' },
    { id: '202305', name: 'Tháng 05/2023' },
    { id: '202306', name: 'Tháng 06/2023' },
    { id: '202307', name: 'Tháng 07/2023' },
    { id: '202308', name: 'Tháng 08/2023' },
    { id: '202309', name: 'Tháng 09/2023' },
    { id: '202310', name: 'Tháng 10/2023' },
    { id: '202311', name: 'Tháng 11/2023' },
    { id: '202312', name: 'Tháng 12/2023' },
  ];

  public static LIST_WEEK_DAY = [
    'Thứ 2',
    'Thứ 3',
    'Thứ 4',
    'Thứ 5',
    'Thứ 6',
    'Thứ 7',
    'Chủ nhật',
  ];

  public static LIST_COMM_STATUS = [
    { key: '', value: 'Tất cả' },
    { key: '1', value: 'Đã công bố' },
    { key: '0', value: 'Chưa công bố' },
  ];

  public static NOT_MINING_TRANSACTION = 'NOT_MINING';
  public static BUY_TRANSACTION = 'BUY';
  public static RENT_TRANSACTION = 'RENT';
  public static SELL_TRANSACTION = 'SELL';
  public static LEASE_TRANSACTION = 'LEASE';
  public static SELL_BROKER_FEE_PRECENT_MAX = 100;
  public static LEASE_BROKER_FEE_PRECENT_MIN = 50;
  public static LEASE_BROKER_FEE_MIN = 10000000;
  public static ROSPID = '58cac11a-8334-49ee-ac52-dfcbc248dfee';
  //Config number input
  public static decimal1Number = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowLeadingZeroes: true,
    decimalLimit: 1,
    integerLimit: 12
  });
  public static decimal2Number = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowLeadingZeroes: true,
    decimalLimit: 2,
    integerLimit: 12
  });
  public static percent = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 2
  });
  public static percent3 = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 3
  });
  public static phoneMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowLeadingZeroes: true,
    includeThousandsSeparator: false,
    integerLimit: 20
  });
  public static otpMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowLeadingZeroes: true,
    includeThousandsSeparator: false,
    integerLimit: 6
  });
  public static numberBankMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowLeadingZeroes: true,
    includeThousandsSeparator: false,
    integerLimit: 20
  });
  public static numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 12
  });
  public static numberFeeMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 1,
    allowLeadingZeroes: true,
    integerLimit: 12
  });
  public static geolocation = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 100,
    allowLeadingZeroes: true,
    integerLimit: 3
  });
  public static numberIntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 12,
    allowLeadingZeroes: true,
  });
  public static number2IntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 2,
    allowLeadingZeroes: true,
  });
  public static number4IntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 4,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  });
  public static moneyMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      integerLimit: 20,
      thousandsSeparatorSymbol: ',',
      allowLeadingZeroes: false
  });
    public static percentMask = createNumberMask({
        prefix: '',
        suffix: '%',
        allowDecimal: true,
        decimalLimit: 2,
        integerLimit: 3
    });

  public static extension_img = "jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG|bmp|BMP|bgp|BGP|PPM|ppm|PGM|pgm|pbm|PBM|ico|ICO|PGF|pgf|IMG|img|HEIC|heic";
  public static extension_file_doc = "pdf|PDF|xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|zip|ZIP|rar|RAR";
  public static checkMobile() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor);
    return window.screen.width < 768 ? check : false;
  }

  public static checkTablet() {
    let screen = window.screen.width;
    return screen >= 768 && screen <= 1024;
  }

  public static checkDateIssue(date) {
    let d = parseInt(date);
    let dayNow = new Date().getDate();
    let monthNow = new Date().getMonth() + 1;
    let yearNow = new Date().getFullYear();
    let day = new Date(d).getDate();
    let month = new Date(d).getMonth() + 1;
    let year = new Date(d).getFullYear();

    if (yearNow === year) {
      if (monthNow === month) {
        return dayNow <= day
      } else return monthNow <= month;
    } else {
      return yearNow <= year;
    }
  }

  // Dao: sua loi disable khi quy da qua ngay dao han
  public static checkMaturityIssue(date) {
    let d = parseInt(date);
    let dayNow = new Date().getDate();
    let monthNow = new Date().getMonth() + 1;
    let yearNow = new Date().getFullYear();
    let dayNext = new Date(d).getDate();
    let monthNext = new Date(d).getMonth() + 1;
    let yearNext = new Date(d).getFullYear();

    if (yearNow === yearNext) {
      if (monthNow === monthNext) {
        return dayNow >= dayNext
      } else return monthNow >= monthNext;
    } else {
      return yearNow >= yearNext;
    }
  }

  public static checkAndroid() {
    let userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public static checkIOS() {
    let userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
  }

  public static getMatchingEventName(): string[] {
    return [
      'dealingUpdated',
      'dealingRequested',
      'dealingAccepted',
      'dealingDeleted',
      'dealingApproved',
      'dealingRejected',
      'dealingReserved',
    ];
  }

  public static projectTypeList(): string[] {
    return ['Đất nền', 'Căn hộ', 'Biệt thự', 'Khu nghỉ dưỡng/Khu Phức hợp', 'Shophouse/Nhà ở TM', 'Khu đô thị thương mại giải trí'];
  }

  public static PRIORITY_ID = {
    UT1: '1',
    UT2: '2',
    UT3: '3',
    EMPTY: '0',
    LOADING: '4'
  }

  public static STATUS_PERIOD = {
    NEW: 'NEW',
    WAITING: 'WAITING',
    APPROVED: 'APPROVED',
    ANNOUNCED: 'ANNOUNCED',
  }

  public static STATUS_WORKFLOW = {
    WAITING: 'WAITING',
    COMPLETED: 'COMPLETED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  }
  public static TYPE_POLICY = {
    PAYMENT: 'payment',
    DISCOUNT: 'discount',
  }

  public static historyFilter = 'historyFilter';

  public static IMPORT_TYPE_COVID_CHECKIN  = {
    IMPORT_USER: 'Người ra vào',
    IMPORT_SECURITY: 'Bảo Vệ'
  }
  //esale-kit
  public static TYPE_FOLDER_ESALEKIT = {
    MAIN: 'main',
    IMAGE: 'image',
    VIDEO: 'video',
    NAMEPROJECT: 'nameProject'
  };
  public static NO_ENTRIES_FOUND_AG_GRID_DEFAULT = '<span></span>';
  public static NO_ENTRIES_FOUND_AG_GRID = '<span> Không có dữ liệu </span>';
  public static VEHICLE_STATUS = {
    pending: 'Chưa xác nhận',
    approved: 'Đã xác nhận',
    rejected: 'Từ chối',
    cancelled: 'Hủy'
  }
  public static RELATIONSHIP = {
    son: 'Con trai',
    owner: 'Chủ hộ',
    mother: 'Mẹ',
    daughter: 'Con gái',
  }
  public static FAMILY_STATUS = {
    active: 'Hoạt động',
    deactive: 'Đã hủy'
  }
  public static SERVICE_TYPE = {
    BASE: 'Cơ bản',
    BOOKING: 'Đặt chỗ',
    LINK: 'Liên kết'
  }
  public static LIMIT_TIME = {
    day:'Ngày',
    week:'Tuần',
    month: 'Tháng',
  }

  public static BOOKING_SEAT_STATUS ={
    PENDING: 'Chờ duyệt',
    APPROVED: 'Đã duyệt',
    REJECTED: 'Đã từ chối',
    CANCEL: 'Đã hủy'
  }
  public static BOOKING_SEAT_TIME = {
    morning:  'Buổi sáng',
    noon: 'Buổi chiều',
    evening: 'Buổi tối'
  }

  public static METHOD_REGISTER_TICKET ={
    monthly_ticket: 'Vé tháng',
    year_ticket: 'Vé năm',
  }

  public static EMERGENCY_HOTLINES: IHotline[] = [
    {
      id:'1',
      pressNumber: '111',
      description: '111 Đường dây nóng bảo vệ trẻ em',
      phone: '111',
      active: false,
    },
    {
      id:'2',
      pressNumber: '112',
      description: '112 Trợ giúp tìm kiếm và cứu nạn',
      phone: '112',
      active: false,
    },
    {
      id:'3',
      pressNumber: '113',
      description: '113 Công an',
      phone: '113',
      active: false,
    },
    {
      id:'4',
      pressNumber: '114',
      description: '114 Phòng cháy chữa cháy',
      phone: '114',
      active: false,
    },
    {
      id:'5',
      pressNumber: '115',
      description: '115 Cấp cứu y tế',
      phone: '115',
      active: false,
    },
  ]

  public static SERVICE_CHARGE_TYPE = {
    unit: 'Đơn giá',
    progressive: 'Giá lũy tiến',
    calculatored: 'Đã tính',
  }

  public static SERVICE_CHARGE_TYPE_OBJECT = {
    UNIT: 'Đơn giá',
    PROGRESSIVE: 'Giá lũy tiến'
  }

  public static BILL_STATUS = {
    DRAFT: 'Chưa công bố',
    PAID: 'Đã thanh toán',
    UNPAID: 'Đã công bố',
    CANCEL: 'Đã hủy'
  }
  public static DOCUMENT_STATUS = {
    ACTIVE: 'Hoạt động',
    INACTIVE: 'Không hoạt động'
  }

  public static FODER_STATUS = {
    ACTIVE: 'Hoạt động',
    INACTIVE: 'Không hoạt động'
  }

  public static SERVICE_STATUS = {
    ACTIVE:  'Hoạt động',
    INACTIVE: 'Không hoạt động'
  }
  public static HANDOVER_STATUS = {
    init: 'Bàn giao'
  }
  public static DELIVERY_SCHEDULE_STATUS ={
   unconfirmed: 'Chưa xác nhận',
   requested: 'Yêu cầu đổi lịch',
   confirmed: 'Đã xác nhận'
  }
  public static HANDOVER_DELIVERY_ITEM_TYPE ={
    category: 'Hạng mục',
    index:'Chỉ số'
  }

  public static PROJECT_RULES =
  "Nguyên tắc Cộng đồng: "+
      "Mục tiêu của cộng đồng này là hỗ trợ các cư dân giao tiếp, giúp duy trì sự kết nối được hình thành, xây dựng mối quan hệ bền chặt và cùng có lợi giữa tất cả các cư dân, thúc đẩy thiện chí trong cộng đồng và kích thích quan tâm và tăng cường sự tham gia với tổ chức của chúng tôi thông qua việc tăng cường cơ hội tham gia."+
      "Để đáp ứng các mục tiêu của cộng đồng này, điều quan trọng là các thành viên phải cảm thấy như họ đang ở một nơi an toàn, nơi tập trung những người ở chung khu vực. Do đó, chúng tôi yêu cầu bạn đọc và tuân thủ các nguyên tắc tuân theo."+
"Quy luật:"+
  "- Đối xử với người khác trên mạng như cách bạn đối xử với họ trong đời thực"+
"- Hãy khoan dung với quan điểm của người khác; tôn trọng không đồng ý khi ý kiến không phù hợp"+
"- Tôn trọng quyền riêng tư và thông tin cá nhân của các cựu sinh viên khác"+
"- Giao tiếp lịch sự và tôn trọng"+
"Vui lòng không:"+
"- Công kích cá nhân đối với các thành viên khác trong cộng đồng"+
"- Sử dụng nhận xét phỉ báng hoặc tuyên bố sai sự thật chống lại người khác"+
"- Đăng nhận xét định kiến hoặc thô tục"+
"- Bắt nạt hoặc làm những nhận xét gây khó chịu cho các thành viên khác trong cộng đồng"+

"Sử dụng tin nhắn / email một cách có trách nhiệm."+
      "Những công cụ này nhằm mục đích giữ cho các cư dân liên lạc với nhau, giúp tạo điều kiện kết nối và thúc đẩy lợi ích chung. Các hoạt động bị cấm bao gồm chào mời cho các dịch vụ kinh doanh hoặc cá nhân, gây quỹ, gọi điện dưới bất kỳ hình thức nào, gửi thư hàng loạt hoặc phát sóng điện tử."+

"Hậu quả:"+
      "Chúng tôi sẽ hành động khi thấy ai đó vi phạm các nguyên tắc này. Đôi khi điều đó chỉ có nghĩa là đưa ra một lời cảnh báo cho ai đó; những lần khác, nó có nghĩa là thu hồi hoàn toàn các đặc quyền hoặc tài khoản nhất định. Chúng tôi yêu cầu tất cả thành viên cộng đồng báo cáo hành vi vi phạm nguyên tắc của chúng tôi tới datxanh@gmail..com."+

"Thỏa thuận:"+
     "Bằng cách đăng nhập vào cộng đồng và kích hoạt hồ sơ của bạn, bạn được coi là đồng ý với các điều khoản và điều kiện được liệt kê ở trên.";


}

export enum WorkflowDataType {
  TYPE_COMMISSION = 'TYPE_COMMISSION'
}

export enum NewsStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CmsStatus {
    ACTIVE = 'active',
    INACTIVE = 'deactive'
}

export enum LoanStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum SupplierStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ServiceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ServiceType {
  BASE = 'BASE',
  BOOKING = 'BOOKING',
  LINK = 'LINK'
}

export enum LimitTime {
  DAY = 'day',
  TUAN = 'week',
  THANG = 'month',
}


export enum Relationship {
  owner = 'owner',
  son = 'Con trai',
}

export enum VehicleStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled"
}
export enum BookingSeatStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCEL = 'CANCEL'
}

export enum BookingSeatTime {
  morning = 'morning',
  noon = 'noon',
  evening = 'evening'
}

export enum FolderStatus {
  ACTIVE = 'active',
  INACTIVE = 'deactive'
}

export enum DocumentStatus {
  ACTIVE = 'active',
  INACTIVE = 'deactive'
}

export enum ChargeType {
  UNIT_PRICE = 'unit',
  PROGRESSIVE_PRICE = 'progressive',
  CALCULATORED_PRICE = 'calculatored'
}

export enum BillStatus {
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  CANCEL = 'CANCEL'
}
export enum DeliveryScheduleStatus {
    UNCONFIRMED = 'unconfirmed',
    REQUESTED = 'requested',
    CONFIRMED = 'confirmed'
}

export enum HandoverDeliveryItemType {
  CATEGORY = 'category',
  INDEX ='index'
}

export enum PropertyPrimaryStatusText {
    CLOSE = TXN_STATUS['CLOSE'], // Chưa mở bán
    COMING = TXN_STATUS['COMING'], // Đang chờ xác nhận
    PROCESSING = TXN_STATUS['PROCESSING'], // Đang xử lý
    POS_CONFIRM = TXN_STATUS['POS_CONFIRM'], //ĐVBH đã xác nhận
    LOCK_CONFIRM = TXN_STATUS['LOCK_CONFIRM'], // Xác nhận cọc, chờ bổ sung hồ sơ
    UNSUCCESS = TXN_STATUS['UNSUCCESS'], // Giao dịch không thành công
    SUCCESS = TXN_STATUS['SUCCESS'], // Giao dịch thành công
    MPOS_CONFIRM = TXN_STATUS['MCONFIRM'], //client ĐVBH đã xác nhận
    MSUCCESS = TXN_STATUS['MSUCCESS'], //client Giao dịch thành công
}

export enum TypeEmployeeEnum {
  EMPLOYEE_TRIAL = "EmployeeTrial",
  TRIAL_REGISTER = "TrialRegister",
  EMPLOYEE_UPGRADE = "EmployeeUpgrade"
}

export enum FilterDay {
  START_DATE_TRIAL = 'Ngày dùng thử',
  DAY_REGISTER = 'Ngày đăng ký',
  END_DATE_TRIAL = 'Ngày hết hạn'
}
export class ActiveStatusFilter {
    public static ACTIVE = {
        label: 'Đã kích hoạt',
        value: true
    };
    public static INACTIVE = {
        label: 'Chưa kích hoạt',
        value: false
    };
}

// Status Notification-Manual
export class ActiveStatusTemplateFilter {
  public static ACTIVE = {
    label: 'Đã kích hoạt',
    value: true
  };
  public static INACTIVE = {
      label: 'Chưa kích hoạt',
      value: false
  };
}

export class SendNoticeStatus {
  public static SENT = {
    label: 'Đã gửi',
    value: 'SENT'
  };
  public static UNSENT = {
      label: 'Chờ gửi',
      value: 'UNSENT'
  };
  public static IN_BIRTHDAY = {
    label: 'Sinh nhật',
    value: 'IN_BIRTHDAY'
  };
}

export class ActiveChannelTemplateFilter {
  public static EMAIL = {
    label: 'Email',
    value: 'Email'
  };
  public static SMS = {
      label: 'SMS',
      value: 'SMS',
  };
  public static NOTIFICATION = {
    label: 'Notification',
    value: 'Notification',
  };
}

export class ActiveStatusProjectFilter {
  public static COMING_SALE = {
    label: 'Sắp mở bán',
    value: 'Sắp mở bán',
};
public static TRADING = {
    label: 'Đang giao dịch',
    value: 'Đang giao dịch',
};
}

export enum EventTypeEnum {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export enum RegsiterTypeEnum {
  SALE = 'sale', // sale đăng ký giúp khách hàng
  CUSTOMER = 'customer', // khách vãng lai
}

export class UserTrialStatus {
  public static TRIAL = {
    label: 'Đang dùng thử',
    value: 'TRIAL'
  }
  public static WAITING_UPGRADE = {
    label: 'Chờ nâng cấp',
    value: 'WAITING_UPGRADE'
  }
  public static EXPIRED = {
    label: 'Hết hạn',
    value: 'EXPIRED'
  }
  public static NOT_ACTIVATED = {
    label: 'Chờ kích hoạt',
    value: 'NOT_ACTIVATED'
  }
  public static UPGRADED = {
    label: 'Đã nâng cấp',
    value: 'UPGRADED'
  }
}

export class UserTrialUpgradePack {
  public static STARTER = {
    label: 'Starter',
    value: 'Starter'
  }
  public static GROWTH = {
    label: 'Growth',
    value: 'Growth'
  }
  public static BUSINESS = {
    label: 'Business',
    value: 'Business'
  }
  public static ENTERPRISE = {
    label: 'Enterprise',
    value: 'Enterprise'
  }
}
