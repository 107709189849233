import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector, NgZone, ElementRef, OnChanges } from '@angular/core';
import { Constant } from '../../constant/constant';

@Component({
    selector: 'custom-input',
    templateUrl: './custom-input.component.html',
    styleUrls: ['./custom-input.component.scss'],
})

export class CustomInputComponent implements OnInit, OnChanges {
    @Input() properties!: any;
    @Input() disabled;
    @Input() readonly;
    value: any;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() dataChange = new EventEmitter<{}>();
    Constant = Constant;
    controlTypes: any = Constant.CONTROL_TYPES_OBJECT;
    constructor(
    ) {
    }
    ngOnInit() {
    }
    ngOnChanges() {
        if (!this.properties) {
            return;
        }
    }
}
