import { Injectable } from '@angular/core';
// import { Response } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { ConstantUrl } from '../../shared/constant/url';
import { CareplusCustomer, Customer, CustomerMailerModel, CustomerNotificationModel, CustomerSmsModel, ManagementCustomer } from './customer.model';
import { BaseService } from '../../shared/components/base.service';
import { CPagination } from 'app/api-models/c-pagination';
import { map } from 'rxjs/operators';
import { HttpService } from 'app/shared/services/http.service';
import { LeadModel } from '../lead/lead.model';

@Injectable()
export class CustomerService extends BaseService {
  ConstantUrl = ConstantUrl;
  constructor(
    public service: DataService,
    public httpService: HttpService,
    private storageService: StorageService
  ) {
    super(service);
  }
  setUrlDXSCustomer() {
    this.domainUrl = ConstantUrl.url_customer_domain + 'customer';
    this.queryUrl = ConstantUrl.url_customer_query + 'customer';
  }
  setUrlPOSCustomer() {
    this.domainUrl = ConstantUrl.url_property_domain + 'propertyCustomer';
    this.queryUrl = ConstantUrl.url_property_query + 'propertyCustomer';
  }
  getCustomerPOS(params?) {
    const url = ConstantUrl.url_property_query + 'propertyCustomer';
    return this.service.get(url, params ? params : {});
  }
  getCustomerPosReport(params?) {
    const url = ConstantUrl.url_property_query + 'propertyCustomer/report';
    return this.service.get(url, params ? params : {});
  }
  getCustomerPosReportPaging(params?): Observable<CPagination<Customer>> {
    const url = ConstantUrl.url_property_query + 'propertyCustomer/report';
    return this.service.get(url, params ? params : null)
      .pipe(
        map((res: any) => {
          const pagination = new CPagination<Customer>().decode(res);
          pagination.items = pagination.items.map((item: any) => { const cus = new Customer(item); return cus});
          return pagination;
        })
      );
  }

  searchCustomer(name: string) {
    // Chỉ search tối đa 20 bản ghi đầu tiên
    const url = `${ConstantUrl.url_property_query}propertyCustomer/autocomplete?name=${name}`;
    return this.httpService.get(url);
  }

  getCustomerPOSV2(params?): Observable<CPagination<Customer>> {
    const url = ConstantUrl.url_property_query + 'propertyCustomer';
    return this.service.get(url, params ? params : null)
      .pipe(
        map((res: any) => {
          const pagination = new CPagination<Customer>().decode(res);
          pagination.items = pagination.items.map((item: any) => { const cus = new Customer(item); return cus.createDataForList(); });
          return pagination;
        })
      );
  }
  downloadCustomer(params?: any){
    const url = ConstantUrl.url_property_query + 'propertyCustomer/downloadCustomer';
        return this.service.dowloadFile(url, params);
  }
  getCustomerByIdentity(type: string, searchValue: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('type', type);
    queryParams.append('searchValue', searchValue);
    const url = ConstantUrl.url_customer_query + 'customer/identity?' + queryParams.toString();
    return this.service.get(url);
  }
  getDefaultCustomerCode() {
    const url = ConstantUrl.url_customer_query + 'customer/pub/defaultCode';
    return this.service.get(url);
  }
  getCustomerByPhone(phone: string, identityNumber?: string) {
    let params = identityNumber ? { phone: phone, identityNumber: identityNumber } : { phone: phone };
    const url = ConstantUrl.url_customer_query + 'customer/getByPhone';
    return this.service.get(url, params);
  }
  getPropertyCustomer(phone: string, identityNumber?: string) {
    let params = identityNumber ? { phone: phone, identityNumber: identityNumber } : { phone: phone };
    const url = ConstantUrl.url_property_query + 'propertyCustomer/getByPhone';
    return this.service.get(url, params);
  }
  updateCustomer(customer, type) {
    const url = type === 'pos' ? ConstantUrl.url_demand_domain + 'customer/update' : ConstantUrl.url_customer_domain + 'customer/update';
    return this.service.put(url, customer);
  }
  getContractByCustomer(id) {
    const url = ConstantUrl.url_transaction_query + 'transaction/customer';
    return this.service.get(url, { customerId: id });
  }

  getCustomerDxs(params?): Observable<CPagination<Customer>> {
    const url = ConstantUrl.url_customer_query + 'customer';
    return this.service.get(url, params ? params : null)
      .pipe(
        map((res: any) => {
          const pagination = new CPagination<Customer>().decode(res);
          pagination.items = pagination.items.map((item: any) => { const cus = new Customer(item.customer); return cus.createDataForList(); });
          return pagination;
        })
      );
  }

  getCareCustomer(params?) {
    const url = `${ConstantUrl.url_care_query}customer`;
    return this.service.get(url, params ? params : null);
  }

  public getAllPosActive() {
    const uri = ConstantUrl.url_orgchart_query + `orgchart/pub/all/typePos`;
    return this.service.get(uri);
  }

  importCustomer(files: File) {
    const url = ConstantUrl.url_property_domain + 'propertyCustomer/import';
    let formData = new FormData();
    formData.append('files', files);
    return this.service.import(url, formData);
  }

  public findByCode(code: any): Observable<any> {
    const url = `${ConstantUrl.url_demand_query}customer/findByCode`;
    const queryParams = {
      code: code
    };
    return this.httpService.get(url, queryParams);
  }

  public getEmployeeTakeCare(querySearch: string) {
    const url = `${ConstantUrl.url_employee_query}employee/byCode`;
    return this.httpService.get(url, { query: querySearch });
  }

  public getCareplusCustomers(params?: any) {
    return this.service.get(`${ConstantUrl.url_care_query}customer`, params || {}).pipe(
        map(response => {
            const pagination = new CPagination<CareplusCustomer>().decode(response);
            pagination.items = pagination.items.map((item, index) => {
                const customer = new CareplusCustomer(item);
                customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                return customer;
            });
            return pagination;
        })
    );
  }

  public getDxHomeCustomers(params?: any) {
    return this.service.get(`${ConstantUrl.url_care_query}customer/dxhomes`, params || {}).pipe(
        map(response => {
            const pagination = new CPagination<CareplusCustomer>().decode(response);
            pagination.items = pagination.items.map((item, index) => {
                const customer = new CareplusCustomer(item);
                customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                return customer;
            });
            return pagination;
        })
    );
  }
  exportListCustomerDXHome(params?: any) {
    const url = `${ConstantUrl.url_care_query}customer/dxhomes/export`;
    return this.service.dowloadFile(url, params);
  }
  exportListCustomerCarePlus(params?: any) {
    const url = `${ConstantUrl.url_care_query}customer/care-plus/export`;
    return this.service.dowloadFile(url, params);
  }
  getCustomerManagement(params:any) {
    const url = ConstantUrl.url_sts_query + 'user/allQuery';
     return this.service.get(url, params ? params : null)
      .pipe(
        map(response => {
          const pagination = new CPagination<ManagementCustomer>().decode(response);
          pagination.items = pagination.items.map((item, index) => {
              const customer = new ManagementCustomer(item);
              customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return customer;
          });
          return pagination;
        })
      );
  }

  public getCareplusCustomerById(id: string) {
    return this.service.get(`${ConstantUrl.url_care_query}customer/${id}`);
  }
  public getContractByCareplusByCustomer(id: string) {
    return this.service.get(`${ConstantUrl.url_sale_policy_query}primary-contract/getByCareCustomer/${id}`);
  }
  public getTicketByCareplusByCustomer(id: string) {
    return this.service.get(`${ConstantUrl.url_property_query}primaryTransaction/getByCareCustomer/${id}`);
  }

  public updateCareplusCustomer(params: any) {
      return this.service.put(`${ConstantUrl.url_care_domain}customer`, params);
  }
  public certifyCareplusCustomer(params: any) {
      return this.service.put(`${ConstantUrl.url_care_domain}customer/certifyCustomer`, params);
  }

  public verifyDataChange(params: any) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/dataChange`, params);
  }

  public addCareplusCustomer(params: any) {
    return this.service.post(`${ConstantUrl.url_care_domain}customer`, params);
  }

  public addManagementCustomer(params: any) {
    return this.service.post(`${ConstantUrl.url_sts_domain}user/bql/signup`, params);
  }

  public changeActiveManagementCustomer(params: any) {
    return this.service.put(`${ConstantUrl.url_sts_domain}user/bql/changeStatus/${params.id}`,params);
  }
  public createDxHomeCustomer(params: any) {
    return this.service.post(`${ConstantUrl.url_care_domain}customer/dxhomes`, params);
  }

  public updateDxHomeCustomer(params: any) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/dxhomes`, params);
  }

  public getByIdDXHomesUser(id: string) {
    return this.service.get(`${ConstantUrl.url_care_query}customer/dxhomes/${id}`);
  }

  public certifyDxHomeCustomer(params: any) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/certifyCustomer/dxhomes`, params);
  }

  public changeIsActiveCustomer(id: string) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/changeActive/${id}`, {});
  }

  public updateProfileCustomer(params) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/adminUpdateProfile/real-care`, params);
  }

  public updateProfileCustomerDxhome(params) {
    return this.service.put(`${ConstantUrl.url_care_domain}customer/adminUpdateProfile/dx-home`, params);
  }

  getAllNotificationByCustomer(params) {
    const url = ConstantUrl.url_notification_query + 'notification/byCustomerId'
    return this.service.get(url, params).pipe(
      map(response => {
          const pagination = new CPagination<CustomerNotificationModel>().decode(response);
          pagination.items = pagination.items.map((item, index) => {
              const customer = new CustomerNotificationModel(item);
              customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return customer.createDataForList();
          });
          return pagination;
      })
  ); 
  }

  getAllSmsByCustomer(params) {
    const url = ConstantUrl.url_notification + 'admin/sms/getByCustomerId'
    return this.service.get(url, params).pipe(
      map(response => {
          const pagination = new CPagination<CustomerSmsModel>().decode(response);
          pagination.items = pagination.items.map((item, index) => {
              const customer = new CustomerSmsModel(item);
              customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return customer.createDataForList();
          });
          return pagination;
      })
  ); 
  }

  getAllMailerByCustomer(params) {
    const url = ConstantUrl.url_mailer + 'emailLog/'
    return this.service.get(url, params).pipe(
      map(response => {
          const pagination = new CPagination<CustomerMailerModel>().decode(response);
          pagination.items = pagination.items.map((item, index) => {
              const customer = new CustomerMailerModel(item);
              customer.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return customer.createDataForList();
          });
          return pagination;
      })
  ); 
  }

  getListLeadsTransfer(params?: any): Observable<CPagination<LeadModel>> {
    const url = ConstantUrl.url_ticket_query + 'leadCare/transfer';
    return this.httpService.get(url, params ? params : null)
      .pipe(
        map((res: any) => {
          const pagination = new CPagination<LeadModel>().decode(res);
          pagination.items = pagination.items.map((item: any) => new LeadModel(item));
          return pagination;
        })
      );
  }

  getListLeads(params?: any): Observable<CPagination<LeadModel>> {
    const url = ConstantUrl.url_ticket_query + 'leadCare/primary';
    return this.httpService.get(url, params ? params : null)
      .pipe(
        map((res: any) => {
          const pagination = new CPagination<LeadModel>().decode(res);
          pagination.items = pagination.items.map((item: any) => new LeadModel(item));
          return pagination;
        })
      );
  }
  
  
}
