import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";
import { ZoneImage, Images } from "../../shared/components/upload-images/image.model";
import { POS } from "../orgchart-pos/model/pos.model";

export class Ticket extends BaseModel implements BaseModelInterface  {
    @ValidateIf(o => o.otherProperty === "")
    @IsNotEmpty()
    @Length(10, 20)
    id: string;

    name: string;

    lastName: string;

    firstName: string;

    email: string;

    phone: string;

    address: string;

    pos: POS;

    namePOS: string;

    processBy: string;

    status: string;

    colorStatus: string;

    lifeCycleStatus: string;

    isHold: boolean;

    isPending: boolean;

    type: string;

    typeText: string;

    description: string;

    t0: Date;

    t1: Date;

    t2: Date;

    t3: Date;

    timeOut: Date;

    lock: boolean;

    source: string;

    code: string;

    customer : any;
    
    property : any;

    processedDate : Date;
    employeeTakeCare : any[];

    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'ticket';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.lastName = params.lastName || '';
            this.firstName = params.firstName || '';
            this.email = params.email || '';
            this.phone = params.phone || '';
            this.address = params.address || '';
            this.pos = params.pos ? new POS(params.pos) : new POS();
            this.namePOS = this.pos.name || '';
            this.lifeCycleStatus = params.lifeCycleStatus || 'assigned';
            this.isHold = params.lifeCycleStatus == 'processing' ? true : false;
            this.isPending = params.lifeCycleStatus == 'pending' ? true : false;
            this.processBy = params.processBy || '';
            this.status = params.status || '';
            this.colorStatus = params.colorStatus || '';
            this.type = params.type ? params.type.toUpperCase() : '';
            if (this.type == 'MUA' || this.type == 'BUY') {
                this.typeText = 'Mua';
            }
            if (this.type == 'BÁN' || this.type == 'SELL' || this.type == 'SALE') {
                this.typeText = 'Bán';
            }
            if (this.type == 'RENT') {
                this.typeText = 'Thuê';
            }
            if (this.type == 'LEASE') {
                this.typeText = 'Cho thuê';
            }
            this.t0 = params.t0 || new Date();
            this.t1 = params.t1 || new Date();
            this.t2 = params.t2 || new Date();
            this.t3 = params.t3 || new Date();
            this.timeOut = params.timeOut || new Date();
            this.lock = params.lock || false;
            this.customer = params.customer || null;
            this.property = params.property || null;
            this.processedDate = params.processedDate || new Date();
            this.employeeTakeCare = params.employeeTakeCare || [];
            this.code = params.code || null;
            this.source = params.source || params.resource || null;
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    } 

    setAllNull() {
        this.id = '';
        this.lastName = '';
        this.firstName = '';
        this.phone = '';
        this.address = '';
        this.pos = new POS();
        this.namePOS = '';
        this.processBy = '';
        this.status = '';
        this.colorStatus = '';
        this.type = '';
        this.isHold = false;
        this.description = '';
        this.t0 = new Date();
        this.t1 = new Date();
        this.t2 = new Date();
        this.t3 = new Date();
        this.timeOut = new Date();
        this.lock = false;
        this.customer = null;
        this.property =null;
        this.processedDate = new Date();
        this.employeeTakeCare = [];
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object.lastName = this.lastName;
        object.firstName = this.firstName;
        object.phone = this.phone;
        object.address = this.address;
        object.description = this.description;
        object.customer = this.customer;
        object.property = this.property;
        object.processedDate = this.processedDate;
        object.employeeTakeCare = this.employeeTakeCare;
        object.source = this.source;
        return object;
    }
    createObjectReference() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        return object;
    }
    createObjectForDemand() {
        let object: any = {};
        object.lastName = this.lastName;
        object.firstName = this.firstName;
        object.email = this.email;
        object.phone = this.phone;
        object.address = this.address;
        object.type = this.type;
        object.lock = this.lock;
        object.pos = new POS(this.pos).createObjectReference();
        object.customer = this.customer;
        object.property = this.property;
        object.processedDate = this.processedDate;
        object.employeeTakeCare = this.employeeTakeCare;
        object.source = this.source;
        return object;
    }
}
