import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Constant } from 'app/shared/constant/constant';

@Component({
  selector: 'app-surveys-config',
  templateUrl: './app-surveys-config.component.html',
  styleUrls: ['./app-surveys-config.component.scss']
})
export class SurveysConfigComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder
  ) {
    
  }

  @Input() lstSurveys: any[] = [];
  Constant = Constant

  dynamicForm: FormGroup = null;
  public lstTypeQuestion: any[] = [
    { code: 'text', name: 'Short text' },
    { code: 'paragraph', name: 'Paragraph' },
    { code: 'radio', name: 'Radio button' },
    { code: 'checkbox', name: 'Checkbox' },
    { code: 'dropdown', name: 'Dropdown list' },
    { code: 'file', name: 'File upload' }
  ];

  ngOnInit() {
    // console.log(this.lstSurveys)
    this.dynamicForm = this.formBuilder.group({
      surveys: new FormArray([]),
      subSurveys: new FormArray([])
    });
    this.initForm()
  }

  ngOnChanges() {
    if(this.lstSurveys) {
      this.initForm()
    }
  }

  initForm() {
    if (this.lstSurveys.length > 0) {
      this.lstSurveys.forEach((item: any) => {
        if (item.type === 'text' || item.type === 'file' || item.type === 'paragraph') {
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer, Validators.required],
            subSurvey: [],
            showStt: [item.showStt]
          }));
        } else if (item.type === 'checkbox' || item.type === 'radio' || item.type === 'dropdown') {
          let lstSubSurvey = [];
          if (item.subSurvey.length > 0) {
            item.subSurvey.forEach((subSurvey: any) => {
              lstSubSurvey.push(this.formBuilder.group({
                idSubSurvey: [subSurvey.idSubSurvey, Validators.required],
                checked: [subSurvey.checked],
                nameOption: [subSurvey.nameOption, [Validators.required]],
              }))
            })
          }
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer],
            subSurvey: [lstSubSurvey],
            showStt: [item.showStt]
          }));
        } else {
          this.surveys.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            type: [item.type, Validators.required],
            question: [item.question, Validators.required],
            answer: [item.answer],
            subSurvey: [item.subSurvey],
            showStt: [item.showStt]
          }));
        }
      })
    }
  }

  addSurvey() {
    let indexId = 0;
    if (this.surveys && this.surveys.controls.length && this.surveys.controls.length > 0) {
      const data = this.surveys.controls[this.surveys.controls.length - 1].value;
      if (data && data.id) {
        indexId = data.id;
      } else {
        indexId = 0;
      }
    };
    indexId++;

    this.surveys.push(this.formBuilder.group({
      id: [indexId, Validators.required],
      type: ['', Validators.required],
      question: ['', [Validators.required]],
      answer: '',
      subSurvey: [[]],
      showStt: true
    }));
  }

  addSubSurvey(id: any) {
    if (this.surveys && this.surveys.controls.length && this.surveys.controls.length > 0) {
      let findSurvey = this.surveys.controls.find((item: any) => item.value && item.value.id === id);
      if (findSurvey && findSurvey.value) {
        let dataSurvey = findSurvey.value;
        if (dataSurvey) {
          let lstSubSurveyOld = dataSurvey.subSurvey ? dataSurvey.subSurvey : [];
          let indexId = 0;
          if (lstSubSurveyOld && lstSubSurveyOld.length > 0) {
            indexId = 
            lstSubSurveyOld[lstSubSurveyOld.length - 1].value && lstSubSurveyOld[lstSubSurveyOld.length - 1].value.idSubSurvey 
            ? lstSubSurveyOld[lstSubSurveyOld.length - 1].value && lstSubSurveyOld[lstSubSurveyOld.length - 1].value.idSubSurvey : 0;
          };
          indexId++;
          lstSubSurveyOld.push(this.formBuilder.group({
            idSubSurvey: [indexId, Validators.required],
            checked: [false],
            nameOption: [`Lựa chọn ${indexId}`, [Validators.required]],
          }));
          dataSurvey.subSurvey = lstSubSurveyOld;
        }
        return;
      }
      return;
    }
    return;
  }

  removeSurvey(item: any) {
    this.surveys.removeAt(item);
  }

  removeSubSurvey(idSurvey: any, idSubSurvey: any) {
    if (!idSurvey || !idSubSurvey) {
      return;
    } else {
      let findSurvey = this.surveys.controls.find((item: any) => item.value && item.value.id === idSurvey);
      if (findSurvey && findSurvey.value) {
        let dataSurvey = findSurvey.value;
        if (dataSurvey) {
          let lstSubSurveyOld: any = dataSurvey.subSurvey ? dataSurvey.subSurvey : [];
          lstSubSurveyOld = lstSubSurveyOld.filter((item: any) => item.value.idSubSurvey !== idSubSurvey);
          dataSurvey.subSurvey = lstSubSurveyOld;
        }
      } else {
        return;
      }
    }
  }

  submitData() {
    let lstSurvey: any[] = [];
    if (this.surveys.controls.length > 0) {
      this.surveys.controls.forEach((survey: any) => {
        let dataSurvey = survey.value;
        let surveyConfig: any = {};
        let dataSubSurvey: any[] = [];
        if (dataSurvey.type === 'text' || dataSurvey.type === 'paragraph') {
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = dataSurvey.answer;
          surveyConfig.subSurvey = [];
          surveyConfig.showStt = dataSurvey.showStt;
        } else if (dataSurvey.type === 'file') {
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = [];
          surveyConfig.subSurvey = [];
          surveyConfig.showStt = dataSurvey.showStt;
        } else if (dataSurvey.type === 'radio' || dataSurvey.type === 'checkbox' || dataSurvey.type === 'dropdown') {
          if (survey.value.subSurvey.length > 0) {
            survey.value.subSurvey.forEach((subSurvey: any) => {
              let data = {
                idSubSurvey: subSurvey.value.idSubSurvey,
                checked: false,
                nameOption: subSurvey.value.nameOption
              }
              dataSubSurvey.push(data);
            });
          }
          surveyConfig.id = dataSurvey.id;
          surveyConfig.type = dataSurvey.type;
          surveyConfig.question = dataSurvey.question;
          surveyConfig.answer = dataSurvey.answer;
          surveyConfig.subSurvey = dataSubSurvey;
          surveyConfig.showStt = dataSurvey.showStt;
        }
        lstSurvey.push(surveyConfig);
      })
    }
    return lstSurvey;
  }

  checkValidateFormSurvey() {
    let checkValidate: boolean = false;
    this.markAsTouched(this.surveys);
    this.surveys.controls.forEach((survey: any, index: any) => {
      let check = this.issurveyControlInvalid(index);
      if (check) {
        checkValidate = true;
      }
    });
    return checkValidate;
  }

  public issurveyControlInvalid(index): boolean {
    return this.surveys.controls[index].get('question').invalid || this.surveys.controls[index].get('type').invalid;
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  get f() { return this.dynamicForm.controls; }
  get surveys() { return this.f.surveys as FormArray; }
}
