export class DateToFormat {
  static parseDate(date, formart) {
    if (!date) {
      return '';
    }
    date = new Date(date);
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() > 8 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    const year = date.getYear() + 1900;
    const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    const second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    if (!formart) {
      return day + '/' + month + '/' + year;
    } else {
      let result = formart;
      result = result.replace(/dd/g, day);
      result = result.replace(/MM/g, month);
      result = result.replace(/yyyy/g, year);
      result = result.replace(/hh/g, hour);
      result = result.replace(/mm/g, minute);
      result = result.replace(/ss/g, second);
      return result;
    }
  }
}