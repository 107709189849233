import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material';
import * as moment from 'moment';

@Component({
    selector: 'app-filter-property',
    templateUrl: './filter-property.component.html',
    styleUrls: ['./filter-property.component.scss']
})
export class FilterPropertyComponent implements OnInit {
    @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;
    @Output() search: EventEmitter<any> = new EventEmitter();
    @Input() statuses: any[] = [];
    @Input() poses: any[] = [];
    @Input() floors: any[] = [];
    @Input() blocks: any[] = [];
    @Input() rooms: any[] = [];
    @Input() owners: any[] = [];
    @Input() isResetSearch: boolean = false;
    public filterWidth = 0;
    public selectStatus: any = [];
    public selectPoses: any = [];
    public selectFloors: any = [];
    public selectBlocks: any = [];
    public selectRooms: any = [];
    public selectOwners: any = [];

    public selectStatusApplied: any = [];
    public selectPosesApplied: any = [];
    public selectFloorsApplied: any = [];
    public selectBlocksApplied: any = [];
    public selectRoomsApplied: any = [];
    public selectOwnersApplied: any = [];

    public searchStatus: any = [];
    public searchPoses: any = [];
    public searchFloors: any = [];
    public searchBlocks: any = [];
    public searchRooms: any = [];
    public searchOwners: any = [];
    public searchKey = new FormControl();
    @Input() isDatePicker: boolean = false;
    createdDateStart = '';
    createdDateEnd = '';

    constructor() {
    }

    ngOnInit() {
        this.setFilterWidth();

     }
    setFilterWidth(){
        if(this.statuses.length){
            this.filterWidth += 200;
        }
        if(this.poses.length){
            this.filterWidth += 200;
        }
        if(this.floors.length){
            this.filterWidth += 200;
        }
        if(this.blocks.length){
            this.filterWidth += 200;
        }
        if(this.rooms.length){
            this.filterWidth += 200;
        }
        if(this.owners.length){
            this.filterWidth += 200;
        }
        // if(this.isDatePicker){
        //     this.filterWidth += 400;
        // }
    }
    searchCode() {
        let keyword = '';
        if (this.searchKey.value) {
            keyword = this.searchKey.value.trim();
        }
        let params: any = { keywords: keyword };
        if (this.statuses.length > 0) {
            params.status = this.searchStatus;
            this.selectStatusApplied = [...this.selectStatus];
        }
        if (this.poses.length > 0) {
            params.poses = this.searchPoses;
            this.selectPosesApplied = [...this.selectPoses];
        }
        if (this.floors.length > 0) {
            params.floors = this.searchFloors;
            this.selectFloorsApplied = [...this.selectFloors];
        }
        if (this.blocks.length > 0) {
            params.blocks = this.searchBlocks;
            this.selectBlocksApplied = [...this.selectBlocks];
        }
        if (this.rooms.length > 0) {
            params.rooms = this.searchRooms;
            this.selectRoomsApplied = [...this.selectRooms];
        }
        if (this.owners.length > 0) {
            params.owners = this.searchOwners;
            this.selectOwnersApplied = [...this.selectOwners];
        }
        if (this.createdDateStart) {
            params.createdFrom = moment(this.createdDateStart).startOf('date').toISOString();
        }
        if (this.createdDateEnd) {
            params.createdTo = moment(this.createdDateEnd).endOf('date').toISOString();
        }
        this.search.emit(params);
        this.matMenu.closeMenu()
    }

    onClickCheckBoxStatus(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectStatus.includes(item.id)) {
                this.selectStatus.push(item.id);
            }
        } else {
            if (this.selectStatus.length > 0) {
                this.selectStatus = this.selectStatus.filter(i => i !== item.id);
            }
        }
        this.searchStatus = this.selectStatus || [];
    }
    onClickCheckBoxPoses(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectPoses.includes(item.id)) {
                this.selectPoses.push(item.id);
            }
        } else {
            if (this.selectPoses.length > 0) {
                this.selectPoses = this.selectPoses.filter(i => i !== item.id);
            }
        }
        this.searchPoses = this.selectPoses || [];
    }
    onClickCheckBoxFloors(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectFloors.includes(item.id)) {
                this.selectFloors.push(item.id);
            }
        } else {
            if (this.selectFloors.length > 0) {
                this.selectFloors = this.selectFloors.filter(i => i !== item.id);
            }
        }
        this.searchFloors = this.selectFloors || [];
    }
    onClickCheckBoxBlocks(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectBlocks.includes(item.id)) {
                this.selectBlocks.push(item.id);
            }
        } else {
            if (this.selectBlocks.length > 0) {
                this.selectBlocks = this.selectBlocks.filter(i => i !== item.id);
            }
        }
        this.searchBlocks = this.selectBlocks || [];
    }
    onClickCheckBoxRooms(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectRooms.includes(item.id)) {
                this.selectRooms.push(item.id);
            }
        } else {
            if (this.selectRooms.length > 0) {
                this.selectRooms = this.selectRooms.filter(i => i !== item.id);
            }
        }
        this.searchRooms = this.selectRooms || [];
    }
    onClickCheckBoxOwners(checked: boolean, item) {
        item.checked = checked;
        if (checked) {
            if (!this.selectOwners.includes(item.id)) {
                this.selectOwners.push(item.id);
            }
        } else {
            if (this.selectOwners.length > 0) {
                this.selectOwners = this.selectOwners.filter(i => i !== item.id);
            }
        }
        this.searchOwners = this.selectOwners || [];
    }
    
    uncheckFilter() {
        if (this.statuses.length) {
            this.selectStatus = [];
            this.selectStatusApplied = [];
            this.statuses.map(item => {
                item.checked = false;
                return item;
            });
            this.searchStatus = '';
        }
        if (this.poses.length) {
            this.selectPoses = [];
            this.selectPosesApplied = [];
            this.poses.map(item => {
                item.checked = false;
                return item;
            });
            this.searchPoses = '';
        }
        if (this.floors.length) {
            this.selectFloors = [];
            this.selectFloorsApplied = [];
            this.floors.map(item => {
                item.checked = false;
                return item;
            });
            this.searchFloors = '';
        }
        if (this.blocks.length) {
            this.selectBlocks = [];
            this.selectBlocksApplied = [];
            this.blocks.map(item => {
                item.checked = false;
                return item;
            });
            this.searchBlocks = '';
        }
        if (this.rooms.length) {
            this.selectRooms = [];
            this.selectRoomsApplied = [];
            this.rooms.map(item => {
                item.checked = false;
                return item;
            });
            this.searchRooms = '';
        }
        if (this.owners.length) {
            this.selectOwners = [];
            this.selectOwnersApplied = [];
            this.owners.map(item => {
                item.checked = false;
                return item;
            });
            this.searchOwners = '';
        }
        this.createdDateStart = null
        this.createdDateEnd = null
        this.searchKey = new FormControl();
        if (this.isResetSearch) {
            this.searchCode();
        }
    }

    menuClosed() {
        if (this.statuses.length) {
            this.selectStatus = [...this.selectStatusApplied];
            this.statuses.map(status => {
                status.checked = this.selectStatus.includes(status.id);
                return status;
            });
        }
        if (this.poses.length) {
            this.selectPoses = [...this.selectPosesApplied];
            this.poses.map(pos => {
                pos.checked = this.selectPoses.includes(pos.id);
                return pos;
            });
        }
        if (this.floors.length) {
            this.selectFloors = [...this.selectFloorsApplied];
            this.floors.map(floors => {
                floors.checked = this.selectFloors.includes(floors.id);
                return floors;
            });
        }
        if (this.blocks.length) {
            this.selectBlocks = [...this.selectBlocksApplied];
            this.blocks.map(block => {
                block.checked = this.selectBlocks.includes(block.id);
                return block;
            });
        }
        if (this.rooms.length) {
            this.selectRooms = [...this.selectRoomsApplied];
            this.rooms.map(room => {
                room.checked = this.selectRooms.includes(room.id);
                return room;
            });
        }
        if (this.owners.length) {
            this.selectOwners = [...this.selectOwnersApplied];
            this.owners.map(source => {
                source.checked = this.selectOwners.includes(source.id);
                return source;
            });
        }

    }
    countFilter() {
        return this.selectStatusApplied.length + this.selectPosesApplied.length +
               this.selectFloorsApplied.length + this.selectBlocksApplied.length +
               this.selectRoomsApplied.length + this.selectOwnersApplied.length
    }
    onSortFilter(){
        this.poses.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
        this.statuses.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
        this.floors.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
        this.blocks.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
        this.rooms.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
        this.owners.sort((a: any, b: any) => {
            return a.checked ? -1 : 1
        });
    }
}
