
import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, } from '@angular/forms';
import {Constant} from '../../../shared/constant/constant'
import {FamilyModel} from '../../../shared/models/family.model';

@Component({
    selector: 'modal-residen-infomation',
    templateUrl: './modal-resident-information.component.html',
    styleUrls: ['./modal-resident-information.component.scss']
  })
  export class ModalResidenInfomationComponent  {
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isEdit: boolean = false;
    public mainForm: FormGroup = null;
    public isLoading:boolean = false;
    constant = Constant;
    constructor(
      public dialogRef: MatDialogRef<ModalResidenInfomationComponent>,
      public formBuilder: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: FamilyModel) {

    }

    initForm():void {
        this.mainForm = this.formBuilder.group({
            fullName: [{value: '', disabled: true}],
            dateOfBirth:  [{value: '', disabled: true}],
            gender: [{value: 1, disabled: true}],
        })
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    closeDialog () {
        this.dialogRef.close();
    }
  
  }