import { Pipe, PipeTransform } from '@angular/core';
import { DateToFormat } from '../parse/date-to-format';
@Pipe({name: 'date'})
export class TransformCustomDatePipe implements PipeTransform {
  transform(value: any, typeformart: any) {
    if (value) {
      return this.parseDate(value, typeformart);
    } else {
      return '';
    }
  }
  parseDate(date, formart) {
    return DateToFormat.parseDate(date, formart);
  }
}
