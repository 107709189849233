import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CIdName } from 'app/api-models/c-id-name';

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss']
})
export class StepProgressBarComponent implements OnInit {

  @Input() steps: CIdName[] = [];
  @Input() stepIndex: number = 0;
  @Output() stepIndexChange: EventEmitter<number> = new EventEmitter();
  @Input() enableClick: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onChangeIndex(index: number) {
    if (this.enableClick) {
      this.stepIndexChange.emit(index);
    }
  }

}
