import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Employee} from '../../models/employee.model';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent implements OnInit {

  @Input() employee: Employee = new Employee();
  constructor(public router: Router) { }

  ngOnInit() {
  }

  showDetail(id) {
    this.router.navigate(['tu-van-vien/' + id], { queryParamsHandling: 'merge' });
  }

}
