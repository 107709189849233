import { Component, OnInit, OnChanges, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {CPagination} from '../../../api-models';

@Component({
  selector: 'app-product-paging-v2',
  templateUrl: './product-paging-v2.component.html',
  styleUrls: ['./product-paging-v2.component.scss']
})
export class ProductPagingV2Component implements OnInit, OnChanges {
  @Input() data: CPagination<any> = new CPagination();
  @Output() dataChange: EventEmitter<CPagination<any>> = new EventEmitter();

  public pageLength = 1;
  public paging: number[] = [];
  get numberPage(): number {
    return Math.floor(this.data.total / this.data.pageSize) + (this.data.total % this.data.pageSize > 0 ? 1 : 0);
  }
  get showStartDot(): boolean {
    return this.paging && this.paging[0] && this.paging[0] > 1;
  }
  get showEndDot(): boolean {
    return this.paging && this.paging[this.paging.length - 1] && this.paging[this.paging.length - 1] < this.numberPage;
  }
  get isPagingNextToStart(): boolean {
    return this.paging[0] <= 2;
  }
  get isPagingNextToEnd(): boolean {
    return this.numberPage - this.paging[this.paging.length - 1] <= 1;
  }
  get indexFrom(): number {
    const indexFrom = ((this.data.page - 1) * this.data.pageSize);
    return indexFrom > 0 ? (indexFrom + 1) : 1;
  }
  get indexTo(): number {
    const indexTo = (this.data.page * this.data.pageSize);
    return indexTo < this.data.total ? indexTo : this.data.total;
  }

  constructor(@Inject(DOCUMENT) private document) { }

  ngOnInit() {
    this.initPaging();
  }

  ngOnChanges() {
    this.initPaging();
  }

  initPaging() {
    const paging = [];
    const startIndex: number = (this.data.page - this.pageLength > 0) ? this.data.page - this.pageLength : 1;
    const endIndex: number = (this.data.page + this.pageLength <= this.data.totalPage)
      ? (this.data.page + this.pageLength)
      : this.data.totalPage;
    for (let i = startIndex; i <= endIndex; i++) {
      paging.push(i);
    }
    this.paging = paging;
  }

  firstPage() {
    this.changePage(1);
  }

  previousPage() {
    this.changePage(this.data.page - 1);
  }

  nextPage() {
    this.changePage(this.data.page + 1);
  }

  lastPage() {
    this.changePage(this.numberPage);
  }

  changePage(pageNumber: number) {
    this.data.page = pageNumber;
    this.initPaging();
    this.dataChange.emit(this.data);
  }
}
